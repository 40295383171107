import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { WarehouseContext } from "../../context/WarehouseContext";
import { fetchShortFormAccounts } from "../../api/Account";
import{LineHaulDetails} from "./LineHaulDetails"
import { renderAlertMessage } from "../../helpers/common";

const LineHaulManage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const getAccounts = (whId) => {
    setIsLoading(true);
    fetchShortFormAccounts("", whId).then((result) => {
      if (result.success) {
        setAccounts(result.accounts);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  useEffect(() => {
    getAccounts();
    setWarehouses(props.warehouseFilter.warehouses);
  }, []);

  useEffect(() => {
    setWarehouses(props.warehouseFilter.warehouses);
    getAccounts();
  }, [
    props.warehouseFilter.selectedWarehouses,
    props.warehouseFilter.warehouses,
  ]);

  const refreshAccounts = (whId) => {
    getAccounts(whId);
  };

  return (
    <div>
      <LineHaulDetails
        warehouses={warehouses}
        accounts={accounts}
        refreshAccounts={refreshAccounts}
        warehouseFilter={props.warehouseFilter}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        orderId={props.orderId}
        accountInfo={props.account}
      />
    </div>
  );
};

export const LineHaulManageComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  return (
    <div>
      <LineHaulManage warehouseFilter={warehouseFilter} {...props} />
    </div>
  );
});

export default LineHaulManageComponent;

import React from 'react';
import PropTypes from "prop-types";
import { Icon, Select } from 'antd';

function WarehouseSelector (props) {
  const {
    isFetchingWarehouses,
    isWarehouseMultiSelect,
    warehouses,
    isDisabled,
    style,
    handleWarehouseChange,
    defaultSelectionValue,
    getWarehouse,
    showIcon=false,
    showAllOption = true,
  } = props;
  return (
    <Select
      loading={ isFetchingWarehouses }
      value={
        getWarehouse()
      }
      suffixIcon={ showIcon ? <Icon type="home" /> : null }
      disabled={ isDisabled }
      labelInValue={ isWarehouseMultiSelect }
      showSearch
      showArrow
      style={ style ? style : { width: "250px" } }
      filterOption={ (input, option) =>
        option.props.children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      mode={ isWarehouseMultiSelect ? "multiple" : "default" }
      maxTagCount={ 1 }
      onChange={ handleWarehouseChange }
    >
      {
        showAllOption && <Select.Option key="" value="">
        { defaultSelectionValue }
      </Select.Option>
      }
      { warehouses.sort((a, b) => a.name.localeCompare(b.name)).map((warehouse) => (
        <Select.Option
          key={ warehouse.location_code }
          value={ warehouse.id }
        >
          { warehouse.name }
        </Select.Option>
      )) }
    </Select>
  );
}

export default WarehouseSelector

WarehouseSelector.defaultProps = {
  isWarehouseMultiSelect: false,
  isDisabled: false,
  style: {},
  defaultSelectionValue : "-- All --",
  handleWarehouseChange: () => { },
  isFetchingWarehouses : false,
  getWarehouse : () => { }
}

WarehouseSelector.propTypes = {
  isFetchingWarehouses: PropTypes.bool,
  isWarehouseMultiSelect: PropTypes.bool,
  warehouses: PropTypes.array,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  handleWarehouseChange: PropTypes.func,
  defaultSelectionValue: PropTypes.string,
  getWarehouse : PropTypes.func,
  showAllOption : PropTypes.bool,
}
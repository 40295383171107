import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { doFormate } from '../../common/Common';
import { Icon, Checkbox, Row, Col, Card } from '../../common/UIComponents';
import userStore from '../../stores/UserStore';
import { accessorialValue } from '../../helpers/orders';
import I18n from '../../common/I18n';

const ItemAccessorial = ({
  item, accessorials, onCheckBoxChange, showFullDetails, isReadOnly, title, showHeading = true, showCosts = false,
}) => {
  const isAuthorised = userStore.superAdmin() || userStore.admin();
  return (item &&
    !isReadOnly ?
      <Card
        key={item.item_id}
        size="small"
        title={showHeading ? (title || `${I18n.t(`order.item_types.${item.item_type}`)} - ${doFormate(item.item_name)}`) : ''}
        extra={item.total > 0 && showCosts &&
        <Row>
          <Col>
            Total : {I18n.t('general.price_symbol')}{item.total}
          </Col>
        </Row>
        }
        className="marginBottom10"
      >
        <Row>
          <Col sm={24} xs={24} md={24} lg={24} className="currentOrder_details">
            { showFullDetails &&
            <Fragment>
              <Row>
                <Col sm={2} xs={2} md={2} lg={2}>
                  <div className="textCenter">
                    <Icon type="number" />
                  </div>
                </Col>
                <Col sm={7} xs={7} md={7} lg={7}>
                  <div className="info-text textBold">{I18n.t('order.model_no')}</div>
    
    
                </Col>
                <Col xs={1}>:</Col>
                <Col sm={14} xs={14} md={14} lg={14}>
                  <div className="info-text">
                    <span>{doFormate(item.item_model)}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={2} xs={2} md={2} lg={2}>
                  <div className="textCenter">
                    <Icon type="calendar" />
                  </div>
                </Col>
                <Col sm={7} xs={7} md={7} lg={7}>
                  <div className="info-text textBold">{I18n.t('order.serial_no')}</div>
    
    
                </Col>
                <Col xs={1}>:</Col>
                <Col sm={14} xs={14} md={14} lg={14}>
                  <div className="info-text">
                    <span>{doFormate(item.serial_number)}</span>
                  </div>
                </Col>
              </Row>
    
            </Fragment>
            }
            { isAuthorised &&
              <Row className="alignLeft">
                <Col sm={2} xs={2} md={2} lg={2}>
                  <div className="textCenter">
                    <Icon type="control" />
                  </div>
                </Col>
                <Col sm={22} xs={22} md={22} lg={22} className="info-text textBold">{I18n.t('general.accessorial')}</Col>
              </Row>
            }
            <Row>
              <Col sm={2} xs={2} md={2} lg={2} />
              <Col sm={24} xs={24} md={isAuthorised && showFullDetails ? 22 : 24} lg={isAuthorised && showFullDetails ? 22 : 24}>
                <Row>
                  <Col xs={24}>
                    <Checkbox.Group
                      style={{ width: '100%' }}
                      value={item.selectedAccessorials || []}
                      onChange={e =>
                        onCheckBoxChange(
                          e,
                          'accessorial',
                          item.item_id,
                        )
                      }
                    >
                      {
                        accessorials.map(accessorial => (
                          <Row className="borderBottom padding5" key={accessorial.org_accessorial_id}>
                            <Col xs={1}>
                              <Checkbox
                                value={accessorial.org_accessorial_id}
                              />
                            </Col>
                            <Col xs={!showCosts ? 23 : 18}>
    
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                              {accessorial.name}
                            </Col>
                            {showCosts && <Col xs={5} >
                              <div className="alignRight">{I18n.t('general.price_symbol')}{accessorial.amount}</div>
                            </Col>
                            }
                          </Row>
                        ))
                      }
    
                    </Checkbox.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
    
    
          </Col>
    
    
        </Row>
    
      </Card>
      :
      <Card
        key={item.item_id}
        size="small"
        title={showHeading ? (title || `${I18n.t(`order.item_types.${item.item_type}`)} - ${doFormate(item.item_name)}`) : ''}
        extra={item.total > 0 && showCosts &&
        <Row>
          <Col>
            Total : {I18n.t('general.price_symbol')}{item.total}
          </Col>
        </Row>
        }
      >
        <Row>
          <Col sm={24} xs={24} md={24} lg={24} className="currentOrder_details">
            { showFullDetails &&
            <Fragment>
              <Row>
                <Col sm={2} xs={2} md={2} lg={2}>
                  <div className="textCenter">
                    <Icon type="number" />
                  </div>
                </Col>
                <Col sm={7} xs={7} md={7} lg={7}>
                  <div className="info-text textBold">{I18n.t('order.model_no')}</div>
    
    
                </Col>
                <Col xs={1}>:</Col>
                <Col sm={14} xs={14} md={14} lg={14}>
                  <div className="info-text">
                    <span>{doFormate(item.item_model)}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={2} xs={2} md={2} lg={2}>
                  <div className="textCenter">
                    <Icon type="calendar" />
                  </div>
                </Col>
                <Col sm={7} xs={7} md={7} lg={7}>
                  <div className="info-text textBold">{I18n.t('order.serial_no')}</div>
    
    
                </Col>
                <Col xs={1}>:</Col>
                <Col sm={14} xs={14} md={14} lg={14}>
                  <div className="info-text">
                    <span>{doFormate(item.serial_number)}</span>
                  </div>
                </Col>
              </Row>
    
            </Fragment>
            }
            <Row>
              <Col sm={2} xs={2} md={2} lg={2} />
              <Col sm={24} xs={24} md={isAuthorised && showFullDetails ? 22 : 24} lg={isAuthorised && showFullDetails ? 22 : 24}>
                <Row>
    
                  { item.selectedAccessorials && item.selectedAccessorials.length > 0 ?
                    <Col xs={24}>
                      {
    
    
                        accessorials.filter(filteredAccessorial => item.selectedAccessorials.includes(filteredAccessorial.org_accessorial_id)).map((accessorial) => {
                          const isCompleted = accessorialValue(item.item_accessorials, 'org_accessorial_id', accessorial.org_accessorial_id, 'is_completed');
                          return (
                            <Row className="borderBottom padding5" key={accessorial.org_accessorial_id}>
                              <Col xs={showCosts ? 15 : 24} className="alignLeft textBold" >
                                {
                                  isAuthorised ?
                                    <Icon
                                      type="check-circle"
                                      className={isCompleted === 'true' ? 'textGreen textBold' : 'textGrey'}
                                    />
                                    :
                                    <Icon type="check" className="primaryText" />
                                }
                                &nbsp;&nbsp;{accessorial.name}
                              </Col>
                              { showCosts &&
                              <Col xs={9} >
                                <div className="alignRight">{I18n.t('general.price_symbol')}{accessorial.amount}</div>
                              </Col>
                              }
                            </Row>
                          );
                        })
                      }
                    </Col>
                    :
                    <Col xs={24}>
                      <h3 className="alignCenter">{I18n.t('accessorials.no_accessorial_opted')}</h3>
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
    
    
          </Col>
    
    
        </Row>
    
      </Card>);
}


ItemAccessorial.propTypes = {
  item: PropTypes.shape().isRequired,
  accessorials: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onCheckBoxChange: PropTypes.func.isRequired,
  showFullDetails: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  title: PropTypes.string,
  showHeading: PropTypes.bool,
  showCosts: PropTypes.bool,
};
ItemAccessorial.defaultProps = {
  showFullDetails: true,
  isReadOnly: false,
  title: '',
  showHeading: true,
  showCosts: true,
};

export default ItemAccessorial;

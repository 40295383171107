import moment from "moment";
import I18n from "../common/I18n";
import { isProdEnvironment } from "../helpers/common";

const isProdEnv = isProdEnvironment();
const mapKey = isProdEnv ? "AIzaSyCsZapWPw5VPmBWRAIz1M3SOl3HBL4W4t4" : "AIzaSyDrG4BOLf0QFidqUioPyurHLnFG1bSV69o"
export default {
  baseUrl: "",
  wmsUrl: "",
  invoices_filter_duration: 15,
  perPage: 20,
  ordersPerPage: 20,
  recordsPerPageForGrid: "50",
  agGridPerPage: 100,
  date_format: "MMM DD, YYYY",
  report_date_format: "MM/DD/YYYY",
  carrierTypes: {
    FINAL_MILE : "Final Mile",
    AIR_CARGO : "Air Cargo",
  },
  exportOptions: {
    PDF: "PDF",
    XLS: "EXCEL",
    CSV: "CSV",
  },
  dateTimeFormat: "MMM DD, YYYY hh:mm A",
  deductionTypes :
  {
    "PERCENTAGE_ON_GROSS_PAY": "PERCENTAGE NET",
    "PERCENTAGE_ON_GROSS_REVENUE": "PERCENTAGE GROSS",
    "VARIABLE":"VARIABLE",
    "FIXED" : "FIXED"
    
  },
  defaultPaymentType: 'VARIABLE',
  mobileRoles: [{
    role_code: "driver",
    role_name: "Driver",
  },
  {
    role_code: "helper",
    role_name: "Helper",
  },
  {
    role_code: "technician",
    role_name: "Technician",
  }],
  
  configType: [{
    config_value: "ACCOUNT",
    config_name: "Account",
  },
  {
    config_value: "BILLING",
    config_name: "Billing",
  },
 ],

  typeOfDriver: [
    {
      driver_type: "Hourly",
      driver_key: "hourly",
    },
    {
      driver_type: "Contract",
      driver_key: "contract",
    },
    {
      driver_type: "Transfer",
      driver_key: "transfer",
    },
    {
      driver_type: "Caremark",
      driver_key: "caremark",
    },
  ],

  referenceActivities: [
    {
      name: "Delivery Zipcodes",
      value: "DELIVERY_ZIPCODES",
    },
    {
      name: "Billing Zones",
      value: "BILLING_ZONES",
    },
    {
      name: "Accessorials",
      value: "ACCESSORIALS",
    },
    {
      name: "Level Of Services",
      value: "LEVEL_OF_SERVICES",
    },
    {
      name: "Adjustments",
      value: "ADJUSTMENTS",
    },
    {
      name: "Dim Configuration",
      value: "DIM_CONFIGURATION",
    },
    {
      name: "Accounts",
      value: "ACCOUNTS",
    },
  ],

  relation: [
    {
      name: "Father",
      key: "father",
    },
    {
      name: "Mother",
      key: "mother",
    },
    {
      name: "Brother",
      key: "brother",
    },
    {
      name: "Sister",
      key: "sister",
    },
    {
      name: "Friend",
      key: "friend",
    },
    {
      name: "Spouse",
      key: "spouse",
    },
    {
      name: "Cousin",
      key: "cousin",
    },
    {
      name: "Grand Father",
      key: "grand_father",
    },
    {
      name: "Grand Mother",
      key: "grand_mother",
    },
  ],
  time_format: "hh:mm A",
  milatary_time_format: "HH:mm",
  milataryDateTimeFormat: "MMM DD, YYYY HH:mm",
  fetchRefreshTime: 2 * 60 * 1000 /* 2 min interval for auto refreshig data */,
  warehouseCode: "WH",
  mapKey,
  //   mapKey: "AIzaSyCNNmfTGsBatXy77JEAcjxuHCR2WSxVhvg",
  notStartedList: ["RECEIVED", "VERIFIED"],
  weight_unit: "Lbs",
  dimension_unit: "inches",
  dimension_unit_short_form: "in",
  distance_unit: "miles",
  pdf_url: 'http://54.226.55.70/dashboard?id=',
  status: {
    All: "",
    New: "NEW",
    Received: "RECEIVED",
    Verified: "VERIFIED",
    Preplan: "PREPLAN",
    Assigned: "ASSIGNED",
    Prepare: "PREPARE",
    Dispatched: "DISPATCHED",
    Completed: "COMPLETED",
    Exception: "EXCEPTION",
    Cancelled: "CANCELLED",
  },
  orderChangeStatus: {
    All: "",
    New: "NEW",
    Received: "RECEIVED",
    "On Hold":"ON_HOLD",
    Verified: "VERIFIED",
    Preplan: "PREPLAN",
    Assigned: "ASSIGNED",
    Prepare: "PREPARE",
    Dispatched: "DISPATCHED",
    Completed: "COMPLETED",
    Exception: "EXCEPTION",
    //Cancelled: "CANCELLED",
    Closed: "CLOSED",
    Archived: "ARCHIVED"
  },
  pendingOrderChangeStatus:{
    // All:"",
    Pending:"PENDING",
    // Approved:"APPROVED",
    Rejected:"REJECTED"
  },
  accessorialsTypes: {
    // All: 'ALL',
    Customer: "CUSTOMER",
    // Billing: 'BILLING',
    Driver: "DRIVER",
  },
  ActiveStatus: [{
    status: "active",
    name: "Active",
  },
  {
    status: "inactive",
    name: "In Active",
  }],
  actions: {
    orders: {
      super_admin: false,
      admin: true,
    },
    preplan: {
      super_admin: false,
      admin: true,
    },
    dispatch: {
      super_admin: false,
      admin: true,
    },
    fleet: {
      super_admin: false,
      admin: true,
    },
  },
  amountTypes: {
    Flat: "FLAT",
    Percentage: "PERCENTAGE",
  },
  contentType: {
    Order: "ORDER",
    Item: "ITEM",
  },
  default_decimal_points: 2,
  driversInfo: {
    primary: {
      driverId: "",
      allotedDriver: {},
      selectedDriverId: "",
    },
    secondary: {
      driverId: "",
      allotedDriver: {},
      selectedDriverId: "",
    },
  },
  preplanTypes: { single_day: "Single Day", multi_day: "Multi Day" },
  optimizeTypes: { auto: "Auto", manual: "Manual" },
  colors: [
    "004a7d",
    "73777f",
    "c7b794",
    "0052d0",
    "009bff",
    "00deff",
    "fed000",
    "de5f15",
    "1470c2",
    "21692a",
    "FF00FF",
    "FFFF00",
    "00FF00",
    "333333",
    "666666",
    "999999",
    "CCCCCC",
    "5a5755",
    "1470c2",
    "21694b",
    "0055FF",
    "FF00FF",
    "FFFF55",
    "00FF55",
    "333444",
    "666555",
    "999888",
    "CCCFFF",
    "FFDBE5",
    "7A4900",
    "0000A6",
    "63FFAC",
    "B79762",
    "004D43",
    "8FB0FF",
    "997D87",
    "5A0007",
    "809693",
    "FEFFE6",
    "1B4400",
    "4FC601",
    "3B5DFF",
    "4A3B53",
    "FF2F80",
    "61615A",
    "BA0900",
    "6B7900",
    "00C2A0",
    "FFAA92",
    "FF90C9",
    "B903AA",
    "D16100",
    "DDEFFF",
    "000035",
    "7B4F4B",
    "A1C299",
    "300018",
    "0AA6D8",
    "013349",
    "00846F",
    "372101",
    "FFB500",
    "C2FFED",
    "A079BF",
    "CC0744",
    "C0B9B2",
    "C2FF99",
    "001E09",
    "00489C",
    "6F0062",
    "0CBD66",
    "EEC3FF",
    "456D75",
    "B77B68",
    "7A87A1",
    "788D66",
    "885578",
    "FAD09F",
    "FF8A9A",
    "D157A0",
    "BEC459",
    "456648",
    "0086ED",
    "886F4C",

    "34362D",
    "B4A8BD",
    "00A6AA",
    "452C2C",
    "636375",
    "A3C8C9",
    "FF913F",
    "938A81",
    "575329",
    "00FECF",
    "B05B6F",
    "8CD0FF",
    "3B9700",
    "04F757",
    "C8A1A1",
    "1E6E00",
    "7900D7",
    "A77500",
    "6367A9",
    "A05837",
    "6B002C",
    "772600",
    "D790FF",
    "9B9700",
    "549E79",
    "FFF69F",
    "201625",
    "72418F",
    "BC23FF",
    "99ADC0",
    "3A2465",
    "922329",
    "5B4534",
    "FDE8DC",
    "404E55",
    "0089A3",
    "CB7E98",
    "A4E804",
    "324E72",
    "6A3A4C",
    "83AB58",
    "001C1E",
    "D1F7CE",
    "004B28",
    "C8D0F6",
    "A3A489",
    "806C66",
    "222800",
    "BF5650",
    "E83000",
    "66796D",
    "DA007C",
    "FF1A59",
    "8ADBB4",
    "1E0200",
    "5B4E51",
    "C895C5",
    "320033",
    "FF6832",
    "66E1D3",
    "CFCDAC",
    "D0AC94",
    "7ED379",
    "012C58",
    "000000",
  ],
  dows: [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ],
  preplanShownDuration: 0,
  orderFieldDuration: 0,
  regexEmail:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  regexMobile: /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/,
  // regexMobile: /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/
  regexNumber: /^[0-9\b]+$/,
  regexFloatNumber: /^-?\d+(\.\d+)?$/,
  regexPrice: /^-?\d+(,\d{3})*(\.\d{1,2})?$/,
  regexWeight: /^(\d*\.?\d+)\s?(\w+)/,
  regexName: /^([a-z0-9]+\s)*[a-z0-9]+$/, //   /^([a-z]+\s)*[a-z]+$/
  regexCode: /^[a-z\d\_\-]+$/i,
  regexUrl:
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
  dbColumns: [
    {
      "fe_column": "customer_city",
      "mapping_column": "City",
      "is_mandatory": true
    },
    {
      "fe_column": "customer_zipcode",
      "mapping_column": "Zip Code",
      "is_mandatory": true
    },
    {
      "fe_column": "customer_address_line",
      "mapping_column": "Address",
      "is_mandatory": true
    },
    {
      "fe_column": "levelOfService",
      "mapping_column": "Level of Service",
      "is_mandatory": true
    },
    {
      "fe_column": "customer_last_name",
      "mapping_column": "Last Name",
      "is_mandatory": false
    },
    {
      "fe_column": "end_time",
      "mapping_column": "End Time Slot",
      "is_mandatory": false
    },
    {
      "fe_column": "weight_uom",
      "mapping_column": "Weight UOM",
      "is_mandatory": false
    },
    {
      "fe_column": "customer_phone_one",
      "mapping_column": "Contact No 1",
      "is_mandatory": false
    },
    {
      "fe_column": "customer_order_number",
      "mapping_column": "Order Number",
      "is_mandatory": false
    },
    {
      "fe_column": "item_description",
      "mapping_column": "Description",
      "is_mandatory": false
    },
    {
      "fe_column": "customer_state",
      "mapping_column": "State",
      "is_mandatory": true
    },
    {
      "fe_column": "serial_number",
      "mapping_column": "Model Number",
      "is_mandatory": false
    },
    {
      "fe_column": "item_width",
      "mapping_column": "Width",
      "is_mandatory": false
    },
    {
      "fe_column": "start_time",
      "mapping_column": "Start Time Slot",
      "is_mandatory": false
    },
    {
      "fe_column": "dimensions_uom",
      "mapping_column": "Dimensions UOM",
      "is_mandatory": false
    },
    {
      "fe_column": "reference_1",
      "mapping_column": "Reference 1",
      "is_mandatory": false
    },
    {
      "fe_column": "scheduled_start_datetime",
      "mapping_column": "Appointment Date",
      "is_mandatory": false
    },
    {
      "fe_column": "type_of_order",
      "mapping_column": "Type of Order",
      "is_mandatory": true
    },
    {
      "fe_column": "item_model",
      "mapping_column": "Serial No",
      "is_mandatory": false
    },
    {
      "fe_column": "item_height",
      "mapping_column": "Height",
      "is_mandatory": false
    },
    {
      "fe_column": "weight",
      "mapping_column": "Weight",
      "is_mandatory": false
    },
    {
      "fe_column": "customer_first_name",
      "mapping_column": "First Name",
      "is_mandatory": true
    },
    {
      "fe_column": "customer_email",
      "mapping_column": "Email",
      "is_mandatory": false
    },
    {
      "fe_column": "quantity",
      "mapping_column": "Quantity",
      "is_mandatory": true
    },
    {
      "fe_column": "reference_1_type",
      "mapping_column": "Reference Type 1",
      "is_mandatory": false
    },
    {
      "fe_column": "item_name",
      "mapping_column": "Item Name",
      "is_mandatory": true
    },
    {
      "fe_column": "stops",
      "mapping_column": "Stop",
      "is_mandatory": true
    },
    {
      "fe_column": "item_length",
      "mapping_column": "Length",
      "is_mandatory": false
    },
    {
      "fe_column": "notes",
      "mapping_column": "Notes",
      "is_mandatory": false
    },
    {
      "fe_column": "account_code",
      "mapping_column": "Account Code",
      "is_mandatory": false
    },
    {
      "fe_column": "warehouse_code",
      "mapping_column": "Warehouse Code",
      "is_mandatory": false
    },
    {
      "fe_column": "move_identity",
      "mapping_column": "Move Identity",
      "is_mandatory": false
    },
    {
      "fe_column": "hawb",
      "mapping_column": "HAWB",
      "is_mandatory": false
    },
    {
      "fe_column": "mawb",
      "mapping_column": "MAWB",
      "is_mandatory": false
    },
    {
      "fe_column": "company_name",
      "mapping_column": "Company Name",
      "is_mandatory": false
    }
  ],
  termsList: [
    "Payment seven days after invoice date",
    "1% discount if payment received within ten days otherwise payment 30 days after invoice date",
    "Payment from the customer offset against the value of supplies purchased from the customer",
  ],
  routeStatus: [
    {
      key: "ALL",
      label: "-- All --",
      backend_count_key: "total_routes_count",
      backgroundColor: "rgb(54, 178, 238)",
      icon: "ordered-list",
    },
    {
      key: "ASSIGNED",
      label: I18n.t("status_labels.ASSIGNED"),
      backend_count_key: "assigned_count",
      backgroundColor: "rgb(214, 118, 96)",
      icon: "branches",
    },
    {
      key: "PREPARE",
      label: I18n.t("status_labels.PREPARE"),
      backend_count_key: "prepare_count",
      backgroundColor: "rgb(96, 122, 214)",
      icon: "bank",
    },
    {
      key: "DISPATCHED",
      label: I18n.t("status_labels.DISPATCHED"),
      backend_count_key: "dispatched_count",
      backgroundColor: "rgb(57, 84, 132)",
      icon: "schedule",
    },
    {
      key: "COMPLETED",
      label: I18n.t("status_labels.COMPLETED"),
      backend_count_key: "completed_count",
      backgroundColor: "rgb(98, 236, 134)",
      icon: "like",
    },
  ],
  billingStatus: [
    {
      key: I18n.t("invoices.ready_for_billing"),
      label: "Ready for billing",
      icon: "file-done",
      className: "textPending",
    },
    {
      key: "APPROVED",
      label: "Approved",
      icon: "like",
      className: "textReceived",
    },
    {
      key: "ON_HOLD",
      label: "On Hold",
      icon: "pause-circle",
      className: "textOnhold",
    },
    // {
    //   key: "PAYMENT_CONFIRMED",
    //   label: "Payment Received",
    //   icon: "dollar",
    //   className: "textCompleted",
    // },
    // {
    //   key: "EXCEPTION",
    //   label: "Exception",
    //   icon: "warning",
    //   className: "textExceptional",
    // },
  ],
  invoiceStatus: [
    {
      key: "PENDING",
      label: "Pending",
      icon: "file-done",
      className: "textPending",
    },
    {
      key: "APPROVED",
      label: "Approved",
      icon: "like",
      className: "textReceived",
    },
  ],
  pendingOrderStatus: [
    // {
    //   key: "ALL",
    //   label: "All",
    //   icon: "ordered-list",
    //   className: "textAll",
    // },
    {
      key: "PENDING",
      label: "Pending",
      icon: "file-done",
      className: "textPending",
    },
    // {
    //   key: "APPROVED",
    //   label: "Approved",
    //   icon: "like",
    //   className: "textReceived",
    // },
    {
      key: "REJECTED",
      label: "Rejected",
      icon: "close-circle",
      className: "textAssigned",
    },
  ],
  itemTypes: {
    DELIVERY: "Delivery",
    RETURN: "Return",
  },
  transferItemTypes: {
    RETURN: "Pickup",
    DELIVERY: "Delivery",
  },
  orderTypes: {
    D: {
      label: "Delivery",
      orderTypes: ["D"],
      is_location_based: false,
      types: [
        {
          type_of_order: "D",
          key: "drop_params",
          label: "Delivery",
          is_location_based: false,
        },
      ],
    },
    R: {
      label: "Return",
      orderTypes: ["R"],
      is_location_based: false,
      types: [
        {
          type_of_order: "R",
          key: "pickup_params",
          label: "Pickup",
          is_location_based: false,
        },
      ],
    },
    X: {
      label: "Exchange",
      orderTypes: ["R", "D"],
      is_location_based: false,
      types: [
        {
          type_of_order: "X",
          key: "exchange_params",
          label: "Order",
          is_location_based: false,
        },
      ],
    },
    M: {
      label: "Move",
      orderTypes: ["TR", "TD"],
      is_location_based: false,
      types: [
        {
          type_of_order: "TR",
          key: "pickup_params",
          label: "Pickup",
          is_location_based: false,
        },
        {
          type_of_order: "TD",
          key: "drop_params",
          label: "Delivery",
           is_location_based: false,
        },
      ],
    },
    // LH: {
    //   label: "Linehaul",
    //   orderTypes: ["LH"],
    //   is_location_based: true,
    //   types: [
    //     {
    //       type_of_order: "LH",
    //       key: "linehaul_params",
    //       label: "Linehaul",
    //       is_location_based: true,
    //     },
    //   ],
    // },
    T: {
      label: "Transfer",
      orderTypes: ["T"],
      is_location_based: true,
      types: [
        {
          type_of_order: "T",
          key: "transfer_params",
          label: "Transfer",
          is_location_based: true,
        },
      ],
    },
    LH: {
      label: "Linehaul",
      orderTypes: ["LH"],
      is_location_based: true,
      types: [
        {
          type_of_order: "LH",
          key: "transfer_params",
          label: "Linehaul",
          is_location_based: true,
        },
      ],
    },
    MS: {
      label: "Multistop",
      orderTypes: ["MS"],
      is_location_based: true,
      types: [
        {
          type_of_order: "MS",
          key: "transfer_params",
          label: "Multistop",
          is_location_based: true,
        },
      ],
    },

  },

  quoteOrderTypes: {
    D: {
      label: "Delivery",
      orderTypes: ["D"],
      is_location_based: false,
      types: [
        {
          type_of_order: "D",
          key: "drop_params",
          label: "Delivery",
          is_location_based: false,
        },
      ],
    },
    R: {
      label: "Return",
      orderTypes: ["R"],
      is_location_based: false,
      types: [
        {
          type_of_order: "R",
          key: "pickup_params",
          label: "Pickup",
          is_location_based: false,
        },
      ],
    },
    X: {
      label: "Exchange",
      orderTypes: ["R", "D"],
      is_location_based: false,
      types: [
        {
          type_of_order: "X",
          key: "exchange_params",
          label: "Order",
          is_location_based: false,
        },
      ],
    },
    M: {
      label: "Move",
      orderTypes: ["TR", "TD"],
      is_location_based: false,
      types: [
        {
          type_of_order: "TR",
          key: "pickup_params",
          label: "Pickup",
          is_location_based: false,
        },
        {
          type_of_order: "TD",
          key: "drop_params",
          label: "Delivery",
           is_location_based: false,
        },
      ],
    },
    T: {
      label: "Transfer",
      orderTypes: ["T"],
      is_location_based: true,
      types: [
        {
          type_of_order: "TR",
          key: "pickup_params",
          label: "Pickup",
          is_location_based: false,
        },
        {
          type_of_order: "TD",
          key: "drop_params",
          label: "Delivery",
           is_location_based: false,
        },
      ],
    },
    LH: {
      label: "Linehaul",
      orderTypes: ["LH"],
      is_location_based: true,
      types: [
        {
          type_of_order: "LH",
          key: "transfer_params",
          label: "Linehaul",
          is_location_based: true,
        },
      ],
    },

  },
  orderContactCustomer: {
    D: "SO Only",
    R: "SR Only",
    X: "SO, SR",
    TR: "TR Only",
    TD: "TD Only",
    T: "SO & SR",
    LH: "SO & SR",
  },
  orderStatusColors: {
    NEW: {
      className: "textPending",
      color: "ed5857",
    },
    RECEIVED: {
      className: "textReceived",
      color: "22698a",
    },
    VERIFIED: {
      className: "textVerified",
      color: "42a5f5", //"22698a",
    },
    PREPLAN: {
      className: "textPreplan",
      color: "22698a",
    },
    ASSIGNED: {
      className: "textAssigned",
      color: "395484",
    },
    DISPATCHED: {
      className: "textDispatched",
      color: "22698a",
    },
    EXCEPTION: {
      className: "textExceptional",
      color: "f5222d",
    },
    COMPLETED: {
      className: "textCompleted",
      color: "228a50",
    },
    ARRIVED: {
      className: "textInProgress",
      color: "ec8108",
    },
    INCOMPLETE: {
      className: "textInCompleted",
      color: "E3AAA6",
    },
    CANCELLED: {
      className: "textCancelled",
      color: "F9EEED", //"e8ecec",
      bgColor: "BA3E33",
    },
    UNALLOCATED: {
      className: "textUnallocated",
      color: "b91c1a", //"e8ecec",
      bgColor: "b91c1a",
    },
    ON_HOLD: {
      className: "textOnhold",
      color: "b91c1a", //"e8ecec",
      bgColor: "b91c1a",
    },
  },
  defaultPoint: [-97.472588, 30.9873867], // lng, lat
  nextSteps: {
    NEW: { readable: "RECEIVED", key: "RECEIVED", canChange: true },
    RECEIVED: { readable: "VERIFIED", key: "VERIFIED", canChange: true },
    PENDING: { readable: "ASSIGNED", key: "ASSIGNED", canChange: false },
    VERIFIED: { readable: "PREPLAN", key: "PREPLAN", canChange: false },
    PREPLAN: { readable: "ASSIGNED", key: "ASSIGNED", canChange: false },
    ASSIGNED: { readable: "PREPARE", key: "PREPARE", canChange: false },
    PREPARE: { readable: "DISPATCH", key: "DISPATCHED", canChange: false },
    DISPATCHED: { readable: "COMPLETE", key: "COMPLETED", canChange: false },
    PICKEDUP: { readable: "COMPLETE", key: "COMPLETED", canChange: false },
    COMPLETED: { readable: "", key: "", canChange: false },
    EXCEPTION: { readable: "", key: "", canChange: false },
    CANCELLED: { readable: "", key: "", canChange: false },
  },
  fileTypes: {
    image: [
      "jpg",
      "jpeg",
      "gif",
      "png",
      "PNG",
      "bmp",
      "BMP",
      "JPG",
      "JPEG",
      "tiff",
      "webp",
      "svg",
    ],
    pdf: ["pdf", "PDF"],
    docs: ["docx", "doc", "csv"],
    sheet: ["xlsx", "xls"],
    video: ["mp4", "avi", "flv", "swf"],
  },
  dimValues: [194, 200, 250,300],
  preferences: {
    ANY: {
      start_time: "08:00",
      end_time: "20:00",
    },
    M: {
      start_time: "08:00",
      end_time: "12:00",
    },
    A: {
      start_time: "12:00",
      end_time: "16:00",
    },
    E: {
      start_time: "16:00",
      end_time: "20:00",
    },
  },
  dispatcherDisabledStartHours: [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23],
  dispatcherDisabledEndHours: [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
  consigneeDisabledStartHours: [
    0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
  ],
  consigneeDisabledEndHours: [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23],
  dispatcherEndHour: 20,
  consigneeEndHour: 17,
  stopInProgressStatuses: [
    "ARRIVED",
    "INCOMPLETE",
    "EXCEPTION",
    "COMPLETED",
    "CANCELLED",
  ],
  sortingOptions: {
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  orderTypeShortForms: {
    X: "E",
    R: "P",
    D: "D",
    TD: "SD",
    TR: "SD",
    MD: "MD",
    MR: "MP",
    LH: "LH",
    M: "M",
    T: "T",
    MS: "MS"
  },
  orderTypeLabels: {
    X: "Exchange",
    R: "Pickup",
    D: "Delivery",
    P: "Pickup",
    TD: "Delivery",
    TR: "Pickup",
    MD: "Delivery",
    MR: "Pickup",
    LH: "Linehaul",
    M: "Move",
    T: "Transfer",
  },
  dependancyOrderRelatedType: {
    X: "X",
    P: "D",
    D: "P",
    TD: "TR",
    TR: "TD",
    T: "T",
    LH: "LH"
  },
  deliveryOrderTypes: ["D", "TD", "LH", "T"],
  pickOrderTypes: ["R", "TR", "LH", "T"],
  rangeMaxValue: 999999999,
  package_credits: ["order_limit", "wh_limit", "driver_limit"],
  fuelSurchargeTypes: {
    LINE_HAUL: "LINE HAUL",
    MILES: "MILES",
    FLAT: "FLAT",
    PERCENTAGE: "PERCENTAGE",
  },
  fuelSurchargeFrequencies: {
    WEEKLY: "WEEKLY",
    BIMONTHLY: "BI MONTHLY",
    MONTHLY: "MONTHLY",
  },
  dayNames: {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  },
  monthNames: {
    JAN: 0,
    FEB: 1,
    MAR: 2,
    APR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AUG: 7,
    SEP: 8,
    OCT: 9,
    NOV: 10,
    DEC: 11,
  },
  order_grouping_types: {
    WEEK: "Week",
    STOP: "Stop",
    //'ROUTE': 'Route',
    DAILY: "Daily",
    INDV: "Individual",
    GROUP: 'Custom Grouping'
  },
  dateFormat: "MMM DD, YYYY",
  default_service_duration: 20,
  default_address_type: 'COMMERCIAL',
  default_type_of_loc : "PICKUP",
  unallocated_color: "b91c1a", // 'ff0100',
  notificationModes: ["CONSIGNEE", "SHIPPER", "BOTH"],
  surface_area_in_units: "sq. in",
  accountSetting: [
    {
      name: I18n.t("general.config"),
      code: "account_configurations",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("menu.notification_templates"),
      code: "notification_templates",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("configurations.delivery_zipcodes_label"),
      code: "delivery_zipcodes",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("menu.accessorial"),
      code: "account_accessorials",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("los.label"),
      code: "los_matrix",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("adjustments.label"),
      code: "account_adjustments",
      value: true,
      dependencies: ["los_matrix"],
    },
    // {
    //   name: I18n.t("general.special_day_adjustment"),
    //   code: "specialday_adjustments",
    //   value: true,
    // },
    {
      name: I18n.t("menu.fuelSurcharge"),
      code: "fuel_surcharges",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("discounts.lable"),
      code: "discounts",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("driver_rates.label"),
      code: "driver_rate",
      value: true,
      dependencies: [],
    },
    // {
    //   name: I18n.t("order.reference_title"),
    //   code: "order_refrences",
    //   value: true,
    // },
    // {
    //   name: I18n.t("instructions.label"),
    //   code: "account_instructions",
    //   value: true,
    // },
    {
      name: I18n.t("menu.dim_config"),
      code: "dim_configurations",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("menu.contacts"),
      code: "contacts",
      value: true,
      dependencies: [],
    },
    {
      name: I18n.t("menu.contact_group"),
      code: "contact_groups",
      value: true,
      dependencies: ["contacts"],
    },
  ],
  quoteApptTypes: [
    {
      type: "ANY",
      label: "Standard",
    },
    {
      type: "CUSTOM",
      label: "Specific",
    },
  ],
  consolidatedMawbStats : [
    {
      key: "PENDING",
      value: "Pending",
      text: I18n.t('status_labels.PENDING'),
      icon : "exclamation-circle"
    },
    {
      key: "dispatched",
      value: "Dispatched",
      text: I18n.t("status_labels.DISPATCHED"),
      icon : "rocket"
    },
    {
      key: "COMPLETED",
      value: "Completed",
      text: I18n.t('status_labels.COMPLETED'),
      icon : "check-circle"
    },  
  ],
  recoveryStatuses: [
    { key: 'in_transit', value: 'in_transit', text: I18n.t('recoveries.in_transit') },
    { key: 'in_hand', value: 'in_hand', text: I18n.t('recoveries.in_hand') },
    { key: 'assigned', value: 'assigned', text: I18n.t('recoveries.assigned') },
    { key: 'completed', value: 'completed', text: I18n.t('recoveries.completed')},
    { key: 'exception', value: 'exception', text: I18n.t('recoveries.exception')}
  ],
  linehaulStatuses: [
    { key: 'pending', value: 'pending', text: I18n.t('status_labels.PENDING') },
    { key: 'assigned', value: 'assigned', text: I18n.t('status_labels.ASSIGNED') },
    { key: 'dispatched', value: 'dispatched', text: I18n.t('status_labels.DISPATCHED') },
    { key: 'completed', value: 'completed', text: I18n.t('status_labels.COMPLETED') },
  ],
  releaseStatuses: [
    { key: 'pending', value: 'pending', text: I18n.t('releases.pending') },
    { key: 'assigned', value: 'assigned', text: I18n.t('releases.assigned') },
    { key: 'completed', value: 'completed', text: I18n.t('releases.completed') },
    { key: 'exception', value: 'exception', text: I18n.t('releases.exception')}
  ],
  phonePrefix:["+", "+1", "+91", "1", "91"],
  uploadOrderFileTypes: ["csv", "excel", 'xml'],
  timeZones: [
    { name: "Eastern Time (US & Canada)", value: "America/New_York" },
    { name: "Central Time (US & Canada)", value: "America/Chicago" },
    { name: "Pacific Time (US & Canada)", value: "America/Los_Angeles" },
    { name: "Mountain Time (US & Canada)", value: "America/Denver" },
    { name: "UTC", value: "Etc/UTC" },
    { name: "IST", value: "Asia/Kolkata" },
  ],
  ORDER_TYPES: {
    D: {
      label: "Delivery",
      symbol: "D",
    }
    , R: {
      label: "Return",
      symbol: "R",
    },
    M : {
      label: "Move",
      symbol: "M",
    },
    // LH : {
    //   label: "Linehaul",
    //   symbol: "LH",
    // },
    T : {
      label: "Transfer",
      symbol: "T",
    },
    LH : {
      label: "Linehaul",
      symbol: "LH",
    },
  },
  orderTypeValues: {
      PICKUP: I18n.t("general.pickup"),
      DELIVERY: I18n.t("general.delivery"),
      BOTH: I18n.t("general.both"),
  },
  orderTypeZipcodes: {
    RESEDENTIAL: I18n.t("order.resedential_type"),
    COMMERCIAL: I18n.t("order.commercial_type"),
    BOTH: I18n.t("general.both"),
},
  support_email: "support@fleetenable.com",
  roles: {
    admin: 'Admin',
    driver: 'Driver',
    helper: 'Helper',
    super_admin: 'Super Admin',
    technician: 'Technician'
  },
  orderTypeColorMapping : {
    R: "#FF0000",
    D: "#0a7474", // purple "#8000FF",
    TD: "#0000FF",
    TR: "#0000FF",
    X: "#FF00FF",
    LH: "#D90BBD",
    M: "#0000FF",
    T: "#8000FF",
    MS: "#000000"
  },
  locTypes: {
    PICKUP: I18n.t("general.pickup"),
    DELIVERY: I18n.t("general.delivery"),
    //BOTH: I18n.t("general.both"),
  },
  locToOrder: {
    'PICKUP': 'pickup_params',
    'DELIVERY': 'drop_params',
    'pickup_params': 'PICKUP',
    'drop_params': 'DELIVERY',
  },
  timeWindow: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
  },
  units: {
   weight: 'Lbs',
   minutes: 'mins',
   hours: 'hrs',
   distance: 'mi',
   area: "sq. in",
   vol: "ft"
  },
  tooltipDelay: 1,
  clientId:  "e8c0a7055c769e84d96a3230480efc258a5624c1101e7b30f8eb666b81242871",
  clientSecret: "c0b3b76335eb06ea5d300a02be4dd8b32375877ba575620afec4dc79dec75a19",
  orderTypeKeysForFilter: {
    "D,TD": "Delivery",
    "R,TR": "Return",
    "T": "Transfer",
    "LH": "Linehaul",
  },
  orderTypesWithLocations: ['T', 'LH'],
  dateRanges: {
    "Last Month": [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    "Last Week": [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    "Last 7 Days": [moment().subtract(7, 'days').startOf('day'), moment().endOf('day') ],
    "Last 3 Days": [moment().subtract(2, 'day'),moment()],
    Yesterday: [moment().subtract(1, 'day'),moment().subtract(1, 'day')],
    Today: [moment(), moment()],
    "Tomorrow": [moment().add(1, 'day'), moment().add(1, 'day')],
    "Today & Tomorrow": [moment(), moment().add(1, "day")],
    "This Week": [moment().startOf("week"), moment().endOf("week")],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    'This Year': [moment().startOf('year'),  moment().endOf('year')],
  },

  monthlyCutoffRanges : {
    "Last 3 months": [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    "Last Month": [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    "Last Week": [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    "Last 7 Days": [moment().subtract(7, 'days').startOf('day'), moment().endOf('day') ],
    "Last 3 Days": [moment().subtract(2, 'day'),moment()],
    "Yesterday": [moment().subtract(1, 'day'),moment().subtract(1, 'day')],
    "Today": [moment(), moment()],
  },

  AccessCodes: [
    {
      OrderAccessCodes: [
        "COI",
        "OPSOS",
        "OPSPPS",
        "OPSBLS",
        "OPSAGRS",
        "AI",
        "OPSAS",
        "WHI",
        "OPSLS",
        "OPSARS",
        "ORGCON",
        "OPSOCS",
      ],
      preplanAccessCodes: ["OPSPPS", "OPSDS"],
      BillingAccessCodes: ["OPSBLS", "OPSINS", "OPSCLS"],
      AccountReceivableAccessCodes: ["OPSBS", "OPSMCS","OPSARS", "OPSOIS", "OPSCNS"],
      BillingReportAccessCodes: ["OPSAGRS", "OPSOBLS", "OPSPJS", "OPSSRS", "OPSCLRS", "OPSDLRS","OPSDN","OPSISR","OPSQB","OPSGLC"],
      DispatchReportAccessCodes :  ["OPSAGRS", "OPSOBLS", "OPSPJS", "OPSSRS", "OPSCLRS", "OPSDLRS", "PODRSR", "OPSCR"],
      AccountMenuAccessCodes : ["AI", "OPSAS","FI", "OPSFS","SRVYI", "OPSSS"],
      LocationsMenuAccessCodes : ["WHI", "OPSLS","PDSI", "OPSPDSS"],
      OrgSettingsMenuAccessCodes : ["ORGCON", "OPSOCS","OPSUS","OPSVTS","OPSRS","UI", "OPSDVS","FLTI", "OPSTS"],
      InsightsMenuAccessCodes : ["FEAI", "OPSINRS"],
      AIConverseCodes : [],
    },
  ],

  OrderstatusIcons : {
    "NEW": "diff",
    "RECEIVED": "plus-square",
    "ON_HOLD": "pause-circle",
    "VERIFIED": "check-circle",
    "ASSIGNED": "branches",
    "DISPATCHED": "schedule",
    "COMPLETED": "like",
    "CLOSED": "check-circle",
    "EXCEPTION": "warning"
  },

  OrderStatusClass : {
    "NEW": "textCompleted",
    "RECEIVED": "textReceived",
    "ON_HOLD": "textOnhold",
    "VERIFIED": "textVerified",
    "ASSIGNED": "textAssn",
    "DISPATCHED": "textAssigned",
    "COMPLETED": "textCompleted",
    "CLOSED": "textReceived",
    "EXCEPTION": "textExceptional"
  },
  shortForms: {
    PICKUP: 'P',
    DELIVERY: 'D'
  }
};


import React from "react";
import moment from "moment";
import _ from "lodash";
import { Icon, Row, Col } from "../../common/UIComponents";
import { Link } from "react-router-dom";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import { momentTime } from "../../helpers/date_functions";
import { formatByTimeConfig } from "../../helpers/common";

class OrderFiledActivities extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("general.table_s_no"),
        key: "index",
        render: (text, record, index) => index + 1,
        width: 55,
      },
      {
        key: "field",
        title: "Field",
        dataIndex: "field",
        render: (data, record) => <div>{data ? data.replaceAll("_", " ") : ''}</div>
      },
      {
        key: "from",
        title: I18n.t("general.from"),
        dataIndex: "from",
        render: (data, record) => <div>{data ? data: 'NA'}</div>
      },
      {
        key: "description",
        title: I18n.t("general.to"),
        dataIndex: "to",
        render: (data, record) => <div>{data ? data : ''}</div>
      }
    ];
  }
}

export default OrderFiledActivities;

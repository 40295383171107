import React, { Component } from "react";
import PropTypes from 'prop-types'
import "antd/dist/antd.css";
// import { Table } from "antd";
import { Table, Search } from "../common/UIComponents";

import { Resizable } from "react-resizable";
import AppConfig from "../config/AppConfig";
import { isEmpty } from "../common/Common";


const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

class BaseList extends Component {

  getMaxWidth = (record) => {
    const maxWidth = 400; // Set a maximum width to avoid column being too wide
    // const minWidth = record.key === 'index' ? 50 : 75; // Set a minimum width to avoid column being too narrow
    const minWidth = record.minWidth && !isEmpty(record.minWidth) ? record.minWidth : 75; //Set minimum width 
    const padding = 24; // Set the padding to match the table's default padding
    const actionsminWidth = [" Action Icons "].length
    // Finding l;ength of the each value in the column
    const columnDataLengths = record.key !== "actions" ? 
       ( this?.props?.data ? this.props.data.map((row) => row[record.dataIndex] ? (row[record.dataIndex]).toString().length : 0) : 75):
    actionsminWidth;     
    // Finding maximum character length with in the column
    let maxLength = Math.max(...columnDataLengths); // finding maximum of all lengths
    //const columnWidth = record.width ? record.width :maxWidth;
    const titleLength = record.title ? record.title.toString().length : '';
    // Set a width based on the maximum length of the characters
    maxLength = titleLength > maxLength ? titleLength : maxLength
    const width = Math.min(
      maxWidth,
      Math.max(minWidth, maxLength * 7 + padding)
    );

    return width;
  };
  
  handleResize = (index) => (e, { size }) => {
    const nextColumns = [...this.columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width
    };
    this.columns = nextColumns;
    this.forceUpdate(); // Force component to re-render to reflect the updated column width
  };

  render() {
    const {
      showSearch,
      searchPlaceholder,
      data= [],
      onSearch,
      rowKey,
      rowClassName,
      bordered,
      pagination,
      size,
      onRow,
      tableChange,
      rowSelection,
      expandedRowRender,
      onExpand,
      scroll,
      locale,
      footer,
      title,
      components,
      isLoading,
      ...rest
    } = this.props
   
    if (!data) {
      return null
    }
    let scollInfo = scroll ? scroll : {}
    if (data && data.length > 0 && scroll) {
      if (data.length > 0 && scroll.x) {
        let neWidth = 0;
        const newColumns = this.columns.map((column) => {
          const columnWidth = !isEmpty(column.width)
            ? column.width
            : this.getMaxWidth(column);
          neWidth = neWidth + columnWidth;
          return { ...column, width: columnWidth };
        });
        scollInfo.x = neWidth;
        this.columns = [...newColumns];
      }
      if (data.length > 0 && data.length < 5) {
        delete scollInfo.y;
      }
    }
    const columns = this.columns ?.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }));
    const localeObject = {
      emptyText: (
        <h4 className="alignCenter">
          No Records Found
        </h4>
      ),
      ...locale, 
    };

    return (
      <div>
        <div>
          {showSearch && (
            <Search
              placeholder={searchPlaceholder}
              onSearch={(value) => onSearch(value)}
              style={{ width: 200 }}
            />
          )}
        </div>
        <Table
          loading={isLoading ? isLoading : false}
          rowKey={rowKey ? rowKey : 'id'}
          bordered={bordered ? bordered : false}
          rowClassName={
            rowClassName
              ? rowClassName
              : (record, index) =>
                  !isEmpty(index) && ((index + 1) % 2) == 1 ? 'table-row-light' : 'table-row-dark'
          }
          dataSource={data}
          columns={this.columns}
          pagination={
            pagination.position !== 'none'
              ? Object.assign({}, pagination, {
                  pageSize: !isEmpty(pagination.pageSize)
                    ? pagination.pageSize
                    : AppConfig.perPage,
                })
              : {
                  position: 'none',
                  pageSize: data ? data.length : 20,
                }
          }
          size={size ? size : 'middle'}
          onRow={onRow}
          onChange={tableChange}
          rowSelection={
            rowSelection
              ? Object.assign({}, rowSelection, { order: 2 })
              : undefined
          }
          expandedRowRender={expandedRowRender && expandedRowRender}
          // expandedRowKeys={this.props.expandedRowKeys || []}
          onExpand={onExpand}
          scroll={scollInfo}
          // locale={locale ? locale : {}}
          locale={localeObject}
          footer={footer ? footer : null}
          title={title ? title : null}
          components={components ? components : {}}
          {...rest}
        />
      </div>
    );
  }
}


BaseList.propTypes = {
  rowKey: PropTypes.string,
  size: PropTypes.string,
  expandedRowKeys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.shape(),
  tableChange: PropTypes.func,
  showSearch: PropTypes.bool,
  onRow: PropTypes.func,
  footer: PropTypes.func,
  title: PropTypes.func,
  onSearch: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  rowClassName: PropTypes.string,
  bordered: PropTypes.bool,
  isLoading: PropTypes.bool,
  onExpand: PropTypes.func,
}

BaseList.defaultProps = {
  rowKey: 'id',
  size: 'middle',
  pagination: {},
  tableChange: null,
  showSearch: false,
  onSearch: null,
  onRow: null,
  searchPlaceholder: 'search',
  footer: null,
  title: null,
  rowClassName: '',
  bordered: false,
  isLoading: false,
  onExpand: () => {},
}

export default BaseList

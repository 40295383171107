import React from "react";
import moment from "moment";
import _ from "lodash";
import { Icon, Row, Col, Popover, Typography } from "../../common/UIComponents";
import { Link } from "react-router-dom";
import BaseList from "../BaseList";
import {  ellipseText } from "../../common/Common";
import { momentTime } from "../../helpers/date_functions";
import { formatByTimeConfig } from "../../helpers/common";
import DisplayTime from "../common/DisplayTime";
import AppConfig from "../../config/AppConfig";
import CopyableContent from "../common/CopyableContent";
import I18n from "../../common/I18n";

class InvoiceActivitiesList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }
  setColumns = (props) => {
    const columns = [
      {
        title: I18n.t("general.table_s_no"),
        key: "index",
        render: (text, record, index) => index + 1,
        width: 55,
      },
      {
        key: "activity_type",
        title: I18n.t("general.activity_type"),
        dataIndex: "activity_type",
        className: "textCaptilize",
        width: 150,
      },
      {
        key: "activity_by",
        title: I18n.t("general.activity_by"),
        dataIndex: "activity_by",
        className: "textCaptilize",
        width: 150,
      },
      {
        key: "activity_on",
        title: I18n.t("general.activity_on"),
        dataIndex: "activity_on",
        render: (data) =>
          data
          ? 
          <DisplayTime
          dateTimeString={data}
          timeFormat={AppConfig.dateTimeFormat}
          displayWithDate={true}
          isUTC={false}
          />
            : "",
        sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
        width: 200,
      },
      {
        key: "emails",
        title: I18n.t("general.emails"),
        dataIndex: "emails",
        render: (data, record) => {
          if (data?.length) {
            return (
              <Typography.Paragraph
                //ellipsis={{ rows: 1, expandable: true }}
                style={{ marginBottom: 0 }}
              >
                {/* {data.join(', ')} */}
                <Popover
                  content={<div style={{ maxWidth : 300}}>
                    {
                        data.map(rec => <p>{rec}</p>)
                    }
                  </div>}
                  title={I18n.t("general.emails")}
                >
                  {data[0]}{" "}
                  {`${data.length > 1 ? `, +${data.length - 1}` : ""}`}
                </Popover>
              </Typography.Paragraph>
            );
          } else {
            return "";
          }
        },
        width: 250,
      },
      {
        key: "remark",
        title: I18n.t("deductions.remark"),
        dataIndex: "remark",
        className: "textCaptilize",
        width: 200,
      },    ];
    this.columns = [...columns];
  };
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default InvoiceActivitiesList;

{
  /* <Popover
content={<CopyableContent text={data.join(", ")} />}
title={I18n.t("general.emails")}
>
{data[0]} {`${data.length > 1 ? `, +${data.length - 1}` : ""}`}
</Popover> */
}

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

const RCTimePicker = ({
  className,
  onChange,
  value,
  minuteStep,
  format,
  placeholder,
  showSecond,
  use12Hours = true,
  ...rest
}) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      document.getElementsByClassName("rc-time-picker-panel")[0].style.display =
        "none";
    }
  };

  return (
    <Fragment>
      <TimePicker
        {...rest}
        placeholder={placeholder}
        className={className}
        format={format}
        showSecond={showSecond}
        focusOnOpen={true}
        onChange={onChange}
        minuteStep={minuteStep}
        value={value}
        defaultOpenValue={!value ? moment().startOf("day") : value}
        use12Hours={use12Hours}
        onKeyDown={handleKeyDown}
      />
    </Fragment>
  );
};

RCTimePicker.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(moment),
};

export default RCTimePicker;

import _ from "lodash";
import moment from "moment"
import React, { Fragment } from "react";
import {
  Typography,
} from "../../common/UIComponents";
import { isEmpty } from "../../common/Common";
const { Text } = Typography;

export const getSlotInfo = (slotTime, isMilitaryTime, showNA = false)=> {
  const reqFormat = isMilitaryTime ? 'HH:mm' : 'hh:mm A';
  if(slotTime){
    const startTime = !isEmpty(slotTime.from_time) ? moment(slotTime.from_time, 'HH:mm').format(reqFormat) : (showNA ? 'NA' : '');
    const endTime = !isEmpty(slotTime.to_time )? moment(slotTime.to_time, 'HH:mm').format(reqFormat) : (showNA ? 'NA' : '');
   return <Fragment>{startTime} - {endTime}</Fragment>;
  }
  return ""
}


export const  retrieveParamValue = (
    component,
    orderType,
    param,
    currentConfiguration,
    zone_id,
    innerParamIndex,
    innerParam
  ) => {
    let currentRecord = {};
    if (!isEmpty(zone_id)) {
      if (isEmpty(currentConfiguration[component.code][orderType][zone_id])) {
        currentConfiguration[component.code][orderType][zone_id] = {};
      }
      currentRecord = currentConfiguration[component.code][orderType][zone_id];
    } else {
      currentRecord = currentConfiguration[component.code][orderType];
    }
    const currentDefaultValue = !isEmpty(param.default_value)
      ? param.default_value
      : "";
    let currentValue = "";
    if(!isEmpty(innerParam)){
      const innerObject = currentRecord[param.code][innerParamIndex] ? currentRecord[param.code][innerParamIndex] : null;
      if(innerObject){
        currentRecord = innerObject ? innerObject : {};
      }
      currentValue = innerObject ? innerObject[innerParam.code] : '';
      if(innerParam && (innerParam.data_type === 'time' || innerParam.data_type === 'date')){
        if(innerParam.data_type === 'time'){
          currentValue = !isEmpty(currentValue) ? moment(currentValue, "HH:mm") : null;
        } else if(innerParam.data_type === 'date'){ 
          currentValue = !isEmpty(currentValue) ? moment(currentValue, "YYYY-MM-DD") : null;
        }
      }
     

    } else{
      currentValue = !isEmpty(currentRecord[param.code])
      ? currentRecord[param.code]
      : "";
      if(param.data_type === 'time' || param.data_type === 'date'){
         currentValue = !isEmpty(currentValue) ? moment(currentValue, "HH:mm") : null;
      }
    }
   
    return { currentRecord, currentValue };
  }
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment,  } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  FormItem,
  Input,
  Card,
} from "../../common/UIComponents";
import {
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n';
import AddressForm from './AddressForm'
import MobileInput from './MobileInput'
import { checkServiceExistance } from '../../helpers/common'
import AddressSearch from '../../stories/components/AddressSearch';

const LocationForm = (props) => {
  const {
    formData = {},
    handleOnChange,
    map,
    editableFields = [],
    includePredefinedStops = false,
    predefined_locations,
    handleLocationChange,
    setAddress,
    request_from,
    twoRows = false,
    requiredFields = [
      "first_name",
      "contact_number_one",
      "address_line1",
      "city",
      "state",
      "country",
      "zipcode",
    ],
    isDisabled = false,
  } = props;

  const onConsigneeLocationChange = (value) => {
    const {
      first_name,
      last_name,
      name,
      email,
      phone,
      phone_number_one,
      phone_number_two,
      company_name,
      l_address,
      id,
      l_type,
    } = value
    handleLocationChange({
      first_name: !isEmpty(first_name)
        ? first_name
        : !isEmpty(name)
        ? name
        : '',
      last_name,
      email: email,
      phone_number_one: !isEmpty(phone_number_one)
        ? phone_number_one
        : !isEmpty(phone)
        ? phone
        : '',
      phone_number_two,
      company_name,
      l_address,
      id,
      l_type,
    })
    // handleOnAddressChange('', '', l_address)
  }

  const gridSize = twoRows ? 8 : 4;

  const handleOnAddressChange = (element, value, obj) => {
    const l_address = formData && formData.l_address ? formData.l_address : {}
    let newAddress = { ...l_address }
    if (obj) {
      newAddress = { ...l_address, ...obj }
    } else if (element) {
      newAddress[element] = value
    }
    handleOnChange('l_address', newAddress)
  }

  const renderForm = () => (
    <Fragment>
      {checkServiceExistance('PDSI') &&
        !isDisabled &&
        editableFields.includes('l_address') &&
        includePredefinedStops === true &&
        window.google &&
        map &&
        String(predefined_locations).toLowerCase() === 'true' && (
          <Row gutter={16}>
            <Col sm={24} xs={24} md={24} lg={24}>
              <FormItem
                label={`${I18n.t('general.location')} ${I18n.t(
                  'general.search',
                )}`}
              >
                <AddressSearch onSelect={ (address) => {
                  onConsigneeLocationChange(address)
                } } />
                <AddressForm
                  autoComplete={!isDisabled && editableFields.includes('l_address')}
                  includePredefinedStops={true}
                  request_from={request_from}
                  address={formData?.l_address ? formData.l_address : {}}
                  onChange={handleOnAddressChange}
                  onConsigneeLocationChange={onConsigneeLocationChange}
                  isAdmin
                  disabled={isDisabled || !editableFields.includes("l_address")}
                  twoRows={twoRows}
                  requiredFields={requiredFields}
                />
              </FormItem>
            </Col>
          </Row>
        )}
      <Row gutter={16}>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize}>
          <FormItem label={I18n.t("general.company_name")}>
            <Input
              value={formData.company_name}
              onChange={(e) => handleOnChange("company_name", e.target.value)}
              disabled={isDisabled}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize} span={4}>
          <FormItem
            label={I18n.t("general.first_name")}
            require={requiredFields.includes("first_name")}
          >
            <Input
              value={formData.first_name}
              onChange={(e) => handleOnChange("first_name", e.target.value)}
              disabled={isDisabled}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize}>
          <FormItem label={I18n.t("general.last_name")}>
            <Input
              value={formData.last_name}
              onChange={(e) => handleOnChange("last_name", e.target.value)}
              disabled={isDisabled}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize}>
          <FormItem label={I18n.t("general.email")}>
            <Input
              value={formData.email}
              onChange={(e) => handleOnChange("email", e.target.value)}
              disabled={isDisabled || !editableFields.includes("email")}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize}>
          <FormItem
            label={I18n.t("order.contact_number_one")}
            require={requiredFields.includes("contact_number_one")}
          >
            <MobileInput
              handleInputChange={(value, data) => {
                handleOnChange("phone_number_one", value, data);
              }}
              value={formData.phone_number_one ? formData.phone_number_one : ""}
              disabled={isDisabled || !editableFields.includes("phone_number_one")}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={gridSize} lg={gridSize}>
          <FormItem label={I18n.t("order.contact_number_two")}>
            <MobileInput
              handleInputChange={(value, data) => {
                handleOnChange("phone_number_two", value, data);
              }}
              value={formData.phone_number_two ? formData.phone_number_two : ""}
              disabled={isDisabled || !editableFields.includes("phone_number_two")}
            />
          </FormItem>
        </Col>
      </Row>
      {editableFields.includes("l_address") && window.google && map && (
        <Row>
          <Col xs={24} className="alignLeft">
            <Card
              size="small"
              bodyStyle={{ textAlign: 'left' }}
            >
              <AddressForm
                autoComplete={!isDisabled && editableFields.includes('l_address')}
                includePredefinedStops={false}
                address={formData?.l_address ? formData.l_address : {}}
                onChange={handleOnAddressChange}
                onConsigneeLocationChange={onConsigneeLocationChange}
                isAdmin
                disabled={isDisabled || !editableFields.includes("l_address")}
                twoRows={twoRows}
                request_from={request_from}
                requiredFields={requiredFields}
              />
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  )
  return <Fragment>{renderForm()}</Fragment>
}
export default LocationForm

LocationForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  handleOnAddressChange: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  predefined_locations: PropTypes.string,
  includePredefinedStops: PropTypes.bool,
  editableFields: PropTypes.array,
  onConsigneeLocationChange: PropTypes.func,
  map: PropTypes.object,
}

LocationForm.defaultProps = {
  predefined_locations: 'true',
}



import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import 'antd/dist/antd.css';
import { Col, Row, Spin, Alert, TextArea, Collapse, Radio, Input, Checkbox, Select, Button, Icon, Tooltip } from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import { fetchFormResponses } from '../../api/FormsApi';
import Gallery from '../common/Gallery';
import { renderAlertMessage } from '../../helpers/common';
import ReactToPrint from 'react-to-print';
import PODEmailsModal from '../Tasks/PODEmailsModal';

const tooltips = ['Very Dissatisfied', 'Dissatisfied', 'Neutral', 'Satisfied', 'Very Satisfied'];
const { Panel } = Collapse;
const singleValueFields = [ 'text', 'yes_no', 'textbox', 'email_field', 'phone_field', 'single_choice', 'signature' , 'populate' ];
class FormResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailProgress: false,
      emailSendModalVisible: false,
      inProgress: false,
      answers: {},
      questionaries: [],
      currentOrderDetails: {
        customer_first_name: '',
      },
      order_id: this.props.recordId ? this.props.recordId : '',
      location_id: this.props.locationId ? this.props.locationId : null ,
      galleryImages: [],
    };
  }

  componentDidMount() {
    this.getDetails(this.state.order_id,this.state.location_id);
  }

  getIsolationAnswers = (question, index, disabled = false) => {
    switch (question.option_type) {
    case 'text': return (
      <Row>
        <Col>
        <p style={{ marginRight: 4 }}>
            {this.state.answers[question.question_id] ? this.state.answers[question.question_id] : ''}
          </p>
        </Col>
      </Row>);
    case 'populate': return (
      <Row>
        <Col>
        <p style={{ marginRight: 4 }}>
            {this.state.answers[question.question_id] ? this.state.answers[question.question_id] : ''}
          </p>
        </Col>
      </Row>);
    case 'textbox': return (
      <Row>
        <Col>
        <p style={{ marginRight: 4 }}>
            {this.state.answers[question.question_id] ? this.state.answers[question.question_id] : ''}
          </p>
        </Col>
      </Row>);
    case 'single_choice': return (
      <Row>
        <Radio.Group
          value={this.state.answers[question.question_id] || ''}

        >
          {
            question.options.map((qoption, qindex) => (
              <Col xs={24} sm={24} md={8} lg={8} key={qindex}>
                <Radio value={qoption.option_id} >{qoption.option_value}</Radio>
              </Col>))
          }
        </Radio.Group>
      </Row>);
    case 'multiple_choice': return (
      <Row>
        <Checkbox.Group
          value={this.state.answers[question.question_id]}

        >
          {
            question.options.map((qoption, qindex) => (
              <Col xs={24} sm={24} md={8} lg={8} key={qindex}>
                <Checkbox
                  value={qoption.option_id}
                /> {qoption.option_value}&nbsp;&nbsp;
              </Col>))
          }
        </Checkbox.Group>
      </Row>);
    case 'dropdown': return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={6}>
          <Select
            showSearch
            value={this.state.answers[question.question_id]}
            style={{ width: '100%' }}
            placeholder="Select"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={e => this.handleChange(question.question_id, e)}

          >
            {
              question.options.map((qoption, qindex) => (
                <Select.Option key={qoption.option_id} value={qoption.option_id}>{qoption.opt_value}</Select.Option>
              ))
            }
          </Select>
        </Col>
      </Row>);
    case 'multi_select_dropdown': return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Select
            mode="multiple"
            value={this.state.answers[question.question_id]}
            showSearch
            style={{ width: '100%' }}
            placeholder="Select"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={e => this.handleChange(question.question_id, e)}

          >
            {
              question.options.map(qoption => (
                <Select.Option key={qoption.id} value={qoption.id}>{qoption.opt_value}</Select.Option>
              ))
            }
          </Select>
        </Col>
      </Row>);
      case 'signature': return (
        <Row>
          <Col xs={ 24 }>
            <img
              // prefix src with base64
              src={ `data:image/png;base64,${this.state.answers[ question.question_id ]}` }
              alt="Signature"
              width="500px"
              // onClick={ () => this.openLightbox(question.question_id) }
            />
          </Col>
        </Row>);
    default: break;
    }
  }

  getDetails = (id, loc_id = null) => {
    this.setState({ inProgress: true });
    fetchFormResponses(id, loc_id)
      .then((result) => {
        if (result.success) {
          const answers = Object.assign({}, this.state.answers);
          let answer = '';
          if (result.form_details) {
            const form_questions = result.form_details && result.form_details.order_responses ? result.form_details.order_responses : [];
            const questions = [];
            form_questions.forEach((question) => {
              const options = [];
              if (singleValueFields.includes(question.option_type)) {
                answer = question.responses.length > 0 ? question.responses[0] : '';
              } else {
                answer = question.responses;
              }
              answers[question.question_id] = answer;
              question.options.forEach((option, index) => {
                if (option) {
                  options.push(option);
                }
              });

              questions.push({
                question_id: question.question_id,
                content: question.content,
                option_type: question.option_type,
                options,
                answer,

              });
            });
            this.setState({
              questionaries: questions,
              answers,
              inProgress: false,
            }, () => {
            });
          } else {
            alertMessage('Form not submitted', 'error', 10);
            this.setState({ inProgress: false });
          }
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    });
  }

  // eslint-disable-next-line react/sort-comp
  openLightbox = (question_id) => {
    this.setState({
      galleryImages: [{ src: this.state.answers[question_id] }],
      lightboxIsOpen: true,
    }, () => {
    });
  }

  setRef = ref => (this.componentRef = ref);

  openSendEmailModal = () => {
    this.setState({
      emailSendModalVisible: true,
    });
  }

  closeSendEmailModal = () => {
    this.setState({
      emailSendModalVisible: false,
    });
  }

  sendFormToEmail = (emailList, description) => {
    this.setState({ emailProgress: true });

    setTimeout(() => {
      this.setState({ emailProgress: false });
      // console.log("🚀 ~ FormResponse ~ description:", description)
      // console.log("🚀 ~ FormResponse ~ emailList:", emailList)
      this.closeSendEmailModal();
    }, 5000);

  }

  render() {
    const { currentOrderDetails } = this.state;
    const item_name = currentOrderDetails.item_name ? currentOrderDetails.item_name : 'Item';
    const customer_name = currentOrderDetails.customer_first_name ? currentOrderDetails.customer_first_name + (currentOrderDetails.customer_last_name ? ` ${currentOrderDetails.customer_last_name}` : '') : I18n.t('general.customer');

    return (
      <div className='content-outer'>
        <div className='content surveyResponses'>
          <Spin spinning={this.state.inProgress} delay={1000}>

            <Fragment>
              {this.state.questionaries.length > 0 ?
                <div>
                  <Row
                    style={{ marginBottom: "1rem" }}
                    type="flex"
                    justify="center"
                    gutter={10}
                  >
                    <Col>
                      <ReactToPrint
                        trigger={() => (
                          <Button type="primary" icon="printer" size={"small"}>
                            Print
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />
                    </Col>
                    {/* <Col>
                      <Button
                        type="primary"
                        icon="mail"
                        size={"small"}
                        onClick={this.openSendEmailModal}
                      >
                        Send To Email
                      </Button>
                    </Col> */}
                  </Row>

                  <Row ref={this.setRef}>
                    <Col className="questionaries">
                      {
                        this.state.questionaries.map((question, index) => {
                      if (question.option_type === 'SIGNATURE') {
                            // image base64
                            return (
                              <Row key={`question${index}`}>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                  <h4>{`${index + 1}. ${question.content}`}</h4>
                                </Col>
                                <Col xs={24}>
                                  <img
                                    src={this.state.answers[question.question_id]}
                                    alt="Signature"
                                    width="500px"
                                    onClick={() => this.openLightbox(question.question_id)}
                                  />
                                </Col>
                              </Row>
                            );
                          }
                          return (
                            <Collapse
                        defaultActiveKey={['1']}
                        style={{ height: 'auto', marginBottom: 20 }}
                              accordion={false}
                              className="marginBottom20"
                              key={`collapse${index}`}
                            >
                              <Panel
                                showArrow={false}
                                header={`${index + 1}. ${question.content}`}
                                key={1}
                              >
                          {
                            this.getIsolationAnswers(question, index)
                          }
                              </Panel>

                            </Collapse>
                      )
                    })
                  }

                    </Col>
                  </Row>
                </div>
                :
              <Alert message="Form not submitted" type="warning" />
              }
            </Fragment>

          </Spin>

        </div>
        {
          this.state.lightboxIsOpen && this.state.galleryImages.length > 0 && <Gallery
            images={this.state.galleryImages}
            onClose={this.closeLightbox}
          />
        }
        {
          this.state.emailSendModalVisible &&
          <PODEmailsModal
            title={'Form Email'}
            description={'Please find the attached form'}
            closeModal={this.closeSendEmailModal}
            onSend={this.sendFormToEmail}
            muteButton={this.state.emailProgress}
          />
        }

      </div >
    );
  }
}

export default FormResponse;

import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Typography, Icon, Alert, message } from 'antd';
import BGimage from "../../../assets/images/bg2.png";
import { withRouter} from 'react-router';
import { AuthenticationService } from '../../api/Auth';
import AppConfig from '../../config/AppConfig';
import I18n from '../../common/I18n';
import { CompanyLogo } from '../common/LogisticsLoadingScreen';

const { Item } = Form;
const { Title } = Typography;


const AuthForm = ({ form, history , setIsAuthenticated , resetPasswordToken }) => {
  const [ operation, setOperation ] = useState('LOG_IN');
  const [ rememberMe, setRememberMe ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const backgroundImageStyle = {
    backgroundImage: `url(${BGimage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const formStyle = {
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '400px',
    width: '100%',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
  };

  useEffect(() => {
    if (resetPasswordToken && resetPasswordToken.length) {
      setOperation('NEW_PASSWORD');
    }
  }, []);

  useEffect(() => {
    if (operation === "VALIDATE_OTP") {
      setOperation("FORGOT_PASSWORD");
    }
  }, [form.getFieldValue('email')]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setIsLoading(true);
        AuthenticationService.login(values.email, values.password).then((response) => {
          if (response.success) {
            localStorage.setItem("auth", JSON.stringify(response.data));
            message.success(I18n.t("auth_messages.login_success"));
            history.push('/');
          } else {
            message.error(response.error || I18n.t("auth_messages.error_msg"));
          }
        }).finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const handleOtpValidate = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setIsLoading(true);
        AuthenticationService.validateOtp(values.email, values.phoneOtp)
          .then((response) => {
            if (response.success) {
              message.success(I18n.t("auth_messages.otp_verified"));
              window.location.assign(`${AppConfig.baseUrl}/forgot_password?reset_password_token=${response.resetToken}`);
            } else {
              message.error(response.error || I18n.t("auth_messages.error_msg"));
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };


  const handleForgotPassword = () => {
    setOperation('FORGOT_PASSWORD');
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      const numberPattern = AppConfig.regexMobile;///^\+?[1-9]\d{1,14}$/;
      if (!err) {
        setIsLoading(true);
        if (typeof values.email === 'string' && numberPattern.test(values.email)) {
          AuthenticationService.sendOtp(values.email)
            .then((response) => {
              if (response.success) {
                message.success(response.sms_msg || I18n.t("auth_messages.otp_sent"));
                setOperation("VALIDATE_OTP");
              } else {
                message.error(response.error || I18n.t("auth_messages.error_msg"));
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
        AuthenticationService.forgotPassword(values.email).then((response) => {
          if (response.success) {
            message.success(I18n.t("auth_messages.reset_email"));
            setOperation('LOG_IN');
          } else {
            message.error(response.error || I18n.t("auth_messages.error_msg"));
          }
        }
        ).finally(() => {
          setIsLoading(false);
        }
        );
        }
      }
    });
  };

  const { getFieldDecorator } = form;

  const handlePasswordChange = (e) => {
    e.preventDefault();
    // check if password and confirm password are same
    form.validateFields((err, values) => {
      if (!err) {
        if(values.password === values.confirmpassword){
          setIsLoading(true);
          AuthenticationService.changePassword({
            token : resetPasswordToken,
            password : values.password,
            password_confirmation : values.confirmpassword
          }).then((response) => {
            if (response.success) {
              message.success(I18n.t("auth_messages.password_changed"));
              history.push('/dashboard');
            } else {
              message.error(response.error || I18n.t("auth_messages.error_msg"));
            }
          }).finally(() => {
            setIsLoading(false);
          });
        }else{
          message.error(I18n.t("auth_messages.reset_password_match"));
        }
      }
    });
  };

  return (
    <div style={ backgroundImageStyle }>
      <div style={ formStyle }>
        {/* use a image to put the logo svg */ }
        <div style={{ textAlign: 'center'}}>
            <CompanyLogo style={{ width: '65%', height: 'auto', margin: 25 }} />
          </div>

        <Title level={ 3 } style={ { display: 'block', textAlign: 'center', marginBottom: '20px' } }>
          { operation === 'LOG_IN' ? 'Login' : operation === 'FORGOT_PASSWORD' ? 'Forgot password' : operation === 'VALIDATE_OTP' ? 'Recovery password' :'Create new password' }
        </Title>

        <Form onSubmit={ operation === 'FORGOT_PASSWORD' ? handleForgotPasswordSubmit : operation === "NEW_PASSWORD" ? handlePasswordChange : operation === 'VALIDATE_OTP' ? handleOtpValidate : handleSubmit } className="login-form">
          { operation !== 'NEW_PASSWORD' && (
            <Item label={I18n.t("auth_messages.username_lable")}>
              { getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: I18n.t("auth_messages.email_phone"),
                  },
                  {
                    pattern: /^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})|(\d{10})$/,
                    message: I18n.t("auth_messages.username_validation_msg"),
                  },
                ],
                validateTrigger: 'onBlur'
              })(<Input autoFocus autoComplete="off" placeholder={I18n.t("auth_messages.username_placeholder")} />) }
            </Item>
          ) }

          { operation === 'VALIDATE_OTP' && (
            <Item label="Enter OTP">
            {getFieldDecorator('phoneOtp', {
              rules: [
                {
                  required: true,
                  message: 'Please input your OTP!',
                }
              ],
              validateTrigger: 'onBlur',
            })(<Input autoFocus autoComplete="off" placeholder={I18n.t("auth_messages.otp_placeholder")} />)}
          </Item>
          )}
          
          { !['FORGOT_PASSWORD', 'VALIDATE_OTP'].includes(operation) && (
            <>
              <Item label={ operation === 'NEW_PASSWORD' ? 'New Password' : 'Password' }>
                { getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: I18n.t("auth_messages.password_validation_msg"),
                    },
                    {
                      min: 6,
                      message: 'Password must be at least 6 characters long!',
                    },
                  ],
                  validateTrigger: 'onBlur'
                })(<Input.Password autoComplete="off" />) }
              </Item>
              { operation === 'NEW_PASSWORD' && (
                <Item label="Confirm Password">
                  { getFieldDecorator('confirmpassword', {
                    rules: [
                      {
                        required: true,
                        message: I18n.t("auth_messages.password_validation_msg"),
                      },
                      {
                        min: 6,
                        message: I18n.t("auth_messages.password_length"),
                      },
                    ],
                  })(<Input.Password autoComplete="off" />) }
                </Item>
              )}
              {/* <Item name="remember_me" valuePropName="checked">
                <Checkbox onChange={ (e) => setRememberMe(e.target.checked) }>
                  Remember me
                </Checkbox>
              </Item> */}
            </>
          ) }
          <Item>
            <Button type="primary" htmlType="submit" block loading={ isLoading }>
              { operation === 'LOG_IN' ? 'Submit' : operation === 'FORGOT_PASSWORD' ? 'Send reset instruction' : operation === 'VALIDATE_OTP' ? 'Validate OTP' : 'Save' }
            </Button>
          </Item>
          <Item>
            {/* back to login with a logo back */ }
            { ((operation !== 'LOG_IN') && (operation !== "NEW_PASSWORD")) && (
              <Button type="link" onClick={ () => setOperation('LOG_IN') }>
                <Icon type="arrow-left" /> {I18n.t("auth_messages.back")}
              </Button>
            ) }
          </Item>
          { operation === 'LOG_IN' && (
            <Item style={ { textAlign: 'center' } }>
              <Button type="link" onClick={ handleForgotPassword } disabled={ isLoading }>
                {I18n.t("auth_messages.forgot_password")}
              </Button>
            </Item>
          ) }
        </Form>
      </div>
    </div>
  );
};

const WrappedAuthForm = Form.create({ name: 'auth_form' })(AuthForm);

export default withRouter(WrappedAuthForm);


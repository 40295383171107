import AppConfig from "../config/AppConfig";

export const handleCurrentRole = (userRoles) => {
  const mobileRoles = AppConfig.mobileRoles.map(role => role.role_code);

  if (userRoles && userRoles.length > 0) {
    const webRoles = userRoles.filter(role => !mobileRoles.includes(role));
    if (userRoles.includes('super_admin')) {
      return 'super_admin';
    } else if (userRoles.includes('admin')) {
      return 'admin';
    } else if (webRoles.length) {
      return webRoles[0];
    } else if (userRoles.includes('driver')) {
      return 'driver';
    } else {
      return userRoles[ 0 ];
    }
  } else {
    return 'customer';
  }
};

export const handleSelectedOrg = (org) => {
  localStorage.setItem('selectedOrg', org.id);
  return org;
};
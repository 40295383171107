import I18n from "../common/I18n";

const defaultBolDataMapping = {
  VEHICLE_NO: I18n.t("BillOfRating.vehicle_no"),
  DATE: I18n.t("BillOfRating.date"),
  BRANCH: I18n.t("BillOfRating.branch"),
  FROM: I18n.t("BillOfRating.from"),
  BILL_TO: I18n.t("BillOfRating.bill_to"),
  SHIP_TO: I18n.t("BillOfRating.ship_to"),
  DESCRIPTION: I18n.t("BillOfRating.description"),
  WEIGHT: I18n.t("BillOfRating.weight"),
  INSTRUCTIONS: I18n.t("BillOfRating.instructions"),
  OTHER: I18n.t("BillOfRating.other"),
  HOURS: I18n.t("BillOfRating.hours"),
  RIGGING: I18n.t("BillOfRating.rigging"),
  SHIPPER: I18n.t("BillOfRating.shipper"),
  PRINT_NAME: I18n.t("BillOfRating.print_name"),
  TITLE: I18n.t("BillOfRating.title"),
  SIGNATURE: I18n.t("BillOfRating.signature"),
  SIGNATURE_DATE: I18n.t("BillOfRating.date"),
  HAWB : "HAWB",
  ORDER_LABEL : "Order",
  CUSTOMER_PHONE: "CUSTOMER PHONE",
  CUSTOMER_REFERENCE : "CUSTOMER REFERENCE",
  PU_PERSON_NAME: "PU PERSON NAME",
  RECEIVER_NAME : "RECEIVER NAME",
  DRIVER_NAME : "DRIVER NAME",
  PU_PERSON_SIGN: "PU PERSON SIGN",
  PU_TIME: "PU TIME",
  RECEIVER_SIGN: "RECEIVER SIGN",
  RECEIVED_TIME: "RECEIVED TIME",
  DRIVER_SIGN: "DRIVER SIGN",
  DRIVER_DROP_TIME: "DRIVER DROP TIME",
};


const lykesDataMapping = {
  ...defaultBolDataMapping,
  VEHICLE_NO: "VEHICLE NO lykes",
};

const orgToBolDataMapping = {
  '602a8070822dd6ce12a49435': lykesDataMapping,
};


export const fetchBolLabels = (organization_id ) => {
  const bolDataMapping = orgToBolDataMapping[ organization_id ] || defaultBolDataMapping;

  return bolDataMapping;
};

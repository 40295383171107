import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  Spin,
  Tooltip,
} from "../../common/UIComponents";
import { printBillingPdf } from "../../api/Billing";
import { alertMessage,  base64ToPdf } from "../../common/Common";
import I18n from "../../common/I18n";
import moment from "moment";
import { BillingConsolidationApi } from "../../api/BilliingConsolidationApi";
import userStore from "../../stores/UserStore";
import Consolidate from "./Consolidate";
import BaseModal from "../BaseModal";
import consolidatedIcon from "../../../assets/images/consolidate.png";
import { withRouter } from "react-router-dom";

const ConsolidationButton = ({
  className,
  buttonType,
  size,
  label,
  isDisabled,
  orders = [],
  displayType = "manage",
  consolidate_order_id = "",
  consolidation_no = "",
  handleSuccess = () => {},
  styleObj = {},
  history = {},
}) => {
  const orgId = userStore.getStateValue("selectedOrg");
  const [isLoading, setIsLoading] = useState(false);
  const [consolidatedOrders, setConsolidatedOrders] = useState(orders ? [...orders] : []);
  const [showModal, setShowModal] = useState(false);
  const [consolidatedAccount, setConsolidatedAccount] = useState(null);

  const validateOrders = () => {
    setIsLoading(true);
    const data = {
      organization_id: orgId,
      customer_order_ids: orders.map((order) => order.customer_order_id),
    };
    BillingConsolidationApi.verifyOrdersToConsolidate(data)
      .then((response) => {
        
        if (response.success) {
          history.push({
            pathname: "/consolidation/"+response.consolidation_number,
            orders: response.consolidated_orders ? response.consolidated_orders : [],
            account: response.account ? response.account : null,
            consolidate_no:response.consolidation_number
          });
          // setConsolidatedOrders(
          //   response.consolidated_orders ? response.consolidated_orders : []
          // );
          // setConsolidatedAccount(response.account ? response.account : null);
          //setShowModal(true);
        } else {
          Modal["error"]({
            title: "Errors",
            content: (
              <Fragment>
                <div>
                  <h4>{response.errors.join(",")}</h4>
                </div>
              </Fragment>
            ),
            width: "40%",
            onOk: () => {},
            okText: "Ok",
            cancelText: "No",
            onCancel: () => {},
          });

          // setErrors(response.errors);
        }
        // handle success response
      })
      .catch((error) => {
        console.error("failed to update linehaul", error);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const consolidatedWindow = () => {
    history.push({
      pathname: "/consolidation/"+consolidation_no,
      //orders: consolidatedOrders ? consolidatedOrders : [],
      account: null,
      consolidate_no: consolidation_no,
      consolidate_order_id,
    });
  }

  const closeWindow = () => {
    setShowModal(false);
    setConsolidatedOrders([]);
    handleSuccess();
  };

  const renderWindow = () => {
    // const account = orders?.length > 0 ? {
    //     id: orders[0].account_id,
    //     name: orders[0].account_name,
    //     code: orders[0].account_code,
    // } : {}
    const account = consolidatedAccount ? consolidatedAccount : {};
    return (
      <BaseModal
        title={`${I18n.t("consolidation.label")}`}
        width="90%"
        style={{
          top: 20,
          height: "90%",
        }}
        // bodyStyle={{
        //   height: "100%",
        //   overflowY: "auto",
        // }}
        bodyStyle={{
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          padding: 10
        }}
        visible={showModal}
        onCancel={() => closeWindow()}
      >
        <Spin spinning={isLoading}>
          <Consolidate
            orders={consolidatedOrders}
            showHeader={false}
            account={account}
            onCancel={() => closeWindow()}
            consolidate_order_id={consolidate_order_id}
          />
        </Spin>
      </BaseModal>
    );
  };

  return (
    <Fragment>
      {displayType === "manage" ? (
        <Button
          onClick={() => validateOrders()}
          type={buttonType ? buttonType : "primary"}
          // icon="printer"
          className={className ? className : "buttonCerulean"}
          loading={isLoading}
          disabled={isDisabled}
          size={size ? size : "default"}
        >
          <img
            src={consolidatedIcon}
            style={{ width: 13,height:12, ...styleObj, 
              filter: 'invert(100%)',}}
            onClick={() => setShowModal(true)}
            className="cursorPointer"
          />
          {label}
        </Button>
      ) : (
        <Tooltip title={label}  getPopupContainer={ (triggerNode) => triggerNode }>
          <img
            src={consolidatedIcon}
            style={{ width: 15, ...styleObj }}
            onClick={() => {
              consolidatedWindow()
              // setShowModal(true)
            }}
            className="cursorPointer"
          />
        </Tooltip>
      )}
      {showModal && renderWindow()}
    </Fragment>
  );
};
export default withRouter(ConsolidationButton);

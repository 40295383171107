/* eslint-disable camelcase */
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { Tooltip,Tag } from "../../common/UIComponents";
import { getOrderTypeLabel } from "../../helpers/orders";
import AppConfig from "../../config/AppConfig";
import { isEmpty } from "../../common/Common";
import { OrderConfigContext } from "../../context/OrderConfigContext";

const TypeOfOrder = ({
  order,
  orderTypeKey,
  relatedOrderKey,
  showTooltip = true,
  placement = "rightTop",
  showBadge = false,
  triggerNodePosition = true,
  isInAggrid = false,
}) => {
  const orderType = getOrderTypeLabel(order, orderTypeKey, relatedOrderKey);
  const { orderConfig = {
    orderTypeLabels: {}
  }} = useContext(OrderConfigContext);

  // Exit early if there is no orderType
  if (!orderType) {
    return null;
  }

  // Define common properties for Tooltip
  const commonTooltipProps = {
    title: orderConfig.orderTypeLabels[ order[ orderTypeKey ] ],
    placement,
  };

  if (triggerNodePosition && !isInAggrid) {
    commonTooltipProps.getPopupContainer = (triggerNode) => triggerNode.parentElement.parentElement;
  }

  // Define the content to be shown, with or without Tooltip
  const content = showTooltip && !isEmpty(order[ orderTypeKey ]) ? (
    <Tooltip { ...commonTooltipProps } overlayStyle={ { minWidth: 70 } } overlayClassName="tooltip_point">
      { orderType }
    </Tooltip>
  ) : (
    orderType
  );

  // Define the Tag if showBadge is true
  const tag = showBadge ? (
    <Tag
      color={ AppConfig.orderTypeColorMapping[ order[ orderTypeKey ] ] }
      style={ !isInAggrid ? {
        padding: "0 2px",
        fontWeight: "bold",
        fontSize: 9,
        height: 20,
        width: 20,
        textAlign: "center",
        margin: 0,
      } : {} }
    >
      { content }
    </Tag>
  ) : null;
  return <>{ showBadge ? tag : content }</>;
};

export default TypeOfOrder;

TypeOfOrder.propTypes = {
  order: PropTypes.shape().isRequired,
  orderTypeKey: PropTypes.string.isRequired,
  relatedOrderKey: PropTypes.string.isRequired,
};

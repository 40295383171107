/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import {
  Button,
  Row,
  Col,
  Radio,
  FormItem,
  Drawer,
  Spin,
  Collapse
} from '../../common/UIComponents'
import {alertMessage, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import { cloneOrders, fetchOrderDetails } from '../../api/OrdersApi';
import BaseModal from '../BaseModal'
import FormButtons from '../common/FormButtons'
import {
  checkEndTime,
  getDisabledHoursFromStartTime,
} from '../../helpers/orders'
import FormErrors from '../common/FormErrors'
import { getHMSFromTimeString } from '../../helpers/date_functions'
import ClonedOrderList from './ClonedOrdersList'
import EditOrderForm from './EditOrderForm';
import Appointments from '../common/Appointments';

const { Panel } = Collapse;
const defaultValue = {
    appt_date: moment(moment().startOf('day')),
    slots: [],
    confirmed: false,
    start_time: null,
    end_time: null,
    has_expedite: false,
}
class CloneOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: this.props.orders || [],
      selectedOrders: this.props.selectedOrders || [],
      inProgress: false,
      ordersProgress: false,
      showForm: false,
      preference: {
        ...defaultValue,
      },
      organizationSettings: {},
      isMilitaryTime: false,
      errorOrderNos: [],
      errors: [],
      screenName: this.props.screenName || [],
      appointment_type: 'EXISTING',
      cloned_orders: [],
      editForm: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.organizationSettings,
        this.props.organizationSettings,
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == 'true',
      })
    }
    if( !_.isEqual(prevProps.selectedOrders, this.props.selectedOrders)){
      this.setState({ selectedOrders : this.props.selectedOrders});
    }
    if(prevProps.isCloneClicked !== this.props.isCloneClicked && this.props.isCloneClicked ) {
      this.enableForm()
    }
  }

  componentDidMount() {
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == 'true',
    })
  }

  handleEditOrder = (id) => {
    fetchOrderDetails(id).then((result) => {
      if (result.success) {
        const orderIndex = _.findIndex(result.orders, [ 'id', id ]);
        const currentOrder = Object.assign({}, result.orders[ orderIndex ]);
        this.setState(
          {
            orderObject: currentOrder,
          },
          () => {
            this.setState({ editForm: true });
          },
        );
      } else {
        alertMessage(result.errors[ 0 ], 'error', 10);
        this.setState({
          orderObject,
          loading: false,
        });
      }
    });
  }

  handleStateChange = (element, value) => {
    this.setState({
      [element]: value,
    })
  }

  enableForm = () => {
    this.setState({
      showForm: true,
    })
  }
  
  disabelForm = () => {
    this.setState(
      {
        showForm: false,
        preference: { ...defaultValue },
        errors: [],
        orderErrors: [],
        errorOrderNos: [],
        appointment_type: 'EXISTING',
        inProgress: false,
        orders: [],
      },
      () => {
        // this.props.resetOrders();
        if(this.props.isFromBulkContainer){
          this.props.setIsCloneClicked(false)
          this.props.setIsHovered(null);
        }
      },
    )
  }

  closeClonedOrders = () => {
    this.setState(
      {
        showClonedOrders: false,
        cloned_orders: [],
        appointment_type: 'EXISTING',
        inProgress: false,
        // orders: [],
      },
      () => {
        this.props.resetOrders();
      },
    )
  }

  saveScheduling = (data = {}) => {
    this.setState({
      inProgress: true,
    })
    data.order_ids = this.state.orders || []
    data.appointment_type = this.state.appointment_type
    cloneOrders(data).then((result) => {
      // result.success = false;
      // result.errors = ["11111111111: false"];
      if (result.success) {
        alertMessage(I18n.t('messages.successfully_msg', {process: 'Cloning'}))
        this.setState(
          {
            preference: { ...defaultValue },
            orderErrors: [],
            errors: [],
            errorOrderNos: [],
            showForm: false,
            showClonedOrders: true,
            cloned_orders: [...result.orders],
            inProgress: false,
          },
          () => {
            // this.props.resetOrders()
            this.props.handleOrderclonedStatus(true)
            //this.props.resetSelection()
          },
        )
      } else {
        if (result.errors.length > 0) {
          const errorValue = result.errors[0]
          if (_.isString(errorValue)) {
            this.setState({
              errors: result.errors || [],
            })
          } else if (_.isObject(errorValue)) {
            const errors = []
            const errorOrderNos = []
            result.errors.forEach((errObj) => {
              if (_.isObject(errObj) && errObj.order_number) {
                errorOrderNos.push(errObj.order_number)
                errors.push(errObj)
              }
            })
            this.setState({
              errorOrderNos,
              inProgress: false,
              errors,
              showErrors: errors.length > 0,
            })
            // alertMessage('One or more required fields are missing', 'error', 10);
          }
        }
        this.setState({ inProgress: false })
      }
    })
  }

  setAppointmentSchedule = () => {
    const { preference, orders } = this.state
    const formErrors = []
    if (!preference.appt_date) {
      formErrors.push(' appointment date')
    }
    if (preference.slots.length === 0) {
      formErrors.push(' slots ');
    }
    else if (preference.slots.includes('CUSTOM')) {
      // if not moment
      if (!moment(preference.start_time).isValid() || !moment(preference.end_time).isValid()
        || moment(preference.start_time).isSameOrAfter(preference.end_time)) {
        formErrors.push(' valid start time and end time');
      }
    }
    if (formErrors.length === 0) {
      const { slots } = preference
      const data = {
        appointment: {
          appt_date: moment(preference.appt_date).format(
            'YYYY-MM-DD 00:00:00',
          ),
          slots,
          confirmed: true, // preference.confirmed === true,
          // start_time: preference.start_time,
          // end_time: preference.end_time, 
          start_time: _.isArray(slots) && slots.includes('CUSTOM') ? moment(preference.start_time).format('HH:mm') : preference.start_time,
          end_time : _.isArray(slots) && slots.includes('CUSTOM') ? moment(preference.end_time).format('HH:mm') : preference.end_time,
          has_expedite: preference.has_expedite === true,
        },
        has_expedite: preference.has_expedite === true,
      }
      this.saveScheduling(data)
    } else {
      alertMessage(`Please select ${formErrors.join(',')}`, 'error', 5)
      this.setState({
        inProgress: false,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      orders: nextProps.orders || [],
    })
  }

  handleItemOptionOnChange = (index, element, value, checked, type) => {
    const { preference } = this.state
    const optionIndex = preference[element].indexOf(value)
    if (optionIndex >= 0) {
      if (value !== 'CUSTOM') {
        preference.start_time = null
        preference.end_time = null
      }
      if (checked) {
        if (type === 'radio' || ['ANY', 'CUSTOM'].includes(value)) {
          preference[element] = [value]
        } else {
          // Assuming getting A, M , E
          preference[element] = preference[element].filter(
            (ele) => !['ANY', 'CUSTOM'].includes(ele),
          )
          preference[element].push(value)
        }
      } else {
        if (value === 'CUSTOM') {
          preference.start_time = null
          preference.end_time = null
        }
        preference[element].splice(optionIndex, 1)
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (value !== 'CUSTOM') {
        preference.start_time = null
        preference.end_time = null
      }
      if (type === 'radio' || ['ANY', 'CUSTOM'].includes(value)) {
        preference[element] = [value]
      } else {
        // Assuming getting A, M , E
        preference[element] = preference[element].filter(
          (ele) => !['ANY', 'CUSTOM'].includes(ele),
        )
        preference[element].push(value)
      }
    }
    if (
      preference[element].includes('M') &&
      preference[element].includes('A') &&
      preference[element].includes('E')
    ) {
      preference[element] = ['ANY']
    }
    this.setState({ preference })
  }

  _renerAppointmentWindow = () => {
    const { organizationSettings, appointment_type } = this.state
    const dispatcherDisabledStartHours = getDisabledHoursFromStartTime(
      organizationSettings.route_delivery_start_time,
      organizationSettings.route_delivery_end_time,
      false,
    )
    const dispatcherDisabledEndHours = getDisabledHoursFromStartTime(
      organizationSettings.route_delivery_start_time,
      organizationSettings.route_delivery_end_time,
      false,
    )
    const endTimes = getHMSFromTimeString(
      organizationSettings.route_delivery_end_time,
      'HH:mm',
      null,
      null,
    )
    const startTimes = getHMSFromTimeString(
      organizationSettings.route_delivery_start_time,
      'HH:mm',
      null,
      null,
    )
    const currentWarehouse = typeof this.props.warehouseFilter.selectedWarehouses === 'string' ? _.find(this.props.warehouseFilter.warehouses, { id: this.props.warehouseFilter.selectedWarehouses }) : _.find(this.props.warehouseFilter.warehouses, { id: this.props.warehouseFilter.selectedWarehouses[ 0 ] });
    const selectedOrders = this.state.selectedOrders.map((order) => order.order_number).join(", ")
    return (
      <Fragment>
        <BaseModal
          title={this.props.showSelectedOrder ? I18n.t('order.schedule_an_appointment') : `${I18n.t('order.schedule_an_appointment')} of #${selectedOrders}`}
          onCancel={this.disabelForm}
          // width="550px"
          width={ 600 }
          maskClosable={false}
        >
          <Spin spinning={this.state.inProgress}>
          <div>
            <FormItem label="Clone the order(s) with">
              <Radio.Group
                onChange={(e) =>
                  this.handleStateChange('appointment_type', e.target.value)
                }
                value={appointment_type}
                style={{ marginTop: 3 }}
              >
                <Radio value="NONE">None</Radio>
                <Radio value="EXISTING">Appointment as per previous order</Radio>
                <Radio value="NEW">New Appointment</Radio>
              </Radio.Group>
            </FormItem>
            {appointment_type === 'NEW' ? (
              // <OpinionForm
              //   record={this.state.preference}
              //   itemOnChange={(index, element, value) =>
              //     this.handleItemOnChange(index, element, value)
              //   }
              //   itemOptionOnChange={(index, element, value, checked, type) =>
              //     this.handleItemOptionOnChange(
              //       index,
              //       element,
              //       value,
              //       checked,
              //       type,
              //     )
              //   }
              //   confirmChange={(index, element, value) =>
              //     this.handleConfirmChange(index, element, value)
              //   }
              //   clearPreference={(index) => this.clearPreference(index)}
              //   isDisabled={false}
              //   feasibleDow={[]}
              //   feasibleDates={[]}
              //   deliveryZipcodes={false}
              //   showClear={false}
              //   disabledStartHours={dispatcherDisabledStartHours}
              //   disabledEndHours={dispatcherDisabledEndHours}
              //   endHour={endTimes.hourValue}
              //   endMinute={endTimes.minuteValue}
              //   startHour={startTimes.hourValue}
              //   startMinute={startTimes.minuteValue}
              //   optingType={'radio'}
              //   showExpedite
              //   isMilitaryTime={this.state.isMilitaryTime}
              // />
            <Appointments
              slots={Array.isArray(currentWarehouse.slot_details) ? currentWarehouse.slot_details : []}
              feasible_dates={ [] }
              isMilitaryTime={ this.state.isMilitaryTime }
              hasDeliveryZipcodesConfig={ false }
              onSave={ () => {
                if (appointment_type === 'NEW') {
                  this.setAppointmentSchedule();
                } else {
                  this.saveScheduling();
                }
              } }
              onSlotsChange={ (selectedSlot, isCustom) => {
                let selectedSlots = isCustom ? [ 'CUSTOM' ] : [ selectedSlot ];
                const [ start_time, end_time ] = selectedSlot.split("-");
                if (selectedSlots[ 0 ] === '08:00-20:00') {
                  selectedSlots = [ 'ANY' ];
                }
                this.setState({
                  preference: {
                    ...this.state.preference,
                    slots: selectedSlots,
                    start_time: isCustom ? null : start_time,
                    end_time: isCustom ? null : end_time,
                  }
                });
              } }
              // onSlotsChange={ (slots, isCustom) => {
              //   if (slots.length > 0 || isCustom) {
              //     this.setState({
              //       preference: {
              //         ...this.state.preference,
              //         slots :isCustom ? [ 'CUSTOM' ] : slots[ 0 ].start_time === '08:00' && slots[ 0 ].end_time === '20:00' ? [ 'ANY' ] : slots.map((slot) => `${slot.start_time}-${slot.end_time}`),
              //         start_time: isCustom ? null : slots[ 0 ].start_time,
              //         end_time: isCustom ? null : slots[ slots.length - 1 ].end_time,
              //       }
              //     });
              //   }
              // } }
              clearPreference={ (index) =>
              {
                this.setState({
                  preference: {
                    s_no: 1,
                    item_preference: moment(moment().startOf('day')),
                    appt_date: moment(moment().startOf('day')),
                    slots: [],
                    has_expedite: false,
                    start_time: '',
                    end_time: '',
                  }
                });
              }
              }
              itemOnChange={ (index, element, value) => {
                let modifiedPreferences = {...this.state.preference};
                if(element === 'slots') {
                  if(Array.isArray(value) && value.length <= 0) {
                    return false;
                  }
                  const isCustom = Array.isArray(value) && value.length > 0 && value[ 0 ] === 'CUSTOM';
                  if (isCustom) {
                    modifiedPreferences = {
                      ...modifiedPreferences,
                      slots: value,
                      start_time: null,
                      end_time: null,
                    }
                  }
                  else { 
                    const [ start_time, end_time ] = value[ 0 ].split("-");
                    modifiedPreferences = {
                      ...modifiedPreferences,
                      slots: value,
                      start_time,
                      end_time,
                    }
                  }
                }else {
                  modifiedPreferences = { ...modifiedPreferences, [ element ]: value };
                }
                this.setState({
                  preference: modifiedPreferences,
                });
              } }
              // itemOnChange={ (index, element, value) => {
              //   this.setState({
              //     preference: {
              //       ...this.state.preference,
              //       [ element ]: value,
              //     }
              //   });
              // } }
              data={
                {
                  preferences: [ {
                    ...this.state.preference,
                  } ],
                  endHour: endTimes.hourValue,
                  endMinute: endTimes.minuteValue,
                  startHour: startTimes.hourValue,
                  startMinute: startTimes.minuteValue,
                  disabledStartHours: dispatcherDisabledStartHours,
                  disabledEndHours: dispatcherDisabledEndHours,
                  showExpedite: true,
                }
              }
              selectedOrderRows={this.state.selectedOrders}
              showSelectedOrder={this.props.showSelectedOrder}
            />
            )
            :
            <Fragment>
            <Row>
              {this.props.showSelectedOrder && (<Col span={ 24 } className='selecetdOrderPanel'>
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    className="marginTop10"
                  >
                    <Panel
                      header="Selected Orders"
                      key="1"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ maxHeight: 70, overflowY: "auto" }}>
                        {this.state.selectedOrders
                          .map((order) => order.customer_order_number)
                          .join(", ")}
                      </p>
                    </Panel>
                  </Collapse>
              </Col>)}
            </Row>
            <Row>
              <Col className="marginTop20 schedule">
                {FormButtons(
                  this.state.inProgress,
                  appointment_type === 'NEW'
                    ? () => {
                        this.setAppointmentSchedule()
                      }
                    : () => {
                        this.saveScheduling()
                      },
                  this.clearPreference,
                  false,
                  true,
                  'default',
                  I18n.t('general.save'),
                  I18n.t('general.clear'),
                )}
              </Col>
            </Row>
            </Fragment>
            }
            {!this.state.showErrors && this.state.errors.length > 0 && (
              <Row>
                <Col xs={24} className="marginTop10">
                  {FormErrors(this.state.errors)}
                </Col>
              </Row>
            )}
          </div>
          </Spin>
        </BaseModal>
      </Fragment>
    )
  }

   _renerClonedOrders = () => {
    return (
      <Fragment>
        <BaseModal
          title={`${I18n.t('order.cloned_order')}s`}
          onCancel={this.closeClonedOrders}
          width="520px"
        >
          <div>
            <ClonedOrderList
             data={this.state.cloned_orders}
             pagination={{position: 'none'}}
              handleEditOrder={ this.handleEditOrder }
            />
          </div>
        </BaseModal>
      </Fragment>
    )
  }

  handleItemOnChange = (index, element, value) => {
    const { hourValue, minuteValue } = getHMSFromTimeString(
      this.state.organizationSettings.route_delivery_end_time,
      'HH:mm',
      null,
      null,
    )
    const dispatcherEndHour = hourValue
    const dispatcherEndMinute = minuteValue
    const { preference } = this.state
    if (element === 'start_time' || element === 'end_time') {
      if (value !== null) {
        // For from_date,  to_date
        let hasErrors = false
        const currentPreference = { ...preference }
        if (element === 'start_time') {
          const startHour = moment(value).hour()
          if (startHour > dispatcherEndHour) {
            value = moment(value)
              .set('hour', dispatcherEndHour - 1)
              .set('minute', 0)
          }
          preference.end_time = checkEndTime(
            currentPreference,
            value,
            dispatcherEndHour,
            dispatcherEndHour,
          )
        }
        if (element === 'end_time') {
          const endHour = moment(value).hour()
          if (endHour > dispatcherEndHour) {
            value = moment(value)
              .set('hour', dispatcherEndHour)
              .set('minute', 0)
          }
          if (!isEmpty(currentPreference.start_time)) {
            const isBefore = value.isBefore(currentPreference.start_time)
            if (isBefore) {
              hasErrors = true
              alertMessage(
                I18n.t('messages.from_time_exceeds_to_time'),
                'error',
                5,
              )
            }
          }
        }
        if (!hasErrors) {
          preference[element] = value
          preference.item_option = ['CUSTOM']
          // if (element === 'start_time' && isEmpty(preference.end_time)) {
          //   preference.end_time = moment(value).add(120, 'minutes');
          // }
          this.setState({ preference })
        }
      } else {
        preference[element] = value
        if (isEmpty(preference.start_time) && isEmpty(preference.end_time)) {
          preference.item_option = []
        }
        this.setState({ preference })
      }
    } else {
      preference[element] = value
      this.setState({ preference })
    }
  }

  handleConfirmChange = (index, element, value) => {
    const { preference } = this.state
    if (value === true) {
      preference.confirmed = true
    } else {
      preference.confirmed = false
    }
    this.setState({ preference })
  }

  clearPreference = () => {
    this.setState({
      preference: { ...defaultValue },
    })
  }

  closeErrors = () => {
    this.setState({
      orderErrors: [],
      showErrors: false,
    })
  }

  renderStatusErrors = () => (
    <BaseModal
      title={I18n.t('errors.errors')}
      placement="right"
      closable
      onCancel={this.closeErrors}
      visible={this.state.showErrors}
      width="50%"
      style={{ top: 35 }}
    >
      {this.state.errors.map((err) => (
        <Row>
          <Col xs={4}>{err.order_number}</Col>
          <Col xs={20}>{_.isArray(err.errors) ? err.errors.join(',') : ''}</Col>
        </Row>
      ))}
    </BaseModal>
  )
  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ editForm: false , orderObject: {} });
  };

  render() {
    const { showIcon } = this.state
    return (
      <Fragment>
        {this.props.isFromBulkContainer ? I18n.t('order.clone') : showIcon ? (
          <Icon type="copy" theme="twoTone" />
        ) : (
          <Button
            type="primary"
            onClick={() => this.enableForm()}
            style={{ marginTop: '2px', marginLeft: 10, marginRight: 10 }}
            className="buttonTeal"
            icon="copy"
            size={this.props.size || 'default'}
          >
            {I18n.t('order.clone')}
          </Button>
        )}
        {this.state.showForm && this._renerAppointmentWindow()}
        {this.state.showClonedOrders && this._renerClonedOrders()}
        {this.state.showErrors && this.renderStatusErrors()}
        { this.state.editForm && (
          <Drawer
            placement="right"
            closable={ false }
            maskClosable={ false }
            onClose={ () => this.setState({ editForm: false }) }
            visible={ this.state.editForm }
            width="90%"
          >
            <EditOrderForm
              customerDetails={ this.state.orderObject }
              customerOrderId={ this.state.orderObject.id }
              preferences={ [] }
              onCancel={ () => this.setState({ editForm: false }) }
              handleSuccess={ this.handleSaveSuccess }
              isNew={false}
            />
          </Drawer>
        ) }
      </Fragment>
    )
  }
}

CloneOrders.propTypes = {
  resetOrders: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.string).isRequired,
  filter: PropTypes.string.isRequired,
  handleOrderclonedStatus: PropTypes.func,
  showSelectedOrder : PropTypes.bool,
  selectedOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFromBulkContainer: PropTypes.bool,
  isCloneClicked: PropTypes.bool,
  setIsCloneClicked: PropTypes.func,
  setIsHovered: PropTypes.func
}
CloneOrders.defaultProps = {
  handleEditOrder: () => {},
  handleOrderclonedStatus: () => { },
  showSelectedOrder: false,
  selectedOrders:[],
  isFromBulkContainer: false,
  isCloneClicked: false,
  setIsCloneClicked: () => {},
  setIsHovered: () => {}
};
export default CloneOrders;

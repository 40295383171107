/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../common/I18n';
import { TextArea, Button, Typography } from '../../common/UIComponents';

const ReasonWindow = ({
  reasonElement, reasonValue = '', handleOnChange, closeReasonModal, 
  saveReason, showButtons = true, showCancel= true,inProgress=false
}) => (
  <div>
    <Fragment>
      <TextArea
        rows={4}
        placeholder={I18n.t("general.reason_placeholder")}
        className='sop-form-textArea'
        value={reasonValue}
        onChange={e => handleOnChange(reasonElement, e.target.value)}
      />
      <div style={{ textAlign: 'center' }}>
        {
          showButtons !== false && 
          <Fragment>
          {
            showCancel && 
            <Button onClick={closeReasonModal} icon="close" style={{ width: '100px',height: '40px'}}>
              {I18n.t('general.close')}
            </Button>
          }
          <Button
            onClick={saveReason}
            disabled={reasonValue.length < 10}
            loading={inProgress}
            className="assignDriver"
            style={{ margin: 10,width: '100px',height: '40px', }}
            type="primary"
            icon="save"
          >
            {I18n.t('general.save')}
          </Button>
          </Fragment>
        }
        
        {false && <Typography.Paragraph className="notes_content">
          <b>
            <span className="textRed">Note</span>
          </b> :
          Reason should be minimum of 10 characters.
        </Typography.Paragraph>
        }
      </div>
    </Fragment>
  </div>
);


export default ReasonWindow;

ReasonWindow.propTypes = {
  reasonValue: PropTypes.string.isRequired,
  reasonElement: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  saveReason: PropTypes.func.isRequired,
  closeReasonModal: PropTypes.func,
};

ReasonWindow.defaultProps = {
  closeReasonModal: null,
};


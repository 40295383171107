import React, { useEffect, useState } from "react";
import moment from "moment";
import MaterialTimePicker from "./MaterialTimePicker";
import { fromTimeDisabledHours, fromTimeDisabledMinutes, toTimeDisabledHours, toTimeDisabledMinutes } from "./helpers";
import _ from "lodash"
function AppointmentPicker (props) {
  const { endHour, endMinute, startHour, startMinute, data, itemChange, disabledStartHours, dataIndex, property, disabledEndHours ,isMilitaryTime , isDisabled = false  } = props;
                      
  const [ disabledHours, setDisabledHours ] = useState([]);

  useEffect(() => {
    if (_.isObject(data)) {
    if (property === "start_time") {
      setDisabledHours(fromTimeDisabledHours(data, disabledStartHours));
    }
    else if (property === "end_time") {
      setDisabledHours(toTimeDisabledHours(data, disabledEndHours));
    }}
  }, [data, disabledStartHours, disabledEndHours, property]);


  return (
    <MaterialTimePicker
      onChange={ (e) => itemChange(dataIndex, property, e) }
      value={ data[ property ] ? moment(data[ property ]) : null }
      disabled={isDisabled} 
      computeDisabledHours={ () => disabledHours }
      computeDisabledMinutes={ (pickedTime) => {
        const selectedHour = pickedTime ? pickedTime.hour() : 0;
        if (property === "start_time") {
          return fromTimeDisabledMinutes(data, selectedHour, endHour, endMinute, startHour, startMinute);
        } else {
          return toTimeDisabledMinutes(data, selectedHour, endHour, endMinute, startHour, startMinute);
        }
      } }
      isMilitaryTime={ isMilitaryTime }
    />
  );
}

export default AppointmentPicker;
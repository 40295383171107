import React, { Fragment } from "react";
import { Icon, Row, Col } from "../../common/UIComponents";

const Errors = (errors, type = 'error') => {
  const color = type === 'error' ? 'FF2D78' : '13ae5c'
  return (
  <Row
    className="custom-notification"
    type="flex"
    style={{ alignItems: "stretch", minHeight: 40 , border : `1px solid #${color}`, margin: '10px 0px'}}
  >
    <Col xs={2} style={{ backgroundColor: `#${color}` }} className="center">
      <Icon style={{ fontSize: 18, color: "white" }} type={type === 'error' ? "warning" : 'check-circle'} />
    </Col>
    <Col xs={22} className="padding5 paddingLeft15 fontSize13 fontWeight600 blkCenterLeft">
      {errors.map((err) => (
        <div key={err}>{err}</div>
      ))}
    </Col>
  </Row>
)};

const ErrorComponent = ({ errors, type = "error" }) => {
  const formErrors = errors || [];
  if (formErrors.length < 1) return null;
  return Errors(formErrors, type);
};

export default ErrorComponent;

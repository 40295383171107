/* eslint-disable no-console */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import _ from 'lodash';
import { AutoComplete } from 'antd';
import PropTypes from 'prop-types';
import I18n from '../../common/I18n';
import { Col, FormItem, Input, Row } from '../../common/UIComponents';
import { formatAddress, isEmpty } from '../../common/Common';
import AppConfig from '../../config/AppConfig';
import { fetchLocationsByName } from '../../api/PredefinedStops';

class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address,
      autoAddress: '',
      disabled: props.disabled,
      consigneeLocations: [],
      requiredFields: props.requiredFields
        ? props.requiredFields
        : [
            "address_line1",
            "city",
            "state",
            "country",
            "zipcode",
          ],
    };
    this.getSuggestions = _.debounce(this.getSuggestions, 500);
  }

  renderAddressLabel = attr => `general.${attr}`;
  handleSelect = () => {

  };

  getAddress = (obj) => {
    const address = obj.description;
    const formattedAddr = formatAddress(address);
    return formattedAddr;
  }

  componentWillMount() {
    this.getInitAddress();
  }


  componentWillReceiveProps(nextProps) {
    this.setState({ address: nextProps.address, disabled: nextProps.disabled });
    if(this.props.isAddressReset !== nextProps.isAddressReset) {
      if(nextProps.isAddressReset) {
        this.setState({ autoAddress: '' });
      }
    }
    if(this.props.requiredFields !== nextProps.requiredFields) {
      this.setState({ requiredFields: nextProps.requiredFields ? nextProps.requiredFields : [ "address_line1", "city", "state", "country", "zipcode", ] }); 
    }
  }

  onSelect = (id, suggestions) => {
    const address = suggestions.filter(x => x.placeId === id);
    if (address) {
      const obj = this.getAddress(address[0]);
      obj.zipcode = '';
      geocodeByAddress(address[0].description)
        .then(results => getLatLng(results[0]))
        .then((latLng) => {
          fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${latLng.lat},${latLng.lng}&key=${AppConfig.mapKey}`)
            .then(response => response.json())
            .then((responseJson) => {
              const response = responseJson.results[0].address_components;
              const newData = _.find(response, { types: ['postal_code'] }); 
              const postalCode = newData ? newData.long_name : '';
              obj.zipcode = !isEmpty(postalCode) ? postalCode : '';
              obj.coordinates =  [latLng.lng, latLng.lat]
              // this.handleOnAddressChange('', '', obj);
            }).finally(() => {
              this.handleOnAddressChange('', '', obj);
            })
          // this.handleOnAddressChange('', '', obj);
        })

      // this.handleOnAddressChange('', '', obj);
    }
  }
  getSuggestions = (value) => {
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => {})
      .catch(error => console.error('Error', error));
  }
  handleChange = (autoAddress) => {
    this.setState({ autoAddress });
  };
  getInitAddress = () => {
    this.setState({ address: this.props.address });
  }

  handleOnAddressChange(element, value, obj) {
    this.props.onChange(element, value, obj);
  }

  // predefined 
  onConsigneeLocationSelect = value => {
    const { onConsigneeLocationChange } = this.props;
    typeof onConsigneeLocationChange === 'function' && onConsigneeLocationChange(JSON.parse(value));
  }

  onConsigneeLocationTextChange = async value => {
    try {
      if (value.trim() === '') {
        this.setState({
          consigneeLocations: []
        });
        return;
      }
      const requestFrom = !isEmpty(this.props.request_from) ? this.props.request_from : '';
      const response = await fetchLocationsByName({ name: value, requestFrom: 'recovery'});

      if (response.success) {
        const { predefined_stops } = response;

        this.setState({
          consigneeLocations: predefined_stops.predefined_stops
        });
      } else {
        throw new Error(response.errors[0]);
      }



    } catch (error) {
    }
  }


  render() {
    const { address, disabled, requiredFields } = this.state;
    // const gridSize = this.props.twoRows ? 8 : (this.props.threeRows ? 12 : 4);
    const includePredefinedStops = this.props.includePredefinedStops;

    // gridsize responsive
    const gridSize =  this.props.gridSize ? this.props.gridSize : {
      xs: 24,
      sm: 24,
      md: 12,
      lg: 8,
      xl: 8,
    }
    const size = this.props.size ? this.props.size : "default"
    const FormItemEle = this.props?.FormItem ? this.props?.FormItem : FormItem;
    return (
      <div>
        {/* {
          this.props.autoComplete ?
            <Row>
              {
                includePredefinedStops ? (
                  <Col xs={24} className="marginRight10">
                    <AutoComplete
                      style={{ width: '100%' }}
                      placeholder="Search locations by name (or) location code (or) city..."
                      onSelect={this.onConsigneeLocationSelect}
                      onSearch={_.debounce(this.onConsigneeLocationTextChange, 500)}
                    >
                      {this.state.consigneeLocations &&
                        this.state.consigneeLocations.map((location, index) => (
                          <AutoComplete.Option key={location.id} value={JSON.stringify(location)}>
                            {`${location.name} ${!isEmpty(location.location_code) ? `(${location.location_code})` : ''}`}
                          </AutoComplete.Option>
                        )
                        )}
                    </AutoComplete>
                  </Col>
                )
                  :
                    <PlacesAutocomplete
                      value={ this.state.autoAddress }
                      onChange={ this.handleChange }
                      onSelect={ this.handleSelect }
                      debounce={ 1000 }
                    >
                      { ({
                        getInputProps, suggestions,
                      }) => (

                        <Col xs={ includePredefinedStops ? 12 : 24 }>
                          <AutoComplete
                            allowClear={ true }
                            style={ { width: '100%', marginBottom: 20 } }
                            placeholder="Search address here..."
                            dataSource={ suggestions.map(item => ({ value: item.placeId, text: item.description })) }
                            onSelect={ value => this.onSelect(value, suggestions) }
                            onSearch={ value => getInputProps().onChange({
                              target: {
                                value,
                              },
                            }) }
                            disabled={disabled}
                          />
                        </Col>
                      ) }
                    </PlacesAutocomplete>
              }
            </Row> :
            <Fragment />
        } */}

        {
          !includePredefinedStops &&
          <Fragment>
              {
                <div>
                    <Row gutter={ [ 8, 8 ] }>
                      <Col { ...{...gridSize, lg: 12, xl: 12} }>
                      <FormItemEle label={I18n.t(this.renderAddressLabel('address_line1'))} require={requiredFields.includes("address_line1")}>
                        <Input
                          value={address && address.address_line1}
                          onChange={e => this.handleOnAddressChange('address_line1', e.target.value)}
                          disabled={disabled}
                          size={size}
                        />
                      </FormItemEle>
                    </Col>
                      <Col { ...{...gridSize, lg: 12, xl: 12} }>
                      <FormItemEle label={I18n.t(this.renderAddressLabel('address_line2'))}>
                        <Input
                          value={address && address.address_line2}
                          onChange={e => this.handleOnAddressChange('address_line2', e.target.value)}
                          disabled={disabled}
                          size={size}
                        />
                      </FormItemEle>
                    </Col>
                      <Col { ...{...gridSize, lg: 8, xl: 8}} >
                      <FormItemEle label={I18n.t('general.city')} 
                      require={requiredFields.includes("city")}
                       labelCol={{lg: 6, xl:6, xxl: 6}}
                       wrapperCol={{lg: 18, xl:18, xxl: 18}}
                      >
                        <Input
                          value={address && address.city}
                          onChange={e => this.handleOnAddressChange('city', e.target.value)}
                          disabled={disabled}
                          size={size}
                        />
                      </FormItemEle>
                    </Col>
                      <Col { ...{...gridSize, lg: 6, xl: 6} }>
                      <FormItemEle label={I18n.t('general.state')} require={requiredFields.includes("state")} >
                        <Input
                          value={address && address.state}
                          onChange={e => this.handleOnAddressChange('state', e.target.value)}
                          disabled={disabled}
                          size={size}
                        />
                      </FormItemEle>
                    </Col>
                      <Col  { ...{...gridSize, lg: 5, xl: 5} }>
                      <FormItemEle label={I18n.t('general.country')} require={requiredFields.includes("country")}
                       labelCol={{lg: 13, xl:13, xxl: 13}}
                       wrapperCol={{lg: 11, xl:11, xxl: 11}}
                      >
                        <Input
                          value={address && address.country}
                          onChange={e => this.handleOnAddressChange('country', e.target.value)}
                          disabled={disabled}
                          size={size}
                        />
                      </FormItemEle>
                    </Col>
                      <Col  { ...{...gridSize, lg: 5, xl: 5} }>
                      <FormItemEle label={I18n.t('general.zipcode_short_form')} 
                        require={requiredFields.includes("zipcode")}
                        labelCol={{lg: 8, xl:8, xxl: 8}}
                        wrapperCol={{lg: 16, xl:16, xxl: 16}}
                      >
                        <Input
                          value={address && address.zipcode ? address.zipcode : ''}
                          onChange={e => this.handleOnAddressChange('zipcode', e.target.value)}
                          disabled={disabled}
                          type="number"
                          size={size}
                        />
                      </FormItemEle>
                    </Col>
                  </Row>
                </div> 
            }
          </Fragment>
        }

      </div>
    );
  }
}

AddressForm.propTypes = {
  address: PropTypes.shape(),
  disabled: PropTypes.bool,
  autoComplete: PropTypes.bool,
  twoRows: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isAddressReset : PropTypes.bool,
};

AddressForm.defaultProps = {
  address: {},
  disabled: false,
  autoComplete: false,
  twoRows: false,
  isAddressReset : false,
};

export default AddressForm;

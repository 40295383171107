import { parse } from "query-string";
import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";
export const checkListApi = {
  fetchCheckList: (organizationId) => {
    let url = ApiUrl(`v2/approval_check_lists?organization_id=${organizationId}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  saveCheckList: (payload) => {
    const data = {
      "approval_check_list": payload
    }
    let url = ApiUrl(`v2/approval_check_lists`)
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  updateCheckList: (id, payload) => {
    const data = { "approval_check_list": payload }
    let url = ApiUrl(`v2/approval_check_lists/${id}`)
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  deleteCheckList: (id) => {
    let url = ApiUrl(`v2/approval_check_lists/${id}`)
    return Call("delete", url,{}, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  updateInvoiceBodyText : (data) => {
    let url = ApiUrl(`v2/organization/invoice_body_text?organization_id=${data.organization_id}`)
    return Call("put", url,data, ParseGeneralResponse, {
      responseDataKeys: { data: "invoice_body_setting" }
    }); 
  },
  getUpdateInvoiceBodyText : (organization_id,setting_name) => {
    const data = {organization_id,setting_name}
    let url = ApiUrl(`v2/organization/invoice_body_setting?organization_id=${organization_id}&setting_name=${setting_name}`)
    return Call("get", url,{}, ParseGeneralResponse, {
      responseDataKeys: { data: "invoice_setting" }
    }); 
  },
  updateOrgConfig : (data) => {
    let url = ApiUrl(`v2/organization/update_org_config?organization_id=${data.organization_id}`)
    return Call("put", url,data, ParseGeneralResponse, {
      responseDataKeys: { data: "org_config_setting" }
    }); 
  },
};
import _ from "lodash";

export const refreshAGGridCells = (currentGridRef) => {
  if (currentGridRef) {
    currentGridRef.refreshCells();
  }
};

export const clearAGGridCells = (currentGridRef) => {
  if (currentGridRef) {
    currentGridRef.deselectAll();
  }
};

// it will update the rows selection
export const updateSelection = (currentGridRef, ids, newValue) => {
  if (currentGridRef) {
    const nodesToSelect =  [];
    currentGridRef.forEachNode(node => {
      if(ids.includes(node.data.id)){
        nodesToSelect.push(node)
      }
    });
    if (nodesToSelect.length > 0) {
      currentGridRef.setNodesSelected({
        nodes: nodesToSelect,
        newValue: newValue,
      });
    }
  }
};

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Dropdown, Button, Menu, Row, Switch, Tooltip, Icon, Col } from "../common/UIComponents";
import { adminMenuItems, superAdminMenuItems } from "../common/MenuItems";
import {  renderAdminMenuItems, renderSuperAdminMenuItems,} from "../common/MenuRenderFunctions";
import { UserContext } from "../context/UserContext";
import { OrgContext } from "../context/OrgContext";
import { NavLink } from "react-router-dom";
import { ChatIcon, LightOnIcon } from "../common/CustomIcon";
import I18n from "../common/I18n";

const SideMenu = (props) => {
  const url = window.location.pathname;
  const [activeKey, setActiveKey] = useState(url);
  const { currentRole, orgRules  } = useContext(UserContext);
  const {orgSettings} = useContext(OrgContext);
  const { changeMenuStatus, menuStatus, isClosedPinned } = props;
  const [ isPinned, setIsPinned ] = useState(menuStatus === "pinned");
  const [isPinnedClosed, setIsPinnedClosed] = useState(isClosedPinned === "pinned");
  useEffect(() => {
    setActiveKey(url);
  }, [url]);


  const handlePinning = useCallback((checked) => {
    if (checked) {
      localStorage.setItem("menuStatus", "pinned");
      changeMenuStatus('isOpenPinned', "pinned");
      setIsPinned(true);
    } else {
      localStorage.setItem("menuStatus", "un-pinned");
      changeMenuStatus('isOpenPinned', "un-pinned");
      setIsPinned(false);
    }
  }
    , [menuStatus]);


  const handleClosedPinning = useCallback((checked) => {
    if (checked) {
      localStorage.setItem("isClosedPinned", "pinned");
      changeMenuStatus('isClosePinned', "pinned");
      setIsPinnedClosed(true);
    } else {
      localStorage.setItem("isClosedPinned", "un-pinned");
      changeMenuStatus('isClosePinned', "un-pinned");
      setIsPinnedClosed(false);
    }
  }, [isClosedPinned]);


  const menu = useMemo(() => {
    const superAdmin = currentRole === "super_admin";
    const current_role = currentRole;
    const { history } = props;

    const handleToggle = () => {
      if (isPinned) {
        handlePinning(false);
        handleClosedPinning(true);
      } else if (isPinnedClosed) {
        handleClosedPinning(false);
      } else {
        handlePinning(true);
      }
    };

    const getToggleIcon = () => {
      if (isPinned) {
        return "pushpin";
      } else if (isPinnedClosed) {
        return "lock";
      }
      return "unlock";
    };

    const getToggleTooltip = () => {
      if (isPinned) {
        return "Menu is pinned";
      } else if (isPinnedClosed) {
        return "Menu is closed";
      }
      return "Menu is unpinned";
    };

    let menuItems = [];

    if (superAdmin || current_role !== "driver") {
      if (!superAdmin) {
        const [ menuItemsObject ] = adminMenuItems;
        menuItems = Object.values(menuItemsObject);
      } else {
        menuItems = superAdminMenuItems;
      }

      return (
        <div className="menu-container">
          <div style={ {
            paddingLeft: "10px",
          } }>
            <Tooltip title={ getToggleTooltip() }>
              <Button onClick={ handleToggle }>
                <Icon type={ getToggleIcon() } />
              </Button>
            </Tooltip>
          </div>
          <Menu
            mode="inline"
            onClick={ (e) => setActiveKey(e.key) }
            selectedKeys={ [ activeKey ] }
          >
            { superAdmin ? menuItems.map((item) => renderSuperAdminMenuItems(item, history)) :
              menuItems.map((currentItem) =>
                currentItem.map((item) => renderAdminMenuItems(item, history))
              )
            }
             {/* <Menu.Item key={"/chat"}>
                <NavLink to="#" onClick={() => toggleChatBot()}>
                  <Row type="flex">
                    <Col> <Icon component={() => <ChatIcon />} /></Col>
                    <Col> <span className="nav-text">AI Converse</span></Col>
                  </Row>
                </NavLink>
              </Menu.Item> */}
          </Menu>
          
        </div>
      );
    }
    return null;
  }, [
    props.history,
    activeKey,
    orgRules,
    orgSettings,
    menuStatus,
    isClosedPinned
  ]);
  return menu;
};
export default SideMenu;

import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { OrgContext } from "../../context/OrgContext";

const EndUserTitle = ({ stringToAppend = "", className = "" }) => {
  const { endUserName } = useContext(OrgContext);
  return (
    <span className={`${className}`}>
      {endUserName}
      {stringToAppend ? ` ${stringToAppend}` : ""}
    </span>
  );
};

EndUserTitle.propTypes = {
  stringToAppend: PropTypes.string,
  className: PropTypes.string,
};

EndUserTitle.defaultProps = {
  stringToAppend: "",
  className: "",
};

export default EndUserTitle;

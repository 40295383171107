import React, { createContext, useState, useEffect, useContext } from 'react';
import _, { isEmpty } from 'lodash';
import { UserContext } from './UserContext';
import { fetchCreditLimitExceededAccounts, fetchOrganizationConfigs } from '../api/Organisations';
import { AppLoadingScreen } from '../components/common/LogisticsLoadingScreen';
import { glCodeApi } from '../api/GLCodeApi';
import { fetchOrgAccounts } from '../api/Account';

const OrgContext = createContext({});

const formatOrgConfig = (orgConfigs) => {
  const formattedOrgConfigs = {};
  orgConfigs.forEach(item => {
    formattedOrgConfigs[ item.setting_name ] = item.setting_value;
  });
  return formattedOrgConfigs;
};

const OrgContextProvider = (props) => {
  const { children } = props;
  const [ orgSettings, setOrgSettings ] = useState({});
  const [ isLoading, setisLoading ] = useState(true);
  const { currentOrg, currentUser } = useContext(UserContext);
  const [accountsExceeded, setAccountsExceeded] = useState([]);
  const [ endUserName, setEndUserName ] = useState('Consignee');
  const [ glCodesList, setGlCodesList ] = useState([]);
  const [ accounts, setAccounts ] = useState([]);
  //const [ grpGlCodesList, setGrpGlCodesList ] = useState([]);


  const fetchOrgData = async () => {
    setisLoading(true);
    const orgId = currentOrg.id;
    if(currentOrg.carrier_type === 'AIR_CARGO'){
      setEndUserName('Shipper')
    }
    const Configresult = await fetchOrganizationConfigs(orgId, false);
    if (Configresult.success) {
      setOrgSettings(formatOrgConfig(Configresult.org_configurations));
    }
    await fetchCreditData();
    await fetchGLCodesData();
    await fetchAccounts();
    setisLoading(false);
  };

  // useEffect(() => {
  //   const list = []
  //   glCodesList.forEach(rec => {
  //     const rowIndex = _.findIndex(list, { gl_code: rec.gl_code})
  //     if(rowIndex >= 0) {
  //       if(!isEmpty(rec.description)){
  //         list[rowIndex].description =  `${list[rowIndex].description}, ${rec.description}`;
  //       }
  //     } else {
  //       list.push(rec);
  //     }
  //   })
  //   setGrpGlCodesList(list)
  // }, [glCodesList])

  const fetchCreditData = async () => {
    const orgId = currentOrg.id;
    const warehouseIds = (_.get(currentUser, 'active_warehouse_ids', []) || []).join(',');
    const exceededAccnts = await fetchCreditLimitExceededAccounts(orgId, warehouseIds);
    if (exceededAccnts.success) {
      setAccountsExceeded(exceededAccnts.accounts);
    }
  }

  const fetchAccounts = async () => {
    const orgId = currentOrg.id;
    const result = await fetchOrgAccounts(orgId);
    if (result.success) {
      setAccounts(result.accounts);
    }
  };

  const fetchGLCodesData = async () => {
    const orgId = currentOrg.id;
    const result = await glCodeApi.fetch(orgId);
    if (result.success) {
      setGlCodesList(result.data || []);
    }
  }

  const isAirCargo = () => {
    return currentOrg.carrier_type === 'AIR_CARGO';
  }

  useEffect(() => {
    const isSuperAdmin = currentUser && currentUser?.roles && currentUser?.roles?.includes('super_admin');
    if (isSuperAdmin) {
      setisLoading(false);
    } else {
      fetchOrgData();
    }
  }, []);

  const contextValues = {
    orgSettings,
    isLoading,
    setOrgSettings,
    currentOrg,
    accountsExceeded,
    fetchCreditData,
    endUserName,
    isAirCargo,
    setGlCodesList,
    glCodesList, //: [...grpGlCodesList]
    accounts
  };

  return (
    <OrgContext.Provider value={ contextValues }>
      { isLoading ? <AppLoadingScreen /> : children }
    </OrgContext.Provider>
  );
};

export { OrgContext, OrgContextProvider };
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PropTypes from 'prop-types';
import { Col, Checkbox, Row, Drawer, Button, Icon } from "antd";
import _, { set } from 'lodash';
import Pagination from "../common/Pagination";
import GridPagination from '../common/GridPagination';

const pageSizeOptions = [
  { value: 100, label: "100 per page" },
  { value: 200, label: "200 per page" },
  { value: 400, label: "400 per page" },
  { value: 600, label: "600 per page" },
  { value: 800, label: "800 per page" },
  { value: 1000, label: "1000 per page" },
  { value: 1200, label: "1200 per page" },
];

const MyGridComponent = (props) => {
  const {
    columnDefs,
    totalCols,
    rowData,
    additionalColumnDefs = {},
    onRowSelection,
    saveColumnWidth,
    onColumnMoved,
    onRowMoved,
    onSortChanged,
    showRerraangeModal,
    closeModal,
    handleColumnVisibilityChange,
    handleRowSelection,
    showPagination= false,
    pagination = {},
    enableRowClick,
    showQuickJumper,
    handleCellData = () => {},
    selectedIds = [],
    shouldResetRowSelectedKeys,
    restoreRowSelection,
    addDropZones = () => {},
    onGridReady = () => {},
    filterPlaceHolder = {},
    RowHeight = 22,
    loading = false
  } = props;
  const gridApiRef = useRef(null);
  const [visibleCols, setVisibleCols] = useState([]);
  const defaultColDef = React.useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      lockPinned : true,
      suppressMenu: true,
      floatingFilter: true,
      ...additionalColumnDefs
    };
  }, [ additionalColumnDefs ]);

  const updateVisibleCols = (newCols) => {
    setVisibleCols(newCols);
  };

  const handleColumnResized = useCallback((event) => {
    if (event.finished ) {
      const column = event.column;
      if(!column) return;
      const newWidth = column.actualWidth;
      const colId = column.colId;
      const colState = gridApiRef.current.getColumnState();
      saveColumnWidth({colId, newWidth , colState , columnDefs});

    }
  }, [columnDefs, saveColumnWidth]);


  const selectAllColumns = () => {
    const allColumns = _.get(props, 'totalCols', []).filter(obj => obj.headerName || obj.headerName === '' || !_.has(obj, "headerName"));
    setVisibleCols(allColumns);
  };

  const clearTextSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      selection.removeAllRanges();
    }
  };

  const handleColumnMoved = useCallback((event) => {
    if(event.finished) {
      const column = event.column;
      if(!column) return;
      const colId = column.colId;
      const colIndex = event.toIndex;
      const colState = gridApiRef.current.getColumnState();
      onColumnMoved(colState);
    }
  }, [onColumnMoved]);

  const onSelectionChanged = useCallback((event) => {
    const selectedNodes = event.api.getSelectedNodes();
    // const selectedData = selectedNodes.map(node => node.data);
    // fetch ids and data in single call
    const selectedData = selectedNodes.map(node => {
      return {
        id: node.data.id,
        data: node.data
      };
    });
    const selectedIds = selectedData.map(node => node.id);
    const selectedOrderData = selectedData.map(node => node.data);

    onRowSelection(selectedIds, selectedOrderData);
  }, [onRowSelection]);

  const onRowClicked = useCallback((event) => {
    const clickedRowId = event.data.id;
    // // Check if the clicked row is selected
    // const isSelected = gridApiRef.current.isSelectedNode(event.node);
    // onSelectionChanged(event)
  }, []);

  const clearSort = useCallback(() => {
    gridApiRef.current.applyColumnState({
      defaultState: { sort: null },
    });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(filterPlaceHolder) && filterPlaceHolder?.sortBy !== "none" && !_.isEmpty(gridApiRef.current)) {
      gridApiRef.current.applyColumnState({
        state: [
          {
            colId: props.filterPlaceHolder.sortBy, 
            sort: props.filterPlaceHolder.sortByType == "descend" ? "desc" : "asc", 
          },
        ],
        defaultState: { sort: null },
      });
    }
  }, [filterPlaceHolder]);

  const saveSort = useCallback(() => {
    var colState = gridApiRef.current.getColumnState();
    var sortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    // if sortState is empty then just reset sort to default
    if (sortState.length === 0) {
      clearSort();
    } else {
      onSortChanged(sortState);
    }
  }, [onSortChanged, clearSort]);

  useEffect(() => {
    setVisibleCols(columnDefs);
  }, [ columnDefs ]);

  useEffect(() => {
    // Check if the source is "unassignedOrders"
    if (props.source !== "unassignedOrders") {
      return;
    }
    // If there are no selected IDs, deselect all nodes and return
    if(!selectedIds || selectedIds.length === 0 ) {
      if(gridApiRef.current){
        gridApiRef.current.deselectAll();
      }
      return;
    };

    // Deselect all nodes initially
    gridApiRef.current.deselectAll();

    // If gridApiRef is not available, call onRowSelection with empty arrays and return
    if(!gridApiRef.current){
      onRowSelection([],[]);
      return;
    }

    const nodesToSelect = [];

    // Iterate through each node and push nodes with selected IDs to nodesToSelect array
    gridApiRef.current.forEachNode((node) => {
      if(selectedIds.includes(node.data.id)){
        nodesToSelect.push(node);
      }
    });

    // If there are nodes to select, set them as selected
    if(nodesToSelect.length > 0){
      gridApiRef.current.setNodesSelected({
        nodes: nodesToSelect,
        newValue : true,
      });
    }

  }, [selectedIds]);

  useEffect(() => {
    if(shouldResetRowSelectedKeys){
      gridApiRef.current.deselectAll();
      restoreRowSelection();
    }
  }, [shouldResetRowSelectedKeys]); 

  useEffect(() => {
    if (gridApiRef.current) {
      if (loading) {
        gridApiRef.current.showLoadingOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  }, [ loading ]);

  const handleResetColumnsOrder = () => {
    const newCols = totalCols.map((obj) => {
      //find the column in the visibleCols
      const foundObj = visibleCols.find(col => col.field === obj.field)

      // replace the isvisible of found Column in totalCols
      return {
        ...obj,
        isVisible: foundObj ? foundObj.isVisible : false
      }
    })
    setVisibleCols(newCols);
    onRowMoved(newCols);
  }

  const handleColAllVisibility = () => {
    const checkerCol = visibleCols.find((col) => col.field === "0");
    const filteredCols = totalCols.filter(obj => obj.headerName !== undefined && obj.headerName !== "");

    // Create an object that maps the field of each visible column to its index
    const visibleIndex = visibleCols.reduce((acc, col, index) => {
      acc[col.field] = index;
      return acc;
    }, {});

    const testRowData = filteredCols.map((obj, index) => ({
      headerName: obj.headerName,
      field: obj.field,
      colId: obj.field,
      isVisible: visibleCols.some(col => col.field === obj.field && col.isVisible),
      index: obj.field in visibleIndex ? visibleIndex[obj.field] : index,
    }));

    // Sort testRowData by the index property to maintain the same order as visibleCols
    testRowData.sort((a, b) => a.index - b.index);

    const visibleColumns = testRowData?.filter(col => col.isVisible);
    const invisibleColumns = testRowData?.filter(col => !col.isVisible);

    const mixedCols = [...visibleColumns, ...invisibleColumns];

    const newCols = mixedCols.map((col) => {
      if (!_.isEmpty(col.headerName)) {
        return { ...col, isVisible: true }
      } else {
        return col
      }
    })

    const colsWithoutCheckbox =  newCols.filter(
      (col) =>
        col.isVisible ||
        col.headerName === undefined ||
        col.headerName === ""
    )
   colsWithoutCheckbox.unshift(checkerCol);
    setVisibleCols(colsWithoutCheckbox);
    onRowMoved(colsWithoutCheckbox);
  }

  const handleColVisibility = useCallback(
    (currentColObj) => {
      const colState = gridApiRef.current.getColumnState();
      const foundCol = colState.find(
        (col) => col.colId === currentColObj.field
      );

      if (foundCol) {
        let newCols;

        if (currentColObj.isVisible) {
          // If the column is currently visible, update isVisible to false
          newCols = visibleCols.map((item) =>
            item.field === currentColObj.field
              ? { ...item, isVisible: false }
              : item
          );
        } else {
          // If the column is currently hidden, update isVisible to true
          newCols = visibleCols.map((item) =>
            item.field === currentColObj.field
              ? { ...item, isVisible: true }
              : item
          );
        }
        setVisibleCols(
          newCols.filter(
            (col) =>
              col.isVisible ||
              col.headerName === undefined ||
              col.headerName === ""
          )
        );
        const colState = gridApiRef.current.getColumnState();
        onRowMoved(newCols);
      } else {
        const actualColObj = totalCols.find(
          (col) => col.field === currentColObj.field
        );

        const newCols = [
          ...visibleCols,
          {
            ...actualColObj,
            isVisible: true,
          },
        ];
        setVisibleCols(newCols);

        const colState = gridApiRef.current.getColumnState();
        onRowMoved(newCols);
      }
    },
    [totalCols, visibleCols, setVisibleCols, gridApiRef, onRowMoved]
  );
  
const renderColumns = useCallback(() => {
  const filteredCols = totalCols.filter(obj => obj.headerName !== undefined && obj.headerName !== "");


  // Create an object that maps the field of each visible column to its index
  const visibleIndex = visibleCols.reduce((acc, col, index) => {
    acc[ col?.field ] = index;
    return acc;
  }, {});

  const testRowData = filteredCols.map((obj , index) => ({
    headerName: obj.headerName,
    field: obj.field,
    colId : obj.field,
    isVisible: visibleCols.some(col => col.field === obj.field && col.isVisible),
    index: obj.field in visibleIndex ? visibleIndex[ obj.field ] : index,
  }));

  // Sort testRowData by the index property to maintain the same order as visibleCols
  testRowData.sort((a, b) => a.index - b.index);

  const visibleColumns = testRowData?.filter(col => col.isVisible);
  const invisibleColumns = testRowData?.filter(col => !col.isVisible);
  const updatedTestRowData = [...visibleColumns, ...invisibleColumns];
  const columns = [
    {
      headerName: "Rearrange Columns",
      field: "headerName",
      width: "270px",
      rowDrag: (params) => params.data.isVisible, // Conditionally enable row drag based on visibility
      cellRenderer: (obj) => {
        return (
          <Row>
            <Col xs={17}>
              {obj.data.headerName}
            </Col>
            <Col xs={7}>
              <Checkbox
                checked={obj.data.isVisible}
                onChange={() => handleColVisibility(obj.data)}
                size='small'
              />
            </Col>
          </Row>
        )
      },
    },
  ];

  return (
    <div className="ag-theme-alpine rearrange-cols" style={{ height: "96vh", width: "100%" }}>
      <AgGridReact
        columnDefs={columns}
        rowData={updatedTestRowData}
        rowDragManaged={true}
        onRowDragEnd={handleRowDragEnd}
        isVisible={false}
        onColumnVisible={handleColVisibility}
        onColumnMoved={ handleColumnMoved }
        rowHeight={ 37 }
        onRowSelected={handleRowSelection} 
      />
    </div>
  );
}, [visibleCols , columnDefs]);

const handleRowDragEnd = useCallback(
  (event) => {
    const {
      overIndex,
      node: { data },
    } = event;

    // Filter out the column with field "0"
    const checkboxCol = visibleCols.find((col) => col.field === "0");
    const newCols = visibleCols.filter((col) => col.field !== "0");

    const draggedColumnIndex = newCols.findIndex(
      (col) => col.field === data.field
    );

    if (draggedColumnIndex !== -1 && draggedColumnIndex !== overIndex) {
      const draggedColumn = newCols.splice(draggedColumnIndex, 1)[0];
      newCols.splice(overIndex, 0, draggedColumn);

      // Re-add the checkbox column to the new columns list
      if (checkboxCol) {
        newCols.unshift(checkboxCol);
      }

      updateVisibleCols(newCols);
      onColumnMoved(newCols);
    }
  },
  [visibleCols, updateVisibleCols, onColumnMoved]
);
  
  
  const isRowSelectable = useCallback((rowNode) => {
    return props.isRowSelectable ? props.isRowSelectable(rowNode) : true;
  }, []);

  const onRowDataUpdated = useCallback((params) => {
    if(props.onRowDataUpdated){
      props.onRowDataUpdated(params)
    }
  }, [props.onRowDataUpdated]);

  const handleGetRowStyle =  useCallback((params) => {
     const { getRowStyle } = props;
    if (getRowStyle) {
      return getRowStyle(params); // Call the provided getRowStyle function if it exists
    }
  }, [props.getRowStyle]);

  const tabToNextCell = useCallback(
    function(params) {
      const previousCell = params.previousCellPosition;
      const renderedRowCount = params.api.getDisplayedRowCount();
      const lastRowIndex = previousCell.rowIndex;
      let nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1;
      if (nextRowIndex < 0) {
        nextRowIndex = -1;
      }
      if (nextRowIndex >= renderedRowCount) {
        nextRowIndex = renderedRowCount - 1;
      }
      const result = {
        rowIndex: nextRowIndex,
        column: previousCell.column,
        rowPinned: previousCell.rowPinned,
      };
      return result;
    },
    []
  );

  
  const LoadingOverlay = () => {
    return (
      <div className="ag-overlay-loading-center" style={ { backgroundColor: 'rgba(255, 255, 255, 0.8)' } }>
        <div className="ag-overlay-loading-center-inner">
          <div className="spinner"></div>
          <span className="loading-text">Loading...</span>
        </div>
      </div>
    );
  }

  const rowClassRules = {
    "ag-row-highlighted": (params) => params.data.highlighted,
    "ag-row-blockBgErrorColor": (params) => {
      const { type_of_order, locations=[], location_partial_match = false } = params.data;
      return type_of_order === "T" 
        ? locations.some((data) => data.location_partial_match) 
        : location_partial_match;
    }
  };


  return (
    <div style={ { height: '100%' } }>
      <Col span={ 24 } style={ { height: '100%' } }>
        <div className="ag-theme-alpine ag-grid-container" style={ { height: '100%', width: '100%', '--ag-font-size': '10px' } }>
          <AgGridReact
            headerHeight={ 30 }
            getRowStyle={handleGetRowStyle}
            floatingFiltersHeight={ 33 }
            rowClassRules={ rowClassRules }
            rowHeight={RowHeight ?? 22}
            loadingOverlayComponent={LoadingOverlay}
            columnDefs={ visibleCols }
            rowData={ rowData }
            onSelectionChanged={ onSelectionChanged }
            onRowClicked={onRowClicked}
            onSortChanged={ saveSort }
            onGridReady={ (params) => {
              gridApiRef.current = params.api;  
              onGridReady(params);
            } }
            suppressMoveWhenRowDragging
            rowSelection="multiple"
            defaultColDef={ defaultColDef }
            onColumnResized={ handleColumnResized }
            onColumnMoved={ handleColumnMoved }
            onColumnVisible={ handleColumnVisibilityChange }
            suppressDragLeaveHidesColumns
            isRowSelectable={ isRowSelectable }
            rowMultiSelectWithClick={ true }
            onRowDataUpdated={onRowDataUpdated}
            // fix scrolling issue by adding rowId so that it can identify the row uniquely
            getRowId={({data})=> {
              return data.id;
            }}
            suppressRowClickSelection
            tabToNextCell={tabToNextCell}
            reactiveCustomComponents={true}
            stopEditingWhenCellsLoseFocus={true}
            onCellEditingStopped={(params) => {
              handleCellData(params)
            }}
            enableCellTextSelection={ true }
            onFirstDataRendered={(params) => {
            }}
            onDragStarted={() => {
              clearTextSelection()
              document.body.classList.add("no-select")
            }}
            onDragStopped={() => {
              clearTextSelection()
              document.body.classList.remove("no-select")
            }}
            // checkboxSelection={true}
          />
        </div>
        { showPagination && <div className="custom-pagination paddingRight20 paddingBottom5">
            <GridPagination
              size="small"
              pageSizeOptions={pageSizeOptions.map((option) =>
                option.value.toString()
              )}
              total={pagination.totalRecords}
              showSizeChanger
              showQuickJumper={showQuickJumper ?? true}
              current={pagination.currentPage || 1}
              onChange={(page, pageSize) =>
                props.getPageWiseData(page, pageSize)
              }
              onShowSizeChange={(current, size) => props.onPerPageChanged(size)}
              showTotal={(total, range) => {
                return `${range[0]}-${range[1]} of ${total} items`;
              }}
              pageSize={pagination.pageSize}
              
            />
          </div>}
      </Col>
      <Drawer
        closable={false}
        onClose={() => closeModal()}
        visible={showRerraangeModal}
        width="275px"
        className ="orderDrawerScroll"
      >
        <Row type='flex' justify='space-between'>
          <Col >
            <Button style={{ marginRight: '.5rem' }} size='small' type='primary' onClick={handleColAllVisibility}>Check All</Button>
            {/* <Button size='small' type='primary' onClick={handleResetColumnsOrder}>Reset Cols</Button> */}
          </Col>
          <Col>
            <Icon
              style={{ fontSize: '1rem' }}
              type="close"
              onClick={() => closeModal()}
            />
          </Col>
        </Row>
          <Row gutter={[10, 10]} style={{ padding: '.5rem' }}>
            {renderColumns()}
          </Row>
      </Drawer>
    </div>
  );
};

const checkIsEqualProp = (prevProps, nextProps, keys) => {
  return _.every(keys, (key) => _.isEqual(prevProps[key], nextProps[key]));
}

export default React.memo(MyGridComponent, (prevProps, nextProps) => {
  if (prevProps.source === "unassignedOrders") {
    return checkIsEqualProp(prevProps, nextProps, [ 'rowData' , 'selectedIds', "showRerraangeModal","columnDefs" , "loading"]);
  } else {
    return checkIsEqualProp(prevProps, nextProps, ['columnDefs', 'rowData', 'showRerraangeModal', 'pagination', "shouldResetRowSelectedKeys", "filterPlaceHolder"]);
  }
})


MyGridComponent.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  additionalColumnDefs: PropTypes.object,
  fetchData: PropTypes.func,
  saveSort: PropTypes.func,
  onRowSelection : PropTypes.func,
  onSortChanged : PropTypes.func,
  saveColumnWidth: PropTypes.func,
  onColumnMoved: PropTypes.func,
  showQuickFilter: PropTypes.bool,
  totalCols: PropTypes.array,
  showRerraangeModal: PropTypes.bool,
  enableRowClick: PropTypes.bool,
};

MyGridComponent.defaultProps = {
  additionalColumnDefs: {},
  fetchData: () => { },
  saveSort: () => { },
  onSortChanged : () => {},
  saveColumnWidth: () => { },
  onColumnMoved: () => { },
  onRowSelection: () => { },
  showQuickFilter: false,
  totalCols: [],
  showRerraangeModal: false,
  enableRowClick: true,
  restoreRowSelection: () => {},
  shouldResetRowSelectedKeys: false,
  RowHeight: 22
};
/* eslint-disable import/prefer-default-export */
import React  from 'react'
import _ from "lodash";
import { Icon } from "antd";
import BranchesIcon from '../components/common/BranchesIcon';

export const getRecoveryStatusIcon = (status) => {
    switch (status) {
      case 'in_transit':
        return <Icon type="clock-circle" theme='twoTone' twoToneColor='#ed5857' />;
      case 'in_hand':
        return <Icon type="check-circle" theme='twoTone' twoToneColor='#395484' />;
      case 'assigned':
        return <BranchesIcon  className="textAllocated" />// theme='twoTone' twoToneColor='#1890ff' />;
      case 'completed':
        return <Icon type="like" className="textCompleted" />;   // theme='twoTone' twoToneColor='#008000' />
      case 'exception':
        return <Icon type="warning" className="textExceptional" />;   
      default:
        return null
    }
}

export const getSatusColor = (status) => {
    switch (status) {
      case 'in_transit':
        return '#ed5857';
      case 'in_hand':
        return '#395484';
      case 'assigned':
        return '#1890ff';
      case 'completed':
        return "#008000";
      default:
        return 'black';
    }
}

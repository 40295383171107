/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment } from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import { Popconfirm, Tooltip, Row, Col } from "antd";
import { checkServiceExistance } from "../../helpers/common";
import ManageInvoiceLine from "./ManageInvoiceLine";
import DeleteIcon from "../common/DeleteIcon";
import DisplayTime from "../common/DisplayTime";
import { checkNegitive } from "../../common/Common";

class LinesList extends BaseList {
  constructor(props) {
    super(props);
    const userColumns = [
      {
        key: "name",
        title: I18n.t("general.description"),
        dataIndex: "invoice_name",
        ellipsis: true,
      },
      {
        key: "gl_code",
        title: I18n.t("general.gl_code"),
        dataIndex: "gl_code",
        ellipsis: true,
      },
      {
        key: "invoice_quantity",
        title: I18n.t("invoices.units"),
        dataIndex: "invoice_quantity",
        ellipsis: true,
      },
      {
        key: "invoice_value",
        title: I18n.t("invoices.amount"),
        dataIndex: "invoice_value",
        render: (data) => data ? <div className="alignRight" style={{width: 100 }}>{checkNegitive(data)}</div> : ''
      },
      {
        key: "created_by",
        title: I18n.t("general.created_by"),
        dataIndex: "created_by",
        ellipsis: true,
        className: 'textCaptilize'
      },
      {
        key: "created_at",
        title: I18n.t("general.created_at"),
        render: (data) => (
          <DisplayTime
            dateTimeString={data.created_at}
            displayWithDate={true}
            isUTC={true}
            tz_short_name="UTC"
          />
        ),
      },
      {
        key: "manage",
        render: (data) => {
          const { handleDelete } = this.props;
          return (
            <div className="pageActions">
              <Row gutter={8}>
                {/* {checkServiceExistance("BLUIND") && ( */}
                <Col span={12}>
                  <ManageInvoiceLine
                    order_id={this.props.order.id}
                    location_id={data.location_id}
                    action_from="order"
                    order={this.props.order}
                    data={data}
                    mode="update"
                    resetList={props.resetList}
                  />{" "}
                </Col>
                {/* )} */}
                {/* {checkServiceExistance("BLDINVI") && ( */}
                <Col span={12}>
                  <Tooltip title={I18n.t("general.delete")}>
                    <Popconfirm
                      placement="left"
                      title={I18n.t("messages.delete_confirm")}
                      onConfirm={() => this.props.handleDelete(data.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteIcon />{" "}
                    </Popconfirm>
                  </Tooltip>
                </Col>
                {/* )} */}
              </Row>
            </div>
          );
        },
        align: "center",
        width: 80,
        fixed: "right",
      },
    ];
    this.columns = userColumns;
  }
}

export default LinesList;

LinesList.propTypes = {};

LinesList.defaultProps = {};

import _ from "lodash";
import { isEmpty } from "../common/Common";
import AppConfig from "../config/AppConfig";

const userStore = {
  state: {},

  init (currentUser) {
    // const currentUser = JSON.parse(localStorage.getItem("CURRENT_USER")) ?? {};
    const currentOrgId = currentUser.currentOrg?.id ?? '';
    this.state = {
      ...currentUser,
      selectedOrg: currentOrgId,
      packageType: currentUser.packageType ?? "pro",
    };
  },

  setState (user) {
    localStorage.setItem("CURRENT_USER", JSON.stringify(user));
    this.state = Object.assign({}, user);
  },

  getState () {
    return this.state;
  },

  getStateValue (element) {
    return this.state[ element ] ? this.state[ element ] : this.getFirstOrg();
  },

  customer () {
    return _.includes(this.state.roles, "customer");
  },

  hasFreePackage () {
    return !isEmpty(this.state.packageType)
      ? this.state.packageType === "free"
      : false;
  },

  currentRole () {
    const mobileRoles = AppConfig.mobileRoles.map(role => role.role_code);
    const webRoles = this.state.roles.filter(role => !mobileRoles.includes(role));
    if (_.includes(this.state.roles, "super_admin")) {
      return "super_admin";
    } else if (_.includes(this.state.roles, "admin")) {
      return "admin";
    } else if (webRoles.length) {
      return webRoles[0];
    } else if (_.includes(this.state.roles, "driver")) {
      return "driver";
    } else if (this.state.roles && this.state.roles.length > 0) {
      return this.state.roles[ 0 ];
    }
    return "customer";
  },

  getServiceAccess () {
    const packageDetails = this.state.rbac ? this.state.rbac : [];
    const accessServices = packageDetails.map(
      (service) => service.operation_code
    );
    return accessServices;
  },

  getModules () {
    const packageDetails = this.state.rbac ? this.state.rbac : [];
    const modules = _.uniq(
      packageDetails.map((service) => service.module_code)
    );
    return modules;
  },

  admin () {
    return _.includes(this.state.roles, "admin");
  },

  superAdmin () {
    return _.includes(this.state.roles, "super_admin");
  },

  driver () {
    return _.includes(this.state.roles, "driver");
  },

  getCurrentRole () {
    return this.state.roles && this.state.roles.length > 0
      ? this.state.roles[ 0 ]
      : "";
  },

  getFirstOrg () {
    return this.state.organization_ids && this.state.organization_ids.length > 0
      ? this.state.organization_ids[ 0 ]
      : "";
  },

  setStateValue(element, value, cb) {
    this.state[element] = value;
  }

};

// userStore.init();
export default userStore;

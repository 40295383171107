import React from "react";
import _ from "lodash";
import { UserContextProvider } from "./UserContext";
import { OrgContextProvider } from "./OrgContext";
import { WarehouseContextProvider } from "./WarehouseContext";
import { OrderConfigContextProvider } from "./OrderConfigContext";
import { SearchConfigContextProvider } from "./SearchConfigContext";
import { AccountUnitsContextProvider } from "./AccountUnitsContext";

const AppContextProvider = ({ children }) => {
  return (
    <UserContextProvider>
      <OrgContextProvider>
        <WarehouseContextProvider>
          <AccountUnitsContextProvider>
            <OrderConfigContextProvider>
              <SearchConfigContextProvider>
                {children}
              </SearchConfigContextProvider>
            </OrderConfigContextProvider>
          </AccountUnitsContextProvider>
        </WarehouseContextProvider>
      </OrgContextProvider>
    </UserContextProvider>
  );
};
export default AppContextProvider;
import React, { Component, Fragment } from "react";
import {
  Alert,
  Descriptions,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Typography,
  Spin,
  Row,
  Col,
  Button,
  Icon
} from "antd";
import PropTypes from "prop-types";
import AddContactByButton from "../components/configurations/Contacts/AddContactByButton";
import { getContactEmails } from "../api/Billing";
import { validateEmailList } from "../components/billing_screen/helpers";

const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;

class SendEmailComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceEmails: [],
      contacts: [],
      isEmailLoading: false,
      isSendingMail: false,
      description: "",
      showEmailModal: false,
      order: null,
      locationId: null,
    };
  }

  getEmails = () => {
    const { order } = this.props;
    const accountId = order.account_id;
    this.setState({ isEmailLoading: true });
    getContactEmails(accountId).then((result) => {
      if (result.success) {
        this.setState({
          contacts: result.contact_emails || [],
          isEmailLoading: false,
        });
      } else {
        alertMessage(result.errors[0], "error", 10);
        this.setState({ isEmailLoading: false, contacts: [] });
      }
    });
  };

  addInvoiceEmail = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ invoiceEmails: validEmails });
  };

  handleContactSaved = (contact) => {
    if (contact?.email) {
      this.setState((prevState) => ({
        invoiceEmails: [...prevState.invoiceEmails, contact.email],
      }));
    }
    this.getEmails();
  };

  renderEmailModal = (order, locationId) => {
    this.setState({
      showEmailModal: true,
      order: order,
      locationId: locationId,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showEmailModal: false,
      order: null,
      locationId: null,
      invoiceEmails: [],
      description: '',
    });
  };

  render() {
    const { handleSendingMails, location, locationId , order} = this.props;
    const {
      isEmailLoading,
      invoiceEmails,
      contacts,
      description,
      isSendingMail,
    } = this.state;

    return (
      <Fragment>
        {/* <Tooltip title={locationToConsider?.is_form_submitted ? "Send Email For Form" : "Form is not submitted"} placement="top"> */}
        <Icon
          type="mail"
          style={{
            fontSize: 18,
            paddingTop: "5px",
            marginLeft: "8px",
            cursor: location?.is_form_submitted
              ? "pointer"
              : "not-allowed",
          }}
          theme="twoTone"
          className="line-actions"
          onClick={() => {
            this.getEmails()
            if(location?.is_form_submitted){
                this.renderEmailModal(order, locationId)
            }
            }
          }
        />
        {/* </Tooltip> */}
        <Drawer
          title={<h4 style={{ marginLeft: "-16px" }}>Send Pod Email</h4>}
          placement="right"
          width="40%"
          onClose={this.handleCloseModal}
          visible={this.state.showEmailModal}
          maskClosable={false}
        >
          <Spin spinning={isEmailLoading || this.props.sendEmailLoading}>
            <Row
              className="tagsInput emailForm"
              type="flex"
              align="middle"
              gutter={8}
            >
              <Col span={18}>
                <FormItem label="Email">
                  <Select
                    mode="tags"
                    value={invoiceEmails}
                    onChange={this.addInvoiceEmail}
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    placeholder="Enter Emails"
                  >
                    {contacts.map((opt) => (
                      <Option key={opt}>{opt}</Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={6} className="alignRight">
                <AddContactByButton
                  accountId={this.props.order.account_id}
                  saveOn={this.handleContactSaved}
                  size="medium"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormItem label="Remarks">
                  <TextArea
                    rows={4}
                    placeholder="Description"
                    className="sop-form-textArea"
                    value={description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" align="middle" justify="center" gutter={4}>
              <Col>
                <Button type="danger" onClick={this.handleCloseModal}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  loading={isSendingMail}
                  onClick={() => handleSendingMails(invoiceEmails, description, this.handleCloseModal)}
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Spin>
        </Drawer>
      </Fragment>
    );
  }
}

SendEmailComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSendingMails: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  locationId: PropTypes.string,
};

export default SendEmailComponent;
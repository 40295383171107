import React, { Fragment, useState } from 'react';
import { Select, Row } from 'antd';
import { isEmpty } from 'lodash';

const LocationSelect = ({ orderData, record, index, handleOnChange, currentSection, isEditing, isSaved , listData }) => {
  const [ orderType, setOrderType ] = useState(null);
  const [ orderError, setOrderError ] = useState(null);

  const locationData = orderData?.locations?.find((id) => id.location_id === record.location_id);
  const ObtainedVal = locationData?.order_type;
  const currentAccesorial = record.accessorial_code
  const currentAccessorialRecords = listData?.filter((i) => i.accessorial_code === currentAccesorial);


  const handleChange = (e) => {
    handleOnChange(index, "location_id", e, currentSection.order_number);
    setOrderType(record.location_id);
  };

  const renderOptions = () => {
    const filteredLocations = isEditing ? orderData?.locations?.filter((location) => {
      const isLocationSelected = currentAccessorialRecords?.some((record) => record.location_id === location.location_id);
      return !isLocationSelected || location.location_id === record.location_id;
    }) : orderData?.locations;

    return filteredLocations?.map((location) => (
      <Select.Option key={ location.location_id } value={ location.location_id }>
        { location.order_type }
      </Select.Option>
    ));
  }

  return (
    record.isNew && !isSaved || isEditing ? (
      <Fragment>
        <Select
          showSearch
          value={ record.location_id }
          filterOption={ (input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={ handleChange }
          size="small"
          className="width100Per"
        >
            {renderOptions()}
        </Select>
        { !isEmpty(orderError) && isEmpty(record.location_id) && (
          <Row>
            <span style={ { color: "red", fontSize: "13px" } }>{ orderError }</span>
          </Row>
        ) }
      </Fragment>
    ) : (
      <Fragment>{ ObtainedVal }</Fragment>
    )
  );
};

export default LocationSelect;
import React from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Col,
  Row,
} from "antd";
import PropTypes from "prop-types";
import { alertMessage, randomUUID } from "../../common/Common";
import { confirmationPopup, formatByTimeConfig, renderAlertMessage } from "../../helpers/common";
import moment from "moment";
import { momentTime } from "../../helpers/date_functions";
// import { dispatcherNotesApi } from "../../api/OrdersApi";
import I18n from "../../common/I18n";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = (form,record) => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input onPressEnter={
      () => this.props.handleSave(form, record.id)
    } autoFocus/>;
  };

  renderCell = (form) => {
    const { getFieldDecorator } = form;
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td { ...restProps }>
        { editing ? (
          <Form.Item style={ { margin: 0 } }>
            { getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[ dataIndex ],
            })(this.getInput(form, record)) }
          </Form.Item>
        ) : (
          children
        ) }
      </td>
    );
  };

  render () {
    return (
      <EditableContext.Consumer>{ this.renderCell }</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: this.props.data,
      editingKey: "",
      loading: false
    };

    this.isLastRow = (id, data) => {
      const index = data.findIndex(item => item.id === id);
      return index === data.length - 1;
    };

    this.columns = [
      {
        title: "#",
        dataIndex: "id",
        width: "3%",
        editable: false,
        render:(data, record, index) => {
          return index + 1;
        }
      },
      {
        title: I18n.t('general.notes'),
        dataIndex: "private_notes",
        width: "56%",
        editable: true,
      },
      {
        title: I18n.t('messages.updated_by'),
        dataIndex: "last_updated_by",
        width: "12%",
        editable: false,
      },
      {
        title: I18n.t('messages.updated_at'),
        dataIndex: "last_updated_at",
        width: "18%",
        editable: false,
        render: (data, record) => {
          return (
            /* {data
                ? moment(new Date(data)).format("Do MMM YYYY, hh:mm A")
                : record.updated_at
                ? moment(new Date(record.updated_at)).format(
                    "Do MMM YYYY, hh:mm A"
                  )
                : ""} */
            <span>
              {data
                ? `${formatByTimeConfig(momentTime(
                    "",
                    data,
                    this.props.timeZone
                  ) , this.props.isMilitaryTime , "MMM Do, YYYY HH:mm" , "MMM Do, YYYY hh:mm A")} ${
                    this.props.timeZoneName ? ` ${this.props.timeZoneName}` : ""
                  }`
                : record.updated_at
                ? `${formatByTimeConfig(momentTime(
                    "",
                    data,
                    this.props.timeZone
                  ) , this.props.isMilitaryTime , "MMM Do, YYYY HH:mm" , "MMM Do, YYYY hh:mm A")} ${
                    this.props.timeZoneName ? ` ${this.props.timeZoneName}` : ""
                  }`
                : ""}
            </span>
          );
        },
      }];
      if(props.show_actions){
        this.columns.push({
        title: "",
        width: "11%",
        dataIndex: "operation",
        render: (text, record) => {
          const editable = this.isEditing(record);
          const {addAtLastColumn} = this.props
          return (
            <div className="actionsWrapper paddingLeft10 fontSize14" type="flex" gutter={ 8 }>
              { editable ? (
                <Row type="flex" gutter={ 8 }>
                  <Col className="paddingLeft5">
                    <EditableContext.Consumer>
                      { (form) => (
                        <Icon
                          type="save"
                          onClick={ () => this.save(form, record.id) }
                          style={ { color: "#1890ff", fontSize: 14 }}
                        />
                      ) }
                    </EditableContext.Consumer>
                  </Col>
                  <Col className="paddingLeft5">
                    
                      <Icon
                       onClick={() => this.cancel(record.id)}
                        type="close"
                        style={ {
                          color: "red", fontSize: 14
                        } }
                      />
               
                  </Col>
                </Row>
              ) : (
                <Row type="flex" gutter={ 8 }>
                  <Col>
                    <EditIcon handleClick={() => this.edit(record.id)}/>
                  </Col>
                  <Col>
                    <Popconfirm
                      title="Sure to Delete?"
                      onConfirm={() => this.delete(record.id)}
                      placement="topLeft"
                    >
                      <DeleteIcon/>
									    &nbsp;&nbsp;&nbsp;
                    </Popconfirm>
                  </Col>
                  { addAtLastColumn && this.isLastRow(record.id, this.state.data) && (
                    <Col>
                      <Icon
                        type="plus-circle"
                        onClick={ () => this.addNewRow() }
                        style={ { color: "#000" } }
                      />
                    </Col>

                  ) }
                </Row>
              ) }
            </div>
          );
        },
      });
      }
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      this.setState({ data: this.props.data });
    }
    if (prevProps.newNotesInsertion !== this.props.newNotesInsertion) {
      if (this.props.newNotesInsertion) {
        this.addNewRow();
      }
    }
    if (prevState.editingKey !== this.state.editingKey) {
      this.props.onCellEditing(!!this.state.editingKey); 
      if (this.state.editingKey.length > 0) {
        // remove last_updated_by and last_updated_at using filter
        this.columns = this.columns.filter(
          (column) => column.dataIndex !== "last_updated_by" && column.dataIndex !== "last_updated_at"
        );
        this.columns[1].width = "89%";
      } else {
        this.columns[1].width = "56%";
        // add last_updated_by and last_updated_at after notes column
        this.columns.splice(2, 0, {
          title: I18n.t('messages.updated_by'),
          dataIndex: "last_updated_by",
          width: "12%",
          editable: false,
        });
        this.columns.splice(3, 0, {
          title: I18n.t("messages.updated_at"),
          dataIndex: "last_updated_at",
          width: "18%",
          editable: false,
          render: (data, record) => {
            return (
              <span>
                {/* {data
                  ? moment(new Date(data)).format("Do MMM YYYY, hh:mm A")
                  : record.updated_at
                  ? moment(new Date(record.updated_at)).format(
                      "Do MMM YYYY, hh:mm A"
                    )
                  : ""} */}
                {data
                  ? `${formatByTimeConfig(momentTime(
                    "",
                    data,
                    this.props.timeZone
                  ) , this.props.isMilitaryTime , "MMM Do, YYYY HH:mm" , "MMM Do, YYYY hh:mm A")} ${
                    this.props.timeZoneName ? ` ${this.props.timeZoneName}` : ""
                  }`
                  : record.updated_at
                  ? `${formatByTimeConfig(momentTime(
                    "",
                    record.updated_at,
                    this.props.timeZone
                  ) , this.props.isMilitaryTime , "MMM Do, YYYY HH:mm" , "MMM Do, YYYY hh:mm A")} ${
                    this.props.timeZoneName ? ` ${this.props.timeZoneName}` : ""
                  }`
                  : ""}
              </span>
            );
          }
        });
      }
      this.forceUpdate();
    }
  }

  isEditing = (record) => record.id === this.state.editingKey;

  cancel = () => {
    const { data } = this.state;
    const newData = data.filter((item) => {
      if (item.id === this.state.editingKey) {
        if (item.notes === "" && item.isNew) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    this.setState({ data: newData, editingKey: "" });
    this.props.updateDispatcherNotesList(newData);
  };

  save (form, id) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [ ...this.state.data ];
      const {apiEndpoint} = this.props
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[ index ];
        const isNewRecord = item.isNew;
        delete item.isNew;
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        const apiPayload = {
          customer_order_id : this.props.refer_id,
          private_notes : row.private_notes,
          organization_id: this.props.organization_id,
          refer_type: this.props.refer_type,
        }
        if (isNewRecord) {
          this.setState({loading: true});
          apiEndpoint.create(apiPayload).then(res => {
            if (res.success) {
              newData[ index ] = res.dispatch_notes;
              this.setState({ data: newData, editingKey: "" });
              alertMessage(I18n.t("messages.saved"));
            }
            else {
              renderAlertMessage(res.errors)
            }
          }
          ).finally(() => {
             this.setState({loading: false});
          })
        }
        else {
          apiPayload.customer_order_notes_id = item.id;
          delete apiPayload.customer_order_id;
          this.setState({loading: true});
          apiEndpoint.update(apiPayload).then(res => {
            if (res.success) {
              newData[ index ] = res.dispatch_notes;
              this.setState({ data: newData, editingKey: "" });
              alertMessage(I18n.t("messages.saved"));
            }
            else {
              renderAlertMessage(res.errors)
            }
          }
          ).finally(() => {
            this.setState({loading: false});
            })
        }
      } 
      this.props.updateDispatcherNotesList(newData);

    });
  }
  onEditConfirm = (id) => {
    const { editingKey, data } = this.state;
    const newData = data.filter(
      (item) => item.id !== editingKey
    );
    this.setState({ data: newData, editingKey: id });
  };

  removeNewRecords = (id) => {
    const nonNewRecords = this.state.data.filter(
      (item) => !item.isNew
    );
    this.setState({ data: nonNewRecords, editingKey: id });
    this.props.updateDispatcherNotesList(nonNewRecords);
  };
  edit (id) {
    if (this.state.editingKey.length) {
      const confirmationInfo = {
        title: I18n.t('general.are_you_sure'),
        content: I18n.t('messages.unsaved_changes'),
        onConfirm: () => {
          this.removeNewRecords(id);
        },
      };
      confirmationPopup(confirmationInfo);
    } else {
      this.setState({ editingKey: id });
    }
  }
  delete (id) {
    const { data } = this.state;
    const {apiEndpoint} = this.props
    this.setState({loading: true});
    apiEndpoint.delete(id).then(res => {
      if (res.success) {
        const newData = data.filter(
          (item) => item.id !== id
        );
        this.setState({ data: newData });
        this.props.updateDispatcherNotesList(newData);
        alertMessage(I18n.t("messages.deleted"));
      }else{
        renderAlertMessage(res.errors)
      }
    }).finally(() => {
      this.setState({editingKey: "" , loading: false});
    });
  }
  addNewRow = () => {
    const randomId = randomUUID();
    const newData = {
      id: `${randomId}`,
      notes : "",
      last_updated_by : "",
      last_updated_at: "",
      isNew: true
    };
    this.setState({
      data: [ ...this.state.data, newData ],
      editingKey: newData.id
    } , () => {
      if (this.props.newNotesInsertion) {
        this.props.toggleNewNotesInsertion(false);
      }
    });
  };

  render () {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          handleSave: this.save.bind(this),
        }),
      };
    });

    return (
      <Row
        style={ {
          display: "grid",
          gap: "10px",
        } }
      >
        <EditableContext.Provider value={ this.props.form }>
          <Table
            loading={ this.state.loading }
            style={ {
              ...this.props.style,
            }}
            scroll={{ ...this.props.scroll}}
            rowKey={ (record) => record.id }
            components={ components }
            dataSource={ this.state.data }
            columns={ columns }
            rowClassName="editable-row"
            pagination={ false }
            size={"small"}
          />
        </EditableContext.Provider>
      </Row>
    );
  }
}

const PrivateNotesTable = Form.create()(EditableTable);
export default PrivateNotesTable;

PrivateNotesTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  updateDispatcherNotesList: PropTypes.func,
  style: PropTypes.object,
  organization_id: PropTypes.number || PropTypes.string,
  refer_id: PropTypes.number || PropTypes.string,
  newNotesInsertion: PropTypes.bool,
  toggleNewNotesInsertion: PropTypes.func,
  apiEndpoint: PropTypes.object.isRequired,
  show_actions: PropTypes.bool.isRequired,
};

PrivateNotesTable.defaultProps = {
  data: [],
  loading: false,
  updateDispatcherNotesList: () => { },
  style: {},
  organization_id: 0,
  refer_id: 0,
  newNotesInsertion: false,
  toggleNewNotesInsertion: () => { },
  apiEndpoint: {},
  show_actions: true,
};

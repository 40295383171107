import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Col,
  FormItem,
  Row,
  Collapse,
} from "../../common/UIComponents";
import {  alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import { checkServiceExistance } from "../../helpers/common";
import TreeSelectComponent from "../../containers/Filters/TreeSelectComponent";
import { getWarehouseAccessorials, saveWarehouseAccessorials } from "../../api/Accessorials";

const { Panel } = Collapse;
class AccWhAccessorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: this.props.orders ? this.props.orders : [],
      inProgress: false,
      accessorials: [],
      errors: [],
      currentForm: {},
    };
    this.handleSave = this.handleSave.bind(this);
  }

  handleStateOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  handleOnChange = (element, value) => {
    this.setState({
      currentForm: Object.assign({}, this.state.currentForm, {
        [element]: value,
      }),
    });
  };

  updatePropValues = (data) => {
    const { handleOnChange } = this.props;
    if (handleOnChange && typeof handleOnChange === "function") {
      // handleOnChange("isModified", true);
      handleOnChange(data.required_accessorials);
    }
  };

  handleElementChange(order, element, value, defaultValue = '') {
    const currentForm = Object.assign({}, this.state.currentForm);
    if (isEmpty(currentForm[order])) {
      currentForm[order] = {};
    }
    currentForm[order][element] = !isEmpty(value) ? value : defaultValue;
    this.handleStateOnChange("currentForm", currentForm);
    // this.updatePropValues();
  }

  componentDidMount() {
    this.getAccountWarehouseAccessorials();
    this.setDefaultConfigs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.accountId, this.props.accountId)) {
      this.getAccountWarehouseAccessorials();
    }
    if(!_.isEqual(prevProps.orders, this.props.orders)) {
        this.setDefaultConfigs();
    }
  }

  getAccountWarehouseAccessorials = () => {
    if (this.props.accountId) {
      getWarehouseAccessorials(this.props.accountId).then((result) => {
        if (result.success) {
          this.setState({ accessorials: result.warehouseAccessorials || [], errors: [] });
        } else {
          this.setState({
            accessorials: [],
            errors: result.errors,
            inProgress: false,
          });
        }
      });
    }
  };

  setDefaultConfigs = () => {
    if(this.props.actionType === 'edit' && this.props.orders){
        const currentForm = {}
        this.props.orders.forEach((order) => {
          currentForm[order.id] = {
            required_accessorials: order.required_accessorials || [],
          }
        })
        this.setState({
          currentForm,
        })
    }
  }

  handleSave() {
    const { currentForm } = this.state;
    const orderAccessorials = [];
    const orgId = this.props.org_id || "";
    Object.keys(currentForm).forEach((order) => {
      orderAccessorials.push({
        order_id: order,
        accessorials: currentForm[order] ? [...currentForm[order].required_accessorials] : [],
      });
    });
    const data = {
      required_accessorials: [...orderAccessorials],
      organization_id: orgId,
    };
    this.setState({ inProgress: true });
    saveWarehouseAccessorials(data).then((result) => {
        if (result.success) {
          this.updatePropValues(data);
          this.setState({ inProgress: false });
          alertMessage(result.message ? result.message : I18n.t('messages.saved'));
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({
            accessorials: [],
            errors: result.errors,
            inProgress: false,
          });
        }
      });
  }

  renderAccessorial = (order) => {
    const { currentForm } = this.state;
    return (
      <FormItem label={I18n.t("accessorials.select_required_warehouse_accessorials")}>
        <TreeSelectComponent
          selectedValues={ currentForm[order]
            ? currentForm[order].required_accessorials
            : []} 
          onValueChange={(value) => this.handleElementChange(order, 'required_accessorials', value, [])}
          records={this.state.accessorials}
          titleField="accessorial_name"
          valueField="accessorial_code"
          showLabel={false}
          size="default"
          element="required_accessorials"
          dropdownMatchSelectWidth={true}
        />
      </FormItem>
    );
  };

  renderAccessorialView = (order) => {
    const accessorils = this.state.accessorials.filter((accessorial) =>
     order?.required_accessorials.includes(accessorial.accessorial_code)).map(accessorial => accessorial.accessorial_name)
    return accessorils.length > 0 ? <div>
        <span className="fontSize13">{I18n.t("accessorials.required_warehouse_accessorials")}</span>:{"  "} {accessorils.join(', ')}
    </div> : <div>No Accessorial Selected</div>
  }

  render() {
    const { orders } = this.state;
    const { actionType } = this.props;
    return (
      <div>
        <Collapse
          defaultActiveKey={[`warehouse_accessorials`]}
          isActive={true}
          style={{ height: "auto" }}
          expandIconPosition="right"
          className="marginBottom10 customCollapse"
        >
          <Panel
            className="textBold"
            header={<Fragment>{I18n.t(
                "accessorials.warehouse_accessorials"
              )}</Fragment>}
            key={`warehouse_accessorials`}
            size="small"
          >
            {actionType === "edit" ? (
              <Fragment>
                <Row gutter={16}>
                  {orders.map((order) => (
                    <Col xs={10} key={`wa${order.id}`}>
                      <Col>
                        <h4 className="fontSize14">
                          {`${order.label} ${I18n.t("order.label")} (${
                            order.order_no
                          })`}
                        </h4>
                      </Col>
                      <Col className="marginTop10">
                         {this.renderAccessorial(order.id)}
                      </Col>
                    </Col>
                  ))}

                {checkServiceExistance("OAA") && (
                    <Col xs={2} style={{ paddingTop: 30}}>
                      {FormButtons(
                        this.state.inProgress,
                        this.handleSave,
                        null,
                        false
                      )}
                    </Col>
                )}
                </Row>
                <Row>{FormErrors(this.state.errors)}</Row>
              </Fragment>
            ) : (
              <Row gutter={16}>
                {orders.map((order) => (
                  <Col sm={24} xs={24} md={12} lg={12} className="textBold" key={`wa${order.id}`}>
                    <h4 className="fontSize14">
                      {`${order.label} ${I18n.t("order.label")} (${
                        order.order_no
                      })`}
                    </h4>
                    <Col className="marginTop10">
                       {this.renderAccessorialView(order)}
                    </Col>
                  </Col>
                ))}
              </Row>
            )}
          </Panel>
        </Collapse>
      </div>
    );
  }
}

AccWhAccessorial.propTypes = {
  currentForm: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

AccWhAccessorial.defaultPropTypes = {
  currentForm: {},
  isNew: true,
};

export default AccWhAccessorial;

import React, { Fragment, useState, useEffect, useMemo } from "react";
import moment from "moment";
import _ from "lodash"; // Make sure you have lodash installed
import { LightOnIcon } from "../../common/CustomIcon";

const QuickDispatchErroBox = React.memo((props) => {
  const {errors, setErrors } = props;


  return errors?.length ? (
    <div className="error-container">
      <p>Error</p>
      <p className="description" style={{ maxHeight: 60, overflowY: "auto" }}>
        {
          errors?.join(', ')
        }
      </p>
      <span
        className="dismiss-tip"
        onClick={() => {
          setErrors([]);
        }}
      >
        Dismiss
      </span>
      <div className="icon">
        {" "}
        <LightOnIcon color="red" />{" "}
      </div>
    </div>
  ) : (
    <Fragment />
  );
});

export default QuickDispatchErroBox;

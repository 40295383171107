/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react'
import _, { last } from 'lodash';
import moment from 'moment'
import { contrast, formateColor } from '../common/Colors'
import AppConfig from '../config/AppConfig'
import {
  driverNameFromRoute,
  formatFullName,
  drivercodeFromRoute,
  formateTwoDates,
  formateTwoDateDays,
  confirmationPopup,
  checkAccessExistance,
  metersToOtherMeasurements,
  secondsToHms,
  formatSingleDay,
} from './common'
import { formatStopName, rearrangeStops, stopTypeFromObject } from './stops'
import {
  alertMessage,
  findNumberTotal,
  isEmpty,
  randomColor,
  randomNumber,
} from '../common/Common'
import I18n from '../common/I18n';
import { getAppointmentTimeFromSlots, getScheduledTime, getStartTime } from './orders'
import {
  findCommonElementsExisting,
  getValueFromArrayOfObjects,
  searchRecords,
} from './array_functions'
import userStore from '../stores/UserStore'
import { getExecutionTime } from './routes'
import {Col, Icon, Modal, notification, Row, message, Divider, Typography, Popover, Link, Button} from '../common/UIComponents'
import { moveOrder, moveStop, updateRoute } from '../api/Routes'
import { resolveAddressApi } from '../api/OrdersApi'
import { capacityValidation } from '../api/PreplanApi'
import ZoneCapacityErrors from '../components/preplan/ZoneCapacityErrors'
import ZoneCapacityErrorDetailInfo from '../components/preplan/ZoneCapacityErrorDetailInfo'
import { displayUnassignedErrors, doRouteCapacityValidation, findOverloadOrdersByZone, findOverloadReasons, findOverloadReasonsWithDetails } from './capacityValidation'
import { momentTime } from './date_functions'
import RouteLabel from '../config/RouteLabel'
import CapacityOrders from '../components/orders/CapacityOrders';
import whiteArrow from "../../assets/images/WhiteArrow.png";

const { confirm } = Modal
const { Paragraph } = Typography

export const assignRouteInfo = (
  user,
  indexValue,
  route = {},
  defaultValue = true,
) => {
  const userName = formatFullName(user)
  if (defaultValue) {
    const bgColor = randomColor(false)
    const fgColor = contrast(`#${bgColor}`)
    return Object.assign({}, user, {
      stops: [],
      route_id: indexValue + 1,
      total_time: '-',
      total_distance: '-',
      route_name: '',
      customer_order_ids: [],
      bgColor,
      fgColor,
      driversInfo: {
        primary: {
          driverId: user.id,
          allotedDriver: user,
          selectedDriverId: '',
        },
        secondary: {
          driverId: '',
          allotedDriver: {},
          selectedDriverId: '',
        },
      },
      optimization_type: '',
      optimized: false,
      displayName: user.employee_code || userName || 'NA',
      isLocked: true,
      warehouse_id: '',
      warehouse_name: '',
      locked: false,
      locked_by: '',
      locked_by_user_name: '',
    })
  }
  return Object.assign({}, user, {
    stops: route.stops,
    route_id: route.route_id,
    total_time: route.total_time,
    total_distance: route.total_distance,
    customer_order_ids: route.customer_order_ids,
    bgColor: route.bgColor,
    fgColor: route.fgColor,
    route_name: '',
    driversInfo: {
      primary: {
        driverId: user.id,
        allotedDriver: user,
        selectedDriverId: '',
      },
      secondary: {
        driverId: '',
        allotedDriver: {},
        selectedDriverId: '',
      },
    },
    optimization_type: route.optimization_type,
    optimized: route.optimized,
    displayName: user.employee_code || userName || 'NA',
  })
}

export const setOrdersinSorting = (routeOrders = [], refs = []) => {
  const orders = routeOrders.filter((order) =>
    refs.includes(order.customer_order_id),
  )
  const deliveryOrders = _.sortBy(
    orders.filter(
      (order) =>
        (isEmpty(order.related_order) || !refs.includes(order.related_order)) &&
        AppConfig.deliveryOrderTypes.includes(order.type_of_order),
    ),
    'type_of_order',
  )
  const pickupOrders = _.sortBy(
    orders.filter(
      (order) =>
        (isEmpty(order.related_order) || !refs.includes(order.related_order)) &&
        AppConfig.pickOrderTypes.includes(order.type_of_order),
    ),
    'type_of_order',
  )
  const dependancyOrders = _.sortBy(
    orders.filter(
      (order) =>
        !isEmpty(order.related_order) && refs.includes(order.related_order),
    ),
    'type_of_order',
  ).reverse()
  const sortedCustomerOrders = [].concat(
    deliveryOrders,
    dependancyOrders,
    pickupOrders,
  )
  const sortedCustomerOrdersIds = sortedCustomerOrders.map(
    (order) => order.customer_order_id,
  )
  return sortedCustomerOrdersIds
}

// export const setRouteObject = (
//   navRoute,
//   routeIndex,
//   notIncldedStops = ['DISPATCHED', 'COMPLETED'],
// ) => {
//   if (!isEmpty(navRoute)) {
//     const currentUser = userStore.getStateValue('id')
//     const navRouteOrders = navRoute.customer_orders || []
//     if (
//       !notIncldedStops.includes(navRoute.nav_route_status) &&
//       navRoute.stops.length > 0
//     ) {
//       let secHrs = secondsToHms(navRoute.total_time, true, false, false)
//       secHrs = secHrs ? `${secHrs} Approx.` : 'NA'
//       const totalDistance = navRoute.total_distance
//         ? `${parseFloat(navRoute.total_distance / 1609).toFixed(2)} mi.`
//         : 'NA'
//       const formattedName = driverNameFromRoute(navRoute, 'object')
//       const formattedCode = drivercodeFromRoute(navRoute, 'object')
//       const bgColor = navRoute.color_code
//         ? formateColor(navRoute.color_code)
//         : AppConfig.colors[routeIndex]
//       const routeDetails = {
//         isLocked:
//           navRoute.locked === true && navRoute.locked_by !== currentUser,
//         locked: navRoute.locked === true,
//         locked_by: !isEmpty(navRoute.locked_by) ? navRoute.locked_by : '',
//         locked_by_user_name: !isEmpty(navRoute.locked_by_user_name)
//           ? navRoute.locked_by_user_name
//           : '',
//         warehouse_id: navRoute.warehouse_id,
//         warehouse_name: navRoute.warehouse_name,
//         route_id: navRoute.nav_route_id,
//         route_name: navRoute.nav_route_name,
//         route_type: navRoute.route_type,
//         fleet_name: navRoute.fleet_name,
//         route_start_datetime: navRoute.route_start_datetime,
//         route_end_datetime: navRoute.route_end_datetime,
//         route_confirmed_at: navRoute.route_confirmed_at,
//         optimization_type: navRoute.optimization_type,
//         optimized: navRoute.optimized,
//         pinned: navRoute.optimization_type === 'manual',
//         status: navRoute.nav_route_status,
//         bgColor,
//         fgColor: contrast(bgColor),
//         optimizer_readable_error_message: navRoute.optimizer_readable_error_message
//           ? navRoute.optimizer_readable_error_message
//           : [],
//         stops: [],
//         routeOrders: [],
//         customer_order_ids: navRoute.customer_order_ids || [],
//         formattedName,
//         formattedCode,
//         displayName: formattedCode || formattedName,
//         driversInfo: {
//           primary: {
//             driverId: navRoute.primary_driver ? navRoute.primary_driver.id : '',
//             allotedDriver: navRoute.primary_driver
//               ? Object.assign({}, navRoute.primary_driver, {
//                   full_name: formatFullName(navRoute.primary_driver),
//                 })
//               : {},
//             selectedDriverId: '',
//           },
//           secondary: {
//             driverId: navRoute.secondary_driver
//               ? navRoute.secondary_driver.id
//               : '',
//             allotedDriver: navRoute.secondary_driver
//               ? Object.assign({}, navRoute.secondary_driver, {
//                   full_name: formatFullName(navRoute.secondary_driver),
//                 })
//               : {},
//             selectedDriverId: '',
//           },
//         },
//         truckInfo: {
//           assignedId: navRoute.fleet_id ? navRoute.fleet_id : '',
//           allotedTruck: navRoute.fleet_id
//             ? { fleet_id: navRoute.fleet_id, name: navRoute.fleet_name }
//             : {},
//           selectedId: '',
//         },
//         total_time: secHrs,
//         total_distance: totalDistance,
//         vehicle_weight:
//           navRoute?.primary_driver &&
//           !isEmpty(navRoute.primary_driver.vehicle_weight)
//             ? navRoute.primary_driver.vehicle_weight
//             : '',
//         vehicle_volume:
//           navRoute?.primary_driver &&
//           !isEmpty(navRoute.primary_driver.vehicle_surface_area)
//             ? navRoute.primary_driver.vehicle_surface_area
//             : '',
//         vehicle_type:
//           navRoute?.primary_driver &&
//           !isEmpty(navRoute.primary_driver.vehicle_type)
//             ? navRoute.primary_driver.vehicle_type
//             : '',
//         total_weight: findNumberTotal(navRouteOrders, 'weight', null, 0),
//         stick_start_time: navRoute.stick_start_time,
//       }

//       const routeStops = navRoute.stops || []
//       const sortedRoutesStops = _.sortBy(routeStops, [
//         function (o) {
//           return o.stop_order_sequence
//         },
//       ])
//       if (sortedRoutesStops.length > 0) {
//         sortedRoutesStops[sortedRoutesStops.length - 1].completion_class =
//           'inCompletedRoute'
//       }
//       const { interconnections } = navRoute
//       const stopsOrders = []
//       sortedRoutesStops.forEach((stop, index) => {
//         sortedRoutesStops[index].location_type = stopTypeFromObject(stop);
//         sortedRoutesStops[index].visibility = false
//         sortedRoutesStops[index].orders = []
//         const customerOrdersRefs = stop.customer_order_ids
//         if (
//           routeDetails.optimizer_readable_error_message.length === 0 &&
//           stop.stop_errors &&
//           stop.stop_errors.length > 0
//         ) {
//           routeDetails.optimizer_readable_error_message = [...stop.stop_errors]
//         }
//         sortedRoutesStops[index].stop_number = index + 1
//         const timeZoneId =
//           stop.location && stop.location.timeZoneId
//             ? stop.location.timeZoneId
//             : ''
//         sortedRoutesStops[index].timeZoneId = timeZoneId
//         const contactDetails = stop.contact_details ? stop.contact_details : {}
//         sortedRoutesStops[index].formatted_stop_name = formatStopName(
//           contactDetails,
//           stop.location,
//           index + 1,
//         )
//         // const sortedCustomerOrdersRefs = _.sortBy(
//         //   navRouteOrders.filter((order) =>
//         //     customerOrdersRefs.includes(order.customer_order_id)
//         //   ),
//         //   "type_of_order"
//         // ).map((order) => order.customer_order_id);
//         const sortedCustomerOrdersRefs = setOrdersinSorting(
//           navRouteOrders,
//           customerOrdersRefs,
//         )
//         _.uniq(sortedCustomerOrdersRefs).forEach((orderRef) => {
//           const orderObject = _.find(navRouteOrders, {
//             customer_order_id: orderRef,
//           })
//           if (!_.isNil(orderObject)) {
//             sortedRoutesStops[index].orders.push(orderObject)
//             const stopType = stopTypeFromObject(stop)
//             if (stopType === 'CS') {
//               const appointments = orderObject.order_appointment || []
//               const cs_sch_start_time = getScheduledTime(
//                 appointments,
//                 'start_datetime',
//                 'hh:mma',
//                 timeZoneId,
//               )
//               const cs_sch_end_time = getScheduledTime(
//                 appointments,
//                 'end_datetime',
//                 'hh:mma',
//                 timeZoneId,
//               )
//               const scheduled_start_date_time_with_tz = !isEmpty(
//                 stop.scheduled_start_date_time_with_tz,
//               )
//                 ? moment
//                     .utc(stop.scheduled_start_date_time_with_tz)
//                     .format('hh:mma')
//                 : ''
//               const scheduled_end_date_time_with_tz = !isEmpty(
//                 stop.scheduled_end_date_time_with_tz,
//               )
//                 ? moment
//                     .utc(stop.scheduled_end_date_time_with_tz)
//                     .format('hh:mma')
//                 : ''
//               const estimated_arrival_date_time_with_tz = !isEmpty(
//                 stop.estimated_arrival_date_time_with_tz,
//               )
//                 ? moment
//                     .utc(stop.estimated_arrival_date_time_with_tz)
//                     .format('hh:mma')
//                 : ''
//               const stopName = contactDetails.contact_name
//                 ? contactDetails.contact_name
//                 : ''
//               const city =
//                 stop.location &&
//                 stop.location.l_address &&
//                 stop.location.l_address.city
//                   ? stop.location.l_address.city
//                   : ''
//               const street =
//                 stop.location &&
//                 stop.location.l_address &&
//                 stop.location.l_address.address_line1
//                   ? stop.location.l_address.address_line1
//                   : ''
//               const zipcode =
//                 stop.location &&
//                 stop.location.l_address &&
//                 stop.location.l_address.zipcode
//                   ? stop.location.l_address.zipcode
//                   : ''
//               stopsOrders.push(
//                 Object.assign({}, orderObject, {
//                   consignee: stopName,
//                   stop_no: sortedRoutesStops[index].stop_number,
//                   stop_id: stop.id,
//                   stop_name: sortedRoutesStops[index].formatted_stop_name,
//                   cs_sch_start_time,
//                   cs_sch_end_time,
//                   stop_sch_start_time: scheduled_start_date_time_with_tz,
//                   stop_sch_end_time: scheduled_end_date_time_with_tz,
//                   stop_arrival_time: estimated_arrival_date_time_with_tz,
//                   street,
//                   city,
//                   zipcode,
//                   status: stop.status,
//                   stop_errors: stop.stop_errors,
//                   id: orderObject.customer_order_id,
//                   timeZoneId,
//                 }),
//               )
//             }
//           }
//         })
//         if (index > 0) {
//           if (stop.status === 'COMPLETED') {
//             sortedRoutesStops[index - 1].completion_class = 'completedRoute'
//           } else {
//             sortedRoutesStops[index - 1].completion_class = 'inCompletedRoute'
//           }
//         }

//         const interconnectionIndex = _.findIndex(interconnections, [
//           'fromWaypoint',
//           stop.id,
//         ])
//         if (interconnectionIndex >= 0) {
//           sortedRoutesStops[index].interconnection_status = true
//           sortedRoutesStops[index].distance = interconnections[
//             interconnectionIndex
//           ].distance
//             ? metersToOtherMeasurements(
//                 interconnections[interconnectionIndex].distance,
//                 'miles',
//               )
//             : 'NA'
//           sortedRoutesStops[index].time = interconnections[interconnectionIndex]
//             .time
//             ? secondsToHms(
//                 interconnections[interconnectionIndex].time,
//                 true,
//                 true,
//                 false,
//               )
//             : 0
//           sortedRoutesStops[index].waiting = interconnections[
//             interconnectionIndex
//           ].waiting
//             ? secondsToHms(
//                 interconnections[interconnectionIndex].waiting,
//                 true,
//                 true,
//                 false,
//               )
//             : '-'
//           sortedRoutesStops[index].rest = interconnections[interconnectionIndex]
//             .rest
//             ? secondsToHms(
//                 interconnections[interconnectionIndex].rest,
//                 true,
//                 true,
//                 false,
//               )
//             : '-'
//         } else {
//           sortedRoutesStops[index].distance = 'NA'
//           sortedRoutesStops[index].time = 'NA'
//           sortedRoutesStops[index].waiting = 'NA'
//           sortedRoutesStops[index].rest = 'NA'
//           sortedRoutesStops[index].interconnection_status = false
//         }
//       })

//       // const sortedOrders = _.sortBy([...stopsOrders], 'type_of_order');
//       routeDetails.routeOrders = [...stopsOrders]
//       routeDetails.stops = [...sortedRoutesStops]
//       let totalEstTime = getExecutionTime(routeDetails)
//       totalEstTime = totalEstTime ? `${totalEstTime} Approx.` : 'NA'
//       routeDetails.totalExecTime = totalEstTime
//       return routeDetails
//     }
//   }
//   return null
// }

export const setRouteObject = (
  navRoute,
  routeIndex,
  notIncludedStops = [ 'DISPATCHED', 'COMPLETED' ],
) => {
  if (isEmpty(navRoute)) {
    return null;
  }

  const currentUser = userStore.getStateValue('id');
  const { primary_driver, secondary_driver, fleet_id, fleet_name, stops, color_code } = navRoute;
  const navRouteOrders = navRoute.customer_orders || [];

  if (!notIncludedStops.includes(navRoute.nav_route_status) && stops.length > 0) {
    const secHrs = secondsToHms(navRoute.total_time, true, false, false) || 'NA';
    const totalDistance = navRoute.total_distance ? `${parseFloat(navRoute.total_distance / 1609).toFixed(2)} mi.` : 'NA';
    const formattedName = driverNameFromRoute(navRoute, 'object');
    const formattedCode = drivercodeFromRoute(navRoute, 'object');
    const bgColor = color_code ? formateColor(color_code) : AppConfig.colors[ routeIndex ];

    const routeDetails = {
      isLocked: navRoute.locked === true && navRoute.locked_by !== currentUser,
      locked: navRoute.locked === true,
      locked_by: navRoute.locked_by || '',
      locked_by_user_name: navRoute.locked_by_user_name || '',
      warehouse_id: navRoute.warehouse_id,
      warehouse_name: navRoute.warehouse_name,
      route_id: navRoute.nav_route_id,
      route_name: navRoute.nav_route_name,
      route_type: navRoute.route_type,
      fleet_name: fleet_name,
      route_start_datetime: navRoute.route_start_datetime,
      route_end_datetime: navRoute.route_end_datetime,
      route_confirmed_at: navRoute.route_confirmed_at,
      optimization_type: navRoute.optimization_type,
      optimized: navRoute.optimized,
      pinned: navRoute.optimization_type === 'manual',
      status: navRoute.nav_route_status,
      bgColor,
      fgColor: contrast(bgColor),
      optimizer_readable_error_message: navRoute.optimizer_readable_error_message || [],
      stops: [],
      routeOrders: [],
      customer_order_ids: navRoute.customer_order_ids || [],
      formattedName,
      formattedCode,
      displayName: formattedCode || formattedName,
      driversInfo: {
        primary: {
          driverId: primary_driver ? primary_driver.id : '',
          allotedDriver: primary_driver
            ? { ...primary_driver, full_name: formatFullName(primary_driver) }
            : {},
          selectedDriverId: '',
        },
        secondary: {
          driverId: secondary_driver ? secondary_driver.id : '',
          allotedDriver: secondary_driver
            ? { ...secondary_driver, full_name: formatFullName(secondary_driver) }
            : {},
          selectedDriverId: '',
        },
      },
      truckInfo: {
        assignedId: fleet_id || '',
        allotedTruck: fleet_id ? { fleet_id, name: fleet_name } : {},
        selectedId: '',
      },
      total_time: secHrs,
      total_distance: totalDistance,
      vehicle_weight: primary_driver?.vehicle_weight || '',
      vehicle_volume: primary_driver?.vehicle_surface_area || '',
      vehicle_type: primary_driver?.vehicle_type || '',
      total_weight: findNumberTotal(navRouteOrders, 'weight', null, 0),
      stick_start_time: navRoute.stick_start_time,
      estimated_truck_revenue : navRoute.estimated_truck_revenue,
    };

    const sortedRoutesStops = _.sortBy(stops, [ 'stop_order_sequence' ]);

    if (sortedRoutesStops.length > 0) {
      sortedRoutesStops[ sortedRoutesStops.length - 1 ].completion_class = 'inCompletedRoute';
    }

    const { interconnections } = navRoute;
    const stopsOrders = [];

    sortedRoutesStops.forEach((stop, index) => {
      stop.location_type = stopTypeFromObject(stop);
      stop.visibility = false;
      stop.orders = [];
      const customerOrdersRefs = stop.customer_order_ids;

      if (
        routeDetails.optimizer_readable_error_message.length === 0 &&
        stop.stop_errors &&
        stop.stop_errors.length > 0
      ) {
        routeDetails.optimizer_readable_error_message = [ ...stop.stop_errors ];
      }

      stop.stop_number = index + 1;
      const timeZoneId = stop.location?.timeZoneId || '';
      stop.timeZoneId = timeZoneId;

      const contactDetails = stop.contact_details || {};
      stop.formatted_stop_name = formatStopName(contactDetails, stop.location, index + 1);

      const sortedCustomerOrdersRefs = setOrdersinSorting(navRouteOrders, customerOrdersRefs);

      _.uniq(sortedCustomerOrdersRefs).forEach((orderRef) => {
        const orderObject = _.find(navRouteOrders, { customer_order_id: orderRef });

        if (orderObject) {
          const isTransferOrder = ["T", "LH"].includes(orderObject.type_of_order);
          // const stop_location = orderObject.location_ids && stop.location ? _.find(orderObject.location_ids, { id: stop.location.id}) : '';
          // const stop_location = isTransferOrder ?  orderObject.location_ids && stop.location?.id ? _.find(orderObject.location_ids, { id: stop.location.id}) : {} : orderObject.location_ids[0]
          const stop_location = isTransferOrder ?  orderObject.location_ids && stop.location?.id ? _.find(orderObject.location_ids, { id: stop.location.id}) : {} : Array.isArray(orderObject.location_ids) && orderObject.location_ids.length > 0 ? orderObject.location_ids[0] : {} 

          const type_of_loc = stop.location?.type_of_loc ? stop.location.type_of_loc : '';
          const service_duration = isTransferOrder && stop_location ? stop_location.service_duration : orderObject.service_duration
          stop.orders.push({...orderObject,stop_location, type_of_loc, service_duration});
          const stopType = stopTypeFromObject(stop);

          if (stopType === 'CS') {
            const appointments = orderObject.order_appointment || [];
            const currentLocAppts = isTransferOrder ? (stop_location && !isEmpty(stop_location.loc_order_sequence) ? [appointments[stop_location.loc_order_sequence]] : []) : appointments;
            // const currentLocAppts = isTransferOrder ? [appointments[ stop_location.loc_order_sequence ]] : appointments;
            const cs_sch_start_time = getScheduledTime(currentLocAppts, 'start_datetime', 'hh:mma', timeZoneId);
            const cs_sch_end_time = getScheduledTime(currentLocAppts, 'end_datetime', 'hh:mma', timeZoneId);
            const scheduled_start_date_time_with_tz = stop.scheduled_start_date_time_with_tz ? moment.utc(stop.scheduled_start_date_time_with_tz).format('hh:mma') : '';
            const scheduled_end_date_time_with_tz = stop.scheduled_end_date_time_with_tz ? moment.utc(stop.scheduled_end_date_time_with_tz).format('hh:mma'): '';
            const estimated_arrival_date_time_with_tz = stop.estimated_arrival_date_time_with_tz ? moment.utc(stop.estimated_arrival_date_time_with_tz).format('hh:mma') : '';
            const stopName = contactDetails.contact_name || '';
            const city = stop.location?.l_address?.city || '';
            const street = stop.location?.l_address?.address_line1 || '';
            const zipcode = stop.location?.l_address?.zipcode || '';
            stopsOrders.push({
              ...orderObject,
              // consignee: stopName,
              consignee: isTransferOrder ?  `${stop_location?.first_name || ''} ${stop_location?.last_name || ''}` : stopName,
              stop_no: stop.stop_number,
              stop_id: stop.id,
              stop_name: stop.formatted_stop_name,
              cs_sch_start_time,
              cs_sch_end_time,
              stop_sch_start_time: scheduled_start_date_time_with_tz,
              stop_sch_end_time: scheduled_end_date_time_with_tz,
              stop_arrival_time: estimated_arrival_date_time_with_tz,
              street,
              city,
              zipcode,
              status: stop.status,
              stop_errors: stop.stop_errors,
              id: orderObject.customer_order_id,
              timeZoneId,
              type_of_loc,
              stop_location: stop_location, 
              stop_location_id: stop_location?.id ? stop_location.id : '',
              loc_status: stop_location?.status ? stop_location.status : '',
              service_duration: isTransferOrder && stop_location ? stop_location.service_duration : orderObject.service_duration

            });
          }
        }
      });

      if (index > 0) {
        sortedRoutesStops[ index - 1 ].completion_class = stop.status === 'COMPLETED' ? 'completedRoute' : 'inCompletedRoute';
      }

      const interconnectionIndex = _.findIndex(interconnections, [ 'fromWaypoint', stop.id ]);

      if (interconnectionIndex >= 0) {
        stop.interconnection_status = true;
        stop.distance = interconnections[ interconnectionIndex ].distance
          ? metersToOtherMeasurements(interconnections[ interconnectionIndex ].distance, 'miles')
          : 'NA';
        stop.time = interconnections[ interconnectionIndex ].time
          ? secondsToHms(interconnections[ interconnectionIndex ].time, true, true, false)
          : 0;
        stop.waiting = interconnections[ interconnectionIndex ].waiting
          ? secondsToHms(interconnections[ interconnectionIndex ].waiting, true, true, false)
          : '-';
        stop.rest = interconnections[ interconnectionIndex ].rest
          ? secondsToHms(interconnections[ interconnectionIndex ].rest, true, true, false)
          : '-';
      } else {
        stop.distance = 'NA';
        stop.time = 'NA';
        stop.waiting = 'NA';
        stop.rest = 'NA';
        stop.interconnection_status = false;
      }
      sortedRoutesStops[index] = stop;
    });

    routeDetails.routeOrders = stopsOrders;
    routeDetails.stops = sortedRoutesStops;

    let totalEstTime = getExecutionTime(routeDetails);
    totalEstTime = totalEstTime ? `${totalEstTime} Approx.` : 'NA';
    routeDetails.totalExecTime = totalEstTime;

    return routeDetails;
  }

  return null;
};


export const removeRoutesFromUsers = (users) => {
  const newUsers = users.map((user, index) =>
    assignRouteInfo(user, index, {}, true),
  )
  return newUsers
}

export const setUsersWithRouteInfo = (UsersRouteInfo = [], usersData = []) => {
  const users = [...usersData]
  UsersRouteInfo.forEach((route) => {
    const hasStops = !isEmpty(route.stops) && route.stops.length > 0
    const primaryIndex = _.findIndex(users, [
      'id',
      route.driversInfo.primary.driverId,
    ])
    if (primaryIndex >= 0) {
      users[primaryIndex] = assignRouteInfo(
        users[primaryIndex],
        primaryIndex,
        hasStops ? route : {},
        hasStops ? false : true,
      )
    }
    const secondaryIndex = _.findIndex(users, [
      'id',
      route.driversInfo.secondary.driverId,
    ])
    if (secondaryIndex >= 0) {
      users[secondaryIndex] = assignRouteInfo(
        users[secondaryIndex],
        secondaryIndex,
        hasStops ? route : {},
        hasStops ? false : true,
      )
    }
  })
  const sortedUsers = _.sortBy(users, 'displayName')
  return sortedUsers
}

export const handleRouteReponse = (result, state) => {
  const routeInfo = []
  /* Preplan Flow Change */
  const preplanZones = result.preplan.delivery_zones || []
  let users = []
  if (result.preplan.drivers) {
    users = result.preplan.drivers.map((user, index) =>
      assignRouteInfo(user, index, {}, true),
    )
  }
  const currentWarehouse = !_.isNil(result.preplan.warehouse)
    ? result.preplan.warehouse
    : {}
  /* Edit  Preplan Flow Change */
  if (
    result.preplan.nav_route_details &&
    result.preplan.nav_route_details.length > 0
  ) {
    const navRoutes = result.preplan.nav_route_details.filter(
      (navRoute) =>
        !['DISPATCHED', 'COMPLETED'].includes(navRoute.nav_route_status) &&
        navRoute.stops &&
        navRoute.stops.length > 0,
    )
    navRoutes.forEach((navRoute, routeIndex) => {
      const routeDetails = setRouteObject(navRoute, routeIndex)
      if (routeDetails) {
        routeDetails.isLocked =
          routeDetails.isLocked ||
          routeDetails.warehouse_id !== currentWarehouse.id
        routeInfo.push(routeDetails)
      }
    })
  }
  // const users = [...state.users];   // Preplan Flow Change
  const usersWithRouteInfo = setUsersWithRouteInfo(routeInfo, users)
  const newState = {
    inProgress: false,
    routeInfo: _.sortBy(routeInfo, "displayName"),
    currentPreplan: result.preplan,
    searchDate: result.preplan.delivery_start_date,
    warehouse_id: result.preplan.warehouse_id,
    currentWarehouse,
    filter: Object.assign({}, state.filter, {
      startDate: result.preplan.delivery_start_date,
      endDate: result.preplan.delivery_end_date,
    }),
    filterPlaceHolder: Object.assign({}, state.filterPlaceHolder, {
      startDate: result.preplan.delivery_start_date,
      endDate: result.preplan.delivery_end_date,
    }),
    users: usersWithRouteInfo,
    // preplanZones: [], // preplanZones, for showing all delivery zones
    zone_ids: preplanZones.map((zone) => zone.delivery_zone_id),
    driver_zones: !isEmpty(result.preplan.driver_zones) ? result.preplan.driver_zones : [],
  };
  return newState
}

export const handlePreplanReponse = (result, state) => {
  const routeInfo = []
  if (
    result.preplan.nav_route_details &&
    result.preplan.nav_route_details.length > 0
  ) {
    const navRoutes = result.preplan.nav_route_details.filter(
      (navRoute) =>
        !['DISPATCHED', 'COMPLETED'].includes(navRoute.nav_route_status) &&
        navRoute.stops &&
        navRoute.stops.length > 0,
    )
    navRoutes.forEach((navRoute, routeIndex) => {
      const routeDetails = setRouteObject(navRoute, routeIndex)
      if (routeDetails) {
        routeInfo.push(routeDetails)
      }
    })
  }
  const newState = {
    inProgress: false,
    routeInfo,
    currentPreplan: result.preplan,
    currentWarehouse: !_.isNil(result.preplan.warehouse)
      ? result.preplan.warehouse
      : {},
  }
  return newState
}

export const checkRouteIsEmpty = (route, type) => {
  if (type === 'order') {
    if (route.customer_order_ids && route.customer_order_ids.length === 0) {
      return true
    }
  } else if (type === 'stop') {
    const stops = route.stops && _.isArray(route.stops) ? route.stops : []
    const csDepos = stops.filter(
      (stop) => stop.location && stop.location.l_type === 'CS',
    )
    if (csDepos.length === 0) {
      return true
    }
  }
  return false
}


function getAppointmentsForToday(order , orderLocs) {
  const { appointments } = order;
  const todaysAppts  = appointments.filter((appointment) => {
    const loc = orderLocs.find((loc) => loc.id === appointment.location_id);
    return loc;
  }
  ).map((appointment) => {
    const loc = orderLocs.find((loc) => loc.id === appointment.location_id);
    return ({...appointment, type_of_loc: loc ? loc.type_of_loc : ''})
  }
  )
  return todaysAppts;
}

const getFirstPickupAndLastDelivery = (locations, appoinments) => {
  const firstPickup = locations.find((location) => location.type_of_loc === 'PICKUP');
  const lastDelivery = locations.find((location) => location.type_of_loc === 'DELIVERY');
  const firstPickupAppoinment = firstPickup ? appoinments.find((appoinment) => appoinment.location_id === firstPickup.id) : null;
  const lastDeliveryAppoinment = lastDelivery ? appoinments.find((appoinment) => appoinment.location_id === lastDelivery.id) : null;
  return {
    firstPickup,
    lastDelivery,
    firstPickupAppoinment: firstPickupAppoinment ? { ...firstPickupAppoinment, type_of_loc: "PICKUP" } : null,
    lastDeliveryAppoinment: lastDeliveryAppoinment ? { ...lastDeliveryAppoinment, type_of_loc: "DELIVERY" } : null,
  };
};

const getTransferLocations = (customerOrder) => {

  const { firstPickupAppoinment, lastDeliveryAppoinment } = getFirstPickupAndLastDelivery(customerOrder.locations, customerOrder.appointments);
  return [ firstPickupAppoinment, lastDeliveryAppoinment ].filter((appoinment) => appoinment !== null);
}

export const setOrderResponse = (customerOrder) => {
  const orderZones =
    !isEmpty(customerOrder.order_zones) &&
    _.isArray(customerOrder.order_zones)
      ? customerOrder.order_zones
      : []
  const currentLocation = customerOrder.locations.find((loc) => loc.id === customerOrder.cs_location_id);
  const isTransferOrder = ["T", "LH"].includes(customerOrder.type_of_order);
  const order = Object.assign({}, customerOrder, {
    // appointments: getAppointmentsForToday(customerOrder , customerOrder.locations) || [],
    // appointments: [customerOrder.appointments.find((appointment) => appointment.location_id === currentLocation.id)] || [],
    appointments: isTransferOrder ? getTransferLocations(customerOrder) : getAppointmentsForToday(customerOrder, customerOrder.locations) || [],
    scheduled_start_datetime_with_tz:
      customerOrder.scheduled_start_datetime_with_tz,
    scheduled_end_datetime_with_tz:
      customerOrder.scheduled_end_datetime_with_tz,
    timeZoneName: customerOrder.timeZoneName,
    // tz_short_form: customerOrder.tz_short_form,
    customer_address:
      currentLocation && currentLocation.l_address
        ? currentLocation.l_address
        : {},
    // warehouse_address:
    //   customerOrder.wh_location && customerOrder.wh_location.l_address
    //     ? customerOrder.wh_location.l_address
    //     : {},
    order_zone_name: orderZones,
    zoneName: orderZones.map((zone) => zone.zone_name).join(', '),
    startTime: getStartTime(customerOrder.appointments),
    locations: customerOrder.locations && _.isArray(customerOrder.locations) ? 
    _.sortBy(customerOrder.locations, 'loc_order_sequence') : [],
  })
  return order
}
// export const setOrderResponse = (customerOrder) => {
//   const orderZones =
//     !isEmpty(customerOrder.order.order_zones) &&
//     _.isArray(customerOrder.order.order_zones)
//       ? customerOrder.order.order_zones
//       : []
//   const order = Object.assign({}, customerOrder.order, {
//     appointments: getAppointmentsForToday(customerOrder , customerOrder.order.locations) || [],
//     nav_route_id: customerOrder.nav_route_id,
//     nav_route_name: customerOrder.nav_route_name,
//     scheduled_start_datetime_with_tz:
//       customerOrder.scheduled_start_datetime_with_tz,
//     scheduled_end_datetime_with_tz:
//       customerOrder.scheduled_end_datetime_with_tz,
//     timeZoneName: customerOrder.timeZoneName,
//     tz_short_form: customerOrder.tz_short_form,
//     customer_address:
//       customerOrder.cs_location && customerOrder.cs_location.l_address
//         ? customerOrder.cs_location.l_address
//         : {},
//     warehouse_address:
//       customerOrder.wh_location && customerOrder.wh_location.l_address
//         ? customerOrder.wh_location.l_address
//         : {},
//     order_zone_name: orderZones,
//     zoneName: orderZones.map((zone) => zone.zone_name).join(', '),
//     startTime: getStartTime(customerOrder.order_appointments),
//     locations: customerOrder.order?.locations ? customerOrder.order.locations: [],
//   })
//   return order
// }

export const setOrderSequence = (orderInfo = [], sequnceIds = []) => {
  const records = orderInfo.filter((order) => sequnceIds.includes(order.id))
  const sortedOrders = records.sort(
    (a, b) => sequnceIds.indexOf(a.id) - sequnceIds.indexOf(b.id),
  )
  const mapObjects = sortedOrders.map((order) => ({
    lat: order.lat,
    lng: order.lng,
  }))
  return mapObjects
}

// find only dependency orders
export const getDependencyOrders = (
  data,
  compareKey,
  sourceKey,
  sourceId,
  responceType = 'id',
  sourceStopId = ''
) => {
  if (
    !isEmpty(compareKey) &&
    !isEmpty(sourceId) &&
    !isEmpty(data) &&
    _.isArray(data)
  ) {
    const filteredRecords = data.filter(
      (record) => record[compareKey] === sourceId || (sourceStopId && record[sourceKey] === sourceId && ["T", "LH"].includes(record['type_of_order']) && record['stop_id'] !== sourceStopId),
    )
    const records = filteredRecords.map((record) =>
      responceType === 'id' ? record[sourceKey] : record,
    )
    return records
  }
  return []
}

// find only dependency stops
export const getDependencyStops = (
  route,
  stopId,
  responceType = 'id',
  stopKey = 'stops',
) => {
  if (!isEmpty(route) && !_.isArray(route)) {
    const currentStop = _.find(route[stopKey], { id: stopId })
    if (!isEmpty(currentStop)) {
      // Assuming for transfer order same order ids are there
      const currentDepencyOrders = currentStop.orders
        .filter((order) => (!["T", "LH"].includes(order.type_of_order) && !isEmpty(order.related_order)) || ["T", "LH"].includes(order.type_of_order))
        .map((order) => !["T", "LH"].includes(order.type_of_order) ? order.related_order : order.customer_order_id)
      const dependencystops = route[stopKey].filter(
        (stop) =>
          stop.location &&
          stop.location.l_type === 'CS' &&
          ['PENDING', 'UNALLOCATED'].includes(stop.status) &&
          stop.id !== stopId &&
          findCommonElementsExisting(
            currentDepencyOrders,
            stop.customer_order_ids,
          ),
      )
      if (responceType === 'id') {
        const stopIds = dependencystops.map((stop) => stop.id)
        return stopIds
      }
      return dependencystops
    }
  }
  return []
}

// find  dependency stops including source id
export const findDependencyStops = (
  route,
  stopId,
  responceType = 'id',
  stopKey = 'stops',
) => {
  const dependencyStops = getDependencyStops(
    route,
    stopId,
    responceType,
    stopKey,
  )
  let removalStops = []
  if (responceType === 'id') {
    removalStops =
      dependencyStops.length === 0
        ? [stopId]
        : [].concat([stopId], dependencyStops)
  } else {
    const currentStop = _.find(route[stopKey], { id: stopId })
    removalStops =
      dependencyStops.length === 0
        ? [{ ...currentStop }]
        : [].concat([{ ...currentStop }], dependencyStops)
  }
  return removalStops
}

export const handleDependencyOrder = (source, orders, typeOfOrder, key) => {
  if (AppConfig.deliveryOrderTypes.includes(typeOfOrder)) {
    return [].concat(orders, [source])
  }
  return [].concat([source], orders)
}

// find  dependency orders including source id
export const findDependencyOrders = (
  data,
  compareKey,
  sourceKey,
  sourceId,
  responceType = 'id',
  orderType = 'type_of_order',
) => {
  const dependencyItems = getDependencyOrders(
    data,
    compareKey,
    sourceKey,
    sourceId,
    responceType,
  )
  let dependencies = []
  const currentOrder = _.find(data, { [sourceKey]: sourceId })
  if(!isEmpty(currentOrder)){
  if (responceType === 'id') {
    dependencies = handleDependencyOrder(
      sourceId,
      dependencyItems,
      currentOrder[orderType],
    )
    // removalIds = dependencyItems.length === 0 ? [sourceId] : [].concat([sourceId], dependencyItems);
  } else {
    dependencies = handleDependencyOrder(
      currentOrder,
      dependencyItems,
      currentOrder[orderType],
    )
    // removalIds = dependencyItems.length === 0 ? [{ ...currentOrder }] : [].concat([{ ...currentOrder }], dependencyItems);
  }
}

  return dependencies
}

// checks order has particular type
export const hasOrderType = (orders, key, orderType = '') => {
  if (!isEmpty(orders) && _.isArray(orders) && orders.length > 0) {
    const hasOrderTypeOrders = orders.filter(
      (order) => order[key] === orderType,
    )
    return hasOrderTypeOrders.length > 0
  }
  return false
}

// checks stops are following particular type
// checks stops are following particular type
export const checkDependancyOrderSeq = (
  route,
  sourceIndex,
  destinationIndex,
) => {
  const stops = route.stops ? [...route.stops] : []
  const customerStops = stops.filter(
    (stop) => stop.location && stop.location.l_type === 'CS',
  )
  if (!isEmpty(stops) && _.isArray(stops) && customerStops[sourceIndex]) {
    const currentStop = customerStops[sourceIndex]
    const stopOrders = currentStop.orders || []
    let hasCorrectOrder = true
    if (stopOrders.length > 0) {
      stopOrders.forEach((currentOrder) => {
        if (currentOrder.type_of_order !== 'X') {
          if(["T", "LH"].includes(currentOrder.type_of_order)){
            const dependencyStops = getDependencyStops(
              route,
              currentStop.id,
              'object',
              'stops',
            )
            if(dependencyStops.length > 0){
              dependencyStops.forEach(stop => {
                const dependencyStopIndex = _.findIndex(customerStops, [
                  'id',
                  stop.id,
                ])
                if(currentStop.stop_order_sequence > stop.stop_order_sequence &&  destinationIndex <= dependencyStopIndex){
                  hasCorrectOrder = false;
                } else if(currentStop.stop_order_sequence < stop.stop_order_sequence &&  destinationIndex >= dependencyStopIndex){
                  hasCorrectOrder = false;
                }
              })
            }
          } else {
            const dependencyStops = getDependencyStops(
              route,
              currentStop.id,
              'object',
              'stops',
            )
            if (dependencyStops.length > 0) {
              const dependencyTypeStops = dependencyStops.filter(
                (stop) =>
                  stop.orders &&
                  hasOrderType(
                    stop.orders,
                    'type_of_order',
                    AppConfig.dependancyOrderRelatedType[
                      currentOrder.type_of_order
                    ]
                      ? AppConfig.dependancyOrderRelatedType[
                          currentOrder.type_of_order
                        ]
                      : '',
                  ),
              )
              if (dependencyTypeStops.length > 0) {
                dependencyTypeStops.forEach((stop) => {
                  const dependencyStopIndex = _.findIndex(customerStops, [
                    'id',
                    stop.id,
                  ])
                  if (dependencyStopIndex >= 0) {
                    if (
                      AppConfig.deliveryOrderTypes.includes(
                        currentOrder.type_of_order,
                      ) &&
                      !(
                        dependencyStopIndex < destinationIndex ||
                        (dependencyStopIndex === destinationIndex &&
                          dependencyStopIndex > sourceIndex)
                      ) // should be pickup < delivery
                    ) {
                      // sourceIndex current : delivery order, destinationIndex
                      // dependency : pickup order, dependencyStopIndex
                      hasCorrectOrder = false
                      // return false;
                    } else if (
                      AppConfig.pickOrderTypes.includes(
                        currentOrder.type_of_order,
                      ) &&
                      !(
                        destinationIndex < dependencyStopIndex ||
                        (destinationIndex === dependencyStopIndex &&
                          sourceIndex >= dependencyStopIndex)
                      ) // should be pickup < delivery
                    ) {
                      // sourceIndex current : pickup order,  destinationIndex
                      // dependency : delivery order,  dependencyStopIndex
                      hasCorrectOrder = false
                      // return false;
                    }
                  }
                })
              }
            }
         }
        }
      })
    }
    if(!hasCorrectOrder){
      alertMessage('Delivery order should be after pickup order', 'warning')
    }
    return hasCorrectOrder
  }
  return true
}

export const rearrangeBySeq = (orders, sourceKey = 'id') => {
  let seqOrders = []
  orders.forEach((order) => {
    if (_.findIndex(seqOrders, [sourceKey, order[sourceKey]]) < 0) {
      const dependencyOrders = getDependencyOrders(
        orders,
        'related_order',
        sourceKey,
        order[sourceKey],
        'object',
        order.stop_id ? order.stop_id : ''
      )
      const sequencedOrders = handleDependencyOrder(
        order,
        dependencyOrders,
        order.type_of_order,
      )
      seqOrders = [].concat(seqOrders, sequencedOrders)
    }
  })
  return seqOrders
}

export const retrieveCustomerStops = (route, rearrangeForm = true) => {
  const ignoredStopTypes = ['WH', 'DV']
  let tempStops = [...(route.stops || [])]
  if (tempStops.length > 2 && rearrangeForm) {
    tempStops = rearrangeStops(tempStops, false)
    const stopsData = tempStops.filter(
      (stop) =>
        stop.location &&
        !ignoredStopTypes.includes(stop.location.l_type) &&
        stop.status !== 'COMPLETED' &&
        stop.status !== 'ARRIVED',
    )
    tempStops = [...stopsData]
  }
  return tempStops
}

export const setRouteOrders = (stops) => {
  const stopsOrders = []
  stops.forEach((stop, index) => {
    stop.orders.forEach((orderObject) => {
      if (!_.isNil(orderObject)) {
        const stopType = stopTypeFromObject(stop)
        if (stopType === 'CS') {
          const contactDetails = stop.contact_details
            ? stop.contact_details
            : {}
          const scheduled_start_date_time_with_tz = !isEmpty(
            stop.scheduled_start_date_time_with_tz,
          )
            ? moment
                .utc(stop.scheduled_start_date_time_with_tz)
                .format('hh:mma')
            : ''
          const scheduled_end_date_time_with_tz = !isEmpty(
            stop.scheduled_end_date_time_with_tz,
          )
            ? moment.utc(stop.scheduled_end_date_time_with_tz).format('hh:mma')
            : ''
          const estimated_arrival_date_time_with_tz = !isEmpty(
            stop.estimated_arrival_date_time_with_tz,
          )
            ? moment
                .utc(stop.estimated_arrival_date_time_with_tz)
                .format('hh:mma')
            : ''
          const stopName = contactDetails.contact_name
            ? contactDetails.contact_name
            : ''
          const street =
            stop.location &&
            stop.location.l_address &&
            stop.location.l_address.address_line1
              ? stop.location.l_address.address_line1
              : ''
          const city =
            stop.location &&
            stop.location.l_address &&
            stop.location.l_address.city
              ? stop.location.l_address.city
              : ''
          const zipcode =
            stop.location &&
            stop.location.l_address &&
            stop.location.l_address.zipcode
              ? stop.location.l_address.zipcode
              : ''
          const timeZoneId =
            stop.location && stop.location.timeZoneId
              ? stop.location.timeZoneId
              : ''
          const appointments = orderObject.order_appointment || []
          const cs_sch_start_time = getScheduledTime(
            appointments,
            'start_datetime',
            'hh:mma',
            timeZoneId,
          )
          const cs_sch_end_time = getScheduledTime(
            appointments,
            'end_datetime',
            'hh:mma',
            timeZoneId,
          )
          // const stop_location = orderObject.location_ids && stop.location ? _.find(orderObject.location_ids, { id: stop.location.id}) : '';
          const isTransferOrder = ["T", "LH"].includes(orderObject.type_of_order);
          const stop_location = isTransferOrder ?  orderObject.location_ids && stop.location?.id ? _.find(orderObject.location_ids, { id: stop.location.id}) : {} : Array.isArray(orderObject.location_ids) && orderObject.location_ids.length > 0 ? orderObject.location_ids[0] : {}
          const type_of_loc = stop.location?.type_of_loc ? stop.location.type_of_loc : '';
          stopsOrders.push(
            Object.assign({}, orderObject, {
              consignee: stopName,
              stop_no: index + 1,
              stop_id: stop.id,
              stop_name: stop.formatted_stop_name,
              stop_sch_start_time: scheduled_start_date_time_with_tz,
              stop_sch_end_time: scheduled_end_date_time_with_tz,
              stop_arrival_time: estimated_arrival_date_time_with_tz,
              street,
              city,
              zipcode,
              status: stop.status,
              stop_errors: stop.stop_errors,
              id: orderObject.customer_order_id,
              cs_sch_start_time,
              cs_sch_end_time,
              timeZoneId,
              type_of_loc,
              stop_location: stop_location, 
              stop_location_id: stop_location?.id ? stop_location.id : '',
              loc_status: stop_location?.status ? stop_location.status : '',
              
            }),
          )
        }
      }
    })
  })
  return stopsOrders
}

export const unlinkRouteDetailsFromUser = (users = [], id) => {
  if (!isEmpty(id) && !isEmpty(users)) {
    const userIndex = _.findIndex(users, ['id', id])
    if (userIndex >= 0) {
      users[userIndex] = assignRouteInfo(users[userIndex], userIndex, {}, true)
    }
  }
  return users
}

export const getLockedUserName = (route) => {
  if (!isEmpty(route)) {
    return `${I18n.t('messages.locked')}${
      route.locked_by_user_name ? ` by ${route.locked_by_user_name}` : ''
    }`
  }
  return ''
}

export const isRouteOptimized = (route = {}) => {
  const isNotOptimized =
    route.optimized === false &&
    (!route.optimizer_readable_error_message ||
      (_.isArray(route.optimizer_readable_error_message) &&
        route.optimizer_readable_error_message.length === 0))
  return !isNotOptimized
}

export const _renderHeader = (currentPreplan,currentWarehouse, routeInfo, filter, navigateMainScreen , isMilitaryTime = false) => {
  const timeZone = currentPreplan?.warehouse ? currentPreplan.warehouse.timeZoneId : ''
  const startDate = filter.startDate ? momentTime("YYYY-MM-DD HH:mm", filter.startDate, timeZone) : null;
  const endDate = filter.endDate ? momentTime("YYYY-MM-DD HH:mm", filter.endDate, timeZone) : null;
  const filteredInfo = routeInfo.filter(
    (route) => !isEmpty(route.status) && route.status !== "ALLOCATED"
  );
  return (
    <Row
      className="page-header borderRadius10"
      style={{ fontSize: 16, height: 40 }}
    >
      <Col xs={8}>
            <img
              src={ whiteArrow }
              alt="whitearrow"
              style={ {
                height: 20,
                width: 30,
                cursor: "pointer",
                marginTop: -2,
              } }
              onClick={() => navigateMainScreen('list')}
            />
            &nbsp;
        {I18n.t('menu.preplanroutes')}&nbsp;
        <span className="smallText fontsize11">
          {currentPreplan.name ? currentPreplan.name : ''}
        </span>
        &nbsp;
      </Col>
      <Col xs={8} className="alignCenter">
        {!isEmpty(currentWarehouse) && _.isObject(currentWarehouse) ? (
          <>{currentWarehouse.name ? `${currentWarehouse.name} : ` : ''}</>
        ) : (
          ''
        )}
        {startDate ? formatSingleDay(
          startDate
        ):''}
      </Col>
      <Col xs={7} className="alignRight">
        {filteredInfo.length > 0 && checkAccessExistance('DISPATCH') &&  
          <Button 
            type="link" 
            size="small"
            style={{ color : '#FFF'}}
            onClick={() => navigateMainScreen('dispatch')} icon="branches">
            <u>{I18n.t('menu.routes')}</u>
          </Button>
        }
      </Col>
      <Col xs={1} className="alignRight">
        <Icon type="close" onClick={() => navigateMainScreen('list')} />
      </Col>
    </Row>
  )
}

export const manageOrderAllocateProcess = (
  data,
  result,
  notificationKey,
  totalOrders,
  orderInfo,
  setStopProgress,
  setTotalOrders,
  setOrderInfo,
  setUnassignedOrdersInfo,
  promptUnprocessedOrders,
  updateRouteInfo,
  routeInfo,
  handleCurrentRoute,
  currentRoute,
  routeSelectedStops,
  setRouteSelectedStops,
  handleCurrentStop,
  currentStop,
  setCurrentRoute,
  users,
  setAssignInfo
) => {
  const unprocessed_orders = result.unprocessed_orders || []
  const unpocessedOrderNos = _.flattenDeep(
    unprocessed_orders.map((err) => !isEmpty(err.orders) && err.orders),
  )
  const ignoredNos = _.flattenDeep(
    unprocessed_orders.filter(err => err.type === 'coordinates_are_missing').map((err) => !isEmpty(err.orders) && err.order_ids),
  )
  const filterdTotalOrders = totalOrders.filter(
    (order) => !data.order_ids.includes(order.id) || ignoredNos.includes(order.id),
  )
  const filterdOrderInfo = orderInfo.filter(
    (order) => !data.order_ids.includes(order.id) || ignoredNos.includes(order.id),
  )
  const processedOrders = filterdTotalOrders
    .filter(
      (order) =>
        data.order_ids.includes(order.id) &&
        !unpocessedOrderNos.includes(order.customer_order_number),
    )
    .map((order) => order.customer_order_number)
  setStopProgress(false)
  setTotalOrders(filterdTotalOrders)
  setOrderInfo(filterdOrderInfo)
  setUnassignedOrdersInfo({
    selectedOrderKeys: [],
    selectedOrderRows: [],
  })
  if (unprocessed_orders.length > 0) {
    promptUnprocessedOrders(data.routeName, unprocessed_orders, notificationKey)
  }
  const funcType = unpocessedOrderNos.length === 0 ? 'success' : 'warning'
  notification['success']({
    key: notificationKey,
    message: data.routeName,
    description: <div>{I18n.t('messages.allocated_successfully')}</div>,
    placement: 'bottomRight',
  })

  const navRoute = result.nav_route ? result.nav_route : {}
  updateRouteInfo(
    [navRoute],
    () => {
      resetRouteNStop(
        true,
        navRoute.nav_route_id,
        routeInfo,
        handleCurrentRoute,
        currentRoute,
        routeSelectedStops,
        setRouteSelectedStops,
        handleCurrentStop,
        currentStop,
        setCurrentRoute,
        setAssignInfo
      )
    },
    routeInfo,
    users,
  )
}

export const resetRouteNStop = (
  updateNewRoute = false,
  id = '',
  routeInfo,
  handleCurrentRoute,
  currentRoute,
  routeSelectedStops,
  setRouteSelectedStops,
  handleCurrentStop,
  currentStop,
  setCurrentRoute,
  setAssignInfo
) => {
  if (updateNewRoute && id) {
    const routeIndex =
      routeInfo.length > 0 ? _.findIndex(routeInfo, ['route_id', id]) : ''
    if (routeIndex >= 0) {
      handleCurrentRoute({}, id)
    }
  } else {
    if (currentRoute.route_id) {
      const route = _.find(routeInfo, {
        route_id: currentRoute.route_id,
      })
      if (!_.isNil(route) && _.isObject(route)) {
        const selectedStops = routeSelectedStops || []
        const stopIds = route.stops
          .map((stop) => stop.id)
          .filter((stopId) => selectedStops.includes(stopId))
        setCurrentRoute(route)
        setRouteSelectedStops(stopIds)
        if (currentStop && currentStop.id) {
          handleCurrentStop({}, currentStop.id, '', currentRoute)
        }
        // when moving order to route, make the last route selected 
        setAssignInfo((prevAssignInfo) => { return { ...prevAssignInfo , checkedList: [route.route_id] } }
        )
      } else {
        const routeId = routeInfo.length > 0 ? routeInfo[0].route_id : ''
        handleCurrentRoute({}, routeId)
      }
    } else {
      const routeId = routeInfo.length > 0 ? routeInfo[0].route_id : ''
      handleCurrentRoute({}, routeId)
    }
  }
}

export const allocateToExistingRoute = (
  data,
  setStopProgress,
  resetPreplan,
  updateRouteResponse,
  totalOrders,
  orderInfo,
  setTotalOrders,
  setOrderInfo,
  setUnassignedOrdersInfo,
  promptUnprocessedOrders,
  updateRouteInfo,
  routeInfo,
  handleCurrentRoute,
  currentRoute,
  routeSelectedStops,
  setRouteSelectedStops,
  handleCurrentStop,
  users,
  currentStop,
  setCurrentRoute,
  setAssignInfo
) => {
  notification.open({
    key: data.nav_route_id,
    message: data.routeName,
    description: <div>{I18n.t('messages.updating_route')}</div>,
    icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
    placement: 'bottomRight',
    duration: 20,
  })
  data.new_customer_order_ids = data.order_ids || [],
  data.action_type = RouteLabel.actionLabels.ORDER_ADDED
  updateRoute(data).then((result) => {
    if (result.success) {
      manageOrderAllocateProcess(
        data,
        result,
        data.nav_route_id,
        totalOrders,
        orderInfo,
        setStopProgress,
        setTotalOrders,
        setOrderInfo,
        setUnassignedOrdersInfo,
        promptUnprocessedOrders,
        updateRouteInfo,
        routeInfo,
        handleCurrentRoute,
        currentRoute,
        routeSelectedStops,
        setRouteSelectedStops,
        handleCurrentStop,
        currentStop,
        setCurrentRoute,
        users,
        setAssignInfo
      )
    } else {
      // alertMessage(result.errors[0], 'error', 10);
      setStopProgress(false)

      if (result.refresh) {
        resetPreplan(false, true)
      } else if (result.nav_route) {
        updateRouteResponse(result.nav_route)
      }
      notification.error({
        key: data.nav_route_id,
        message: data.routeName,
        description: <div>{result.errors[0]}</div>,
        placement: 'bottomRight',
      })
    }
  })
}

export const onSearchNumbers = (
  filter,
  totalOrders,
  setOrderInfo,
  setZone_ids,
  setAccountCodes,
  setUnassignedOrdersInfo,
  setOrderVhTypes,
  accountCodesWithExceededLimit = [],
  setOrderTypeFilter = () => {}

) => {
  const searchToken = _.get(filter, 'search_order_token', '').toLowerCase();
  const tokens = _.chain(searchToken).replace(' ', '').split(',').value();

  const orders = _.filter(totalOrders, (order) =>
    _.some(tokens, (token) => 
      _.chain(order)
        .pick(['customer_order_number', 'hawb', 'mawb', 'reference_1', 'reference_2', 'references'])
        .values()
        .map(_.method('toLowerCase'))
        .some((value) => _.includes(value, token))
        .value()
    )
  );

  setOrderInfo(orders);
  setZone_ids([]);
  setAccountCodes([]);
  setOrderVhTypes([]);
  setOrderTypeFilter(["ALL"])
  const notExceededAccountCodes = _.filter(orders, (order) => !accountCodesWithExceededLimit?.includes(order.account_code));
  setUnassignedOrdersInfo({
    selectedOrderKeys: _.isEmpty(searchToken) ? [] : _.map(notExceededAccountCodes, 'id'),
    selectedOrderRows: _.isEmpty(searchToken) ? [] : notExceededAccountCodes,
  });
};
const handleStopOrdersChange = (
  selectedRowKeys,
  selectedRows,
  currentRoute,
  stopSelectedOrders,
  setStopSelectedOrders,
) => {
  const stopOrders =
    currentRoute && currentRoute.routeOrders ? currentRoute.routeOrders : []
  let orders = []
  if (selectedRowKeys.length > 0) {
    orders = handleDependencies(
      stopOrders,
      stopSelectedOrders.selectedKeys,
      selectedRowKeys,
      selectedRows,
    )
  }
  setStopSelectedOrders({
    selectedKeys: orders.map((order) => order.id),
    selectedRows: orders,
  })
}

export const moveOrderToRoute = (
  data,
  currentRoute,
  stopSelectedOrders,
  setStopSelectedOrders,
  promptUnprocessedOrders,
  setStopProgress,
  resetPreplan,
  setInProgress,
  handleRouteDeleteClick,
  unlinkRouteFromUsers,
  setRouteInfo,
  setUsers,
  setRouteKey,
  updateRouteInfo,
  routeInfo,
  handleCurrentRoute,
  routeSelectedStops,
  setRouteSelectedStops,
  handleCurrentStop,
  currentStop,
  setCurrentRoute,
  users,
  setAssignInfo
) => {
  const { oldRouteName } = data
  const { newRouteName } = data
  const { orderNumber } = data
  delete data.oldRouteName
  delete data.newRouteName
  delete data.orderNumber
  notification.open({
    key: data.old_nav_route_id,
    message: `Order: ${orderNumber}`,
    description: (
      <div>
        {oldRouteName}&nbsp;
        <Icon type="arrow-right" />
        &nbsp;{newRouteName}
      </div>
    ),
    icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
    placement: 'bottomRight',
    duration: 20,
  })
  data.action_type= RouteLabel.actionLabels.ORDER_MOVED;
  moveOrder(data)
    .then((result) => {
      if (result.success) {
        const unprocessed_orders = result.unprocessed_orders || []
        promptUnprocessedOrders(
          newRouteName,
          unprocessed_orders,
          data.old_nav_route_id,
        )
        notification.success({
          key: data.old_nav_route_id,
          message: `Order: ${orderNumber}`,
          description: (
            <>
              <div>
                {oldRouteName}&nbsp;
                <Icon type="arrow-right" />
                &nbsp;{newRouteName}
              </div>
              <div>{I18n.t('messages.moved_successfully')}</div>
            </>
          ),
          placement: 'bottomRight',
        })
        // setStopProgress(false);
        handleStopOrdersChange(
          [],
          [],
          currentRoute,
          stopSelectedOrders,
          setStopSelectedOrders,
        )
        checkMoveRouteElemntResponse(
          result,
          false,
          data.old_nav_route_id,
          handleRouteDeleteClick,
          unlinkRouteFromUsers,
          setRouteInfo,
          setUsers,
          setRouteKey,
          updateRouteInfo,
          routeInfo,
          handleCurrentRoute,
          currentRoute,
          routeSelectedStops,
          setRouteSelectedStops,
          handleCurrentStop,
          currentStop,
          setCurrentRoute,
          users,
          setAssignInfo
        )
      } else {
        if (result && result.refresh === true) {
          resetPreplan(false, true)
        } else if (result && (result.old_nav_route || result.new_nav_route)) {
          checkMoveRouteElemntResponse(
            { nav_route: result },
            false,
            data.old_nav_route_id,
            handleRouteDeleteClick,
            unlinkRouteFromUsers,
            setRouteInfo,
            setUsers,
            setRouteKey,
            updateRouteInfo,
            routeInfo,
            handleCurrentRoute,
            currentRoute,
            routeSelectedStops,
            setRouteSelectedStops,
            handleCurrentStop,
            currentStop,
            setCurrentRoute,
            users,
            setAssignInfo
          )
        }
        notification.error({
          key: data.old_nav_route_id,
          message: `Order: ${orderNumber}`,
          description: (
            <>
              <div>
                {oldRouteName}&nbsp;
                <Icon type="arrow-right" />
                &nbsp;{newRouteName}
              </div>
              <div>{result.errors[0]}</div>
            </>
          ),
          placement: 'bottomRight',
        })
        // setInProgress(false);
        setStopProgress(false)
      }
    })
    .finally(() => {
      setInProgress(false)
      // setStopProgress(false);
    })
}

const checkMoveRouteElemntResponse = (
  result,
  checkRouteEmpty = true,
  oldRouteId,
  handleRouteDeleteClick,
  unlinkRouteFromUsers,
  setRouteInfo,
  setUsers,
  setRouteKey,
  updateRouteInfo,
  routeInfo,
  handleCurrentRoute,
  currentRoute,
  routeSelectedStops,
  setRouteSelectedStops,
  handleCurrentStop,
  currentStop,
  setCurrentRoute,
  users,
  setAssignInfo,
) => {
  const navRoute = isEmpty(result.nav_route) ? {} : result.nav_route
  const newRoute = navRoute.new_nav_route ? navRoute.new_nav_route : {}
  const oldRoute = navRoute.old_nav_route ? navRoute.old_nav_route : {}
  const isOldRouteEmpty =
    !oldRoute.customer_order_ids ||
    (oldRoute.customer_order_ids && oldRoute.customer_order_ids.length === 0)
  const routes = []
  if (checkRouteEmpty && isOldRouteEmpty) {
    handleRouteDeleteClick(oldRoute.nav_route_id, newRoute.nav_route_id)
  } else if (isOldRouteEmpty) {
    const returnedData = unlinkRouteFromUsers(
      oldRoute.nav_route_id || oldRouteId,
    )
    setRouteInfo(returnedData.routeInfo)
    setUsers(returnedData.users)
    setRouteKey(randomNumber())
    routes.push(newRoute)
    updateRouteInfo(
      routes,
      (info , currRoute) => {
        resetRouteNStop(
          false,
          '',
          info,
          handleCurrentRoute,
          currRoute,
          routeSelectedStops,
          setRouteSelectedStops,
          handleCurrentStop,
          currentStop,
          setCurrentRoute,
          setAssignInfo
        )
      },
      returnedData.routeInfo,
      returnedData.users,
    )
  } else {
    routes.push(oldRoute)
    routes.push(newRoute)
    updateRouteInfo(
      routes,
      (info , currRoute) => {
        resetRouteNStop(
          false,
          '',
          info,
          handleCurrentRoute,
          currRoute,
          routeSelectedStops,
          setRouteSelectedStops,
          handleCurrentStop,
          currentStop,
          setCurrentRoute,
          setAssignInfo
        )
      },
      routeInfo,
      users,
    )
  }
}

const moveStopToRoute = (
  resetPreplan,
  data,
  handleRouteDeleteClick,
  unlinkRouteFromUsers,
  setRouteInfo,
  setUsers,
  setRouteKey,
  updateRouteInfo,
  handleCurrentRoute,
  currentRoute,
  routeSelectedStops,
  setRouteSelectedStops,
  handleCurrentStop,
  currentStop,
  setCurrentRoute,
  routeInfo,
  promptUnprocessedOrders,
  setStopProgress,
  users,
  setAssignInfo,
  setInProgress
) => {
  // message.loading({ content: I18n.t('messages.updating_route'), key: 'preplan', duration: 2 });
  // showFlashMessage('info', I18n.t('messages.updating_route'));
  const { oldRouteName } = data
  const { newRouteName } = data
  const { stopName } = data
  delete data.oldRouteName
  delete data.newRouteName
  delete data.stopName
  notification.open({
    key: data.old_nav_route_id,
    message: (
      <span className="fontSize14">{`Stop${
        stopName.length > 1 ? 's' : ''
      }: ${stopName}`}</span>
    ),
    description: (
      <div>
        {oldRouteName}&nbsp;
        <Icon type="arrow-right" />
        &nbsp;{newRouteName}
      </div>
    ),
    icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
    placement: 'bottomRight',
    duration: 20,
  })
  moveStop(data).then((result) => {
    if (result.success) {
      const unprocessed_orders = result.unprocessed_orders || []
      promptUnprocessedOrders(
        newRouteName,
        unprocessed_orders,
        data.old_nav_route_id,
      )
      notification.success({
        key: data.old_nav_route_id,
        message: (
          <span className="fontSize14">{`Stop${
            stopName.length > 1 ? 's' : ''
          }: ${stopName}`}</span>
        ),
        description: (
          <>
            <div>
              {oldRouteName}&nbsp;
              <Icon type="arrow-right" />
              &nbsp;{newRouteName}
            </div>
            <div>{I18n.t('messages.moved_successfully')}</div>
          </>
        ),
        placement: 'bottomRight',
      })
      setStopProgress(false)
      checkMoveRouteElemntResponse(
        result,
        false,
        data.old_nav_route_id,
        handleRouteDeleteClick,
        unlinkRouteFromUsers,
        setRouteInfo,
        setUsers,
        setRouteKey,
        updateRouteInfo,
        routeInfo,
        handleCurrentRoute,
        currentRoute,
        routeSelectedStops,
        setRouteSelectedStops,
        handleCurrentStop,
        currentStop,
        setCurrentRoute,
        users,
        setAssignInfo
      )
    } else {
      if (result && result.refresh === true) {
        resetPreplan(false, true)
      } else if (result && (result.old_nav_route || result.new_nav_route)) {
        checkMoveRouteElemntResponse(
          { nav_route: result },
          false,
          data.old_nav_route_id,
          handleRouteDeleteClick,
          unlinkRouteFromUsers,
          setRouteInfo,
          setUsers,
          setRouteKey,
          updateRouteInfo,
          routeInfo,
          handleCurrentRoute,
          currentRoute,
          routeSelectedStops,
          setRouteSelectedStops,
          handleCurrentStop,
          currentStop,
          setCurrentRoute,
          users,
          setAssignInfo
        )
      }
      notification.error({
        key: data.old_nav_route_id,
        message: <span className="fontSize14">{`Stop: ${stopName}`}</span>,
        description: (
          <>
            <div>
              {oldRouteName}&nbsp;
              <Icon type="arrow-right" />
              &nbsp;{newRouteName}
            </div>
            <div>{result.errors[0]}</div>
          </>
        ),
        placement: 'bottomRight',
      })
      setInProgress(false)
      setStopProgress(false)
    }
  })
}

export const takeConfirmation = (confirmationModalProps) => {
  const {
    allocType,
    currentRouteId,
    data,
    title,
    content,
    handleRouteDeleteClick,
    createRoute,
    handleServiceDuration,
    setStopProgress,
    setRouteProcessedErrors,
    resetPreplan,
    updateRouteResponse,
    totalOrders,
    orderInfo,
    setTotalOrders,
    setOrderInfo,
    setUnassignedOrdersInfo,
    promptUnprocessedOrders,
    updateRouteInfo,
    currentRoute,
    stopSelectedOrders,
    setStopSelectedOrders,
    routeInfo,
    handleCurrentRoute,
    routeSelectedStops,
    setRouteSelectedStops,
    handleCurrentStop,
    unlinkRouteFromUsers,
    setRouteInfo,
    setUsers,
    setRouteKey,
    setInProgress,
    currentStop,
    setCurrentRoute,
    users,
    config = {},
    handleAutoPreplan,
    doCapacityValidation,
    preplanId,
    setAssignInfo
  } = confirmationModalProps
  const modalType =
    allocType === 'autoPreplan' && data.length === 0 ? 'warning' : 'confirm'
  Modal[modalType]({
    title,
    content,
    className: `preplanModal ${allocType === 'serviceDuration' ? 'serviceDurationConfirm' : ''}`,
    onOk: () => {
      switch (allocType) {
        case 'routeDelete':
          handleRouteDeleteClick(currentRouteId)
          break
        case 'moveOrder':
          doRouteCapacityValidation(
            doCapacityValidation,
            preplanId,
            allocType,
            data,
            () => {
              moveOrderToRoute(
                data,
                currentRoute,
                stopSelectedOrders,
                setStopSelectedOrders,
                promptUnprocessedOrders,
                setStopProgress,
                resetPreplan,
                setInProgress,
                handleRouteDeleteClick,
                unlinkRouteFromUsers,
                setRouteInfo,
                setUsers,
                setRouteKey,
                updateRouteInfo,
                routeInfo,
                handleCurrentRoute,
                routeSelectedStops,
                setRouteSelectedStops,
                handleCurrentStop,
                currentStop,
                setCurrentRoute,
                users,
                setAssignInfo
              )
            },
            setStopProgress,
          )
          break
        case 'moveStop':
          doRouteCapacityValidation(
            doCapacityValidation,
            preplanId,
            allocType,
            data,
            () => {
              moveStopToRoute(
                resetPreplan,
                data,
                handleRouteDeleteClick,
                unlinkRouteFromUsers,
                setRouteInfo,
                setUsers,
                setRouteKey,
                updateRouteInfo,
                handleCurrentRoute,
                currentRoute,
                routeSelectedStops,
                setRouteSelectedStops,
                handleCurrentStop,
                currentStop,
                setCurrentRoute,
                routeInfo,
                promptUnprocessedOrders,
                setStopProgress,
                users,
                setAssignInfo,
                setInProgress
              )
            },
            setStopProgress,
          )
          break
        case 'allocateToRoute':
          doRouteCapacityValidation(
            doCapacityValidation,
            preplanId,
            allocType,
            data,
            () => {
              allocateToExistingRoute(
                data,
                setStopProgress,
                resetPreplan,
                updateRouteResponse,
                totalOrders,
                orderInfo,
                setTotalOrders,
                setOrderInfo,
                setUnassignedOrdersInfo,
                promptUnprocessedOrders,
                updateRouteInfo,
                routeInfo,
                handleCurrentRoute,
                currentRoute,
                routeSelectedStops,
                setRouteSelectedStops,
                handleCurrentStop,
                users,
                currentStop,
                setCurrentRoute,
                setAssignInfo
              )
            },
            setStopProgress,
          )
          break
        case 'assignToUser':
          doRouteCapacityValidation(
            doCapacityValidation,
            preplanId,
            allocType,
            data,
            () => {
              createRoute(data)
            },
            setStopProgress,
          )
          break
        case 'serviceDuration':
          handleServiceDuration(currentRouteId)
          break
        case 'autoPreplan':
          if (modalType === 'confirm') {
            handleAutoPreplan(data)
          }
          break

        default:
          ;() => {}
      }
    },
    onCancel: () => {
      if (
        ['moveOrder', 'moveStop', 'assignToUser', 'allocateToRoute'].includes(
          allocType,
        )
      ) {
        setStopProgress(false)
        setRouteProcessedErrors([])
      }
      if (allocType === 'autoPreplan') {
        setStopProgress(false)
        setRouteProcessedErrors([])
        setUnassignedOrdersInfo({
          selectedOrderKeys: [],
          selectedOrderRows: [],
        })
      }
    },
    width: config?.width ? config.width : 420,
  })
}

// finding differences with prev selected, removed along with dependeny orders
export const handleDependencies = (
  orderInfo,
  previousKeys,
  selectedRowKeys,
  selectedRows,
  key = 'id',
  dependence_key = 'related_order',
) => {
  const removedElements = _.difference(previousKeys, selectedRowKeys)
  const appendedElements = _.difference(selectedRowKeys, previousKeys)
  // returns dependancy orders from removed ones
  const removedDependableOrders = orderInfo
    .filter((order) => removedElements.includes(order[key]))
    .filter((order) => !isEmpty(order[dependence_key]))
    .map((order) => order[dependence_key])
  // returns dependancy order ids of newly added ones
  const appendDependableOrderKeys = orderInfo
    .filter((order) => appendedElements.includes(order[key]))
    .filter((order) => !isEmpty(order[dependence_key]))
    .map((order) => order[dependence_key])
  // returns dependancy order of newly added ones
  const appendDependableOrderRows = orderInfo.filter((order) =>
    appendDependableOrderKeys.includes(order[key]),
  )
  const updatedRows = selectedRows.filter(
    (order) => !removedDependableOrders.includes(order[key]),
  )
  // appending both remaining from existing one & dependency orders
  return [].concat(updatedRows, appendDependableOrderRows)
}

export const setRouteOrderSequence = (
  sourceObj,
  destinationObj,
  currentRoute,
  setCurrentRoute,
  currentStop,
  handleCurrentStop,
) => {
  if (currentRoute.stops && currentRoute.stops.length > 2) {
    // && rearrangeForm && currentRoute.stops && currentRoute.stops.length > 2)
    const stops = currentRoute.stops ? [...currentRoute.stops] : []
    const routeOrders = currentRoute.routeOrders
      ? [...currentRoute.routeOrders]
      : []
    const sourceStop =  routeOrders[sourceObj].stop_id;
    const destinationStop =routeOrders[destinationObj].stop_id;
    // const sourceStop = getValueFromArrayOfObjects(
    //   routeOrders,
    //   'customer_order_id',
    //   routeOrders[sourceObj].customer_order_id,
    //   'stop_id',
    // )
    // const destinationStop = getValueFromArrayOfObjects(
    //   routeOrders,
    //   'customer_order_id',
    //   routeOrders[destinationObj].customer_order_id,
    //   'stop_id',
    // )
    if (sourceStop !== destinationStop) {
      const customerStops = retrieveCustomerStops(currentRoute, true)
      const sourceStopIndex = _.findIndex(customerStops, ['id', sourceStop])
      const destinationStopIndex = _.findIndex(customerStops, [
        'id',
        destinationStop,
      ])
      const hasCorrectOrder = checkDependancyOrderSeq(
        currentRoute,
        sourceStopIndex,
        destinationStopIndex,
      )
      if (hasCorrectOrder) {
        const newStops = rearrangeStops(
          stops,
          true,
          sourceStopIndex,
          destinationStopIndex,
        )
        const newRouteOrders = setRouteOrders(newStops)
        setCurrentRoute(
          Object.assign({}, currentRoute, {
            stops: newStops,
            routeOrders: newRouteOrders,
          }),
        )
        if (currentStop && currentStop.id) {
          handleCurrentStop({}, currentStop.id, '', currentRoute)
        }
      }
    }
  }
}

export const getOptimizeInfo = (
  routes,
  info = {},
  routeInfo,
  doOptimizeBulkRoutes,
  currentWarehouse,
) => {
  const lockedRoutes = routeInfo.filter(
    (route) =>
      routes.includes(route.route_id) &&
      (route.isLocked || route.warehouse_id !== currentWarehouse.id),
  )
  const optPendingRoutes = routeInfo
    .filter(
      (route) =>
        routes.includes(route.route_id) &&
        !route.isLocked &&
        route.warehouse_id === currentWarehouse.id,
    )
    .map((route) => ({
      route_id: route.route_id,
      inProgress: false,
      status: false,
      full_name: route.displayName,
      pinned: route.pinned,
      stick_start_time:
        info && info[route.route_id] && !isEmpty(info[route.route_id].stick_start_time)
          ? info[route.route_id].stick_start_time
          : route.stick_start_time === true,
    }))
  doOptimizeBulkRoutes(optPendingRoutes, false, lockedRoutes)
}

export const getAddrErrOrders = (
  sourceOrders,
  key,
  responseType = 'obj',
  keysToCheck = [],
) => {
  let errorOrders = []
  if (keysToCheck.length > 0) {
    errorOrders = sourceOrders.filter(
      (order) =>
        keysToCheck.includes(order[key]) &&
        order.location_partial_match === true,
    )
  } else {
    errorOrders = sourceOrders.filter(
      (order) => order.location_partial_match === true,
    )
  }
  if (responseType !== 'obj') {
    const responseOrders = errorOrders.map((order) => order[responseType])
    return responseOrders
  }
  return errorOrders
}

//show address errors
export const addressResolveMsg = (orders = []) => {
  return orders.length > 0 ? (
    <div className="fontSize12">
      {I18n.t('preplan.address_issues')}
      <div className="paddingLeft15 marginTop10 textRed">
        {orders.join(',')}
      </div>
    </div>
  ) : (
    ''
  )
}

export const handleCapacityResponse = (
  unassignedOrdersInfo,
  preplanId,
  handleResponse,
  handleAutoPreplan,
  setPreplanProgress,
  preplanZones = [],
  setStopProgress,
  setRouteProcessedErrors,
  currentPreplan,
  result,
  setUnassignedOrdersInfo,
  doCapacityValidation,
  // currentPreplan.zone_wise_distribution
) => {
  setPreplanProgress(false)
  const validZones = result.preplan.valid_zones || []
  const invalidZones = result.preplan.invalid_zones || []
  const preplaZones = preplanZones || []
  const zones = []
  invalidZones.forEach((zone) => {
    const zoneRecord = _.find(preplaZones, {
      delivery_zone_id: zone.zone,
    })
    zones.push({
      ...zone,
      zone_name: !isEmpty(zoneRecord) ? zoneRecord.delivery_zone_name : '',
      isValid: false,
    })
  })
  validZones.forEach((zone) => {
    const zoneRecord = _.find(preplaZones, {
      delivery_zone_id: zone.zone,
    })
    zones.push({
      ...zone,
      zone_name: !isEmpty(zoneRecord) ? zoneRecord.delivery_zone_name : '',
      isValid: true,
    })
  })
  let content = ''
  const messages = {}
  messages.title = I18n.t('general.result')
  const noDriverZones = zones.filter(
    (zone) => !zone.isValid && zone.drivers.length === 0,
  )
  const overloadedZones = zones.filter(
    (zone) => !zone.isValid && zone.drivers.length > 0,
  )
  const capacityErrZones = zones.filter(
    (zone) =>
      !zone.isValid &&
      (zone.total_vol_overload === true ||
        zone.total_wt_overload === true ||
        zone.max_wt_overload === true ||
        zone.max_dim_overload === true),
  )
  const overlodedOrderZones = zones.filter(
    (zone) =>
      !zone.isValid &&
      (zone.max_dim_overload === true || zone.max_wt_overload === true),
  )
  const overloadedOrders = overlodedOrderZones.map((zone) =>
    [].concat(zone.max_wt_orders, zone.max_dim_orders),
  )
  const cleanedOverloadedOrders = _.uniqBy(
    _.flattenDeep(_.compact(overloadedOrders), 'id'),
  )
  const cleanedOverloadedOrderNos = cleanedOverloadedOrders.map(order => order.customer_order_number)
  const reasons = []
  if (invalidZones.length > 0) {
    if (overloadedZones.length > 0) {
      reasons.push({
        id: 1,
        reason: I18n.t('route_optimization.capacity_overloaded'),
        zones: overloadedZones.map((zone) => zone.zone_name).join(', '),
      })
    }
    if (noDriverZones.length > 0) {
      reasons.push({
        id: 2,
        reason: I18n.t('route_optimization.no_drivers_available'),
        zones: noDriverZones.map((zone) => zone.zone_name).join(', '),
      })
    }
  }
  content = (
    <div>
      {invalidZones.length > 0 && (
        <Fragment>
          <div className="marginTop20">
            <h4>{I18n.t('preplan.invalid_zones')}</h4>
            {reasons.length > 0 && (
              <ZoneCapacityErrors
                data={reasons}
                size="small"
                pagination={{ position: 'none' }}
                bordered
                expandedRowRender={(record) =>
                <Fragment>
                  {
                    record.id !== 2 ? (
                      <ZoneCapacityErrorDetailInfo
                        data={capacityErrZones}
                        size="small"
                        pagination={{ position: 'none' }}
                        bordered
                        expandedRowRender={rec => <div className='padding20'>{findOverloadReasonsWithDetails(rec)}</div>}
                      />
                      
                    ) : (
                      <h4
                        onClick={() => {
                          Modal.destroyAll()
                        }}
                      >
                        Drivers for above specified zones were not added to this
                        preplan.
                      </h4>
                  )}
                  </Fragment>
                }
              />
            )}
            {/* {
              overloadedZones.length > 0 &&  
              <p className="paddingLeft15">
                <span className="textRed textBold">
                {overloadedZones.map((zone) => zone.zone_name)
                  .join(", ")}:
                </span>&nbsp;&nbsp;-&nbsp;&nbsp;
                <span className="textBold">
                  {I18n.t('route_optimization.capacity_overloaded')}</span>
              </p>
              }
            {noDriverZones.length > 0 && <p className="paddingLeft15">
              <span className="textRed textBold">
              {
                noDriverZones.map((zone) => zone.zone_name)
                .join(", ")
                }
              </span>&nbsp;&nbsp;-&nbsp;&nbsp;
              <span className="textBold">
                {I18n.t('route_optimization.no_drivers_available')}
              </span>
            </p>
            } */}
          </div>
          { cleanedOverloadedOrders.length && <div className="marginTop20">
          <div className='textBold textRed'>{I18n.t('preplan.capacity.overloaded_orders')}:</div>
          <div style={{ maxWidth: 400}}>
            <CapacityOrders
              data={cleanedOverloadedOrders}
              pagination={{ position: 'none'}}
              scroll={{ y: 100 }}
              size={"small"}
            />
          </div>
            {/* {cleanedOverloadedOrderNos.length > 0 && (
              <Fragment>
                <span className="textBold">
                  {I18n.t('route_optimization.orders_overloaded')}
                </span>
                <p className="paddingLeft15 textRed">
                  {cleanedOverloadedOrderNos.join(', ')}
                </p>
              </Fragment>
            )} */}
          </div>
         }
        </Fragment>
      )}
      
      {validZones.length > 0 && (
        <Fragment>
          <Divider style={{ margin: '2px 0px 2px 0px' }} />
          <div className="marginTop20">
            <h4>{I18n.t('preplan.valid_zones')}</h4>
            <p className="paddingLeft15 specialText textBold">
              {zones
                .filter((zone) => zone.isValid)
                .map((zone) => zone.zone_name)
                .join(', ')}
            </p>
          </div>
        </Fragment>
      )}
      {validZones.length > 0 && (
        <h4 className="marginTop15">
          {I18n.t('messages.proceed_confirm_with_field', {
            field: 'with valid zones',
          })}
        </h4>
      )}
    </div>
  )
  messages.content = content
  takeConfirmation({
    allocType: 'autoPreplan',
    preplanId,
    data: validZones,
    title: messages.title,
    content: messages.content,
    config: { width: '60%' },
    setStopProgress: setStopProgress,
    setRouteProcessedErrors,
    handleAutoPreplan,
    setUnassignedOrdersInfo,
    doCapacityValidation,
  })
}

export const doCheckCapacity = (
  unassignedOrdersInfo,
  preplanId,
  handleResponse,
  handleAutoPreplan,
  setPreplanProgress,
  preplanZones = [],
  setStopProgress,
  setRouteProcessedErrors,
  currentPreplan,
  saveAutoPreplan,
  setUnassignedOrdersInfo,
  doCapacityValidation,
  // currentPreplan.zone_wise_distribution
) => {
  const data = {
    order_ids: unassignedOrdersInfo.selectedOrderKeys,
    pre_plan_id: preplanId,
  }
  setPreplanProgress(false)
  // showFlashMessage('info', I18n.t('messages.preplan_process'));
  notification.open({
    key: preplanId,
    message: I18n.t('preplan.auto_preplan'),
    description: <div>{I18n.t('messages.capacity_process')}</div>,
    icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
    placement: 'bottomRight',
    duration: 20,
  })
  capacityValidation(data, preplanId).then((result) => {
    if (result.success) {
      if (!isEmpty(result.preplan) && result.preplan.preplan) {
        handleResponse(result.preplan)
      } else {
        const validZones = result.preplan.valid_zones || []
        const invalidZones = result.preplan.invalid_zones || []
        setPreplanProgress(false)
        notification.close(preplanId)
        if (currentPreplan.zone_wise_distribution) {
          if (invalidZones.length === 0 && validZones.length > 0) {
            handleAutoPreplan(validZones)
          } else {
            handleCapacityResponse(
              unassignedOrdersInfo,
              preplanId,
              handleResponse,
              handleAutoPreplan,
              setPreplanProgress,
              preplanZones,
              setStopProgress,
              setRouteProcessedErrors,
              currentPreplan,
              result,
              setUnassignedOrdersInfo,
              doCapacityValidation,
            )
          }
        } else {
          if (invalidZones.length > 0) {
            //const overloadedOrders = findOverloadOrdersByZone(invalidZones)
            const capacityInfo = invalidZones[0] || {};
            const overloadedBy = findOverloadReasons(capacityInfo);
            Modal.error({
              title: 'Errors',
              content: (
                <Fragment>
                   <h4><span className="textRed">{I18n.t('preplan.capacity.capacity_overloaded_by')} {overloadedBy}</span></h4>
                  {/* <h4 className="textBold">
                    {I18n.t(
                      'route_optimization.no_distribution_orders_overloaded',
                    )}
                  </h4>
                  {overloadedOrders.length > 0 &&
                    <div className='marginTop10'>
                      <div className='textBold textRed'>{I18n.t('route_optimization.orders_overloaded')}:</div>
                      <Typography.Paragraph ellipsis={{ rows: 1, expandable: true }}>{_.uniq(_.flattenDeep(_.compact(overloadedOrders))).join(',')}</Typography.Paragraph>
                    </div>
                  } */}
                  {
                   findOverloadReasonsWithDetails(invalidZones[0])
                  }

                </Fragment>
              ),
              width: '40%',
            })
          } else {
            const orderIds = validZones.map((zone) => zone.order_ids)
            const cleanedOrders = _.uniq(_.flattenDeep(_.compact(orderIds)))
            const reqOrders = unassignedOrdersInfo.selectedOrderRows.filter(
              (order) => cleanedOrders.includes(order.id),
            )
            const reqData = {
              selectedOrderKeys: reqOrders.map((order) => order.id),
              selectedOrderRows: reqOrders,
            }
            if (reqOrders.length > 0) {
              saveAutoPreplan(false, reqData, setPreplanProgress)
            }

            // handleCapacityResponse(
            //   unassignedOrdersInfo,
            //   preplanId,
            //   handleResponse,
            //   handleAutoPreplan,
            //   setPreplanProgress,
            //   preplanZones,
            //   setStopProgress,
            //   setRouteProcessedErrors,
            //   currentPreplan,
            //   result,
            // )
          }
        }
      }
    } else {
      Modal.error({
        title: 'Errors',
        content: (
          <Fragment>
            {result.errors.map((err, index) => (
              <Row>
                <Col key={`err${index + 1}`} className="fontWeight500">
                  {err}
                </Col>
              </Row>
            ))}
          </Fragment>
        ),
        width: '40%',
      })
      setPreplanProgress(false)
      message.destroy()
    }
  })
}

export const checkAddressValidation = (
  action = 'add_route_button',
  data = {},
  unassignedOrdersInfo,
  totalOrders,
  resetPreplan,
  createRoute,
  handleUnassignOrdersInfo,
  handleAssignInfo,
  saveAutoPreplan,
  doCapacityValidation,
  handleResponse,
  preplanId,
  handleAutoPreplan,
  setPreplanProgress,
  preplanZones,
  setStopProgress,
  setRouteProcessedErrors,
  currentPreplan,
  setUnassignedOrdersInfo,
) => {
  const selectedKeys = unassignedOrdersInfo.selectedOrderKeys || []
  let addrErrOrders = totalOrders
    .filter(
      (order) =>
        selectedKeys.includes(order.id) &&
        order.location_partial_match === true,
    )
    .map((order) => order.id)
  const errOrderDependencies = totalOrders
    .filter(
      (order) =>
        !isEmpty(order.related_order) && addrErrOrders.includes(order.id),
    )
    .map((order) => order.related_order)
  addrErrOrders = [].concat(addrErrOrders, errOrderDependencies)
  addrErrOrders = _.uniq(addrErrOrders)
  let errOrderObjects = []
  let addressErrContent = ''
  if (addrErrOrders.length > 0) {
    errOrderObjects = totalOrders
      .filter((order) => addrErrOrders.includes(order.id))
      .map((order) => order.customer_order_number)
    addressErrContent = addressResolveMsg(errOrderObjects)
  }
  const filteredKeys = selectedKeys.filter(
    (order) => !addrErrOrders.includes(order),
  )
  const errFreeObjects = totalOrders.filter((order) =>
    filteredKeys.includes(order.id),
  )
  if (addrErrOrders.length > 0 && filteredKeys.length === 0) {
    showErrorsonModal('Errors', addressErrContent)
  } else if (addrErrOrders.length > 0) {
    confirmationPopup({
      title: I18n.t('messages.proceed_confirm'),
      content: addressErrContent,
      onConfirm: () => {
        if (action === 'add_driver') {
          data.orderNumbers = errFreeObjects.map(
            (order) => order.customer_order_number,
          )
          data.order_ids = errFreeObjects.map((order) => order.id)
          data.order_ids_for_validation = [...data.order_ids]
          doRouteCapacityValidation(
            doCapacityValidation,
            preplanId,
            'add_driver',
            data,
            () => {
              resetPreplan(false, false, () => {
                createRoute(data)
              })
            },
            setStopProgress,
          )
        } else if (action === 'auto_preplan') {
          handleUnassignOrdersInfo(errFreeObjects)
          const reqData = {
            selectedOrderKeys: errFreeObjects.map((order) => order.id),
            selectedOrderRows: errFreeObjects,
          }
          if (doCapacityValidation) {
            doCheckCapacity(
              reqData,
              preplanId,
              handleResponse,
              handleAutoPreplan,
              setPreplanProgress,
              preplanZones,
              setStopProgress,
              setRouteProcessedErrors,
              currentPreplan,
              saveAutoPreplan,
              setUnassignedOrdersInfo,
              doCapacityValidation,
            )
          } else {
            saveAutoPreplan(false, reqData, setPreplanProgress)
          }
        } else {
          handleUnassignOrdersInfo(errFreeObjects)
          handleAssignInfo()
        }
      },
    })
  } else {
    if (action === 'add_driver') {
      data.orderNumbers = errFreeObjects.map(
        (order) => order.customer_order_number,
      )
      data.order_ids = errFreeObjects.map((order) => order.id)
      data.order_ids_for_validation = [...data.order_ids]
      doRouteCapacityValidation(
        doCapacityValidation,
        preplanId,
        'add_driver',
        data,
        () => {
          resetPreplan(false, false, () => {
            createRoute(data)
          })
        },
        setStopProgress,
      )
    } else if (action === 'auto_preplan') {
      handleUnassignOrdersInfo(errFreeObjects)
      const reqData = {
        selectedOrderKeys: errFreeObjects.map((order) => order.id),
        selectedOrderRows: errFreeObjects,
      }
      if (doCapacityValidation) {
        doCheckCapacity(
          reqData,
          preplanId,
          handleResponse,
          handleAutoPreplan,
          setPreplanProgress,
          preplanZones,
          setStopProgress,
          setRouteProcessedErrors,
          currentPreplan,
          saveAutoPreplan,
          setUnassignedOrdersInfo,
          doCapacityValidation,
        )
      } else {
        saveAutoPreplan(false, reqData)
      }
    } else {
      handleUnassignOrdersInfo(errFreeObjects)
      handleAssignInfo()
    }
  }
}

export const showErrorsonModal = (title, content) => {
  return Modal.error({
    title,
    content,
    width: '40%',
  })
}

export const clearBulkFalseNegatives = (
  unassignedOrdersInfo,
  setOrderProgress,
  getOrders,
) => {
  const selectedOrderKeys = unassignedOrdersInfo?.selectedOrderKeys
    ? [...unassignedOrdersInfo.selectedOrderKeys]
    : []
  const org_id = userStore.getStateValue('selectedOrg')
  resolveAddressApi
    .clearFalseNegative(org_id, selectedOrderKeys)
    .then((result) => {
      if (result.success) {
        getOrders()
        notification.success({
          message: I18n.t('messages.address_corrected'),
          description: <>{I18n.t('messages.selected_address_corrected')}</>,
          placement: 'bottomRight',
        })
      } else {
        setOrderProgress(false)
        notification.error({
          key: 'error_orders',
          message: (
            <span className="fontSize14">{I18n.t('errors.errors')}</span>
          ),
          description: (
            <>
              {result.errors.map((err, index) => (
                <div key={index}>{err}</div>
              ))}
            </>
          ),
          placement: 'bottomRight',
        })
      }
    })
    .finally(() => {})
}

export const markMultipleOrders = (checked, selectedOrders, previousRows, totalOrders) => {
    let orders = []; // ...selectedRows
    let dependencyOrders = [];
    selectedOrders.forEach((currentRecord) => {
       const dependencies = findDependencyOrders(
        totalOrders,
        "related_order",
        "id",
        currentRecord,
        "object",
        "type_of_order"
      );
      dependencyOrders = [].concat(dependencyOrders, dependencies);
    });
    const dependencyIds = dependencyOrders.map((order) => order.id);
    if (checked === false) {
      orders = previousRows.filter(
        (order) => !dependencyIds.includes(order.id)
      );
    } else {
      const filteredItems = previousRows.filter(
        (order) => !dependencyIds.includes(order.id)
      );
      if (dependencyOrders.length > 0) {
        orders = [].concat(filteredItems, dependencyOrders);
      } else {
        orders = [...filteredItems];
      }
    }
    return orders;
  }

  // export const handleChangeTableCheckChange = (selectedRowKeys, selectedRows, previousRows, totalOrders) => {
  //   let orders = []
  //   const diffOrders = selectedRows.filter((d) => !previousRows.includes(d.id))
  //   const removedOrders = previousRows.filter(
  //     (d) => !selectedRowKeys.includes(d.id),
  //   )
  //   let dependencyOrders = []
  //   removedOrders.forEach((currentRecord) => {
  //     const dependencies = findDependencyOrders(
  //       totalOrders,
  //       'related_order',
  //       'id',
  //       currentRecord.id,
  //       'object',
  //       'type_of_order',
  //     )
  //     dependencyOrders = [].concat(dependencyOrders, dependencies)
  //   })
  //   const dependencyIds = dependencyOrders.map((order) => order.id)
  //   dependencyOrders = []
  //   diffOrders
  //     .filter((showOrder) => !dependencyIds.includes(showOrder.id))
  //     .forEach((currentRecord) => {
  //       const dependencies = findDependencyOrders(
  //         totalOrders,
  //         'related_order',
  //         'id',
  //         currentRecord.id,
  //         'object',
  //         'type_of_order',
  //       )
  //       dependencyOrders = [].concat(dependencyOrders, dependencies)
  //       const dependencyIds = dependencyOrders.map((order) => order.id)
  //       if (!selectedRowKeys) {
  //         orders = previousRows.filter(
  //           (order) => !dependencyIds.includes(order.id),
  //         )
  //       } else if (selectedRowKeys) {
  //         const filteredItems = previousRows.filter((order) =>
  //           dependencyIds.includes(order.id),
  //         )
  //         if (dependencyOrders.length > 0) {
  //           orders = [].concat(filteredItems, dependencyOrders)
  //         } else {
  //           orders = [...filteredItems]
  //         }
  //       }
  //     })
  //   return orders;
  // }

export const handleChangeTableCheckChange = (selectedRowKeys, selectedRows, previousRows, totalOrders) => {
  let orders = [];
  const diffOrders = selectedRows.filter((d) => !previousRows.includes(d.id));
  const removedOrders = previousRows.filter(
    (d) => !selectedRowKeys.includes(d.id),
  );
  let dependencyOrders = [];
  removedOrders.forEach((currentRecord) => {
    const dependencies = findDependencyOrders(
      totalOrders,
      'related_order',
      'id',
      currentRecord.id,
      'object',
      'type_of_order',
    );
    dependencyOrders = [].concat(dependencyOrders, dependencies);
  });
  const dependencyIds = dependencyOrders.map((order) => order.id);
  dependencyOrders = [];
  diffOrders
    .filter((showOrder) => !dependencyIds.includes(showOrder.id))
    .forEach((currentRecord) => {
      const dependencies = findDependencyOrders(
        totalOrders,
        'related_order',
        'id',
        currentRecord.id,
        'object',
        'type_of_order',
      );
      dependencyOrders = [].concat(dependencyOrders, dependencies);
      const dependencyIds = dependencyOrders.map((order) => order.id);
      if (!selectedRowKeys) {
        orders = previousRows.filter(
          (order) => !dependencyIds.includes(order.id),
        );
      } else if (selectedRowKeys) {
        const filteredItems = previousRows.filter((order) =>
          dependencyIds.includes(order.id),
        );
        if (dependencyOrders.length > 0) {
          orders = [].concat(filteredItems, dependencyOrders);
        } else {
          orders = [ ...filteredItems ];
        }
      }
    });
  return orders;
}
  
export const dependencyValidation = (route) => {
  let hasCorrectOrder = true
  const errorStops = []
  const stops = route.stops ? [...route.stops] : []
  const customerStops = stops.filter(
    (stop) => stop.location && stop.location.l_type === 'CS',
  )
  if (!isEmpty(customerStops) && _.isArray(customerStops)) {
    customerStops.forEach((currentStop, sourceIndex) => {
      const stopOrders = currentStop.orders || []
      stopOrders.forEach((currentOrder) => {
        if (currentOrder.type_of_order !== 'X') {
          const dependencyStops = getDependencyStops(
            route,
            currentStop.id,
            'object',
            'stops',
          )
          if (dependencyStops.length > 0) {
            if(["T", "LH"].includes(currentOrder.type_of_order)){
                dependencyStops.forEach(stop => {
                const dependencyStopIndex = _.findIndex(customerStops, [
                  'id',
                  stop.id,
                ])
                if(currentStop.stop_order_sequence > stop.stop_order_sequence &&  sourceIndex <= dependencyStopIndex){
                  errorStops.push(currentOrder.customer_order_number)
                } else if(currentStop.stop_order_sequence < stop.stop_order_sequence &&  sourceIndex >= dependencyStopIndex){
                  errorStops.push(currentOrder.customer_order_number)
                }
              })
            }else {
              const dependencyTypeStops = dependencyStops.filter(
                (stop) =>
                  stop.orders &&
                  hasOrderType(
                    stop.orders,
                    'type_of_order',
                    AppConfig.dependancyOrderRelatedType[
                      currentOrder.type_of_order
                    ]
                      ? AppConfig.dependancyOrderRelatedType[
                          currentOrder.type_of_order
                        ]
                      : '',
                  ),
              )
              if (dependencyTypeStops.length > 0) {
                dependencyTypeStops.forEach((stop) => {
                  const dependencyStopIndex = _.findIndex(customerStops, [
                    'id',
                    stop.id,
                  ])
                  if (dependencyStopIndex >= 0) {
                    if (
                      AppConfig.deliveryOrderTypes.includes(
                        currentOrder.type_of_order,
                      ) &&
                      (dependencyStopIndex > sourceIndex)
                      ) // should be pickup < delivery
                    {
                      // sourceIndex current : delivery order, destinationIndex
                      // dependency : pickup order, dependencyStopIndex
                      errorStops.push(currentOrder.customer_order_number)
                      // return false;
                    } else if (
                      AppConfig.pickOrderTypes.includes(
                        currentOrder.type_of_order,
                      ) &&
                      (
                        dependencyStopIndex < sourceIndex )
                      // should be pickup < delivery
                    ) {
                      // sourceIndex current : pickup order,  destinationIndex
                      // dependency : delivery order,  dependencyStopIndex
                      errorStops.push(currentOrder.customer_order_number)
                      // return false;
                    }
                  }
                })
              }
            }
          }
        }
      })
    })
  }
  // return errorStops
  return [...new Set(errorStops)]
}

  export const validAddressToResolve = (address) => {
    // remove spaces which values are strings
    const addressObj = _.mapValues(address, (value) => {
      if (typeof value === 'string') {
        return value.trim();
      }
      return value;
    });

    const isKeysPresent = _.isObject(addressObj) && _.every(['address_line1', 'city', 'state', 'country', 'zipcode'], _.partial(_.has, addressObj));

    const isAddressValid = _.every(addressObj, (value, key) => {
      if (key === 'address_line1' || key === 'city' || key === 'state' || key === 'country' || key === 'zipcode') {
        return value.length > 0;
      }
      return true;
    });
    return isKeysPresent && isAddressValid;
  }

   export const renderDriverZones = (driverId, driverZones) => {
    const currentDriverId = driverId;
    const currentDriverZoneIndex = _.findIndex(driverZones, ["driver_id", currentDriverId]);
    let currentDriverZones = []
    if(currentDriverZoneIndex >=0){
      currentDriverZones = driverZones[currentDriverZoneIndex].driver_zone_name
    }
    return (
      <Col xs={24}  style={{ display: "flex", alignItems: "center" }}>
        <Icon type="global" style={{ fontSize: "9.5px",fontWeight: "bold"}} />
        &nbsp;<span style={{ fontSize: "9.5px",fontWeight: "bold"}}>{I18n.t("menu.zones")}:</span>
        &nbsp;
        {currentDriverZones.length > 0 ? (
          <Popover
            title="Zones List"
            placement="top"
            content={<div>{currentDriverZones.join(", ")}</div>}
            overlayStyle={{ marginRight: "200px", maxWidth: 200 }}
            arrowPointAtCenter
            // getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            <Paragraph ellipsis style={{ marginBottom: 0, fontSize: "9.5px",fontWeight: "bold" }}>
              {currentDriverZones.join(", ")}
            </Paragraph>
          </Popover>
        ) : (
          <span style={{ fontSize: "9.5px",fontWeight: "bold" }}>{"NA"}</span>
        )}
      </Col>
    );
  }


export const arrangeOrderByIdSeq = (orderInfo = [], sequnceIds = []) => {
  const records = orderInfo ? orderInfo.filter((order) => sequnceIds.includes(order.id)) : []
  const sortedOrders = records.sort(
    (a, b) => sequnceIds.indexOf(a.id) - sequnceIds.indexOf(b.id),
  )
  return sortedOrders
}

export const updateRouteOrder = (
  routeOrder = {},
  order = {},
  currentWarehouse = {},
  location = null
) => {
  const currentLocation = location
    ? location
    : order?.cs_location
    ? order.cs_location
    : {};
  const dataObject = location ? location : order;
  const address = dataObject.cs_location && dataObject.cs_location.l_address
  ? dataObject.cs_location.l_address
  : {};
  routeOrder.consignee = _.trim(dataObject.customer_first_name + dataObject.customer_last_name)
  routeOrder.customer_city = address.city;
  routeOrder.street = address.address_line1;
  routeOrder.customer_first_name = !isEmpty(dataObject.customer_first_name)
    ? dataObject.customer_first_name
    : "";
  routeOrder.customer_last_name = !isEmpty(dataObject.customer_last_name)
    ? dataObject.customer_last_name
    : "";

  // if (routeOrder.service_duration !== dataObject.service_duration) {
  //   isModified = true;
  // }
  routeOrder.service_duration = dataObject.service_duration;
  routeOrder.zipcode = address.zipcode;
  const appointments = dataObject.appointments || [];
  const orderStartTime = getAppointmentTimeFromSlots(
    appointments,
    "start_time",
    "hh:mma",
    currentWarehouse.slot_details
  );
  const orderEndTime = getAppointmentTimeFromSlots(
    appointments,
    "end_time",
    "hh:mma",
    currentWarehouse.slot_details
  );
  routeOrder.cs_sch_start_time = orderStartTime;
  routeOrder.cs_sch_end_time = orderEndTime;
  routeOrder.order_appointment =
    !_.isEmpty(dataObject.appointments) &&
    _.isArray(dataObject.appointments)
      ? dataObject.appointments.map((appointment) => {
          const { end_datetime, start_datetime } = processDateTime(
            currentWarehouse,
            appointment,
            routeOrder.timeZoneId
          );
          return {
            start_datetime: start_datetime,
            end_datetime: end_datetime,
            slot_name: appointment.slots[0],
            confirmed: true,
          };
        })
      : [];
  return routeOrder;
};

export const processDateTime = (currentWarehouse,appointmentsInfo, timeZoneId) => {
  let appt_date = appointmentsInfo.appt_date;
  let slots = appointmentsInfo.slots;
  let appt_date_moment = moment(appt_date);
  let start_datetime = null;
  let end_datetime = null;
  if (slots[ 0 ] === 'CUSTOM') {
    start_datetime = appt_date_moment.clone().set({
      hour: appointmentsInfo.start_time.split(":")[ 0 ],
      minute: appointmentsInfo.start_time.split(":")[ 1 ],
      second: 0,
      millisecond: 0,
    });
    end_datetime = appt_date_moment.clone().set({
      hour: appointmentsInfo.end_time.split(":")[ 0 ],
      minute: appointmentsInfo.end_time.split(":")[ 1 ],
      second: 0,
      millisecond: 0,
    });
  } else if(slots[0] === 'ANY'){
    start_datetime = appt_date_moment.clone().set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    end_datetime = appt_date_moment.clone().set({ hour: 20, minute: 0, second: 0, millisecond: 0 });
  } else {
    const selectedSlot = currentWarehouse.slot_details.find(slot => slot.start_time === slots[ 0 ].split("-")[ 0 ] && slot.end_time === slots[ 0 ].split("-")[ 1 ]);
    start_datetime = appt_date_moment.clone().set({
      hour: selectedSlot.start_time.split(":")[ 0 ],
      minute: selectedSlot.start_time.split(":")[ 1 ],
      second: 0,
      millisecond: 0,
    });
    end_datetime = appt_date_moment.clone().set({
      hour: selectedSlot.end_time.split(":")[ 0 ],
      minute: selectedSlot.end_time.split(":")[ 1 ],
      second: 0,
      millisecond: 0,
    });
  }
  start_datetime = start_datetime.format("YYYY-MM-DD HH:mm:ss");
  end_datetime = end_datetime.format("YYYY-MM-DD HH:mm:ss");
  start_datetime = moment.tz(start_datetime, timeZoneId).format();
  end_datetime = moment.tz(end_datetime, timeZoneId).format();
  return { start_datetime, end_datetime };
};

export const updateOrderDetailsToRouteOrders = (
  routes = [],
  routeIndex = -1,
  routeOrderIndex = -1,
  processingOrder = {},
  updatedOrder = {},
  currentWarehouse = {},
  location = null
) => {
    processingOrder.vehicle_type = updatedOrder.vehicle_type;
    processingOrder.account_code = updatedOrder.account_code;
    processingOrder.customer_order_number = updatedOrder.customer_order_number;
    processingOrder.items_count = updatedOrder.quantity;
    processingOrder.weight = updatedOrder.weight;
    processingOrder.type_of_order = updatedOrder.type_of_order;
    processingOrder.los = updatedOrder.los;
    processingOrder.hawb = updatedOrder.hawb;
    processingOrder.mawb = updatedOrder.mawb;
    const currentOrder = updateRouteOrder(processingOrder, updatedOrder, currentWarehouse, location);
    // update order_appointment in stops where updated order.id === currentOrder.
    routes[ routeIndex ].routeOrders[ routeOrderIndex ] = currentOrder;
    routes[ routeIndex ].stops = routes[ routeIndex ].stops.map((stop) => {
      if (stop.customer_order_ids && stop.customer_order_ids.includes(updatedOrder.id)) {
        // find the order in the stop.orders and update the order appointment
        stop.orders = stop.orders.filter(order => order.customer_order_id === updatedOrder.id).map((order) => {
          order = {...order, ...currentOrder}
          const { stop_location } = order;
          const { locations } = updatedOrder;
          if(locations.length > 0 && location && stop?.location_ids && stop.location_ids.includes(location.id)){
            const currentLocationOrderAppts =
              !_.isEmpty(location.appointments) &&
              _.isArray(location.appointments)
                ? location.appointments.map(
                    (appointment) => {
                      const { start_datetime, end_datetime } =
                        processDateTime(
                          currentWarehouse,
                          appointment,
                          currentOrder.order_timeZoneId
                        );
                      return {
                        start_datetime: start_datetime,
                        end_datetime: end_datetime,
                        slot_name: appointment.slots[0],
                        confirmed: true,
                        customer_order_id: updatedOrder.id,
                      };
                    }
                  )
                : [];
            order.order_appointment = currentLocationOrderAppts;
          } else {
            order.order_appointment = !_.isEmpty(updatedOrder.appointments) && _.isArray(updatedOrder.appointments) ?
            updatedOrder.appointments.map((appointment) => {
              const { start_datetime ,end_datetime } = processDateTime(currentWarehouse, appointment ,currentOrder.timeZoneId);
              return {
                "start_datetime": start_datetime,
                "end_datetime": end_datetime,
                "slot_name": appointment.slots[ 0 ],
                "confirmed": true,
                customer_order_id: updatedOrder.id,
              };
            }) : [];
          }
          







            // order.order_appointment = !_.isEmpty(updatedOrder.appointments) && _.isArray(updatedOrder.appointments) ?
            // updatedOrder.appointments.map((appointment) => {
            //   const { start_datetime ,end_datetime } = processDateTime(appointment ,currentOrder.timeZoneId);
            //   return {
            //     "start_datetime": start_datetime,
            //     "end_datetime": end_datetime,
            //     "slot_name": appointment.slots[ 0 ],
            //     "confirmed": true,
            //     customer_order_id: updatedOrder.id,
            //   };
            // }) : [];
          
          return order;
        });
      }
      return stop;
    }
    );
    return routes;
}

export const showUnassignedErrors = (unalllocatedOrders = [], preplanInfo) => {
  Modal.error({
    title: 'The following orders cannot be assigned to the route.',
    //maskClosable: false,
    content: (
      <Fragment>
        {
         displayUnassignedErrors(unalllocatedOrders)
        }
      </Fragment>
    ),
    width: 720,
    // onOk: () => {
    //   preplanInfo()
    // },
    // onCancel: () => {
    //   preplanInfo()
    // }
  })
}



import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isEmpty } from '../../common/Common';
import { Tooltip } from '../../common/UIComponents';
import AppConfig from '../../config/AppConfig';

const OrderZoneNames = ({
  orderZoneName,
}) => (
  (!isEmpty(orderZoneName) && _.isArray(orderZoneName) && orderZoneName.length > 0) ?
    <Tooltip title={orderZoneName.join(', ')} mouseEnterDelay={AppConfig.tooltipDelay} getPopupContainer={(triggerNode) => triggerNode.parentNode}>
      {`${orderZoneName[0]} ${orderZoneName.length > 1 ? ` +${orderZoneName.length - 1}` : ''}`}
    </Tooltip>
    :
    ''
);

OrderZoneNames.propTypes = {
  orderZoneName: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OrderZoneNames;

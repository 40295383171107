import React, { Fragment, useContext, useEffect, useState } from "react";
import {isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Col,
  Row,
  Icon,
  Popover,
} from "../../common/UIComponents";
import { OrgContext } from "../../context/OrgContext";
import { momentTimeWithShortName } from "../../helpers/date_functions";

const ApprovedOrRejectStatus = (props) => {
  const { order } = props;
  const timeZone = order.order_timeZoneId;
  const timeZoneName = order.order_tz_short_name;
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const [isMilitaryTime, setIsMilitaryTime] = useState(false);

  useEffect(() => {
    if (
      organizationSettings &&
      organizationSettings.is_military_time === "true"
    ) {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [organizationSettings]);

  return (
    <Popover
      title={I18n.t("general.status")}
      overlayStyle={{
        width: "315px",
      }}
      placement="topLeft"
      content={
        <Fragment>
          {!isEmpty(order.approved_by) && (
            <Row>
              <Col lg={8} md={8} sm={10} xs={10}>
                <span className="info-text ">
                  {I18n.t("order.stat.approved_by")}
                </span>
              </Col>
              <Col lg={1} md={1} sm={1} xs={1}>
                :
              </Col>
              <Col lg={15} md={15} sm={13} xs={13}>
                {order.approved_by}
              </Col>
            </Row>
          )}
          {!isEmpty(order.approved_at) && (
            <Row>
              <Col lg={8} md={8} sm={10} xs={10}>
                {I18n.t("order.stat.approved_on")}
              </Col>
              <Col lg={1} md={1} sm={1} xs={1}>
                :
              </Col>
              <Col lg={15} md={15} sm={13} xs={13}>
                {momentTimeWithShortName(
                  isMilitaryTime ? "MMM D, YYYY HH:mm" : "lll",
                  order.approved_at,
                  timeZone,
                  timeZoneName
                )}
              </Col>
            </Row>
          )}
          {!isEmpty(order.rejected_by) && (
            <Row>
              <Col lg={8} md={8} sm={10} xs={10}>
                {I18n.t("order.stat.rejected_by")}
              </Col>
              <Col lg={1} md={1} sm={1} xs={1}>
                :
              </Col>
              <Col lg={15} md={15} sm={13} xs={13}>
                {order.rejected_by}
              </Col>
            </Row>
          )}
          {!isEmpty(order.rejected_at) && (
            <Row>
              <Col lg={8} md={8} sm={10} xs={10}>
                {I18n.t("order.stat.rejected_on")}
              </Col>
              <Col lg={1} md={1} sm={1} xs={1}>
                :
              </Col>
              <Col lg={15} md={15} sm={13} xs={13}>
                {momentTimeWithShortName(
                  isMilitaryTime ? "MMM D, YYYY HH:mm" : "lll",
                  order.rejected_at,
                  timeZone,
                  timeZoneName
                )}
              </Col>
            </Row>
          )}
          {!isEmpty(order.rejection_message) && (
            <Row>
              <Col lg={8} md={8} sm={10} xs={10}>
                {I18n.t("general.reason")}
              </Col>
              <Col lg={1} md={1} sm={1} xs={1}>
                :
              </Col>
              <Col lg={15} md={15} sm={13} xs={13}>
                {order.rejection_message}
              </Col>
            </Row>
          )}
        </Fragment>
      }
    >
      &nbsp;
        {(!isEmpty(order.rejected_at) || !isEmpty(order.approved_at)) 
          && (
            <Icon
              type="info-circle"
              theme="twoTone"
              twoToneColor={!isEmpty(order.rejected_by) ? "#ff0000" : "#1890FF"}
              style={{
                fontSize: "15px",
              }}
            />
        )}
      </Popover>
  );
};

export default ApprovedOrRejectStatus;

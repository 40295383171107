import userStore from '../stores/UserStore';
import {
  Call,
  ApiUrl,
  ParseGeneralResponse,
} from './ApiUtils';

export const PicturesApi = {
  fetch: (refer, refer_id) => {
    const organization_id = userStore.getStateValue('selectedOrg');
    const url = ApiUrl(`v2/stop_order_pictures/get_stop_order_pictures?refer=${refer}&refer_id=${refer_id}&organization_id=${organization_id}`);
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: { pictures : 'stop_order_pictures' },
    });
  }
};

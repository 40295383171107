import {
  Call,
  ApiUrl,
  ParseGeneralResponse,
  setObjectToFormData,
  PageParams,
} from "./ApiUtils";

export const OrganizationApi = {
  fetch: () => {
    const url = ApiUrl(
      `v2/organization?page=1&per_page=20&operation_code=ORGI&current_role=super_admin`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        data: "organizations",
      },
    });
  },
};

export const LableSettingApi = {
  fetch: (selectedOrgId, page = 1, perPage = AppConfig.perPage) => {
    const url = ApiUrl(
      `v2/labels?organization_id=${selectedOrgId}&${PageParams(page, perPage)}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        data: "labels",
        pagination: "meta.pagination",
      },
    });
  },

  create: (data) => {
    const url = ApiUrl("v2/labels");
    const formData = setObjectToFormData(data);
    return Call("post", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "id" },
    });
  },

  update: (id, data) => {
    const url = ApiUrl("v2/labels/" + id);
    const formData = setObjectToFormData(data);
    return Call("put", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "id" },
    });
  },
};

export function deleteLabels(id) {
  const url = ApiUrl(`v2/labels/${id}`);
  return Call("delete", url, {}, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function saveLabels(isNew, data) {
  const formData = setObjectToFormData(data);
  const url = isNew ? ApiUrl("v2/labels") : ApiUrl(`v2/labels/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, formData, ParseGeneralResponse, {}, true);
}

import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import AuthForm from './components/Auth/AuthForm';

const ProtectedRoute = ({ component: Component, isAuthenticated, location, ...rest }) => {

  const url = new URL(window.location.href);
  const searchParams = url.pathname === "/forgot_password" ? new URLSearchParams(url.search) : new URLSearchParams("");
  const reset_password_token = searchParams.get('reset_password_token');

  return (
    <Route
      { ...rest }
      exact
      render={ (props) =>
        isAuthenticated() ? (
          <Component { ...props } />
        ) : (
          reset_password_token ? (
            <AuthForm { ...props } resetPasswordToken={ reset_password_token} />
          ) : (
            <Redirect
              to={ {
                pathname: '/login',
                state: { from: props.location },
              } }
            />
          )
        )
      }
    />
  );
};

export default withRouter(ProtectedRoute);

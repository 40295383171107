/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from "react";
import _, { isEmpty } from "lodash";
import {
  Col,
  Icon,
  Row,
  Card,
} from "../../common/UIComponents";
import logo from "../../../assets/images/logo-thumbnail.png";
import placeholder from "../../../assets/images/not_available.png";
import Gallery from "../common/Gallery";
import EyeIcon from "../common/EyeIcon";
import MixedGallery from "../../stories/MixedGallery";
import BaseModal from "../BaseModal";

class Legs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      pictures: this.props.pictures ? this.props.pictures : [],
      signature: this.props.signature ? this.props.signature : "",
      showType: this.props.showType,
      lightboxImages: [],
      fileName: '',
    };
  }

  openLightbox = (index, event, type) => {
    event.preventDefault();
    if (type === "pics" && this.props.imageAction) {
      this.props.imageAction();
    } else if (type === "pics") {
      this.setState({
        lightboxImages: [...this.state.pictures],
        lightboxIsOpen: true,
      });
    } else if (type === "signature") {
      this.setState({
        lightboxImages: [this.state.signature],
        lightboxIsOpen: true,
      });
    } else {
      const lightboxImages = [...this.state.pictures];
      if (this.state.signature && this.state.signature !== "") {
        lightboxImages.push(this.state.signature);
      }
      this.setState({
        lightboxImages,
        lightboxIsOpen: true,
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    return {
      pictures: props.pictures ? props.pictures : [],
      signature: props.signature ? props.signature : "",
      showType: props.showType,
    };
  }

  getFirstImage = (images) => {
    images = images || "";
    for (let i = 0; i < images.length; i++) {
      if (images[i]) {
        return images[i];
        break;
      }
    }
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  handleFileNameChange = (newFileName) => {
    const formattedFileName = newFileName.replace(/[_-]/g, ' ');
    this.setState({ fileName: formattedFileName });
  }

  render() {
    const { pictures, signature, showType, lightboxImages } = this.state;
    let picturesLength = 0;
    let firstImage = "";
    let imgUrl = "";
    let galleryImages = [];

    const divStyle = {
      width: "100%",
      height: "100%",
    };

    if (showType !== "iconClick") {
      picturesLength = pictures ? pictures.length : 0;
      if (showType === "imageClick" && !isEmpty(signature)) {
        picturesLength = picturesLength + 1;
      }
      firstImage = this.getFirstImage(pictures);
      imgUrl = firstImage || logo;
      galleryImages = _.uniq(lightboxImages).map((pic) => ({ src: pic }));
    } else {
      galleryImages = _.uniq(lightboxImages).map((pic) => ({ src: pic }));
    }

    return (
      <Fragment>
        {showType === "seperate" && (
          <Row gutter={16} className="event-images alignLeft">
            <Col xs={24} sm={24} md={12} lg={12} className="padding5 alignLeft">
              <h4>Signature:</h4>
              <Card
                hoverable
                style={{ width: "100%", height: 120, padding: 5 }}
                className="podLegs"
              >
                {signature ? (
                  <div
                    style={divStyle}
                    onClick={(e) => this.openLightbox(0, e, "signature")}
                  >
                    <img
                      src={signature}
                      alt="signature"
                      style={{ height: 100, width: "100%" }}
                    />
                  </div>
                ) : (
                  <img
                    src={placeholder}
                    alt="signature"
                    style={{ height: 100, width: "100%" }}
                  />
                )}
              </Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              className="padding5 event_pics alignLeft"
            >
              <h4>Attachemts:</h4>
              <Card
                hoverable
                style={{ width: "100%", height: 120, padding: 5 }}
              >
                {picturesLength > 0 ? (
                  <div
                    style={divStyle}
                    onClick={(e) => this.openLightbox(0, e, "pics")}
                  >
                    <img
                      src={imgUrl}
                      alt="pod"
                      style={{ height: 100, width: "100%" }}
                    />
                    <h2 className="button2">
                      {picturesLength}{" "}
                      {picturesLength > 1 ? "Attachments" : "Attachment"}{" "}
                    </h2>
                  </div>
                ) : (
                  <div style={divStyle}>
                    <img
                      src={placeholder}
                      alt="pod"
                      style={{ height: 100, width: "100%" }}
                    />
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        )}
        {showType === "iconClick" && (
          <Fragment>
            <EyeIcon handleClick={(e) => this.openLightbox(0, e, "all_pics")} />
          </Fragment>
        )}
        {showType === "imageClick" ? (
          picturesLength > 0 ? (
            <div
              style={divStyle}
              onClick={(e) => this.openLightbox(0, e, "pics")}
              /* all_pics */
            >
              <img
                src={imgUrl}
                alt="pod"
                style={{ height: 100, width: "100%" }}
              />
              <h2 className="button1">{picturesLength} Images </h2>
            </div>
          ) : (
            <div style={divStyle}>
              <img
                src={placeholder}
                alt="pod"
                style={{ height: 100, width: "100%" }}
              />
            </div>
          )
        ) : (
          ""
        )}

      {this.state.lightboxIsOpen &&
        this.state.lightboxImages.length > 0 &&
        <Fragment>
          {
            !this.props.currentStatus || this.props.currentStatus === "APPROVED"  ?
              <BaseModal
              visible={this.state.lightboxIsOpen}
              title="Gallery"
              onCancel={this.closeLightbox}
              footer={null}
              width="70%"
              style={{ top: 10}}
            >
              <div>
                <MixedGallery
                  files={galleryImages.map((pic) => pic.src)}
                  onFileNameChange={this.handleFileNameChange}
                  currentImage={galleryImages[0].src}      
                />
              </div>
            </BaseModal>
            : !this.props.currentStatus === "APPROVED" ? (
              <Gallery images={galleryImages} onClose={this.closeLightbox} />
            ) : null
          }
      </Fragment>}
      </Fragment>
    );
  }
}

export default Legs;

import objectToFormData from "object-to-formdata";
import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";

export function setGridConfigSetting(data) {
  const { config_type, config_value,organization_id,user_id } = data;
  const url = ApiUrl(`v2/grid_configs/update_setting`);
  const formData = objectToFormData({  config_type, config_value,organization_id,user_id  });
  return Call("put", url, formData, ParseGeneralResponse);
}

export function fetchUserForDisp(orgId,userId) {
  const url = ApiUrl(
    `v2/grid_configs?organization_id=${orgId}&user_id=${userId}`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        configuration: "configuration",
      },
    },
  );
}

import React, { useEffect, useRef, useState } from 'react';
import _ from "lodash";
import PropTypes from "prop-types";
import { compose } from "recompose";
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';
import { Spin } from 'antd';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import styles from "../../javascripts/components/common/mapStyle.json";
import { Marker } from 'react-google-maps';
import greenMarker from "../../assets/images/cyan_filled.png";
import MultiMarkers from './MultiMarkers';
import RedMarker from "./red_marker.svg";
import { reverseGeoCodeFromLatLng } from '../helpers/common';
import { alertMessage, isEmpty } from '../common/Common';
import DriverMarkers from './DriverMarkers';


const IncorrectOrdersMap = compose(
  withScriptjs,
  withGoogleMap,
)((props) => {
  const mapRef = useRef(null);
  const { orderInfo , rowKeys, resetCustomMarker , pathName = ""  } = props;
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [ fitToBoundsAlways, setFitToBoundsAlways ] = useState(true); 
  const [customMarker, setCustomMarker] = useState({
    position :null,
    address : null,
  });
  const [geocoder, setGeocoder] = useState(null); // Geocoder instance
  const [doesCoordinatesExist, setDoesCoordinatesExist] = useState(false); 
  const [latLngArray, setLatlngArray] = useState([]);
  const [singleOrderlatLng, setSingleOrderlatLng] = useState([0,0]); // in this array first element is longitude and second is latitude
  const [isMultiAddressResolve , setIsMultiAddressResolve] = useState(false);

  useEffect(() => {
    const orderAddress = Array.isArray(orderInfo) ? orderInfo.map(address => address.customer_address) : orderInfo;  
    let lat = 0; let lon = 0;
    const isMultiAddressResolveActon = Array.isArray(orderAddress);
    if(!isMultiAddressResolveActon)
    {
      // single address resolve
      [ lon, lat ] = Array.isArray(orderAddress.coordinates) ? orderAddress.coordinates : [ 0, 0 ];
      if(customMarker.position === null){ 
        setSingleOrderlatLng([lon, lat]);
      }
      setDoesCoordinatesExist(_.isNumber(lon) && _.isNumber(lat));
    }else{
      // multi address resolve
      const addressLatLngs= orderAddress.map(address => {
        const [ lon, lat ] = Array.isArray(address.coordinates) ? address.coordinates : [ 0, 0 ];
        return {
          lat,
          lon
        }
      }
      );
      setLatlngArray(addressLatLngs);
    }
    setIsMultiAddressResolve(isMultiAddressResolveActon);
  }, [orderInfo])

  // if custom marker is present then remove it 
  useEffect(() => {
    if (!customMarker.position) return;
    if (resetCustomMarker) {
      setCustomMarker({
        position: null,
        address: null,
      });
      props.handleResetCustomMarker();
    }
  }, [ resetCustomMarker ]);

  // set bounds 
  useEffect(() => {
    // get bounds of the map and set the bounds of the map for mapRef
    if (fitToBoundsAlways) {
      const bounds = new window.google.maps.LatLngBounds();
      if (isMultiAddressResolve) {
        // if no rows are selected,no need to set bounds
        if ((rowKeys.length === 0) && (!["/routes"].includes(pathName))) return;
        // if ((rowKeys.length === 0) && (!["/routes","/routes-beta"].includes(pathName))) return;
        latLngArray.forEach(latLng => {
          bounds.extend(new window.google.maps.LatLng(latLng.lat, latLng.lon));
        }
        );
      }
      else if (doesCoordinatesExist) {
        bounds.extend(new window.google.maps.LatLng(singleOrderlatLng[ 1 ], singleOrderlatLng[ 0 ]));
      }
      if (props.fitOnce && (orderInfo.length > 0) && (latLngArray.length > 0)) {
        setFitToBoundsAlways(false);
      }
      if (customMarker.position) {
        bounds.extend(new window.google.maps.LatLng(customMarker.position.lat, customMarker.position.lng));
      }
      mapRef.current.fitBounds(bounds);
    }
    // extens custom marker
  }, [
    latLngArray,
    doesCoordinatesExist,
    isMultiAddressResolve,
    singleOrderlatLng
  ])


  useEffect(() => {
    if(!geocoder && window.google && window.google.maps){
      setGeocoder(new window.google.maps.Geocoder());
    }
  }, []);

  useEffect(() => {
    if(customMarker.position && geocoder){
      async function fetchAddress () {
        try {
          const address = await reverseGeoCodeFromLatLng({
            location: customMarker.position,
            geocoder,
          });
          if (address) {
            setCustomMarker({ ...customMarker, address });
          }
        }
        catch (err) {
          alertMessage(err , "error");
        }
      }
      fetchAddress();
    }
  }, [customMarker.position]);

  // on change of custom marker adress call the  handleCustomMarkerSelection props
  useEffect(() => {
    // set address in parent address component
    if(customMarker.address){
      props.handleCustomMarkerSelection(customMarker.address);
    }
  }, [customMarker.address]);

  const onMarkerHover = (customer_order_id = null) => {
    setHoveredMarker(customer_order_id);
  };

  useEffect(() => {
    // if hovermarker then close the info window of stop
    if (hoveredMarker !== null && props.activeMarker) {
      props.handleActiveMarkerUpdate(null);
    }
  }, [ hoveredMarker ]);

  const handleMapClick = (e) => {
    if(!["/routes"].includes(pathName)){
    // if(!["/routes","/routes-beta"].includes(pathName)){
    setCustomMarker({
      position: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      },
      address: null
    });
  }}

  return (
    <Spin spinning={ false } delay={ 1000 }>
      <GoogleMap
        { ...props }
        defaultOptions={ {
          streetViewControl: false,
          scaleControl: true,
          mapTypeControl: false,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
          zoomControl: false,
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_RIGHT,
          },
          disableDefaultUI: true, // disable default map UI
          draggable: true, // make map draggable
          keyboardShortcuts: false, // disable keyboard shortcuts
          scrollwheel: true, // allow scroll wheel
          styles, // change default map styles
        } }
        ref={ (c) => {
          mapRef.current = c;
        } }
        onClick={handleMapClick}
      >
        {/* single order  */}
        { doesCoordinatesExist && (
          <Marker position={ {
            lat: singleOrderlatLng[1],
            lng: singleOrderlatLng[0]
          } }
            icon={
              {
                url: greenMarker,
                size: { width: 20, height: 20 },
                scaledSize: { width: 20, height: 20 },
              }
            }
          />
        ) }

        {/* multi order  */}
        { isMultiAddressResolve && <MultiMarkers
          orderInfo={ orderInfo }
          rowKeys={ rowKeys }
          latLngArray={ latLngArray }
          hoveredMarker={ hoveredMarker }
          onMarkerHover={ onMarkerHover }
          handleMarkerSelection={ props.handleMarkerSelection }
        /> }
        {/* driver locations */}
        { props.routes && props.routes.length > 0 &&
          <DriverMarkers className="driverMarkers" routes={ props.routes } showRouteSummary={ props.showRouteSummary } driversInfo={ props.driversInfo } isMilitaryTime={props.isMilitaryTime} />
        }
        {/* custom location seleciton marker */}
        { customMarker.position && (
          <Marker position={ {
            lat: customMarker.position.lat,
            lng: customMarker.position.lng
          } }
            draggable={ true }
            onDragEnd={ (e) => {
              setCustomMarker({
                 position: {
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng()
                },
                address : null
              });
            } }
            icon={
              {
                url: RedMarker,
                size: { width: 40, height: 40 },
                scaledSize: { width: 40, height: 40 },
              }
            }
            >
            </Marker>
        ) }

      </GoogleMap>
    </Spin>
  );
})


export default IncorrectOrdersMap;


IncorrectOrdersMap.propTypes = {
  orderInfo: PropTypes.array,
  rowKeys: PropTypes.array,
  handleMarkerSelection: PropTypes.func,
  fitOnce: PropTypes.bool,
  resetCustomMarker: PropTypes.bool,
};

IncorrectOrdersMap.defaultProps = {
  orderInfo: [],
  rowKeys: [],
  handleMarkerSelection: () => { },
  fitOnce: false,
  resetCustomMarker: false,
};

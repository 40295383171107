import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import _ from "lodash";
import { isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Row,
  Col,
  Checkbox,
  Icon,
  Input,
  Popconfirm,
  Radio,
  TextArea,
  Card,
  Button,
  Text,
  Spin
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import { DriverSelect } from "../common/SelectDropdowns/BaseSelect";
import { getOrdinal } from "../../helpers/array_functions";
import DeleteIcon from "../common/DeleteIcon";
import { getSlotInfo } from "../configurations/helper";

const RadioGroup = Radio.Group;


const BillingAccessorialRow = (props) => {
  const authorizeDetails = props?.authorizeDetails ? props.authorizeDetails : {};
  const reqFrom = props.reqFrom ? props.reqFrom : 'orders';
  const renderAttemptedDriver = (value, record, orderAccObject, index, ordinalCount) => (
    <Col xs={ 8 } key={ `drv${index}` }>
      <Row className="marginBottom5">
        <Col xs={ 10 }> { getOrdinal(ordinalCount) }&nbsp;Attempt by
          {/* {AppConfig.roles.driver} */ }
        </Col>
        <Col xs={ 1 }>:</Col>
        <Col xs={ 13 }>
          <DriverSelect
            showSearch
            data={ props.drivers }
            value={ !isEmpty(value) ? value : '' }
            onChange={ e => props.onChange(record.id, 'attempted_driver_ids', e, index) }
            placeholder={ I18n.t('location.location') }
            style={ { width: '88%' } }
            selectKey="id"
            selectValue="id"
            selectedValue="employee_code"
            size="small"
            showSelectOption={ isEmpty(value) ? true : false }
          />
          {
            index >= 0 &&
            <Fragment>
              &nbsp;
              <Popconfirm
                placement="topRight"
                title={ I18n.t("messages.delete_confirm") }
                onConfirm={ () => props.onChange(record.id, 'attempted_driver_ids', '', index) }
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon />
                &nbsp;&nbsp;&nbsp;
              </Popconfirm>

            </Fragment>
          }
        </Col>
      </Row>
    </Col>);

  const renderSlotObject = (
    paramDefinition,
    accountAccComponent,
    accessorial,
    orderAccObject,
    currentParamObj,
  ) => {
    const size = 'small';
    const slotObject = props.slotConfig[ accessorial.id ];
    const paramValues = slotObject && slotObject.length > 0 && slotObject[ 0 ].param_values && slotObject[ 0 ].param_values.length > 0 ? slotObject[ 0 ].param_values : {};
    const slotConfigObjects = paramValues.length > 0 ? paramValues[ 0 ].expedite_sharp : [];
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === 'los';
    const isDisabled = isEmpty(orderAccObject) || isLos;
    const value = orderAccObject?.driver_pay_params &&
      orderAccObject.driver_pay_params?.time_slots ?
      `${orderAccObject.driver_pay_params.time_slots.from_time}-${orderAccObject.driver_pay_params.time_slots.to_time}` :
      "";
    return (
      <Fragment>
        {
          slotConfigObjects &&
          <Fragment>
            <Radio.Group
              value={ value }
              onChange={ e => props.onChange(accessorial.id, 'time_slots', e.target.value, "") }
              // disabled={ isDisabled }
              size={ size }
            >
              {
                slotConfigObjects.map(slot => (<Radio value={ `${slot.from_time}-${slot.to_time}` }>{ getSlotInfo(slot, props.isMilitaryTime) }</Radio>))
              }
            </Radio.Group>
          </Fragment>
        }
      </Fragment>
    );
  };

  const renderField = (
    paramDefinition,
    accountAccComponent,
    accessorial,
    orderAccObject,
    currentParamObj,
    isDisabled
  ) => {
    const size = 'small';
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === 'los';
  // const isDisabled = isEmpty(orderAccObject) || isLos;
    switch (paramDefinition.data_type) {
      case 'string':
        return (
          <Row>
            <Col>
              { paramDefinition.code === 'charge_type' ? (
                <Radio.Group
                  value={ currentParamObj && currentParamObj.accessorial_value }
                  onChange={ (e) =>
                    props.handleOnParamChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value,
                    )
                  }
                  disabled={ isDisabled }
                  size={ size }
                >
                  <Radio value="percentage">Percentage</Radio>
                  <Radio value="flat">Flat</Radio>
                </Radio.Group>
              ) : paramDefinition.code === 'charge_value' ? (
                <Input
                  type="number"
                  addonBefore={
                    currentConfiguration[ component.code ].charge_type ===
                      'flat' ? (
                      <Icon type="dollar" />
                    ) : null
                  }
                  addonAfter={
                    currentConfiguration[ component.code ].charge_type ===
                      'percentage' ? (
                      <Icon type="percentage" />
                    ) : null
                  }
                    value={ currentConfiguration[ component.code ][ param.code ] }
                    onChange={ (e) =>
                      props.handleOnParamChange(
                        component.code,
                        param.code,
                        e.target.value,
                      )
                    }
                  className="textUpperCase"
                  min={ 0 }
                  precision={ props.decimalPoints }
                  defaultValue={ 0 }
                  size={ size }
                // disabled={isDisabled}
                />
              ) : (
                <Input
                  size={ size }
                      value={ currentParamObj && currentParamObj.accessorial_value }
                      onChange={ (e) =>
                        props.handleOnParamChange(
                          paramDefinition.code,
                          accountAccComponent.component_code,
                          accessorial.id,
                          e.target.value,
                        )
                      }
                      disabled={ isDisabled }
                />
              ) }
            </Col>
          </Row>
        );
      case 'number':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={ size }
                value={ currentParamObj && currentParamObj.accessorial_value }
                onChange={ (e) =>
                  props.handleOnParamChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value,
                  )
                }
                disabled={ isDisabled }
                min={ 0 }
                precision={
                  paramDefinition.code === 'min_charge' ||
                    paramDefinition.code === 'max_charge'
                    ? props.decimalPoints
                    : 0
                }
              />
            </Col>
          </Row>
        );
      case 'price':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={ size }
                value={ currentParamObj && currentParamObj.accessorial_value }
                onChange={ (e) =>
                  props.handleOnParamChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value,
                  )
                }
                addonBefore={ <Icon type="dollar" /> }
                disabled={ isDisabled }
                min={ 0 }
                precision={ props.decimalPoints }
              />
            </Col>
          </Row>
        );
      case 'Boolean':
        return (
          <Row>
            <Radio.Group
              value={ currentParamObj && currentParamObj.accessorial_value }
              onChange={ (e) =>
                props.handleOnParamChange(
                  paramDefinition.code,
                  accountAccComponent.component_code,
                  accessorial.id,
                  e.target.value,
                )
              }
              size={ size }
              disabled={ isDisabled }
            >
              <Radio value>Yes</Radio>
              <Radio value={ false }>No</Radio>
            </Radio.Group>
          </Row>
        );
      case 'Select':
        return (
          <Select
            showSearch
            size={ size }
            value={ currentParamObj && currentParamObj.accessorial_value }
            style={ { width: '100%' } }
            placeholder="Select"
            filterOption={ (input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={ (e) =>
              props.handleOnParamChange(
                paramDefinition.code,
                accountAccComponent.component_code,
                accessorial.id,
                e,
              )
            }
            disabled={ isDisabled }
          >
            { paramDefinition.options.map((qoption, qindex) => (
              <Select.Option
                key={ qoption.option_key }
                value={ qoption.option_key }
              >
                { qoption.option_value }
              </Select.Option>
            )) }
          </Select>
        );
      default:
        break;
    }
  };

  const processRow = (record, orderAccessorials) => {
    const authorizeRecord = authorizeDetails && authorizeDetails[ record.accessorial_code ] ? authorizeDetails[ record.accessorial_code ] : {};
    const orderAccObject = orderAccessorials.find(
      (acc) => acc.account_accessorial_id === record.id
    );
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === "los";
    const isReadOnly = props.isReadOnly;
    let orderComponentParams = [];
    if (!isEmpty(orderAccObject) && !isEmpty(orderAccObject.component_params)) {
      orderComponentParams = orderAccObject.component_params;
    }
    const componentDefinitions =
      !isEmpty(record.componentDefs) &&
        _.isArray(record.componentDefs) &&
        record.componentDefs.length > 0
        ? record.componentDefs.filter((component) =>
          props.displayComponents.includes(component.code)
        )
        : [];
    const prevAttempts = orderAccObject?.driver_pay_params &&
      orderAccObject.driver_pay_params?.attempted_driver_ids ? orderAccObject.driver_pay_params.attempted_driver_ids : [];
    const isDisabled = props.isEditing ? false : !props.isNew


    const renderParam = (paramDefinition, componentDef, record, orderAccObject, currentParamObj, isDisabled, isReadOnly, paramObject) => {
      const isSlotObject = paramDefinition.code === 'SLOT_CONFIG';
      const isReadOnlyAndNotEmpty = isReadOnly && !_.isEmpty(currentParamObj);
      const paramObjectValue = _.get(paramObject, 'accessorial_value', paramDefinition.name);
      const currentParamObjValue = _.get(currentParamObj, 'accessorial_value', '-');

      if (!(isReadOnlyAndNotEmpty || !isReadOnly)) {
        return null;
      }

      const renderContent = isSlotObject ? renderSlotObject : renderField;
      const content = !props.isReadOnly
        ? renderContent(
          paramDefinition,
          {
            ...componentDef,
            component_code: componentDef.code,
          },
          record,
          orderAccObject,
          currentParamObj,
          isDisabled
        )
        : currentParamObjValue;

      return (
        <Col xs={ isSlotObject ? 24 : 6 } className="fontSize12">
          <Row>
            <Col xs={ isSlotObject ? 4 : 10 }>
              { paramDefinition.code === "things_count" && !_.isEmpty(paramObject) ? paramObjectValue : paramDefinition.name }
            </Col>
            <Col xs={ 1 }>:</Col>
            <Col xs={ isSlotObject ? 19 : 13 }>
              <Fragment>
                { content }
              </Fragment>
            </Col>
          </Row>
        </Col>
      );
    };

    const renderComponent = (componentDef, orderComponentParams, isReadOnly) => {
      const paramObject = _.find(componentDef.component_params, { accessorial_key: "collection_name" });
      const orderComponent = _.find(orderComponentParams, { component_code: componentDef.code });
      const orderParamValues = _.get(orderComponent, 'component_values', []);
      const componentParams = _.filter(componentDef.component_params, (param) => _.includes(param.visible_to, "DISPATCHER"));

      return componentParams.map((paramDefinition) => {
        const currentParamObj = _.find(orderParamValues, { accessorial_key: paramDefinition.code });
        return renderParam(paramDefinition, componentDef, record, orderAccObject, currentParamObj, isDisabled, isReadOnly, paramObject);
      });
    };

    const renderComponents = (componentDefinitions, orderComponentParams, isReadOnly) => {
      if (_.isEmpty(componentDefinitions)) {
        return null;
      }

      return (
        <Fragment>
          { componentDefinitions.map((componentDef) => renderComponent(componentDef, orderComponentParams, isReadOnly)) }
        </Fragment>
      );
    };


    const paramsFound = (record.accessorial_config_params && record.accessorial_config_params.eligible_for && record.accessorial_config_params.eligible_for == 'wh_handling') || (reqFrom === 'orders' && record.standard_code === 'ATMP' && !isEmpty(orderAccObject) &&
      Array.isArray(prevAttempts)) || (reqFrom === 'orders' && record.standard_code === 'ATMP');

    const showComponentWithParams = componentDefinitions.some(componentDef => componentDef.component_params.some(param => param.visible_to.includes('DISPATCHER')));

    const noParamsToInput = !paramsFound && !showComponentWithParams

    if(noParamsToInput) {
      // return null
      // give a text with button side to generate the charges
      if(!props.isNew && !props.isEditing) { return null }
      props.onflatChargeDetected(true , !props.isNew)
      return null;

      const { currentAccCost , fetchingAccCost } = props
      return (
        <Row type="flex" justify="space-between" align="middle" gutter={ 16 } className="marginTop5">
          <Col span={ 24 } className="">
            <Text>
              {fetchingAccCost ? 'Retrieving cost information...' : ''}
              { !fetchingAccCost && currentAccCost === 0 &&
                <Text style={ { color: 'red', fontSize: 15 } } className="notes_content textBold">
                  Note: Cannot apply Accesorial Amount with 0 value
                </Text>
              }
              { !fetchingAccCost && currentAccCost !== 0 &&
                <Text>
                  An amount of {' '}
                  <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                    ${currentAccCost}
                  </Text>
                  {' '}
                  will be applied 
                </Text>
              }
            </Text>
          </Col>
          <Col>
            <Row type="flex" justify="space-between" align="middle" gutter={ 16 } className="margin5">
              <Col>
                  <Button loading={ props.isAccesorialLoading } size="small" type="primary" onClick={ props.saveAccesorial } disabled={ fetchingAccCost || currentAccCost === 0 }> Apply Charges </Button>
              </Col>
              <Col>
                <Button size="small" onClick={ props.cancelAccesorial }> Cancel </Button>
              </Col>
            </Row>
          </Col>
          
        </Row>)
    }

    props.onflatChargeDetected(false)
    return (
      <Row type="flex" justify="space-between" align="middle" gutter={ 16 } className="marginTop5">
        <Col span={ 24 }>
          <Spin spinning={ props.isAccesorialLoading }>
            <Card size="small" className="marginTop5">
              <Row className="marginTop5">
                <Col xs={ 24 }>
                  <Row gutter={ 16 }>
                    { record.accessorial_config_params && record.accessorial_config_params.eligible_for && record.accessorial_config_params.eligible_for == 'wh_handling' &&
                      <Col xs={ 6 }>
                        <Row>
                          <Col xs={ 10 }>{ AppConfig.roles.technician }</Col>
                          <Col xs={ 1 }>:</Col>
                          <Col xs={ 13 }>
                            <DriverSelect
                              showSearch
                              data={ props.technicians }
                              value={ !isEmpty(orderAccObject) && orderAccObject.driver_pay_params?.driver_id ? orderAccObject.driver_pay_params.driver_id : '' }
                              onChange={ e => props.onChange(record.id, 'driver_id', e, 'eligible_for') }
                              placeholder={ I18n.t('location.location') }
                              style={ { width: '100%' } }
                              selectKey="id"
                              selectValue="id"
                              selectedValue="employee_code"
                              size="small"
                            // disabled={isEmpty(orderAccObject)}
                            />
                          </Col>
                        </Row>
                      </Col> }
                    {/* Start Render attempt details */ }
                    { reqFrom === 'orders' && record.standard_code === 'ATMP' && !isEmpty(orderAccObject) &&
                      prevAttempts &&
                      prevAttempts.map((rec, index) => renderAttemptedDriver(rec, record, orderAccObject, index, index + 1))
                    }
                    { reqFrom === 'orders' && record.standard_code === 'ATMP' &&
                      renderAttemptedDriver('', record, orderAccObject, -1, prevAttempts.length + 1)
                    }
                    { renderComponents(componentDefinitions, orderComponentParams, isReadOnly) }
                  </Row>
                </Col>
              </Row>
            </Card>
          </Spin>
        </Col>
        { (props.isNew || (props.isActiveRow)) && (
        // { (props.isNew || (props.isActiveRow && props.isHavingZeroAmount)) && (
          <Row type="flex" justify="space-between" align="middle" gutter={ 16 } className="margin5" style={{ marginLeft: 0}}>
            <Col className="paddingLeft5">
              <Button size="small"
              loading={ props.isAccesorialLoading }
              type="primary" onClick={ props.saveAccesorial }> Calculate & Apply Charges </Button>
            </Col>
            <Col>
              <Button size="small" onClick={ props.cancelAccesorial }> Cancel </Button>
            </Col>
          </Row>
        ) }
      </Row>
    );
  };
  const data = props.data || [];

  const [ revisedData, setRevisedData ] = useState(data);

  // when data updates from parent
  useEffect(() => {
    setRevisedData(props.data);
  }, [ props.data, props.orderAccessorials ]);

  const handleSearchChange = (e) => {
    if (e.target.value == "") {
      setRevisedData(data);
    } else {
      const filteredData = filterData(e.target.value);
      setRevisedData(filteredData);
    }
  };


  const filterData = (val) => {
    return (data.filter(item =>
      item.accessorial_name.toLowerCase().includes(val.toLowerCase()) ||
      item.accessorial_code.toLowerCase().includes(val.toLowerCase())
    ));
  };

  return (
    <Fragment>
      {/* <Row className="textBold fontSize13" gutter={16} style={{ height: 30 }}>
        <Col xs={6}>{I18n.t("general.accessorial")} </Col>
        <Col xs={3}>{I18n.t("general.code")} </Col>
        <Col xs={15}></Col>
      </Row> */}
      {
        props.hideSearch ? (
          revisedData.map((record) => (
            <Fragment>
              { processRow(record, props.orderAccessorials) }
            </Fragment>
          ))
        ) : (
          <>
            <Input
              placeholder="Search Accessorials"
              onChange={ handleSearchChange }
              style={ { marginBottom: '10px' } }
            />
            { revisedData.map((record) => (
              <Fragment>
                { processRow(record, false) }
              </Fragment>
            )) }
          </>
        )
      }

    </Fragment>
  );
};

export default BillingAccessorialRow;
BillingAccessorialRow.propTypes = {
  renderSlotObject: PropTypes.func,
  handleAuthorizeChange: PropTypes.func,
  // renderField: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.array,
  isReadOnly: PropTypes.bool,
  technicians: PropTypes.array,
  order: PropTypes.object,
  orderAccessorial: PropTypes.array,
  orderComponentParams: PropTypes.array,
  orderAccessorialParams: PropTypes.array,
  orderAccessorialAttemptedDrivers: PropTypes.array,
  reqFrom: PropTypes.string,
  hideSearch: PropTypes.bool,
};



// default 
BillingAccessorialRow.defaultProps = {
  renderSlotObject: () => { },
  handleAuthorizeChange: () => { },
  reqFrom: 'orders',
  required_accessorials: [],
  hideSearch: false
};

import React, { Fragment, useEffect, useState } from 'react'
import _, { isEmpty,  result } from 'lodash'
import PropTypes from 'prop-types'
import {
  Button,
  Spin,
  Col,
  Row,
  Icon,
  Popconfirm,
  Card,
  Alert
} from 'antd'
import I18n from '../../common/I18n'
import { alertMessage } from '../../common/Common'
import moment from 'moment'
import { ReleasesApi } from '../../api/ReleasesApi'
import ReleaseDetails from './ReleaseDetails'
import DisplayTime from '../common/DisplayTime'
import { ReleaseForm } from './ReleaseForm'
import { getReleaseSatusColor } from '../../helpers/release'
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common'
import { momentTime } from '../../helpers/date_functions'

const defaultRelease = {
  company_name: '',
  delivery_location: {},
  scheduled_release_time: null,
  release_type: "cross_dock",
  delivery_location: {},
  pickup_person: '',
  email: '',
  contact_no: ''
}

function ReleaseManage(props) {
  const {
    order_id,
    clickedSave,
    actionType = 'view',
    orderWH,
    timeZoneId,
    account_id,
    org_id,
  } = props

  const [errors, setErrors] = useState([])
  const [isNew, setIsNew] = useState(false)
  const [releases, setReleases] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if(checkServiceExistance('OREI')){
      getReleases()
    }
  }, [])

  const getReleases = () => {
    try {
      setIsLoading(true)
      ReleasesApi.fetch(false, order_id).then((result) => {
        if (result.success) {
          const releasesList = result.releases || []
          const releaseObjects = releasesList.map((release) => {
            return {
              ...release,
              scheduled_release_time: !isEmpty(release.scheduled_release_time)
                ? moment.utc(release.scheduled_release_time)
                : null,
              // scheduled_release_time: !isEmpty(release.scheduled_release_time)
              //   ? ((!isEmpty(orderWH) && orderWH.timeZoneId ? momentTime("", release.scheduled_release_time, orderWH.timeZoneId) : moment(release.scheduled_release_time)))
              //   : null,
              isNew: false,
            }
          })
          setReleases(releaseObjects)
        } else {
          renderAlertMessage(result.errors)
        }
      })
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteClick = (id) => {
    setIsLoading(false)
    if (!isEmpty(id)) {
      try {
        ReleasesApi.delete(id).then((result) => {
          if (result.success) {
            alertMessage('messages.deleted', 'success', 10)
            getReleases()
          } else {
            renderAlertMessage(result.errors)
          }
        })
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    } else {
      setIsNew(false)
    }
  }

  const refreshReleases = () => {
    setIsNew(false)
    getReleases()
  }

  return (
    <Spin spinning={isLoading} delay={1000}>
      <div className="padding10">
        {actionType !== 'view' ? (
          <Row
            className="quote-order-consignee-details"
            type="flex"
            gutter={32}
          >
            <Col xs={24}>
              {releases.length > 0 ? (
                <Fragment>
                  {releases.map((release, index) => {
                    const rec = {
                      ...release,
                      delivery_location:
                        !isEmpty(release.delivery_location_id) &&
                        release.delivery_location
                          ? release.delivery_location
                          : {},
                      scheduled_release_time: release.scheduled_release_time ? momentTime("" , release.scheduled_release_time, release.timeZoneId) : null,
                    }
                    return (
                      <ReleaseForm
                        key={`updateReleaseForm${release.id}`}
                        currentRelease={rec}
                        isNew={false}
                        getReleases={refreshReleases}
                        request_from={'release'}
                        order_id={order_id}
                        deleteClick={deleteClick}
                        defaultRelease={{ ...defaultRelease }}
                        account_id={account_id}
                        org_id={org_id}
                      />
                    )
                  })}
                </Fragment>
              ) : (
                <Fragment>
                  {
                    checkServiceExistance('OREC') &&  <ReleaseForm
                    key="newReleaseForm"
                        currentRelease={ { ...defaultRelease, warehouse_code: _.isEmpty(orderWH) ? "" : orderWH.location_code }} 
                    isNew={true}
                    getReleases={refreshReleases}
                    request_from={'release'}
                    order_id={order_id}
                    deleteClick={deleteClick}
                    account_id={account_id}
                    org_id={org_id}
                  />
                  }
                </Fragment>
              )}
            </Col>
          </Row>
        ) : (
          <Row className="">
            <Col xs={24}>
            {releases.length > 0 ?
              (<Fragment>
              {releases.map((release, index) => {
                const { scheduled_release_time, status } = release
                const statusColor = getReleaseSatusColor(status)
                return (
                  <Card
                    size="small"
                    title={
                      <Fragment>
                        <span className="textBold fontSize16 textPrimary">
                          <Icon type="calendar" />
                          &nbsp;{I18n.t('releases.scheduled_release_time')} :
                        </span>{' '}
                        &nbsp;&nbsp;
                        <span
                          className="textBold fontSize16 textPrimary"
                          style={{ color: statusColor }}
                        >
                          <DisplayTime
                            isUTC={true}
                            displayWithDate={true}
                            dateTimeString={scheduled_release_time}
                            key={release.id}
                            timeZoneId={timeZoneId}
                          />
                          {/* {!isEmpty(scheduled_release_time) && moment(scheduled_release_time).isValid() ? moment(scheduled_release_time).format(`${AppConfig.date_format} ${AppConfig.time_format}`) : 'NA'} */}
                        </span>
                      </Fragment>
                    }
                    extra={
                      <Fragment>
                        {/* <Button
                              type="default"
                              size="small"
                              shape="circle"
                              icon="edit"
                              className="marginRight5"
                              // onClick={() => handleEditStop(stop.id)}
                            /> */}
                        <span
                          className="textBold fontSize16"
                          style={{ color: '#607AD6' }}
                        >
                          {I18n.t('general.status')} :
                        </span>
                        &nbsp;&nbsp;
                        <span
                          className="textBold fontSize16"
                          style={{ color: statusColor }}
                        >
                          {!isEmpty(status) && I18n.t(`releases.${status}`)}
                        </span>
                        {/* <Popconfirm
                          placement="topRight"
                          title={I18n.t('messages.cancel_confirm')}
                          onConfirm={() => deleteClick(release.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="danger"
                            size={'small'}
                            shape="circle"
                            icon="delete"
                            className="marginRight5"
                          />
                        </Popconfirm> */}
                      </Fragment>
                    }
                  >
                    <ReleaseDetails
                      currentRelease={release}
                      key={`rec${index}`}
                      timeZoneId={timeZoneId}
                      order_id={order_id}
                      account_id={account_id}
                      org_id={org_id}
                    />
                  </Card>
                )
              })}
              </Fragment>) :(
              <Fragment>
                <Alert
                  message={I18n.t('releases.alert')}
                  type="info"
                  showIcon
                />
              </Fragment>
            )}
            </Col>
          </Row>
        )}
      </div>
    </Spin>
  )
}

export default ReleaseManage

ReleaseManage.propTypes = {
  currentRelease: PropTypes.shape(),
}

ReleaseManage.defaultProps = {
  currentRelease: null,
}

import { isEmpty } from "../common/Common";
import {
  ApiUrl,
  Call,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from "./ApiUtils";
import AppConfig from "../config/AppConfig";
import userStore from "../stores/UserStore";

const getAccountId = (_) => localStorage.getItem("accountId");
// const orgId = userStore.getStateValue("selectedOrg");

export function fetchContactDetails(id) {
  const url = ApiUrl(`v2/contacts/${id}?organization_id=${userStore.getStateValue("selectedOrg")}&operation_code=CONI`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { contacts: "" },
  });
}

export const fetchContacts = ({
  page = 1,
  perPage = AppConfig.perPage,
  render_type = "",
  isOrgLevel,
  accountId = null ,
}) => {
  const orgID = userStore.getStateValue("selectedOrg");
  if (isEmpty(accountId)) {
    accountId = getAccountId();
  }
  let url = ApiUrl(`v2/contacts?operation_code=CONI`);
  if (accountId) {
    url += `&render_type=${render_type}&level=ACCOUNT&level_id=${accountId}&${PageParams(
      page,
      perPage
    )}`;
  } else {
    url += `&render_type=${render_type}&level=ORGANIZATION&level_id=${userStore.getStateValue("selectedOrg")}&${PageParams(
      page,
      perPage
    )}`;
  }

  return Call("get", url, null, ParseGeneralResponse, {
    responseDataKeys: {
      contacts: "contacts",
      pagination: "meta.pagination",
    },
  });
};

export const deleteContact = (id) => {
  let url = ApiUrl(`v2/contacts/${id}`);
  const method = "DELETE";
  return Call(method, url, {operation_code:"COND"}, ParseGeneralResponse);
};

export const saveContact = (data, isOrgLevel, accountId = '') => {
  const isNew = isEmpty(data.id);
  let url = ApiUrl(`v2/contacts/${isNew ? "" : data.id}`);
  const method = isNew ? "POST" : "PUT";

  if (!isOrgLevel) {
    data.level = "ACCOUNT";
    data.level_id = accountId || getAccountId();
  } else {
    data.level = "ORGANIZATION";
    data.level_id = userStore.getStateValue("selectedOrg");
  }

  return Call(method, url, data, ParseGeneralResponse,{responseDataKeys: { contact: "contact"}});
};

export const fetchSubscriptionData = (data) => {
  const org_id = userStore.getStateValue("selectedOrg");
  const { level } = data
  let url = ApiUrl(
    // `v2/account_contact_groups/${contact_group_id}/alerts?organisation_id=${org_id}`
    `v2/contacts_groups/alerts?level=${level}&operation_code=CGAL`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      alerts: "",
    },
  });
};

export const saveSubscriptionData = (data, contact_group_id) => {
  data.organization_id = userStore.getStateValue("selectedOrg");
  const formData = setObjectToFormData(data);
  let url = ApiUrl(
    // `v2/account_contact_groups/${data.contact_group_id}/create_alerts`
    `v2/contacts_groups/${contact_group_id}/update_alert_preferences`
  );
  return Call("PUT", url, formData, ParseGeneralResponse);
};
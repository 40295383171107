import React, { Fragment, useContext, useEffect, useState, } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Col,
  Popconfirm,
  Radio,
  Row,
  Select,
} from '../../common/UIComponents'
import { alertMessage, } from '../../common/Common'
import { _ } from 'core-js'
import BaseModal from '../BaseModal'
import ReasonWindow from '../common/ReasonWindow'
import { moveOrdersToNewFromPending } from '../../api/OrdersApi'
import I18n from '../../common/I18n'
import { RejectedReasonApi } from '../../api/RejectedReason'
import { OrgContext } from '../../context/OrgContext'
import { renderAlertMessage } from '../../helpers/common'

const PendingOrdersActions = ({
  handleCancel,
  size,
  orderIds,
  isDisabled = false,
  enableLoading,
  handleStatusResult,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [rejection_code, set_Rejection_code] = useState('NONE');
  const [rejection_reason, setRejection_reason] = useState(false)
  const [approvalRejections, setApprovalRejections] = useState([]);
  const orgContextData = useContext(OrgContext);
  const { currentOrg} = orgContextData;

  const resetValues = (closeModal = false) => {
    setShowInfoWindow(false)
    if (closeModal) {
      // handleCancel()
    }
  }

  useEffect(() => {
    getRejectionReason()
  }, [])

  const handleOptionChange = (value) => {
    set_Rejection_code(value);
  };

  const getRejectionReason = () => {
    const organization_id = currentOrg.id;
    RejectedReasonApi.fetchRejectedReason(organization_id).then((result) => {
      if (result.success) {
        const rejectionMessages = result.rejection_messages || [];
        setApprovalRejections(rejectionMessages);
      } else {
        renderAlertMessage(result.errors)

      }
    });
  };
  

  const renderModalWindow = () => {
    return (
      <BaseModal
        title={I18n.t('general.reason')}
        className="customer-orders-modal"
        onCancel={() => {
          resetValues();
        }}
        width="30%"
      >
        <Row>
          <Col span={24}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              <h4 style={{ marginBottom: '0', marginRight: '10px' }}>{I18n.t("general.rejection_to_reason")}</h4>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select onChange={handleOptionChange} value={rejection_code} style={{ marginLeft: '10px',width:'200px' }}>
                <Select.Option value="NONE">{I18n.t("general.none")}</Select.Option>
                <Select.Option value="CUSTOM">{I18n.t("general.custom_message")}</Select.Option>
                {approvalRejections.map((message) => (
                  <Select.Option key={message.id} value={message.rejection_code}>
                    {message.rejection_message}
                  </Select.Option>
                ))}
              </Select>
              </div>
            </div>
          </Col>
        </Row>
        
        {rejection_code !== 'CUSTOM'  ? (
          <Row>
            <Col span={24}>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={resetValues}
                icon="close"
                style={{
                  marginRight: '10px',
                  marginTop: '10px',
                  width: '100px',
                  height: '40px',
                }}
              >
                {I18n.t("general.close")}
              </Button>
              <Button
                onClick={() => handleStatusChange('REJECTED')}
                className="assignDriver"
                style={{
                  margin: '10px',
                  marginTop: '10px',
                  width: '100px',
                  height: '40px',
                }}
                type="primary"
                icon="save"
              >
                {I18n.t("general.save")}
              </Button>
            </div>
          </Col>

          </Row>
        ) : rejection_code === 'CUSTOM' ? (
          <Row>
            <Col span={24}>
              <div style={{ marginTop: '20px' }}>
        <ReasonWindow
          reasonElement={'rejection_reason'}
          reasonValue={rejection_reason}
          handleOnChange={(element, value) => {
            setRejection_reason(value)
          }}
          closeReasonModal={resetValues}
          saveReason={() => handleStatusChange('REJECTED')}
        />
              </div>
            </Col>
          </Row>
        ) : null}
      </BaseModal>
    );
  };

  const handleStatusChangeCalls = (func, status) => {
    try {
      func().then((result) => {
        handleStatusResult(result, orderIds)
        resetValues(status === 'REJECTED')
      })
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      enableLoading(false)
    }
  }

  const handleStatusChange = (status, reasonMessage = '') => {
    enableLoading(true)
    if (status === 'APPROVED') {
      const data = {
        order_ids: orderIds,
        status: 'APPROVED',
        rejection_reason: 'Approved',
      }
      handleStatusChangeCalls(() => moveOrdersToNewFromPending(data), status)
    } else if (status === 'REJECTED') {
      const selectedReason = approvalRejections.
      filter(msg => msg.rejection_code === rejection_code).
      map((message) => message.rejection_message).join(',')
      let data= {
        order_ids: orderIds,
        status: 'REJECTED',
        rejection_code,
        rejection_reason: rejection_code !== 'CUSTOM' ? selectedReason : (rejection_reason || '')
      }

  
      handleStatusChangeCalls(() => moveOrdersToNewFromPending(data), status);
    }
  }

  return (
    <Fragment>
      
        <Button
        onClick={() => handleStatusChange('APPROVED', 'Approved')}
          type={'primary'}
          icon="file-done"
          disabled={isDisabled}
          size={size ? size : 'default'}
          className="marginLeft10"
        >
          Approve
        </Button>
      &nbsp;
      
        <Button
        onClick={() => setShowInfoWindow(true)}
          type={'danger'}
          icon="close-circle"
          disabled={isDisabled}
          size={size ? size : 'default'}
          className="marginLeft10"
        >
          Reject
        </Button>
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  )
}

PendingOrdersActions.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  orderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PendingOrdersActions

import { Col, Icon, Row } from "antd";
import React  from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import EditIcon from "../common/EditIcon";
import CustomerOrderDetails from "./CustomerOrderDetails";
export default class ClonedOrderList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "#",
        key: "index",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: `${I18n.t("general.source")} ${I18n.t("general.order_number")}`,
        dataIndex: "source_order_number",
        key: "source_order_number",
        render: (data, record) => {
          return this.renderEditableOrder(record.source_order_id, record.source_order_number);
        },
      },
      {
        title: `${I18n.t("order.cloned_order")}`,
        dataIndex: "cloned_order_no",
        key: "cloned_order_no",
        render: (data, record) => {
          return this.renderEditableOrder(record.cloned_order_id, record.cloned_order_no);
        },
      }
    ];
  }

  renderEditableOrder = (orderId ,orderNumber ) => {
    return (
      <Row type="flex" gutter={4}>
        <Col>
          { orderNumber }
        </Col>
        <Col>
          <EditIcon handleClick={() => this.props.handleEditOrder(orderId)}/>
        </Col>
      </Row>
    );
  }

}

/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Spin, Drawer, Icon, Row, Col, Popconfirm, Button, Link, Tooltip,Popover } from '../../common/UIComponents';
import DetailsConfig from '../Tasks/DetailsConfig'
import { checkForSMP, showFMP } from '../../helpers/billing'
import { withRouter } from 'react-router';
import { isEmpty, ellipseText } from "../../common/Common";
import EditIcon from '../common/EditIcon';
import EyeIcon from '../common/EyeIcon';
import Copyable from '../common/Copyable';
import TypeOfOrder from './TypeOfOrder';
import I18n from '../../common/I18n';


class CustomerOrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentOrder: this.props.order || {},
      orders: [],
      inProgress: false,
      detailsVisible: false,
      exptionmessage: this.props.exprecord?.exception_message || {},
      orderNo: this.props.order_no,
      status:this.props.exprecord?.status,
      toShow: this.props.toShow ? this.props.toShow : "order",
      is_updated: false,
      currentPath: this.props.location.pathname,
      requireRefresh : false,
      isAnyOrderCloned: false,
      charsToLimit: this.props.charsToLimit,
    }
  }

  // update path on location change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ currentPath: this.props.location.pathname })
    }
    if (this.props.order !== prevProps.order) {
      this.setState({ currentOrder: this.props.order })
    }
    if(this.props.order_no !== prevProps.order_no){
      this.setState({ orderNo : this.props.order_no })
    }
  }

  getOrderDetails = () => {
    this.setState({
      detailsVisible: true,
    })
  }

  setRefreshValue = (value) => {
    this.setState({
      requireRefresh: value
    })
  }

  handleOrderclonedStatus = (isCloned) => {
    this.setState({requireRefresh: isCloned})
  }

  onClose = () => {
    // if(this.state.requireRefresh){
      this.refreshCallback();
    // }
    this.setState({
      detailsVisible: false,
      requireRefresh: false,
    })
    if(this.state.is_updated){
      this.refreshCallback();
    }
    this.props?.forPopOverClose(this.props.order_no)
  }

  handleEdit = () => {
    this.props.editClick()
    this.onClose()
  }

  refreshCallback = () => {
    if( typeof this.props.refreshCallback === 'function'){
      this.props.refreshCallback();
    }
  }

  updateParentState = (element, value) => {
    this.setState({
      [element]: value,
    })
  } 
  render() {
    const { currentOrder, charsToLimit } = this.state
    const { tab, isAttempted, showEdit= true } = this.props;
    const orderNumberElement = 
      isAttempted && isAttempted === "true" ? (
        <span className="textRed">{this.state.orderNo}</span>
      ) : (
        <span>{this.state.orderNo}</span>
      );
  
    const orderNumberContent = this.state.toShow === "order" ? (
      this.state.status === "EXCEPTION" ?  (
        <Popover
          overlayClassName="popoverWidth"
          title="Exception"
          // content={<div style={{ width: '200px' }}>{!isEmpty(this.state.exptionmessage.exception_message) ? (this.state.exptionmessage.exception_message) : ("Reason not available") } </div>}
          content={
            <div style={{ width: '200px' }}>
              {this.state.exptionmessage?.exception_message
                ? this.state.exptionmessage.exception_message
                : 'Reason not available'}
            </div>
          }
      
        >
          <span className="textRed">{orderNumberElement}</span>
        </Popover>
      ) : (
        <span>{orderNumberElement}</span>
      )
    ) : 
      ellipseText(
        this.props.displayedItem,
        charsToLimit,
        true,
        false,
        this.props.showPopupContainer
      );
    return (
      <Fragment>
      
        <Spin spinning={this.state.inProgress} delay={1000}>
          {!isEmpty(this.props.data) ? (
            <Link onClick={this.getOrderDetails}>{this.props.data}</Link>
          ) : (
              this.props.showIcon ?  <EyeIcon styleObj={{...this.props.eyeIconStyle}} handleClick={this.getOrderDetails}/> : this.state.currentPath.includes('/billing') ? (
                <Row type='flex' gutter={8} style={{ ...this.props.style, flexFlow: "row"}} >
                  <Col>
                    <Link className="clickableLink" onClick={this.getOrderDetails}>
                      { this.state.toShow === "order" ?
                          isAttempted && isAttempted === "true" ? 
                            (<span className="textRed">{this.state.orderNo}</span>) 
                              : this.state.orderNo 
                        :
                        ["hawb","mawb"].includes(this.state.toShow) && !isEmpty(this.props.displayedItem) ? 
                          isAttempted && isAttempted === "true" ? 
                            (<span className="textRed">{ellipseText(this.props.displayedItem, charsToLimit, true)}</span>) 
                              : ellipseText(this.props.displayedItem, charsToLimit, true)
                        : ""
                      }
                    </Link>
                  </Col>
                  {((this.state.toShow !== "order" &&
                    !isEmpty(this.props.displayedItem)) ||
                    (this.state.toShow === "order" &&
                    !isEmpty(this.state.orderNo))) && (
                    <Col>
                      <Copyable
                        text={
                          this.state.toShow !== "order"
                            ? this.props.displayedItem
                            : this.state.orderNo.replace(/\([^()]*\)/g, "")   //replace any content within parentheses
                        }
                      />
                      &nbsp;
                    </Col>
                  )}
                  <Col>
                  {this.state.toShow === "order" && showEdit ? (
                  <Tooltip title={I18n.t("general.edit")}>
                    <EditIcon handleClick={this.handleEdit} />
                  </Tooltip>
                ) : null}
                    {this.props.showSMP === true && checkForSMP(currentOrder)}
                  </Col>
                  {this.props.showFMPLink && currentOrder.smp_stamp === "true" && currentOrder.is_smp_applied === true && !this.props.isOnHold && <Col>
                        <Button type="primary" icon='check-circle' size="small" className="textBold fontSize12 "
                            style={{marginTop: 1}} onClick={this.props.applyFmp}>Apply&nbsp;
                            {
                            showFMP()
                            }
                        </Button>
                    </Col>
                   }
                  {
                    currentOrder.smp_stamp === "true" && currentOrder.is_smp_applied === false && !this.props.isOnHold && (
                    <Col>
                        <Button
                          type="primary"
                          size="small"
                          className="textBold fontSize12 "
                          style={{ marginTop: 1 }}
                          icon='check-circle'
                          onClick={this.props.applySmp}
                        >
                          Apply SMP
                        </Button>
                    </Col>
                  )}


                </Row>
              ) : (
                <Row type ='flex' style={{ flexFlow: "unset" }} gutter={4} align="middle">
                  <Col>
                  <Fragment>
                  <Link className="clickableLink" onClick={this.getOrderDetails}>
                      {orderNumberContent}
                   </Link>
                    {/* <Link className='clickableLink' onClick={ this.getOrderDetails }>
                      {this.state.toShow !== "order"
                      ? ellipseText(this.props.displayedItem, 10, true, false, this.props.showPopupContainer)
                      : this.state.orderNo}
                    </Link> */}
                    {this.props.showSMP === true && checkForSMP(currentOrder)}
                  </Fragment>
                  </Col>
                  {((this.state.toShow !== "order" && !isEmpty(this.props.displayedItem)) || (this.state.toShow === "order" && !isEmpty(this.state.orderNo)))  && (<Col>
                      <Copyable text={this.state.toShow !== "order"
                          ? this.props.displayedItem
                          : this.state.orderNo.replace(/\([^()]*\)/g, "")}  //replace any content within parentheses
                      />
                  </Col>)}
                  </Row>
                  )
                  )}
          {this.state.detailsVisible && (
            <Drawer
              // title={
              //   <OrderTitle
              //     order={currentOrder}
              //     showEdit={this.props.showEdit}
              //     editClick={this.handleEdit}
              //   />
              // }
              placement="right"
              closable={false}
              // onClose={this.onClose}
              visible={this.state.detailsVisible}
              width="90%"
              className="orderDrawerScroll"
            >
              <div className="order_details">
                <DetailsConfig
                  order_id={currentOrder.id}
                  key={currentOrder.id}
                  editClick={this.handleEdit}
                  onClose={this.onClose}
                  showEdit={this.props.showEdit}
                  tab={tab}
                  updateParentState={this.updateParentState}
                  showCloneButton={this.props.showCloneButton}
                  handleOrderclonedStatus={ this.handleOrderclonedStatus }
                  setRefreshValue={this.setRefreshValue}
                />
              </div>
            </Drawer>
          )}
        </Spin>
      </Fragment>
    )
  }
}
CustomerOrderDetails.propTypes = {
  order: PropTypes.shape().isRequired,
  reqFrom: PropTypes.string,
  showCloneButton : PropTypes.bool,
  showPopupContainer : PropTypes.bool,
  forPopOverClose: PropTypes.func,
  charsToLimit:  PropTypes.string,
  eyeIconStyle: PropTypes.shape().isRequired
}
CustomerOrderDetails.defaultProps = {
  reqFrom: '',
  showCloneButton : true,
  showPopupContainer: false,
  eyeIconStyle: {},
  forPopOverClose: () => { },
  charsToLimit: 10,
}

export default withRouter(CustomerOrderDetails) 

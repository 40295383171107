import _ from "lodash";
import moment from "moment";
// import { secondsToHms } from "../../../../assets/javascripts/common";
import { fetchOptimizedStops } from "../api/Routes";
import { isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import { message } from "../common/UIComponents";
import { secondsToHms } from "./common";

function optimizeRoute(routeId, callback = null) {
  if (routeId) {
    message.loading({
      content: I18n.t("messages.optimization_process"),
      key: "preplan",
      duration: 2500,
    });
    fetchOptimizedStops(routeId).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false });
        const errorMessage =
          result.optimize_route.optimizer_readable_error_message;
        if (result.optimize_route.optimized === false) {
          message.error({
            content: errorMessage,
            key: "preplan",
            duration: 10,
          });
        } else {
          message.success({
            content: I18n.t("messages.preplan_success"),
            key: "preplan",
            duration: 5,
          });
        }
        if (callback) {
          callback();
        }
      } else {
        message.error({
          content: result.optimize_route.optimizer_readable_error_message,
          key: "preplan",
          duration: 5,
        });
        if (callback) {
          callback();
        }
      }
    });
  }
}

function getExecutionTime(route) {
  const stops = route.stops || [];
  const whStops = stops.filter(
    (stop) => stop.location && stop.location.l_type === "WH"
  );
  if (whStops.length > 1) {
    const firstStop = whStops[0];
    const lastStop = whStops[whStops.length - 1];
    if (
      !isEmpty(firstStop.estimated_departure_date_time_with_tz) &&
      !isEmpty(lastStop.estimated_arrival_date_time_with_tz)
    ) {
      const timeDiff = moment(
        moment(lastStop.estimated_arrival_date_time_with_tz)
      ).diff(firstStop.estimated_departure_date_time_with_tz, "seconds");
      const totalTravelTime = secondsToHms(timeDiff, true, false, false, true);
      return totalTravelTime;
    }
  }
  return "";
}

const checkErrors = (errors, errorString = 'Appointment') => {
  if(!isEmpty(errors)){
    const filteredErrors = errors.filter(err => err.includes(errorString));
    return filteredErrors;
  }
  return [];
}

export const formatTotalTime = (total_time , showNA = false) => {
  let totalTime  = ""
  if(!isEmpty(total_time)){
    totalTime = secondsToHms(total_time, true, false, false)
    totalTime = totalTime ? `${totalTime} Approx.` : 'NA'
    return totalTime;
  }
  return showNA ? 'NA' : '';
}

export const formatTotalDistance = (total_distance, showNA = false) => {
  let totalDistance  = ""
  if(!isEmpty(total_distance)){
    totalDistance = `${parseFloat(total_distance / 1609).toFixed(2)} mi.`;
    return totalDistance;
  }
  return showNA ? 'NA' : '';
}

const navigateToRoute = (route,  historyObj = {}) => {
  historyObj.push({
    pathname: '/routes',
    filter: 'ASSIGNED',
    routeId: route.nav_route_id,
    dateFilter: {
      fromDate: route.r_scheduled_start_datetime,
      toDate: route.r_scheduled_end_datetime,
    },
  })
}


export { optimizeRoute, getExecutionTime, checkErrors,navigateToRoute };

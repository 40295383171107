import { isEmpty } from 'lodash';
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from './ApiUtils';

export const LineHaulApi = {
  fetch: (isPagingEnabled, order_id, page = 1, perPage = '', token = '', payload = {}) => {
    let params = '';
    if (!isEmpty(token)) {
      params = `&order_no=${token}`;
    } else {
      const parameter = Object.keys(payload).map(
        (key) => `${key}=${!isEmpty(payload[ key ]) ? payload[ key ] : ''}`,
      );
      params = parameter ? `&${parameter.join('&')}` : '';
    }

    if (isPagingEnabled) {
      const url = ApiUrl(
        `v2/line_haul?${PageParams(
          page,
          perPage,
        )}${params}&operation_code=OREI&customer_order_id=${order_id}`,
      );
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
          linehauls: 'linehauls',
          pagination: 'meta.pagination',
          stats: 'stats',
        },
      });
    } else {
      const url = ApiUrl(
        `v2/line_haul?page=1&per_page=all${params}&operation_code=OREI&customer_order_id=${order_id}`,
      );
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
          linehauls: 'linehauls',
          pagination: 'meta.pagination',
          stats: 'stats',
        },
      });
    }
  },

  fetchOne:(id) => {
    const url = ApiUrl('v2/line_haul/get_order_line_haul?customer_order_id=' +id)
    return Call('get', url, {} , ParseGeneralResponse, {
      responseDataKeys : {linehaulstops: 'linehaul'}
    })
  },

  create: (payload) => {
    const url = ApiUrl('v2/line_haul')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { linehaul: 'linehaul' },
    })
  },

  update: (id, payload) => {
    const url = ApiUrl('v2/line_haul/' + id)
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { linehaul: 'linehaul' },
    })
  },

  get:(id) => {
    const url = ApiUrl('v2/line_haul/' + id)
    return Call('get', url, {} , ParseGeneralResponse,{
      responseDataKeys : {linehaul: ''}
    })
  },
  deleteLh: (id) => {
    const url = ApiUrl('v2/line_haul/' + id)
    return Call('delete', url, {} , ParseGeneralResponse, {
      responseDataKeys: { linehaul: '' },
    })
  },
  createShipment: (payload) => {
    const url = ApiUrl('v2/line_haul/create_shipment')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { shipment: 'shipment' , errors : 'errors'},
    })
  },
  createRoute: (payload) => {
    const url = ApiUrl('v2/line_haul_routes/create_route')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { route: 'route' , errors : 'errors'},
    })
  },
  deleteRoute: (id) => {
    const url = ApiUrl('v2/line_haul_routes/' + id)
    return Call('delete', url, {} , ParseGeneralResponse, {
      responseDataKeys: { route: '' },
    })
  },
  bulkDelete: (ids) => {
    const url = ApiUrl('v2/line_haul/bulk_delete_linehauls')
    return Call('delete', url, { linehaul_ids: ids }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  bulkSchedule: (payload) => {
    const url = ApiUrl('v2/line_haul/bulk_schedule_linehauls')
    return Call('put', url, payload , ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  changeStatus: (payload) => {
    const url = ApiUrl(`v2/line_haul_orders/update_bulk_linehaul_status`)
    return Call('put', url, payload , ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
};


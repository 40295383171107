import React from "react";
import { PureComponent } from "react";
import { Checkbox } from "antd";

class SelectImage extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.sentPods !== this.props.sentPods) {
			if(this.props.sentPods){
				this.setState({ checked: false });
			}
		}
	} 

	onCheckboxChange = () => {
		const { onImageSelect, file } = this.props;
		onImageSelect(file.id);
		this.setState({
			checked: !this.state.checked,
		});
	};
	render() {
		const { title, file } = this.props;
		return (
			<div>
				<Checkbox
					checked={this.state.checked}
					onChange={this.onCheckboxChange}
					disabled={file.isNew}
				>
					{title}
				</Checkbox>
			</div>
		);
	}
}
export default SelectImage;


import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

const EditIcon = (props) => {
  const { handleClick,color="blue",style } = props;
  return (
    <>
      <Icon
        type="edit"
        theme="twoTone"
        twoToneColor={color}
        style={{style}}
        onClick={handleClick}
      />
    </>
  );
};

EditIcon.propTypes = {
  handleClick: PropTypes.func,
};

EditIcon.defaultProps = {
  handleClick: () => {},
};
export default EditIcon;

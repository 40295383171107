import React from 'react';
import { Pagination as AntPagination, Icon } from '../../common/UIComponents';

const renderItem = (page, type, element) => {
  if (type === 'page') {
    return page;
  }
  return element;
};

function itemRender(current, type, originalElement) {
  if (type === 'jump-prev') {
    return <span><Icon type="step-backward" /></span>;
  } else if (type === 'jump-next') {
    return <span><Icon type="step-forward" /></span>;
  }
  return originalElement;
}

const GridPagination = (props) => (
  <AntPagination
    {...props}
    // current={options.current}
    // total={options.total}
    // pageSize={options.pageSize}
    itemRender={itemRender}
    renderItem={renderItem}

  />
);

export default GridPagination;
/* eslint-disable no-nested-ternary */
import _ from 'lodash';

const primary_text = '#384855';

export const fontFamily = {
  fontFamily: '"Courier New", Courier, monospace',
}
export const invoiceBox = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '30px',
  border: '1px solid #eee',
  boxShadow: '0 0 10px rgba(0, 0, 0, .15)',
  fontSize: '16px',
  lineHeight: '24px',
  // fontFamily: '\'Helvetica Neue\', \'Helvetica\', Helvetica, Arial, sans-serif',
  color: '#555',
};

export const invoiceHeading = {
  color: '#a4aaaf',
};

export const mainHeading = {
  letterSpacing: '0.8pt',
  color: primary_text,
};

export const shipperHeading = {
  color: primary_text,
};
export const invoiceItem = {
  borderBottom: '1px solid #eee',
  fontSize: '12px',
  padding: '1px 5px',
};

export const totalItems = {
  fontSize: '13px',
  padding: '1px 5px',
};

export const marginTop40 = {
  marginTop: 40,
};

export const marginBottom10 = {
  marginBottom: 10,
};

export const commentSection = {
  minHeight: '80px',
};
export const paddingLeft15 = {
  paddingLeft: '15px',
};
export const paddingLeft5 = {
  paddingLeft: '5px',
};

// .invoice-box, .invoice_details {
export const smallContent = {
  fontSize: '12px',
  lineHeight: '18px',
};

export const borderLine = {
  border: '1px solid #ddd',
};
export const heading = {
  background: '#eee',
  borderBottom: '1px solid #ddd',
  fontWeight: 'bold',
  padding: '3px 5px',
  fontSize: '13px',
};
/* .ant-list-item-meta-title{
        font-size: 13px;
    }

    .ant-list-item{
        padding: 5px !important;
    } */


export const invoiceDetailsTd = {
  padding: '4px !important',
  fontSize: '12px',
};
export const paddingRight115 = {
  paddingRight: '115px',
};
export const marginTop20 = {
  marginTop: '20px',
};
export const alignCenter = {
  textAlign: 'center',
};
export const textBold = {
  fontWeight: 'bold',
};
export const alignRight = {
  textAlign: 'right',
};
export const alignLeft = {
  textAlign: 'left',
};
export const marginTop10 = {
  marginTop: '10px',
};
export const paddingTop90 = {
  paddingTop: '90px',
};
export const fontSize11 = {
  fontSize: 11,
};
export const fontSize10 = {
  fontSize: 10,
};
export const padding5 = {
  padding: 5,
};
export const paddingTop20 = {
  paddingTop: 20,
};
export const borderCollapseInherit = {
  borderCollapse: 'inherit',
};
export const borderCollapseInitial = {
  borderCollapse: 'initial',
};
export const marginLeft10 = {
  marginLeft: 10,
};
export const floatLeft = {
  float: 'left',
};
export const maxHeight40 = {
  maxHeight: 40,
}
export const paddingTop5 = {
  paddingTop: 5,
}

import React, { Fragment } from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import {  isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { Icon, Popover, Tooltip, Row, Col } from "../../common/UIComponents";
import Leg from "../../components/Tasks/Legs";
import PrintGallery from "../common/PrintGallery";
import ItemAccessorial from "../orders/ItemAccessorial";
import { formatSurfaceArea, formatWeight } from "../../helpers/orders";
import Copyable from "../common/Copyable";
import AppConfig from "../../config/AppConfig";
import CopyableContent from "../common/CopyableContent";

class UnassignedErrors extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "order_numbers",
        title: `${I18n.t("order.order_no")} (s)`,
        dataIndex: "order_numbers",
        render: (data, record) => {
          const totalOrders = _.compact(data);
          return (
              totalOrders?.length ? totalOrders.join(', ') :''
          );
        },
      },
      {
        key: "reason",
        title: I18n.t("general.reason"),
        dataIndex: "reason",
        render: (data) => {
          return <Popover content={data}>
            <span className="textBold">{data}</span>
        </Popover>;
        },
        width: 260,
      }
    ];
  }
}

export default UnassignedErrors;

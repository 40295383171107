/* eslint-disable react/sort-comp */

import React, { Component, Fragment, useContext } from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
import moment from 'moment';
import { Col, Row, Spin,Card, Button, Icon, Collapse, Select, Table, Tooltip } from '../../common/UIComponents';
import { alertMessage, formatDate } from '../../common/Common';
import I18n from '../../common/I18n';
import { fetchLocations } from '../../api/LocationsApi';
import { ordersByPeriod } from '../../api/OrdersApi';
import CalendarView from './CalendarView';
import AppConfig from '../../config/AppConfig';
import { Drawer, Skeleton } from 'antd';
import OrderTitle from './OrderTitle';
import OrderDetails from '../Tasks/OrderDetails';
import WarehouseFilter from '../common/WarehouseFilter';
import { withRouter } from 'react-router';
import { WarehouseContext } from '../../context/WarehouseContext';
import { OrgContext } from '../../context/OrgContext';
import { renderAlertMessage } from '../../helpers/common';

const { Panel } = Collapse;
class CalendarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      orders: [],
      warehouses: [],
      filter: {
        warehouse: '',
        startDate: moment(moment().add(24, 'hours'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
        endDate: moment(moment().add(24 * 7, 'hours'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      },
      processedRecords: {
        firstRecord: {},
        lastRecord: {},
      },
      detailsVisible: false,
      orderObject: {},

    };
    this.columns = [];
  }

  componentDidMount () {
    this.setState({ warehouses: this.props.warehouseFilter.selectedWarehouses });
    // if (!WarehouseFilter.isWarehouseMultiSelect) {
        this.onHandleWareHouseChange('warehouse', this.props.warehouseFilter.selectedWarehouses)
    // }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.warehouseFilter.selectedWarehouses !== this.props.warehouseFilter.selectedWarehouses ) {
        this.onHandleWareHouseChange('warehouse', this.props.warehouseFilter.selectedWarehouses)
    }
  }


  getWarehouses = (cb) => {
    fetchLocations(AppConfig.warehouseCode, 1, null)
      .then((result) => {
        if (result.success) {
          this.setState({
            warehouses: result.locations.locations,
            inProgress: false,
          }, () => {
            if (this.props.warehouseFilter.warehouses.length > 0) {
              this.handleFilterPlaceHolderChange('warehouse', this.props.warehouseFilter.warehouses[0].id, () => {
                this.getOrders();
              });
            }
          });
        } else {
          this.getOrders();
        }
      });
  }


  getOrders = () => {
    const {
      filter,
    } = this.state;
    this.setState({ inProgress: true });
    ordersByPeriod(filter)
      .then((result) => {
        if (result.success) {
          const formattedData = {};
          const orders = result.orders ? [...result.orders] : [];
          orders.forEach((order) => {
            formattedData[order.schedule_date] = Object.assign({}, order);
          });

          this.setState({
            orders,
            formattedData,
            inProgress: false,
            processedRecords: {
              firstRecord: orders.length > 0 ? orders[0] : {},
              lastRecord: orders.length > 0 ? orders[orders.length - 1] : {},
            },
          }, () => {
            // this.setData();
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ orders: [], inProgress: false });
        }
      });
  }

  handleFilterPlaceHolderChange = (element, value, cb = () => { }) => {
    this.setState({
      filter: Object.assign({}, this.state.filter, { [element]: value }),
    }, cb);
  }

  previousWeek = () => {
    const { startDate } = this.state.filter;
    this.setState({
      filter: Object.assign({}, this.state.filter, {
        startDate: moment(moment(startDate).add(-24 * 7, 'hours'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
        endDate: moment(moment(startDate).add(-24, 'hours'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      }),
    }, () => {
      this.getOrders();
    });
  }

  nextWeek = () => {
    const { endDate } = this.state.filter;
    this.setState({
      filter: Object.assign({}, this.state.filter, {
        startDate: moment(moment(endDate).add(24, 'hours'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
        endDate: moment(moment(endDate).add(24 * 7, 'hours'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      }),
    }, () => {
      this.getOrders();
    });
  }

  onHandleWareHouseChange = (element, value) => {
    this.handleFilterPlaceHolderChange(element, value, () => {
      this.resetDateFields();
    });
  }

  resetDateFields = () => {
    this.setState({
      filter: Object.assign({}, this.state.filter, {
        startDate: moment(moment().add(24, 'hours'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
        endDate: moment(moment().add(24 * 7, 'hours'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      }),
    }, () => {
      this.getOrders();
    });
  }


  showDetails = (order) => {
    this.setState({ // result.orders.customer_order
      orderObject: Object.assign({}, order, { id: order.order_id, customer_order_number: order.order_number }),
    }, () => {
      this.setState({ detailsVisible: true });
    });
  }

  formateHeading = () => {
    const { processedRecords, orders } = this.state;
    const { firstRecord, lastRecord } = processedRecords;
    let heading = '';
    if (orders.length > 0) {
      const firstRecordYear = moment(firstRecord.schedule_date, 'YYYY-MM-DD').format('YYYY');
      const lastRecordYear = moment(lastRecord.schedule_date, 'YYYY-MM-DD').format('YYYY');
      if (firstRecord.schedule_month === lastRecord.schedule_month) {
        heading = `${firstRecord.schedule_month} ${firstRecordYear}`;
      } else if (lastRecordYear === firstRecordYear) {
        heading = `${firstRecord.schedule_month} - ${lastRecord.schedule_month} ${firstRecordYear}`;
      } else {
        heading = `${firstRecord.schedule_month} ${firstRecordYear} - ${lastRecord.schedule_month} ${lastRecordYear}`;
      }
    }
    return heading;
  }

  setData = () => {
    const { formattedData } = this.state;
    const orders = [formattedData];
    this.columns = [];
    orders.map((weekInfo, index) => {
      Object.entries(weekInfo).forEach(([dowDate, dowDateInfo], dowIndex) => {
        this.columns.push({
          key: dowDate,
          title: <Row>
            <Col className="alignCenter fontSize12">{dowDateInfo.schedule_dow}</Col>
            <Col className="alignCenter marginTop10">
              <Button
                shape="circle"
                type={dowDate === moment().format('YYYY-MM-DD') ? 'primary' : 'default'}
              >{dowDateInfo.schedule_day}
              </Button>
            </Col>
            <Col className="alignCenter marginTop10">
              <Button size="small" type="primary" className="appointment_info">
                {I18n.t('appointments.appointments')} {dowDateInfo.schedule_order_count}
              </Button>
            </Col>
          </Row>,
          dataIndex: dowDate,
          render: (value) => {
            value = value || [];
            return (
              <div >
                {value.account_orders && value.account_orders.length > 0 && value.account_orders.map(order => (
                  <Card key={order.account_id} onClick={() => this.props.getDetails(order.order_id)}>
                    {order.orders && order.orders.length > 0 && order.orders.map(orderData => (
                      <Row className="fontSize12">
                        <Col xs={24} className="textBold">
                          {orderData.order_number}
                        </Col>
                        <Col xs={24}>
                          {orderData.customer_name}
                        </Col>
                        {/* <Col xs={24}>
                      {order.city}
                    </Col> */}
                      </Row>
                    ))}
                  </Card>
                ))}
              </div>
            );
          },
          align: 'center',
          width: 150,


        });
      });
    });
  }

  onClose = () => {
    this.setState({
      detailsVisible: false,
    });
  };

  handleCloseCalender = (orderId, orderDate, warehouseId, orderStatus) => {
    const data = {
      orderId: orderId,
      orderDate: orderDate,
      warehouseId: warehouseId,
      orderStatus: orderStatus,
    }
    this.props.closeCalender(true, data);
  }

  render() {
    const { orders, formattedData } = this.state;
    const heading = this.formateHeading();

    return (
      <div className='content-outer'>
        <div className='content calendarView'>
          <Spin spinning={this.state.inProgress} delay={1000}>
            {this.state.orders.length > 0 ?
              <Fragment>
                <Row className="marginTopM20">
                  <Col xs={1} />
                  <Col xs={12} className="heading">
                    {/* <Icon type="left-circle" className="cursorPointer navigationIcons" onClick={this.previousWeek} />&nbsp;
                <Icon type="right-circle" className="cursorPointer navigationIcons" onClick={this.nextWeek} />
    &nbsp;&nbsp; */}
                    {heading}
                  </Col>
                  <Col xs={10} className="alignRight">

                    {/* <Select
                      value={this.state.filter.warehouse || ''}
                      showSearch
                      style={{ width: '60%', textTransform: 'capitalize' }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={e => this.onHandleWareHouseChange('warehouse', e)}
                    >
                      {this.state.warehouses.map(warehouseObj => (
                        <Select.Option key={warehouseObj.id} value={warehouseObj.id}>
                          {warehouseObj.name ? warehouseObj.name : ''}
                        </Select.Option>
                      ))}
                    </Select> */}
                    <WarehouseFilter isDisabled={true} 
                      style={{ width: '60%', textTransform: 'capitalize' }}
                    />
                  </Col>
                </Row>

                {this.state.orders.length > 0 &&
                  <Fragment>
                    <Row className="marginTop10">
                      <Col xs={1}>
                        <Tooltip title={I18n.t('order.previous_week')}>
                          <Icon type="left-circle" className="cursorPointer marginTop10 navigationIcons primaryText" onClick={this.previousWeek} />
                        </Tooltip>
                      </Col>
                      <Col xs={22}>
                        <Col xs={24} className="displayFlex">

                          {
                            this.state.orders.map(dowDateInfo => (
                              <Card style={{ width: '14.28%' }} key={`head_${dowDateInfo.schedule_date}`} className="padding5">
                                <Row>
                                  <Col xs={24}>
                                    <Col className="alignCenter fontSize12">{dowDateInfo.schedule_dow}</Col>
                                    <Col className="alignCenter marginTop10">
                                      <Button
                                        shape="circle"
                                        type={dowDateInfo.schedule_date === moment().format('YYYY-MM-DD') ? 'primary' : 'default'}
                                      >
                                        {dowDateInfo.schedule_day}
                                      </Button>
                                    </Col>
                                    <Col className="alignCenter marginTop10">
                                      <Button size="small" type="primary" className="appointment_info">
                                        {dowDateInfo.schedule_order_count}{dowDateInfo.schedule_order_count > 999 ? '+' : ''} {I18n.t('appointments.appointments')}
                                      </Button>
                                    </Col>
                                  </Col>
                                </Row>
                              </Card>
                            ))
                          }
                        </Col>

                        <Col xs={24} className="displayFlex overflowYScroll">
                          {this.state.orders.map(dowDateInfo => (
                            <Card style={{ width: '14.28%', overflowY: 'auto' }} key={`data_${dowDateInfo.schedule_date}`} className="padding5">
                              <Row>
                                <Col className="minHeight">
                                  {dowDateInfo.account_orders && dowDateInfo.account_orders.length > 0 && dowDateInfo.account_orders.map(order => {
                                    const orderStatus = order.orders && order.orders.length > 0 ? order.orders[0].status : '';
                                    return (
                                      <Card size="small" key={order.account_id} className="marginBottom10" bodyStyle={{ padding: 0 }} onClick={() => this.handleCloseCalender(order.account_code, dowDateInfo.schedule_date, this.state.filter.warehouse, orderStatus)}>  {/* onClick={() => this.showDetails(order)} */}
                                        <Row className="fontSize10">
                                          <Col xs={24} className="textBold">
                                            <Row className="alignLeft">
                                              <div className="backgroundPrimary padding5">
                                                <div className="floatLeft">
                                                  {order.account_code}
                                                </div>
                                                <div className="alignRight">
                                                  <Icon type="double-right" />
                                                </div>
                                              </div>
                                              <Col xs={24} className="padding5">
                                                <div className="borderRadius15 padding5 border">
                                                  <div className="floatLeft">
                                                    Pickup:
                                                  </div>
                                                  <div className="alignRight">
                                                    {order.pickup_order_count}
                                                  </div>
                                                </div>
                                                <div className="borderRadius15 padding5 marginTop5 border">
                                                  <div className="floatLeft">
                                                    Delivery:
                                                  </div>
                                                  <div className="alignRight">
                                                    {order.delivery_order_count}
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Col>
                                          {/* <Col xs={24}>
                                    {order.customer_name}
                                  </Col>
                                   <Col xs={24}>
                  {order.city}
                </Col> */}
                                        </Row>
                                      </Card>
                                    )
                                  })}

                                </Col>
                              </Row>
                            </Card>))
                          }
                        </Col>
                      </Col>
                      <Col xs={1} className="alignRight">
                        <Tooltip title={I18n.t('order.next_week')}>
                          <Icon type="right-circle" className="cursorPointer marginTop10 navigationIcons primaryText" onClick={this.nextWeek} />
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        {false && <CalendarView
                          data={[formattedData]}
                          orderInfo={orders}
                          pagination={{
                            position: 'none',
                          }}
                          rowKey="sno"
                          getDetails={id => this.showDetails(id)}
                          bordered
                        // scroll={{ y: 400 }}
                        />
                        }
                        {false && this.columns.length > 0 && <Table
                          bordered
                          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                          dataSource={[formattedData]}
                          columns={this.columns}
                          pagination={{
                            position: 'none',
                          }}

                        />
                        }
                      </Col>

                    </Row>
                  </Fragment>
                }
              </Fragment>
              :
              <Skeleton />
            }
            {this.state.detailsVisible &&
              <Drawer
                title={
                  <OrderTitle order={this.state.orderObject} />
                }
                placement="right"
                closable
                onClose={this.onClose}
                visible={this.state.detailsVisible}
                width="80%"
              >
                <OrderDetails
                  order_id={this.state.orderObject.id}
                  surveyDetails={this.props.handleSurveyDetails}
                  handleSearch={this.handleSearch}
                />
              </Drawer>
            }
          </Spin>

        </div>
      </div>
    );
  }
}

const CalendarContainerComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <CalendarContainer
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
}
);
export  default CalendarContainerComponent;
CalendarContainerComponent.defaultProps = {
  warehouses : [],
  getDetails: () => { },
  closeCalender: () => { },
  handleSurveyDetails: () => { },
}

CalendarContainerComponent.propTypes = {
  warehouses : PropTypes.array,
  getDetails: PropTypes.func,
  closeCalender: PropTypes.func,
  handleSurveyDetails: PropTypes.func,
}

import React, { createContext, useContext, useEffect, useState } from "react";
import { AppLoadingScreen } from "../components/common/LogisticsLoadingScreen";
import { fetchAccountUnits } from "../api/Account";
import { UserContext } from "./UserContext";

const AccountUnitsContext = createContext({});

const AccountUnitsContextProvider = ({ children }) => {
  const [isFetchingAccountUnits, setIsFetchingAccountUnits] = useState(false);
  const [accountUnits, setAccountUnits] = useState({});
  const { currentOrg } = useContext(UserContext);

  const fetchAccountUnitsData = () => {
    const orgId = currentOrg.id;

    setIsFetchingAccountUnits(true);
    fetchAccountUnits(orgId)
      .then((result) => {
        if (result.success) {
          setAccountUnits(result.accountsUnits);
        }
      })
      .finally(() => {
        setIsFetchingAccountUnits(false);
      });
  };

  useEffect(() => {
    fetchAccountUnitsData();
  }, []);

  const accountUnitsValues = {
    accountWeightUnits: accountUnits,
    setAccountUnits,
  }

  return (
    <AccountUnitsContext.Provider value={accountUnitsValues}>
      {isFetchingAccountUnits ? <AppLoadingScreen /> : children}
    </AccountUnitsContext.Provider>
  );
};

export { AccountUnitsContext, AccountUnitsContextProvider };

import React, { useState } from "react";
import I18n from "../../common/I18n";
import { Col, Row, Icon, Tooltip } from "../../common/UIComponents";
import FormIcon from "../common/FormIcon";
import { sendFormEmail } from "../../api/OrdersApi";
import { alertMessage } from "../../common/Common";
import { renderAlertMessage } from "../../helpers/common";
import SendEmailComponent from "../../common/SendEmailsDrawer";


const SurveyFormIcon = ({
  order,
  handleQuesFormDetails = () => {},
  surveyDetails = () => {},
  renderEmailModal = () => {},
  locationDetails = null,
}) => {
  const locationId = locationDetails ? locationDetails.id : null;
  const locationToConsider = locationDetails?.id ? locationDetails : order.cs_location
  const location_id = locationDetails ? locationDetails?.id : order?.cs_location?.id
  const [sendEmailLoading, setSendEmailLoading] = useState(false)

  const  handleSendingMails = (invoiceEmails, description, handleCloseModal) => {
    const customerOrderId = order.id;

    setSendEmailLoading(true)
    sendFormEmail(invoiceEmails, description, customerOrderId, location_id)
      .then((result) => {
        if (result.success) {
          alertMessage(result.message, 'success', 5);
          handleCloseModal();
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        setSendEmailLoading(false)
      });
  };  
  return (
    <Row justify="space-around" align="middle" className="marginTop5">
      <Col xs={23} md={12} className="leftBlock">
        {/* <Tooltip title={locationToConsider?.is_form_submitted ? "Form Details" : "Form is not submitted"} placement="top"> */}
          <span>
            <FormIcon
              handleClick={locationToConsider?.is_form_submitted ? () => handleQuesFormDetails(order.id, locationId) : undefined}
              style={{ fontSize: 30,  cursor: locationToConsider?.is_form_submitted ? 'pointer' : 'not-allowed' }}
            />
          </span>
        {/* </Tooltip> */}
        &nbsp;
        <span>{I18n.t("form.single")}</span>
        <span>
          <SendEmailComponent
            handleSendingMails={handleSendingMails}
            order={order}
            locationId={locationId}
            location={locationToConsider}
            sendEmailLoading={sendEmailLoading}
          />
        </span>

      </Col>
      <Col xs={23} md={12} className="leftBlock">
        <Tooltip title={I18n.t("order.survey_detail")} placement="top">
          <Icon
            type="file-done"
            onClick={() => surveyDetails(order.id, locationId)}
            style={{ fontSize: 30 }}
            className="primaryText"
          />
        </Tooltip>
        &nbsp;<span>{I18n.t("survey.single")}</span>
      </Col>
    </Row>
  );
};
export default SurveyFormIcon;

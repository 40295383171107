import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { Select } from "../../common/UIComponents";
import EndUserTitle from "../common/EndUserTitle";
import I18n from "../../common/I18n";

const { Option } = Select;
const OrderSortByOptions = ({
  onChange,
  value,
  className = "",
  size = "default",
  styleObj = {},
  screenFrom = "",
  optionsForSorting = [],
}) => {
  return (
    <Fragment>

      {!_.isEmpty(optionsForSorting) ?
        <Select
          value={value == "none" ? "Last Updated" : value}
          onChange={(e) =>
            onChange("sortBy", {
              sortKey: e,
              sortKeyType: e === "none" ? "descend" : "ascend",
            })
          }
          className={className}
          size={size}
          style={styleObj}
          filterOption={(input, option) => {
            const optionText = option.props.children;
            if (typeof optionText === "string") {
              return optionText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            return false;
          }}
          dropdownMatchSelectWidth={true}
          showSearch
        >
          {optionsForSorting.map((column) => {
            return (
              <Option key={column.field} value={column.field}>
                {I18n.t(`order.filters.sortBy.${column.field}`)}
              </Option>
            )
          })}
        </Select>
        :
        <Select
        value={value || "none"}
        onChange={(e) =>
          onChange("sortBy", {
            sortKey: e,
            sortKeyType: e === "none" ? "descend" : "ascend",
          })
        }
        className={className}
        size={size}
        style={styleObj}
        filterOption={(input, option) => {
          const optionText = option.props.children;
          if (typeof optionText === "string") {
            return optionText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        dropdownMatchSelectWidth={true}
        showSearch
      >
        <Option value="account_name">
          {I18n.t("order.filters.sortBy.account_name")}
        </Option>
        <Option value="account_code">
          {I18n.t("order.filters.sortBy.account_code")}
        </Option>
        <Option value="appointment">
          {I18n.t("general.scheduled_appointment")}
        </Option>
        <Option value="consignee">
          <EndUserTitle />
        </Option>
        <Option value="city">{I18n.t("order.filters.sortBy.city")}</Option> 
        {screenFrom === "dispatch" && <Option value="cities">{I18n.t("order.filters.sortBy.cities")}</Option>}
        <Option value="customer_order_number">
          {I18n.t("order.filters.sortBy.customer_order_no")}
        </Option>
        <Option value="hawb">{I18n.t("order.filters.sortBy.hawb")}</Option>
        <Option value="mawb">{I18n.t("order.filters.sortBy.mawb")}</Option>
        <Option value="reference_1">
          {I18n.t("order.filters.sortBy.reference_1")}
        </Option>
        <Option value="reference_2">
          {I18n.t("order.filters.sortBy.reference_2")}
        </Option>
        <Option value="status">{I18n.t("order.filters.sortBy.status")}</Option>
        <Option value="none">{I18n.t("order.filters.sortBy.none")}</Option>
        <Option value="state">{I18n.t("order.filters.sortBy.state")}</Option>
        <Option value="weight">{I18n.t("general.weight")}</Option>
        <Option value="items_count">{I18n.t("order.items")}</Option>
        <Option value="los">{I18n.t("los.label")}</Option>
        <Option value="created_at">{I18n.t("order.age")}</Option>
        <Option value="zipcode">{I18n.t("order.filters.sortBy.zipcode")}</Option>
        <Option value="zone_name">{I18n.t("order.filters.sortBy.zone_name")}</Option>
        <Option value="type_of_order">{I18n.t("order.filters.sortBy.type_of_order")}</Option>
        <Option value="priority">{I18n.t("order.filters.sortBy.priority")}</Option>
        <Option value="deliver_by">{I18n.t("order.filters.sortBy.deliver_by")}</Option>
        </Select>      
      }
    </Fragment>
  );
};

OrderSortByOptions.propTypes = {
  filter: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  handleReceiveToConfirm: PropTypes.func.isRequired,
  multipleOrderDeletion: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  resetOrders: PropTypes.func.isRequired,
  handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  handleStatusResult: PropTypes.func.isRequired,
  enableLoading: PropTypes.func.isRequired,
  handleEditOrder: PropTypes.func.isRequired,
};

OrderSortByOptions.defaultProps = {
  value: "none",
  onChange: () => {},
  handleEditOrder: () => {},
};

export default OrderSortByOptions;

/* eslint-disable camelcase */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import AddressInfo from '../common/AddressInfo'
import { Col, Row, Icon } from '../../common/UIComponents'
import { doFormate, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import Profile from '../common/Profile'
import imagePlaceholder from '../../../assets/images/logo_placeholder.png'
import { formatUSPhoneNumber } from '../../helpers/common'

const ReleaseToDetails = ({
  details,
  showAddress = true,
  showEmail = true,
}) => {
  const contact_number = doFormate(details?.drop_location?.phone_number_one ? details?.drop_location?.phone_number_one : details.customer_phone_one || details.contact_no)
  const company_name = doFormate(details.company_name)
  const pickup_person = doFormate(details.pickup_person)
  const contact_email = doFormate(details?.drop_location?.email ? details?.drop_location?.email : details.customer_email || details.email)
  const deliveryDetails = details?.delivery_location
    ? details.delivery_location
    : null
  const deliveryAddress = deliveryDetails?.l_address ? deliveryDetails.l_address : null;
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={2} xs={2} md={2} lg={1}>
            <div className="textCenter">
              <Icon type="home" />
            </div>
          </Col>
          <Col sm={6} xs={6} md={6} lg={6}>
            <div className="info-text textBold">
              &nbsp;{I18n.t('general.company_name')}
            </div>
          </Col>
          <Col xs={1}>:</Col>
          <Col sm={10} xs={14} md={14} lg={14}>
            <div className="info-text">
              <span>{company_name}</span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={2} xs={2} md={2} lg={1}>
            <div className="textCenter">
              <Icon type="user" />
            </div>
          </Col>
          <Col sm={6} xs={6} md={6} lg={6}>
            <div className="info-text textBold">
              &nbsp;{I18n.t('releases.pickup_person')}
            </div>
          </Col>
          <Col xs={1}>:</Col>
          <Col sm={10} xs={8} md={12} lg={13}>
            <div className="info-text">
              <span>{pickup_person}</span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={2} xs={2} md={2} lg={1}>
            <div className="textCenter">
              <Icon type="phone" />
            </div>
          </Col>
          <Col sm={6} xs={6} md={6} lg={6}>
            <div className="info-text textBold">&nbsp;Contact No</div>
          </Col>
          <Col xs={1}>:</Col>
          <Col sm={10} xs={8} md={12} lg={13}>
            <div className="info-text">
              <span>
                {contact_number !== 'NA'
                  ? formatUSPhoneNumber(contact_number)
                  : contact_number}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
      {showEmail !== false && (
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row>
            <Col sm={2} xs={2} md={2} lg={1}>
              <div className="textCenter">
                <Icon type="mail" />
              </div>
            </Col>
            <Col sm={6} xs={6} md={6} lg={6}>
              <div className="info-text textBold">
                &nbsp;{I18n.t('general.email')}
              </div>
            </Col>
            <Col xs={1}>:</Col>
            <Col sm={10} xs={8} md={12} lg={13}>
              <div className="info-text wordWrap">
                <span>{contact_email}</span>
              </div>
            </Col>
          </Row>
        </Col>
      )}
      {showAddress !== false && (
        <Col xs={24} sm={24} md={24} lg={24} className="marginTop20 fontSize12">
          <Fragment>
          <h3 style={{ fontWeight: 'initial !important' }} className="fontSize14">
            {I18n.t('releases.delivery_details')}
          </h3>
          <hr style={{ width:120 }} />
           {
            details.delivery_location_id && deliveryDetails ? (
            <Fragment>
             <Profile
              address={deliveryAddress}
              profile={(({
                name,
                phone_number_one,
                phone_number_two,
                email,
                company_name,
              }) => ({
                image: imagePlaceholder,
                full_name: !isEmpty(name) ? name : '',
                mobile_number:
                  !isEmpty(phone_number_one) && !isEmpty(phone_number_one)
                    ? `${phone_number_one} ${
                        !isEmpty(phone_number_two) ? `,${phone_number_two}` : ''
                      }`
                    : '',
                email: doFormate(email),
                company_name: !isEmpty(company_name) ? company_name : '',
              }))(deliveryDetails)}
              displayType="line"
              showImage={false}
              withLables={false}
              inBilling={true}
              showAddress={false}
            />
             {
              deliveryAddress ? ( <div className="paddingLeft5">
              <AddressInfo address={deliveryAddress} showIcon={true} />
            </div>
              ) : ''
             }
            </Fragment>)
            :
            "Delivery Details not available"
           }
          </Fragment>
        </Col>
      )}
    </Row>
  )
}

export default ReleaseToDetails

ReleaseToDetails.propTypes = {
  details: PropTypes.shape().isRequired,
}

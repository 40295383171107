/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import BaseList from '../BaseList';
import { Card, Row, Col, Button, } from '../../common/UIComponents';
import I18n from '../../common/I18n';

class CalendarView extends BaseList {
  constructor(props) {
    super(props);
    const orders = props.data;
    const ordersInfo = props.ordersInfo;
    this.state = {
      orders,
    };
    this.columns = [];
    this.setData();
  }

  // `${moment(dowDate, 'YYYY-MM-DD HH:mm:ss').format('ddd')}, ${moment(dowDate, 'YYYY-MM-DD HH:mm:ss').format('DD MMM')}`,
  setData = () => {
    this.state.orders.map((weekInfo, index) => {
      Object.entries(weekInfo).forEach(([dowDate, dowDateInfo], dowIndex) => {
        this.columns.push({
          key: dowDate,
          title: <Row>
            <Col className="alignCenter fontSize12">{dowDateInfo.schedule_dow}</Col>
            <Col className="alignCenter marginTop10">
              <Button
                shape="circle"
                type={dowDate === moment().format('YYYY-MM-DD') ? 'primary' : 'default'}
              >{dowDateInfo.schedule_day}
              </Button>
            </Col>
            <Col className="alignCenter marginTop10">
              <Button size="small" type="primary" className="appointment_info">
                {I18n.t('appointments.appointments')} {dowDateInfo.schedule_order_count}
              </Button>
            </Col>
          </Row>,
          dataIndex: dowDate,
          render: (value) => {
            value = value || [];
            return (
              <div >
                {value.schedule_orders && value.schedule_orders.length > 0 && value.schedule_orders.map(order => (
                  <Card key={dowDateInfo.schedule_day} onClick={() => this.props.getDetails(order.order_id)}>
                    <Row className="fontSize12">
                      <Col xs={24} className="textBold">
                        {order.order_number}
                      </Col>
                      <Col xs={24}>
                        {order.customer_name}
                      </Col>
                      {/* <Col xs={24}>
                      {order.city}
                    </Col> */}
                    </Row>
                  </Card>
                ))}
              </div>
            );
          },
          align: 'center',
          width: 150,


        });
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ orders: nextProps.data }, () => {
      this.columns = [];
      this.setData();
    });
  }
}


export default CalendarView;


import React, { useContext } from "react";
import PropTypes from "prop-types";
import { OrgContext } from '../../context/OrgContext';

const DecimalValue = (props) => {
  const { value,color="blue",style,decimalPoints } = props;
  const { orgSettings : organizationSettings  }= useContext(OrgContext)
  
//   const formattedValue = (Math.round(value * 100) / 100).toFixed(2);
  return (
    <>
      <div style={{style}}>
        {value}, {decimalPoints}
      </div>
    </>
  );
};

DecimalValue.propTypes = {
    value: PropTypes.number.isRequired
};

DecimalValue.defaultProps = {
  value : 2
};
export default DecimalValue;

import React  from 'react'
import BaseList from '../BaseList'
import I18n from "../../common/I18n";
import { formatSurfaceArea, formatWeight } from '../../helpers/orders'

class ZoneCapacityErrorDetailInfo extends BaseList {
  constructor(props) {
    super(props)
    this.columns = [
      {
        key: 'sno',
        title: '#',
        dataIndex: 'zone_name',
        render: (data, record, index) => <div>{index+1}</div>,
      },
      {
        key: 'zones',
        title: I18n.t('menu.zones'),
        dataIndex: 'zone_name',
        render: (data, record) => <div>{data ? `${data}` : ''}</div>,
      },
      {
        key: 'reason',
        title: I18n.t('preplan.capacity.overloaded_by'),
        dataIndex: 'zone_name',
        render: (data, record) => {
          const reasons = [];
          if(record.total_wt_overload || record.max_wt_overload){
            reasons.push(I18n.t('order.weight'));
          }
          if(record.total_vol_overload || record.max_dim_overload){
            reasons.push(I18n.t('order.surface_area'));
          }
          return (<div>{reasons.length ? reasons.join(' & ') : ''}</div>);
        }
      },
      // {
      //   key: 'overloaded_weight',
      //   title: I18n.t('route_optimization.weight_overloaded'),
      //   dataIndex: 'orders_tot_wt',
      //   render: (record) => {
      //     const isOverLoaded = record.total_wt_overload;
      //     const overloadedWeight = isOverLoaded ? record.orders_tot_wt-record.trucks_tot_wt : 0;
      //     return (<div>
      //       {isOverLoaded ? (
      //         <Fragment>
      //           {formatWeight(overloadedWeight)}
      //           {record?.max_wt_orders && record.max_wt_orders.length > 0 && (
      //             <p>
      //               {I18n.t('menu.orders')}:&nbsp;
      //               <span className="textRed">
      //                 {record.max_wt_orders.join(', ')}
      //               </span>
      //             </p>
      //           )}
      //         </Fragment>
      //       ) : (
      //         ''
      //       )}
      //     </div>);
      //   },
      // },
      // {
      //   key: 'overloaded_area',
      //   title: I18n.t('route_optimization.dim_overloaded'),
      //   dataIndex: 'orders_tot_vol',
      //   render: (data, record) => {
      //     const isOverLoaded = record.total_vol_overload;
      //     const overloadedVolume = isOverLoaded ? record.orders_tot_vol-record.trucks_tot_vol : 0;
      //     return(
      //     <div>
      //       {isOverLoaded ? (
      //         <Fragment>
      //           {formatSurfaceArea(overloadedVolume)}
      //           {record?.max_dim_orders && record.max_dim_orders.length > 0 && (
      //             <p>
      //               {I18n.t('menu.orders')}:&nbsp;
      //               <span className="textRed">
      //                 {record.max_dim_orders.join(', ')}
      //               </span>
      //             </p>
      //           )}
      //         </Fragment>
      //       ) : (
      //         ''
      //       )}
      //     </div>)
      //   },
      // },
    ]
  }
}

export default ZoneCapacityErrorDetailInfo

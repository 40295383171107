import React, { Fragment, useEffect, useState } from 'react'
import _, { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Col, Row, Icon, Spin } from 'antd'
import I18n from '../../common/I18n'
import { doFormate } from '../../common/Common'
import moment from 'moment'
import imagePlaceholder from '../../../assets/images/logo_placeholder.png'
import Profile from '../common/Profile'
import AddressInfo from '../common/AddressInfo'
import DisplayTime from '../common/DisplayTime'
import ReleaseToDetails from './ReleaseToDetails'
import DispatcherNotes from '../orders/DispatcherNotes'
import { dispatcherNotesApi } from '../../api/OrdersApi'
import { PicturesApi } from '../../api/PicturesApi';
import OrderPictures from '../common/OrderPictures';

function ReleaseDetails(props) {
  const { currentRelease, timeZoneId , order_id,account_id,org_id } = props

  const [driverNotes, setDriverNotes] = useState([])
  const [attachments, setAttachments] = useState([])
  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState(false)
  const {
    delivery_location,
    scheduled_release_time,
    actual_release_time,
    driver_comments,
    delivery_location_id,
    released_by,
  } = currentRelease
  const deliveryAddress = delivery_location?.l_address
    ? delivery_location.l_address
    : {}

  const getOriverNotes = () => {
    dispatcherNotesApi.fetch(currentRelease.id).then((response) => {
      if (response.success) {
        setDriverNotes(response.dispatch_notes)
      }
    })
  }

  useEffect(() => {
    if (currentRelease.id) {
      getOriverNotes()
      getAttachments()
    }
  }, [])

  const renderDispatcherNotes = () => {
    const { organization_id } = currentRelease
    return (
      <DispatcherNotes
        organization_id={organization_id}
        currentSourceRecord={currentRelease}
        customer_order_notes={driverNotes}
        key={'customer_order_notes'}
        refer_type="release"
        apiEndpoint={dispatcherNotesApi}
        noteTitle="Driver Notes"
      />
    )
  }

  const renderDetails = () => (
    <>
      <Row>
        <Col>
          <table
            width="100%"
            className="marginTop10 marginBottom10"
            cellSpacing={10}
          >
            <thead>
              <tr>
                <th style={{ width: '33.33%' }} className="label_heading">
                  Release To Details
                  <hr style={{ width: 125 }} />
                </th>
                <th style={{ width: '33.33%' }} className="label_heading">
                  {I18n.t('releases.delivery_details')}

                  <hr style={{ width: 110 }} />
                </th>
                <th style={{ width: '33.33%' }} className="label_heading">
                  Estimate Details
                  <hr style={{ width: 113 }} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fontSize12" style={{ verticalAlign: 'top' }}>
                  <ReleaseToDetails
                    details={currentRelease}
                    showAddress={false}
                  />
                </td>
                <td className="fontSize12" style={{ verticalAlign: 'top' }}>
                  {delivery_location_id ? (
                    <Fragment>
                      <Profile
                        address={deliveryAddress}
                        profile={(({
                          name,
                          phone_number_one,
                          phone_number_two,
                          email,
                          company_name,
                        }) => ({
                          image: imagePlaceholder,
                          full_name: !isEmpty(name) ? name : '',
                          mobile_number:
                            !isEmpty(phone_number_one) &&
                            !isEmpty(phone_number_one)
                              ? `${phone_number_one} ${
                                  !isEmpty(phone_number_two)
                                    ? `,${phone_number_two}`
                                    : ''
                                }`
                              : '',
                          email: doFormate(email),
                          company_name: !isEmpty(company_name)
                            ? company_name
                            : '',
                        }))(delivery_location)}
                        displayType="line"
                        showImage={false}
                        withLables={false}
                        inBilling={true}
                        showAddress={false}
                      />
                      <div className="paddingLeft5">
                        <AddressInfo
                          address={deliveryAddress}
                          showIcon={true}
                        />
                      </div>
                    </Fragment>
                  ) : (
                    'Not Available'
                  )}
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <Row className="fontSize15">
                    <Col xs={8}>
                      <Icon type="clock-circle" />
                      &nbsp; {I18n.t('releases.scheduled_release_time')}:
                    </Col>
                    <Col xs={16} className="textBold">
                      {!isEmpty(scheduled_release_time) &&
                      moment(scheduled_release_time).isValid() ? (
                        <DisplayTime
                          isUTC={true}
                          displayWithDate={true}
                          dateTimeString={scheduled_release_time}
                          key={'scheduled_release_time'}
                          timeZoneId={timeZoneId}
                        />
                      ) : (
                        'NA'
                      )}
                    </Col>
                  </Row>
                  <Row className="fontSize15">
                    <Col xs={8}>
                      <Icon type="clock-circle" />
                      &nbsp; {I18n.t('releases.actual_release_time')}:
                    </Col>
                    <Col xs={16} className="textBold">
                      {!isEmpty(actual_release_time) &&
                      moment(actual_release_time).isValid() ? (
                        <DisplayTime
                          isUTC={true}
                          displayWithDate={true}
                          dateTimeString={actual_release_time}
                          key={'scheduled_release_time'}
                          timeZoneId={timeZoneId}
                        />
                      ) : (
                        'NA'
                      )}
                    </Col>
                  </Row>
                  <Row className="fontSize15">
                    <Col xs={8}>
                      <Icon type="user" />
                      &nbsp; {I18n.t('releases.released_by')}:
                    </Col>
                    <Col xs={16} className="textBold">
                      {!isEmpty(released_by) ?
                         released_by
                      : (
                        'NA'
                      )}
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col>{renderDispatcherNotes()}</Col>
      </Row>
    </>
  )

  const getAttachments = () => {
    setIsAttachmentsLoading(true)
    PicturesApi.fetch("release", currentRelease.id).then((result) => {
      setAttachments(result.pictures)
    }).catch((error) => {
      setAttachments([])
    }).finally(() => {
      setIsAttachmentsLoading(false)
    })
  }

  const handleSuccessFullUpload = (attachments) => {
    setAttachments(attachments)
  }
  return (
    <div className="releaseDetails">
      { renderDetails() }
      { !isEmpty(currentRelease.id) && (
        <Spin spinning={isAttachmentsLoading}>
        <Row className="marginTop20">
          <Col xs={ 12 }>{
            attachments
              ?
              <OrderPictures
                order_id={ order_id }
                takeMultipleSigns={ false }
                showType="imageClick"
                refer="release"
                release_id={ currentRelease.id }
                attachments={ attachments }
                account_id={ account_id }
                org_id={ org_id }
                getAttachments={ getAttachments }
                handleSuccessFullUpload={ handleSuccessFullUpload }
              /> : null
          }</Col>
        </Row>
        </Spin>
      ) }
    </div>
  ) 
}

export default ReleaseDetails

ReleaseDetails.propTypes = {
  currentRelease: PropTypes.shape(),
}

ReleaseDetails.defaultProps = {
  currentRelease: null,
}

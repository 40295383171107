import { isEmpty } from 'lodash'
import userStore from '../stores/UserStore'
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from './ApiUtils'

//  store the functions related to account and org level instructions
export const RecoveriesApis = {
  fetch: (isPagingEnabled, order_id, page = 1, perPage = '', token = '', payload = {}) => {
    const organization_id = userStore.getStateValue('selectedOrg')
    let params = ''
    if (!isEmpty(token)) {
      params = `&order_no=${token}`
    } else {
      const parameter = Object.keys(payload).map(
        (key) => `${key}=${!isEmpty(payload[key]) ? payload[key] : ''}`,
      )
      params = parameter ? `&${parameter.join('&')}` : ''
    }

    if (isPagingEnabled) {
      const url = ApiUrl(
        `v2/recoveries?${PageParams(
          page,
          perPage,
        )}${params}&operation_code=ORI&order_id=${order_id}&organization_id=${organization_id}`,
      )
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
          recoveries: 'recoveries',
          pagination: 'meta.pagination',
          stats: 'stats',
        },
      })
    } else {
      const url = ApiUrl(
        `v2/recoveries?page=1&per_page=all${params}&operation_code=ORI&order_id=${order_id}&organization_id=${organization_id}`,
      )
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
          recoveries: 'recoveries',
          pagination: 'meta.pagination',
          stats: 'stats'
        },
      })
    }
  },
  fetchone: (id) => {
    const url = ApiUrl(`v2/recoveries/${id}?operation_code=ORD`)
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  update: (id, payload) => {
    payload.operation_code = 'ORU'
    const url = ApiUrl('v2/recoveries/' + id)
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { quote: 'quote' },
    })
  },
  // changeStatus: (payload) => {
  //   payload.operation_code = 'ORCS'
  //   const url = ApiUrl('v2/recoveries/change_status')
  //   return Call('put', url, payload, ParseGeneralResponse, {
  //     responseDataKeys: { data: '' },
  //   })
  // },
  create: (payload) => {
    payload.operation_code = 'ORC'
    const url = ApiUrl('v2/recoveries')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { quote: 'quote' },
    })
  },
  delete: (id) => {
    const url = ApiUrl('v2/recoveries/' + id)
    return Call('delete', url, { operation_code: 'ORDEL' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  fetchRecoveryLocation: (wh_id, recovery_date) => {
    const url = ApiUrl(`v2/recoveries/get_recovery_locations?operation_code=ORL&wh_id=${wh_id}&recovery_dead_line=${recovery_date}`)
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: { locations: 'recoveries' },
    })
  },
  changeStatus: (payload) => {
    payload.operation_code= 'ORCS';
    const url = ApiUrl(`v2/recoveries/update_bulk_recovery_status`)
    return Call('put', url, payload , ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  createRoute: (payload) => {
    payload.operation_code = 'ORRC'
    const url = ApiUrl('v2/recovery_routes/')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { route: '' },
    })
  },
  addStopsToRoute: (payload) => {
    payload.operation_code = 'ORRAS'
    const url = ApiUrl('v2/recovery_routes/add_recovery_stops')
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { route: '' },
    })
  },
  deleteRecoveryRoute: (id) => {
     const url = ApiUrl('v2/recovery_routes/' + id)
    return Call('delete', url, { operation_code: 'ORRD' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  deleteStopFromDispatch: (data) => {
    data.operation_code = 'DDO';
    const url = ApiUrl("v2/recovery_routes/delete_r_stop");
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { nav_route: "" },
    });
  },
  uploadStopPod(data) {
    const formData = setObjectToFormData(data);
    data.operation_code = 'ORRUSP'
    const url = ApiUrl("v2/recovery_routes/upload_r_stop_pictures");
    return Call(
      "PUT",
      url,
      formData,
      ParseGeneralResponse,
      {
        responseDataKeys: { orders: "" },
      },
      false
    );
  },
  deleteStopAttachment(data) {
    data.operation_code = 'ORRDSP'
    const url = ApiUrl("v2/recovery_routes/delete_r_stop_pictures");
    return Call("delete", url, data, ParseGeneralResponse);
  }
  ,changeRecoveryTime(data) {
    data.operation_code = 'ORCRT'
    const url = ApiUrl("v2/recoveries/update_bulk_recovery_eta");
    return Call(
      "PUT",
      url,
      data,
      ParseGeneralResponse,
      {
        responseDataKeys: { recoveries: "" },
      },
      false
    );
  },
  driverSelection(dateString) {
    const url = ApiUrl(`v1/users/allocated_drivers_list?date=${dateString}`);
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
}

import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

const DeleteIcon = (props) => {
  const { handleClick,color = "Red", styleObj = {}, className = '' } = props;
  return (
      <Icon
        type="delete"
        theme="twoTone"
        twoToneColor={color}
        style={{cursor: "pointer", ...styleObj }}
        onClick={handleClick}
        className={`${className}`}
      />
  );
};

DeleteIcon.propTypes = {
  handleClick: PropTypes.func,
  className: PropTypes.string,
};

DeleteIcon.defaultProps = {
  handleClick: () => {},
  className: '',
};
export default DeleteIcon;

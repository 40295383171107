import React, { Fragment, useEffect, useState } from 'react'
import _, { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import {
  Button,
  Spin,
  Col,
  Row,
  Icon,
  Popconfirm,
  Timeline,
  Card,
  Alert
} from 'antd'
import I18n from '../../common/I18n'
import { alertMessage } from '../../common/Common'
import moment from 'moment'
import { RecoveriesApis } from '../../api/RecoveriesApi'
import { RecoveryForm } from './RecoveryForm'
import RecoveryDetails from './RecoveryDetails'
import DisplayTime from '../common/DisplayTime'
import { momentTime } from '../../helpers/date_functions'
import { getSatusColor } from '../../helpers/recovery'
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common'

const defaultRecovery = {
  pickup_location: {},
  drop_location: {},
  recovery_dead_line: null,
  recovery_eta: null,
}

const recoveryKeys = {
  pickup_location: 'Pickup Location',
  drop_location: 'Delivery Location',
}

function RecoveryManage(props) {
  const {
    order_id,
    clickedSave,
    actionType = 'view',
    orderWH,
    timeZoneId,
  } = props

  const [errors, setErrors] = useState([])
  const [isNew, setIsNew] = useState(false)
  const [recoveries, setRecoveries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [isFetchingRecoveries, setIsFetchingRecoveries] = useState(false)

  useEffect(() => {
    getOrderRecoveries()
  }, [])

  const renderRecoveryAlert = (message) =>{
    return (
      <Fragment>
        <Alert message={message} type="info" showIcon className="alignLeft"/>
      </Fragment>
    );
  }

  const getOrderRecoveries = () => {
    try {
      setIsFetchingRecoveries(true)
      RecoveriesApis.fetch(false, order_id).then((result) => {
        if (result.success) {
          const recoveriesList = result.recoveries || []
          const recoveryObjects = recoveriesList.map((recovery) => {
            return {
              ...recovery,
              // recovery_date: !isEmpty(recovery.recovery_date) ? moment.utc(recovery.recovery_date) : null,
              recovery_eta: !isEmpty(recovery.recovery_eta)
                ? (recovery.pickup_timeZoneId ? 
                   momentTime('', recovery.recovery_eta, recovery.pickup_timeZoneId) : 
                   !isEmpty(orderWH) && orderWH.timeZoneId
                  ? momentTime('', recovery.recovery_eta, orderWH.timeZoneId)
                  : moment(recovery.recovery_eta))
                : null,
              recovery_dead_line: !isEmpty(recovery.recovery_dead_line)
                ? moment.utc(recovery.recovery_dead_line)
                : null,
              isNew: false,
            }
          })
          setRecoveries(recoveryObjects)
          setIsFetchingRecoveries(false)
        } else {
          renderAlertMessage(result.errors)
        }
      })
    } catch (error) {
      alertMessage(error.message, 'error', 10)
      setIsFetchingRecoveries(false)
    }
  }

  const deleteClick = (id) => {
    setIsLoading(false)
    if (!isEmpty(id)) {
      try {
        RecoveriesApis.delete(id).then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.deleted'), 'success', 10)
            getOrderRecoveries()
          } else {
            renderAlertMessage(result.errors)
          }
        })
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    } else {
      setIsNew(false)
    }
  }

  const refreshOrderRecoveries = () => {
    setIsNew(false)
    getOrderRecoveries()
  }
  if (isFetchingRecoveries) {
    return <Spin />;
  } 

  return (
      <div className="padding10">
        {actionType !== 'view' ? (
          <Row
            className="quote-order-consignee-details"
            type="flex"
            gutter={32}
          >
            <Col xs={24}>
              
                <Fragment>
                  {recoveries.map((recovery, index) => {
                    const rec = {
                      ...recovery,
                      pickup_location:
                        recovery.pickup_location_id &&
                        !isEmpty(recovery.pickup_location)
                          ? recovery.pickup_location
                          : {},
                      drop_location:
                        recovery.drop_location_id &&
                        !isEmpty(recovery.drop_location)
                          ? recovery.drop_location
                          : {},
                      driver_notes: recovery.driver_notes || [],
                    }
                    return (
                      <div className="marginTop10">
                        <RecoveryForm
                          currentRecovery={rec}
                          isNew={false}
                          getOrderRecoveries={refreshOrderRecoveries}
                          recoveryParams={{ ...recoveryKeys }}
                          request_from={'recovery'}
                          order_id={order_id}
                          deleteClick={deleteClick}
                        />
                      </div>
                    )
                  })}
                </Fragment>
            
              
              {!isNew ? (
                <div className="alignRight marginTop10">
                    {
                      recoveries.length ===0 && checkServiceExistance('ORC') &&
                      <Button type="primary" onClick={() => setIsNew(true)} className="marginBottom10">
                       {I18n.t("recoveries.add")}
                      </Button>
                    }
                   {recoveries.length ===0 && (
                      renderRecoveryAlert(I18n.t("recoveries.add_alert"))
                    )}
                </div>
              ) : (
                <div className="marginTop10">
                  { checkServiceExistance('ORC') && <RecoveryForm
                    currentRecovery={{
                      ...defaultRecovery,
                      pickup_location:
                        recoveries.length > 0 &&
                        recoveries[recoveries.length - 1].drop_location
                          ? recoveries[recoveries.length - 1].drop_location
                          : {},
                      drop_location:
                        recoveries.length === 0
                          ? !isEmpty(orderWH)
                            ? {
                                ...orderWH,
                                first_name: orderWH.name,
                                phone_number_one: !isEmpty(orderWH.phone)
                                  ? orderWH.phone
                                  : '',
                              }
                            : {}
                          : {},
                    }}
                    isNew={true}
                    getOrderRecoveries={refreshOrderRecoveries}
                    recoveryParams={{ ...recoveryKeys }}
                    order_id={order_id}
                    request_from={'recovery'}
                    deleteClick={deleteClick}
                  />
                }
                </div>
              )}
            </Col>
          </Row>
        ) : (
          <Row className="center route_action_buttons"> {/* timeLine */}
            <Col xs={24}>
            {recoveries.length > 0 ?
              (<Timeline>
                {recoveries.map((recovery, index) => {
                  const { recovery_eta, recovery_dead_line, status } = recovery
                  const statusColor = getSatusColor(recovery.status);

                  {/* recovery.status === 'in_transit'
                              ? 'inCompletedStop'
                              : 'inProgessStop' */}
                  return (
                    <Timeline.Item
                      dot={
                        <Button
                          className={
                            'textBold'
                          }
                          shape="circle"
                          size="small"
                          style={{ backgroundColor: statusColor, color: '#FFFFFF'}}
                        >
                          {index + 1}
                        </Button>
                      }
                      color="red"
                      className="releaseTimelineHead"
                    >
                      <Card
                        size="small"
                        title={
                          <Fragment>
                            <span className="textBold fontSize16 textPrimary">
                              <Icon type="calendar" />
                              &nbsp;{I18n.t('recoveries.recovery_date')} :
                            </span>{' '}
                            &nbsp;&nbsp;
                            <span
                              className="textBold fontSize16 textPrimary"
                              style={{ color: statusColor }}
                            >
                              <DisplayTime
                                isUTC={true}
                                displayWithDate={true}
                                dateTimeString={recovery_eta}
                                key={recovery.id}
                                timeZoneId={recovery.pickup_timeZoneId}
                              />
                              {/* {!isEmpty(recovery_eta) && moment(recovery_eta).isValid() ? moment(recovery_eta).format(`${AppConfig.date_format} ${AppConfig.time_format}`) : 'NA'} */}
                            </span>
                          </Fragment>
                        }
                        extra={
                          <Fragment>
                            {/* <Button
                              type="default"
                              size="small"
                              shape="circle"
                              icon="edit"
                              className="marginRight5"
                              // onClick={() => handleEditStop(stop.id)}
                            /> */}
                            <span
                              className="textBold fontSize16"
                              style={{ color: '#607AD6' }}
                            >
                              {I18n.t('general.status')} :
                            </span>
                            &nbsp;&nbsp;
                            <span
                              className="textBold fontSize16 marginRight15"
                              style={{ color: statusColor }}
                            >
                              {!isEmpty(status) &&
                                I18n.t(`recoveries.${status}`)}
                            </span>
                           
                            <Popconfirm
                              placement="topRight"
                              title={I18n.t('messages.delete_confirm')}
                              onConfirm={() => deleteClick(recovery.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="danger"
                                size={'small'}
                                shape="circle"
                                icon="delete"
                                className="marginRight5"
                              />
                            </Popconfirm>
                          </Fragment>
                        }
                      >
                        <RecoveryDetails
                          currentRecovery={recovery}
                          key={`rec${index}`}
                          timeZoneId={timeZoneId}
                        />
                      </Card>
                    </Timeline.Item>
                  )
                })}
              </Timeline>):(
                renderRecoveryAlert(I18n.t("recoveries.alert"))
            )}
            </Col>
          </Row>
        )}
    </div>
  )
}

export default RecoveryManage

RecoveryManage.propTypes = {
  currentRecovery: PropTypes.shape(),
}

RecoveryManage.defaultProps = {
  currentRecovery: null,
}

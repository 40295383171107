import React, { useState, useEffect } from 'react';
import { Button, Icon, Typography, Row, Col } from 'antd';
import { alertMessage } from '../common/Common';
const { Title, Paragraph } = Typography;

const FleetCoolCrash = ({ history }) => {
  const handleReload = () => {
    window.location.reload();
  };

  const handleGoToOrders = () => {
    console.log("Navigating to Orders page");
    history.push('/orders');
  };

  const handleContactSupport = () => {
    const email = "mailto:support@fleetenable.com";
    window.location.href = email;
  };

  return (
    <div
      style={ {
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      } }
    >
      <div
        style={ {
          background: "#fff",
          padding: "32px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          maxWidth: "50vw",
          width: "100%",
        } }
      >
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={ { marginBottom: "24px" } }
        >
          <Icon type="warning" style={ { fontSize: "48px", color: "#faad14" } } />
        </Row>
        <Title level={ 2 } style={ { textAlign: "center" } }>
          Oops! We've Hit a Roadblock
        </Title>
        <Paragraph style={ { textAlign: "center" } }>
          Our system is experiencing a temporary glitch. Please try refreshing your page or check your orders.
        </Paragraph>
        <Row type="flex" justify="center" gutter={ 16 }>
          <Col>
            <Button type="primary" icon="reload" onClick={ handleReload }>
              Reload Screen
            </Button>
          </Col>
          <Col>
            <Button icon="file-text" onClick={ handleGoToOrders }>
              Go to Orders
            </Button>
          </Col>
          <Col>
            <Button icon="mail" onClick={ handleContactSupport }>
              Contact Support
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const NewVersionAvailableScreen = ({ reloadOnClick, updateType = 'update' }) => {
  const getUpdateInfo = () => {
    switch (updateType) {
      case 'bugfix':
        return {
          title: "Bug Fix Available",
          message: "We've squashed some bugs to improve your experience. Please reload to apply the fix."
        };
      case 'feature':
        return {
          title: "New Feature Available",
          message: "We've added a new feature to enhance your experience. Please reload to access it."
        };
      default:
        return {
          title: "Update Available",
          message: "An update is available for the application. Please reload to apply the changes."
        };
    }
  };

  const { title, message } = getUpdateInfo();

  return (
    <div
      style={ {
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      } }
    >
      <div
        style={ {
          background: "#fff",
          padding: "32px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          maxWidth: "50vw",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        } }
      >
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={ { marginBottom: "24px" } }
        >
          <Icon type="info-circle" style={ { fontSize: "48px", color: "#1890ff" } } />
        </Row>
        <Title level={ 2 } style={ { textAlign: "center", marginBottom: "16px" } }>
          {title}
        </Title>
        <Paragraph style={ { textAlign: "center", marginBottom: "24px" } }>
          {message}
        </Paragraph>
        <Row type="flex" justify="center" gutter={ 16 } style={ { marginBottom: "24px" } }>
          <Col>
            <Button type="primary" icon="reload" onClick={ reloadOnClick }>
              Reload Now
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export { NewVersionAvailableScreen, FleetCoolCrash };
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {
  Col,
  Row,
  Card,
  Icon,
  Drawer,
  Tooltip,
} from '../../common/UIComponents';
import {doFormate } from '../../common/Common';
import I18n from '../../common/I18n';
import Legs from '../Tasks/Legs';
// import tvImg from '../../../../../../public/icons/items/TV';
// import acImg from '../../../../../../public/icons/items/AC';
// import dishWasherImg from '../../../../../../public/icons/items/DISHWASHER';
// import fridgeImg from '../../../../../../public/icons/items/FRIDGE';
// import microwaveImg from '../../../../../../public/icons/items/MICROWAVE';
// import dryerImg from '../../../../../../public/icons/items/DRYER';
// import washerImg from '../../../../../../public/icons/items/WASHER';
import ReactToPrint from 'react-to-print';

import Gallery from '../common/Gallery';
import PrintGallery from '../common/PrintGallery';
import ItemAccessorial from '../orders/ItemAccessorial';
import FormIcon from '../common/FormIcon';

// const imgTypes = {
//   TV: tvImg,
//   AC: acImg,
//   DISHWASHER: dishWasherImg,
//   FRIDGE: fridgeImg,
//   'MICRO WAVE': microwaveImg,
//   DRYER: dryerImg,
//   WASHER: washerImg,
// };

class ItemDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderItem: this.props.order_item,
      item_types: {
        RETURN: { picked: `${I18n.t('general.customer')} Pickup`, dropped: 'Warehouse Drop' },
        NEW: { picked: 'Warehouse Pickup', dropped: `${I18n.t('general.customer')} Drop` },
      },
      imageProps: {
        columnWidth: 8,
        width: '100%',
        height: 300,
        showBottomIcons: true,
      },
      lightboxIsOpen: false,
      currentImage: 0,
      item_pictiures: [],
      accessorials: this.props.accessorials || [],
    };
  }

  fomatString = (str, format = '') => doFormate(str, format);

  static getDerivedStateFromProps(props, state) {
    return props.order_item;
  }

  onCheckBoxChange = (e, element) => {
    this.setState(
      {
        orderItem: Object.assign({}, this.state.orderItem, {
          [element]: e.target.checked,
        }),
      },
      () => {
        this.props.servceChange(e, element);
      },
    );
  };

  handleDownloadImages = () => {
    const itemPictures =
      this.state.orderItem.print_pictures &&
      this.state.orderItem.print_pictures.length > 0
        ? this.state.orderItem.print_pictures
        : [];
    const cleaneData = _.compact(itemPictures);
    const pictures = cleaneData.map(pic => ({ src: pic }));
    this.setState({
      item_pictiures: pictures,
      imagesVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      imagesVisible: false,
    });
  };

  setRef = ref => (this.componentRef = ref);

  setImageSpecifications = () => new Promise((resolve, reject) => {
    this.setState(
      {
        imageProps: {
          columnWidth: 24,
          width: '100%',
          height: 'auto',
          showBottomIcons: false,
        },
      },
      () => resolve(),
    );
  });

  resetImageSettings = () => new Promise((resolve, reject) => {
    this.setState(
      {
        imageProps: {
          columnWidth: 8,
          width: '100%',
          height: 300,
          showBottomIcons: true,
        },
      },
      () => resolve(),
    );
  });

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };


  render() {
    const { orderItem, accessorials } = this.state;
    const itemAccessorials = orderItem.item_accessorials || [];
    let total = 0;
    itemAccessorials.map((accessorial) => {
      const orderAccessorialIndex = _.findIndex(accessorials, [
        'org_accessorial_id',
        accessorial.org_accessorial_id,
      ]);
      if (orderAccessorialIndex >= 0) {
        total += accessorials[orderAccessorialIndex].amount;
      }
    });

    const item = {
      id: orderItem.id,
      item_name: this.fomatString(orderItem.item_name),
      quantity: this.fomatString(orderItem.item_quantity),
      item_type: this.fomatString(orderItem.item_type),
      // return_model: '',
      // return_serial: '',
      // new_model: this.fomatString(orderItem.item_model),
      // new_serial: this.fomatString(orderItem.serial_number),
      // services_ids: orderItem.service_ids,
      item_accessorials: orderItem.item_accessorials,
      item_model: this.fomatString(orderItem.item_model),
      item_serial: this.fomatString(orderItem.serial_number),
      service_load_opted:
        !!(orderItem.service_load_opted && orderItem.service_load_opted),
      service_load_completed: orderItem.service_load_completed,
      service_unload_opted:
        !!(orderItem.service_unload_opted && orderItem.service_unload_opted),
      service_unload_completed: orderItem.service_unload_completed,
      service_install_opted:
        !!(orderItem.service_install_opted && orderItem.service_install_opted),
      service_install_completed: orderItem.service_install_completed,
      service_uninstall_opted:
        !!(orderItem.service_uninstall_opted && orderItem.service_uninstall_opted),
      service_uninstall_completed: orderItem.service_uninstall_completed,
      signature: orderItem.signature ? orderItem.signature : '',
      pictures: orderItem.pictures ? orderItem.pictures : [],
      failed_notes: orderItem.failed_notes,
      status: 'Pending',
      picked_at: orderItem.picked_at,
      dropped_at: orderItem.dropped_at,
      selectedAccessorials: orderItem.item_accessorials ? orderItem.item_accessorials.map(accessorial => accessorial.org_accessorial_id) : [],
      total,
    };
    if (
      item.service_load_opted ||
      item.service_unload_opted ||
      item.service_install_opted ||
      item.service_uninstall_opted
    ) {
      item.is_services_opted = true;
    }

    return (
      <div>
        <Card
          style={{ marginBottom: 10 }}
          title={`${item.item_name} (${item.item_type})`}
          size="small"
          extra={
            <Fragment>
              {orderItem.print_pictures &&
                orderItem.print_pictures.length > 0 && (
                <Tooltip title="Print Images">
                  {/* <Icon
                    type="printer"
                    className="marginRight5"
                    onClick={() => this.handleDownloadImages()}
                  /> */}
                  <PrintGallery pictures={orderItem.print_pictures} />
                </Tooltip>
              )}
            </Fragment>
          }
        >
          <Row>
            {/* <Col xs={6} sm={6} md={4} lg={4}>
              <img
                src={
                  imgTypes[item.item_name.toUpperCase()]
                    ? imgTypes[item.item_name.toUpperCase()]
                    : otherImg
                }
                alt="Item"
                className="details_icon_imag"
              />
            </Col> */}
            <Col xs={24} sm={24} md={20} lg={20}>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Row>
                    <Col sm={2} xs={2} md={2} lg={2}>
                      <div className="textCenter">
                        <Icon type="shopping" />
                      </div>
                    </Col>
                    <Col sm={6} xs={6} md={6} lg={6}>
                      <div className="info-text textBold">Item</div>
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col sm={15} xs={15} md={15} lg={15}>
                      <div className="info-text">
                        <span>{item.item_name}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Row>
                    <Col sm={2} xs={2} md={2} lg={2}>
                      <div className="textCenter">
                        <Icon type="number" />
                      </div>
                    </Col>
                    <Col sm={10} xs={13} md={7} lg={7}>
                      <div className="info-text textBold">Serial No</div>
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col sm={11} xs={8} md={12} lg={12}>
                      <div className="info-text">
                        <span>{item.item_serial}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Row>
                    <Col sm={2} xs={2} md={2} lg={2}>
                      <div className="textCenter">
                        <Icon type="number" />
                      </div>
                    </Col>
                    <Col sm={6} xs={6} md={4} lg={6}>
                      <div className="info-text textBold">Quantity</div>
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col sm={15} xs={15} md={15} lg={15}>
                      <div className="info-text">
                        <span>{item.quantity}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Row>
                    <Col sm={2} xs={2} md={2} lg={2}>
                      <div className="textCenter">
                        <Icon type="qrcode" />
                      </div>
                    </Col>
                    <Col sm={10} xs={13} md={7} lg={7}>
                      <div className="info-text textBold">Model No</div>
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col sm={11} xs={8} md={12} lg={12}>
                      <div className="info-text">
                        <span>{item.item_model}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} sm={24} lg={24} md={24} className="alignLeft">
                  <Row>
                    <Col sm={2} xs={2} md={1} lg={1}>
                      <Icon type="clock-circle" />
                    </Col>
                    <Col sm={9} xs={9} md={7} lg={7} className="textBold">
                      {this.state.item_types[item.item_type].picked}
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col sm={12} xs={2} md={15} lg={15}>
                      {item.picked_at
                        ? moment(new Date(item.picked_at)).format('lll')
                        : 'Not yet done'}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} lg={24} md={24} className="alignLeft">
                  <Row>
                    <Col sm={2} xs={2} md={1} lg={1}>
                      <Icon type="clock-circle" />
                    </Col>
                    <Col sm={9} xs={9} md={7} lg={7} className="textBold">
                      {this.state.item_types[item.item_type].dropped}
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col sm={12} xs={2} md={15} lg={15}>
                      {item.dropped_at
                        ? moment(new Date(item.dropped_at)).format('lll')
                        : 'Not yet done'}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} lg={24} md={24} className="alignLeft">
                  <Row>
                    <Col sm={2} xs={2} md={1} lg={1}>
                      <FormIcon/>
                      &nbsp;
                    </Col>
                    <Col sm={9} xs={9} md={7} lg={7} className="textBold">
                      <div className="info-text textBold">Notes</div>
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col sm={12} xs={2} md={15} lg={15}>
                      <div className="info-text">
                        <span>{item.notes ? item.notes : 'NA'}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>

                {item.failed_notes && (
                  <Col xs={24} sm={24} lg={24} md={24} className="alignLeft">
                    <Row>
                      <Col sm={2} xs={2} md={1} lg={1}>
                        <FormIcon/> 
                        &nbsp;
                      </Col>
                      <Col sm={9} xs={9} md={7} lg={7} className="textBold">
                        <div className="info-text textBold">Failed Notes</div>
                      </Col>
                      <Col xs={1}>:</Col>
                      <Col sm={12} xs={2} md={15} lg={15}>
                        <div className="info-text">
                          <span>{item.failed_notes}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}


              </Row>
            </Col>
          </Row>

          {/* <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Row>
                                <Col sm={2} xs={2} md={1} lg={1}>
                                    <div className="textCenter">
                                        <Icon type="form" />
                                    </div>
                                </Col>
                                <Col sm={10} xs={10} md={2} lg={2}>
                                    <div className="info-text textBold">Notes</div>
                                </Col>
                                <Col xs={1}>:</Col>
                                <Col sm={11} xs={11} md={20} lg={20}>
                                    {
                                        <div className="info-text">
                                            <span>{item.notes ? item.notes : 'NA'}</span>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
          {/* {item.failed_notes && <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Row>
                                <Col sm={2} xs={2} md={1} lg={1}>
                                    <div className="textCenter">
                                        <Icon type="form" />
                                    </div>
                                </Col>
                                <Col sm={10} xs={10} md={2} lg={2}>
                                    <div className="info-text textBold">Failed Notes</div>
                                </Col>
                                <Col xs={1}>:</Col>
                                <Col sm={11} xs={11} md={20} lg={20}>
                                    {
                                        <div className="info-text">
                                            <span>{item.failed_notes}</span>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    } */}
          {/* <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            {item['is_services_opted'] && <Row>
                                <Col sm={2} xs={2} md={1} lg={1}>
                                    <div className="textCenter">
                                        <Icon type="control" />
                                    </div>
                                </Col>
                                <Col sm={10} xs={10} md={2} lg={2}>
                                    <div className="info-text textBold">Services</div>
                                </Col>
                                <Col xs={1}>:</Col>

                                <Col sm={11} xs={11} md={24} lg={24} className="textBlack marginTop20">
                                    <Row>

                                        {item.service_load_opted &&
                                            <Col xs={12} sm={8} lg={6} md={6} className="alignCenter">
                                                <img src={item.service_load_completed ? load_green_img : load_grey_img} style={{ width: 40, height: 40 }} /><br />
                                                <Col>Load</Col>
                                                <Col>{item.service_load_completed} </Col>
                                            </Col>
                                        }
                                        {item.service_uninstall_opted &&
                                            <Col xs={12} sm={8} lg={6} md={6} className="alignCenter">
                                                <img src={item.service_uninstall_opted ? uninstall_green_img : uninstall_grey_img} style={{ width: 40, height: 40 }} /><br />
                                                <Col> Uninstall</Col>
                                                <Col>{item.service_uninstall_completed}</Col>
                                            </Col>
                                        }
                                        {item.service_install_opted &&
                                            <Col xs={12} sm={8} lg={6} md={6} className="alignCenter">
                                                <img src={item.service_install_opted ? install_green_img : install_grey_img} style={{ width: 40, height: 40 }} /><br />
                                                <Col>Install</Col>
                                                <Col>{item.service_install_completed}</Col>
                                            </Col>
                                        }

                                        {item.service_unload_opted &&
                                            <Col xs={12} sm={8} lg={6} md={6} className="alignCenter">
                                                <img src={item.service_unload_opted ? unload_green_img : unload_grey_img} style={{ width: 40, height: 40 }} /><br />
                                                <Col> UnInstall</Col>
                                                <Col>{item.service_unload_completed}</Col>
                                            </Col>
                                        }
                                        <Col xs={2}></Col>
                                    </Row>

                                </Col>
                            </Row>
                            }
                        </Col>
                    </Row>
                    */}
          <Row>
            <Col xs={24}>
              {accessorials.length > 0 && (
                <Col xs={24} sm={24} lg={24} md={24} className="alignLeft">
                  <ItemAccessorial
                    item={item}
                    accessorials={accessorials}
                    isReadOnly
                    showFullDetails={false}
                    title={I18n.t('general.accessorial')}
                    showHeading

                  />
                </Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Legs
                signature={item.signature}
                pictures={item.pictures}
                paination={{ position: 'none' }}
                size="small"
              />
            </Col>
          </Row>
          {this.state.imagesVisible && (
            <Drawer
              title="View Images"
              placement="left"
              onClose={this.onClose}
              visible={this.state.imagesVisible}
              width="85%"
            >
              <ReactToPrint
                trigger={() => (
                  <div className="alignCenter" style={{ cursor: 'pointer' }}>
                    <Icon type="printer" /> Print All
                  </div>
                )}
                onBeforeGetContent={() => this.setImageSpecifications()}
                onAfterPrint={this.resetImageSettings}
                content={() => this.componentRef}
              />
              <div ref={this.setRef} className="margin10">
                <Row gutter={16}>
                  {this.state.item_pictiures.map((pic, index) => {
                    const imagName = _.last(_.split(pic.src, '/'));
                    return (
                      <Col xs={this.state.imageProps.columnWidth}>
                        <a href={pic.src} download={imagName} target="_blank">
                          <img
                            src={pic.src}
                            style={{
                              width: this.state.imageProps.width,
                              height: this.state.imageProps.height,
                            }}
                            alt="picture"
                          />
                        </a>
                        {this.state.imageProps.showBottomIcons && (
                          <div className="imageBottomIcons">
                            <Row>
                              <Col xs={12} className="alignLeft textWhite">
                                <Icon
                                  type="zoom-in"
                                  onClick={e => this.openLightbox(index, e)}
                                />
                              </Col>
                              <Col xs={12} className="alignRight textWhite">
                                <a
                                  href={pic.src}
                                  download={imagName}
                                  target="_blank"
                                >
                                  <Icon type="cloud-download" />{' '}
                                </a>{' '}
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Drawer>
          )}
        </Card>
        {this.state.lightboxIsOpen && this.state.item_pictiures.length > 0 && (
          <Gallery
            images={this.state.item_pictiures}
            onClose={this.closeLightbox}
          />
        )}
      </div>
    );
  }
}
ItemDetails.propTypes = {
  order_item: PropTypes.shape().isRequired,
  accessorials: PropTypes.shape().isRequired,
};
export default ItemDetails;

import React from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import ImageViewer from "../common/ImageViewer";
import moment from "moment";
import { Icon, Tooltip } from "antd";

class ItemsExceptionsList extends BaseList {
  constructor(props) {
    super(props);
    const timeFormat = props.isMilitaryTime === true ? "MMM DD, YYYY HH:mm" : "MMM DD, YYYY hh:mm A";
    this.columns = [
      {
        key: "location_type",
        title: <b>Location</b>,
        dataIndex: "location_type",
        // width: 120,
        render: (locationType) => {
          const iconType = locationType === "WH" ? "home" : locationType === "CS" ? "user" : "";
          const tooltipTitle = locationType === "WH" ? "Warehouse" : locationType === "CS" ? "Consignee" : "";

          return iconType ? (
            <Tooltip title={tooltipTitle}>
              <Icon type={iconType} />
            </Tooltip>
          ) : null;
        },
      },

      {
        key: "exception_type",
        title: <b>{I18n.t("item_exceptions.exceptions")}</b>,
        dataIndex: "exception_message",
        // width:120
      },
      {
        key:"exception_comment",
        title:<b>{I18n.t("item_exceptions.exception_comment")}</b>,
        dataIndex:"exception_comments",
        // width:120
      },
      {
        key: "captured_by",
        title: <b>{I18n.t("item_exceptions.captured_by")}</b>,
        dataIndex: "created_by_name",
        // width:120
      },
      {
        key: "captured_at",
        title: <b>{I18n.t("item_exceptions.captured_at")}</b>,
        // width: 120,
        render: (record) => {
          return _.get(record, "received_at")
            ? moment(record.received_at).format(timeFormat)
            : "NA";
        },
      },
      {
        key: "images",
        title: <b>{I18n.t("item_exceptions.images")}</b>,
        // width:120,
        render: (record) => {
          const imageData =
            !_.isEmpty(record.item_pictures) &&
            record.item_pictures.map((item) =>
              item.image_url ? item.image_url : _.get(item.item_image, "url")
            );
          return <ImageViewer images={imageData} />;
        },
      },
    ];
  }
}
export default ItemsExceptionsList;
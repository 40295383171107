import React, { Component, Fragment } from "react";
import { Col, Row, Icon } from "../../common/UIComponents";
import {
  isEmpty,
  getFileIcon,
  getFileType,
} from "../../common/Common";
import Gallery from "./Gallery";
import MixedGallery from "../../stories/MixedGallery";
import BaseModal from "../BaseModal";
import PropTypes from "prop-types";

class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      imagesVisible: false,
      galleryPics: [],
      fileName: '',
    };
    this.scroll = this.scroll.bind(this);
    this.imageContainerRef = React.createRef();
  }

  scroll(direction) {
    const container = this.imageContainerRef.current;

    const far = (container.offsetWidth / 2) * direction;
    const pos = container.scrollLeft + far;
    container.scrollTo({
      left: pos,
      behavior: 'smooth',
    });
  }

  openLightbox = (event, index, element, imageElement) => {
    event.preventDefault();
    const pictures = this.props.images ? this.props.images : [];
    if (pictures.length > 0) {
      const fileList = pictures[index] ? [pictures[index]] : [];
      const fileType = getFileType(pictures[index]);
      if (!_.isEmpty(fileType)) {
        const cleaneData = _.compact(fileList);
        const cleanedPictures = cleaneData.map((pic) => ({
          src: pic,
        }));
        this.setState({
          currentImage: 0,
          lightboxIsOpen: true,
          galleryPics: cleanedPictures,
        });
      } 
      // else {
      //   window.open(pictures[index], "_blank");
      // }
    }
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  // handleFileNameChange = (newFileName) => {
  //   const formattedFileName = newFileName.replace(/[_-]/g, ' ');
  //   this.setState({ fileName: formattedFileName });
  // }


  render() {
    const pictures = this.props?.images ? this.props.images.filter(i => !isEmpty(i)) : [];
    const totalImages = pictures.length;
    
    return (
      <Fragment>
        {totalImages > 0 && (
          
            this.props.isIconBased ?
            <Icon type="link" 
              style={{ color: 'blue', fontSize: 14 }} 
              rotate={135}
              onClick={(event) =>
              this.openLightbox(
                event,
                0,
                "pod_pic_settings",
                "src"
              )
            }
            
            />
            :
            <Row>
            <Col xs={24} className="scrollBlock2">
              <div className="main">
                <Row>
                  <Col xs={2} className="center buttonsblock">
                    <Icon
                      type="left-circle"
                      theme="filled"
                      className="prev"
                      onClick={() => this.scroll(-1)}
                    />
                  </Col>

                  <Col xs={20}>
                    <div className="image-container" ref={this.imageContainerRef}>
                      {
                        // this.state.config.pod_pic_settings.filter(file => !isEmpty(file.src))
                        pictures.map((src, index) => {
                          const fileType = getFileType(src);
                          return (
                            <Fragment>
                              <div
                                className="ant-upload-list-item"
                                key={`gallery${src}`}
                                onClick={(event) =>
                                  this.openLightbox(
                                    event,
                                    index,
                                    "pod_pic_settings",
                                    "src"
                                  )
                                }
                              >
                                <div className="ant-upload-list-item-info">
                                  <span
                                    className="ant-upload-list-item-thumbnail"
                                    rel="noopener noreferrer"
                                  >
                                    {getFileType(src) === "image" ? (
                                      <img
                                        src={src}
                                        alt="Pod"
                                        className="ant-upload-list-item-image"
                                      />
                                    ) : (
                                      <Icon
                                        type={getFileIcon(src)}
                                        style={{
                                          fontSize: 34,
                                          marginTop: "3px",
                                        }}
                                        theme="twoTone"
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })
                      }
                    </div>
                  </Col>

                  <Col xs={2} className="center buttonsblock">
                    <Icon
                      type="right-circle"
                      theme="filled"
                      className="next"
                      onClick={() => this.scroll(1)}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        {this.state.lightboxIsOpen && this.state.galleryPics.length > 0 && (
          // <Gallery
          //   images={this.state.galleryPics}
          //   onClose={this.closeLightbox}
          //   currentImage={this.state.currentImage}
          // />
          <BaseModal
            visible={this.state.lightboxIsOpen}
            title={"Gallery"}
            onCancel={this.closeLightbox}
            footer={null}
            width="70%"
          >
            <div>
              <MixedGallery
                handlePrint={this.props.handlePrint}
                showPrintButton={!_.isEmpty(this.props.showPrintButton) ? this.props.showPrintButton : false}
                closeComponent={this.closeLightbox}
                showDeleteButton={this.props.showDeleteButton}
                deleteImage={this.props.deleteImage}
                showCapturedDate={this.props.showCapturedDate ?? false}
                orderPictures={this.props.orderPictures}
                files={this.props.images}
                currentImage={_.get(this.state.galleryPics, '[0].src', '')}
                />
            </div>
          </BaseModal>
        )}
      </Fragment>
    );
  }
}
export default ImageViewer;

ImageViewer.propTypes = {
  images: PropTypes.array,
  showDeleteButton: PropTypes.bool,
  deleteImage: PropTypes.func,
  showCapturedDate: PropTypes.bool,
  orderPictures: PropTypes.func,
  handlePrint: PropTypes.func,
};

ImageViewer.defaultProps = {
  handlePrint: () => { },
  images: [],
  showDeleteButton: false,
  deleteImage: () => { },
  showCapturedDate: false,
  orderPictures: () => { },
};

import React, { useMemo } from "react";

import { Skeleton, Spin } from 'antd';
import { Row, Col } from 'antd';
import logo from '../../../assets/images/logo.png';
const PageLoadingScreen = () => {
  return (
    <div className="loader-content">
      <div className="content-outer routes-classes">
        <Row className="page-header">
          <Col xs={ 12 }>
          </Col>
        </Row>
        <Spin tip="Loading page content..." size="large">
          <div className="skeleton-list">
            { [ ...Array(5) ].map((_, index) => (
              <Skeleton key={ index } active paragraph={ { rows: 4 } } />
            )) }
          </div>
        </Spin>
      </div>
      <style jsx>{ `
        .loader-content {
          width: 100%;
        }

        :global(.ant-spin-nested-loading) {
          width: 100%;
        }

        :global(.ant-spin-container) {
          padding: 24px;
          background: #fff;
          border-radius: 4px;
        }
      `}</style>
    </div>
  );
};


export const CompanyLogo = ({ style }) => {
  const defaultStyle = { width: '70%', height: '50%' };
  return <img src={ logo } alt="loading" style={ style ? { ...defaultStyle, ...style } : defaultStyle } />;
}

const AppLoadingScreen = () => {
  return (
    <div className="app-loader-container">
      <div className="spinner"></div>
      <div className="logo-text-container">
        <CompanyLogo />
        <div className="loading-text">is starting up...</div>
      </div>
      <style jsx>{ `
        .app-loader-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #eee;
        }

        .spinner {
          width: 80px;
          height: 80px;
          border: 8px solid #b3d9ff;
          border-top: 8px solid #0066cc;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin-bottom: 30px;
        }

        .logo-text-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
        }

        .loading-text {
          font-size: 24px;
          color: #0066cc;
          letter-spacing: 1px;
          animation: pulse 2s ease-in-out infinite;
          margin-top: 10px;
          white-space: nowrap;
        }


        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        @keyframes pulse {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.6; }
        }
      `}</style>
    </div>
  );
};

export {
  AppLoadingScreen,
  PageLoadingScreen,
};
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Icon,
  Drawer,
} from '../../common/UIComponents';
import Gallery from './Gallery';
import equal from "fast-deep-equal";

class PrintGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageProps: {
        columnWidth: 8,
        width: '100%',
        height: 300,
        showBottomIcons: true,
      },
      lightboxIsOpen: false,
      currentImage: 0,
      pictures: this.props.pictures || [],
      imagesVisible: false,
      selectedImages: [],
    };
  }

  componentDidMount() {
    this.setPicturesData();
  }

  setPicturesData = () => {
    const pictures =
      this.state.pictures &&
      this.state.pictures.length > 0
        ? this.state.pictures
        : [];
    const cleaneData = _.compact(pictures);
    const cleanedPictures = cleaneData.map(pic => ({ src: pic }));
    this.setState({
      galleryData: cleanedPictures,
    });
    
  }

  handleDownloadImages = () => {
    this.setState({
      imagesVisible: true,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if(!equal(prevProps.pictures, this.props.pictures)) {
      this.setState({ pictures: this.props.pictures }, () => {
        this.setPicturesData();
      });
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   const { pictures } = props;
  //   return {
  //     pictures,
  //   };
  // }

  setRef = ref => (this.componentRef = ref);

  handleCheckboxChange = (src, event) => {
    if (event.target.checked) {
      this.setState(prevState => ({
        selectedImages: [ ...prevState.selectedImages, src ],
      }));
    } else {
      this.setState(prevState => ({
        selectedImages: prevState.selectedImages.filter(image => image !== src),
      }));
    }
  };

  setImageSpecifications = () => new Promise((resolve, reject) => {
    this.setState(prevState => ({
      galleryData: prevState.galleryData.filter(image => prevState.selectedImages.includes(image.src)),
      imageProps: {
        columnWidth: 24,
        width: '100%',
        height: 'auto',
        showBottomIcons: false,
      },
    }), resolve);
  });

  resetImageSettings = () => new Promise((resolve, reject) => {
    this.setState(prevState => ({
      galleryData: this.props.pictures.map(pic => ({ src: pic })),
      imageProps: {
        columnWidth: 8,
        width: '100%',
        height: 300,
        showBottomIcons: true,
      },
      selectedImages: [],
    }), resolve);
  });

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };
  onClose = () => {
    this.setState({
      imagesVisible: false,
    });
  };

  render() {
    const props = {
      name: 'file',
      onChange: this.handleFileChange,
      multiple: false,
    };
    return (
      <Fragment>
        {this.state.galleryData && this.state.galleryData.length > 0 &&
        <Icon
          type="printer"
          style={{ color: "#eb2f96", fontSize: '16px', width: 30, verticalAlign: 'middle' }}
          onClick={() => this.handleDownloadImages()}
        />}
        {
          this.state.imagesVisible &&
        (
          <Drawer
            title="View Images"
            placement="left"
            onClose={this.onClose}
            visible={this.state.imagesVisible}
            width="85%"
          >
            <ReactToPrint
              trigger={() => (
                <div className="alignCenter" style={{ cursor: 'pointer' }}>
                  <Icon type="printer" /> {this.state.selectedImages.length > 0 ? 'Print Selected' : 'Print All'}
                </div>
              )}
              onBeforeGetContent={() => this.setImageSpecifications()}
              onAfterPrint={this.resetImageSettings}
              content={() => this.componentRef}
            />
            <div ref={this.setRef} className="margin10">
              <Row gutter={16}>
                {this.state.galleryData.map((picture, index) => {
                  const pic = picture.src;
                  const imagName = _.last(_.split(pic, '/'));
                  return (
                    <Col xs={this.state.imageProps.columnWidth}>
                      {this.state.imageProps.showBottomIcons && (
                        <input
                          type="checkbox"
                          onChange={ event => this.handleCheckboxChange(pic, event) }
                        />
                      )}
                      <a href={pic} download={imagName} target="_blank">
                        <img
                          src={pic}
                          style={{
                            width: this.state.imageProps.width,
                            height: this.state.imageProps.height,
                          }}
                          alt="picture"
                        />
                      </a>
                      {this.state.imageProps.showBottomIcons && (
                        <div className="imageBottomIcons">
                          <Row>
                            <Col xs={12} className="alignLeft textWhite">
                              <Icon
                                type="zoom-in"
                                onClick={e => this.openLightbox(index, e)}
                              />
                            </Col>
                            <Col xs={12} className="alignRight textWhite">
                              <a
                                href={pic}
                                download={imagName}
                                target="_blank"
                              >
                                <Icon type="cloud-download" />{' '}
                              </a>{' '}
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Drawer>
        )}
        {this.state.lightboxIsOpen && this.state.pictures.length > 0 && (
          <Gallery
            images={this.state.galleryData}
            onClose={this.closeLightbox}
          />
        )}
      </Fragment>
    );
  }
}

PrintGallery.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrintGallery;

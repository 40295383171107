import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { fetchAllDeliveryZones } from "../api/DeliveryZones";
import { alertMessage } from "../common/Common";
import {TreeSelect } from "../common/UIComponents";
import { withRouter } from "react-router";
import { OrgContext } from "../context/OrgContext";
import { WarehouseContext } from "../context/WarehouseContext";
import { renderAlertMessage } from "../helpers/common";
const { TreeNode } = TreeSelect;

class ZonesList extends Component {
	constructor() {
		super();
		this.state = {
			zones: [],
			isLoading: false,
			selectedZoneIds: [],
		};
		this.getAllZones = this.getAllZones.bind(this);
	}
	componentDidMount() {
		this.getAllZones();
		this.setState({selectedZoneIds: this.props.zone_ids || []});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.zone_ids !== this.props.zone_ids) {
		this.setState({
			selectedZoneIds: this.props.zone_ids || [],
		});
		}
		if (prevProps.warehouseFilter.selectedWarehouses !== this.props.warehouseFilter.selectedWarehouses) {
			this.getAllZones();
		}
	}


	getAllZones() {
		this.setState({ isLoading: true });
		fetchAllDeliveryZones().then((result) => {
			if (result.success) {
				this.setState({ zones: result.zones, isLoading: false });
				this.props.onZonesChange(result.zones);
			} else {
				renderAlertMessage(result.errors)
				this.setState({ isLoading: false });
			}
		});
	}

	handleOnChange = (selectedZones) => {
		// check if all is selected then set all zones , else set selected zones
		const filteredZones = selectedZones.length
			? selectedZones[0].key === "all"
				? selectedZones.slice(1)
				: selectedZones[selectedZones.length - 1].key === "all"
				? []
				: selectedZones
			: [];
		this.props.handleFilterPlaceHolderChange("zone_ids", filteredZones);
		this.setState({
			selectedZoneIds: filteredZones,
		});
	};

	render() {
		const { isLoading, zones, selectedZoneIds } = this.state;
		return (
			<TreeSelect
			value={selectedZoneIds}
				labelInValue
				showSearch
				showArrow
				style={{ width: "100%" }}
				className="minWidth"
				dropdownStyle={ {
					maxHeight: 300,
					overflow: "auto",
					maxWidth:300,
				  } }
				placeholder="--Select--"
				allowClear
				treeDefaultExpandAll
				dropdownMatchSelectWidth={ true }
				treeCheckable
				maxTagCount={ 1 }
				treeNodeFilterProp="title"
				filterOption={(input, option) =>
					option.props.children
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}
				onChange={this.handleOnChange}
				mode="multiple"
				loading={isLoading}
			>
				<TreeNode value="ALL" title="All" key="all">
                {zones.map((zone) => (
                 <TreeNode
                   value={ zone.id}
                   title={ `${zone.name}` }
                   key={ zone.id}
            />
          )) }
        </TreeNode>
			</TreeSelect>

			 
		);
	}
}
export const ZonesListComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <ZonesList
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default ZonesListComponent;

ZonesListComponent.propTypes = {
	onZonesChange: PropTypes.func,
};

ZonesListComponent.defaultProps = {
	onZonesChange: () => {},
};



// not tree select
// import React, { Component, useContext } from "react";
// import PropTypes from "prop-types";
// import { fetchAllDeliveryZones } from "../api/DeliveryZones";
// import { alertMessage } from "../common/Common";
// import { Select } from "antd";
// import { withRouter } from "react-router";
// import { OrgContext } from "../context/OrgContext";
// import { WarehouseContext } from "../context/WarehouseContext";

// const { Option } = Select;

// class ZonesList extends Component {
// 	constructor () {
// 		super();
// 		this.state = {
// 			zones: [],
// 			isLoading: false,
// 			selectedZoneIds: [],
// 		};
// 		this.getAllZones = this.getAllZones.bind(this);
// 	}

// 	componentDidMount () {
// 		this.getAllZones();
// 		this.setState({ selectedZoneIds: this.props.zone_ids || [] });
// 	}

// 	componentDidUpdate (prevProps) {
// 		if (prevProps.zone_ids !== this.props.zone_ids) {
// 			this.setState({
// 				selectedZoneIds: this.props.zone_ids || [],
// 			});
// 		}
// 		if (
// 			prevProps.warehouseFilter.selectedWarehouses !==
// 			this.props.warehouseFilter.selectedWarehouses
// 		) {
// 			this.getAllZones();
// 		}
// 	}

// 	getAllZones () {
// 		this.setState({ isLoading: true });
// 		fetchAllDeliveryZones().then((result) => {
// 			if (result.success) {
// 				this.setState({ zones: result.zones, isLoading: false });
// 				this.props.onZonesChange(result.zones);
// 			} else {
// 				alertMessage(result.errors[ 0 ], "error", 10);
// 				this.setState({ isLoading: false });
// 			}
// 		});
// 	}

// 	handleOnChange = (selectedZones) => {
// 		// this.props.handleFilterPlaceHolderChange("zone_ids", selectedZones);
// 		this.setState({
// 			selectedZoneIds: selectedZones,
// 		});
// 	};

// 	render () {
// 		const { isLoading, zones, selectedZoneIds } = this.state;
// 		return (
// 			<Select
// 				value={ selectedZoneIds }
// 				mode="multiple"
// 				style={ { width: "100%" } }
// 				placeholder="--Select--"
// 				allowClear
// 				maxTagCount={ 1 }
// 				showSearch
// 				optionFilterProp="children"
// 				loading={ isLoading }
// 				onChange={ this.handleOnChange }
// 			>
// 				{ zones.map((zone) => (
// 					<Option key={ zone.id } value={ zone.id }>
// 						{ zone.name }
// 					</Option>
// 				)) }
// 			</Select>
// 		);
// 	}
// }

// export const ZonesListComponent = withRouter((props) => {
// 	const warehouseFilter = useContext(WarehouseContext);
// 	const { orgSettings: organizationSettings } = useContext(OrgContext);
// 	return (
// 		<ZonesList
// 			warehouseFilter={ warehouseFilter }
// 			organizationSettings={ organizationSettings }
// 			{ ...props }
// 		/>
// 	);
// });

// export default ZonesListComponent;

// ZonesListComponent.propTypes = {
// 	onZonesChange: PropTypes.func,
// };

// ZonesListComponent.defaultProps = {
// 	onZonesChange: () => { },
// };

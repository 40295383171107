import React from "react";
import {
  Col,
  Row,
} from "../../common/UIComponents";
import {isEmpty} from "../../common/Common"
import userStore from "../../stores/UserStore";
import I18n from "../../common/I18n";

const ShipperNote = ({ order }) => {
  const current_role = userStore.getCurrentRole();
  return (
    <Col span={24}>
      {
        <Row>
          <Col xs={24}>
            <span className="textBold">{I18n.t("info.notes")}:</span>
            &nbsp;&nbsp;
            <span className="alignLeft">
              {order.notes !== "" ? order.notes : "N/A"}
            </span>
          </Col>
          {/* {!['shipper', 'driver'].includes(current_role) && <Col xs={24}>
            <span className="textBold">{I18n.t("general.privateNotes")}: </span>
            &nbsp;&nbsp;
            <span className="alignLeft">
              {order.private_notes !== "" ? order.private_notes : "N/A"}
            </span>
          </Col>
          } */}
        </Row>
      }
      {!isEmpty(order.instructions_from_account_1) ? (
        <Row>
          <Col xs={24}>
            <span className="textBold">
              {I18n.t("general.instruction")} 1:&nbsp;&nbsp;
            </span>

            <span className="alignLeft">
              <span>{order.instructions_from_account_1}</span>
            </span>
          </Col>
        </Row>
      ) : (
        <React.Fragment />
      )}
      {!isEmpty(order.instructions_from_account_2) ? (
        <Row>
          <Col xs={24}>
            <span className="textBold">
              {I18n.t("general.instruction")} 2:&nbsp;&nbsp;
            </span>
            <span className="alignLeft">
              <span>{order.instructions_from_account_2}</span>
            </span>
          </Col>
        </Row>
      ) : (
        <React.Fragment />
      )}
      {!isEmpty(order.instructions_from_account_3) ? (
        <Row>
          <Col xs={24}>
            <span className="textBold">
              {I18n.t("general.instruction")} 3:&nbsp;&nbsp;
            </span>
            <span className="alignLeft">
              <span>{order.instructions_from_account_3}</span>
            </span>
          </Col>
        </Row>
      ) : (
        <React.Fragment />
      )}
    </Col>
  );
};
export default ShipperNote;

import React from "react";
import {
  Icon,
  Popconfirm,
  Tooltip,
} from "../../../common/UIComponents";
import BaseList from "../../BaseList";
import _ from "lodash";
import EditIcon from "../../common/EditIcon";
import DeleteIcon from "../../common/DeleteIcon";
import CopyableContent from "../../common/CopyableContent";
import I18n from "../../../common/I18n";
// import { Tooltip } from "@mui/material";
class ContactList extends BaseList {
  constructor(props) {
    super(props);
    const columns = [
      {
        key: "name",
        title: I18n.t("configurations.contact.name"),
        render: (data) => (
          <span>
            {`${data.first_name}${data.last_name ? ` ${data.last_name}` : ''}`}
          </span>
        ),
        className: 'noWrap'
      },
      {
        key: "email",
        title: I18n.t("configurations.contact.email"),
        dataIndex: "email",
        //render: (data) => <CopyableContent text={data} />,
        className: 'noWrap'
      },
      {
        key: "mobile",
        title: I18n.t("configurations.contact.mobile"),
        render: (data) => (
          <div>
            <div className="marginBottom10">{data.phone}</div>
            <div>{data.phone_2}</div>
          </div>
        ),
        className: 'noWrap'
      },
      // {
      //   key: "contact_group",
      //   title: I18n.t("configurations.contact.contact_group"),
      //   //dataIndex: "contact_group_details.name",
      //   render: (data) => (
      //     <span>{`${_.map(data.contact_group_details, "name").join(
      //       ", "
      //     )}`}</span>
      //   ),
      // },
      {
        key: "designation",
        title: I18n.t("configurations.contact.designation"),
        dataIndex: "designation",
      },
      {
        key: "alert_preference",
        title: I18n.t("general.communication_preference"),
        dataIndex: "alert_preference",
      },
      {
        key: "actions",
        title: "",
        dataIndex: "id",
        fixed: "right",
        render: (id) => (
          <div className="line-actions">
          <Tooltip title={I18n.t("general.edit")}>
            <span><EditIcon handleClick={() => this.props.onEdit(id)}/></span>
          </Tooltip>
            {/* <Icon type="bell" onClick={() => this.props.onClickSubscribe(id)} /> */}
            <Popconfirm
              placement="left"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={() => this.props.onDelete(id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={I18n.t("general.delete")}>
                <span><DeleteIcon/></span>
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ];
    const hideColumns = props.hideColumns || [];
    this.columns = columns.filter(rec => !hideColumns?.includes(rec.key));
  }
}

export default ContactList;

import React, { useState, Fragment } from 'react';
import { Tooltip, Icon,message,Popover } from 'antd';
import { Link } from '../../common/UIComponents';

const CopyableContent = ({ text, tooltipPosition = 'right', tooltipText = 'Copy', color = '#1890ff', showTooltip = true,showPopOver = false,PopoverTitle ="", popOverContent =[]}) => {
  const [ copied, setCopied ] = useState(false);

  const copyText = () => {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setCopied(true);
    message.success('Copied Successfully.!!');
    setTimeout(() => setCopied(false), 1000);
  };

  return text ? (
    <Fragment>
      {showTooltip ? (
        <Tooltip
          placement={tooltipPosition}
          title={copied ? "Copied!" : tooltipText}
        >
          {text}&nbsp;
          <Icon
            type={copied ? "check" : "copy"}
            onClick={copyText}
            style={{ cursor: "pointer", color: color }}
          />
        </Tooltip>
      ) : !showPopOver ? (
        <Fragment>
          {text}&nbsp;
          <Icon
            type={copied ? "check" : "copy"}
            onClick={copyText}
            style={{ cursor: "pointer", color: color }}
          />
        </Fragment>
      ) : (
        <Popover content={popOverContent} title={PopoverTitle} trigger="hover" className="my-custom-popover" placement="rightTop">
          <span>
            <Link style={{color:"black"}}>{text}&nbsp;</Link>
            <Icon
              type={copied ? "check" : "copy"}
              onClick={copyText}
              style={{ cursor: "pointer", color: color }}
            />
          </span>
        </Popover>
      )}
    </Fragment>
  ) : (
    <Fragment />
  );
};

export default CopyableContent;

import React, { Component, Fragment, useContext } from 'react'
import {Popconfirm,Select, Tabs} from "antd"
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { validateEmailList} from './helpers'
import {
  Icon,
  Tooltip,
  Dropdown,
  Menu,
  Row,
  Col,
  Button,
  Spin,
  Input,
  TextArea,
  FormItem,
  Link,
} from '../../common/UIComponents'

import {
  alertMessage,
  base64ToPdf,
  isEmpty,
  randomNumber,
} from '../../common/Common'
import I18n from '../../common/I18n'
import BaseModal from '../BaseModal'
import Invoice from './Invoice'
import expandIcon from '../../../assets/images/file-expand-icon.png'
import AppConfig from '../../config/AppConfig'
import {
  fetchConsolidatedInvoicePdf,
  fetchInvoiceRemarks,
  fetchOrderInvoicePdf,
  getContactEmails,
  getEmailSubjectLine,
  saveInvoiceRemark,
  SendInvoiceEmail,
} from '../../api/Billing'
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common'
import userStore from '../../stores/UserStore'
import AddOrdersToInvoice from './AddOrdersToInvoice'
import PostInvoiceToQB from './PostInvoiceToQB'
import EmailsInput from '../../containers/Modals/EmailsInput';
import UnlockInvoice from './UnlockInvoice'
import { checkListApi } from '../../api/CheckList'
import InvoiceActivitiesList from '../billing/InvoicesActivitiesList'
import { InvoicesActivitiesApi } from '../../api/InvoicesActivitiesApi'
import { OrgContext } from '../../context/OrgContext'
import PropTypes from "prop-types";
import { UserContext } from '../../context/UserContext'

const {TabPane} = Tabs
class ShowInvoiceComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: this.props.account,
      invoiceNo: this.props.invoice_no,
      showForm: false,
      isGeneratingPdf: false,
      status: this.props.status,
      isInvoiceModified: false,
      isSendingMail: false,
      showEmailModal: false,
      isEmailLoading:false,
      invoiceEmails: [],
      contacts: [],
      subject: "",
      subPrefix: "",
      description: "",
      checkList: this.props.checkList,
      is_locked: this.props.is_locked == true,
      is_file_generated: this.props?.is_file_generated ? this.props.is_file_generated : false,
      account_invoice_id: this.props.account_invoice_id,
      activeKey:'details',
      activityData: [],
      isLoading: false, 
      showInvoiceActions : this.props.showInvoiceActions,
      showActions: !isEmpty(this.props.showActions) ? this.props.showActions : true,
      approvalDate: !isEmpty(this.props.approvalDate) ? this.props.approvalDate : "",
      renderType: !isEmpty(this.props.renderType) ? this.props.renderType : "icon",
      vendorNo: "",
      remarkSaveLoading: false,
      remarks: ""
    }
  }
  addInvoiceEmail = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ invoiceEmails: validEmails });
  };
  showInvoiceForm = () => {
    this.setState({
      showForm: true,
      driverRates: [],
    })
    this.getInvoiceActivities();
  }

  // componentDidUpdate (prevProps, prevState) {
  //   if (this.state.is_locked !== this.props.is_locked) {
  //     this.setState({
  //       is_locked: this.props.is_locked == true,
  //     })
  //   }
  // }
  handleOnModalClose = () => {
    this.setState({
      showForm: false,
      driverRates: [],
    },
    //  () => {
    //   if (this.state.isInvoiceModified) {
    //     this.props.refreshList();
    //   }
    // }
  )
  }

  setRef = (ref) => (this.componentRef = ref)

  printOrderInvoice = (customer_order_id, invoiceNo) => {
    this.setState({
      isGeneratingPdf: true,
    })
    fetchOrderInvoicePdf(customer_order_id, invoiceNo)
      .then((result) => {
        if (result.success) {
          base64ToPdf(result.bol_file, `OrderInvoice-${customer_order_id}.pdf`)
        } else {
        }
      })
      .finally(() => {
        this.setState({
          isGeneratingPdf: false,
        })
      })
  }

  printConsolidateInvoice = () => {
    this.setState({
      isGeneratingPdf: true,
    })
    const org_id = userStore.getStateValue('selectedOrg')
    fetchConsolidatedInvoicePdf(
      this.state.account,
      this.state.invoiceNo,
      org_id,
    )
      .then((result) => {
        if (result.success) {
          base64ToPdf(
            result.bol_file,
            `ConsolidateInvoice-${this.state.invoiceNo}.pdf`,
          )
        } else {
        }
      })
      .finally(() => {
        this.setState({
          isGeneratingPdf: false,
        })
      })
  }

  printInvoice = (customer_order_id) => {
    if (this.props.invoice_type === 'INDV') {
      this.printOrderInvoice(customer_order_id, this.state.invoiceNo)
    } else {
      this.printConsolidateInvoice()
    }
  }

  setInvoiceDetails = ({ invoiceDetails, isLocked = false, totalDetails, vendorNo, billingCompanyDetails = {} }) => {
    this.setState({
      invoiceDetails: invoiceDetails,
      is_locked: isLocked,
      isInvoiceModified: true,// TO DO
      vendorNo,
      billingCompanyDetails
    })
  }

  handleAddOrdersSuccess = () => {
    this.setState({
      key: randomNumber(3),
      isInvoiceModified: true,
    })
  }

  handleApproveSuccess = (status,invoiceNo,statusChange) => {
    this.setState({
      key: randomNumber(3),
      status,
      isInvoiceModified: true,
    }), () => {
      this.props.onUnmount(invoiceNo,statusChange)
    }
  }

  getEmails = () => {
    const { account } = this.props;
    const accountId = account
    this.setState({isEmailLoading:true})
    getContactEmails(accountId).then((result) => {
      if (result.success) {
        this.setState({
          contacts: result.contact_emails || [],
          isEmailLoading:false
        })
      }else{
        alertMessage(result.errors[0], "error", 10);
        this.setState({ isEmailLoading: false, contacts: [] });
      }
    })
  }

getEmailSubject = async () => {
  const { organizationSettings } = this.props;
  const { invoice_body_text } = organizationSettings;
  const { currentOrg, invoice_no } = this.props;
  const payload = {
    organization_id: currentOrg.id,
    invoice_number: this.state.invoiceNo,
    setting_name: "invoice_email_subject",
  };

  const defaultSubject =
    currentOrg.code === "HILL" &&
    !_.isEmpty(this.state.billingCompanyDetails.name)
      ? `${this.state.billingCompanyDetails.name} #${invoice_no} Invoice`
      : `${invoice_no} Invoice`;

  try {
    const result = await getEmailSubjectLine(payload);

    if (result.success) {
      const emailSubject = result?.subject ? result.subject : defaultSubject;
      this.setState({
        subject: emailSubject,
      });
    } else {
      this.setState({
        subject: defaultSubject,
      });
      alertMessage(result.errors[0], "error", 10);
    }
  } catch (error) {
    console.error("Error fetching email subject:", error);
  } finally {
    this.setState({
      showEmailModal: true,
      description: invoice_body_text || I18n.t("configurations.default_email_body_message"),
    });
  }
};

getEmailSubjectPrefix = async () => {
  const { currentOrg} = this.props;
  const payload = {
    organization_id: currentOrg.id,
    invoice_number: this.state.invoiceNo,
    setting_name: "invoice_email_subject_prefix_value",
  };


  try {
    const result = await getEmailSubjectLine(payload);
    const { is_revised } = result;

    if (result.success) {
      // ony if is_revised is true
      if (is_revised) {
        const subjectPrefix = result?.subject ? result.subject : " ";
        this.setState({
          subPrefix: subjectPrefix,
        });
      }
    } else {
      this.setState({
        subPrefix: "",
      });
      alertMessage(result.errors[0], "error", 10);
    }
  } catch (error) {
    console.error("Error fetching email subject prefix:", error);
  } 
};

  handleSendingMails = () => {
    if (!this.state.invoiceEmails.length) return alertMessage('Please enter atleast one email address', 'error');

    const { account } = this.props;
    const payload = {
      account_id: account,
      invoice_number: this.state.invoiceNo,
      organization_id: userStore.getStateValue('selectedOrg'),
      email_ids: this.state.invoiceEmails,
      subject: this.state.subject,
      description: this.state.description,
    };
    this.setState({ isSendingMail: true });
    SendInvoiceEmail(payload).then((result) => {
      if (result.success) {
        alertMessage(result.message, 'success', 5);
        this.setState({
          // invoiceEmails: [],
          // subject: "",
          // description: "",

        });
        this.setState({showEmailModal:false})
      } else {
        alertMessage(result.message, 'error', 5);
      }
    }
    ).finally(() => { this.setState({ isSendingMail: false }); }
    );
  }

  onSaveRemark = async() =>{
    this.setState({
      remarkSaveLoading: true
    });
    try{
      const payload = {
        organization_id: userStore.getStateValue('selectedOrg'),
        account_invoice_id: this.props.account_invoice_id ? this.props.account_invoice_id : '',
        remarks: this.state.remarks
      }
      const response = await saveInvoiceRemark(payload)

      if(response?.success){
        const msg = response?.message ?  response.message : "Saved Successfully!!"
        alertMessage(msg, "success", 10)
      }else{
        renderAlertMessage(response?.errors)
      }
    }catch(error){
      console.error("error in getting Invoice remark response")
    }finally{
      this.setState({
        remarkSaveLoading: false
      });
    }
  }

  _rederRemarkForm = () =>{
    const status = this.state.status;
    return (
      <Fragment>
        <Spin spinning={this.state.remarkSaveLoading}>
          <Row
            type="flex"
            align="middle"
            gutter={8}
          >
            <Col span={21}>
              <FormItem label="Remarks">
                <TextArea
                disabled={status === "APPROVED"}
                  rows={4}
                  placeholder={I18n.t("general.remarks")}
                  className="sop-form-textArea"
                  value={this.state.remarks}
                  onChange={(e) => this.setState({ remarks: e.target.value })}
                />
              </FormItem>
            </Col>
            <Col span={3}>
          {  status != "APPROVED" && <Button type="primary" loading={this.state.remarkSaveLoading} onClick={this.onSaveRemark}>
                Save
              </Button>}
            </Col>
          </Row>
        </Spin>
      </Fragment>
    );
  }

  showEmailModal = () => {
    const { organizationSettings } = this.props;
    const { invoice_body_text } = organizationSettings;
    this.getEmailSubject()
    this.getEmails();
    this.getEmailSubjectPrefix();

    // this.setState({
    //   showEmailModal: true,
    //   subject:
    //     this.props.currentOrg.code == "HILL" && !_.isEmpty(this.state.billingCompanyDetails.name)
    //       ? this.state.billingCompanyDetails.name + " #" + this.props.invoice_no + " Invoice"
    //       : this.props.invoice_no + " Invoice",
    //   description:
    //     invoice_body_text || I18n.t("configurations.default_email_body_message"),
    // });

    // this.getEmails();
  }
  goToTab = (key) => {
    this.setState({
      activeKey: key,
    },()=>{
      if (key === "remarks") {
        this.getInvoiceRemarks();
      }
      if(key === "activities"){
        this.getInvoiceActivities();
      }
    });
  };
   getInvoiceActivities = async() => {
    this.setState({
      isLoading: false,
    })
    await InvoicesActivitiesApi.fetchInvoiceActivity({
      organization_id: userStore.getStateValue('selectedOrg'),
      invoice_id: this.props.account_invoice_id
      ? this.props.account_invoice_id
      : ''
    })
      .then((response) => {
        console.log('Invoice API---->',response)
        if (response.success) {
          this.setState({
            activityData: response.invoice_activity || []
          })
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      });
  };

  getInvoiceRemarks = async () => {
    this.setState({
      isLoading: true,
    });

    try {
      const payload = {
        organization_id: userStore.getStateValue("selectedOrg"),
        account_invoice_id: this.props.account_invoice_id || "",
      };

      const response = await fetchInvoiceRemarks(payload);

      if (response?.success) {
        const remarks = response.invoiceRemarks || "";
        this.setState({
          remarks,
        });
      } else {
        renderAlertMessage(response?.errors);
      }
    } catch (error) {
      console.error("Error fetching invoice remarks:", error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  renderFormModal = () => {
    const title = `${I18n.t('invoices.label')} #${this.state.invoiceNo}`
    const invoiceId = this.props.account_invoice_id
      ? this.props.account_invoice_id
      : ''
      const selectedInfo = this.props.selectedInfo
    const menu = (
      <Menu onClick={(e) => this.handleExportChange(e.key)}>
        {Object.keys(AppConfig.exportOptions).map((key) => (
          <Menu.Item key={key}>{AppConfig.exportOptions[key]}</Menu.Item>
        ))}
      </Menu>
    )
    const status = this.state.status;
    const {isSendingMail, is_locked, showActions,showInvoiceActions } = this.state
    const isLocked = is_locked == true
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        style={{ top: 30 }}
        width="80%"
      >
          <div className="child-card-container">
          <Tabs
            onChange={this.goToTab}
            activeKey={this.state.activeKey}
            size="small"
            type="card"
            tabBarExtraContent={isLocked && !(this.props.inBillingScreen) ? <Button type="danger" icon="lock" size="small">{I18n.t('general.locked')}</Button> : <Fragment />}
          >
          <TabPane tab={I18n.t("general.details")} key="details">  
        {showActions && (<Row type='flex' justify='center' align='middle' gutter={ 4 } style={{
          marginBottom: '10px',
        }}>
  
          {checkServiceExistance('GOIP') && (
            <Col>
              <Button
                type="default"
                size="small"
                icon="printer"
                className="buttonGreen"
                      disabled={this.state.is_file_generated}
                onClick={() =>
                  this.printInvoice(
                    this.state.invoiceDetails.customer_order_id,
                  )
                }
              >
                {I18n.t('general.print')}
              </Button>
            </Col>
          )}
          {
            checkServiceExistance('PITQB') && showInvoiceActions && (
              <Col>
                <PostInvoiceToQB
                  buttonType="danger"
                  isDisabled={!invoiceId || isLocked}
                  label={status === 'PENDING' ? I18n.t('invoices.approve') : I18n.t('invoices.reapprove')}
                  invoiceIds={[invoiceId]}
                  handleSuccess={() => this.handleApproveSuccess('APPROVED',"",true)}
                  size="small"
                  checkListData={this.state.checkList}
                  enableChecklistApproval={this.props.enableChecklistApproval}
                  selectedInfo={selectedInfo}
                  indexList={false}
                  handleUnmount={this.props.onUnmount}
                />
              </Col>
            )
          }
          {false && status === 'APPROVED' &&
            checkServiceExistance('PITQB') && showInvoiceActions && (
              <Col>
                <PostInvoiceToQB
                  buttonType="danger"
                  isDisabled={false}
                  label={I18n.t('invoices.push_to_qb')}
                  invoiceIds={[invoiceId]}
                  size="small"
                  checkListData={this.state.checkList}
                  enableChecklistApproval={this.props.enableChecklistApproval} 
                  handleSuccess={this.props.onUnmount}
                  selectedInfo={this.props.selectedInfo}
                />
              </Col>
            )}
          {checkServiceExistance('IAO') && showInvoiceActions && (
            <Col>
              <AddOrdersToInvoice
                buttonType="primary"
                isDisabled={false || isLocked}
                label={`${I18n.t('general.add')} ${I18n.t('menu.orders')}`}
                handleSuccess={this.handleAddOrdersSuccess}
                invoiceId={invoiceId}
                size="small"
              // handleCancel={handleCancel}
              />
            </Col>
          )}
                {
                // !this.state.is_file_generated &&
          <Col>
                    <Button
                      type="primary"
                      size="small"
                      icon="mail"
                      disabled={this.state.is_file_generated}
                      onClick={this.showEmailModal}
                      loading={isSendingMail}
                      style={
                        !this.state.is_file_generated
                          ? {
                            backgroundColor: '#1890ff',
                            borderColor: '#1890ff',
                            color: '#fff',
                          }
                          : {}
                      }
                    >
              {I18n.t('general.send_item', { item: I18n.t('general.invoice') })}
            </Button>
                  </Col>}
          {/* {
            isLocked && <UnlockInvoice
            buttonType="primary"
            isDisabled={!isLocked}
            label={I18n.t('invoices.unlock')}
            invoiceIds={invoiceId}
            handleSuccess={this.handleAddOrdersSuccess}
            size="small"
          />
          } */}
        </Row>)}

        <Spin spinning={this.state.isGeneratingPdf} delay={1000}>
          <Invoice
            orgRules={this.props.orgRules}
            currentOrg={this.props.currentOrg}
            accountUnitContext={this.props.accountUnitContext}
            orgSettings={this.props.orgSettings}
            isFileGenerated={this.state.is_file_generated}
            accountId={ this.state.account }
            invoiceNo={ this.state.invoiceNo }
            account_invoice_id= {this.state.account_invoice_id}
            setInvoiceDetails={ this.setInvoiceDetails }
            key={ this.state.key }
            showActions={this.state.showActions}
            approvalDate={this.props.approvalDate}
            invoiceApprovalDate={this.props.invoiceApprovalDate}
            showInvoiceActions = {showInvoiceActions}
            status={this.props.status}
            onUnmount={this.props.onUnmount}
            timeZoneId={this.props.timeZoneId}
            selectedInfo={selectedInfo}
            handleOnModalClose={this.handleOnModalClose}
            organizationSettings={this.props.organizationSettings}
          />
        </Spin>
          </TabPane>
            {!this.props.inBillingScreen &&
              <TabPane tab="Activities" key="activities">
                <InvoiceActivitiesList data={this.state.activityData}
                  timeZone="UTC"
                  isMilitaryTime={true}
                  timeZoneName="UTC"
                />

              </TabPane>
            }
            <TabPane tab={I18n.t("general.remarks")} key="remarks">
              {this._rederRemarkForm()}
            </TabPane>
        </Tabs>
        </div>
       
      </BaseModal>
    )
  }


renderEmailModal = () => {
  const fullSubject = `${this.state.subPrefix || ''} ${this.state.subject || ''}`.trim();  
  return (
      <BaseModal
        title={I18n.t("general.send_to", { from: I18n.t("general.invoice"), to: "Emails" })}
        onCancel={() => this.setState({
          showEmailModal: false,
          invoiceEmails: []
        })}
        width="50%"
        style={{ top: 40 }}
        maskClosable={false}
      >
      <Spin spinning={this.state.isEmailLoading}>
        <Row className="tagsInput emailForm" type='flex' align='middle' gutter={8}>
          <Col span={21}>
            <FormItem label={I18n.t('general.email')}>
            <Select
                  value = {this.state.invoiceEmails}
                  onChange={this.addInvoiceEmail}
                  mode="tags"
                  validationRegex = {this.addInvoiceEmail}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  tokenSeparators={[","]}
                  placeholder="Enter or select Emails"
                >
                  {
                    this.state.contacts.map(opt => <Option key={opt}>{opt}</Option>)
                  }
                </Select>


            </FormItem>
          </Col>
          <Col span={3} className="alignRight">
            <Popconfirm
              placement="topRight"
              title={I18n.t('messages.confirm')}
              onConfirm={this.handleSendingMails}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={this.state.isSendingMail}>
                Send
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormItem label={I18n.t("general.subject")}>
              <Input
                rows={4}
                placeholder={I18n.t("general.subject")}
                className="sop-form-textArea"
                value={fullSubject}
                onChange={(e) => {
                  const value = e.target.value;
                  const [newSubPrefix, ...rest] = value.split(' ');
                  const newSubject = rest.join(' ');

                  this.setState({
                    subPrefix: newSubPrefix, // Update subPrefix with the first word(s)
                    subject: newSubject // Update subject with the rest of the string
                  });
                }}
              />
            </FormItem>
          </Col>
          <Col>
            <FormItem label={I18n.t("general.description")}>
              <TextArea
                rows={4}
                placeholder={I18n.t("general.description")}
                className="sop-form-textArea"
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </FormItem>
          </Col>
        </Row>
        </Spin>
      </BaseModal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.renderType === 'icon' ? (
          <Tooltip title={I18n.t("general.views.detail")}>
            <img
              src={expandIcon}
              className="expandIcon cursorPointer"
              onClick={this.showInvoiceForm}
              alt={I18n.t("general.expand")}
              style={{ width: 14, ...this.props.style }}
            />
          </Tooltip>
        ) : (
          <Link className={"anchor_cursor"} onClick={this.showInvoiceForm}>
            {this.state.invoiceNo}
          </Link>
        )}
        {this.state.showForm && this.renderFormModal()}
        {this.state.showEmailModal && this.renderEmailModal()}
      </React.Fragment>
    );
  }
}

export const ShowInvoice = withRouter((props) => {
  const { orgSettings : organizationSettings , currentOrg }= useContext(OrgContext);
  const { orgRules } = useContext(UserContext);
  return (
    <ShowInvoiceComponent
      orgRules={orgRules}
      organizationSettings={organizationSettings}
      currentOrg={currentOrg}
      {...props}
    />
  );
});

export default withRouter(ShowInvoice)



ShowInvoice.propTypes = {
  refreshList: PropTypes.func,
  showInvoiceActions: PropTypes.bool,
  showActions:  PropTypes.bool
};

ShowInvoice.defaultProps = {
  refreshList: () => {},
  showInvoiceActions: true,
  inBillingScreen: false
};
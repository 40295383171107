import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { TreeSelect } from "antd";
import { isEmpty } from "../../../common/Common";

const { TreeNode } = TreeSelect;

const GLCodeSelect = (props) => {
  const {value, onChange, placeholder = 'Select', size="default", styleObj = {},defaultValue ,invoiceType, isTemplateZoneBilling = false, currentAccessorialHasGlCode = true, toDisable = false } = props


  useEffect(() => {
    if (isEmpty(value) && !isEmpty(defaultValue)) {
      onChange(defaultValue);
    }
  }, [value, defaultValue, onChange]);

  const onValueChange = (newValue) => {
    console.log("newValue", newValue);
    if(!isEmpty(newValue)){
      const tokens = _.split(newValue, '#$');
        onChange(tokens?.length ? tokens[0] : undefined)
    } else{
        onChange(undefined);
    }
  };

  // const renderDescriptions = (code) => {
  //   return props.data.filter(rec => rec.gl_code === code).map((rec, index) => 
  //     <TreeNode value={ `${rec.gl_code}#$${rec.description}` } title={ rec.description } key={ `${rec.gl_code}-${rec.description}-${index}` } />)
  // }

  const renderDescriptions = (code) => {
  return props.data.filter(rec => rec.gl_code === code).map((rec, index) => {
    // Create a unique key using a combination of unique properties and the index
    const uniqueKey = `${rec.gl_code}-${rec.description}-${index}-${rec.id || ''}`;
    return (
      <TreeNode
        value={`${rec.gl_code}#$${rec.description}`}
        title={rec.description}
        key={uniqueKey}
      />
    );
  });
}

  const isDisabled = (invoiceType, value, defaultValue) => {
    return (
      (invoiceType === "transportation_charges" && (!isEmpty(value) || !isEmpty(defaultValue))) ||
      (invoiceType === "fuel_surcharges" && (!isEmpty(value) || !isEmpty(defaultValue))) ||
      (invoiceType === "accessorial_charges" && (!isEmpty(value) || !isEmpty(defaultValue)))
    );
  };

  const glCodes = _.uniq(props.data.map((rec) => rec.gl_code));
  return (
    <TreeSelect
      disabled={!isTemplateZoneBilling ? ((currentAccessorialHasGlCode && isDisabled(invoiceType, value, defaultValue)) || toDisable) : null}
      showSearch
      style={{ width: 120, ...styleObj }}
      value={value}
      dropdownStyle={{ maxHeight: 300, overflow: "auto", maxWidth: 300 }}
      placeholder={placeholder}
      // allowClear
      treeDefaultExpandAll
      onChange={onValueChange}
      size={size}
    >
    {
        glCodes.map(code => <TreeNode value={code} title={code} key={code}>
            {renderDescriptions(code)}
        </TreeNode>)
    }
    </TreeSelect>
  );
};

export default GLCodeSelect;

import React, { Fragment, useEffect, useState } from 'react'
import _, { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Divider,
} from "antd";
import I18n from '../../common/I18n'
import {
  doFormate,
} from '../../common/Common'
import AppConfig from '../../config/AppConfig'
import moment from 'moment'
import imagePlaceholder from '../../../assets/images/logo_placeholder.png'
import Profile from '../common/Profile'
import AddressInfo from '../common/AddressInfo'
import DisplayTime from '../common/DisplayTime'
import { dispatcherNotesApi } from '../../api/OrdersApi';
import DispatcherNotes from '../orders/DispatcherNotes';

function RecoveryDetails(props) {
  const {
    recoveryParams,
    currentRecovery,
    deleteClick,
    isNew,
    getOrderRecoveries,
    timeZoneId,
  } = props
  const [driverNotes, setDriverNotes] = useState([])
  const {
    pickup_location,
    drop_location,
    recovery_date,
    recovery_eta,
    recovery_dead_line,
    driver_comments,
  } = currentRecovery;
  const pickupAddress = pickup_location?.l_address
    ? pickup_location.l_address
    : {}
  const deliveryAddress = drop_location?.l_address
    ? drop_location.l_address
    : {}
  const dv_comment = doFormate(driver_comments);

   useEffect(() => {
    if (currentRecovery.id) {
      getOriverNotes()
    }
  }, [])

  const getOriverNotes = () => {
    dispatcherNotesApi.fetch(currentRecovery.id).then((response) => {
      if (response.success) {
        setDriverNotes(response.dispatch_notes)
      }
    })
  }

  const renderDispatcherNotes = () => {
    const { organization_id } = currentRecovery
    return (
      <DispatcherNotes
        organization_id={organization_id}
        currentSourceRecord={currentRecovery}
        customer_order_notes={driverNotes}
        key={'customer_order_notes'}
        refer_type="recovery"
        apiEndpoint={dispatcherNotesApi}
        noteTitle="Driver Notes"
      />
    )
  }


  const renderDetails = () => (
    <>
    <Row>
      <Col>
      <table width="100%" className="marginTop10 marginBottom10" cellSpacing={10}>
      <thead>
        <tr>
          <th style={{ width: '33.33%' }} className="label_heading">
            Pickup Details
            <hr style={{ width: 92 }} />
          </th>
          <th style={{ width: '33.33%' }} className="label_heading">
            Delivery Details
            <hr style={{ width: 105 }} />
          </th>
          <th style={{ width: '33.33%' }} className="label_heading">
            Estimate Details
            <hr style={{ width: 105 }} />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fontSize12" style={{ verticalAlign: 'top' }}>
            <Profile
              address={pickupAddress}
              profile={(({
                name,
                phone_number_one,
                phone_number_two,
                email,
                company_name,
              }) => ({
                image: imagePlaceholder,
                full_name: name,
                mobile_number: `${phone_number_one} ${
                  !isEmpty(phone_number_two) ? `,${phone_number_two}` : ''
                }`,
                email: doFormate(email),
                company_name: company_name,
              }))(pickup_location)}
              displayType="line"
              showImage={false}
              withLables={false}
              inBilling={true}
              showAddress={false}
            />
            <div className="paddingLeft5">
              <AddressInfo address={pickupAddress} showIcon={true} />
            </div>
          </td>
          <td className="fontSize12" style={{ verticalAlign: 'top' }}>
            <Profile
              address={deliveryAddress}
              profile={(({
                name,
                phone_number_one,
                phone_number_two,
                email,
                company_name,
              }) => ({
                image: imagePlaceholder,
                full_name: name,
                mobile_number: `${phone_number_one} ${
                  !isEmpty(phone_number_two) ? `,${phone_number_two}` : ''
                }`,
                email: doFormate(email),
                company_name: company_name,
              }))(drop_location)}
              displayType="line"
              showImage={false}
              withLables={false}
              inBilling={true}
              showAddress={false}
            />
            <div className="paddingLeft5">
              <AddressInfo address={deliveryAddress} showIcon={true} />
            </div>
          </td>
          <td style={{ verticalAlign: 'top' }}>
            <Row className="fontSize15">
              <Col xs={8}>{I18n.t('recoveries.eta')}</Col>
              <Col xs={16} className="textBold">
                <DisplayTime
                  isUTC={true}
                  displayWithDate={true}
                  dateTimeString={recovery_eta}
                  key={'recovery_eta'}
                  timeZoneId={currentRecovery.pickup_timeZoneId}
                />
                {/* {!isEmpty(recovery_eta) && moment(recovery_eta).isValid()
                  ? moment
                      .utc(recovery_eta)
                      .format(
                        `${AppConfig.date_format} ${AppConfig.time_format}`,
                      )
                  : 'NA'} */}
              </Col>
            </Row>
            <Row className="fontSize15">
              <Col xs={8}>{I18n.t('recoveries.deadline')}</Col>
              <Col xs={16} className="textBold">
                {!isEmpty(recovery_dead_line) &&
                moment(recovery_dead_line).isValid()
                  ? moment
                      .utc(recovery_dead_line)
                      .format(`${AppConfig.date_format}`)
                  : 'NA'}
              </Col>
            </Row>
          </td>
        </tr>
      </tbody>
      </table>
      </Col>
    </Row>
    {dv_comment != "NA" && (
        <Row>
          <Col>
            <Divider />
            <span className="label_heading" style={{color: 'rgba(0, 0, 0, 0.85)'}}>
              {I18n.t("recoveries.comments")} :
              <hr style={{ width: 70 }} />
            </span>
            <p>{dv_comment}</p>
          </Col>
        </Row>
      )}
      <Row>
        <Col>{renderDispatcherNotes()}</Col>
      </Row>
    </>
  )

  return <Fragment>{renderDetails()}</Fragment>
}

export default RecoveryDetails

RecoveryDetails.propTypes = {
  currentRecovery: PropTypes.shape(),
}

RecoveryDetails.defaultProps = {
  currentRecovery: null,
}

import { Col, Descriptions, Row } from "antd";
import React, { useContext, useState } from "react";
import Profile from "../common/Profile";
import _,{ isEmpty } from "lodash";
import { formatStopTime } from "../../common/Common";
import I18n from "../../common/I18n";
import { momentTime } from "../../helpers/date_functions";
import moment from 'moment';
import AppConfig from "../../config/AppConfig";
import EndUserTitle from "../common/EndUserTitle";

const RecoveryDataTab = (props) => {
  const [recoveryData, setRecoveryData] = useState({
    ...props.customerDetails,
  });
  function formatAddress(address) {
    return _.compact([
      address.address_line1,
      address.address_line2,
      address.city,
      address.state,
      address.country,
      address.zipcode,
    ]).join(", ");
  }

  const {
    appointments: pickupAppointments = [],
    service_duration: serviceDuration,
    los_code: los,
  } = recoveryData?.locations?.[0];
  const pickupLocationObj = recoveryData?.locations ? _.find(recoveryData.locations, { type_of_order: 'PICKUP'}) : {}
  const pickupLocation = pickupLocationObj?.l_address;
  const pickupLocationAddress = pickupLocation
    ? formatAddress(pickupLocation)
    : "";
    const deliveryLocationObj = recoveryData?.locations ? _.find(recoveryData.locations, { type_of_order: 'DELIVERY'}) : {}
  const deliveryLocation =deliveryLocationObj?.l_address;
  const deliveryAddress = deliveryLocation
    ? formatAddress(deliveryLocation)
    : "";
  const ScheduledDate = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.appt_date
    : "";
  const pickupSchlStartTime = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.start_time
    : "";
  const pickupSchlEndTime = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.end_time
    : "";
    const pickupTz = pickupLocationObj?.tz_short_form;
    const pickupStatus = pickupLocationObj?.status;
    const deliveryStatus = deliveryLocationObj?.status;
    const driverInfo = recoveryData?.locations?.[0]?.driver_details?.[0];
    const pickupData = recoveryData?.locations?.[0];
    const pactualStartTime = pickupData?.actual_start_datetime;
    const pactualEndTime = pickupData?.actual_end_datetime;
    const timeFormat = props.isMilitaryTime ? "HH:mm" : "h:mm A";


    const formatTimeStamp = (dataObject, startTime, endTime, formatString) => {
      let startString = "";
      let endString = "";
      if (isEmpty(dataObject[startTime]) && isEmpty(dataObject[endTime])) {
        return "NA";
      }
      const scheduleDate = dataObject.scheduled_end_datetime
        ? moment(dataObject.scheduled_end_datetime).format("YYYY-MM-DD")
        : "";
      const skipDateCheck =
        isEmpty(scheduleDate) ||
        startTime === "scheduled_start_datetime" ||
        endTime === "scheduled_end_datetime";
      if (!isEmpty(dataObject[startTime])) {
        const startDate = moment(dataObject[startTime]).format("YYYY-MM-DD");
        const finalFormate =
          !skipDateCheck && startDate !== scheduleDate
            ? `${AppConfig.dateFormat} ${formatString}`
            : formatString;
        startString =
          startTime !== "scheduled_start_datetime"
            ? momentTime(
                finalFormate,
                dataObject[startTime],
                dataObject.timeZoneId
              )
            : moment(dataObject[startTime]).format(finalFormate);
      }
      if (!isEmpty(dataObject[endTime])) {
        const endDate = moment(dataObject[startTime]).format("YYYY-MM-DD");
        const finalFormate =
          !skipDateCheck && endDate !== scheduleDate
            ? `${AppConfig.dateFormat} ${formatString}`
            : formatString;
        endString =
          endTime !== "scheduled_end_datetime"
            ? momentTime(finalFormate, dataObject[endTime], dataObject.timeZoneId)
            : moment(dataObject[endTime]).format(finalFormate);
      }
      if (!isEmpty(dataObject[startTime]) && isEmpty(dataObject[endTime])) {
        endString = startString;
        startString = "Arr. at";
      }
      if (!isEmpty(dataObject[endTime]) && isEmpty(dataObject[startTime])) {
        startString = "Dept. at";
      }
      return `${startString} - ${endString} ${
        dataObject.tz_short_form ? dataObject.tz_short_form : ""
      }`;
    };


  return (
    <div>
      <Descriptions title="Recovery Details" bordered>
        <Descriptions.Item label="Pickup Address">
          {pickupLocationAddress}
        </Descriptions.Item>
        <Descriptions.Item label="Delivery Address" span={3}>
          {deliveryAddress}
        </Descriptions.Item>
        <Descriptions.Item
         label= {<EndUserTitle stringToAppend={I18n.t("general.details")} />}
        >
          <Profile
            profile={(({ first_name, last_name, email, phone }) => ({
              full_name: `${first_name !== null ? first_name : ""} ${
                last_name !== null ? last_name : ""
              }`,
              email,
              mobile_number: phone ? phone : "",
            }))(recoveryData.locations[0])}
            withLables={false}
            showImage={false}
            verifymedium={true}
          />
        </Descriptions.Item>
        <Descriptions.Item 
         label= {<EndUserTitle stringToAppend={I18n.t("general.details")} />}
         span={3}>
          <Profile
            profile={(({ first_name, last_name, email, phone }) => ({
              full_name: `${first_name !== null ? first_name : ""} ${
                last_name !== null ? last_name : ""
              }`,
              email,
              mobile_number: phone ? phone : "",
            }))(recoveryData?.locations[1] ? recoveryData.locations[1] : recoveryData?.wh_location)}
            withLables={false}
            showImage={false}
            verifymedium={true}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Scheduled Date" span={3}>
          {!isEmpty(ScheduledDate)
            ? moment(ScheduledDate).format("DD MMM, YYYY")
            : "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Pickup Timings Info" span={3}>
        <Row gutter={8}>
              <Col>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"SC :"} </b>
                    </span>
                    {!isEmpty(pickupSchlStartTime) ||
                    !isEmpty(pickupSchlEndTime) ? (
                      <React.Fragment>
                        {formatStopTime(
                          pickupSchlStartTime,
                          pickupSchlEndTime,
                          pickupTz
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"AT :"} </b>
                    </span>
                    {pickupData &&
                    (!isEmpty(pactualStartTime) || !isEmpty(pactualEndTime)) ? (
                      <React.Fragment>
                        {formatTimeStamp(
                          pickupData,
                          "actual_start_datetime",
                          "actual_end_datetime",
                          timeFormat
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
        </Descriptions.Item>
        <Descriptions.Item label="Pickup Status" span={isEmpty(deliveryStatus) ? 3 : 0}>
            {pickupStatus !== null ? <b>{pickupStatus}</b> : "NA"}
          </Descriptions.Item>
          {!isEmpty(deliveryStatus) &&
          <Descriptions.Item label="Delivery Status" span={3}>
            {deliveryStatus !== null ? <b>{deliveryStatus}</b> : "NA"}
          </Descriptions.Item>
          }
        <Descriptions.Item label="Level Of Service" span={3}>
          {!isEmpty(los) ? los : "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Service Duration" span={3}>
          {`${serviceDuration} (${"mins"})`}
        </Descriptions.Item>
        {!_.isEmpty(driverInfo) ? (
            <Descriptions.Item label="Driver Details">
              <Profile
                profile={(({
                  first_name,
                  last_name,
                  mobile_number,
                  employee_code,
                }) => ({
                  full_name: `${first_name !== null ? first_name : ""} ${
                    last_name !== null ? last_name : ""
                  } (${employee_code !== null ? employee_code : ""})`,
                  mobile_number: mobile_number ? mobile_number : "",
                }))(driverInfo)}
                withLables={false}
                showImage={false}
                verifymedium={true}
                hideMail={true}
              />
            </Descriptions.Item>
          ) : null}
      </Descriptions>
    </div>
  );
};
export default RecoveryDataTab;

/* eslint-disable import/prefer-default-export */
import React from 'react'
import _ from "lodash";
import { Icon } from "antd";
import BranchesIcon from '../components/common/BranchesIcon';

export const getReleaseStatusIcon = (status) => {
    switch (status) {
      case 'pending':
        return <Icon type="clock-circle" theme='twoTone' twoToneColor='#ed5857' />;
      case 'assigned':
        return <BranchesIcon/>;
      case 'released':
        return <Icon type="check-circle" theme='twoTone' twoToneColor='#008000' />;
      case 'completed':
        return <Icon type="check-circle" theme='twoTone' twoToneColor='#008000' />;
      case 'exception':
        return <Icon type="warning" className="textExceptional" />;  
      default:
        return null
    }
}

export const getReleaseSatusColor = (status) => {
  switch (status) {
    case "pending":
      return "#ed5857";
    case "released":
      return "#008000";
    case "completed":
      return "#008000";
  }
};

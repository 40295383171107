
export default {
  actionLabels: {
    ORDER_DELETED: "order_removed_from_route",
    ORDER_ADDED: "order_added_to_route",
    ROUTE_CREATED: "route_is_created",
    ROUTE_DELETED: "route_is_deleted",
    ORDER_MOVED: "order_moved_from_route",
    ROUTE_SWAP: "route_is_swapped",
    ROUTE_UNLOCK: "route_unlocked",
    ROUTE_LOCK: "route_locked",
    ROUTE_OPTIMIZE: "route_get_optimized",
    ROUTE_TIME_MANAGE: "route_start_time_changed_with_stick_start_time",
    ROUTE_LOCK_FOR_SEQUENCE: "route_locked_for_resequence",
    ROUTE_RESEQUENCE: "route_resequenced",
    REVERSE_STOP_SEQUENCE: "stop_sequence_reversed",
    ROUTE_REALLOCATE: "route_is_reallocated",
    ROUTE_ASSIGNED: "route_get_assigned",
    ROUTE_MENIFEST: "route_menifest_file_generated",
    STOP_DELETED: "stop_deleted_from_a_route",
    SERVICE_DURATION: "service_duration_updated",
    MOVE_STOP: "stop_moved_from_a_route",
    AUTO_PREPLAN: "created_auto_preplan",
    NAV_STOP_TERMINATED: "nav_stop_completed",
    NAV_ROUTE_TERMINATED: "nav_route_completed",
    STOP_DELETED: "nav_stop_deleted_from_route",
    STOP_ORDER_DELETED: "order_deleted_from_nav_route",
    ASSIGN_NOTIFICATION: "sended_assign_notification_to_driver",
    DRIVER_NOTIFICATION: "driver_notification_sended",
    REASSIGN_DRIVER: "route_got_reassigned_to_a_new_driver"
  },
};

import React, { createContext, useState, useEffect, useContext } from 'react';
import _, { set } from 'lodash';
import AppConfig from '../config/AppConfig';
import { fetchLocations } from '../api/LocationsApi';
import { renderAlertMessage, sortData } from '../helpers/common';
import { UserContext } from './UserContext';
import { AppLoadingScreen } from '../components/common/LogisticsLoadingScreen';
import { updateActiveWarehouse } from '../api/WarehouseApi';
import { fetchOrgAccounts, fetchShortFormAccounts } from '../api/Account';

const WarehouseContext = createContext({});

const WarehouseContextProvider = ({ children }) => {
  const [ warehouses, setWarehouses ] = useState([]);
  const [ selectedWarehouse, setSelectedWarehouse ] = useState("");
  const [ selectedWarehouses, setSelectedWarehouses ] = useState("");
  const [ isFetchingWarehouses, setIsFetchingWarehouses ] = useState(true);
  const [ isWarehouseMultiSelect, setIsWarehouseMultiSelect ] = useState(true); // change this to false if you want to disable multi select
  const [ searchCriteria, setSearchCriteria ] = useState({});
  const { currentUser, activeWarehouseid,currentOrg } = useContext(UserContext);
  const [accounts, setAccounts] = useState([]);
  const [warehouseWithAccounts, setWarehouseWithAccounts] = useState([]);
  const [currentWhHistoryData, setCurrentWhHistoryData] = useState([])
 

  const getWarehouses = async (cb = null) => {
    let warehouses = [];
    let selectedWarehouses = "";
    let selectedWarehouse = "";
    const orgId = _.get(currentOrg, 'id',null);
    try {
      setIsFetchingWarehouses(true);
      const result = await fetchLocations(AppConfig.warehouseCode, 1, null, false, null, currentUser);
      warehouses = result.success ? result.locations.locations : [];

      localStorage.setItem("warehouses", JSON.stringify(warehouses));
      // selectedWarehouses = sortData(warehouses, "name").map((warehouse) => warehouse.id)[ 0 ];
      selectedWarehouses = (activeWarehouseid && activeWarehouseid.length > 0) ?
      activeWarehouseid : sortData(warehouses, "name").map((warehouse) => warehouse.id)[0];

   

      if (isWarehouseMultiSelect) {
        if (localStorage.getItem("selectedWarehouses") === null) {
          localStorage.setItem("selectedWarehouses", selectedWarehouses);
        } else {
          selectedWarehouses = localStorage.getItem("selectedWarehouses") || [];
        }
      } else {
        if (localStorage.getItem("selectedWarehouse") === null) {
          localStorage.setItem("selectedWarehouse", selectedWarehouse);
        } else {
          selectedWarehouse = localStorage.getItem("selectedWarehouse");
          // filterWhs(selectedWarehouse);
        }
      }
    } catch (error) {
      // handle error
    } finally {
      setWarehouses(warehouses);
      setSelectedWarehouses(selectedWarehouses);
      setSelectedWarehouse(selectedWarehouse);
      setIsFetchingWarehouses(false);
      setWarehouseWithAccounts(warehouses.map((details) => {
        return {
          warehouseName : details.name,
          warehouseId : details.id,
          accounts :[]
        }
      }))
      // getAccounts(warehouses.map((details) => details.id));
      getAccounts(orgId);
    }
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    if(selectedWarehouses && selectedWarehouses.length > 0 && currentWhHistoryData.length === 0) {
      setCurrentSearchHistory();
    }
  }, [selectedWarehouses]);

  useEffect(()=>{
    if(currentWhHistoryData?.length > 0){
      setCurrentSearchHistory();
    }
  },[currentWhHistoryData])

  const getAccounts = (id) => {
    fetchOrgAccounts(id).then((result) => {
      if (result.success) {
        setAccounts(result.accounts);
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  useEffect(() => {
    if (!_.isEmpty(accounts)) {
      const updatedWarehouseWithAccounts = warehouseWithAccounts.map((wh) => {
        const whAccounts = [];
        accounts.forEach((account) => {
          if (account.warehouse_ids.includes(wh.warehouseId)) {
            whAccounts.push(account);
          }
        });
        return {
          ...wh,
          accounts: whAccounts,
        };
      });
      setWarehouseWithAccounts(updatedWarehouseWithAccounts);
    }
  }, [accounts]);

  // Function to receive warehouse id or ids and this function should return the respective accounts based on the incoming ids

  const findMatchingAccounts = (whId) => {
    const selectedWh = _.isArray(selectedWarehouses) ? selectedWarehouses[ 0 ] : selectedWarehouses;
    const data = _.isArray(whId) ? _.flattenDeep(whId) : whId;
    let matchingAccounts = [];

    if (_.isEmpty(data)) {
      matchingAccounts = warehouseWithAccounts.length > 0
        ? warehouseWithAccounts.find((wh) => wh.warehouseId === selectedWh)?.accounts
        : accounts;
    } else if (_.isArray(data)) {
      matchingAccounts = warehouseWithAccounts
        .filter((wh) => data.includes(wh.warehouseId))
        ?.map((wh) => wh?.accounts)
        .flat();
    } else {
      matchingAccounts = warehouseWithAccounts.find((wh) => wh.warehouseId === data)?.accounts;
    }
    return _.uniqBy(matchingAccounts, 'id');
  }
  const getWHDetails = () => {
    const selectedwh = _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
    ? selectedWarehouses[0]
    : selectedWarehouses;
    const selWh = _.find(warehouses, { id: selectedwh})
    return selWh;
  };

  const getSelectesWHs = () => {
    const selectedWhs = _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
    ? selectedWarehouses
    : selectedWarehouses
    ? [selectedWarehouses]
    : null;;
    return selectedWhs;
  };



  const setCurrentSearchHistory = () =>{
    const selectedwh = _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
      ? selectedWarehouses[0]
      : selectedWarehouses;
    const history = currentWhHistoryData?.length > 0 ? currentWhHistoryData : currentUser.search_history
    const orderSearchHistory = history
      .filter((item) => item.search_page === "orders")
      .map((item) => item.search_keys)
      .flat().filter((search_key) => search_key !== null && search_key !== undefined);

    const activeWhSearchHistory = orderSearchHistory.filter(
      (obj) => obj.warehouse_ids === selectedwh
    );

    const activeWhLatestSearch =
      activeWhSearchHistory[activeWhSearchHistory.length - 1];

    const searchHistory = {};
    // searchHistory.orders = activeWhLatestSearch;
    searchHistory.orders = {
      ...activeWhLatestSearch,
      search_type : "EQUALS"
    };
    setSearchCriteria(searchHistory);
    setCurrentWhHistoryData([])
  }
  
  const onWarehouseChange = async (warehouseVals) => {
    if(isWarehouseMultiSelect) {
      localStorage.setItem("selectedWarehouses", [warehouseVals]);
      setSelectedWarehouses([warehouseVals]);
    } else {
      localStorage.setItem("selectedWarehouse", warehouseVals);
      setSelectedWarehouse(warehouseVals);
    }
    try {
      const activeWarehouseIds = isWarehouseMultiSelect ? [warehouseVals] : warehouseVals;
      const response = await updateActiveWarehouse(activeWarehouseIds);
      const currentWhHistoryData = response.user?.search_history ? response.user.search_history : [];
      setCurrentWhHistoryData(currentWhHistoryData);
    } catch (error) {
    }
  };

  const contextValues = {
    warehouses,
    selectedWarehouse,
    setSelectedWarehouse,
    selectedWarehouses,
    setSelectedWarehouses,
    isFetchingWarehouses,
    isWarehouseMultiSelect,
    setIsWarehouseMultiSelect,
    onWarehouseChange,
    setWarehouses,
    setSearchCriteria,
    searchCriteria,
    getWHDetails,
    getSelectesWHs,
    warehouseWithAccounts,
    setWarehouseWithAccounts,
    findMatchingAccounts
  };

  return (
    <WarehouseContext.Provider value={ contextValues }>
      { isFetchingWarehouses ? <AppLoadingScreen /> : children }
    </WarehouseContext.Provider>
  );
};

export { WarehouseContext, WarehouseContextProvider };

import React from "react";
import { Row, Col, Link, Popover } from "../../common/UIComponents";
import CustomerOrderDetails from "./CustomerOrderDetails";
import Copyable from "../common/Copyable";
import { ellipseText, isEmpty, doFormate } from "../../common/Common";
import I18n from "../../common/I18n";
import PropTypes from "prop-types";

const OrderFieldDisplay = ({
  data,
  toDisplay,
  showTriggerEdit = false,
  editClick = () => { },
  refreshCallback = () => { },
  showEditIcon = false,
  tootlTipDelay = null,
  showPopupContainer = false,
  tab = null,
  charsToLimit = 10,
  showOrderDetailsByReference = false,
}) => {
  const isHawbOrMawb = ["hawb", "mawb"].includes(toDisplay);
  const isReference = ["reference_1", "reference_2"].includes(toDisplay);
  const isReferenceWithOrderLink = showOrderDetailsByReference && isReference;

  const renderCopyable = (text) =>
    text && (
      <Col>
        <Copyable text={text} />
      </Col>
    );

  const renderHawbMawbOrReference = () =>
    showTriggerEdit ? (
      <Row type="flex" gutter={8} style={{ flexFlow: "unset" }} align="middle">
        <Col>
        <Link onClick={() => editClick(data.id)}>
          {ellipseText(
            data[toDisplay],
            charsToLimit,
            true,
            tootlTipDelay,
            showPopupContainer
          )}
        </Link>
        </Col>
        &nbsp;
        <Col>
        {renderCopyable(data[toDisplay])}
        </Col>
      </Row>
    ) : (
      <CustomerOrderDetails
        displayedItem={data[toDisplay]}
        toShow={toDisplay}
        order_no={data.customer_order_number}
        order={{
          id: data.id,
          customer_order_number: data.customer_order_number,
        }}
        showEdit={showEditIcon}
        editClick={() => editClick(data.id)}
        refreshCallback={refreshCallback}
        tab={tab}
        showPopupContainer={showPopupContainer}
        charsToLimit={charsToLimit}
      />
    );

  const renderReference = () => (
    <Row type="flex" style={{ flexFlow: "unset" }} align="middle" gutter={8}>
      <Popover
        title={I18n.t("order.reference_details")}
        content={
          <div>
            {doFormate(data[`${toDisplay}_type`])} :{" "}
            {doFormate(data[toDisplay])}
          </div>
        }
        placement="topLeft"
        getPopupContainer={
          showPopupContainer ? (triggerNode) => triggerNode.parentNode : null
        }
        mouseEnterDelay={tootlTipDelay}
      >
        <Col>
          {(!isEmpty(data[`${toDisplay}_type`]) || !isEmpty(data[toDisplay])) &&
            ellipseText(data[toDisplay], charsToLimit, false)}
        </Col>
      </Popover>
      {renderCopyable(data[toDisplay])}
    </Row>
  );

  if (isHawbOrMawb || isReferenceWithOrderLink) {
    return renderHawbMawbOrReference();
  } else if (isReference) {
    return renderReference();
  }

  return null;
};

export default OrderFieldDisplay;

OrderFieldDisplay.propTypes = {
  data: PropTypes.object.isRequired,
  toDisplay: PropTypes.string.isRequired,
  showTriggerEdit: PropTypes.bool,
  editClick: PropTypes.func,
  refreshCallback: PropTypes.func,
  showEditIcon: PropTypes.bool,
  tootlTipDelay: PropTypes.number,
  showPopupContainer: PropTypes.bool,
  tab: PropTypes.string,
  charsToLimit: PropTypes.number,
  showOrderDetailsByReference: PropTypes.bool,
};

OrderFieldDisplay.defaultProps = {
  showTriggerEdit: false,
  editClick: () => { },
  refreshCallback: () => { },
  showEditIcon: false,
  tootlTipDelay: null,
  showPopupContainer: false,
  tab: null,
  charsToLimit: 10,
  showOrderDetailsByReference: false,
};

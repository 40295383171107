import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../common/I18n';
import { Row, Col, Icon } from '../../common/UIComponents';
import { checkServiceExistance } from '../../helpers/common';
import EditIcon from '../common/EditIcon';

const OrderTitle = ({
  order, showEdit, editClick
}) => order &&
<Row >
  <Col
    xs={11}
    style={{ float: 'left' }}
  >
    {I18n.t('order.details')}
    {/* {order.customer_order_number ? ` (#${order.customer_order_number})` : ''} */}
  </Col>
  <Col
    xs={showEdit ? 11 : 13}
    style={{ paddingRight: 40 }}
    className="alignLeft textBold "
  >
    {order.customer_order_number ? ` #${order.customer_order_number}` : ''}{order.status ? <span className={order.status === 'EXCEPTION' ? 'textRed' : ''}> - {order.status}</span> : ''}
  </Col>
  { showEdit && checkServiceExistance('SCA') && <Col
    xs={2}
    className="alignLeft textBold " style={{ paddingTop: 2 }}
  >
    <span className="cursorPointer" onClick={editClick}>
      <EditIcon/>
     Edit</span>
  </Col>
  }
</Row>;

OrderTitle.propTypes = {
  order: PropTypes.shape().isRequired,
  editClick: PropTypes.func,
  showEdit: PropTypes.bool,
};

export default OrderTitle;

import React  from "react";
import moment from "moment";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import {
  DatePicker,
  Checkbox,
  Row,
  Col,
  FormItem,
  Radio,
} from "../../common/UIComponents";
import {
  getDisabledHours,
  getDisabledMinutes,
} from "../../helpers/date_functions";
import AppConfig from "../../config/AppConfig";
import ConfiguredDeliveryTime from "../common/ConfiguredDeliveryTime";
import MaterialTimePicker from "./MaterialTimePicker";

const OpinionForm = (props) => {
  const {
    rowPosition,
    record,
    itemOnChange,
    itemOptionOnChange,
    confirmChange,
    clearPreference,
    isDisabled,
    feasibleDow = [],
    feasibleDates = [],
    deliveryZipcodes,
    showClear,
    isMobile = false,
    disabledStartHours = [],
    disabledEndHours = [],
    endHour,
    endMinute,
    optingType = "checkbox",
    showExpedite = false,
    isMilitaryTime = false,
    startHour,
    startMinute,
  } = props;


  return record ? (
    <Row className="preferenceForm">
      <Col xs={ 24 }>
        <Row gutter={ 10 }>
          { false && (
            <Col xs={ 24 } className="alignRight">
              <Checkbox
                onChange={ (e) =>
                  confirmChange(rowPosition, "confirmed", e.target.checked)
                }
                checked={ record.confirmed === true }
                disabled={ isDisabled }
              />
              &nbsp;
              { I18n.t("appointmentForm.confirmed") }
            </Col>
          ) }
          { !isDisabled && showClear !== false && (
            <Col xs={ 24 } className="alignRight">
              <span onClick={ (e) => clearPreference(rowPosition) }>
                <u className="text-decoration-dashed cursorPointer">
                  { I18n.t("general.clear") }
                </u>
              </span>
            </Col>
          ) }
        </Row>
      </Col>

      <Col xs={ 24 }>
        <Row gutter={ 10 } style={ { paddingTop: 10 } }>
          <Col xs={ 3 } className="paddingTop15">
            Date
          </Col>
          <Col xs={ 21 } style={ { paddingTop: 10 } }>
            <DatePicker
              size="small"
              style={ { width: "100%", marginLeft: "1%" } }
              placeholder="Select Date"
              format="Do MMM YYYY"
              onChange={ (e) => itemOnChange(rowPosition, "item_preference", e) }
              value={
                record.item_preference ? moment(record.item_preference) : null
              }
              defaultValue={ null }

              // current => current && ((current <= moment(moment().add(24, 'hours'), 'YYYY-MM-DD') || current > moment(moment().add(31, 'days'), 'YYYY-MM-DD')) || (!feasibleDow.includes(moment(current).format('dddd'))))
              disabledDate={ (current) =>
                deliveryZipcodes
                  ? (isMobile ?
                    (current &&
                      (current <= moment() ||
                        current >
                        moment(moment().add(31, "days"), "YYYY-MM-DD") ||
                      !feasibleDow.includes(moment(current).format("dddd")))
                    ) :
                    (current &&
                      (current <=
                        moment(
                          moment().add(
                            AppConfig.orderFieldDuration - 24,
                            "hours"
                          ),
                          "YYYY-MM-DD"
                        ) ||
                        current >
                        moment(moment().add(31, "days"), "YYYY-MM-DD") ||
                      !feasibleDow.includes(moment(current).format("dddd")))
                    ))
                  : (
                    isMobile ?
                      current && current <= moment()
                      :
                      current && current <=
                      moment(
                        moment().add(
                          AppConfig.orderFieldDuration - 24,
                          "hours"
                        ),
                        "YYYY-MM-DD"
                      )
                  )
              }
              className="bottomBorder readOnlyFields"
              disabled={ isDisabled }
            />
          </Col>
        </Row>
      </Col>
      {
        !isMobile  && <Col xs={24} className="paddingTop20">
        <ConfiguredDeliveryTime />
      </Col>
      }
      <Col xs={ 24 } style={ { paddingTop: 10 } }>
        <Radio.Group
          onChange={ (e) => {
            itemOptionOnChange(
              rowPosition,
              "item_option",
              e.target.value,
              true,
              "radio"
            );
          } }
          value={ record.item_option.length > 0 ? record.item_option[ 0 ] : "" }
        >
          <Row gutter={ 10 }>
            <Col xs={ 5 }>
              <Row>
                <Col xs={ 24 } className="alignCenter">
                  <Checkbox
                    onChange={ (e) =>
                      itemOptionOnChange(
                        rowPosition,
                        "item_option",
                        "ANY",
                        e.target.checked,
                        optingType
                      )
                    }
                    checked={ record.item_option.includes("ANY") }
                    disabled={ isDisabled }
                  />
                </Col>
                <Col xs={ 24 } className="alignCenter">
                  { I18n.t("appointmentForm.any") }
                </Col>
                <Col xs={ 24 } className="alignCenter time">
                  { isMobile
                    ? isMilitaryTime ? I18n.t("appointmentForm.consignee_any_military_time") : I18n.t("appointmentForm.consignee_any_time")
                    : isMilitaryTime ? I18n.t("appointmentForm.military_any_time") : I18n.t("appointmentForm.any_time") }
                </Col>
              </Row>
            </Col>
            <Col xs={ 5 }>
              <Row>
                <Col xs={ 24 } className="alignCenter">
                  <Checkbox
                    onChange={ (e) =>
                      itemOptionOnChange(
                        rowPosition,
                        "item_option",
                        "M",
                        e.target.checked,
                        optingType
                      )
                    }
                    checked={ record.item_option.includes("M") }
                    disabled={ isDisabled }
                  />
                </Col>
                <Col xs={ 24 } className="alignCenter">
                  { I18n.t("appointmentForm.morning") }
                </Col>
                <Col xs={ 24 } className="alignCenter time">
                  {/* { I18n.t("appointmentForm.morning_time") } */}
                  {isMilitaryTime ? I18n.t("appointmentForm.military_morning_time") : I18n.t("appointmentForm.morning_time")}
                </Col>
              </Row>
            </Col>
            <Col xs={ 5 }>
              <Row>
                <Col xs={ 24 } className="alignCenter">
                  <Checkbox
                    onChange={ (e) =>
                      itemOptionOnChange(
                        rowPosition,
                        "item_option",
                        "A",
                        e.target.checked,
                        optingType
                      )
                    }
                    checked={ record.item_option.includes("A") }
                    disabled={ isDisabled }
                  />
                </Col>
                <Col xs={ 24 } className="alignCenter">
                  { I18n.t("appointmentForm.afternoon") }
                </Col>
                <Col xs={ 24 } className="alignCenter time">
                  {isMilitaryTime ? I18n.t("appointmentForm.military_afternoon_time") : I18n.t("appointmentForm.afternoon_time")}
                </Col>
              </Row>
            </Col>
            <Col xs={ 5 }>
              <Row>
                <Col xs={ 24 } className="alignCenter">
                  <Checkbox
                    onChange={ (e) =>
                      itemOptionOnChange(
                        rowPosition,
                        "item_option",
                        "E",
                        e.target.checked,
                        optingType
                      )
                    }
                    checked={ record.item_option.includes("E") }
                    disabled={ isDisabled }
                  />
                </Col>
                <Col xs={ 24 } className="alignCenter">
                  { I18n.t("appointmentForm.evening") }
                </Col>
                <Col xs={ 24 } className="alignCenter time">
                  {/* { I18n.t("appointmentForm.evening_time") } */}
                  {isMilitaryTime ? I18n.t("appointmentForm.military_evening_time") : I18n.t("appointmentForm.evening_time")}
                </Col>
              </Row>
            </Col>
            <Col xs={ 4 }>
              <Row>
                <Col xs={ 24 } className="alignCenter">
                  <Checkbox
                    onChange={ (e) =>
                      itemOptionOnChange(
                        rowPosition,
                        "item_option",
                        "CUSTOM",
                        e.target.checked,
                        optingType
                      )
                    }
                    checked={ record.item_option.includes("CUSTOM") }
                    disabled={ isDisabled }
                  />
                </Col>
                <Col xs={ 24 } className="alignCenter">
                  { I18n.t("appointmentForm.custom") }
                </Col>
                {/* <Col xs={24} className="alignCenter time">
            {I18n.t('general.from')} - {I18n.t('general.to')}

        </Col> */}
              </Row>
            </Col>
          </Row>
        </Radio.Group>
      </Col>
      <Col xs={ 24 } className="marginTop10" style={ { paddingTop: 10 } }>
        <Row gutter={ 10 }>
          <Col md={ 12 }>
            <FormItem label={ `From time ${isMilitaryTime ? "(HH:mm)" : "(h:mm AM/PM)"}` } className="timepickerContainer">
              <MaterialTimePicker
                onChange={ (e) => itemOnChange(rowPosition, "start_time", e) }
                value={ record.start_time ? moment(record.start_time) : null }
                computeDisabledHours={ () =>
                  record.start_time || record.item_preference ?
                    getDisabledHours("start_time", disabledStartHours, record) :
                    []
                }
                computeDisabledMinutes={ (pickedTime) => {
                  const selectedHour = pickedTime ? pickedTime.hour() : 0;
                  return record.start_time || record.item_preference ?
                    getDisabledMinutes(
                      "start_time",
                      selectedHour,
                      record,
                      endHour,
                      endMinute,
                      startHour,
                      startMinute,
                    )
                    :
                    [];
                }
                }
                isMilitaryTime={ isMilitaryTime }
                disabled={ isDisabled }
              />
            </FormItem>
          </Col>
          <Col md={ 12 }>
            <FormItem label={ `To time ${isMilitaryTime ? "(HH:mm)" : "(h:mm AM/PM)"}` } className="timepickerContainer">
              <MaterialTimePicker
                onChange={ (e) => itemOnChange(rowPosition, "end_time", e) }
                value={ record.end_time ? moment(record.end_time) : null }
                computeDisabledHours={ () =>
                  record.end_time || record.item_preference ?
                    getDisabledHours("end_time", disabledEndHours, record) :
                    []
                }
                computeDisabledMinutes={ (pickedTime) => {
                  const selectedHour = pickedTime ? pickedTime.hour() : 0;
                  return record.end_time || record.item_preference ?
                    getDisabledMinutes("end_time", selectedHour, record, endHour, endMinute,
                      startHour,
                      startMinute,) :
                    [];
                }}
                isMilitaryTime={ isMilitaryTime }
                disabled={ isDisabled }
              />
            </FormItem>
          </Col>
        </Row>
      </Col>
      { showExpedite && (
        <Col xs={ 24 }>
          <Checkbox
            onChange={ (e) =>
              itemOnChange(rowPosition, "has_expedite", e.target.checked)
            }
            checked={ record.has_expedite }
            disabled={ isDisabled }
          >
            { I18n.t("order.expedite_delivery") }
          </Checkbox>
        </Col>
      ) }
    </Row>
  ) : null;
}




OpinionForm.propTypes = {
  rowPosition: PropTypes.number.isRequired,
  itemOnChange: PropTypes.func.isRequired,
  record: PropTypes.shape().isRequired,
  itemOptionOnChange: PropTypes.func.isRequired,
  confirmChange: PropTypes.func.isRequired,
  clearPreference: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  feasibleDow: PropTypes.arrayOf(PropTypes.string),
  feasibleDates: PropTypes.arrayOf(PropTypes.string),
  deliveryZipcodes: PropTypes.bool,
  isMobile: PropTypes.bool,
  disabledEndHours: PropTypes.arrayOf(PropTypes.number),
  disabledStartHours: PropTypes.arrayOf(PropTypes.number),

  // isNew: PropTypes.bool,
};

OpinionForm.defaultProps = {
  feasibleDow: [],
  feasibleDates: [],
  isDisabled: false,
  deliveryZipcodes: true,
  isMobile: false,
  disabledStartHours: [],
  disabledEndHours: [],
  // isNew: true,
};

export default OpinionForm;

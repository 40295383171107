
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

const SettingIcon = (props) => {
  const { handleClick,color="#0b3d33" } = props; // 08979C
  return (
    <>
      <Icon
        type="setting"
        theme="twoTone"
        twoToneColor={color}
        // style={{ fontSize: "medium" }}
        onClick={handleClick}
      />
    </>
  );
};
SettingIcon.propTypes = {
  handleClick: PropTypes.func,
};

SettingIcon.defaultProps = {
  handleClick: () => {},
};
export default SettingIcon;

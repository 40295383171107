import React from "react";
import BaseList from "../BaseList";
import TypeOfOrder from "../orders/TypeOfOrder";
import { formatByTimeConfig } from "../../helpers/common";
import { momentTime } from "../../helpers/date_functions";
import moment from "moment/moment";

class BillingActivitiesList extends BaseList {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Order Number',
                dataIndex: 'customer_order_number',
                key: 'customer_order_number',
            },
            {
                title: 'Order Type',
                dataIndex: 'type_of_order',
                key: 'type_of_order',
                render: (data, record) => (
                    <div>
                        <TypeOfOrder
                            order={record}
                            orderTypeKey="type_of_order"
                            relatedOrderKey="related_order"
                            placement="topLeft"
                            showBadge={true}
                        />
                    </div>
                )
            },
            {
                title: 'Account Code',
                dataIndex: 'account_code',
                key: 'account_code',
            },
            {
                title: 'Level Of Service',
                dataIndex: 'los_code',
                key: 'los_code',
            },
            {
                title: 'Billing Amount',
                dataIndex: 'billing_amount',
                key: 'billing_amount',
            },
            {
                title: 'Activity By',
                dataIndex: 'activity_by',
                key: 'activity_by',
            },
            {
                title: 'Activity At',
                dataIndex: 'activity_at',
                key: 'activity_at',
                render: (text, record) => {
                    return (
                        <div>
                            {
                                formatByTimeConfig(
                                    moment.utc(text),
                                    false,
                                    'DD MMM  HH:mm',
                                    'DD MMM  hh:mm A',
                                )
                            }
                        </div>
                    );
                }
            },
            {
                title: 'Action Type',
                dataIndex: 'action_type',
                key: 'action_type',
            },

        ];
    }
}
export default BillingActivitiesList;

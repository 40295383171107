/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Select,
  InputNumber,
} from "../../common/UIComponents";
import {alertMessage, doValidate, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import { createItem, updateItem } from "../../api/OrdersApi";
import AppConfig from "../../config/AppConfig";
import { fetchAccountDims } from "../../api/Account";
import userStore from "../../stores/UserStore";

const { TextArea } = Input;

const requiredFields = [
  {
    form_field: "item_name",
    ui_name: I18n.t("order.item_name"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "item_weight",
    ui_name: I18n.t("order.weight"),
    isRequired: true,
    inputType: "price",
  },
  {
    form_field: "item_type",
    ui_name: I18n.t("order.item_type"),
    isRequired: false,
    inputType: "text",
  },
];
const dimFields = [
  {
    form_field: "item_length",
    ui_name: I18n.t("order.length"),
    isRequired: true,
    inputType: "price",
  },
  {
    form_field: "item_width",
    ui_name: I18n.t("order.width"),
    isRequired: true,
    inputType: "price",
  },
  {
    form_field: "item_height",
    ui_name: I18n.t("order.height"),
    isRequired: true,
    inputType: "price",
  },
];
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItem: this.props.item,
      inProgress: false,
      errors: [],
      isNew: this.props.isItemNew,
      order: this.props.order,
      account: this.props.account,
      accountDim: {},
      items: this.props.items ? this.props.items : [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (_.isObject(props.order) && Object.keys(props.order).length > 0) {
      newState.order = props.order;
    }
    if (_.isArray(props.items) && state.items.length !== props.items.length) {
      newState.items = props.items;
    }
    return newState;
  }

  setDimValues = () => {
    if (isEmpty(this.state.currentItem.dim_factor)) {
      this.handleOnChange("dim_factor", this.state.accountDim.dim_factor);
    }
  };

  getAccountConfigs = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    const accountId =
      this.state.account.primary_account_id || this.state.account.id;
    fetchAccountDims(orgId, accountId).then((result) => {
      if (result.success) {
        const accountDims = result.configurations || [];
        const dim = accountDims.length > 0 ? accountDims[0] : {};
        this.setState(
          {
            accountDim: dim,
          },
          () => {
            this.setDimValues();
          }
        );
      }
    });
  };

  clearForm = () => {
    this.setState({
      currentItem: {},
      isNew: true,
    });
  };

  componentDidMount() {
    this.getAccountConfigs();
    if (this.state.isNew) {
      this.setItemType();
    }
  }

  setItemType = () => {
    if (this.state.order.type_of_order !== "X") {
      this.setState({
        currentItem: Object.assign({}, this.state.currentItem, {
          item_type:
            // this.state.order.type_of_order === "R" ||
            // this.state.order.type_of_order === "T"
              ['R', 'T', 'LH'].includes(this.state.order.type_of_order)
              ? "RETURN"
              : this.state.order.type_of_order === "D"
              ? "DELIVERY"
              : "",
        }),
      });
    }
  };

  handleOnChange = (element, value) => {
    this.setState(
      {
        currentItem: Object.assign({}, this.state.currentItem, {
          [element]: value,
        }),
      },
      () => {
        this.calculateDimValues();
      }
    );
  };

  calculateDimValues = () => {
    let dimWeight = 0;
    const dimFactor = !isEmpty(this.state.currentItem.dim_factor)
      ? this.state.currentItem.dim_factor
      : 1;
    const width = !isEmpty(this.state.currentItem.item_width)
      ? this.state.currentItem.item_width
      : 1;
    const height = !isEmpty(this.state.currentItem.item_height)
      ? this.state.currentItem.item_height
      : 1;
    const length = !isEmpty(this.state.currentItem.item_length)
      ? this.state.currentItem.item_length
      : 1;
    const weight = !isEmpty(this.state.currentItem.item_weight)
      ? this.state.currentItem.item_weight
      : 0;
    if (
      (!isEmpty(this.state.currentItem.item_width) ||
        !isEmpty(this.state.currentItem.item_height) ||
        !isEmpty(this.state.currentItem.item_length)) &&
      !isEmpty(this.state.currentItem.dim_factor)
    ) {
      const configuredMinWeight = !isEmpty(this.state.accountDim.min_weight)
        ? this.state.accountDim.min_weight
        : 0;
      if ((configuredMinWeight === 0 || configuredMinWeight < weight) && dimFactor > 0) {
        dimWeight = ((width * height * length) / dimFactor).toFixed(2);
      } else {
        dimWeight = 0;
      }
    }
    this.setState({
      currentItem: Object.assign({}, this.state.currentItem, {
        dim_weight: dimWeight,
      }),
    });
  };

  handleSaveItem = () => {
    const { currentItem, account } = this.state;
    currentItem.account_id = account.id;
    this.setState({ inProgress: true, errors: [] });
    let dimErrors = [];
    const errors = doValidate(requiredFields, currentItem);
    if (!isEmpty(currentItem.dim_factor)) {
      dimErrors = doValidate(dimFields, currentItem);
    } else if (!isEmpty(this.state.accountDim.dim_factor)) {
      dimErrors.push(
        I18n.t("errors.field_required", {
          field: I18n.t("configurations.dim_factor"),
        })
      );
    }
    const totalErrors = [].concat(errors, dimErrors);
    if (totalErrors.length === 0) {
      updateItem(currentItem, this.state.isNew).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState({ inProgress: false, errors: [] });
          this.props.saveItem(currentItem);
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ inProgress: false });
        }
      });
    } else {
      this.setState({ errors: totalErrors, inProgress: false });
    }
  };

  getOrderID = () => {
    const { order, currentItem } = this.state;
    const { orderConfig } = this.props;
    let orderId = "";
    let itemType = "";
    if (["T", "LH"].includes(order.type_of_order)) {
      itemType = currentItem.item_type === "RETURN" ? "TR" : "TD";
    } else if (order.type_of_order === "X") {
      itemType = "X";
    } else {
      itemType = currentItem.item_type === "RETURN" ? "R" : "D";
    }
    const detailsObj = _.find(orderConfig.orderTypes[order.type_of_order].types, {
      type_of_order: itemType,
    });
    if (!isEmpty(detailsObj)) {
      orderId = order[detailsObj.key] ? order[detailsObj.key].id : "";
    }
    return orderId;
  };

  handleCreateItem = () => {
    const { currentItem, account } = this.state;
    currentItem.account_id = account.id;
    this.setState({ inProgress: true, errors: [] });
    let dimErrors = [];
    const errors = doValidate(requiredFields, currentItem);
    if (!isEmpty(currentItem.dim_factor)) {
      dimErrors = doValidate(dimFields, currentItem);
    } else if (!isEmpty(this.state.accountDim.dim_factor)) {
      dimErrors.push(
        I18n.t("errors.field_required", {
          field: I18n.t("configurations.dim_factor"),
        })
      );
    }
    const isLastItem = this.checkLastItem();
    if (isEmpty(currentItem.customer_order_id)) {
      currentItem.customer_order_id = this.getOrderID(); // this.state.order.id;
    }
    const totalErrors = [].concat(errors, dimErrors);
    if (totalErrors.length === 0) {
      createItem(currentItem, this.state.isNew).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          const savedItems = result.order_items || [];
          const items = savedItems.map((item) =>
            Object.assign({}, item, { item_id: item.id })
          );
          this.setState(
            {
              inProgress: false,
              errors: [],
              currentItem: {},
              isNew: true,
            },
            () => {
              this.setItemType();
            }
          );
          this.props.saveItem(items, isLastItem);
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ inProgress: false });
        }
      });
    } else {
      this.setState({ errors: totalErrors, inProgress: false });
    }
  };

  checkLastItem = () => {
    const { isNew, items, order } = this.state;
    const totalItems = items || [];
    return isNew && order.quantity - 1 === totalItems.length;
  };

  render() {
    const { currentItem, order } = this.state;
    const isLastItem = this.checkLastItem();
    return (
      <div className="marginTop10 item-form">
        <Row gutter={16}>
          <Col xs={24}>
            <Row gutter={16} className="marginTop10">
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem label={I18n.t("general.name")} require>
                  <Input
                    value={currentItem.item_name}
                    onChange={(e) =>
                      this.handleOnChange("item_name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem label={I18n.t("order.pcs")}>
                  <InputNumber
                    value={currentItem.item_quantity}
                    onChange={(e) => this.handleOnChange("item_quantity", e)}
                    precision={0}
                    className="width100Per"
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem label={I18n.t("order.model_no")}>
                  <Input
                    value={currentItem.item_model}
                    onChange={(e) =>
                      this.handleOnChange("item_model", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem label={I18n.t("order.serial_no")}>
                  <Input
                    value={currentItem.serial_number}
                    onChange={(e) =>
                      this.handleOnChange("serial_number", e.target.value)
                    }
                  />
                </FormItem>
              </Col>

              {false && (
                <Col sm={24} xs={24} md={8} lg={8}>
                  <FormItem label={I18n.t("order.item_type")}>
                    <Select
                      style={{ width: "100%" }}
                      onChange={(e) => this.handleOnChange("item_type", e)}
                      defaultValue="Item Type"
                      value={currentItem.item_type}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {["T", "LH"].includes(order.type_of_order)
                        ? Object.keys(AppConfig.transferItemTypes).map(
                            (key) => (
                              <Select.Option value={key} key={key}>
                                {AppConfig.transferItemTypes[key]}
                              </Select.Option>
                            )
                          )
                        : Object.keys(AppConfig.itemTypes).map((key) => (
                            <Select.Option value={key} key={key}>
                              {AppConfig.itemTypes[key]}
                            </Select.Option>
                          ))}
                    </Select>
                  </FormItem>
                </Col>
              )}
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem
                  label={`${I18n.t("order.weight")} ${
                    currentItem.item_weight_uom
                      ? `(${currentItem.item_weight_uom})`
                      : ""
                  }`}
                  require
                >
                  <Input
                    value={currentItem.item_weight}
                    onChange={(e) =>
                      this.handleOnChange("item_weight", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem label={`${I18n.t("order.wh_dock")}`}>
                  <Input
                    value={currentItem.wh_dock}
                    onChange={(e) =>
                      this.handleOnChange("wh_dock", e.target.value)
                    }
                    className="width100Per"
                    precision={0}
                    step="1"
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem
                  label={`${I18n.t("order.length")}   ${
                    currentItem.item_length_uom
                      ? `(${currentItem.item_length_uom})`
                      : ""
                  }`}
                >
                  <Input
                    value={currentItem.item_length}
                    onChange={(e) =>
                      this.handleOnChange("item_length", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem
                  label={`${I18n.t("order.width")} ${
                    currentItem.item_width_uom
                      ? `(${currentItem.item_width_uom})`
                      : ""
                  }`}
                >
                  <Input
                    value={currentItem.item_width}
                    onChange={(e) =>
                      this.handleOnChange("item_width", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem
                  label={`${I18n.t("order.height")} ${
                    currentItem.item_height_uom
                      ? `(${currentItem.item_height_uom})`
                      : ""
                  }`}
                >
                  <Input
                    value={currentItem.item_height}
                    onChange={(e) =>
                      this.handleOnChange("item_height", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              {!isEmpty(this.state.accountDim.dim_factor) && (
                <Col className="notes_content marginBottom10">
                  Configured DIM values for the Account{" "}
                  <span className="">{this.state.account.name}</span>:{" "}
                  <span className="textBold">
                    {I18n.t("configurations.dim_factor")}:{" "}
                    {this.state.accountDim.dim_factor}
                  </span>
                  , &nbsp;&nbsp;
                  <span className="textBold">
                    {I18n.t("configurations.dim_min_weight_label")} (
                    {AppConfig.weight_unit}) :{" "}
                    {!isEmpty(this.state.accountDim.min_weight)
                      ? this.state.accountDim.min_weight
                      : "NA"}
                  </span>
                </Col>
              )}
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem label={`${I18n.t("configurations.dim_factor")}`}>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => this.handleOnChange("dim_factor", e)}
                    defaultValue={I18n.t("configurations.dim_factor")}
                    value={currentItem.dim_factor}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch={false}
                  >
                    {AppConfig.dimValues.map((key) => (
                      <Select.Option value={key} key={key}>
                        {key}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={8} lg={8}>
                <FormItem label={`${I18n.t("configurations.dim_weight")}`}>
                  <Input
                    value={currentItem.dim_weight}
                    onChange={(e) =>
                      this.handleOnChange("dim_weight", e.target.value)
                    }
                    disabled
                  />
                </FormItem>
              </Col>

              <Col xs={24}>
                <FormItem label={I18n.t("general.description")}>
                  <TextArea
                    rows={4}
                    placeholder={I18n.t("general.description")}
                    value={currentItem.item_description}
                    onChange={(e) =>
                      this.handleOnChange("item_description", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.state.isNew ? this.handleCreateItem : this.handleSaveItem,
              this.clearForm,
              true,
              true,
              "default",
              !isLastItem
                ? I18n.t("general.save")
                : I18n.t("general.save_n_close"),
              I18n.t("general.clear")
            )}
          </Col>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

Form.propTypes = {
  saveItem: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
  isItemNew: PropTypes.bool.isRequired,
  order: PropTypes.shape().isRequired,
};

export default Form;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col,Input, Row, Button, Icon, Typography } from '../../common/UIComponents';
import {  alertMessage, findTotal, doValidate, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import InvoicesItemList from './InvoicesItemList';
import { changeInvoiceStatus, deleteInvoiceLine, updateInvoice } from '../../api/Billing';
import { fetchAccountAccessorialWithParams } from '../../api/Account';
import BaseModal from '../BaseModal';
import InvoiceHeader from './DriverHeader';
import { renderAlertMessage } from '../../helpers/common';


const { TextArea } = Input;

const nextsteps = {
  [I18n.t('invoices.ready_for_billing')]: 'APPROVED',
  APPROVED: 'PAYMENT_CONFIRMED',
  PAYMENT_CONFIRMED: 'CLOSED',
};

const requiredFields = [{
  form_field: 'invoice_name',
  ui_name: I18n.t('invoices.description'),
  isRequired: true,
  inputType: 'text',
},
{
  form_field: 'invoice_value',
  ui_name: I18n.t('invoices.amount'),
  isRequired: true,
  inputType: 'price',
},
];

class DriverPayDetails extends Component {
  constructor(props) {
    super(props);
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.state = {
      account: this.props.account ? this.props.account : {},
      invoice: this.props.currentInvoice || {},
      inProgress: false,
      errors: [],
      currentStatus: '',
      disableRemarksButton: true,
      comments: '',
      accessorials: [],
      decimalPoints,
      zeroValue,
    };
  }

  componentDidMount() {
    this.getAccessorials();
  }
  getAccessorials = () => {
    const account = this.state.invoice.account_details ? this.state.invoice.account_details : {};
    fetchAccountAccessorialWithParams(account.id, 'DISPATCHER')
      .then((result) => {
        if (result.success) {
          this.setState({
            accessorials: result.accessorials,
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  findPriceTotal = () => {
    const { invoice } = this.state;
    let totalAmount = 0;
    ['transportation_charges', 'accessorial_charges', 'adjustment_charges', 'driver_accessorial_charges'].forEach((descriptionEle) => {
      const priceList = invoice[descriptionEle].map(order => order.price_list);
      const flattenList = _.flatten(priceList);
      const amount = parseFloat(findTotal(flattenList, 'invoice_value'));
      totalAmount += amount;
    });
    this.handleOnChange('total_amount', totalAmount);
  }

  findSectionTotal = (sectionType, orderNo) => {
    const { invoice, zeroValue } = this.state;
    const charges = [...invoice[sectionType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      const priceList = !isEmpty(charges[elementIndex].price_list) ? charges[elementIndex].price_list : [];
      // const flattenList = _.flatten(priceList);
      const amount = (findTotal(priceList, 'invoice_value', 1, true));
      return amount;
    }
    return zeroValue;
  }

  handleOnChange = (element, value, cb = null) => {
    this.setState({
      invoice: Object.assign({}, this.state.invoice, { [element]: value }),
    }, cb);
  }

  handleOnItemsChange = (recordNO, element, value, itemType, orderNo) => {
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      charges[elementIndex].price_list[recordNO][element] = value;
      this.handleOnChange(itemType, charges, () => {
        this.findPriceTotal();
      });
    }
  }


  addItem = (itemType, orderNo) => {
    const charges = [...this.state.invoice[itemType]];
    const index = _.findIndex(charges, { order_number: orderNo });
    if (index >= 0) {
      const data = {
        invoice_name: '',
        invoice_value: '',
        invoice_quantity: 0,
        // sign: 'CR',
        isNew: true,
        invoice_type: I18n.t(`invoices.invoice_types.${itemType}`),

      };
      if (itemType === 'accessorial_charges' || itemType === 'driver_accessorial_charges') {
        data.accessorial_key = '';
        data.order_id = charges[index].order_id;
      }
      charges[index].price_list.push(data);
      this.handleOnChange(itemType, charges);
    }
  }

  handleDeleteclick = (recordNO, itemType, orderNo) => {
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      const cuttentInvoiceItem = charges[elementIndex].price_list ? charges[elementIndex].price_list[recordNO] : {};
      if (cuttentInvoiceItem.id) {
        this.handleDeleteInvoiceItem(cuttentInvoiceItem.id);
      } else {
        charges[elementIndex].price_list.splice(recordNO, 1);
        this.setState({
          errors: [],
        });
        this.handleOnChange(itemType, charges, () => {
          this.findPriceTotal();
        });
      }
    }
  }


  handleDeleteInvoiceItem = (id) => {
    this.setState({ inProgress: true });
    deleteInvoiceLine(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.props.getInvoiceDetails(this.state.invoice.customer_order_id);
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleSaveItem = (index, itemType, orderNo) => {
    const { invoice } = this.state;
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      const priceList = invoice[itemType][elementIndex].price_list || [];
      const item = priceList[index];
      const errors = doValidate(requiredFields, item, `Item  ${index + 1}`);
      if (itemType === 'accessorial_charges' && isEmpty(item.accessorial_key)) {
        errors.push(`Item  ${index + 1} ${I18n.t('errors.field_required', { field: I18n.t('order.accessorial') })}`);
      }
      if (errors.length > 0) {
        this.setState({ errors, inProgress: false });
      } else {
        let newInvoiceLines = [];
        const invoiceLines = priceList.filter(line => line.isNew === true);
        if (itemType === 'accessorial_charges') {
          invoiceLines.forEach((line) => {
            const newLine = Object.assign({}, line);
            newLine.accessorial_key = '';
            const accessorialIndex = _.findIndex(this.state.accessorials, ['accessorial_key', line.accessorial_key]);
            if (accessorialIndex >= 0) {
              newLine.accessorial_key = this.state.accessorials[accessorialIndex].name;
            }
            newLine.invoice_type = (itemType === 'accessorial_charges' ? 'Accessorial' : itemType);
            newInvoiceLines.push(newLine);
          });
        } else {
          newInvoiceLines = [...invoiceLines];
        }
        const data = {
          new_invoice_lines: newInvoiceLines,
          remark: '',
          order_group_type: invoice.order_group_type,
          reference_id: invoice.order_group_type === 'ORDER' ? invoice.customer_order_id : invoice.order_number,
          status: invoice.status,
        };
        updateInvoice(data)
          .then((result) => {
            if (result.success) {
              alertMessage(I18n.t('messages.saved'));
              this.setState({
                inProgress: false, errors,
              });
              this.props.getInvoiceDetails(this.state.invoice.customer_order_id);
            } else {
              alertMessage(result.errors, 'error', 10);
              this.setState({ inProgress: false });
            }
          });
      }
    }
  }

  handleSave = () => {
    const invoice = {
      new_invoice_lines: [],
      remark: this.state.comments || '',
      reference_id: this.state.invoice.order_group_type === 'ORDER' ? this.state.invoice.customer_order_id : this.state.invoice.order_number,
      order_group_type: this.state.invoice.order_group_type,
      status: this.state.invoice.status,
    };
    updateInvoice(invoice)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'));
          this.setState({
            inProgress: false,
            comments: '',
          });
          this.closeRemarksModal();
          this.props.getInvoiceDetails(this.state.invoice.customer_order_id);
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  }

  handleStatusChange = (status) => {
    this.setState({
      loading: true,
    });
    const data = {
      // order_id: id,
      order_id: this.state.invoice.order_group_type === 'ORDER' ? this.state.invoice.customer_order_id : this.state.invoice.order_number,
      order_group_type: this.state.invoice.order_group_type,
      status,
    };
    changeInvoiceStatus(data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.processed'), 10);
          this.setState({
            loading: false,
            invoice: Object.assign({}, this.state.invoice, { status }),
          }, () => {
            this.props.onChange('currentInvoice', this.state.invoice);
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            loading: false,
          });
        }
      });
  };

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.currentInvoice, this.state.invoice)) {
      this.setState({
        invoice: nextProps.currentInvoice,
      });
    }
  }

  closeRemarksModal = () => {
    this.setState({
      showRemarks: false,
    });
  }

  showRemarksModal = () => {
    this.setState({
      showRemarks: true,
    });
  }


  renderRemarks = () => (
    <BaseModal
      title={I18n.t('invoices.add_remark')}
      onCancel={() => this.closeRemarksModal()}
    >
      <div>
        <TextArea
          rows={4}
          placeholder={I18n.t('invoices.remarks')}
          className='sop-form-textArea'
          value={this.state.comments}
          onChange={e => this.handleRemarksChange('comments', e.target.value)}
        />
        <div style={{ textAlign: 'center' }}>
          <Button onClick={this.closeRemarksModal}>
            {I18n.t('general.close')}
          </Button>
          <Button onClick={this.handleSave} disabled={this.state.disableRemarksButton} className="assignDriver" style={{ margin: 10 }} type="primary" loading={this.state.loading}>
            {I18n.t('general.save')}
          </Button>
          <Typography.Paragraph > <b> <span className="textRed">Note</span> </b> : Remarks should be minimum of 10 characters. </Typography.Paragraph>
        </div>
      </div>
    </BaseModal>
  )

  handleRemarksChange = (element, value) => {
    if (value !== '' && value.length > 2) {
      this.setState({
        [element]: value, disableRemarksButton: false,
      });
    } else {
      this.setState({
        [element]: value, disableRemarksButton: true,
      });
    }
  }

  _renderHeaderInfo = () => {
    const { invoice } = this.state;
    const isReadyOnly = invoice.status === 'PAYMENT_CONFIRMED';
    return (<InvoiceHeader invoice={invoice} isReadyOnly={isReadyOnly} />);
  }


  render() {
    const { invoice } = this.state;
    const errorMessages = invoice.error_messages && invoice.error_messages.length > 0 ? (invoice.error_messages) : (invoice.error_log);
    const isReadyOnly = invoice.status === 'PAYMENT_CONFIRMED';
    return (
      <div className="invoice_details">
        <Row gutter={16}>
          {this.props.showHeaders !== false ?
            <this._renderHeaderInfo /> :
            <div className="alignRight" />
          }
          {invoice.driver_transportation_charges.length > 0 &&
            <Col sm={24} xs={24} md={24} lg={24} className="marginTop10">
              <h4>Driver transportation charges</h4>
              {invoice.driver_transportation_charges.map(section =>
                (
                  <Row>
                    <Col xs={12} className="textBold">
                      <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.handleOrderDetailsViewClick(section.order_id)}>#{section.order_number}</span>
                    </Col>
                    <Col xs={12} className="textBold alignRight">
                      {/* (section.price_list.length === 0 && !isReadyOnly) &&
                      <Button
                        type="link"
                        size='small'
                        onClick={() => this.addItem('driver_accessorial_charges', section.order_number)}
                      >
                        <Icon type="plus-circle" />&nbsp;{I18n.t('accessorials.add')}
                      </Button>
                      */}
                    </Col>
                    <Col xs={24}>
                      <InvoicesItemList
                        invoice_type='driver_transportation_charges'
                        currentSection={section}
                        reqData={this.state.accessorials}
                        data={section.price_list || []}
                        handleOnChange={(index, element, value, orderNo) => this.handleOnItemsChange(index, element, value, 'accessorial_charges', orderNo)}
                        size="small"
                        pagination={{ position: 'none' }}
                        deleteClick={this.handleDeleteclick}
                        saveItem={this.handleSaveItem}
                        totalRecords={section.price_list.length}
                        addItem={this.addItem}
                        showAddIcon={false}
                        isReadyOnly={isReadyOnly}
                        hasNew={section.price_list.filter(item => item.isNew === true).length === 0}
                        footer={
                          () =>
                            (
                              <div className="alignRight textBold" style={{ paddingRight: 100 }}>
                                {I18n.t('invoices.total')}:&nbsp;&nbsp;
                                {this.findSectionTotal('driver_transportation_charges', section.order_number)}
                              </div>
                            )
                        }
                      />
                    </Col>
                  </Row>
                ))

              }
            </Col>
          }
          {invoice.driver_accessorial_charges.length > 0 &&
            <Col sm={24} xs={24} md={24} lg={24} className="marginTop10">
              <h4>{I18n.t('invoices.driver_accessorial_charges')}</h4>
              {invoice.driver_accessorial_charges.map(section =>
                (
                  <Row>
                    <Col xs={12} className="textBold">
                      <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.handleOrderDetailsViewClick(section.order_id)}>#{section.order_number}</span>
                    </Col>
                    <Col xs={12} className="textBold alignRight">
                      {/* (section.price_list.length === 0 && !isReadyOnly) &&
                      <Button
                        type="link"
                        size='small'
                        onClick={() => this.addItem('driver_accessorial_charges', section.order_number)}
                      >
                        <Icon type="plus-circle" />&nbsp;{I18n.t('accessorials.add')}
                      </Button>
                      */}
                    </Col>
                    <Col xs={24}>
                      <InvoicesItemList
                        invoice_type='driver_accessorial_charges'
                        currentSection={section}
                        reqData={this.state.accessorials}
                        data={section.price_list || []}
                        handleOnChange={(index, element, value, orderNo) => this.handleOnItemsChange(index, element, value, 'accessorial_charges', orderNo)}
                        size="small"
                        pagination={{ position: 'none' }}
                        deleteClick={this.handleDeleteclick}
                        saveItem={this.handleSaveItem}
                        totalRecords={section.price_list.length}
                        addItem={this.addItem}
                        showAddIcon={false}
                        isReadyOnly={isReadyOnly}
                        hasNew={section.price_list.filter(item => item.isNew === true).length === 0}
                        footer={
                          () =>
                            (
                              <div className="alignRight textBold" style={{ paddingRight: 100 }}>
                                {I18n.t('invoices.total')}:&nbsp;&nbsp;
                                {this.findSectionTotal('driver_accessorial_charges', section.order_number)}
                              </div>
                            )
                        }
                      />
                    </Col>
                  </Row>
                ))

              }
            </Col>
          }
        </Row>
      </div>
    );
  }
}

DriverPayDetails.propTypes = {
  account: PropTypes.shape().isRequired,
  currentInvoice: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  getInvoiceDetails: PropTypes.func.isRequired,
  showHeaders: PropTypes.bool.isRequired,
};

export default DriverPayDetails;

import React from 'react'
import BaseModal from '../components/BaseModal';
import Dms from './Dms';

export const BarCodeModal = (props) => {
  const {
    title = 'Print Barcodes',
    placement = 'right',
    closable = true,
    onCancel = () => {},
    visible = false,
    style = { top: 20 },
    width = '60%',
    selectedOrders = [],
    org_id = '',
    handleBarCodePrint = () => {},
    maskClosable = false
  } = props;

  return (
    <BaseModal
      title={ title }
      placement={ placement }
      closable={ closable }
      onCancel={ onCancel }
      visible={ visible }
      style={ style }
      width={ width }
      maskClosable={maskClosable}
    >
      <div className="smallTable">
        <Dms selectedOrders={ selectedOrders } org_id={ org_id } handleBarCodePrint={ handleBarCodePrint } />
      </div>
    </BaseModal>
  );
};

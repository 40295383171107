import React from 'react';
import _ from 'lodash';
import BaseList from '../BaseList';
import {isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';

import { Tooltip } from '../../common/UIComponents';
import { momentTimeWithShortName } from '../../helpers/date_functions';

class ExceptionsList extends BaseList {
  constructor (props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }
  setColumns = (props) => {
    const { isMilitaryTime, timeZone, timeZoneName } = props;
    const displayFormat = isMilitaryTime ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY hh:mm A';
    this.columns = [
      {
        title: '#',
        key: 'index',
        render: (text, record, index) => index + 1,
        width: 40,
      },
      {
        key: 'reason',
        title: I18n.t('exceptions.reason'),
        dataIndex: 'exception_message',
        render: (data, record) => (
          <div>
            { data }
          </div>
        ),
      },
      {
        key: 'comments',
        title: I18n.t('exceptions.comments'),
        dataIndex: 'exception_comments',
        ellipsis: true,
        render: (data, record) => (
          <div>
            { !isEmpty(record.exception_comments) ? <Tooltip title={ record.exception_comments }> { record.exception_comments } </Tooltip> : '' }
          </div>
        ),
      },
      {
        key: 'previous_status',
        title: I18n.t('exceptions.previous'),
        dataIndex: 'previous_status',
      },
      {
        key: 'moved_to_on',
        title: I18n.t('exceptions.moved_on'),
        dataIndex: 'moved_to_exception_on',
        render: (data) =>
          data
            ? <div>{ momentTimeWithShortName(displayFormat, data, timeZone, timeZoneName) }</div>
            : "NA",
      },
      {
        key: 'moved_to_by',
        title: I18n.t('exceptions.moved_by'),
        dataIndex: 'moved_to_exception_by_name',
      },
      {
        key: 'next_status',
        title: I18n.t('exceptions.next'),
        dataIndex: 'next_status',
      },
      {
        key: 'moved_from_on',
        title: I18n.t('exceptions.moved_on'),
        dataIndex: 'moved_from_exception_on',
        render: (data) =>
          data
            ? <div>{ momentTimeWithShortName(displayFormat, data, timeZone, timeZoneName) }</div>
            : "NA",
      },
      {
        key: 'moved_from_by',
        title: I18n.t('exceptions.moved_by'),
        dataIndex: 'moved_from_exception_by_name',
      } ];
  };

  componentDidUpdate (prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }
}

export default ExceptionsList;

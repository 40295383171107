/* eslint-disable no-nested-ternary */
import React, { Fragment } from "react";
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import moment from "moment";
import BaseList from "../BaseList";
import {isEmpty } from "../../common/Common";
import {
  Icon,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Button,
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import { formatByTimeConfig } from "../../helpers/common";
import AppointmentPicker from "./AppointmentPicker";
import I18n from "../../common/I18n";

class AppointmentsForm extends BaseList {
  constructor(props) {
    super(props);
    const isReadOnly = props.isReadOnly || false;
    const feasible_dates = props.feasible_dates || [];
    const isMilitaryTime = this.props.organizationSettings['is_military_time'] == 'true';
    this.columns = [];
    if (this.props.showSerialNos !== false) {
      this.columns.push({
        title: "#",
        key: "index",
        render: (text, record, index) => <div>{index + 1}</div>,
        width: 30,
      });
    }
    if (
      this.props.showConfirmation !== false &&
      this.props.viewType === "read"
    ) {
      this.columns.push({
        key: "confirm",
        title:
          this.props.viewType !== "read"
            ? I18n.t("appointmentForm.confirmed")
            : I18n.t("general.confirmed"),
        dataIndex: "confirmed",
        render: (text, record, index) => (
          <div className="form_item">
            <Fragment>
              {record.confirmed ? (
                <Icon type="check" className="primaryText" />
              ) : (
                ""
              )}
            </Fragment>
          </div>
        ),
        align: "center",
        width: 75,
      });
    }
    if (_.isUndefined(this.props.hideDate) || this.props.hideDate == false) {
      this.columns.push({
        key: "preference_date",
        title: I18n.t("general.date"),
        dataIndex: "item_name",
        render: (text, record, index) => (
          <div className="form_item">
            {this.props.viewType !== "read" ? (
              <Fragment>
                <DatePicker
                  size="small"
                  style={{ width: "150", marginLeft: "1%" }}
                  placeholder="Select Date"
                  format="Do MMM YYYY"
                  onChange={(e) =>
                    this.props.itemOnChange(index, "item_preference", e)
                  }
                  value={
                    record.item_preference
                      ? moment(record.item_preference)
                      : null
                  }
                  dateRender={(current) => {
                    const style = {};
                    const pickerDate = current.format("YYYY-MM-DD");
                    if (
                      this.props.hasDeliveryZipcodesConfig &&
                      this.props.feasible_dates.includes(pickerDate)
                    ) {
                      style.border = "2px solid green";
                      // style.backgroundColor = 'green';
                      // style.color = '#FFF';
                      style.borderRadius = "50%";
                      style.fontSize = "11px";
                    }
                    return (
                      <div
                        className="ant-calendar-date fontSize12"
                        style={style}
                      >
                        {current.date()}
                      </div>
                    );
                  }}
                  defaultValue={null}
                  onOk={this.onOk}
                  disabledDate={
                    // current => current && (current < moment())
                    (current) =>
                      current &&
                      current <=
                        moment(
                          moment().add(
                            AppConfig.orderFieldDuration - 24,
                            "hours"
                          ),
                          "YYYY-MM-DD"
                        )
                  }
                  disabled={isReadOnly}
                />
              </Fragment>
            ) : (
              record.item_preference &&
              moment(record.item_preference).format("DD MMM YYYY")
            )}
          </div>
        ),
        width: isReadOnly ? 75 : 160,
      }
    );
    }

    this.columns.push(
      {
        key: "any",
        title: (
          <Row>
            <Col className="alignCenter">{I18n.t("appointmentForm.any")}</Col>
            <Col className="alignCenter fontsize11 smallText">
              (
              {
                isMilitaryTime ? I18n.t("appointmentForm.military_any_time") : I18n.t("appointmentForm.any_time")
              }
              )
            </Col>
          </Row>
        ),
        dataIndex: "item_option",
        render: (text, record, index) => (
          <div className="form_item">
            {this.props.viewType !== "read" ? (
              <Fragment>
                <Checkbox
                  onChange={(e) =>
                    this.props.itemOptionOnChange(
                      index,
                      "item_option",
                      "ANY",
                      e.target.checked,
                      this.props.optingType
                    )
                  }
                  checked={record.item_option.includes("ANY")}
                  disabled={isReadOnly}
                />
              </Fragment>
            ) : record.item_option.includes("ANY") ? (
              <Icon type="check" className="primaryText" />
            ) : (
              <Icon type="close" />
            )}
          </div>
        ),
        align: "center",
      },

      {
        key: "morning",
        title: (
          <Row>
            <Col className="alignCenter">
              {I18n.t("appointmentForm.morning")}
            </Col>
            <Col className="alignCenter fontsize11 smallText">
              ({
                (isMilitaryTime ? I18n.t("appointmentForm.military_morning_time") : I18n.t("appointmentForm.morning_time")
                )
              })
            </Col>
          </Row>
        ),
        dataIndex: "item_option",
        render: (text, record, index) => (
          <div className="form_item">
            {this.props.viewType !== "read" ? (
              <Fragment>
                <Checkbox
                  onChange={(e) =>
                    this.props.itemOptionOnChange(
                      index,
                      "item_option",
                      "M",
                      e.target.checked,
                      this.props.optingType
                    )
                  }
                  checked={record.item_option.includes("M")}
                  disabled={
                    // record.item_option.includes("ANY") ||
                    // record.item_option.includes("CUSTOM") ||
                    isReadOnly
                  }
                />
              </Fragment>
            ) : record.item_option.includes("M") ? (
              <Icon type="check" className="primaryText" />
            ) : (
              <Icon type="close" />
            )}
          </div>
        ),
        align: "center",
      },
      {
        key: "aternoon",
        title: (
          <Row>
            <Col className="alignCenter">
              {I18n.t("appointmentForm.afternoon")}
            </Col>
            <Col className="alignCenter fontsize11 smallText">
            ({
            (isMilitaryTime ? I18n.t("appointmentForm.military_afternoon_time") : I18n.t("appointmentForm.afternoon_time"))
            })
            </Col>
          </Row>
        ),
        dataIndex: "item_option",
        render: (text, record, index) => (
          <div className="form_item">
            {this.props.viewType !== "read" ? (
              <Fragment>
                <Checkbox
                  onChange={(e) =>
                    this.props.itemOptionOnChange(
                      index,
                      "item_option",
                      "A",
                      e.target.checked,
                      this.props.optingType
                    )
                  }
                  checked={record.item_option.includes("A")}
                  disabled={
                    // record.item_option.includes("ANY") ||
                    // record.item_option.includes("CUSTOM") ||
                    isReadOnly
                  }
                />
              </Fragment>
            ) : record.item_option.includes("A") ? (
              <Icon type="check" className="primaryText" />
            ) : (
              <Icon type="close" />
            )}
          </div>
        ),
        align: "center",
      },
      {
        key: "evening",
        title: (
          <Row>
            <Col className="alignCenter">
              {I18n.t("appointmentForm.evening")}
            </Col>
            <Col className="alignCenter fontsize11 smallText">
              ({
                (isMilitaryTime ? I18n.t("appointmentForm.military_evening_time") :
                  I18n.t("appointmentForm.evening_time"))
              })
            </Col>
          </Row>
        ),
        dataIndex: "item_option",
        render: (text, record, index) => (
          <div className="form_item">
            {this.props.viewType !== "read" ? (
              <Fragment>
                <Checkbox
                  onChange={(e) =>
                    this.props.itemOptionOnChange(
                      index,
                      "item_option",
                      "E",
                      e.target.checked,
                      this.props.optingType
                    )
                  }
                  checked={record.item_option.includes("E")}
                  disabled={
                    // record.item_option.includes("ANY") ||
                    // record.item_option.includes("CUSTOM") ||
                    isReadOnly
                  }
                />
              </Fragment>
            ) : record.item_option.includes("E") ? (
              <Icon type="check" className="primaryText" />
            ) : (
              <Icon type="close" />
            )}
          </div>
        ),
        align: "center",
      },
      {
        key: "custom",
        title: (
          <Row>
            <Col className="alignCenter">
              {I18n.t("appointmentForm.custom")}
            </Col>
          </Row>
        ),
        dataIndex: "item_option",
        render: (text, record, index) => (
          <div className="form_item">
            {this.props.viewType !== "read" ? (
              <Fragment>
                <Checkbox
                  onChange={(e) =>
                    this.props.itemOptionOnChange(
                      index,
                      "item_option",
                      "CUSTOM",
                      e.target.checked,
                      this.props.optingType
                    )
                  }
                  checked={record.item_option.includes("CUSTOM")}
                  disabled={isReadOnly}
                />
              </Fragment>
            ) : record.item_option.includes("CUSTOM") ? (
              <Icon type="check" className="primaryText" />
            ) : (
              <Icon type="close" />
            )}
          </div>
        ),
        align: "center",
      }
    );
    // if (this.props.hasCustom === true) {  // && this.props.viewType !== 'read'
    this.columns.push({
      key: "custom_times",
      title: 
      (
        <Row>
          <Col className="alignCenter">
            {I18n.t("general.from")} - {I18n.t("general.to")}
          </Col>
          <Col className="alignCenter fontsize11 smallText">{
            isMilitaryTime ? I18n.t("appointmentForm.military_time_format") : I18n.t("appointmentForm.standard_time_format")
          }</Col>
        </Row>
      ),
      dataIndex: "item_option",
      render: (text, record, index) => (
        <div className="form_item" style={ {
          display: "flex",
          justifyContent: "center",
        } }>
          {this.props.viewType !== "read" ? (
            <Fragment>
              <Row gutter={ 2 } type="flex">
                <Col >
                  <AppointmentPicker
                    data={ record }
                    endHour={ this.props.endHour }
                    endMinute={ this.props.endMinute }
                    startHour={ this.props.startHour }
                    startMinute={ this.props.startMinute }
                    disabledStartHours={ this.props.disabledStartHours }
                    itemChange={ this.props.itemChange }
                    dataIndex={ index }
                    property="start_time"
                    isMilitaryTime={ isMilitaryTime }
                    onError={ (error) => {
                      this.props.onError("start_time", error);
                    } }
                  />
                </Col>
                <Col >
                  <AppointmentPicker
                    data={ record }
                    endHour={ this.props.endHour }
                    endMinute={ this.props.endMinute }
                    startHour={ this.props.startHour }
                    startMinute={ this.props.startMinute }
                    disabledStartHours={ this.props.disabledStartHours }
                    disabledEndHours={ this.props.disabledEndHours }
                    itemChange={ this.props.itemChange }
                    dataIndex={ index }
                    property="end_time"
                    isMilitaryTime={ isMilitaryTime }
                    onError={ (error) => {
                      this.props.onError("end_time", error);
                    } }
                  />
                </Col>
              </Row>
            </Fragment>
          ) : record.item_option.length > 0 &&
            record.item_option.includes("CUSTOM") ? (
            <div>
              {record.start_time
                ? formatByTimeConfig(moment(record.start_time) , this.props.organizationSettings['is_military_time'] , "HH:mm" , "hh:mm A")
                : "NA"}{" "}
              -{" "}
              {record.end_time
                ? formatByTimeConfig(moment(record.end_time) , this.props.organizationSettings['is_military_time'] , "HH:mm" , "hh:mm A")
                : "NA"}
            </div>
          ) : (
            <Fragment />
          )}
        </div>
      ),
      align: "center",
    });
    // }
    if (this.props.showExpedite === true) {
      this.columns.push({
        key: "expedite",
        title: (
          <Row>
            <Col className="alignCenter">{I18n.t("order.expedite")}</Col>
          </Row>
        ),
        dataIndex: "has_expedite",
        render: (text, record, index) => (
          <div className="form_item">
            {this.props.viewType !== "read" ? (
              <Fragment>
                <Checkbox
                  onChange={(e) =>
                    this.props.itemChange(
                      index,
                      "has_expedite",
                      e.target.checked
                    )
                  }
                  checked={record.has_expedite}
                  disabled={isReadOnly}
                />
              </Fragment>
            ) : record.has_expedite ? (
              <Icon type="check" className="primaryText" />
            ) : (
              <Icon type="close" />
            )}
          </div>
        ),
        align: "center",
      });
    }
    if (
      this.props.showConfirmation !== false &&
      this.props.viewType !== "read"
    ) {
      this.columns.push({
        key: "confirm",
        title:
          this.props.viewType !== "read"
            ? I18n.t("appointmentForm.confirmed")
            : I18n.t("general.confirmed"),
        dataIndex: "confirmed",
        render: (text, record, index) => (
          <div className="form_item">
            {/* <Checkbox
                  onChange={e => this.props.confirmChange(index, 'confirmed', e.target.checked)}
                  checked={record.confirmed === true}
                  disabled={isReadOnly}
                 /> */}
            <Button
              size="small"
              type={record.confirmed ? "primary" : "default"}
              onClick={(e) =>
                this.props.confirmChange(index, "confirmed", !record.confirmed)
              }
              disabled={
                isReadOnly ||
                record.item_preference === "" ||
                record.item_option.length === 0 ||
                (record.item_option.includes("CUSTOM") &&
                  (isEmpty(record.start_time) || isEmpty(record.end_time)))
              }
            >
              {record.confirmed ? <Icon type="check" /> : ""}{" "}
              {record.confirmed
                ? I18n.t("general.confirmed")
                : I18n.t("appointmentForm.confirmed")}
            </Button>
          </div>
        ),
        align: "center",
        width: 100,
      });
    }
    if (!isReadOnly) {
      this.columns.push({
        key: "action",
        title: "",
        render: (text, record, index) => (
          <div>
            {this.props.viewType !== "" && (
              <Tooltip title={I18n.t("general.clear")}>
                <Popconfirm
                  placement="topRight"
                  title={I18n.t("messages.clear_confirm")}
                  onConfirm={() => this.props.clearPreference(index)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" size="small">
                    {I18n.t("general.clear")}
                  </Button>
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        ),
        width: 50,
      });
    }
  }
}

export default withRouter(AppointmentsForm);

AppointmentsForm.propTypes = {
  onError: PropTypes.func.isRequired,
}

AppointmentsForm.defaultProps = {
  onError: () => {},
}

import _ from "lodash";
import React  from "react";
import {
  Col,
  Row,
} from "../../common/UIComponents";
import { renderDriverNotes } from "../../helpers/orders";
import { isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";

const ConsigneeNote = ({ order,isDriverNote }) => {
  const renderDriverNotes = (notes) => {
   if(_.isArray(notes)){
    return notes.map(note => note.notes).join(', ')
   } else if(!isEmpty(notes)){
    return notes;
   }
   return 'NA';
  }

  return (
    <Col span={24}>
      {!isEmpty(order.customer_comments) && !isDriverNote ? (
        <Row>
          <Col xs={24}>
            <span className="textBold">
              {I18n.t("appointmentForm.notes_to_driver")}:{" "}
            </span>
            <span className="alignLeft">
              <span>{order.customer_comments}</span>
            </span>
          </Col>
        </Row>
      ) : (
        <React.Fragment />
      )}
      {!isEmpty(order.driver_notes) && isDriverNote ? (
        <Row>
          <Col xs={24}>
            <span className=" textBold">{I18n.t("order.driver_notes")}: </span>
            <span className="alignLeft">
              <span>{renderDriverNotes(order.driver_notes)}</span>
            </span>
          </Col>
        </Row>
      ) : (
        <React.Fragment />
      )}
      {!isEmpty(order.customer_notes) && !isDriverNote ? (
        <Row>
          <Col xs={24}>
            <span className=" textBold">
              {I18n.t("order.customer_notes")}:{" "}
            </span>
            <span>{order.customer_notes}</span>
          </Col>
        </Row>
      ) : (
        <React.Fragment />
      )}
      {!isEmpty(order.exception_comments) && !isDriverNote ? (
        <Row>
          <Col xs={24}>
            <span className="textBold">
              {I18n.t("order.exception_comments")}:{" "}
            </span>
            <span className="alignLeft">{order.exception_comments}</span>
          </Col>
        </Row>
      ) : (
        <React.Fragment />
      )}
    </Col>
  );
};
export default ConsigneeNote;

import React from 'react'
import BaseList from '../BaseList'
import I18n from "../../common/I18n";
import { formatSurfaceArea, formatWeight } from '../../helpers/orders'

class ZoneCapacityErrors extends BaseList {
  constructor(props) {
    super(props)
    this.columns = [
      {
        key: 'zones',
        title: I18n.t('menu.zones'),
        dataIndex: 'zones',
        render: (data, record) => <div>{data ? `${data}` : ''}</div>,
        width: '70%',
      },
      {
        key: 'reason',
        title: I18n.t('general.reason'),
        dataIndex: 'reason',
        width: '30%',
      },
      // {
      //   key: 'overloaded_weight',
      //   title: I18n.t('general.reason'),
      //   dataIndex: 'orders_tot_wt',
      //   render: (data, record) => <div>{record.max_dim_overload ? formatWeight(data) : ''}</div>,
      // },
      // {
      //   key: 'overloaded_area',
      //   title: I18n.t('general.reason'),
      //   dataIndex: 'orders_tot_vol',
      //   render: (data, record) => <div>{record.max_wt_overload ? formatSurfaceArea(data) : ''}</div>,
      // },
    ]
  }
}

export default ZoneCapacityErrors

import React, { Fragment, useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Button,
  Popconfirm,
  Modal,
  Link,
  Row,
  Col,
  FormItem,
  Select,
  Spin,
  MaterialFormItem,
  Tooltip,
} from '../../common/UIComponents'
import { linehaulRoutes } from '../../api/Routes'
import userStore from '../../stores/UserStore'
import { alertMessage,  isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import AppConfig from '../../config/AppConfig'
import moment from 'moment'
import MaterialTimePicker from './MaterialTimePicker'
import BaseModal from '../BaseModal'
import { fetchTrucks } from '../../api/TrucksApi'
import { fetchUsers } from '../../api/UsersApi'
import { getValueFromArrayOfObjects } from '../../helpers/array_functions'
import { checkAccessExistance, formatFullName, renderAlertMessage } from '../../helpers/common'
import { OrgContext } from '../../context/OrgContext'
import vtype from "../../../assets/images/load_green.png";
import DateTimeSelector from '../../common/DateTimeSelector';
import { OrderConfigContext } from '../../context/OrderConfigContext'



const DispatchButtonForm = (props) => {
  const organizationSettings = useContext(OrgContext);
  const isMilitaryTime = organizationSettings.is_military_time === 'true'
  const { orders, getOrderDetails, history, setRefreshValue, closeForm } = props
  const typeOfOrder = orders.type_of_order ? orders.type_of_order : ''
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [drivers, setDrivers] = useState([])
  const [fleets, setFleets] = useState([])
  const [currentForm, setCurrentForm] = useState({
    start_date: null, 
    start_time: null,
    driver_id: '',
    fleet_id: '',
  });
  useEffect(() => { 
    getDrivers(); 
    getTrucks();
    setFormData()
  }, [])
 
  const setFormData = () => {
    const startDatetime = orders?.scheduled_start_datetime ? moment(orders.scheduled_start_datetime, "YYYY-MM-DD HH:mm:ss") : moment(organizationSettings.route_delivery_start_time, "HH:mm")
    setCurrentForm(
      {
        start_date: startDatetime,
        start_time: startDatetime, 
        driver_id: orders.driver_id,
        fleet_id: orders.fleet_id,
      }
    )
  }
  const getDrivers = async () => {
    const result = await fetchUsers('driver', 1, null);
    if (result.success) {
      setDrivers(result.users)
    }
  };
  const getTrucks = () => {
    fetchTrucks({}, 1, 1000).then((result) => {
      if (result.success) {
       setFleets(result.trucks)
      }
    });
  };
  const navigateToRoute = (route) => {
    history.push({
      pathname: '/routes',
      filter: 'ASSIGNED',
      routeId: route.nav_route_id,
      dateFilter: {
        fromDate: route.route_start_datetime,
        toDate: route.route_end_datetime,
      },
    })
  }

  const handleDispatchAllocation = () => {
    const organization_id = userStore.getStateValue('selectedOrg')
    setIsLoading(true)
    const startDateTime = moment(currentForm.start_date).format("YYYY-MM-DD") + ' ' + moment(currentForm.start_time).format("HH:mm");
    const payload = {
      organization_id,
      ...currentForm,
      start_time: startDateTime,
      scheduled_start_datetime: startDateTime,
      customer_order_id: orders.id,
    }

    linehaulRoutes
      .createRoute(payload)
      .then((result) => {
        if (result.success) {
          const route = result.nav_route ? result.nav_route : {}
          const dirver =
            route?.primary_driver 
              ? route.primary_driver
              : {}
          getOrderDetails()
          setRefreshValue(true)
          // alertMessage('Orders have been assigned', 'success', 10)
          Modal.info({
            key: 'dispathButton',
            title: `Order ${orders.order_number} has been assigned to the Route #${
              !isEmpty(dirver.employee_code) ? dirver.employee_code : (formatFullName(dirver))
            }`,
            content: (
              <div>
                { checkAccessExistance('DISPATCH') && 
                  <Fragment>
                     <u>
                      <Link
                        style={{ cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={() => {
                          navigateToRoute(route)
                          Modal.destroyAll()
                        }}
                      >
                        Click here
                      </Link>
                     </u>
                     &nbsp;&nbsp; to view the route details
                  </Fragment>
                }
               
              </div>
            ),
            onOk() { closeWindow (); 
                     getOrderDetails();
            },
          })
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const closeWindow = () => {
    setCurrentForm({
      start_time: null,
      driver_id: '',
      fleet_id: '',
    });
    closeForm();
  }

  const handleOnChange = (element, value) => {
    const data = { ...currentForm, [element]: value };
    if(element === 'driver_id' && !isEmpty(value)){
      data.fleet_id = getValueFromArrayOfObjects(drivers, 'id', value, 'driver_fleet_id');
    }
    setCurrentForm(data)
  }

  const renderForm = () => {
    return (
        <Spin spinning={isLoading} >
          <Row className="marginTop10" gutter={32}>
          <Col>
            <DateTimeSelector
              dateProps={ {
                format: 'Do MMM YYYY',
                label: "Route start date",
                value: currentForm.start_date ? moment(currentForm.start_date) : null,
                onChange: (date) => { setCurrentForm({ ...currentForm, start_date: date }); },
                style: { width: '100%' },
                allowClear: false,
                disabledDate: (current) => {
                  return (
                    current && current < moment().startOf('day')
                  );
                },
              } }
              timeProps={ {
                format: isMilitaryTime ? 'HH:mm' : 'hh:mm A',
                label: "Route start time",
                showTime: { format: isMilitaryTime ? 'HH:mm' : 'hh:mm A', use12Hours: !isMilitaryTime },
                showSecond: false,
                onChange: (time) => {
                  setCurrentForm({ ...currentForm, start_time: time });
                },
                style: { width: '100%', height: '100%', marginTop: '4px' },
                value: currentForm.start_time ? moment(currentForm.start_time) : null,
                placeholder: 'Select Time',
                minuteStep: 1,
                isMilitaryTime,
              } }
              gridStyle={ { row: { type: "", gutter: 0 } } }
            />
          </Col>
          <Col>
              <FormItem label={I18n.t('general.Driver')}>
                <Select
                  value={currentForm.driver_id || ''}
                  showSearch
                  style={{ width: '100%' }}
                  filterOption={(input, option) => {
                    const children = option.props.children;
                    if (children && children.length > 0) {
                      if (Array.isArray(children)) {
                        return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      } else {
                        return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }
                    }
                  }}
                  onChange={(e) => handleOnChange('driver_id', e)}
                >
                  <Select.Option key="driver_id" value="">
                    -- Select --
                  </Select.Option>
                  {drivers.map((driver) => (
                    <Select.Option key={driver.id} value={driver.id}>
                      {driver.employee_code}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            {/* <Col xs={8}>
              <FormItem label={I18n.t('fleet.label')}>
                <Select
                  value={currentForm.fleet_id || ''}
                  showSearch
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => handleOnChange('fleet_id', e)}
                >
                  <Select.Option key="LOS" value="">
                    -- Select --
                  </Select.Option>
                  {fleets.map((fleet) => (
                    <Select.Option key={fleet.id} value={fleet.id}>
                      {fleet.name}
                      {!isEmpty(fleet.vehicle_type) && ` (${fleet.vehicle_type})`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col> */}
            <Col xs={24} className="alignCenter">
              <Button className="marginRight10" icon="close" onClick={closeWindow}>{I18n.t('general.cancel')}</Button>
              <Button type='primary' icon="save" disabled={!currentForm?.driver_id?.length} onClick={handleDispatchAllocation}>{I18n.t('general.save')}</Button>
            </Col>
          </Row>
        </Spin>
    )
  }

  return (
    <Fragment>
      {renderForm()}
    </Fragment>
  )
}

const DispatchButton = (props) => {
  const { orderConfig = {
    orderTypes: {}
  }} = useContext(OrderConfigContext);
  const [showModal, setShowModal] = useState(false)
  const { orders, showIcon } = props;
  const typeOfOrder = orders.type_of_order ? orders.type_of_order : ''
  const renderAllocateWindow = () => {
    return (
      <BaseModal
        title={`${I18n.t('menu.routes')} info for the order #${orders.order_number} `}
        width="500px"
        style={{ top: 50 }}
        visible={showModal}
        onCancel={() => closeModal()}
        maskClosable={false}
      >
       <DispatchButtonForm   closeForm={() => closeModal()} {...props} />
      </BaseModal>)
  }
  
  const closeModal = () => {
    setShowModal(false)
  }


  return (
    <Fragment>
      {typeOfOrder && ["T", "LH"].includes(typeOfOrder) &&
        orderConfig.orderTypes[typeOfOrder].is_location_based &&
        !orders.nav_route_id && (
          <Popconfirm
            placement="topRight"
            title={I18n.t('messages.action_confirm', {
              action: 'Dispatch',
              field: '',
            })}
            onConfirm={() => 
            {
              setShowModal(true)
            }}
            okText="Yes"
            cancelText="No"
          >
            {
              showIcon ?
              <Tooltip title={I18n.t("menu.routes")} zIndex={999}>
              <img
              src={vtype}
              alt="vtype"
              style={{ height: 22, width: 22, cursor: "pointer" }}
            />
            </Tooltip>
            :
              <Button
              type="primary"
              style={{ marginTop: '2px', marginLeft: 10, marginRight: 10 }}
              className="buttonCerulean"
              icon="branches"
              size={'small'}
              //loading={isLoading}
            >
              {I18n.t('menu.routes')}
            </Button>
            }
          </Popconfirm>
        )}
      {showModal && renderAllocateWindow()}

    </Fragment>
  )
}

DispatchButton.propTypes = {
  getOrderDetails: PropTypes.func,
  setRefreshValue: PropTypes.func,
}
DispatchButton.defaultProps = {
  getOrderDetails: {},
  setRefreshValue: () => {},
}
export default withRouter(DispatchButton)

import React, { useState, useEffect, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { Tag, Button, Select, message, Modal, Row, Col } from "antd";
import BaseModal from "../BaseModal";
import { fetchLocations } from "../../api/LocationsApi";
import AppConfig from "../../config/AppConfig";
import { WarehouseContext } from "../../context/WarehouseContext";
import { UserContext } from "../../context/UserContext";
import { fetchShortFormAccounts } from "../../api/Account";
import { fetchAccountShortLos } from "../../api/Los";
import { SwitchDetailsApi } from "../preplan_management/SwitchDetailsApi";
import I18n from "../../common/I18n";

function SwitchOrder(props) {
  const {
    selectedOrders,
    isFromBulkContainer,
    isSwitchOrderClicked,
    setIsSwitchOrderClicked,
    setIsHovered,
  } = props;

  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [selectedOrdersState, setSelectedOrdersState] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [currentWarehouse, setCurrentWarehouse] = useState({});
  const [switchableAccList, setSwitchableAccList] = useState([]);
  const [switchableLOSList, setSwitchableLOSList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [switchDetailsModalVisible, setSwitchDetailsModalVisible] =
    useState(false);
  const [selectedLOS, setSelectedLOS] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const { currentOrg } = useContext(UserContext);

  const warehouseContext = useContext(WarehouseContext);
  const { getWHDetails } = warehouseContext;

  useEffect(() => {
    // setSelectedOrdersState(selectedOrders);
  }, [selectedOrders]);

  const resetValues = () => {
   // setWarehouses([])
    setCurrentWarehouse({})
    setSwitchableAccList([])
    setSwitchableLOSList([])
    setSelectedAccount("")
    setSelectedLOS("")
  }

  useEffect(() => {
    if (currentWarehouse.id) {
      setSelectedWarehouse(currentWarehouse.id);
    }
  }, [currentWarehouse.id]);

  useEffect(() => {
    if (isSwitchOrderClicked) {
      setSelectedOrdersState(selectedOrders);
      enableForm();
    }
  }, [isSwitchOrderClicked]);

  useEffect(() => {
    if (isFromBulkContainer && !switchDetailsModalVisible) {
      setIsSwitchOrderClicked(false);
      setIsHovered(null);
    }
  }, [switchDetailsModalVisible]);

  useEffect(() => {
    const fetchWarehouseData = async () => {
      try {
        setInProgress(true);
        const warehouseResult = await fetchLocations(
          AppConfig.warehouseCode,
          1,
          null
        );
        if (warehouseResult.success) {
          setWarehouses(warehouseResult.locations.locations);
          setInProgress(false);
          if (warehouseResult.locations.locations.length > 0) {
            setSelectedWarehouse(currentWarehouse.id);
          }
        } else {
          setInProgress(false);
          message.error("Error fetching warehouse locations", "error");
        }
      } catch (error) {
        setInProgress(false);
        message.error("Error fetching warehouse locations", "error");
      }
    };
    fetchWarehouseData();
  }, []);

  useEffect(() => {
    const fetchCurrentWarehouse = async () => {
      try {
        const currentWarehouseDetails = getWHDetails();
        setCurrentWarehouse(currentWarehouseDetails);
      } catch (error) {
        message.error("Error fetching current warehouse");
      }
    };
    fetchCurrentWarehouse();
  }, []);

  useEffect(() => {
    if (selectedWarehouse) {
      getAccounts(selectedWarehouse);
    }
  }, [selectedWarehouse]);

  useEffect(() => {
    const switchableOrders = selectedOrdersState.filter(
      (order) => !filteredOrders.includes(order)
    );

    if (switchableOrders.length > 0) {
      const firstOrder = switchableOrders[0];
      const accountId = firstOrder.account_id;
      const losName = firstOrder.los_name;
      const los = firstOrder.levelOfService;
      const accCode = firstOrder.account_code;
      getLOS(accCode);
      const isSameAccount = switchableOrders.every(
        (order) => order.account_id === accountId
      );

      if (isSameAccount) {
        setSelectedAccount(accountId);
        const isSameLos = switchableOrders.every(
          (order) => order.levelOfService === los
        );
        setSelectedLOS(isSameLos ? losName : "");
      } else {
        setSelectedAccount("");
        setSelectedLOS("");
      }
    }
  }, [selectedOrdersState]);

  const handleCloseModal = () => {
    const switchableOrders = selectedOrdersState.filter(
      (order) => !filteredOrders.includes(order)
    );
    if (switchableOrders.length <= 0) {
      props.clearOrders();
    }
    // resetValues()
    setSwitchDetailsModalVisible(false);
  };

  const getAccounts = (whId) => {
    fetchShortFormAccounts("", whId).then((result) => {
      if (result.success) {
        setSwitchableAccList(result.accounts);
      } else {
        message.error(result.message);
      }
    });
  };

  const getLOS = async (accountCode) => {
    try {
      const result = await fetchAccountShortLos(accountCode, selectedAccount);
      if (result.success) {
        const levelOfServices = result.account.account_los || [];
        if (levelOfServices.length === 0) {
          setSwitchableLOSList([{ id: "NONE", name: "None", code: "NONE" }]);
        } else {
          setSwitchableLOSList(
            levelOfServices.map((los) => ({
              id: los.id,
              name: los.name,
              code: los.code,
            }))
          );
        }
      }
    } catch (error) {
      message.error("Error fetching LOS");
    }
  };

  const isSwitchDetailsButtonEnabled = () => {
    if (!selectedAccount || !selectedLOS) {
      return false;
    }
    const switchableOrders = selectedOrdersState.filter(
      (order) => !filteredOrders.includes(order)
    );

    if (switchableOrders.length <= 0) {
      return false;
    }

    return true;
  };

  const handleOrderDeselect = (orderId) => {
    const updatedOrders = selectedOrdersState.filter(
      (order) => order.id !== orderId
    );
    setSelectedOrdersState(updatedOrders);
    const OrdersToSwitch = updatedOrders.filter(
      (order) => !filteredOrders.includes(order)
    );
    if (OrdersToSwitch.length <= 0) {
      message.info({
        content: "There are no switchable selected orders to switch details.",
        duration: 3,
      });
    }
  };

  const handleSwitchDetails = async () => {
    try {
      setInProgress(true);
      const warehouseToSend = selectedWarehouse || currentWarehouse.id;
      const switchableOrders = selectedOrdersState.filter(
        (order) => !filteredOrders.includes(order)
      );
      const losCode = switchableOrders[0].levelOfService;
      const selectedLOSObject = switchableLOSList.find(
        (los) => los.name === selectedLOS
      );
      const selectedLOSCode = selectedLOSObject
        ? selectedLOSObject.code
        : losCode;

      const response = await SwitchDetailsApi.switchOrderWarehouse(
        currentOrg.id,
        warehouseToSend,
        switchableOrders.map((order) => order.id),
        selectedAccount,
        selectedLOSCode
      );
      if (response.success) {
        message.success("Successfully switched");
        props.resetOrders();
      } else {
        message.error("Error switching");
      }
    } catch (error) {
      message.error("Error switching");
    } finally {
      setInProgress(false);
      props.resetSelection();
      if(onClose && typeof onClose === "function"){
        onClose();
      }
      props.refreshOrders();
    }
  };

  const handleAccountChange = async (value) => {
    try {
      setSelectedAccount(value);
      const selectedAccountObject = switchableAccList.find(
        (acc) => acc.id === value
      );
      if (selectedAccountObject) {
        const accountCode = selectedAccountObject.code;
        getLOS(accountCode);
        const result = await fetchAccountShortLos(accountCode, selectedAccount);
        if (result.success) {
          const existingLosInNewAccount = result.account.account_los.some(
            (los) => los.name === selectedLOS
          );
          if (!existingLosInNewAccount) {
            setSelectedLOS("");
          }
        } else {
          message.error(result.message);
        }
      } else{
        setSelectedLOS("");
        setSwitchableLOSList([{ id: "NONE", name: "None", code: "NONE" }]);
      }
    } catch (error) {
      message.error("Error fetching LOS");
    }
  };

  const handleWarehouseChange = async (value) => {
    setSelectedWarehouse(value);

    try {
      const result = await fetchShortFormAccounts("", value);
      if (result.success) {
        setSwitchableAccList(result.accounts);

        const existingAccountInNewWarehouse = result.accounts.some(
          (acc) => acc.id === selectedAccount
        );

        if (!existingAccountInNewWarehouse) {
          setSelectedAccount("");

          setSelectedLOS("");
        }
      } else {
        message.error(result.message);
      }
    } catch (error) {
      message.error("Error fetching accounts");
    }
  };

  const Footer = () => (
    <Row gutter={8} type="flex" justify="center" align="middle">
      <Col>
        <Button
          key="clear"
          type="danger"
          onClick={handleCloseModal}
          icon="close"
        >
          Close
        </Button>
      </Col>
      <Col>
        <Button
          key="switchDetails"
          type="primary"
          onClick={handleSwitchDetails}
          disabled={!isSwitchDetailsButtonEnabled()}
          loading={inProgress}
          icon="swap"
        >
          Switch Details
        </Button>
      </Col>
    </Row>
  );

  const renderSwitchableOrders = () => {
    const switchableOrders = selectedOrdersState.filter(
      (order) => !filteredOrders.includes(order)
    );

    if (switchableOrders.length === 0) {
      return null;
    }

    return (
      <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
        <span className="textBold">Switchable Orders: </span>
        {switchableOrders.map((order) => (
          <Tag
            key={order.id}
            visible
            color="blue"
            closable
            onClose={() => handleOrderDeselect(order.id)}
            className="marginTop7"
          >
            {order.customer_order_number}
          </Tag>
        ))}
      </div>
    );
  };

  const filteredOrders = selectedOrdersState.filter((order) => {
    if (order.type_of_order === "T") {
      return (
        order.origin.status === "COMPLETED" ||
        order.origin.status === "ASSIGNED" ||
        order.destination.status === "COMPLETED" ||
        order.destination.status === "ASSIGNED"
      );
    }
    return false;
  });

  const renderFilteredOrders = () =>
    filteredOrders.length > 0 && (
      <div style={{ marginTop: "10px" }}>
        <span className="textBold">
          UnSwitchable Orders - The following orders are not switchable because,
          the route has already started:
        </span>
        <div style={{ marginTop: "5px", display: "flex", flexWrap: "wrap" }}>
          {filteredOrders.map((order) => (
            <Tag
              key={order.id}
              color="red"
              onClose={() => handleOrderDeselect(order.id)}
              className="marginTop7"
              style={{ marginRight: "5px", marginBottom: "5px" }}
            >
              {order.customer_order_number}
            </Tag>
          ))}
        </div>
      </div>
    );

  const switchableWhList = warehouses.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const enableForm = () => {
    setSwitchDetailsModalVisible(true);
  };

  const renderSwitchModal = () => {
    return (
      <BaseModal
        title="Switch Details"
        visible={switchDetailsModalVisible}
        onCancel={handleCloseModal}
        destroyOnClose={true}
        width={1000}
        maskClosable={false}
      >
        {/* Render selected orders */}
        <Row type="flex" gutter={[16, 16]} style={{ marginBottom: "10px" }}>
          <Col>{renderSwitchableOrders()}</Col>
        </Row>

        {/* Render Filtered orders */}
        <Row type="flex" gutter={[16, 16]} style={{ marginBottom: "10px" }}>
          <Col>{renderFilteredOrders()}</Col>
        </Row>

        {/* Switch options */}
        <Row
          type="flex"
          gutter={[16, 16]}
          align="middle"
          style={{ marginBottom: "30px" }}
        >
          {/* Switch Warehouse */}
          <Col flex="0 0 auto">
            <span className="textBold">Warehouse :</span>
          </Col>
          <Col flex="auto">
            <Select
              placeholder="Select Warehouse"
              value={selectedWarehouse || currentWarehouse.name}
              style={{ width: 200 }}
              size="small"
              onChange={(value) => handleWarehouseChange(value)}
              disabled={inProgress}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              showSearch
            >
              <Select.Option key="ref" value="">
                -- Select --
              </Select.Option>
              {switchableWhList.map((warehouse) => (
                <Select.Option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          {/* Switch Account */}
          <Col flex="0 0 auto">
            <span className="textBold">Account :</span>
          </Col>
          <Col flex="auto">
            <Select
              placeholder="Select Account"
              value={selectedAccount}
              onChange={(value) => handleAccountChange(value)}
              disabled={inProgress}
              style={{ width: 200 }}
              size="small"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              dropdownMatchSelectWidth={false}
            >
              <Select.Option key="ref" value="">
                -- Select --
              </Select.Option>
              {switchableAccList.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {account.name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          {/* Switch LOS */}
          <Col flex="0 0 auto">
            <span className="textBold">LOS :</span>
          </Col>
          <Col flex="auto">
            <Select
              placeholder="Select LOS"
              value={selectedLOS}
              onChange={(value) => setSelectedLOS(value)}
              style={{ width: 200 }}
              size="small"
              disabled={inProgress}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              dropdownMatchSelectWidth={false}
            >
              <Select.Option key="ref" value="">
                -- Select --
              </Select.Option>
              {switchableLOSList.map((los) => (
                <Select.Option key={los.name} value={los.name}>
                  {los.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        {/* Footer */}
        <Row gutter={[16, 16]} type="flex">
          <Col span={24}>
            <Footer />
          </Col>
        </Row>
      </BaseModal>
    );
  };

  return (
    <Fragment>
      {!isFromBulkContainer ? (
        <Button
          type="primary"
          icon="swap"
          className="buttonCherryRed"
          onClick={enableForm}
        >
          {I18n.t("order.switch_details")}
        </Button>
      ) : (
        I18n.t("order.switch_details")
      )}
      {switchDetailsModalVisible && renderSwitchModal()}
    </Fragment>
  );
}

SwitchOrder.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedOrders: PropTypes.array.isRequired,
  isFromBulkContainer: PropTypes.bool,
  isSwitchOrderClicked: PropTypes.bool,
  setIsSwitchOrderClicked: PropTypes.func,
  setIsHovered: PropTypes.func,
};
SwitchOrder.defaultProps = {
  isFromBulkContainer: false,
  isSwitchOrderClicked: false,
  setIsSwitchOrderClicked: () => {},
  setIsHovered: () => {},
};

export default SwitchOrder;
import React, { Fragment, useContext } from 'react';
import BaseList from '../BaseList';
import { isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import Leg from '../../components/Tasks/Legs';
import _ from "lodash";
import AppConfig from '../../config/AppConfig';
import { formatDimension } from '../../helpers/orders';
import Copyable from '../common/Copyable';
import { Icon, Tooltip } from 'antd';
import { convertToUnitsWithBothLabels, getWeightUnits } from '../../helpers/common';
import { AccountUnitsContext } from '../../context/AccountUnitsContext';
import { withRouter } from 'react-router-dom';
import { OrgContext } from '../../context/OrgContext';
import { displayWeightUnits } from '../orders/helpers';
import { UserContext } from '../../context/UserContext';

class ItemsList extends BaseList {

  constructor(props) {
    super(props);

    this.state = {
      maxColumnLengths: {}, // Initialize maxColumnLengths state
    };
    this.columns = [
      {
        title: "#",
        key: "index",
        width: 40,
        render: (text, record, index) => index + 1,
      },
      {
        key: "item_name",
        title: I18n.t("general.name"),
        dataIndex: "item_name",
        minWidth: 200,
      },
    ];

    // Define columns to check for dynamic width
    const dynamicWidthColumns = ["item_name"];

    // Calculate maximum lengths for dynamic width columns and set state
    dynamicWidthColumns.forEach((column) => {
      const maxLength = Math.max(
        ...this.props.data.map((item) => item[column].length)
      );
      this.state.maxColumnLengths[column] = maxLength 
    });

    // Calculate maximum length and adjust width for columns with specific keys
    // this.columns.forEach(column => {
    //   if (["item_name", "dimensions"].includes(column.key) && column.minWidth && column.dataIndex) {
    //     const maxColumnLength = Math.max(...this.props?.data.map(item => item[column.dataIndex].toString().length));
    //     let calculatedWidth = maxColumnLength * 8; // Adjust the multiplier as per your preference
    //     if (calculatedWidth < column.minWidth) {
    //       calculatedWidth = column.minWidth;
    //     }
    //     column.width = calculatedWidth;
    //   }
    // });

    if (this.props.showOrderNo !== false) {
      this.columns.push({
        key: 'customer_order_number',
        title: I18n.t('order.order_no'),
        dataIndex: 'customer_order_number',
        render: (text, record, index) => (
          <div
            onClick={() =>
              this.props.orderDetailsViewClick(record.customer_order_id)
            }
          >
            <u className="text-decoration-dashed cursorPointer">{text}</u>
          </div>
        ),
      });
    }
    this.columns.push({
      key: 'weight',
      title: I18n.t('order.weight'),
      dataIndex: 'item_weight',
      width: 120,
      render: (data, record) => (
        <Fragment>
        <div>
            <div>{!isEmpty(data) && data.toFixed(
              Number(
                _.get(
                  this.props.userContext,
                  "orgRules.org_billing_rules.round_off_decimals",
                  "2"
                )
              )
            )} lbs</div>
          <div style={{ color: "#999999" }}>
            {" "}
            {!isEmpty(record.item_weight_in_kgs) &&
              record.item_weight_in_kgs.toFixed(
                Number(
                  _.get(
                    this.props.userContext,
                    "orgRules.org_billing_rules.round_off_decimals",
                    "2"
                  )
                )
              ) + " kgs"}{" "}
          </div>
        </div>
      </Fragment>      
      ),
    });
    if (this.props.showQuantity !== false) {
      this.columns.push({
        key: 'quantity',
        title: I18n.t('order.pcs'),
        dataIndex: 'item_quantity',
      });
    }
    if (this.props.showItemType !== false) {
      this.columns.push({
        key: 'item_type',
        title: I18n.t('order.item_type'),
        dataIndex: 'item_type',
      });
    }
    
    // Only show item_class for billing, invoices, and quotes pages
    if (window.location.pathname.match(/\/(billing|invoices|quotes)/) && this.props.currentOrg.code == 'LK') {
      this.columns.push({
        key: 'item_class',
        title: I18n.t('order.item_class'),
        dataIndex: 'item_class',
        render: (text, record) => _.get(record, 'item_class', ''),
      });
    }
    
    this.columns.push({
      key: 'model',
      title: I18n.t('order.model_no'),
      dataIndex: 'item_model',
      width: 90,
    });
    if (this.props.showSerialNo === true) {
      this.columns.push({
        key: 'serial_number',
        title: I18n.t('order.serial_no'),
        dataIndex: 'serial_number',
        width:130,
        render: (data) => data ? <span>{data}&nbsp;<Copyable text={data} /></span> : ''
      });
    }
    if (this.props.showDimensions !== false) {
      this.columns.push({
        key: 'dimensions',
        title: <div>{I18n.t('order.dimensions')}&nbsp;(<small>L * W * H</small>)</div>,
        dataIndex: 'item_width',
        width:200,
        render: (data, record) => (
          <Fragment>{formatDimension(record.item_length, record.item_length_uom, record.item_width, record.item_width_uom, record.item_height, record.item_height_uom)}</Fragment>
        ),
      });
    }

    if(this.props.showActions === true){
      this.columns.push({
        key: "actions",
        render: (id, record) => (
          <Fragment className="line-actions">
            {record.item_picture_data?.some(item => _.get(item, "exception_code") !== "") && (
              <Tooltip title={I18n.t("order.item_failure")} placement="top">
                <Icon type="info-circle" className="info-icon" onClick={() => { console.log('Icon clicked!'); this.props.handleModalView(record); }} />
              </Tooltip>
            )}
          </Fragment>
        ),
      });
    }

    if (this.props.showPod !== false) {
      this.columns.push({
        key: 'images',
        title: I18n.t('general.pod'),
        render: (text, record, index) => {
          if (this.props.stopType !== 'WH') {
            const pictures = [];
            let signature_imgs = [];
            const item_pictures =
                record.pictures && record.pictures.length > 0
                  ? record.pictures
                  : [];
            item_pictures.forEach((pic) => {
              if (pic.image_type === 'normal' && pic.picture && pic.picture.url) {
                pictures.push(pic.picture.url);
              }
            });
            signature_imgs = item_pictures.filter(pic => (pic.image_type === 'signature' && pic.picture && pic.picture.url));
            const signature = signature_imgs.length > 0 ? signature_imgs[0].picture.url : '';
            return pictures.length > 0 || signature !== '' ? (
              <Leg
                pictures={pictures}
                signature={signature}
                pagination={{ position: 'none' }}
                size="small"
                showType="iconClick"
              />
            ) : (
              ''
            );
          }
        },
        align: 'center',
      });
    }
  }
}

// export default ItemsList;
export default withRouter((props) => {
  const orgContextData = useContext(OrgContext);
  const { currentOrg } = orgContextData;
  const userContext = useContext(UserContext);
  return (
    <Fragment>
      <ItemsList
        currentOrg={ currentOrg }
        userContext={ userContext }
        { ...props }
      />
    </Fragment>
  );
});

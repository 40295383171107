/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import _ from 'lodash';
import BaseList from '../../BaseList';
import { formateImageName, isEmpty } from '../../../common/Common';
import AppConfig from '../../../config/AppConfig';
import { Avatar, Icon, Tooltip, Popover, Button } from '../../../common/UIComponents';
import unlink from '../../../../assets/images/unlink.png';
import link from '../../../../assets/images/link.png';
import OrderZoneNames from '../../orders/OrderZoneNames';
import PropTypes from 'prop-types'
import I18n from '../../../common/I18n';

class UsersList extends BaseList {
  constructor(props) {
    super(props);
    const userColumns = [
      {
        key: 'name',
        title: I18n.t('general.name'),
        dataIndex: 'id',
        render: (id, profile, rowNumber) => {
          const employeeCode = profile.employee_code;
          const fullName = profile.full_name ? profile.full_name : `${profile.first_name} ${profile.last_name}`;
          return (
            <Fragment>
              <Tooltip title={fullName}>
                { (profile.image && profile.image.url) ?
                  <Avatar
                    src={profile.image.url}
                    size={20}
                  />
                  :
                  <Avatar
                    size={20}
                    style={{ fontSize: 12, backgroundColor: `#${AppConfig.colors[rowNumber]}` }}
                  >
                    {formateImageName(fullName)}
                  </Avatar>
                } &nbsp;
                {(employeeCode || fullName)}
              </Tooltip>
            </Fragment>
          );
        },
        ellipsis: true,

      }];
      // {
      //   key: 'email',
      //   title: I18n.t('general.email'),
      //   dataIndex: 'email',
      // },
    if (this.props.showMobile !== false) {
      userColumns.push({
        key: 'mobile',
        title: I18n.t('general.mobile_number'),
        dataIndex: 'mobile_number',
      });
    }
    if (this.props.showFleetDetails) {
      userColumns.push(
        {
          key: 'vehicle_type',
          title: I18n.t('fleet.vehicle_type'),
          dataIndex: 'fleet_details',
          width: 100,
          render: (fleet_details) => {
            const { vehicle_type } = fleet_details;
            return vehicle_type;
          },
        },
        {
          key: 'vehicle_number',
          title: I18n.t('fleet.vehicle_number'),
          dataIndex: 'fleet_details',
          width: 100,
          render: (fleet_details) => {
            const { vehicle_number } = fleet_details;
            return vehicle_number;
          },
        },
        {
          key: 'vehicle_weight',
          title: I18n.t('fleet.vehicle_weight'),
          dataIndex: 'fleet_details',
          width: 100,
          render: (fleet_details) => {
            const { vehicle_weight } = fleet_details;
            return vehicle_weight;
          },
        },
        {
          key: 'vehicle_volume',
          title: I18n.t('fleet.vehicle_volume'),
          dataIndex: 'fleet_details',
          width: 100,
          render: (fleet_details) => {
            const { volume } = fleet_details;
            return volume;
          },
        }
      );
    }


    if (this.props.showStops !== false) {
      userColumns.push({
        key: 'stops',
        title: `#${I18n.t('preplan.stops')}`,
        render: record => <div>{record.stops.length}</div>,
        align: 'center',
        width: 68,
      });
    }
    if (this.props.showZones !== false) {
      userColumns.push({
        key: 'zones',
        title: I18n.t('menu.zones'),
        render: data => (
          <div>
            {
              this.setZones(data, this.props.driverZones)
            }
          </div>
        ),
      });
    }
    if (this.props.showLinks !== false) {
      userColumns.push({
        key: 'link',
        render: data => (data.stops.length > 0 ?
          <Tooltip title="Show in dispatch">
            <img src={link} style={{ width: 12, marginRight: 8 }} alt="link" onClick={() => this.props.goToTab('2')} />
          </Tooltip>
          :
          <Tooltip title="Assign">
            <img src={unlink} style={{ width: 12, marginRight: 8 }} alt="unlink" onClick={() => this.props.goToTab('1')} />
          </Tooltip>),
        align: 'center',
      });
    }
    if (this.props.showResequence === true) {
      userColumns.push({
        key: 'resequence',
        render: data => (
          <Fragment>
            {
              data.stops && data.stops.length > 0 &&
              <Tooltip title={I18n.t('routes.resequence')}>
                <Button type="primary" size="small" onClick={() => this.props.callResequence(data.route_id)}>{I18n.t('routes.resequence')}</Button>
              </Tooltip>
            }
          </Fragment>
        ),
        align: 'center',
        width: 92,
      });
    }
    if (this.props.showOptError === true) {
      userColumns.push({
        key: 'opt_error',
        render: data => (
          <Fragment>
            {data.hasError}
            {
              !isEmpty(data.hasError) &&
              <div>
                {
                  data.hasError === false ?
                    <Icon type="check-circle" className="textCompleted textBold fontSize20" /> :
                    <Popover
                      title={I18n.t('general.error')}
                      content={data.optimizeError}
                    >
                      <Icon type="warning" className="textBold textRed fontSize20" />
                    </Popover>
                }
              </div>
            }
          </Fragment>
        ),
        width: 25,
        align: 'center',
      });
    }
    this.columns = userColumns;
  }

  setZones = (data, driverZones) => {
    const zones = _.isArray(driverZones) ? driverZones : [];
    const zoneIndex = _.findIndex(zones, ['driver_id', data.id]);
    return (zoneIndex >= 0 ?
      <OrderZoneNames orderZoneName={zones[zoneIndex].driver_zone_name} />
      :
      <Fragment />);
  }
}

export default UsersList;

UsersList.propTypes = {
  showMobile: PropTypes.bool,
  showStops: PropTypes.bool,
  showZones: PropTypes.bool,
  showLinks: PropTypes.bool,
  showResequence: PropTypes.bool,
  showOptError: PropTypes.bool,
  showFleetDetails: PropTypes.bool,
  driverZones: PropTypes.arrayOf(PropTypes.shape({})),
  goToTab: PropTypes.func,
  callResequence: PropTypes.func,
};

UsersList.defaultProps = {
  showFleetDetails: false,
}

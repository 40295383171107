import { isEmpty } from "lodash";
import React from "react";
import { Tag } from "../../common/UIComponents";
import PropTypes from "prop-types";

const FilterTag = (props) => {
  const {
    children,
    InternationalizationText,
    onClose,
    filterValue,
    closable,
    ...rest
  } = props;
  if (!isEmpty(filterValue)) {
    return (
      <Tag
        color="blue"
        closable={ closable }
        onClose={ onClose }
        className="filterTag"
        { ...rest }
      >
        { InternationalizationText }: { children }
      </Tag>
    );
  } else {
    return null;
  }
}
export default FilterTag;

FilterTag.defaultProps = {
  closable: true,
  onClose: () => {},
  filterValue: "",
  children: "",
  InternationalizationText: "",
};
FilterTag.propTypes = {
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  filterValue: PropTypes.string,
  children: PropTypes.string,
  InternationalizationText: PropTypes.string,
};
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { isEmpty } from "../../common/Common";
import { Tooltip, Popover, Button, Spin, Row, Col, Link } from "../../common/UIComponents";
import { fetchOrderItems } from "../../api/OrdersApi";
import ItemsList from "../stops/ItemsList";
import refresh from "../../../assets/images/refresh.png";
import BaseModal from "../BaseModal";
import ItemsExceptionsList from "../orders/ItemsExceptionsList";
import I18n from "../../common/I18n";
import ItemsExceptionModal from "../orders/ItemExceptionModal";
import { AccountUnitsContext } from "../../context/AccountUnitsContext";
import { OrgContext } from "../../context/OrgContext";


const OrderQuantity = ({ record, keyId, children, itemsRequired  = true, primaryKey = "id",showActions,isMilitaryTime,style,setIsItemVisible}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [showItemExceptionModal, setShowItemExceptionModal] = useState(false);
  const accountUnitContext = useContext(AccountUnitsContext);
  const org_context = useContext(OrgContext);

  const hide = () => {
    setVisible(false);
    setIsItemVisible(false);
  };

  const closeItemExceptionModal = () => {
    setShowItemExceptionModal(false);
  }

  const handleVisibleChange = visibleValue => {
    if(visibleValue){
      if(data.length === 0){
        fetchData(visibleValue);
      } else {
        // setVisible(visibleValue);
      }

    }else {
      setData([]);
      // setVisible(visibleValue);
    }
    setVisible(visibleValue);
    setIsItemVisible(visibleValue);
  };
  
  //
  // useEffect(() => {
  //   fetchData();
  // }, []);

  // Fetch data function
  const fetchData = async (visibleValue = false) => {
      setIsLoading(true);
      const response = await fetchOrderItems(record[primaryKey])
      const data = response.items || []
      if(response.success){
        const new_data = !_.isEmpty(data) ? data.map((item) => ({ ...item, account_code: record.account_code })) : data
        setData(new_data);
      } else{
        setData([]);
      }
      // if(visibleValue){
      //   setVisible(visibleValue);
      // }
      setIsLoading(false);
    };

    const renderItemsExceptionModal = () => {
      return (
        <ItemsExceptionModal
          visible={showItemExceptionModal}
          onCancel={closeItemExceptionModal}
          data={currentRecord}
          isMilitaryTime={isMilitaryTime}
        />
      );
    };    

  // Render the Popover and the button with a custom class name
  return (
      itemsRequired ? 
      <>
        <Popover
        placement={"topLeft"}
        title={<Row>
          <Col xs={20} className="fontSize12 textBold">#{record.customer_order_number} Items</Col>
          <Col xs={4} className="alignRight">
              <span onClick={fetchData} className="clickableLink fontSize11">
              <img
                  src={refresh}
                  className="cursorPointer textPrimary marginRight10"
                  style={{ width: 12, marginTop: -2 }}
              />
              </span>
              <span onClick={hide} className="clickableLink fontSize11">X</span>
          </Col>
        </Row>}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        content={<Spin spinning={isLoading}>
          <div className="smallTable" style={{ maxWidth : 1000, overflowY: 'auto' }}>
          <ItemsList 
              accountUnitContext={accountUnitContext}
              currentOrg={org_context.currentOrg}
              data={data} 
              size="small" 
              pagination={{ position: 'none'}}
              showDimensions={true}
              showOrderNo={false}
              showPod={false}
              showItemType={false}
              showSerialNo={true}
              showActions={showActions}
              rowClassName={(record, index) =>
                showActions && record.item_picture_data?.some(item => _.get(item, "exception_code") !== "") ? "errRow" : ""
              }
              scroll={showActions ? { y: 350, x: "max-content" } : undefined}
              handleModalView={(record) => {
                setCurrentRecord(record);
                setShowItemExceptionModal(true);
                setVisible(false);
              }
              }
              />
          </div>
        </Spin>}
      >
        <span className="popover-button textBold" id={keyId}><Link style={style}>{children}</Link></span>
      </Popover>
      {showItemExceptionModal && renderItemsExceptionModal()}
      </>
    :
    children
    );
};

{/*
// const OrderQuantity = ({ record, keyId }) => {
//     const [data, setData] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     useEffect(() => {
//       // Fetch data function
//       setIsLoading(true);
//       const fetchData = async () => {
//         const response = await fetchOrderItems(record.id)
//         const data = response.items || [];
       
//         if(response.success){
//           setData(data);
//         } else{
//           setData([]);
//         }
//         setIsLoading(false);
//       };
  
//       // Define the button element and attach the onMouseEnter event listener
//       const button = document.querySelector("#"+keyId);
//       button.addEventListener("click", fetchData);
  
//       // Remove the event listener when the component unmounts
//       return () => {
//         button.removeEventListener("click", fetchData);
//       };
//     }, []);
  
//     // Render the Popover and the button with a custom class name
//     return (
//       <Popover
//         title="Items"
//         trigger={["click"]}
//         content={<Spin spinning={isLoading}>
//           <div className="smallTable">
//           <ItemsList 
//               data={data} 
//               size="small" 
//               pagination={{ position: 'none'}}
//               showDimensions={false}
//               showOrderNo={false}
//               showPod={false}
//               showItemType={false}
//               showSerialNo={true}
//           />
//           </div>
//         </Spin>}
//       >
//         <span className="popover-button textBold clickableLink" id={keyId}><u>{record.quantity}</u></span>
//       </Popover>
//     );
//   };
*/}



OrderQuantity.propTypes = {
  record: PropTypes.shape().isRequired,
  setIsItemVisible: PropTypes.func,
};
OrderQuantity.defaultProps = {
  setIsItemVisible: () => {},
};

export default OrderQuantity;

import AppConfig from "../config/AppConfig";
import { Call, ApiUrl, ParseGeneralResponse, optionalPageParams } from "./ApiUtils";

export const InvoicesActivitiesApi = {
  fetchInvoiceActivity: ({
    organization_id,invoice_id
  }) => {
    const url = ApiUrl(`v2/account_invoices/invoice_activity?organization_id=${organization_id}&account_invoice_id=${invoice_id}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { invoice_activity: "invoice_audit" },
    });
  },
}

import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

const FormIcon = (props) => {
  const { handleClick, style,color="#045dac" } = props; // 9e1068
  return (
    <>
      <Icon
        type="form"
        style={{ color, ...style }}
        onClick={handleClick}
      />
    </>
  );
};

FormIcon.propTypes = {
  handleClick: PropTypes.func,
};

FormIcon.defaultProps = {
  handleClick: () => {},
};
export default FormIcon;

import React, { Fragment, useContext } from 'react';
import moment from 'moment';
import { Row, Col, Tag } from 'antd'; 
import I18n from '../../common/I18n';

const ConfiguredDeliveryTime = ({ start_time, end_time, isMilitaryTime }) => {
  if (!start_time || !end_time) return null;
  return (
    <Row className="marginBottom15" type='flex' gutter={ 4 }>
      <Col className="notes_content textBold">
        { I18n.t('order.estimated_delivery_timings') }:
        { isMilitaryTime ? (
          <Fragment>
            { start_time } - { end_time }
          </Fragment>
        ) : (
          <Fragment>
            { moment(start_time, 'HH:mm').format('hh:mm A') } -{ ' ' }
            { moment(end_time, 'HH:mm').format('hh:mm A') }
          </Fragment>
        ) }
      </Col>
      <Col>
        <Tag color={ isMilitaryTime ? 'green' : 'red' }>
          { isMilitaryTime ? 'Military Time (24-Hours Format)' : '12-Hours Format' }
        </Tag>
      </Col>
    </Row>
  );
};
export default ConfiguredDeliveryTime;

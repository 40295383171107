import React, { Component, Fragment ,useState} from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Popconfirm,
  Icon,
  Spin,
  Divider,
  Tooltip,
  Alert,
  Checkbox,
} from "../common/UIComponents";
import { DriverSelect } from "../components/common/SelectDropdowns/BaseSelect";
import FormButtons from "../components/common/FormButtons";
import {
  fetchDriverPayInfo,
  saveDriverPayInfo,
  deleteDriverPayInfo,
  saveDriverAdjustmentInfo,
  getDriverAdjustmentInfo,
  UpdateDriverAdjustmentInfo,
  deleteDriverAdjustmentInfo
} from "../api/Billing";
import userStore from "../stores/UserStore";
import { alertMessage, isEmpty } from "../common/Common";
import _ from "lodash";
import DeleteIcon from "../components/common/DeleteIcon";
import I18n from "../common/I18n";
import { renderAlertMessage } from "../helpers/common";
import EditIcon from "../components/common/EditIcon"
import InvoiceContact from "../components/billing/InvoiceContact";
import { fetchBillingDetails } from "../api/Billing";
import InvoicesItemList from "../components/billing/InvoicesItemList";
import DriverAdjustmentsTable from "../components/billing/DriverAdjustmentsTable";


class BillingDriverPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      isDisplayAdd: false,
      customer_orders: [],
      driver_info: [],
      currentInvoice: this.props.currentInvoice || {},
      orderDriverPayments: [],
      clearDisabled: false,
      // saveDisabled: false,
      driverAdjustments: [],
      showError: false,
      delivered_driver: false,
      currentInvoiceDriverId: this.props.currentInvoice?.driver_details
        ?.driver_id
        ? this.props.currentInvoice.driver_details.driver_id
        : null,
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
      driverIds: [], 
    };
  }

  componentDidMount() {
    this.setInitialData();
    this.getdriverAdjustmentInfo();
    // this.checkButtonDisable();
    this.extractDriverIds();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.currentInvoice, prevProps.currentInvoice)) {
      this.setState(
        {
          currentInvoice: this.props.currentInvoice,
          currentInvoiceDriverId: this.props.currentInvoice?.driver_details
            ?.driver_id
            ? this.props.currentInvoice.driver_details.driver_id
            : null,
        },
        () => {
          this.setInitialData();
          this.extractDriverIds();
        }
      );
    }
  }

  extractDriverIds = () => {
    const { order_driver_details } = this.state.currentInvoice;

    // Check if order_driver_details is available and not empty
    if (!order_driver_details || order_driver_details.length === 0) return;

    // Extract driver IDs from the order_driver_details array
    const driverIds = order_driver_details.flatMap(order =>
      order.driver_details.map(driver => driver.driver_id)
    );

    // Update the state with the driver IDs
    this.setState({ driverIds });
  }

  // checkButtonDisable = () => {
  //   const orderDriverPayments = [...this.state.orderDriverPayments];
  //   let driverIsEmptyCount = 0;
  //   orderDriverPayments.forEach((rec, index) => {
  //     if (rec.driver_payments.length === 0) {
  //       driverIsEmptyCount++;
  //     }
  //   });
  //   if (driverIsEmptyCount === orderDriverPayments.length) {
  //     this.setState({ saveDisabled: true });
  //   } else {
  //     this.setState({ saveDisabled: false });
  //   }
  // };
  handleInvoiceResponse = (result, id) => {
    const defaultInvoice = {
      description: {},
      account_details: {},
      customer_details: {},
      customer_order_id: id,
      remarks: _.get(result, 'remarks', []),
      consolidated_order_numbers: _.get(result, 'consolidated_order_numbers', []),
      is_driver_settlement_approved: result.is_driver_settlement_approved,
      pickup_driver_pay_details: result.pickup_driver_pay_details || {},
      accessorial_charges: [],
      adjustment_charges: [],
      transportation_charges: [],
      order_info: {},
      status: result.billing_status,
      error_log: [],
      error_messages: [],
    };

    const currentInvoice = _.merge({}, defaultInvoice, result.invoice);

    const error_logs = result.error_logs || [];
    currentInvoice.error_log = _.map(_.filter(error_logs, 'error_log'), 'error_log');
    currentInvoice.error_messages = _.map(_.filter(error_logs, 'error_message'), 'error_message');

    return currentInvoice;
  };
  sortPriceList = (data) => {
    const sortedData = _.cloneDeep(data);

    // Sort accessorial charges
    sortedData.accessorial_charges.forEach(charge => {
      charge.price_list = _.sortBy(charge.price_list, 'invoice_name');
    });

    // Sort transportation charges
    // sortedData.transportation_charges.forEach(charge => {
    //   charge.price_list = _.sortBy(charge.price_list, 'invoice_name');
    // });

    // Sort fuel surcharges
    sortedData.fuel_surcharges.forEach(charge => {
      charge.price_list = _.sortBy(charge.price_list, 'invoice_name');
    });

    // Sort adjustment charges
    sortedData.adjustment_charges.forEach(charge => {
      charge.price_list = _.sortBy(charge.price_list, 'invoice_name');
    });

    return sortedData;
  };

  getBillingDetails = (id) => {
    this.setState({ isLoading: true });
    fetchBillingDetails(id, this.state.currentOrderGroupType).then((result) => {
      if (result.success) {
        const invoiceStatus = result.billing_status;
        const orgCode = _.get(this.props, 'userContext.currentOrg.code', '');
        const showHoldBucket = _.get(this.props, 'organizationSettings.show_hold_bucket', '') == "true";
        let currentInvoice = this.handleInvoiceResponse(result, id);
        currentInvoice = this.sortPriceList(currentInvoice);
        const defaultTransportationChargeGLCode = result.invoice.transportation_gl_code;
        const transportationCharges = currentInvoice?.transportation_charges?.length ? currentInvoice.transportation_charges[0] : []
        this.setState(
          {
            invoice: currentInvoice,
            inProgress: false,
            consolidatedOrderNumbers: currentInvoice.consolidated_order_numbers,
            isDriverSettlementApproved: currentInvoice.is_driver_settlement_approved,
            is_pickup_by_driver: _.get(currentInvoice, 'pickup_driver_pay_details.is_pickup_by_driver', "false"),
            pickup_order_driver_id: _.get(currentInvoice, 'pickup_driver_pay_details.pickup_order_driver_id', ""),
            pickup_order_amount: _.get(currentInvoice, 'pickup_driver_pay_details.pickup_order_amount', null),
            decimalPoints: window.localStorage.getItem('round_off_decimals'),
            isLoading: false,
            organizationSettings: this.props.organizationSettings,
            showActions: currentInvoice.status === 'READY_FOR_BILLING' ? true : false,
            isInvoiceLocked: currentInvoice?.transportation_charges &&
              currentInvoice.transportation_charges.filter(rec => rec.is_locked == 'true').length > 0 ? true : false,
            isOnHold: showHoldBucket ? invoiceStatus == 'HOLD' : false,
            defaultTransportationChargeGLCode: defaultTransportationChargeGLCode, // Set default GL code
            ikeaChargesrecord:
              this.state.newIkeaChargesRecord?.length > 0
                ? [
                    ...(transportationCharges?.price_list || []),
                    ...this.state.newIkeaChargesRecord,
                  ]
                : transportationCharges?.price_list || [],
          },
          () => {
            if(Object.keys(this.state.rawInvoice).length === 0){
              this.getAccountLos();
            } else{
              // this.setInvoiceLos()
            }
          }
        );
      } else {
        renderAlertMessage(result.errors);
        this.setState({
          invoice: {},
          isLoading: false,
        });
      }
    });
  };

  
  setInitialData = () => {
    const { currentInvoice } = this.state;
    const orderDriverPayments = currentInvoice?.transportation_charges
      ? currentInvoice?.transportation_charges.map((order) => ({
          order_id: order.order_id,
          order_no: order.order_number,
          locations: order.locations || [],
          driver_payments: [
            { driver_id: "", amount: "", delivered_driver: false },``
          ],
        }))
      : [];
    this.setState(
      {
        orderDriverPayments,
      },
      () => {
        this.getdriverPayInfo();
      }
    );
  };

  getdriverPayInfo = () => {
    const { currentInvoice } = this.state;
    this.setState({ inProgress: true });

    const customer_order_ids = currentInvoice?.transportation_charges?.length > 0 ? currentInvoice.transportation_charges.map(
      (order) => order.order_id
    ) : [];
    fetchDriverPayInfo(customer_order_ids).then((result) => {
      if (result.success) {
        const order_driver_payments = result.order_driver_payments || [];
        this.setDriverPayments(order_driver_payments);
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };


  getdriverAdjustmentInfo = () => {
    const { currentInvoice } = this.state;
    this.setState({ adjProgress: true });
  
    const customer_order_ids = currentInvoice?.transportation_charges?.length > 0 
      ? currentInvoice.transportation_charges.map(order => order.order_id) 
      : [];
  
    getDriverAdjustmentInfo(customer_order_ids).then(result => {
      if (result.success) {
        const driver_pay_adjustments = result.driver_pay_adjustments || [];
        this.setState({
          driverAdjustments: driver_pay_adjustments,
          adjProgress: false
        });
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ adjProgress: false });
      }
    });
  };


  setDriverPayments = (order_driver_payments) => {
    const { orderDriverPayments } = this.state;
    if (order_driver_payments.length > 0) {
      order_driver_payments.forEach((rec, index) => {
        const orderIndex = _.findIndex(orderDriverPayments, {
          order_id: rec.customer_order_id,
        });
        orderDriverPayments[orderIndex].driver_payments = rec.driver_payments;
        // for setting checkbox to be checked
        const consideredDriver =
          rec.driver_payments.findIndex(
            (record, index) =>
              record.driver_id === this.state.currentInvoiceDriverId
          ) >= 0;
        if (consideredDriver > 0) {
          orderDriverPayments[orderIndex].delivered_driver = true;
        }
        //
      });
    } else {
      orderDriverPayments.forEach((rec, index) => {
        orderDriverPayments[index].driver_payments = [
          { driver_id: "", amount: "", delivered_driver: false },
        ];
      });
    }
    this.setState({
      orderDriverPayments,
      inProgress: false,
    });
    // this.checkButtonDisable();
  };

  handleDetailsChange = (orderIndex, index, element, value) => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    orderDriverPayments[orderIndex].driver_payments[index][element] = value;
    this.setState({
      orderDriverPayments,
    });
  };

  // handleRateChange = (orderIndex, rateIndex, value) => {
  //   const orderDriverPayments = [...this.state.orderDriverPayments];
  //   orderDriverPayments[orderIndex].driver_payments[rateIndex].amount = value;
  //   this.setState({ orderDriverPayments });
  // };

  handleSave = () => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    let fieldIsEmptyCount = 0;
    orderDriverPayments.forEach((rec, index) => {
      const driverEmpty =
        rec.driver_payments.findIndex(
          (record, index) =>
            (!isEmpty(record.driver_id) && isEmpty(record.amount)) ||
            (isEmpty(record.driver_id) && !isEmpty(record.amount))
        ) >= 0;

      if (driverEmpty) {
        fieldIsEmptyCount++;
      }
    });
    if (fieldIsEmptyCount > 0) {
      this.setState({
        showError: true,
      });
    } else {
      this.setState({ inProgress: true });
      const customerOrders = [];
      this.state.orderDriverPayments.forEach((order) => {
        // const { ["order_no"]: deletedKey, ...rest } = order;
        const { order_no, delivered_driver, ...rest } = order;
        if (rest?.driver_payments.length > 0) {
          const orderDriverPayments = rest.driver_payments.filter(
            (rec) => !isEmpty(rec.driver_id) && !isEmpty(rec.amount)
          );
          if (orderDriverPayments.length > 0) {
            customerOrders.push({
              ...rest,
              driver_payments: [...orderDriverPayments],
            });
          }
        }
      });
      const data = {
        customer_orders: customerOrders,
        customer_order_ids:
          this.state.currentInvoice?.transportation_charges.map(
            (order) => order.order_id
          ),
        account_id: this.state.currentInvoice.account_details.id,
        organization_id: userStore.getStateValue("selectedOrg"),
      };

      saveDriverPayInfo(data).then((result) => {
        if (result.success) {
          alertMessage("Saved Successfully");
          this.setState(
            {
              inProgress: false,
              showError: false,
              // saveDisabled: false,
            },
            () => {
              this.setInitialData();
            }
          );
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ inProgress: false });
        }
      });
    }
  };

  handleClear = () => {
    const { orderDriverPayments } = this.state;
    this.setState({ inProgress: true });
    const customer_order_ids = orderDriverPayments.map(
      (order) => order.order_id
    );
    deleteDriverPayInfo(customer_order_ids).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
        this.setState({ showError: false });
        this.setDriverPayments([]);
        this.clearCheckboxes();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  clearCheckboxes = () => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    orderDriverPayments.forEach((record) => {
      record.delivered_driver = false;
    });
    this.setState({ orderDriverPayments });
  };

  handleCheckedChange = (orderIndex, element, value) => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    orderDriverPayments[orderIndex][element] = value;
    this.setState({ orderDriverPayments }, () => {
      orderDriverPayments.forEach((order, index) => {
        if (orderIndex === index) {
          if (order.delivered_driver && order.delivered_driver === true) {
            if (order.driver_payments.length > 0) {
              if (
                isEmpty(order.driver_payments[0].driver_id) &&
                isEmpty(order.driver_payments[0].amount)
              ) {
                order.driver_payments[0].driver_id =
                  this.state.currentInvoiceDriverId;
                order.driver_payments[0].amount = "";
                order.driver_payments[0].delivered_driver = true;
              } else {
                order.driver_payments.unshift({
                  driver_id: this.state.currentInvoiceDriverId,
                  amount: "",
                  delivered_driver: true,
                });
              }
            } else {
              order.driver_payments.push({
                driver_id: this.state.currentInvoiceDriverId,
                amount: "",
                delivered_driver: true,
              });
            }
          } else if (order.delivered_driver === false) {
            order.driver_payments = order.driver_payments.filter(
              (order) => order.driver_id !== this.state.currentInvoiceDriverId
            );
          }
        }
      });

      this.setState({ orderDriverPayments });
    });
  };

  addDriverPayRow = (orderIndex) => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    orderDriverPayments[orderIndex].driver_payments.push({
      driver_id: "",
      amount: "",
    });
    this.setState({
      orderDriverPayments,
    });
    // this.checkButtonDisable();
  };

  removeDriverPayRow = (orderIndex, rowIndex) => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    if (
      orderDriverPayments.length > 0 &&
      orderDriverPayments[orderIndex].driver_payments.length > 0 &&
      orderIndex >= 0
    ) {
      orderDriverPayments[orderIndex].driver_payments.splice(rowIndex, 1);

      this.setState({
        orderDriverPayments,
        showError: false,
      });
    }
    // this.checkButtonDisable();
  };

  // onDriverPaySave = (record, order) => {
  //     const data = {
  //       customer_order_id: order.order_id,
  //         driver_adjustments: {
  //         gl_code: record.glCode,
  //         description: record.description,
  //         amount: record.amount,
  //         location_id: record.orderType
  //       },
  //       account_id: this.state.currentInvoice.account_details.id,
  //       organization_id: userStore.getStateValue("selectedOrg"),
  //     };
  //     this.setState({
  //       inProgress: true
  //     })

  //     saveDriverAdjustmentInfo(data).then((result) => {
  //       if (result.success) {
  //         alertMessage("Saved Successfully");
  //         this.setState(
  //           {
  //             inProgress: false,
  //           }
  //         );
  //       } else {
  //         alertMessage(result.errors[0], "error", 10);
  //         this.setState({ inProgress: false });
  //       }
  //     });
  // };

  onDriverPaySave = (record, order) => {
    const data = {
      customer_order_id: order.order_id,
      driver_adjustments: {
        gl_code: record.glCode, // Ensure keys are correctly mapped
        description: record.description,
        amount: record.amount,
        location_id: record.location_id
      },
      account_id: this.state.currentInvoice.account_details.id,
      organization_id: userStore.getStateValue("selectedOrg"),
    };
    if(!record.isNew){
      data.id = record.key;
    }
  
    console.log('Payload Data:', data);  // Log the final payload data
  
    this.setState({ adjProgress: true });
  
    saveDriverAdjustmentInfo(data).then((result) => {
      if (result.success) {
        alertMessage("Saved Successfully");
        this.setState({ adjProgress: false });
        this.getdriverAdjustmentInfo()
      } else {
        alertMessage(result.errors[0], "error", 10);
        this.setState({ adjProgress: false });
      }
    });
  };
  
  onDriverPayDelete = (id) => {
    const organization_id =  userStore.getStateValue("selectedOrg");  
    this.setState({ adjProgress: true });
    deleteDriverAdjustmentInfo(id, organization_id ).then((result) => {
      if (result.success) {
        alertMessage(result.message || "Deleted Successfully");
        this.setState({ 
          adjProgress: false, 
          driverAdjustments: this.state.driverAdjustments.filter(adj => adj.id !== id) 
        });
      } else {
        renderAlertMessage(result.errors[0], "error", 10);
        this.setState({ adjProgress: false });
      }
    });
  };
  

  render() {
    const { orderDriverPayments, currentInvoice } = this.state;
    console.log(orderDriverPayments);
    const { drivers, isLocked } = this.props;
    const filteredDrivers = drivers.filter(
      (driver) => !this.state.driverIds.includes(driver.id)
    );
    const { inProgress, currentInvoiceDriverId } = this.state;
    const selectedDrivers = drivers.filter(
      (driver) => driver.id === this.state.currentInvoiceDriverId
    );
    console.log(currentInvoiceDriverId)
    console.log(selectedDrivers);
    const isDisabled = isLocked; // isLocked || this.state.currentInvoice.status === "APPROVED";
    return (
      <div className="content">
        {/* Section 1: Pickup Driver Pays */}
        <Row>
          <Col xs={24} className="alignLeft">
            <span
              style={{
                fontSize: 16,
                fontStyle: "Regular",
                fontWeight: "bold",
                color: "#395484",
              }}
            >
              Pickup Driver Pays:
            </span>
          </Col>
        </Row>
    
        <Spin spinning={this.state.inProgress} delay={1000}>
          {orderDriverPayments.map((order, orderIndex) => {
            return (
              <Fragment key={`pickupDriverPays${orderIndex}`}>
                <Row>
                  <Col xs={12} className="alignLeft">
                    <span
                      style={{
                        fontSize: 14,
                        fontStyle: "italic",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        color: "#395484",
                      }}
                    >
                      For Order #{order.order_no}
                    </span>
                  </Col>
                </Row>
    
                {orderDriverPayments &&
                orderDriverPayments[orderIndex].driver_payments.length > 0 ? (
                  <Fragment>
                    <Row className="marginTop10" gutter={32}>
                      <Col xs={24}>
                        <Col xs={8}>
                          <span style={{ fontWeight: 700 }}>
                            <sup style={{ color: "red" }}>*</sup>
                            {I18n.t("general.driver")}:
                          </span>
                        </Col>
                        <Col xs={8}>
                          <span style={{ fontWeight: 700 }}>
                            <sup style={{ color: "red" }}>*</sup>
                            {I18n.t("account.billing.order_amount")}:
                          </span>
                        </Col>
                      </Col>
                    </Row>
    
                    {orderDriverPayments[orderIndex].driver_payments.map(
                      (driverInfoRow, index) => {
                        const isRowModified =
                          (!isEmpty(driverInfoRow.driver_id) &&
                            isEmpty(driverInfoRow.amount)) ||
                          (isEmpty(driverInfoRow.driver_id) &&
                            !isEmpty(driverInfoRow.amount));
    
                        return (
                          <Row
                            className="marginTop10"
                            key={`orderRow${order.orderIndex}${index}`}
                            gutter={32}
                          >
                            <Col xs={24} style={{ marginBottom: 6 }}>
                              <Col xs={8}>
                                <Select
                                  showSearch
                                  value={driverInfoRow.driver_id || ""}
                                  onChange={(e) =>
                                    this.handleDetailsChange(
                                      orderIndex,
                                      index,
                                      "driver_id",
                                      e
                                    )
                                  }
                                  filterOption={(input, option) => {
                                    const children = option.props.children;
                                    if (children && children.length > 0) {
                                      if (Array.isArray(children)) {
                                        return children[0]
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0;
                                      } else {
                                        return children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0;
                                      }
                                    }
                                  }}
                                  style={{ width: "80%", height: "20%" }}
                                  disabled={
                                    driverInfoRow.driver_id ===
                                    this.state.currentInvoiceDriverId
                                  }
                                >
                                  {!this.state.driverIds.includes(driverInfoRow.driver_id)
                                    ? filteredDrivers.map((driver) => (
                                        <Select.Option
                                          key={driver.employee_code}
                                          value={driver.id}
                                        >
                                          {driver.employee_code}
                                        </Select.Option>
                                      ))
                                    : this.props.drivers.map((driver) => (
                                        <Select.Option
                                          key={driver.employee_code}
                                          value={driver.id}
                                        >
                                          {driver.employee_code}
                                        </Select.Option>
                                      ))}
                                </Select>
                                <Col>
                                  {isRowModified &&
                                    isEmpty(driverInfoRow.driver_id) &&
                                    this.state.showError && (
                                      <span className="textRed font500 marginTop5">
                                        {"Please Select!"}
                                      </span>
                                    )}
                                </Col>
                              </Col>
                              <Col xs={8}>
                                <Input
                                  type="number"
                                  value={driverInfoRow.amount}
                                  onChange={(e) =>
                                    this.handleDetailsChange(
                                      orderIndex,
                                      index,
                                      "amount",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                  precision={this.state.decimalPoints}
                                  defaultValue={0}
                                  style={{ width: "80%", height: "20%" }}
                                />
                                <Col>
                                  {isRowModified &&
                                    isEmpty(driverInfoRow.amount) &&
                                    this.state.showError && (
                                      <span className="textRed font500 marginTop5">
                                        {"Please Select!"}
                                      </span>
                                    )}
                                </Col>
                              </Col>
                              <Col
                                xs={8}
                                className="alignLeft"
                                style={{ height: 23, marginLeft: -60 }}
                              >
                                {!isDisabled &&
                                  !this.state.driverIds.includes(driverInfoRow.driver_id) && (
                                  <Popconfirm
                                    placement="topRight"
                                    title={I18n.t("messages.confirm")}
                                    onConfirm={() =>
                                      this.removeDriverPayRow(orderIndex, index)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip title={I18n.t("general.delete")}>
                                      <DeleteIcon className="textBold fontSize18" />
                                      &nbsp;&nbsp;
                                    </Tooltip>
                                  </Popconfirm>
                                )}
                                {!isDisabled &&
                                  orderDriverPayments[orderIndex].driver_payments
                                    .length -
                                    1 ===
                                    index &&
                                  !isEmpty(driverInfoRow.driver_id) &&
                                  !isEmpty(driverInfoRow.amount) && (
                                    <Icon
                                      type="plus-circle"
                                      onClick={() =>
                                        this.addDriverPayRow(orderIndex)
                                      }
                                      size="small"
                                      className="marginLeft10 fontSize18"
                                    />
                                  )}
                              </Col>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Alert
                      message={
                        <h4>
                          No Pickup Driver Payment Configuration is done for above
                          order.
                          <Button
                            type="link"
                            onClick={() => this.addDriverPayRow(orderIndex)}
                          >
                            Click here to configure
                          </Button>
                        </h4>
                      }
                      type="info"
                      showIcon
                      style={{ paddingBottom: 0, paddingTop: 2 }}
                    />
                  </Fragment>
                )}
    
              
              </Fragment>
            );
          })}
        </Spin>
        <Row>
            <Col className="alignCenter">
              <Button
                type="default"
                className="clearButtonStyle"
                onClick={this.handleClear}
                disabled={isDisabled}
                icon="close-circle"
              >
                {I18n.t("general.clear")}
              </Button>
              &nbsp;&nbsp;
              <Button
                type="primary"
                onClick={this.handleSave}
                disabled={isDisabled}
                icon="save"
              >
                {I18n.t("general.save")}
              </Button>
            </Col>
          </Row>
            <Divider />
          {/* Section 2: Driver Transportation Rates */}
          <Row>
            <Col xs={24} className="alignLeft">
              <span
                style={{
                  fontSize: 16,
                  fontStyle: "Regular",
                  fontWeight: "bold",
                  color: "#395484",
                }}
              >
                Driver Transportation Rates:
              </span>
            </Col>
          </Row>
 {/* selectedDrivers.length === 0 if driver is not selected spin is not stopping */}
  <Spin spinning={inProgress} delay={1000}>
    {this.state.currentInvoice.order_driver_details.map((order, orderIndex) => (
      <Fragment key={`driverTransportationRates${orderIndex}`}>
        <Row className="marginTop10">
          <Col xs={12} className="alignLeft">
            <span
              style={{
                fontSize: 14,
                fontStyle: "italic",
                fontWeight: "bold",
                textDecoration: "underline",
                color: "#395484",
              }}
            >
              For Order #{order.customer_order_number}
            </span>
          </Col>
        </Row>
        <Row type='flex'>
          <Col>
            <span
              style={{
                fontStyle: "Regular",
                fontWeight: "bold",
                color: "#395484",
              }}
            >
              {order.driver_details.map((driver, index) => (
                <Fragment key={`driverName${index}`}>
                  {index > 0 && <br />}
                  Update Driver Transportation Rate For : {driver.driver_name}
                  {driver.type_of_location && ` : (${driver.type_of_location})`}
                  &nbsp;
                </Fragment>
              ))}
            </span>
          </Col>
          <Col>
            {order.driver_details.map((driver, driverIndex) => (
              <Fragment key={`driver${driverIndex}`}>
                <Row>
                  <Col xs={24}>
                    <InvoiceContact
                      data={driver}
                      orderNo={order.customer_order_id}
                      updateParentComponent={(updatedInvoice, orderNo) => {
                        const currentOrderId = orderNo;
                        const updatedOrderDriverDetails = this.state.currentInvoice.order_driver_details.map(
                          (od, idx) => {
                            if (idx === orderIndex) {
                              const updatedDriverDetails =
                                updatedInvoice.order_driver_details.find(
                                  (order) => order.customer_order_id === currentOrderId
                                )?.driver_details || [];

                              return {
                                ...od,
                                driver_details: od.driver_details.map((d, i) =>
                                  i === driverIndex ? { ...d, ...updatedDriverDetails[driverIndex] } : d
                                ),
                              };
                            }
                            return od;
                          }
                        );

                        this.setState(
                          {
                            currentInvoice: {
                              ...this.state.currentInvoice,
                              order_driver_details: updatedOrderDriverDetails,
                            },
                          },
                          () => {
                            // Call the parent component's callback to update its state
                            if (this.props.updateParentComponent) {
                              this.props.updateParentComponent(this.state.currentInvoice, currentOrderId);
                            }
                          }
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Fragment>
            ))}
          </Col>
        </Row>
      </Fragment>
    ))}
  </Spin>


       <Divider /> 
          {/* Section 3: Driver Adjustments */}
          <Row>
            <Col xs={24} className="alignLeft">
              <span
                style={{
                  fontSize: 16,
                  fontStyle: "Regular",
                  fontWeight: "bold",
                  color: "#395484",
                }}
              >
                Driver Adjustments:
              </span>
            </Col>
          </Row>
          <Spin spinning={this.state.adjProgress} delay={1000}>
          {orderDriverPayments.map((order, orderIndex) => {
            const locations = order.locations ||  []
            return (
              <Fragment key={`driverAdjustments${orderIndex}`}>
                <Row>
                  <Col xs={12} className="alignLeft">
                    <span
                      style={{
                        fontSize: 14,
                        fontStyle: "italic",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        color: "#395484",
                      }}
                    >
                      For Order #{order.order_no}
                    </span>
                   
                  </Col>
                </Row>
                {/* <DriverAdjustmentsTable
                    data={order.adjustments || []}
                    orderTypes={orderTypes}
                    glCodes={this.props.glCodesList}
                    onSave={this.onDriverPaySave}
                    loading={this.state.inProgress}
                    order={order}
                /> */}
                <DriverAdjustmentsTable
                  data={this.state.driverAdjustments.filter(adjustment => adjustment.customer_order_id === order.order_id).map(adjustment => ({
                    key: adjustment.id,
                    description: adjustment.driver_adjustments?.description || '',
                    location_id: adjustment.driver_adjustments?.location_id || 
                    (locations?.length == 1 ? locations[0].location_id : ''), // Map correctly
                    glCode: adjustment.driver_adjustments?.gl_code || '',
                    amount: adjustment.driver_adjustments.amount
                  }))}
                  locations={locations}
                  glCodes={this.props.glCodesList}
                  onSave={this.onDriverPaySave}
                  onDelete={this.onDriverPayDelete}
                  loading={this.state.inProgress}
                  order={order}
                />
               
              </Fragment>
            );
          })}
     <Divider />
          
        </Spin>
      </div>
    );
    
  }
}

export default BillingDriverPay;
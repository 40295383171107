import { isEmpty } from '../common/Common';
import { Call, ApiUrl, ParseGeneralResponse, setObjectToFormData } from './ApiUtils';

export function fetchForms(status = '') {
  let url = ApiUrl('v2/forms?operation_code=FI');
  if (status) {
    url = `${url}&status=${status}`;
  }
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { forms: '' } });
}

export function fetchSubmittedForms(id,page,perPage,search_term) {
  let url = ApiUrl(`v2/forms/order_forms?form_id=${id}&page=${page}&per_page=${perPage}`);
  if(!isEmpty(search_term)){
    url = `${url}&search_term=${search_term}`;
  }
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { forms: 'order_forms',pagination: 'meta.pagination' } });
}

export function saveForm(isNew, data) {
  data.operation_code = isNew ? 'FC' : 'FU';
  const formData = setObjectToFormData(data);
  const method = isNew ? 'post' : 'put';
  const url = isNew ? ApiUrl('v2/forms') : ApiUrl(`v2/forms/${data.id}`);
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { forms: '' } }, false);
}

export function deleteForm(id) {
  const url = ApiUrl(`v2/forms/${id}`);
  return Call('delete', url, {operation_code: 'FD'}, ParseGeneralResponse);
}

export function fetchFormResponses(id, loc_id = '') {
  let url = ApiUrl(`v2/forms/order_form_responses?order_id=${id}&operation_code=OFR`);
  if(!isEmpty(loc_id)){
    url = `${url}&location_id=${loc_id}`;
  }
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { form_details: 'form_response' } });
}

export function fetchPopulateFields() {
  let url = ApiUrl(`v2/forms/mapping_columns`);

  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { FeCol: "order_mapping_columns" },
    },
    true
  );
}

export function fetchWithOrderNumber (formId, page, perPage, search_term) {
  const url = ApiUrl(`v2/forms/order_forms?form_id=${formId}&page=${page}&per_page=${perPage}&search_term=${search_term}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { orders: 'order_forms', pagination: 'meta.pagination' },
  });
}

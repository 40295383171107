
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

const BranchesIcon = (props) => {
  const { handleClick } = props;
  return (
    <>
      <Icon
        type="branches"
        style={{ color:"brown" }} // #9e1092
        onClick={handleClick}
      />
    </>
  );
};

BranchesIcon.propTypes = {
  handleClick: PropTypes.func,
};

BranchesIcon.defaultProps = {
  handleClick: () => {},
};
export default BranchesIcon;

import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col, Radio } from '../../common/UIComponents'
import { alertMessage, base64ToPdf,  isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import { downloadLabels } from '../../api/OrdersApi'
import { LableSettingApi } from '../../api/LableSettingApi'
import { checkServiceExistance } from '../../helpers/common'
import BaseModal from '../BaseModal'
import Gallery from '../common/Gallery'

const PrintLabels = ({
  handleSuccess,
  buttonType,
  className,
  label,
  isDisabled,
  orderIds,
  size,
  buttonIcon = "",
  isFromBulkContainer,
  isPrintLabelClicked,
  setIsPrintLabelClicked,
  setIsHovered
}) => {
  const [loading, setLoading] = useState(false)
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [labelTypes, setLabelTypes] = useState([])
  const [selectedType, setSelectedType] = useState('')
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [pictures, setPictures] = useState([])
  const [currentImage, setCurrentImage] = useState(-1)

  useEffect(() => {
    if (isPrintLabelClicked) {
      enableForm();
    }
  }, [isPrintLabelClicked]);

  useEffect(() => {
    if(isFromBulkContainer && !showInfoWindow){
      setIsPrintLabelClicked(false)
      setIsHovered(null);
    }
  }, [showInfoWindow]);

  const getLabelTypes = async () => {
    try {
      const organizationId = userStore.getStateValue('selectedOrg')
      const page = 1;
      const perPage = 1000;
      setLoading(true)
      let result = await LableSettingApi.fetch(organizationId, page, perPage);
      if (result.success) {
        const labels = [...result.data]
        setLabelTypes(labels)
        setSelectedType(labels.length > 0 ? labels[0].code : '')
      } else {
        if (result.errors.length > 0) {
        }
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getLabelTypes()
  }, [])

  const enableForm = () => {
    if (labelTypes.length > 0) {
      setShowInfoWindow(true)
    } else {
      handlePrintLabels()
    }
  }

  const handlePrintLabels = async () => {
    const selectedIds = !isEmpty(orderIds) ? orderIds : []
    try {
      const payload = {
        customer_order_ids: selectedIds,
        organization_id: userStore.getStateValue('selectedOrg'),
        label_type: selectedType,
      }
      setLoading(true)
      let result = await downloadLabels(payload)
      if (result.success) {
        base64ToPdf(result.label_file, `Labels.pdf`)
      } else {
        if (result.errors.length > 0) {
          alertMessage(result.errors.join(', '), 'error', 10)
        }
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setLoading(false)
    }
  }

  const renderModalWindow = () => {
    const totalTypes = labelTypes.length > 0 ? labelTypes.length : 1
    const gridSize = 8 //totalTypes > 3 ? 8 :
    return (
      <BaseModal
        title={`${I18n.t('lable_settings.format_to_download')}`}
        onCancel={disableForm}
        width={'55%'}
        style={{ top: 40 }}
        bodyStyle={{ padding: 20 }}
        maskClosable={false}
      >
        <Radio.Group
          style={{ width: '100%' }}
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          <Row gutter={32}>
            {labelTypes.map((rec) => (
              <Col span={gridSize} className="textBold textCapitalize padding5">
                <Radio value={rec.code}>
                  {' '}
                  <span className="textCaptilize">
                    {rec.name ? rec.name.toLowerCase() : ''}{' '}
                  </span>
                </Radio>
                <br />
                <div
                  className="centerToBlock borderLine marginTop10 cursorPointer"
                  style={{
                    width: '100%',
                    height: 160,
                    border: '1px solid #000',
                  }}
                >
                  {rec.sample_label && rec.sample_label.url ? (
                    <img
                      src={rec.sample_label.url}
                      width={200}
                      height={150}
                      onClick={() => {
                        setSelectedType(rec.code)
                        // setPictures([
                        //   {
                        //     src: rec.sample_label.url,
                        //   },
                        // ])
                      }}
                    />
                  ) : (
                    <div
                      style={{ width: 200, height: 150, letterSpacing: '0.4pt' }}
                      onClick={() => {
                        setSelectedType(rec.code)
                      }}
                      className="centerToBlock fontSize18"
                    >
                      No Preview Available
                    </div>
                  )}
                  {/*  */}
                </div>

                {/* {
                rec.sample_label && rec.sample_label.url &&
                <img src={rec.sample_label.url} width={rec.width * 96} height={rec.height * 96} />
              } */}
              </Col>
            ))}
          </Row>
        </Radio.Group>
        <div className="alignCenter marginTop40">
          <Button
            onClick={() => handlePrintLabels(true)}
            type="primary"
            loading={loading}
            disabled={labelTypes.length === 0 || isEmpty(selectedType)}
            size={'default'}
            icon={buttonIcon}
          >
            Download
          </Button>
        </div>
      </BaseModal>
    )
  }

  const disableForm = () => {
    setSelectedType('')
    setShowInfoWindow(false)
  }

  useEffect(() => {
    if (pictures.length > 0) {
      setCurrentImage(0)
      setLightboxIsOpen(true)
    } else {
      setCurrentImage(-1)
      setLightboxIsOpen(false)
    }
  }, [pictures])

  return (
    <Fragment>
      {!isFromBulkContainer ?  <Button
        // onClick={() => handlePrintLabels(true)}
        onClick={enableForm}
        type={buttonType ? buttonType : 'primary'}
        icon="download"
        className={className ? className : ''}
        loading={loading}
        disabled={isDisabled}
        size={size ? size : 'default'}
      >
        {label}
      </Button> : I18n.t("general.label")}
      {showInfoWindow && renderModalWindow()}
      {lightboxIsOpen && pictures.length > 0 && (
        <Gallery
          images={pictures}
          onClose={() => {
            setPictures([])
          }}
          currentImage={currentImage}
        />
      )}
    </Fragment>
  )
}

PrintLabels.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  buttonType: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  orderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.string.isRequired,
  isFromBulkContainer: PropTypes.bool,
  isPrintLabelClicked:  PropTypes.bool,
  setIsPrintLabelClicked: PropTypes.func,
  setIsHovered: PropTypes.func
}

PrintLabels.defaultProps = {
  handleSuccess: () => {},
  buttonType: PropTypes.bool.isRequired,
  className: '',
  label: I18n.t('general.download'),
  isDisabled: true,
  orderIds: [],
  size: 'default',
  isFromBulkContainer: false,
  isPrintLabelClicked: false,
  setIsPrintLabelClicked: () => {},
  setIsHovered: () => {}
}

export default PrintLabels

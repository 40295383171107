import { Col, Descriptions, Row } from "antd";
import React, { useContext, useState } from "react";
import Profile from "../common/Profile";
import _ , { isEmpty } from "lodash";
import { formatStopTime } from "../../common/Common";
import moment from 'moment';
import { momentTime } from "../../helpers/date_functions";
import AppConfig from "../../config/AppConfig";
import EndUserTitle from "../common/EndUserTitle";
import I18n from "../../common/I18n";

const ReleaseDataTab = (props) => {


  const [releaseData, setReleaseData] = useState({
    ...props.customerDetails,
  });
  function formatAddress(address) {
    return _.compact([
      address.address_line1,
      address.address_line2,
      address.city,
      address.state,
      address.country,
      address.zipcode,
    ]).join(", ");
  }
  const pickupLocationObj = releaseData?.locations ? _.find(releaseData.locations, { type_of_order: 'PICKUP'}) : {}
  const deliveryLocationObj = releaseData?.locations ? _.find(releaseData.locations, { type_of_order: 'DELIVERY'}) : {}
  const {
    appointments: deliveryAppointments = [],
    service_duration: deliveryServiceduration,
    los_code: delivery_los_code,
  } = deliveryLocationObj;
  const {
    appointments: pickupAppointments = [],
    service_duration: pickupServiceduration,
    los_code: pickup_los_code,
  } = pickupLocationObj;

  const pickupLocation = pickupLocationObj.l_address;
  const pickupLocationAddress =  pickupLocation
    ? formatAddress(pickupLocation)
    : "";
  const  deliveryLocation = deliveryLocationObj.l_address;
  const deliveryLocationAddress = deliveryLocation
    ? formatAddress(deliveryLocation)
    : "";   

  const ScheduledDate = !_.isEmpty(deliveryAppointments)
    ? deliveryAppointments?.[0]?.appt_date
    : "";
  const deliverySchlStartTime = !_.isEmpty(deliveryAppointments)
    ? deliveryAppointments?.[0]?.start_time
    : "";
  const deliverySchlEndTime = !_.isEmpty(deliveryAppointments)
    ? deliveryAppointments?.[0]?.end_time
    : "";

    const pickupScheduledDate = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.appt_date
    : "";

    const pickupSchlStartTime = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.start_time
    : "";

    const pickupSchlEndTime = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.end_time
    : "";
    const pickupTz = pickupLocationObj?.tz_short_form;
    const deliveryTz = deliveryLocationObj?.tz_short_form;
    const pickupStatus = pickupLocationObj?.status;
    const deliveryStatus = deliveryLocationObj?.status;
    const pickupDriverInfo = pickupLocationObj?.driver_details?.[0];
    const deliveryDriverInfo = deliveryLocationObj?.driver_details?.[0];
    const pickupData = pickupLocationObj;
    const pickupPactualStartTime = pickupData?.actual_start_datetime;
    const pickupPactualEndTime = pickupData?.actual_end_datetime;
    const deliveryData = deliveryLocationObj;
    const deliveryPactualStartTime = deliveryData?.actual_start_datetime;
    const deliveryPactualEndTime = deliveryData?.actual_end_datetime;
    const timeFormat = props.isMilitaryTime ? "HH:mm" : "h:mm A";

    const formatTimeStamp = (dataObject, startTime, endTime, formatString) => {
      let startString = "";
      let endString = "";
      if (isEmpty(dataObject[startTime]) && isEmpty(dataObject[endTime])) {
        return "NA";
      }
    
      if (!isEmpty(dataObject[startTime])) {
        startString = startTime !== "scheduled_start_datetime"
          ? momentTime(formatString, dataObject[startTime], dataObject.timeZoneId)
          : moment(dataObject[startTime]).format(formatString);
      }
      if (!isEmpty(dataObject[endTime])) {
        endString = endTime !== "scheduled_end_datetime"
          ? momentTime(formatString, dataObject[endTime], dataObject.timeZoneId)
          : moment(dataObject[endTime]).format(formatString);
      }
      if (!isEmpty(dataObject[startTime]) && isEmpty(dataObject[endTime])) {
        endString = startString;
        startString = "Arr. at";
      }
      if (!isEmpty(dataObject[endTime]) && isEmpty(dataObject[startTime])) {
        startString = "Dept. at";
      }
    
      return `${startString} - ${endString} ${
        dataObject.tz_short_form ? dataObject.tz_short_form : ""
      }`;
    };
    


  return (
    <div>
      <Descriptions title={I18n.t('release_tab.release_details')}  bordered>
        <Descriptions.Item label={I18n.t('release_tab.pickup_address')}>
          {pickupLocationAddress}
        </Descriptions.Item>
        <Descriptions.Item label={I18n.t('release_tab.delivery_address')} span={3}>
        {deliveryLocationAddress}
        </Descriptions.Item>
        <Descriptions.Item
          label= {<EndUserTitle stringToAppend={I18n.t("general.details")} />}
        >
          <Profile
            profile={(({ first_name, last_name, email, phone }) => ({
              full_name: `${first_name !== null ? first_name : ""} ${
                last_name !== null ? last_name : ""
              }`,
              email,
              mobile_number: phone ? phone : "",
            }))(releaseData.locations[0])}
            withLables={false}
            showImage={false}
            verifymedium={true}
          />
        </Descriptions.Item>
        <Descriptions.Item
         label= {<EndUserTitle stringToAppend={I18n.t("general.details")} />}
          span={3}>
          <Profile
            profile={(({ first_name, last_name, email, phone }) => ({
              full_name: `${first_name !== null ? first_name : ""} ${
                last_name !== null ? last_name : ""
              }`,
              email,
              mobile_number: phone ? phone : "",
            }))(releaseData.locations[1])}
            withLables={false}
            showImage={false}
            verifymedium={true}
          />
        </Descriptions.Item>
        <Descriptions.Item label={I18n.t('release_tab.schedule_date')} span={isEmpty(pickupScheduledDate) ? 3 : 0}>
           {!isEmpty(pickupScheduledDate)
            ? moment(pickupScheduledDate).format("DD MMM, YYYY")
            : "NA"}       
            </Descriptions.Item>
          <Descriptions.Item label={I18n.t('release_tab.schedule_date')} span={3}>
           {!isEmpty(ScheduledDate)
            ? moment(ScheduledDate).format("DD MMM, YYYY")
            : "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Pickup Timings Info" span={isEmpty(pickupSchlStartTime) ? 3 : 0}>
        <Row gutter={8}>
              <Col>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"SC :"} </b>
                    </span>
                    {!isEmpty(pickupSchlStartTime) ||
                    !isEmpty(pickupSchlEndTime) ? (
                      <React.Fragment>
                        {formatStopTime(
                          pickupSchlStartTime,
                          pickupSchlEndTime,
                          pickupTz
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"AT :"} </b>
                    </span>
                    {pickupData &&
                    (!isEmpty(pickupPactualStartTime) || !isEmpty(pickupPactualEndTime)) ? (
                      <React.Fragment>
                        {formatTimeStamp(
                          pickupData,
                          "actual_start_datetime",
                          "actual_end_datetime",
                          timeFormat
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
        </Descriptions.Item>
        <Descriptions.Item label= "Delivery Timings Info" span={3}>
        <Row gutter={8}>
              <Col>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"SC :"} </b>
                    </span>
                    {!isEmpty(deliverySchlStartTime) ||
                    !isEmpty(deliverySchlEndTime) ? (
                      <React.Fragment>
                        {formatStopTime(
                          deliverySchlStartTime,
                          deliverySchlEndTime,
                          deliveryTz
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"AT :"} </b>
                    </span>
                    {deliveryData &&
                    (!isEmpty(deliveryPactualStartTime) || !isEmpty(deliveryPactualEndTime)) ? (
                      <React.Fragment>
                        {formatTimeStamp(
                          deliveryData,
                          "actual_start_datetime",
                          "actual_end_datetime",
                          timeFormat
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
        </Descriptions.Item>
        <Descriptions.Item label={I18n.t('release_tab.pickup_status')} span={isEmpty(pickupStatus) ? 3 : 0}>
            {pickupStatus !== null ? <b>{pickupStatus}</b> : "NA"}
          </Descriptions.Item>
          {!isEmpty(deliveryStatus) &&
          <Descriptions.Item label={I18n.t('release_tab.delivery_status')} span={3}>
            {deliveryStatus !== null ? <b>{deliveryStatus}</b> : "NA"}
          </Descriptions.Item>
          }
        <Descriptions.Item label={I18n.t('release_tab.los')} span={isEmpty(pickup_los_code) ? 3 : 0}>
          {!isEmpty(pickup_los_code) ? pickup_los_code : "NA"}       
          </Descriptions.Item>
        <Descriptions.Item label={I18n.t('release_tab.los')} span={3}>
            {!isEmpty(delivery_los_code) ? delivery_los_code : "NA"}        
            </Descriptions.Item>
        <Descriptions.Item label={I18n.t('release_tab.service_duration')} span={isEmpty(deliveryStatus) ? 3 : 0}>
         { `${pickupServiceduration} (${"mins"})`} 
        </Descriptions.Item>
        <Descriptions.Item label={I18n.t('release_tab.service_duration')} span={3}>
        {`${deliveryServiceduration} (${"mins"})`}
            </Descriptions.Item>
        {!_.isEmpty(pickupDriverInfo) ? (
            <Descriptions.Item label="Driver Details" span={isEmpty(pickupDriverInfo) ? 3 : 0} >
              <Profile
                profile={(({
                  first_name,
                  last_name,
                  mobile_number,
                  employee_code,
                }) => ({
                  full_name: `${first_name !== null ? first_name : ""} ${
                    last_name !== null ? last_name : ""
                  } (${employee_code !== null ? employee_code : ""})`,
                  mobile_number: mobile_number ? mobile_number : "",
                }))(pickupDriverInfo)}
                withLables={false}
                showImage={false}
                verifymedium={true}
                hideMail={true}
              />
            </Descriptions.Item>
            
          ) : null}
          {!_.isEmpty(deliveryDriverInfo) ? (
            <Descriptions.Item label="Driver Details" span={3} >
              <Profile
                profile={(({
                  first_name,
                  last_name,
                  mobile_number,
                  employee_code,
                }) => ({
                  full_name: `${first_name !== null ? first_name : ""} ${
                    last_name !== null ? last_name : ""
                  } (${employee_code !== null ? employee_code : ""})`,
                  mobile_number: mobile_number ? mobile_number : "",
                }))(deliveryDriverInfo)}
                withLables={false}
                showImage={false}
                verifymedium={true}
                hideMail={true}
              />
            </Descriptions.Item>
          ) : null}

        
      </Descriptions>
    </div>
  );
};
export default ReleaseDataTab;

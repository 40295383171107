import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";
export const RejectedReasonApi = {
  fetchRejectedReason: (organizationId) => {
    let url = ApiUrl(`v2/rejection_messages?organization_id=${organizationId}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { rejection_messages: "rejection_messages" },
    });
  },
  saveRejectedReason: (data) => {
    let url = ApiUrl(`v2/rejection_messages`)
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  updateRejectedReason: (id, data,organizationId) => {
    let url = ApiUrl(`v2/rejection_messages/${id}?organization_id=${organizationId}`);
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  deleteRejectedReason: (id,organizationId) => {
    let url = ApiUrl(`v2/rejection_messages/${id}?organization_id=${organizationId}`)
    return Call("delete", url,{}, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
};
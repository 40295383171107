import React from "react";
import { Row, Col, Icon, Tooltip, Tag } from "antd";
import _ from "lodash";
import RightArrow from "../../../assets/images/right-arrow.png";
import { processOptionData } from "./helpers";
import AppConfig from "../../config/AppConfig";
import TypeOfOrder from "../orders/TypeOfOrder";
import Title from "antd/lib/typography/Title";
import { ellipseText } from "../../common/Common";

const {orderTypeColorMapping} = AppConfig
function TemplateOption(props) {
  const COLUMN_STYLE = { width: 100 };
  let { data, onlylabel, drivers , isMilitaryTime } = props;
  const { selected_warehouse, selected_account } = data;
  const {
    first_name,
    last_name,
    pickup_address,
    delivery_address,
    pickup_time,
    delivery_time,
  } = processOptionData(data, isMilitaryTime);
  if (onlylabel) {
    //only show label is used when a template is selected
    return (
      <Row type="flex" align="middle" gutter={8}>
        <Col>
          <Title level={4} style={{ marginBottom: "0px" }}>
            {data?.template_name || ""}
          </Title>
        </Col>
        <Col>
          <Row>
            <Col>
              <span>{`${pickup_address} ${
                pickup_time
                  ? `(${pickup_time.start_time} - ${pickup_time.end_time})`
                  : ""
              }`}</span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Icon type="arrow-right" />
        </Col>
        <Col>
          <Row>
            <Col>
              <span>{`${delivery_address} ${
                delivery_time
                  ? `(${delivery_time.start_time} - ${delivery_time.end_time})`
                  : ""
              }`}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  return (
    <Row align="middle" type="flex">
      <Col span={ 4 } style={ COLUMN_STYLE }>
          <p className="text-sorting">{ data?.template_name || "" }</p>
      </Col>
      <Col span={ 6 }>
        <Row>
          <Col span={ 24 }>
            <span>{ `${first_name || ""} ${last_name || ""}` }</span>
          </Col>
        </Row>
        <Row type="flex" gutter={ 2 }>
          <Col>
            <span>WH :</span>
          </Col>
          <Col>
            <span>{ `${selected_warehouse?.location_code
                ? selected_warehouse.location_code
                : ""
              }` }</span>
          </Col>
          <Col offset={ 1 }>
            <span>Acc :</span>
          </Col>
          <Col>
            <span>{ `${selected_account?.code ? selected_account.code : ""
              }` }</span>
          </Col>
        </Row>
      </Col>
      {/* route */ }
      <Col span={ 12 }>
        <Row type="flex" align="middle" gutter={2}>
          <Col span={ 10 }>
            <Row style={ { textAlign: "center" } }>
              <Col>
                <Tooltip title={ pickup_address }>
                  <span>{ ellipseText(pickup_address, 20) }</span>
                </Tooltip>
              </Col>
              <Col>
                <span>
                  { _.isObject(pickup_time) ? `${pickup_time.start_time || ""} - ${pickup_time.end_time || ""}` : "-" }
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={ 2 }>
            <img src={ RightArrow } alt="" style={ { width: "100%" } } />
          </Col>
          <Col span={ 10 }>
            {/* <Row justify='center'> */ }
            <Row style={ { textAlign: "center" } }>
              <Col>
                {/* <span>{ delivery_address }</span> */}
                <Tooltip title={ delivery_address }>
                  <span>{ ellipseText(delivery_address, 20) }</span>
                </Tooltip>
              </Col>
              <Col>
                <span>
                  { _.isObject(delivery_time) ? `${delivery_time.start_time || ""} - ${delivery_time.end_time || ""}` : "-" }
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={ 2 }>
        <Row type="flex" align="middle" gutter={ 2 }>
          <Col>
            <span>
              {
                drivers.find((driver) => driver.id === data.driver_id)
                  ?.employee_code
              }
            </span>
          </Col>
          <Col>
            <Tag color={ orderTypeColorMapping[ data.order_type ] }> {
              <TypeOfOrder
                order={ data }
                orderTypeKey="order_type"
                placement={ "right" }
              />
            }
            </Tag>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default TemplateOption;

TemplateOption.defaultProps = {
  drivers: [],
  onlylabel: false,
};

import React, { useState, useContext, useCallback, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Tooltip,
  Icon,
  Modal,
} from "../../common/UIComponents";
import { fetchOrderPictures } from "../../api/OrdersApi";
import ImageViewer from "../common/ImageViewer";
import { renderAlertMessage } from "../../helpers/common";
import { UserContext } from "../../context/UserContext";

const OrderAttachments = ({ record }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentOrg } = useContext(UserContext);
  const [images, setImages] = useState({});

  const fetchImages = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await fetchOrderPictures(record.id || record.customer_order_id);
      if (result.success) {
        if(!result?.images?.pickup_order_pictures?.length &&  !result?.images?.delivery_order_pictures?.length){
            renderAlertMessage("No Attachments Found", "error", 10);
        } else{
            setImages(result.images || {});
            setModalVisible(true);
        }
      } else {
        renderAlertMessage("No Attachments Found", "error", 10);
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setIsLoading(false);
    }
  }, [record.id]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
    setImages({});
  }, []);

  const totalPickupPictures = useMemo(() => {
    return images?.pickup_order_pictures?.filter(pic => pic?.picture?.url).map(pic => pic.picture.url) || [];
  }, [images]);

  const totalDelPictures = useMemo(() => {
    return images?.delivery_order_pictures?.filter(pic => pic?.picture?.url).map(pic => pic.picture.url) || [];
  }, [images]);

  const renderImages = useCallback(() => (
    <Modal
      title="Attachments"
      visible={modalVisible}
      onCancel={handleCancel}
      footer={null}
      width="600px"
      maskClosable={false}
    >
      {totalPickupPictures.length > 0 && (
        <Fragment>
          <h4>POD - Pickup</h4>
          <ImageViewer
            images={totalPickupPictures}
            key={`pickup${record.id}`}
          />
        </Fragment>
      )}
      {totalDelPictures.length > 0 && (
        <Fragment>
          <h4>POD - Delivery</h4>
          <ImageViewer
            images={totalDelPictures}
            key={`del${record.id}`}
          />
        </Fragment>
      )}
    </Modal>
  ), [modalVisible, handleCancel, totalPickupPictures, totalDelPictures, record.id]);

  return (
    <Fragment>
      <Tooltip title="Attachments">
        <Icon type="link" onClick={fetchImages} style={{ color: 'blue', fontSize: 14 }} rotate={135} />
      </Tooltip>
      {modalVisible && renderImages()}
    </Fragment>
  );
};

OrderAttachments.propTypes = {
  record: PropTypes.shape().isRequired,
  setIsItemVisible: PropTypes.func,
};

OrderAttachments.defaultProps = {
  setIsItemVisible: () => {},
};

export default React.memo(OrderAttachments);
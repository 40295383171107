import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";

const AppRoute = ({
  route,
  routeindex,
  isOrderPage,
  updateDisplayContext,
  filterPlaceHolder,
  searchType,
  handleChildFilterChange,
  handleAppStateOnChange,
  appContexts
}) => {
  // const { userContext, orgContext, warehouseContext } = appContexts;
  return (
    <ErrorBoundary key={ routeindex }>
      <Route
        exact={ route.exact }
        path={ route.path }
        render={
          // !isUserAuthenticated() ? () => <Redirect to="/login" /> 
          // :
          !isOrderPage
            ? (props) => <route.component { ...props } dispContext={ updateDisplayContext } appContexts={appContexts} handleAppStateOnChange={ handleAppStateOnChange } />
            : () => (
              <route.component
                filterPlaceHolder={
                  filterPlaceHolder
                }
                appContexts={ appContexts }
                searchType={ searchType }
                handleChildFilterChange={
                  handleChildFilterChange
                }
                handleAppStateOnChange={ handleAppStateOnChange }
                dispContext={ updateDisplayContext }
              />
            )
        }
        // component={route.component}
        key={ route.key }
      />
    </ErrorBoundary>
  );
};


export default AppRoute;
import * as React from 'react';
import PropTypes from "prop-types";
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'; // using moment.js as date library
import _ from "lodash"
import { useEffect } from 'react';
import moment from 'moment';

/**
 * @param {moment} value - moment object
 * @param {function} onChange - onChange function which takes moment object as argument
 * @param {string} label - label for the input
 * @param {isMilitaryTime} boolean - if true, time will be displayed in 24 hour format
 * @param {disabled} boolean - if true, input will be disabled
 * @param {inputStyle} object - style object for the input 
 * @param {computeDisabledHours} function - which returns an array of hours to be disabled
 * @param {computeDisabledMinutes} function - which returns an array of minutes to be disabled for a given hour
 * @param {placeholder} string - placeholder for the input
 * @param {style} object - style object for the input
 * @returns {React.Component} - Material Time Picker
 * @example
 * <MaterialTimePicker
 * inputStyle={{width: "100%"}}
 * value={moment()}
 * onChange={onChange}
 * label="Time"
 * isMilitaryTime={true}
 * isDisabled={false}
 * />
 * 
 **/

export default function MaterialTimePicker (props) {
  const { isMilitaryTime = false, value, onChange, inputStyle , computeDisabledHours , computeDisabledMinutes , disabled , placeholder , style, size , onError } = props;
  const [ pickerValue, setPickerValue ] = React.useState(value || null);

  // if props.value changes, update pickerValue
  useEffect(() => {
    setPickerValue(value || null);
  }, [value]);

  const getDisabledVals = React.useCallback((clockType, clockValue) => {
    if(!pickerValue) return [];
    if (clockType === 'hours') {
      const amOrPm = pickerValue && moment(pickerValue).isValid() ? pickerValue.format('a') : 'am';
      // check if not militaryTime , then am or pm is selected. if am is selected then remove all the hours from 12 to 23, if pm is selected then remove all the hours from 0 to 11 from  
      const resultantHours = computeDisabledHours(pickerValue);
      if(!isMilitaryTime && amOrPm === 'am') {
        return resultantHours.filter((hour) => hour < 12);
      }
      if(!isMilitaryTime && amOrPm === 'pm') {
        return resultantHours.filter((hour) => hour >= 12);
      }
      return resultantHours;
    }
    if (clockType === 'minutes') {
      return computeDisabledMinutes(pickerValue);
    }
    return [];
  }, [computeDisabledHours, computeDisabledMinutes]);

  const defaultInputStyle = {
    "padding": "5px 10px",
    "font-size": "14px",
  };

  return (
    <LocalizationProvider dateAdapter={ AdapterMoment }>
      <TimePicker
        className={props.className || ""}
        value={ pickerValue }
        ampm={ !isMilitaryTime }
        size={size ? size : 'small'}
        onChange={ (newValue) => {
          setPickerValue(newValue); 
          if(_.isFunction(onChange)) {
            onChange(newValue);
          }
        } }
        disabled={ disabled }
        onError={onError}
        shouldDisableTime={ (timeValue, clockType) => {
          const valsToBeDisabled = getDisabledVals(clockType);
          return valsToBeDisabled.includes(timeValue); 
        } }
        renderInput={ (params) => // render input 
        {
          const inputStyleObj = inputStyle ? inputStyle : defaultInputStyle;
          const styleObj = style ? style :  { width: isMilitaryTime ? '120px' : '140px' }
          return (
          <TextField
            { ...params }
            className={params.className + ' ' + "time-picker-input"}
            style={styleObj}
            size={size ? size : 'small'}
            inputProps={ {
              ...params.inputProps,
              placeholder, 
              style: {
                ...params.inputProps.style,
                ...inputStyleObj
              },
            } }
          />
          )
        }
        }
      />
    </LocalizationProvider>
  );
}

MaterialTimePicker.propTypes = {
  isMilitaryTime: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  inputStyle: PropTypes.object,
  computeDisabledHours : PropTypes.func,
  computeDisabledMinutes : PropTypes.func,
  disabled: PropTypes.bool,
  placeholder : PropTypes.string,
  style : PropTypes.object,
  onError: PropTypes.func
};

MaterialTimePicker.defaultProps = {
  isMilitaryTime: false,
  value: null,
  onChange: () => {},
  inputStyle: null,
  computeDisabledHours : () => [],
  computeDisabledMinutes : () => [],
  disabled: false,
  placeholder : "Time",
  style : null,
  onError: () => {}
};
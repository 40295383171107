/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Select,
  Button,
  Typography,
  Popconfirm,
  Alert,
  Modal,
  Collapse,
  SideBySideFormItem,
  Radio,
} from "../../common/UIComponents";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormErrors from "../common/FormErrors";
import {
  changeStatusOfOrders,
  changeStatusToException,
} from "../../api/OrdersApi";
import { fetchExceptionMessages } from "../../api/PreplanApi";
import ReasonWindow from "../common/ReasonWindow";
import AppConfig from "../../config/AppConfig";
import { fetchDriverList, fetchUsers } from "../../api/UsersApi";
import DateTimeSelector from "../../common/DateTimeSelector";
import moment from "moment";
import { renderAlertMessage } from "../../helpers/common";

const { TextArea } = Input;
const { confirm } = Modal;
const { Panel } = Collapse;
class MoreChangeStatusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      errors: [],
      statusErrors: [],
      currentStatus: this.props.currentStatus || "",
      drivers: [],
      selectedDriver: "",
      exceptionCode: "",
      exceptionMessage: "",
      order: this.props.orderObject || {},
      status: this.props.isPending
        ? AppConfig.pendingOrderChangeStatus
        : AppConfig.orderChangeStatus,
      preDispatchStatus: !this.props.isPending
        ? ["NEW", "RECEIVED", "VERIFIED", "PREPLAN"]
        : [],
      postDispatchStatus: !this.props.isPending
        ? ["PREPARE", "ASSIGNED", "DISPATCHED", "COMPLETED", "CLOSED"]
        : [],
      reason: "",
      orderStatus: this.props.currentStatus || "",
      exceptions: ["NEW"],
      orders: this.props.orders || [],
      showReason: false,
      warehouseId: this.props.warehouseId || {},
      completedInfo: {},
      locationInfo: {},
      isMilitaryTime: props.is_military_time,
      complete_for: "BOTH",
      isSignatutredRequired: true,
    };
    this.handleDriverChange = this.handleDriverChange.bind(this);
  }

  clearForm = () => {
    this.setState({
      currentItem: {},
      isNew: true,
    });
  };

  componentDidMount() {
    this.getExceptionMessages();
    this.getDriversList();
    // this.checkBulkStatus();
  }

  getCapturedInfo = (service_duration, nsr) => {
    const duration =
      service_duration && _.isNumber(service_duration) && service_duration >= 10
        ? service_duration / 2
        : 10;
    return {
      sign_by: "",
      signer_title: "",
      completed_date: moment(),
      captured_at: !nsr ? moment() : null,
      actual_start_datetime: moment().subtract(Math.ceil(duration), "minute"),
      actual_end_datetime: moment().add(Math.floor(duration), "minute"),
    };
  };

  renderDateTimeField = (
    loc,
    dateLabel,
    timeLabel,
    key,
    showDate,
    showTime,
    dateReq,
    timeReq,
    index
  ) => {
    const { isMilitaryTime } = this.state;
    return (
      <DateTimeSelector
        dateProps={{
          format: "Do MMM YYYY",
          label: dateLabel,
          value: loc[key] ? loc[key] : null,
          onChange: (date) => {
            this.handleDetailsTimeChange(key, date);
          },
          style: { width: "100%" },
          size: "small",
          disabledDate: (current) => {
            return (
              current && current.valueOf() > Date.now() // disabled the future dates
            );
          },
          dateRequire: dateReq,
          allowClear: false,
        }}
        timeProps={{
          allowClear: false,
          format: isMilitaryTime ? "HH:mm" : "hh:mm A",
          label: timeLabel,
          showTime: {
            format: isMilitaryTime ? "HH:mm" : "hh:mm A",
            use12Hours: !isMilitaryTime,
          },
          showSecond: false,
          onChange: (time) => {
            this.handleDetailsTimeChange(key, time);
          },
          style: { width: "100%", height: "100%", marginTop: "5px" },
          value: loc[key] ? loc[key] : null,
          placeholder: "Select Time",
          minuteStep: 1,
          className: "formMaterialTimePicker",
          isMilitaryTime,
          timeRequire: timeReq,
        }}
        hideDate={!showDate}
        hideTime={!showTime}
        gridStyle={{
          row: { type: "flex", gutter: 4 },
          dateCol: {
            span: showDate && showTime ? 12 : showDate ? 24 : showTime ? 0 : 12,
          },
          timeCol: {
            span: showDate && showTime ? 12 : showTime ? 24 : showDate ? 0 : 12,
          },
        }}
        FormItem={FormItem}
      />
    );
  };

  setCompletedInfo = () => {
    const { orders } = this.state;
    const timeObject = this.getCapturedInfo(20, isSignatutredRequired);
    const isSignatutredRequired = this.state.orders.filter(order => !order.nsr).length > 0;
    this.setState({
      locationInfo: { ...timeObject },
      completedInfo: { ...timeObject },
      isSignatutredRequired,
    });
  };

  handleCompleteForChange = (element, value) => {
    const { locationInfo } = this.state;
    this.setState({
      [element]: value,
      completedInfo:
        value === "PICKUP"
          ? locationInfo.filter((loc) => loc.type_of_loc === "PICKUP")
          : [...locationInfo],
    });
  };

  checkBulkStatus = () => {
    if (this.props.hasBulkOrders) {
      const { status } = this.state;
      delete status.Exception;
      this.setState({
        status,
      });
    }
  };

  handleChange = (element, value) => {
    if (
      element === "currentStatus" &&
      this.state.postDispatchStatus.includes(this.state.orderStatus) &&
      this.state.preDispatchStatus.includes(value)
    ) {
      confirm({
        title: I18n.t("messages.order_deleted_from_route"),
        content: <span className="">{I18n.t("messages.proceed_confirm")}</span>,
        onOk: () => {
          this.setState(
            {
              [element]: value,
              showReason: true,
            },
            () => {
              this.setCompletedInfo();
            }
          );
        },
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onCancel: () => {},
      });
    } else {
      if (
        element === "currentStatus" &&
        value === "COMPLETED" &&
        this.state.orders.length === 0
      ) {
        alertMessage("Please select orders inorder to complete them", "error"); // Please complete one order at one time
      } else {
        this.setState({ [element]: value }, () => {
          if (element === "currentStatus") {
            this.setCompletedInfo();
          }
        });
      }
    }
  };

  getDriversList(initial, cb) {
    this.setState({ inProgress: true });
    fetchDriverList().then((result) => {
      if (result.success) {
        this.setState(
          {
            drivers: result.data.drivers.map((driver) => ({
              id: driver._id,
              employee_code: driver.employee_code,
            })),
            // selectedDriver: result.users[0].id, for making first driver to appear in dropdown
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors);
        this.setState({ inProgress: false });
      }
    });
  }

  handleDriverChange = (value) => {
    this.setState({ selectedDriver: value });
  };

  getExceptionMessages = () => {
    this.setState({ inProgress: true });
    fetchExceptionMessages().then((result) => {
      if (result.success) {
        const exceptions = [
          ...result.exceptions,
          { exception_code: "CUSTOM", exception_message: "Custom Message" },
        ];
        this.setState({
          exceptions,
          inProgress: false,
        });
      } else {
        this.setState({
          inProgress: false,
          exceptions: [
            { exception_code: "CUSTOM", exception_message: "Custom Message" },
          ],
        });
        renderAlertMessage(result.errors);
      }
    });
  };

  handleSave = () => {
    this.setState({ inProgress: true, errors: [] });
    const {isSignatutredRequired} = this.state;
    const errors = [];
    if (
      this.state.exceptionCode === "CUSTOM" &&
      this.state.exceptionMessage === ""
    ) {
      errors.push("Message is required");
    }
    if (errors.length === 0) {
      if (this.state.currentStatus === "EXCEPTION") {
        const data = {
          order_id: this.state.orders.map((order) => order.id).join(","),
          exception_code: this.state.exceptionCode,
          exception_message:
            this.state.exceptionCode === "CUSTOM"
              ? this.state.exceptionMessage
              : "",
        };
        changeStatusToException(data).then((result) => {
          if (result.success) {
            alertMessage(I18n.t("messages.processed"), 10);
            this.setState(
              {
                inProgress: false,
                errors: [],
                statusErrors: [],
              },
              () => {
                this.props.saveStatus();
              }
            );
          } else {
            this.setState({
              inProgress: false,
            });
            this.processErrors(result.errors);
            const errorOrders = result.errors
              ? result.errors.map((error) => error.order_number)
              : [];
            if (this.props.hasBulkOrders) {
              const errorOrders = result.errors
                ? result.errors.map((error) => error.order_number)
                : [];
              this.props.processErrors(errorOrders);
            }
          }
        });
      } else {
        const selectedWarehouses = localStorage.getItem("selectedWarehouses");
        let errors = [];
        if (this.state.currentStatus === "COMPLETED") {
          const orderStatus = this.state.orders?.length > 0 ?  this.state.orders[0].status : "";

          const completedInfo = { ...this.state.completedInfo };
          const tempErrors = [];
          if (isEmpty(completedInfo.sign_by) && isSignatutredRequired) {
            tempErrors.push(I18n.t("order.sign_by"));
          }
          if (isEmpty(completedInfo.captured_at) && isSignatutredRequired) {
            tempErrors.push(I18n.t("general.capture_date"));
          }
          if (
            !this.state.postDispatchStatus.includes(orderStatus) &&
            isEmpty(completedInfo.driver_id)
          ) {
            tempErrors.push(I18n.t("general.Driver"));
          }
          // if (isEmpty(completedInfo.completed_date)) {
          //   tempErrors.push(I18n.t('info.completed_date' ));
          // }
          if (isEmpty(completedInfo.actual_start_datetime)) {
            tempErrors.push("Arrival Time");
          }
          if (isEmpty(completedInfo.actual_end_datetime)) {
            tempErrors.push("Dept. Time");
          }
          if (tempErrors.length > 0) {
            errors.push(
              `${tempErrors.join(", ")} required`
            );
          }
          if (
            completedInfo.actual_start_datetime &&
            completedInfo.actual_end_datetime &&
            completedInfo.actual_end_datetime.isBefore(completedInfo.actual_start_datetime)
          ) {
            errors.push("Departure time cannot be less than arrival time");
          }

          if (
            completedInfo.actual_start_datetime &&
            completedInfo.captured_at &&
            completedInfo.captured_at.isBefore(completedInfo.actual_start_datetime)
          ) {
            errors.push("Captured time cannot be less than arrival time");
          }

          if (
            completedInfo.actual_end_datetime &&
            completedInfo.captured_at &&
            completedInfo.actual_end_datetime.isBefore(completedInfo.captured_at)
          ) {
            errors.push("Departure time cannot be less than captured time");
          }
        }
        if (errors.length > 0) {
          this.setState({ errors, inProgress: false });
        } else {
          const completedInfo = _.cloneDeep(this.state.completedInfo);
          const data = {
            order_ids: this.state.orders.map((order) => order.id), // [this.state.order.id],
            status: this.state.currentStatus,
            exception_code: this.state.exceptionCode,
            exception_message:
              this.state.exceptionCode === "CUSTOM"
                ? this.state.exceptionMessage
                : "",
            deletion_reason: this.state.showReason ? this.state.reason : "",
            //driver_id: this.state.selectedDriver ? this.state.selectedDriver : '',
            warehouse_id: selectedWarehouses,
          };
          if (data.status === "COMPLETED") {
            const completed_date = completedInfo.completed_date;

            // if(completedInfo.length > 1){
            //   data.complete_for = this.state.complete_for;
            // } else if(completedInfo.length === 1){
            //   data.complete_for = completedInfo[0].type_of_loc;
            // }
            const orders = [...this.state.orders];
            console.log("orders", orders);
            const timeData = {
              sign_by: completedInfo.sign_by || "",
              signer_title: completedInfo.signer_title || "",
              captured_at: completedInfo.captured_at
                ? `${moment(completedInfo.completed_date).format("YYYY-MM-DD")} ${moment(
                    completedInfo.captured_at
                  ).format("HH:mm")}`
                : null,
              actual_start_datetime: `${moment(completed_date).format(
                "YYYY-MM-DD"
              )} ${moment(completedInfo.actual_start_datetime).format(
                "HH:mm"
              )}`,
              actual_end_datetime: `${moment(completed_date).format(
                "YYYY-MM-DD"
              )} ${moment(completedInfo.actual_end_datetime).format("HH:mm")}`,
            };
            const locData = [];
            orders.forEach((orderObject) => {
              if (!["T", "LH"].includes(orderObject.type_of_order)) {
                const label = ["R", "TR"].includes(orderObject.type_of_order)
                  ? "PICKUP"
                  : "DELIVERY";
                locData.push({
                  id: orderObject.id,
                  order_id: orderObject.id,
                  type_of_order: orderObject.type_of_order,
                  customer_order_number: orderObject.customer_order_number,
                  location_id: orderObject.cs_location_id,
                  account_id: orderObject.account_id,
                  type_of_loc: label,
                  ...timeData,
                  status: orderObject.status,
                  nsr: !orderObject.nsr,
                  driver_id: !this.state.postDispatchStatus.includes(
                    orderObject.status
                  )
                    ? completedInfo.driver_id
                    : "",
                });
              } else {
                const { origin, destination } = orderObject;
                if (origin.status !== "COMPLETED") {
                  locData.push({
                    id: orderObject.id,
                    order_id: orderObject.id,
                    type_of_order: orderObject.type_of_order,
                    customer_order_number: orderObject.customer_order_number,
                    location_id: origin?.location_id,
                    account_id: orderObject.account_id,
                    type_of_loc: "PICKUP",
                    ...timeData,
                    status: origin.status,
                    nsr: !orderObject.nsr,
                    driver_id: !this.state.postDispatchStatus.includes(
                      origin.status
                    )
                      ? completedInfo.driver_id
                      : "",
                  });
                }
                if (
                  !isEmpty(destination) &&
                  destination.location_id &&
                  destination.status !== "COMPLETED"
                ) {
                  locData.push({
                    id: orderObject.id,
                    order_id: orderObject.id,
                    type_of_order: orderObject.type_of_order,
                    customer_order_number: orderObject.customer_order_number,
                    location_id: destination?.location_id,
                    account_id: orderObject.account_id,
                    type_of_loc: "DELIVERY",
                    ...timeData,
                    status: orderObject.status,
                    nsr: !orderObject.nsr,
                    driver_id: !this.state.postDispatchStatus.includes(
                      orderObject.status
                    )
                      ? completedInfo.driver_id
                      : "",
                  });
                }
              }
            });
            data.captured_info = [...locData];
          }
          if (this.state.currentStatus === "ON_HOLD") {
            data.on_hold_reason = this.state.exceptionMessage;
          }
         
          changeStatusOfOrders(data).then((result) => {
            if (result.success) {
              if(result?.orders?.exceptional_orders){
                alertMessage(result?.orders?.exceptional_orders,5,"warning")
              } else {
                alertMessage(I18n.t("messages.processed"), 10)
              }
              this.setState(
                {
                  inProgress: false,
                  errors: [],
                  statusErrors: [],
                  showReason: false,
                  reason: "",
                },
                () => {
                  this.props.saveStatus();
                }
              );
            } else {
              // alertMessage(result.errors[0], 'error', 10);
              this.setState({
                inProgress: false,
              });
              this.processErrors(result.errors);
              if (this.props.hasBulkOrders) {
                const errorOrders = result.errors
                  ? result.errors.map((error) => error.order_number)
                  : [];
                this.props.processErrors(errorOrders);
              }
            }
          });
        }
      }
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  processErrors = (errors) => {
    if (errors?.length > 0) {
      const errorValue = errors[0];
      if (_.isString(errorValue)) {
        this.setState({
          errors,
          statusErrors: [],
        });
      } else if (_.isObject(errorValue)) {
        const statusErrors = [];
        errors.forEach((errObj) => {
          if (_.isObject(errObj) && errObj.order_number) {
            statusErrors.push(errObj);
          }
        });
        this.setState({
          errors: [],
          statusErrors,
        });
      }
    }
  };

  handleDetailsTimeChange = (element, value) => {
    const completedInfo = { ...this.state.completedInfo };
    if (isEmpty(value)) {
      value = null; // moment()
    }
    completedInfo[element] = value;
    this.setState({
      completedInfo,
    });
  };

  handleDetailsChange = (element, value) => {
    const completedInfo = { ...this.state.completedInfo };
    if (isEmpty(value)) {
      value = "";
    }
    completedInfo[element] = value;
    this.setState({
      completedInfo,
    });
  };

  renderCapturedInfo = () => {
    const { completedInfo, isMilitaryTime, orders } = this.state;
    const {isSignatutredRequired} = this.state;
    const orderStatus = orders?.length > 0 ?  orders[0].status : "";
    return (
      <div className="order_details_form marginTop10">
        <Row gutter={16}>
          <Col span={6}>
            <FormItem
              label=" Signed By"
              name="signed_by"
              require={isSignatutredRequired}
            >
              <Input
                type="text"
                value={completedInfo.sign_by}
                maxLength={40}
                size="small"
                onChange={(e) =>
                  this.handleDetailsChange("sign_by", e.target.value)
                }
                required
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label=" Signed Title" name="signed_title">
              <Input
                type="text"
                value={completedInfo.signer_title}
                size="small"
                onChange={(e) =>
                  this.handleDetailsChange("signer_title", e.target.value)
                }
                required
              />
            </FormItem>
          </Col>
          {/* <Col span={12}>
            {this.renderDateTimeField(
              loc,
              I18n.t("general.capture_date"),
              I18n.t("general.capture_time"),
              "captured_at",
              true,
              true,
              true,
              true,
              index
            )}
          </Col> */}
          <Col span={6}>
            {this.renderDateTimeField(
              completedInfo,
              "Completed Date",
              "Completed Time",
              "completed_date",
              true,
              false,
              isSignatutredRequired,
              false
            )}
          </Col>
          <Col span={6}>
            {this.renderDateTimeField(
              completedInfo,
              "Captured Date",
              "Captured Time",
              "captured_at",
              false,
              true,
              false,
              isSignatutredRequired
            )}
          </Col>
            {!this.state.postDispatchStatus.includes(
                    orderStatus
                  ) &&  <Col span={6}>
                <FormItem label="Driver" require>
                  <Select
                    style={{ width: "100%" }}
                    //onChange={this.handleDriverChange}
                    onChange={(e) => this.handleDetailsChange("driver_id", e)}
                    value={completedInfo.driver_id}
                    size="small"
                    filterOption={(input, option) => {
                      if (option.props.children) {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }
                    }}
                    showSearch
                  >
                    <Select.Option key={"driver"} value={""}>
                      --select--
                    </Select.Option>
                    {this.state.drivers.map((driverDetail) => (
                      <Select.Option key={driverDetail.id} value={driverDetail.id}>
                        {driverDetail.employee_code}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
          </Col>
          }

          <Col span={6}>
            {this.renderDateTimeField(
              completedInfo,
              "Arrival Date",
              "Arrival Time",
              "actual_start_datetime",
              false,
              true,
              false,
              true
            )}
          </Col>
          <Col span={6}>
            {this.renderDateTimeField(
              completedInfo,
              "Dept. Date",
              "Dept. Time",
              "actual_end_datetime",
              false,
              true,
              false,
              true
            )}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const currentIndex = Object.values(this.state.status).indexOf(
      this.state.orderStatus
    );
    return (
      <div className="marginTop10">
        <Row gutter={16}>
          <Col xs={24}>
            <Row
              style={{
                padding: "10",
                backgroundColor: "#fff",
              }}
            >
              <Row>
                <Col xs={24} style={{ paddingBottom: 10 }}>
                  <div className="marginBottom15">
                    <Typography.Text strong>
                      {" "}
                      Current Status :{" "}
                      {currentIndex >= 0
                        ? Object.keys(this.state.status)[currentIndex]
                        : this.state.orderStatus}
                    </Typography.Text>
                  </div>
                  <Row type="flex" gutter={16}>
                    <Col className="textBold" xs={23} md={6}>
                      Select Status
                    </Col>
                    <Col xs={1} md={1}>
                      {" "}
                      :{" "}
                    </Col>
                    <Col xs={24} md={17}>
                      <Select
                        style={{ width: 200 }}
                        className="routes-input"
                        onChange={(e) => this.handleChange("currentStatus", e)}
                        defaultValue="Change Status"
                        value={this.state.currentStatus}
                        size="small"
                        filterOption={(input, option) => {
                          if (option.props.children) {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }
                        }}
                        showSearch
                      >
                        {Object.keys(this.state.status).map((key, index) => {
                          const unwantedKeys = [
                            "Preplan",
                            "Prepare",
                            "Dispatched",
                            "Assigned",
                          ];
                          const isUnwantedKey = unwantedKeys.includes(key);
                          const isNotAllKey = key !== "All";
                          const isNotSameOrderStatus =
                            this.state.status[key] !== this.state.orderStatus;

                          if (
                            !isUnwantedKey &&
                            isNotAllKey &&
                            isNotSameOrderStatus
                          ) {
                            return (
                              <Select.Option
                                value={this.state.status[key]}
                                key={index}
                              >
                                {key}
                              </Select.Option>
                            );
                          }

                          return null; // Skip unwanted keys
                        })}
                      </Select>
                    </Col>
                  </Row>
                  {this.state.currentStatus === "COMPLETED" &&
                    this.state.locationInfo.length > 1 && (
                      <Row type="flex" gutter={16} className="marginTop10">
                        <Col className="textBold" xs={23} md={6}>
                          Complete
                        </Col>
                        <Col xs={1} md={1}>
                          {" "}
                          :{" "}
                        </Col>
                        <Col xs={24} md={17}>
                          <Radio.Group
                            onChange={(e) =>
                              this.handleCompleteForChange(
                                "complete_for",
                                e.target.value
                              )
                            }
                            value={this.state.complete_for}
                            size={"small"}
                          >
                            {Object.keys(AppConfig.orderTypeValues).map(
                              (key) => (
                                <Radio key={key} value={key}>
                                  {AppConfig.orderTypeValues[key]}
                                </Radio>
                              )
                            )}
                          </Radio.Group>
                        </Col>
                      </Row>
                    )}
                  {(["NEW", "RECEIVED", "VERIFIED"].includes(
                    this.state.orderStatus
                  ) ||
                    (
                      this.state.orders[0].status !== "COMPLETED")) &&
                    this.state.currentStatus === "COMPLETED" && (
                      <>{this.renderCapturedInfo()}</>
                    )}
                </Col>
                <Col xs={24} style={{ paddingBottom: 10 }}>
                  {this.state.currentStatus === "EXCEPTION" && (
                    <Row gutter={16} type="flex">
                      <Col className="textBold" xs={23} md={6}>
                        Select Exception
                      </Col>
                      <Col xs={1} md={1}>
                        {" "}
                        :{" "}
                      </Col>
                      <Col xs={24} md={17}>
                        <Select
                          style={{ width: 200 }}
                          className="routes-input"
                          onChange={(e) =>
                            this.handleChange("exceptionCode", e)
                          }
                          defaultValue="Change Status"
                          value={this.state.exceptionCode}
                          size="small"
                          filterOption={(input, option) => {
                            if (option.props.children) {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }
                          }}
                          showSearch
                        >
                          {this.state.exceptions.map((excep) => (
                            <Select.Option
                              value={excep.exception_code}
                              key={excep.exception_code}
                            >
                              {excep.exception_message}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  )}
                  {(this.state.exceptionCode === "CUSTOM" ||
                    this.state.currentStatus === "ON_HOLD") && (
                    <div className="marginTop10">
                      <FormItem label={I18n.t("messages.reason_to_change")}>
                        <TextArea
                          rows={4}
                          placeholder={I18n.t("general.message")}
                          className="sop-form-textArea"
                          value={this.state.exceptionMessage}
                          onChange={(e) =>
                            this.handleChange(
                              "exceptionMessage",
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  )}
                  {this.state.showReason && (
                    <div>
                      <FormItem label={I18n.t("messages.reason_to_change")}>
                        <ReasonWindow
                          reasonElement="reason"
                          reasonValue={this.state.reason}
                          handleOnChange={this.handleChange}
                          showButtons={false}
                        />
                      </FormItem>
                    </div>
                  )}
                </Col>
              </Row>
              {this.props.hasBulkOrders && (
                <Row>
                  <Col span={24} className="selecetdOrderPanel">
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      className="marginTop10"
                    >
                      <Panel
                        header="Selected Orders"
                        key="1"
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <p style={{ maxHeight: 70, overflowY: "auto" }}>
                          {this.state.orders
                            .map((order) => order.customer_order_number)
                            .join(", ")}
                        </p>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  offset={1}
                  xs={22}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <Button type="danger" onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  &nbsp; &nbsp;
                    <Button
                      type="primary"
                      loading={this.state.inProgress}
                      onClick={this.handleSave}
                      disabled={
                        !this.state.currentStatus ||
                        (this.state.currentStatus === "EXCEPTION" &&
                          isEmpty(this.state.exceptionCode)) ||
                        (this.state.currentStatus === "EXCEPTION" &&
                          this.state.exceptionCode === "CUSTOM" &&
                          isEmpty(this.state.exceptionMessage)) ||
                        (this.state.showReason && this.state.reason.length < 10)
                      }
                      icon="save"
                    >
                      Update
                    </Button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {/* FormErrors(this.state.errors) */}
            {this.state.statusErrors.length > 0 && (
              <Alert
                message="Error"
                description={
                  <div>
                    {this.state.statusErrors.map((err) =>
                      _.isObject(err) ? (
                        <Row>
                          <Col xs={8} className="textBold">
                            {err.order_number}
                          </Col>
                          <Col xs={16}>
                            {_.isArray(err.errors) ? err.errors.join(",") : ""}
                          </Col>
                        </Row>
                      ) : (
                        <Fragment />
                      )
                    )}
                  </div>
                }
                type="error"
                closable={false}
              />
            )}
            {this.state.errors.length > 0 && FormErrors(this.state.errors)}
          </Col>
        </Row>
      </div>
    );
  }
}

MoreChangeStatusForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveStatus: PropTypes.func.isRequired,
  orderObject: PropTypes.shape().isRequired,
  currentStatus: PropTypes.string.isRequired,
};

export default MoreChangeStatusForm;

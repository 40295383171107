import userStore from '../stores/UserStore';
import {
    Call,
    ApiUrl,
    PageParams,
    ParseGeneralResponse,
    setObjectToFormData,
  } from './ApiUtils';
import I18n from '../common/I18n';
import _ from "lodash";
import { isEmpty } from '../common/Common';

export const VehicleTypeApi = {
  create: (payload) => {
    payload.operation_code = 'VTC'
    const url = ApiUrl('v2/vehicle_types')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { vehicle_types: 'vehicle_types' },
    })
  },

  update: (id,payload) => {
    payload.operation_code = 'VTU'
    const url = ApiUrl('v2/vehicle_types/' + id)
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { vehicle_types: 'vehicle_types' },
    })
  },

  fetch: (page =1, perPage ='', filter = {}) => {  
    const organization_id = userStore.getStateValue("selectedOrg")
    let url = ApiUrl(`v2/vehicle_types?${PageParams(
      page,
      perPage,
    )}&organization_id=${organization_id}&operation_code=VTI`,
  );
    if (!_.isEmpty(filter) && !isEmpty(filter.sortBy) && !isEmpty(filter.sortByType)) {
      url = `${url}&sort_by=${filter.sortBy}&sort_order=${I18n.t(`general.sort_${filter.sortByType}`)}`;
    }
    return Call('get', url, {}, ParseGeneralResponse, {
      responseDataKeys: { vehicle_types: 'vehicle_types',pagination: 'meta.pagination' },
    })
  },
  delete: (id) => {
    const url = ApiUrl('v2/vehicle_types/' + id)
    return Call('delete', url, {operation_code:'VTD'}, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  fetchOne: (id) => {
    
    const url = ApiUrl(`v2/vehicle_types/${id}?operation_code=VTS`);
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
      },
    });
  },
}

import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Upload, Button, InputNumber, Typography, Row, Col, Radio, Slider, Select, Spin } from 'antd';
import { alertMessage } from '../common/Common';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { Text, Title } = Typography;
const { Option } = Select;

const PDFViewer = (props) => {
  const { file } = props;
  const [isView,setIsView] = useState(!_.isEmpty(file) ? true : false)
  const [ pdfFile, setPdfFile ] = useState(isView ? file : null);
  const [ numPages, setNumPages ] = useState(null);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ scale, setScale ] = useState(1.0);
  const [ viewType, setViewType ] = useState('continuous');
  const [viewTransformed, setViewTransformed] = useState(false);
  const viewerRef = useRef(null);
  const [pdfLoadFailed, setPdfLoadFailed] = useState(false);



  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = () => {
    setPdfLoadFailed(true);
  };
  

  const zoomLevels = [ 50, 75, 100, 125, 150, 200 ];

  const handleZoomChange = (value) => {
    setScale(value / 100);
  };

  const handlePageChange = (value) => {
    setPageNumber(value);
  };

  const handleUpload = (file) => {
    setPdfFile(URL.createObjectURL(file));
    setScale(1.0);
  };

  const renderUploadButton = () => (
    <Upload
      beforeUpload={ handleUpload }
      accept=".pdf"
      showUploadList={ false }
    >
      <Button style={ { backgroundColor: '#1890ff', color: 'white' } }>Upload PDF</Button>
    </Upload>
  );

  const renderZoomControl = () => (
    <Select defaultValue={ 100 } onChange={ handleZoomChange } style={ { width: 120 } } disabled={!pdfFile}>
      { zoomLevels.map((level) => (
        <Option key={ level } value={ level }>
          { level }%
        </Option>
      )) }
    </Select>
  );

  const isContinuousView = viewType === 'continuous';

  const renderPageNavigation = () => (
    <Row type="flex" gutter={[16, 16]}>
      <Col span={24}>
        {numPages && !isContinuousView && (
          <Slider
            min={1}
            max={numPages}
            value={pageNumber}
            onChange={handlePageChange}
            style={{ width: 200, margin: "0 10px" }}
          />
        )}
      </Col>
      <Col span={24}>
        {numPages && !isContinuousView && (
          <>
            <InputNumber
              min={1}
              max={numPages}
              value={pageNumber}
              onChange={handlePageChange}
              style={{ width: 60 }}
            />{" "}
            <Text>of {numPages}</Text>
          </>
        )}
      </Col>
    </Row>
  );

  const renderViewTypeSelector = () => (
    <div>
      <Button 
        type={viewType === 'page' ? 'primary' : 'default'}
        onClick={() => handleViewTypeChange('page')}
      >
        Page by Page
      </Button>
      &nbsp;&nbsp;
      <Button 
        type={viewType === 'continuous' ? 'primary' : 'default'}
        onClick={() => handleViewTypeChange('continuous')}
      >
        Continuous
      </Button>
    </div>
  );
  
  const handleViewTypeChange = (value) => {
    if(!pdfFile) {
      alertMessage("Please upload a PDF file.","error",5);
      return;
    }
    setViewTransformed(true);
    setViewType(value);
    setPageNumber(1);
    setTimeout(() => setViewTransformed(false), 500);
  };

  const renderPDFViewer = () => (
    pdfFile ? (
      <div 
        style={ 
          pdfLoadFailed 
          ? { marginTop: '16px', height: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center' } 
          : { marginTop: '16px', height: '600px', overflow: 'auto', display: 'flex', justifyContent: 'center' } 
        } 
        ref={viewerRef} 
        onScroll={handleScroll}
      >
        <Document 
          file={ pdfFile } 
          onLoadSuccess={ onDocumentLoadSuccess } 
          onLoadError={ onDocumentLoadError }
          renderMode={ viewType === 'continuous' ? 'continuous' : undefined }
        >
          { viewType === 'page' ? (
            <Page pageNumber={ pageNumber } scale={ scale } />
          ) : (
            [ ...Array(numPages) ].map((_, index) => (
              <Page key={ `page_${index + 1}` } pageNumber={ index + 1 } scale={ scale } />
            ))
          ) }
        </Document>
      </div>
    ) : (
      <Title level={ 2 }>Please upload a PDF file.</Title>
    )
  );

  const renderControls = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        { renderViewTypeSelector() }
        { renderZoomControl() }
        {numPages && <Text strong>Page { pageNumber } of { numPages }</Text>}  
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {!isView && renderUploadButton() }
        { renderPageNavigation() }
      </div>
    </div>
  );

  const handleScroll = () => {
    if (!viewerRef.current) return;
    else if(! isContinuousView) return;
    const scrollTop = viewerRef.current.scrollTop;
    const pageHeight = viewerRef.current.scrollHeight / numPages;
    const currentPage = Math.floor(scrollTop / pageHeight) + 1;
    setPageNumber(currentPage);
  };

  useEffect(() => {
    if (viewerRef.current) {
      viewerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (viewerRef.current) {
        viewerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [numPages]);

  return (
    <div style={ { padding: '16px', backgroundColor: '#f0f0f0' } }>
      <Row gutter={ [ 16, 16 ] }>
        <Col span={ 24 }>
          { renderControls() }
          <Spin spinning={ viewTransformed}>
          { renderPDFViewer() }
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default PDFViewer;

import React, { useState, useEffect, Fragment, useContext } from 'react';
import {
  fetchAccessorialDetails,
  fetchAccessorialsComponents,
  fetchAccountAccessorials as fetchAccountComponentAccessorials,
  saveBillingAccessorial,
  saveManualBillingAccessorial,
  updateAccessorialInvoiceLine
} from '../api/Accessorials';
import { fetchOrderAccessorials } from '../api/OrdersApi';
import { renderAlertMessage } from '../helpers/common';
import { alertMessage, isEmpty } from '../common/Common';
import { setAccessorialResponse } from '../helpers/orders';
import AccesorialList from '../components/billing/AccesorialList';
import BillingAccessorialRow from '../components/orders/BillingAccessorialRow';
import { Modal } from 'antd';
import { OrgContext } from '../context/OrgContext';

function AccessorialCharges (props) {
  const { drivers, technicians } = props;
  const { isInvoiceApproved, data, currentSection, showActions, invoice_type, handleOnChange, size, footer, deleteClick, saveItem, totalRecords, addItem, showAddIcon, isReadyOnly, isLocked, reqData, hasNew, bordered, orderData, glCodesList } = props;
  const [ noParamComponents, setNoParamComponents ] = useState([]);
  const [ inProgress, setInProgress ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ accountComponentAccessorial, setAccountComponentAccessorial ] = useState([]);
  const [ orgComponents, setOrgComponents ] = useState([]);
  const [ orderAccessorials, setOrderAccessorials ] = useState([]);
  const [ accountId, setAccountId ] = useState(props.accountId);
  const [ orgId, setOrgId ] = useState(props.orgId);
  const [ orderId, setOrderId ] = useState(props.orderId);
  const [ displayComponents, setDisplayComponents ] = useState([]);
  const [ slotConfig, setSlotConfig ] = useState({});
  const [ authorizeDetails, setAuthorizeDetails ] = useState({});
  const decimalPoints = useState(window.localStorage.getItem('round_off_decimals'));
  const [ clickedSave, setClickedSave ] = useState(false);
  const [ isAccesorialLoading, setIsAccesorialLoading ] = useState(false);
  const [ currentAccCost, setCurrentAccCost ] = useState(0);
  const [ clickedCancel, setClickedCancel ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const [ unEditedRecordData, setUnEditedRecordData ] = useState({});
  const [ currentAcc, setCurrentAcc ] = useState(null);
  const [ currentOrderNo, setCurrentOrderNo ] = useState(null);
  const [ activeRow, setActiveRow ] = useState(null);
  const [ fetchingAccCost, setFetchingAccCost ] = useState(false);
  const [ listData, setListData ] = useState(data);
  const [ isReadOnly, setIsReadOnly ] = useState(false);
  const [ expandedRowKeys, setExpandedRowKeys ] = useState([]);
  const { currentOrg } = useContext(OrgContext);
  const showZeroInvLines = _.get(props, 'org_rules.show_zero_invoice_lines', false) === 'true'; 

  useEffect(() => {
    // setListData(data);
    // setExpandedRowKeys(data.map((item) => item.id));
    // instead of id use key 
    const newRecordList = data.map((record, index) => { return { ...record, key: index }; });
    setListData(newRecordList);
    setExpandedRowKeys(newRecordList.map((item) => item.key));
    const noNewRecord = !listData.find(i => i.isNew);
    const isNewExist = _.some(listData, item => _.has(item, 'isNew'));
    if (noNewRecord || !isNewExist) {
      setIsActiveRecordFlatCharge(false);
    }
  }
  , [ props.data ]);

  const cancelAccesorial = () => {
    setClickedCancel(true);
  };


  const getOrgComponents = () => {
    fetchAccessorialsComponents().then((result) => {
      if (result.success) {
        const orgComponentsResponse = result.components || [];
        const displayComponents = [];
        const noParamComponents = [];
        orgComponentsResponse.forEach((component) => {
          if (
            component.component_params &&
            component.component_params.length > 0
          ) {
            const componentParams = component.component_params || [];
            const visibleParams = componentParams.filter((param) =>
              param.visible_to.includes('DISPATCHER'),
            );
            if (visibleParams.length > 0) {
              displayComponents.push(component.code);
            } else {
              noParamComponents.push(component.code);
            }
          } else {
            noParamComponents.push(component.code);
          }
        });
        setDisplayComponents(displayComponents);
        setNoParamComponents(noParamComponents);
        setOrgComponents(orgComponentsResponse);
        // getAccountComponentAccessorials();
      }
    });
  };

  useEffect(() => {
    if (orgComponents.length)
      getAccountComponentAccessorials();
  }, [ orgComponents ]);


  const getDeifinitions = (accessorial) => {
    if (!isEmpty(accessorial)) {
      const components = accessorial.components || [];
      const accComponents = components.map(
        (component) => component.component_code,
      );
      const componentDefs = orgComponents.filter(
        (component) =>
          accComponents.includes(component.code) &&
          displayComponents.includes(component.code),
      );
      return componentDefs;
    }
    return [];
  };

  const getSlotDetails = (id, reqCode = '') => {
    getAccessorialDetails(id, reqCode);
  };

  const getAccessorialDetails = (id, reqCode) => {
    fetchAccessorialDetails(id).then((result) => {
      if (result.success) {
        const currentAccessorial = result.accessorial || [];
        const componentParams = currentAccessorial ? currentAccessorial.component_params : [];
        const slotParams = !isEmpty(reqCode) ?
          componentParams.filter(param => param.component_code === reqCode) :
          [ ...componentParams ];
        const newSlotConfig = { ...slotConfig };
        newSlotConfig[ id ] = slotParams;
        setInProgress(false);
        setSlotConfig(newSlotConfig);
      } else {
        const newSlotConfig = { ...slotConfig };
        newSlotConfig[ id ] = [];
        renderAlertMessage(result.errors);
        setInProgress(false);
        setSlotConfig(newSlotConfig);
      }
    });
  };

  const getAccountComponentAccessorials = () => {
    if (accountId) {
      setIsLoading(true);

      fetchAccountComponentAccessorials(orgId, accountId, 'DISPATCHER').then(
        (result) => {
          if (result.success) {
            const accessorials = result.accessorials || [];
            const accountComponentAccessorial = accessorials.map(
              (accessorial) => {
                const componentDefs = getDeifinitions(accessorial);
                const slotObject = _.find(componentDefs, { code: 'SLOTS' });
                if (!isEmpty(slotObject)) {
                  getSlotDetails(accessorial.id, 'SLOTS');
                }
                return Object.assign({}, accessorial, {
                  componentDefs,
                });
              },
            );
            setInProgress(false);
            const sortedAccountComponentAccessorial = _.sortBy(accountComponentAccessorial, [ "standard_code", "accessorial_code" ]);
            setAccountComponentAccessorial(sortedAccountComponentAccessorial);
            getOrderAccessorials();
          } else {
            setInProgress(false);
          }
        }
      );
    }
  };



  const getOrderAccessorials = () => {
    fetchOrderAccessorials(orderId, '').then((result) => {
      if (result.success) {
        const orderAccessorial = result.accessorials || {};
        const accessorials = orderAccessorial.account_accessorial_params || [];
        const authorizeDetails = {};
        const allowedIds = {};
        accountComponentAccessorial.forEach(
          (accessorial) => {
            const components = accessorial.components ?
              accessorial.components.map(record => record.component_code)
              :
              [];
            allowedIds[ accessorial.id ] = [ ...components ];
          });
        const orderAccessorials = setAccessorialResponse(
          accessorials,
          true,
          allowedIds,
        );
        orderAccessorials.forEach(rec => {
          if (!isEmpty(rec.authorized_by)) {
            authorizeDetails[ rec.accessorial_code ] = {
              authorized_by: rec.authorized_by,
              authorization_code: rec.authorization_code,
              comments: rec.comments,
              is_modified: false,
            };
          }
        });
        setOrderAccessorials(orderAccessorials);
        setAuthorizeDetails(authorizeDetails);

      } else {
        renderAlertMessage(result.errors);
      }
    });
  };



  useEffect(() => {
    getOrgComponents();
    // setOrgComponents(orgComponents);
    // setDisplayComponents(displayComponents);
    // setNoParamComponents(noParamComponents);
  }
    , []);





  const accessorialSaved = (record, index, resultAccessorials) => {
    // save it in listData
    const newRecord = { ...record };
    newRecord.accessorial_charges = resultAccessorials;
    const totalCost = resultAccessorials.accessorial_charges.reduce((total, charge) => total + (charge.component_cost), 0);
    newRecord.invoice_value = totalCost.toFixed(decimalPoints);
    const newListData = [ ...listData ];
    newListData[ index ] = newRecord;

    // setListData(newListData);
    // add key to the record
    const newRecordList = newListData.map((record, index) => {
      return { ...record, key: index };
    });
    setListData(newRecordList);
    props.handleRecordUpdate(newRecord, index, currentOrderNo);
    props.handleOnChange(index, 'invoice_value', newRecord.invoice_value, currentOrderNo);
  };


  const [ currentPayload, setCurrentPayload ] = useState({});
  const [isActiveRecordFlatCharge, setIsActiveRecordFlatCharge] = useState(false);
  const [ isUpdatingZerothVal, setIsUpdatingZerothVal ] = useState(false);

  const handleCurrentData = (accPayload) => {
    setCurrentPayload(accPayload);
  };

  const BillingAccExpandedRow = ({ record, index, currentAcc }) => {
    const [ expOrderAccessorials, setExpOrderAccessorials ] = useState([]);
    const [ expAuthorizeDetails, setExpAuthorizeDetails ] = useState(authorizeDetails);


    useEffect(() => {
      const authorizeDetails = {};
      const allowedIds = {};
      accountComponentAccessorial.forEach(
        (accessorial) => {
          const components = accessorial.components ?
            accessorial.components.map(record => record.component_code)
            :
            [];
          allowedIds[ accessorial.id ] = [ ...components ];
        });

      const orderAccessorials = setAccessorialResponse(
        [ record ],
        true,
        allowedIds,
      );


      orderAccessorials.forEach(rec => {
        if (!isEmpty(rec.authorized_by)) {
          expAuthorizeDetails[ rec.accessorial_code ] = {
            authorized_by: rec.authorized_by,
            authorization_code: rec.authorization_code,
            comments: rec.comments,
            is_modified: false,
          };
        }
      });
      setExpOrderAccessorials(orderAccessorials);
      // setExpAuthorizeDetails(authorizeDetails);
    }, [ orderAccessorials, accountComponentAccessorial ]);

    const handleOnParamChange = (
      accParam,
      componentCode,
      accountAccessorialId,
      eleValue,
    ) => {
      const accessorials = [ ...expOrderAccessorials ];
      const index = _.findIndex(accessorials, { 'account_accessorial_id': accountAccessorialId });

      if (index >= 0) {
        const param = _.get(accessorials, `[${index}]`);
        const componentIndex = _.findIndex(_.get(param, 'component_params', []), { 'component_code': componentCode });
        let currentComponent = {};

        if (componentIndex >= 0) {
          currentComponent = _.get(param, `component_params[${componentIndex}]`, {});
        }

        currentComponent.component_values = _.get(currentComponent, 'component_values', []);

        const paramObjIndex = _.findIndex(currentComponent.component_values, { 'accessorial_key': accParam });

        if (paramObjIndex >= 0) {
          _.set(currentComponent, `component_values[${paramObjIndex}].accessorial_value`, eleValue);
        } else {
          currentComponent.component_values.push({
            'accessorial_key': accParam,
            'accessorial_value': eleValue,
          });
        }

        if (componentIndex < 0) {
          param.component_params = _.get(param, 'component_params', []).concat({
            'component_code': componentCode,
            'component_values': currentComponent.component_values,
          });
        } else {
          _.set(param, `component_params[${componentIndex}]`, currentComponent);
        }

        _.set(accessorials, `[${index}]`, param);

        setExpOrderAccessorials(accessorials);
      }
    };


    const saveAccesorial = () => {
      const currentSelectedAcc = expOrderAccessorials.find(acc => acc.accessorial_code === currentAcc);
      const invoice_lines_to_save = isEditing ? listData.filter((record) => record.isEditing === true) : listData.filter((record) => record.isNew === true);
      const data = {
        organization_id: orgId,
        account_id: accountId,
        customer_order_id: orderId,
        location_id: invoice_lines_to_save[0].location_id,
        account_accessorial_id: currentSelectedAcc.account_accessorial_id,
        accessorial_code: currentSelectedAcc.accessorial_code,
        standard_code: currentSelectedAcc.standard_code,
        component_params: currentSelectedAcc.component_params,
        driver_pay_params: currentSelectedAcc.driver_pay_params,
        authorized_by: null,
        authorization_code: null,
        comments: null,
        invoice_line_id : invoice_lines_to_save[0].id,
        new_invoice_lines : invoice_lines_to_save,
      };

      handleCurrentData(data);
      setClickedSave(true)
    };

    const handleAddOrderCompAccessorial = (accountAccessorialId, accessorialCode) => {
      let newAuthorizeDetails = _.cloneDeep(authorizeDetails) || {};
      newAuthorizeDetails[ accessorialCode ] = newAuthorizeDetails[ accessorialCode ] || {};
      newAuthorizeDetails[ accessorialCode ].is_modified = !_.isEmpty(newAuthorizeDetails[ accessorialCode ]);

      let newOrderAccessorials = _.cloneDeep(expOrderAccessorials);
      const accountAccessorial = _.find(newOrderAccessorials, { id: accountAccessorialId });

      if (!accountAccessorial) {
        const accountAccessorialObj = _.find(accountComponentAccessorial, { id: accountAccessorialId });
        const accessorialObj = {
          account_accessorial_id: accountAccessorialId,
          accessorial_code: accessorialCode,
          standard_code: _.get(accountAccessorialObj, 'standard_code', ''),
          component_params: [],
          driver_pay_params: _.get(accountAccessorial, 'driver_pay_params', null),
        };

        newOrderAccessorials.push(accessorialObj);
        newAuthorizeDetails[ accessorialCode ].is_modified = true;
      }

      setExpOrderAccessorials(newOrderAccessorials);
      setExpAuthorizeDetails(newAuthorizeDetails);
      // setAuthorizeDetails(newAuthorizeDetails);
    };

    useEffect(() => {
      const currentAccObj = _.find(accountComponentAccessorial, { accessorial_code: currentAcc });
      if (currentAcc && record.isNew) {
        handleAddOrderCompAccessorial(currentAccObj._id, currentAccObj.accessorial_code);
      }

    }, [ currentAcc ]);

    const handleOnChange = (accountAccessorialId, element, value, source = "") => {
      const orderAccessorialsList = [ ...expOrderAccessorials ];
      const accountAccessorialParamIndex = _.findIndex(orderAccessorialsList, [ 'account_accessorial_id', accountAccessorialId ]);

      if (accountAccessorialParamIndex >= 0) {
        const accessorialObj = { ...orderAccessorialsList[ accountAccessorialParamIndex ] };

        if (source === 'eligible_for') {
          _.set(accessorialObj, 'driver_pay_params.driver_id', value);
        } else if (element === 'attempted_driver_ids') {
          let attempted_driver_ids = _.get(accessorialObj, 'driver_pay_params.attempted_driver_ids', []);
          if (source >= 0) {
            if (_.isEmpty(value)) {
              _.remove(attempted_driver_ids, (v, i) => i === source);
            } else if (attempted_driver_ids.length >= source) {
              attempted_driver_ids[ source ] = value;
            }
          } else {
            attempted_driver_ids.push(value);
          }
          _.set(accessorialObj, 'driver_pay_params.attempted_driver_ids', attempted_driver_ids);
        } else if (element === 'time_slots') {
          const [ from_time, to_time ] = _.split(value, '-');
          _.set(accessorialObj, 'driver_pay_params.time_slots', { from_time, to_time });
        } else {
          accessorialObj[ element ] = value;
        }

        orderAccessorialsList[ accountAccessorialParamIndex ] = accessorialObj;
        setExpOrderAccessorials(orderAccessorialsList);
      }
    };


    const isHavingZeroAmount = _.toNumber(record.invoice_value) == 0

    return (
      <BillingAccessorialRow
        onflatChargeDetected={ (val, isUpdatingZerothVal = false) => {
          setIsActiveRecordFlatCharge(val);
          setIsUpdatingZerothVal(isUpdatingZerothVal);
        } }
        data={ accountComponentAccessorial.filter(acc => acc.accessorial_code === record.accessorial_code) }
        isNew={ record.isNew   }
        isEditing={ record.isEditing }
        hideSearch
        isHavingZeroAmount={ isHavingZeroAmount }
        // cancelAccesorial={ cancelAccesorial }
        isAccesorialLoading={ isAccesorialLoading }
        isActiveRow={ index === activeRow }
        saveAccesorial={ saveAccesorial }
        orderAccessorials={ expOrderAccessorials }
        handleOnParamChange={ handleOnParamChange }
        handleAddOrderCompAccessorial={
          handleAddOrderCompAccessorial
        }
        decimalPoints={ decimalPoints }
        handleAddOrderCompStandardAccessorial={
          // this.handleAddOrderCompStandardAccessorial
          () => { }
        }
        handleCheckStandardAccessorial={
          // this.handleCheckStandardAccessorial
          () => { }
        }
        onChange={
          handleOnChange
        }
        displayComponents={ displayComponents }
        size="small"
        pagination={ { position: 'none' } }
        technicians={ technicians }
        drivers={ drivers }
        isReadOnly={ isReadOnly }
        // renderSlotObject={ renderSlotObject }
        // handleAuthorizeChange={this.handleAuthorizeChange}
        handleAuthorizeChange={ () => { } }
        authorizeDetails={ expAuthorizeDetails }
        required_accessorials={ [] }
        slotConfig={ slotConfig }
        currentAccCost={ currentAccCost }
        fetchingAccCost={ fetchingAccCost }
        cancelAccesorial={() => {
          cancelAccesorial()
          setClickedCancel(false);
          setIsActiveRecordFlatCharge(false);
          setActiveRow(null);
          if(isEditing){
            setIsEditing(false);
            setListData(listData.map((record) => {
              return { ...record, isEditing: false };
            }
            ));
            props.handleOnChange(index, 'isEditing', false, currentSection.order_number);
          }else{
            props.deleteClick(index, invoice_type, currentSection.order_number);
          }
        }}
      />
    );
  };

  const renderExpandedRow = (record, index) => {
    return (
      <BillingAccExpandedRow
        record={ record }
        index={ index }
        currentAcc={ currentAcc }
      />
    );
  };

  // // use memo to re render only if activeRow 
  // const renderExpandedRow = React.useMemo(() => {
  //   return (record, index) => {
  //     return (
  //       <BillingAccExpandedRow

  //         record={ record }
  //         index={ index }
  //         currentAcc={ currentAcc }
  //       />
  //     );
  //   };
  // }, [ activeRow, currentAcc ]);

  // findIndex, this.props.invoice_type, this.props.currentSection.order_number, currentState
  const saveItemNew = (currentState) => {
    const { invoice } = props;
    const { invoice_name, location_id : currentLocation, gl_code, invoice_quantity, invoice_value } = currentState;
    const finalPayload = {
      ...currentPayload,
      location_id : isEditing ? unEditedRecordData.location_id : currentLocation,
      new_location_id : currentLocation,
      order_group_type: invoice.order_group_type,
      reference_id: invoice.order_group_type === 'ORDER' ? invoice.customer_order_id : invoice.order_number,
      status: invoice.status,
      new_invoice_lines: currentPayload.new_invoice_lines.map((record) => {
        return {
          ...record,
          invoice_name,
          location_id : isEditing ? unEditedRecordData.location_id : currentLocation,
          gl_code,
          invoice_quantity,
          invoice_value,
          order_id : invoice.customer_order_id,
        };
      }),
    };

    setIsAccesorialLoading(true);
    const apiToCall = isEditing ? updateAccessorialInvoiceLine : saveBillingAccessorial;
    const unmutatedAtmpDriverids = _.get(finalPayload, 'driver_pay_params.attempted_driver_ids', []); 
    const unmutatedInvLines = _.get(finalPayload, 'new_invoice_lines', []); 
    const finalFinalPayload = { ...finalPayload , driver_pay_params : {
      ...finalPayload.driver_pay_params,
      attempted_driver_ids: unmutatedAtmpDriverids
    } , new_invoice_lines : unmutatedInvLines}
    apiToCall(finalFinalPayload).then((result) => {
      if (result.success) {
        if (!isEditing) {
          const resultAccessorials = Array.isArray(result.accessorials) && result.accessorials.length > 0 ? result.accessorials[ 0 ] : [];
          const hasZeroCost = resultAccessorials.accessorial_charges.length === 0 || _.some(resultAccessorials.accessorial_charges, { component_cost: 0 });
          if (hasZeroCost && !showZeroInvLines) {
            alertMessage('The calculated cost for the accessorial is zero, so it will not be saved', 'warning', 10);
          } else {
            alertMessage('Accessorial saved successfully', 'success');
          }
          props.refreshAccessorial()
        }

        // accessorialSaved(record, index, resultAccessorials);
        // if(!isEditing){
        props.refreshParentDetails();
        // }
        setActiveRow(null);
        setClickedSave(false);
        setIsEditing(false);
        setIsAccesorialLoading(false);
        props.reapproveBilling()
      } else {
        setIsAccesorialLoading(false);
        setClickedSave(false);
        renderAlertMessage(result.errors);
      }
    });
  }

  const fetchManualBillingAccessorial = (currAcc , locationId) => {
    const data = {
      organization_id: orgId,
      customer_order_id: orderId,
      account_accessorial_id: accountComponentAccessorial.find(acc => acc.accessorial_code === currAcc)._id,
      location_id: locationId,
      accessorial_code: currAcc,
      account_id: accountId,
    };
    setFetchingAccCost(true);
    saveManualBillingAccessorial(data).then((result) => {
      if (result.success) {
        const parsedCost = parseFloat(_.get(result, 'accessorialcost', 0));
        setCurrentAccCost(parsedCost);
        setFetchingAccCost(false);
      }
    }
    );
  };


  const savedAccessorials = listData
    .filter((record) => !record.isEditing && !record.isNew)
    .reduce((acc, record) => {
      if (!acc[ record.location_id ]) {
        acc[ record.location_id ] = [];
      }
      acc[ record.location_id ].push(record.accessorial_code);
      return acc;
    }, {});

  const filterdReqData = reqData.filter((record) => {
    const locationId = isEditing ? unEditedRecordData.location_id : _.get(_.find(listData, { isNew: true }), 'location_id', '');
    if (!locationId) return false;
    const activeRecord = {};
    if (isEditing) {
      activeRecord.location_id = unEditedRecordData.location_id;
    } else {
      activeRecord.location_id = locationId;
    }
    const locationAccessorials = savedAccessorials[ activeRecord.location_id ] || [];
    return !locationAccessorials.includes(record.accessorial_code);
  });


  return (
    <AccesorialList
      isActiveRecordFlatCharge={ isActiveRecordFlatCharge }
      currentAccCost={ currentAccCost }
      className="accessorial-charges-list"
      isInvoiceApproved={ isInvoiceApproved }
      data={ listData }
      currentSection={ currentSection }
      showActions={ showActions }
      invoice_type={ invoice_type }
      handleOnChange={ (index, element, value, orderNo) => {
        if (element === 'accessorial_code') {
          if (value.length) {
            setCurrentAcc(value);
            setCurrentOrderNo(orderNo);
            setActiveRow(index);
            const currentRecord = isEditing ? listData.find((record) => record.isEditing === true) : listData.find((record) => record.isNew === true);
            fetchManualBillingAccessorial(value , currentRecord.location_id);
          }
        }else if (element === 'location_id') {
          if (currentAcc) {
            fetchManualBillingAccessorial(currentAcc, value);
          }
          const updatedRecord = listData.find((record) => (record.isEditing === true) || (record.isNew === true));
          updatedRecord.location_id = value;
          setListData(listData.map((record) => {
            return { ...record, location_id: value };
          }
          ));
        }
        handleOnChange(index, element, value, orderNo);
      } }
      expandedRowRender={ (record, index) => {
        const isZeroInvValue = _.toNumber(unEditedRecordData.invoice_value) == 0;
        return (record.accessorial_code && (record.isNew)) || (activeRow === index && isZeroInvValue) ? renderExpandedRow(record, index) : null;
      } }
      onExpand={ (expanded, record) => {
        if (expanded) {
          setExpandedRowKeys([ ...expandedRowKeys, record.key ]);
        } else {
          setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key));
        }
      } }
      accessorials={ props.accessorials }
      expandedRowKeys={ expandedRowKeys }
      rowClassName={ (record) => (((_.isEmpty(record.accessorial_code) || !record.isNew)) ? 'no-expand-icon' : '') }
      setClickedSave={setClickedSave}
      size="small"
      footer={ footer }
      pagination={ { position: "none" } }
      // deleteClick={ deleteClick }
      deleteClick={(index,invoiceType,orderNum) => {
        setClickedCancel(false);
        setIsActiveRecordFlatCharge(false);
        setActiveRow(null);
        if(isEditing){
          setIsEditing(false);
          setListData(listData.map((record) => {
            return { ...record, isEditing: false };
          }
          ));
          props.handleOnChange(index, 'isEditing', false, orderNum);
        }else{
          props.deleteClick(index,invoiceType, orderNum);
        }
      }}
      saveItem={ (currentState, index, invoiceType, orderNum) => {
        if(isActiveRecordFlatCharge && !isUpdatingZerothVal){
          setIsActiveRecordFlatCharge(false);
          setIsEditing(false);
          props.saveItem(index, invoiceType, orderNum , currentState);
        }else{
        if (isEditing) {
          setIsEditing(false);
          if(index===undefined){
            saveItemNew(currentState);
          }
          else props.editSave(index, invoiceType, orderNum, currentState.id);
        } else {
          saveItemNew(currentState);
        }
        }
      } }
   
      totalRecords={ totalRecords }
      addItem={ (invoiceType, orderNum, locations) => { 
        // if any editing is in progress then show modal confirmation
        if(isEditing){
          Modal.confirm({
            title: 'Are you sure you want to add a new accessorial?',
            content: 'You have unsaved changes, adding a new accessorial will discard the changes. Do you want to continue?',
            onOk() {
              setIsEditing(false);
              props.addItem(invoiceType, orderNum, locations);
            },
            onCancel() {
              return;
            },
          });
        }
        else{
          props.addItem(invoiceType, orderNum, locations);
        }
      }
      }
      showAddIcon={ true }
      isReadyOnly={ false }
      isLocked={ isLocked }
      reqData={ filterdReqData }
      hasNew={ hasNew }
      bordered={ bordered }
      orderData={ orderData }
      glCodesList={ glCodesList }
      rowKey="key"
      clickedSave={clickedSave}
      clickedCancel={ clickedCancel }
      setClickedCancel={ setClickedCancel }
      handleEditItem={ (index, invoiceType, orderNum, record, locations) => {
        const { accessorial_code } = record;
          setCurrentAcc(accessorial_code);
          setCurrentOrderNo(orderNum);
          setActiveRow(index);
          setIsEditing(true);
          setUnEditedRecordData(record);
          props.handleEditItem(index, invoiceType, orderNum, record, locations);
        // }
      } }
    />
  );
}

export default AccessorialCharges;
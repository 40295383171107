import { ApiUrl, buildQueryString, Call, ParseGeneralResponse,  PageParams,
} from "../../api/ApiUtils";
import AppConfig from "../../config/AppConfig";

export const TemplatesApi = {
  fetch: (payload) => {
    payload.operation_code = 'ROI'
    const url = ApiUrl(`v2/order_templates/?${buildQueryString(payload)}`);
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        templates: "order_templates",
        pagination: "meta.pagination",
        recurring_routes_count : "recurring_routes_count"
      },
    });
  },
  fetchOne: (id) => {
    const url = ApiUrl(`v2/order_templates/${id}?operation_code=ROS`);
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        template: "order_template",
      },
    });
  },
  create: (payload) => {
    payload.operation_code ="ROCR"
    const url = ApiUrl("v2/order_templates");
    return Call("post", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        template: "order_template",
      },
    });
  },
  update: (id, payload) => {
    payload.operation_code ="ROU"
    const url = ApiUrl(`v2/order_templates/${id}`);
    return Call("put", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        template: "order_template",
      },
    });
  },
  delete: (id) => {
    const url = ApiUrl("v2/order_templates/" + id);
    return Call("delete", url, {operation_code: 'ROD'}, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  saveTemplateSchedule: (data) => {
    data.operation_code=  "ROSTS"
    const url = ApiUrl("v2/order_templates/add_template_schedule/");
    return Call("POST", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  getTemplateSchedule: (id) => {
    const url = ApiUrl(
      "v2/order_templates/show_template_schedule?template_id=" + id+"&operation_code=ROGTS"
    );
    return Call("GET", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "schedule_templates" },
    });
  },
  getTemplateLog: (data) => {
    const {
      activeRecordId,
      page = 1,
      perPage = AppConfig.perPage,
    } = data;
    const url = ApiUrl(`/v2/template_activity_logs?template_id=${activeRecordId}&${PageParams(
      page,
      perPage,
    )})&operation_code=ROGTL`)
    return Call("GET", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        data: "order_templates",
        pagination: "meta.pagination",
      },
    });
  }

}

export function saveSettingsTemplateOrder(data) {
  const url = ApiUrl("v2/order_templates/create_template_to_order");
  const { template_ids, date } = data;
 
  return Call('post', url, { template_ids, date }, ParseGeneralResponse, { responseDataKeys: { 'orders': '' } });
}


export const RecurringRouteApi = {
  fetch: (payload) => {
    const url = ApiUrl(`v2/recurring_routes?${buildQueryString(payload)}`);
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        recurringRoutes: "recurring_routes",
        pagination: "meta.pagination",
        recurring_orders_count : "recurring_orders_count",
        total_count : "total_count"
      },
    });
  },
  fetchOne: (id) => {
    const url = ApiUrl(`v2/recurring_routes/${id}`);
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        recurringRoute: "recurring_route",
      },
    });
  },
  create: (payload) => {
    const url = ApiUrl("v2/recurring_routes");
    return Call("post", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        recurringRoute: "recurring_route",
      },
    });
  },
  update: (id, payload) => {
    const url = ApiUrl(`v2/recurring_routes/${id}`);
    return Call("put", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        recurringRoute: "recurring_route",
      },
    });
  },
  delete: (id) => {
    const url = ApiUrl("v2/recurring_routes/" + id);
    return Call("delete", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  fetchPendingRoutes: (data) => {
    const url = ApiUrl(`v2/recurring_routes/pending_routes?${buildQueryString(data)}`);
    return Call("get", url, null, ParseGeneralResponse, {
      responseDataKeys: {
        pendingRoutes: "pending_routes",
      },
    });
  },
  instantRouteCreation: (data) => {
    const url = ApiUrl(`v2/recurring_routes/instant_recurring_route_creation`);
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
      },
    });
  },
  validate: (data) => {
    const url = ApiUrl(`v2/recurring_routes/validate_recurring_route`);
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
      },
    });
  },
};

export const DummyDriverApi = {
  get : (data) => {
    const url = ApiUrl(`v2/recurring_routes/get_dummy_driver?${buildQueryString(data)}`);
    return Call("get", url, null, ParseGeneralResponse, {
      responseDataKeys: {
        dummyDriver: "driver_id",
      },
    });
  }
}

import React,{useState} from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/dist/style.css";
import PropTypes from "prop-types";
import { isEmpty } from "../../common/Common";

const MobileInput = (props) => {
  const [prevCountryCode, setPrevCountryCode] = useState("us");

  const handleInputChange = (value, data) => {
    // Remove the '+' sign from the input value
    const sanitizedValue = value.replace(/\+/g, "");
    props.handleInputChange(sanitizedValue, data);
  };

  const handleChange = (value, data) => {
    // Check if the selected country code is different from the previous one
    if (data.countryCode !== prevCountryCode) {
      // Clear the prefix country code value when changing to a different country
      handleInputChange("", data);
      setPrevCountryCode(data.countryCode);
    } else {
      props.handleInputChange(value, data);
    }
  };

  const handleKeyDown = (e) => {
    // Prevent the default behavior for the spacebar key (key code 32)
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  return (
  <ReactPhoneInput
    defaultCountry="us"
    onlyCountries={["us", "in"]}
    inputClass="ant-input"
    disableAreaCodes
    value={!isEmpty(props.value) ? props.value : ''}
    inputStyle={props.inputStyle ? props.inputStyle : { width: "100%", height: "32px" }}
    onChange={handleChange}
    onKeyDown={handleKeyDown}
    // onChange={(value, data) => {
    //   // const mobileNumber = value.replace(/[^0-9]+/g, '').slice(data.dialCode.length);
    //   props.handleInputChange(value, data);
    // }}
    autoFormat={true}
    disabled={props.disabled}
  />
)}

export default MobileInput;

MobileInput.propTypes = {
  value: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
};

MobileInput.defaultProps = {
  value: "",
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Col,
  FormItem,
  Input,
  Row,
  Icon,
  Collapse,
  Checkbox,
} from "../../common/UIComponents";
import {alertMessage, checkNegitive } from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import { checkServiceExistance } from "../../helpers/common";
import TreeSelectComponent from "../../containers/Filters/TreeSelectComponent";
import {
  getWarehouseAccessorials,
  saveWarehouseAccessorials,
} from "../../api/Accessorials";

const { Panel } = Collapse;
class OrderAccessorialSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessorials: [],
      errors: [],
      currentForm: {},
      accessorialIds: this.props.selectedAccessorials || [],
    };
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.getAccountWarehouseAccessorials();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.accountId, this.props.accountId)) {
      this.getAccountWarehouseAccessorials();
    }
  }

  handleAccessorialsChange(val) {
    this.setState({ accessorialIds: val });
  }

  getAccountWarehouseAccessorials = () => {
    if (this.props.accountId) {
      getWarehouseAccessorials(this.props.accountId).then((result) => {
        if (result.success) {
          this.setState({ accessorials: result.warehouseAccessorials || [] });
        } else {
          this.setState({
            accessorials: [],
            errors: result.errors,
            inProgress: false,
          });
        }
      });
    }
  };

  handleSave() {
    saveWarehouseAccessorials({
      customer_order_id: this.props.orderId,
      required_accessorials: this.state.accessorialIds,
    }).then((result) => {
      if (result.success) {
        this.setState({ accessorials: result.warehouseAccessorials || [] });
      } else {
        this.setState({
          accessorials: [],
          errors: result.errors,
          inProgress: false,
        });
      }
    });
  }

  render() {
    const { warehouseAccessorials } = this.state;
    const { actionType } = this.props;
    let requiredAccessorials = [];
    return (
      <div>
        <Collapse
          defaultActiveKey={"warehouseAccessorials"}
          isActive={true}
          style={{ height: "auto" }}
          expandIconPosition="right"
          className="marginBottom10 customCollapse"
        >
          <Panel
            className="textBold"
            header={
              <Fragment>
                {I18n.t("accessorials.required_warehouse_accessorials")}
              </Fragment>
            }
            key={`warehouseAccessorials`}
            size="small"
          >
            {actionType === "edit" ? (
              <Fragment>
                <Row gutter={16}>
                  <Col md={10} xs={24}>
                    <FormItem
                      label={I18n.t(
                        "accessorials.select_warehouse_accessorials"
                      )}
                    >
                      <TreeSelectComponent
                        selectedValues={this.state.accessorialIds}
                        onValueChange={(value) =>
                          this.handleAccessorialsChange(value)
                        }
                        records={this.state.accessorials}
                        titleField="accessorial_name"
                        valueField="accessorial_code"
                        showLabel={false}
                        size="default"
                        element="accessorialIds"
                      />
                    </FormItem>
                  </Col>
                  <Col md={2} xs={24}>
                    {FormButtons(
                        this.state.inProgress,
                        this.handleSave,
                        null,
                        false
                    )}
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <Fragment>
                <Row gutter={16}>
                  <Col xs={12}>
                    {this.state.accessorials.map((accessorial) => {
                      console.log(accessorial);
                      if (
                        this.state.accessorialIds.includes(
                          accessorial.accessorial_code
                        )
                      ) {
                        requiredAccessorials.push(accessorial.accessorial_name);
                      }
                    })}
                    {requiredAccessorials.join(",")}
                  </Col>
                </Row>
              </Fragment>
            )}
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default OrderAccessorialSelect;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, Skeleton, Avatar } from '../common/UIComponents';


const BaseListView = props => (
  <Fragment>
    <List
      size={props.size || 'default'}
      className="demo-loadmore-list"
      loading={props.initLoading || false}
      itemLayout={props.itemLayout || 'horizontal'}
      loadMore={props.loadMore || ''}
      dataSource={props.list || []}
      bordered={props.bordered || []}
      renderItem={item => (
        <List.Item
          actions={props.action || []}
        >
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={
                props.showAvatar && <Avatar src={item.src} />
              }
              title={props.showTitle ? item.title : null}
              description={props.showDescription ? item.description : null}
            />
            { false && <div>content</div>}
          </Skeleton>
        </List.Item>
      )}
    />
  </Fragment>
);


BaseListView.propTypes = {
  initLoading: PropTypes.bool,
  showAvatar: PropTypes.bool,
  showTitle: PropTypes.bool,
  showDescription: PropTypes.bool,
  bordered: PropTypes.bool,
  itemLayout: PropTypes.string,
  loadMore: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape).isRequired,
  action: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  size: PropTypes.string,
};

BaseListView.defaultProps = {
  initLoading: false,
  bordered: true,
  itemLayout: 'horizontal',
  loadMore: false,
  showAvatar: false,
  showTitle: true,
  showDescription: true,
  action: [],
  size: 'default',
};

export default BaseListView;

import moment from "moment";
import { isEmpty } from "../common/Common";
import RouteLabel from "../config/RouteLabel";
import {
  Call,
  ApiUrl,
  ParseGeneralResponse,
  setObjectToFormData,
  PageParams,
  buildQueryString,
} from "./ApiUtils";
import AppConfig from "../config/AppConfig";

export function fetchRoutes(
  status = "",
  dateFilter = {},
  screenFrom = "",
  selectedWH,
  requiredRouteId = "",
  route_type = ""
) {
  let url = ApiUrl(
    `v2/nav_routes?wh_id=${selectedWH}&status=${status}&caller_type=LONG&operation_code=NRI`
  );
  if (!isEmpty(dateFilter)) {
    if (dateFilter.fromDate) {
      url = `${url}&from_date=${moment(new Date(dateFilter.fromDate)).format(
        "YYYY-MM-DD 00:00:00 Z"
      )}`;
    }
    if (dateFilter.toDate) {
      url = `${url}&to_date=${moment(new Date(dateFilter.fromDate)).format(
        "YYYY-MM-DD 00:00:00 Z"
      )}`;
    }
  }
  if (!isEmpty(screenFrom)) {
    url = `${url}&screen_from=${screenFrom}`;
  }
  if (!isEmpty(requiredRouteId)) {
    url = `${url}&route_id=${requiredRouteId}`;
  }
  if (!isEmpty(route_type)) {
    url = `${url}&route_type=${route_type}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { nav_routes: "" },
  });
}

export function fetchTasks(data) {
  const url = ApiUrl(`v2/tasks?nav_route_id=${data}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { routes: "" },
  });
}

export function fetchRoute(id) {
  const url = ApiUrl(`v2/nav_routes/${id}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "nav_route" },
  });
}

export function fetchRouteStops(id) {
  const url = ApiUrl(`v2/nav_routes/${id}/stops_in_route`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { stops: "", primary_driver: "", customer_orders: "" },
  });
}

export function fetchUpdatedStops(route_id, stop_ids) {
  const url = ApiUrl(`v2/real_time_updates/stop_detail_in_route?stop_ids=${stop_ids}&nav_route_id=${route_id}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { stops: "", primary_driver: "", customer_orders: "" },
  });
}

export function fetchOptimizedStops(
  navRouteId,
  isPinned = false,
  stick_start_time = false
) {
  const action_type = RouteLabel.actionLabels.ROUTE_OPTIMIZE;
  const url = ApiUrl(
    `v2/nav_routes/optimize_route?nav_route_id=${navRouteId}&m_sequence_lock=${isPinned}&stick_start_time=${stick_start_time}&action_type=${action_type}`
  );
  return Call("POST", url, {}, ParseGeneralResponse, {
    responseDataKeys: { optimize_route: "" },
  });
}

export function fetchPreplanOptimizedStops(
  navRouteId,
  isPinned,
  stick_start_time = false
) {
  const action_type = RouteLabel.actionLabels.ROUTE_OPTIMIZE;
  let url = ApiUrl(
    `v2/nav_routes/optimize_route?nav_route_id=${navRouteId}&stick_start_time=${stick_start_time}&action_type=${action_type}`
  );
  if(!isEmpty(isPinned)){
    url = `${url}&&m_sequence_lock=${isPinned}`
  }
  return Call("POST", url, {}, ParseGeneralResponse, {
    responseDataKeys: { optimize_route: "" },
  });
}

export function fetchOptimizedStopsOfMidRoute(navRouteId) {
  const url = ApiUrl(
    `v2/nav_routes/optimize_mid_route?nav_route_id=${navRouteId}`
  );
  return Call("POST", url, {}, ParseGeneralResponse, {
    responseDataKeys: { optimize_route: "" },
  });
}

export function allocateDriver(driver, route, type = "primary") {
  let driverKey = "driver_id";
  if (type === "primary") {
    driverKey = "primary_driver_id";
  } else if (type === "secondary") {
    driverKey = "secondary_driver_id";
  }
  const url = ApiUrl(
    `v2/allocate_driver?${driverKey}=${driver}&nav_route_id=${route}&operation_code=NRAD`
  );
  return Call("put", url, {}, ParseGeneralResponse, {
    responseDataKeys: { driver: "" },
  });
}

export function allocateAllDrivers(
  primaryDriver,
  secondaryDriver,
  route,
  action_type = ""
) {
  const url = ApiUrl(
    `v2/allocate_driver?primary_driver_id=${primaryDriver}&secondary_driver_id=${secondaryDriver}&nav_route_id=${route}&action_type=${action_type}&operation_code=NRAD`
  );
  return Call("put", url, {}, ParseGeneralResponse, {
    responseDataKeys: { driver: "" },
  });
}

export function allocateTruck(route, truck) {
  const data = setObjectToFormData({
    fleet_id: truck,
    nav_route_id: route,
    operation_code: "NRAF",
  });
  const url = ApiUrl("v2/allocate_fleet");
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { truck: "" } },
    false
  );
}

export function deleteTasks(id) {
  const url = ApiUrl(`v2/tasks/${id}`);
  return Call("delete", url, {}, ParseGeneralResponse);
}

export function deleteRoutes(id, action_type = null) {
  const url = ApiUrl(
    `v2/nav_routes/${id}?action_type=${action_type}&operation_code=NRD`
  );
  return Call("delete", url, {}, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function deleteOrderFromRoute(data) {
  data.operation_code = "NRROFR";
  const url = ApiUrl("v2/nav_routes/remove_order_from_route");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function deleteStopFromRoute(data) {
  const url = ApiUrl(
    "v2/nav_routes/remove_stop_from_route?operation_code=NRRSFR"
  );
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function deleteStopFromDispatch(data) {
  const isStopDeleted =
    data.deletion_reason &&
    data.deletion_reason.length &&
    data.deletion_reason.some((obj) => obj.type === "stop");
  data.operation_code = "DDO";
  data.action_type = isStopDeleted
    ? RouteLabel.actionLabels.STOP_DELETED
    : RouteLabel.actionLabels.STOP_ORDER_DELETED;
  data.action_from = "DISPATCH"
  const url = ApiUrl("v2/dispatch/delete_orders");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function saveTasks(data, isNew) {
  const method = isNew ? "post" : "put";
  const url = isNew ? ApiUrl("v2/tasks") : ApiUrl(`v2/tasks/${data.id}`);
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { routeTasks: "" },
  });
}

export function updateTaskOrder(data) {
  data.operation_code = "UTASKO";
  const url = ApiUrl("v2/update_task_order");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { order: "" },
  });
}

export function manageRoute(data, isNew) {
  data.operation_code = isNew ? "NRC" : "NRU";
  const method = isNew ? "post" : "put";
  const url = isNew
    ? ApiUrl("v2/nav_routes")
    : ApiUrl(`v2/nav_routes/${data.nav_route_id}`);
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { routeTasks: "" },
  });
}

export function updateRoute(data) {
  data.operation_code = "NRURO";
  const url = ApiUrl("v2/nav_routes/update_route_orders");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      nav_route: "nav_route",
      unprocessed_orders: "unprocessed_orders",
    },
  });
}

export function updateStop(data) {
  const url = ApiUrl("v2/nav_routes/update_stop_estimated_timings");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function moveStop(data) {
  data.operation_code = "NRTS";
  data.action_type = RouteLabel.actionLabels.MOVE_STOP;
  const url = ApiUrl("v2/nav_routes/transmit_stop");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function moveOrder(data) {
  const url = ApiUrl("v2/nav_routes/transmit_order?operation_code=NRTO");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function updateMidRoute(data) {
  data.operation_code = "NRUMRO";
  const url = ApiUrl("v2/nav_routes/update_mid_route_orders");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function confirmMidRoute(data) {
  data.operation_code = "NRCMR";
  const url = ApiUrl("v2/nav_routes/confirm_mid_route_update");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function deleteMidRoute(data) {
  data.operation_code = "NRCCMR";
  const url = ApiUrl("v2/nav_routes/cancel_mid_route");
  return Call("delete", url, { data }, ParseGeneralResponse);
}

export function updateStopsOrder(route_id, data) {
  data.operation_code = "NRUSS";
  const url = ApiUrl("v2/nav_routes/update_stop_sequence");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "nav_route" },
  });
}

export function changeLockRoute(id, lockRoute = false) {
  let url = "";
  if (lockRoute) {
    url = ApiUrl(`v2/nav_routes/${id}/lock_route?operation_code=NRLOCK`);
  } else {
    url = ApiUrl(`v2/nav_routes/${id}/unlock_route?operation_code=NRULOCK`);
  }
  return Call("put", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      nav_route: "nav_route",
      message: "message",
      error: "error",
    },
  });
}

export function changeLockOfMultipleRoute(ids, lockRoute = false) {
  const unlock_action_type = RouteLabel.actionLabels.ROUTE_UNLOCK;
  const lock_action_type = RouteLabel.actionLabels.ROUTE_LOCK;
  let url = "";
  if (lockRoute) {
    const id = ids.length > 0 ? ids[0] : "";
    url = ApiUrl(
      `v2/nav_routes/${id}/lock_route?operation_code=NRLOCK&action_type=${lock_action_type}`
    );
  } else {
    url = ApiUrl(
      `v2/nav_routes/unlock_all_routes?operation_code=NRULOCK&action_type=${unlock_action_type}&route_ids=${ids}`
    );
  }
  return Call("put", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      nav_route: "nav_route",
      message: "message",
      error: "error",
    },
  });
}

export function completeRoute(data) {
  data.action_type = RouteLabel.actionLabels.NAV_ROUTE_TERMINATED
  data.action_from = "DISPATCH"
  const url = ApiUrl("v2/nav_routes/terminate_nav_route?operation_code=NRTNR");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { driver: "" },
  });
}

export function completeStop(data) {
  data.action_type = RouteLabel.actionLabels.NAV_STOP_TERMINATED
  data.action_from = "DISPATCH"
  const url = ApiUrl("v2/nav_stops/terminate_nav_stop");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}

export function fetchExceptionMessages() {
  const url = ApiUrl("v2/nav_routes/nav_route_exceptions?operation_code=NRNRE");
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        exceptions: "nav_routes",
        pagination: "meta.pagination",
      },
    },
    true
  );
}

export function updateRouteInMiddle(data) {
  data.action_type = RouteLabel.actionLabels.ORDER_ADDED
  data.action_from = data?.action_from ? data.action_from : "DISPATCH";
  data.operation_code = "DAO";
  const url = ApiUrl("v2/dispatch/add_orders");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function sendStatusFile(data) {
  data.operation_code = "PPCNR";
  data.action_type = RouteLabel.actionLabels.ROUTE_MENIFEST;
  const method = "put";
  const url = ApiUrl("v2/pre_plan/confirm_nav_routes");
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "" },
  });
}

export function printManifest(data) {
  const { nav_route_ids, organization_id } = data;
  const method = "get";
  const url = ApiUrl(`v2/dispatch/print_multiple_route_manifest_pdf?operation_code=PPCNR&action_type=${RouteLabel.actionLabels.ROUTE_MENIFEST}&nav_route_ids=${nav_route_ids}&organization_id=${organization_id}`);
  return Call(method, url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function updateRouteTime(data) {
  data.operation_code = "PPCST";
  const url = ApiUrl("v2/pre_plan/change_route_start_time");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "nav_route" },
  });
}

export function fetchNavRoueDetails(id) {
  const url = ApiUrl(
    `v2/nav_routes/nav_route_obj_for_track?nav_route_id=${id}&operation_code=NROFT`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "details" },
  });
}

export function updateStopServiceDuration(id, service_duration) {
  const url = ApiUrl(`v2/nav_stops/${id}/update_service_duration`);
  return Call(
    "put",
    url,
    { service_duration: service_duration, operation_code: "NSUSD" },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        nav_stop: "nav_stop",
        nav_route: "details",
        message: "message",
      },
    }
  );
}

export function updateAllStopsServiceDuration(data) {
  data.action_type = RouteLabel.actionLabels.SERVICE_DURATION;
  const url = ApiUrl(`v2/nav_stops/update_service_duration_of_multiple_stops`);
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      nav_stop: "nav_stop",
      nav_route: "details",
      message: "message",
    },
  });
}

export const newDispatchRoute = (data) => {
  const url = ApiUrl("v2/dispatch/create_new_route");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "nav_route", response: '' },
  });
};

export const linehaulRoutes = {
  manage : (data) => {
    const url = ApiUrl("v2/line_haul_routes");
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { nav_route: "route" },
    });
  },
  delete: (id) => {
    const url = ApiUrl('v2/line_haul_routes/' + id)
    return Call('delete', url, { operation_code: 'OLRD' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  createRoute : (data) => {
    const url = ApiUrl("v2/line_haul_routes/create_linehaul_route");
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { nav_route: "nav_route" },
    });
  },

} 

export const getRouteLogApi = (activities) => {
  const { routeId, page = 1, perPage = AppConfig.perPage } = activities;
  const url = ApiUrl(
    `v2/activities/route_activities_list?route_id=${routeId}&${PageParams(
      page,
      perPage
    )}&operation_code=NRA`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      activities: "activities",
      pagination: "meta.pagination",
    },
  });
};

export function addAnyStopToRoute(data) {
  data.action_type = RouteLabel.actionLabels.ORDER_ADDED
  data.action_from = "DISPATCH"
  data.operation_code = "DAO";
  const url = ApiUrl("v2/dispatch/update_navroute_and_navstops");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function assignOrderToRoutes(data) {
  data.action_type = RouteLabel.actionLabels.ROUTE_CREATED,
  data.action_from = "ORDERS"
  data.operation_code = "NRC";
  let url = ApiUrl("v2/quick_dispatches/get_active_drivers");
  const queryString = buildQueryString(data);
  if(!isEmpty(queryString)){
    url = `${url}?${queryString}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { driver_details: "driver_details" },
  });
}

export function getDriversRoute(data) {
  let url = ApiUrl("v2/quick_dispatches/get_driver_routes");
  const queryString = buildQueryString(data);
  if(!isEmpty(queryString)){
    url = `${url}?${queryString}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { driver_routes: "driver_route_details" },
  });
}

export function updateQuickDispatchRouteInMiddle(data) {
  data.action_type = RouteLabel.actionLabels.ORDER_ADDED
  data.action_from = "ORDER"
  const url = ApiUrl("v2/quick_dispatches/add_orders");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { 
      nav_route_id: "nav_route_id", 
      message: "result",
      response: '' 
    },
  });
}

export default {
  fetchRoutes,
  saveTasks,
  fetchTasks,
  fetchRoute,
};

import { isEmpty } from 'lodash'
import userStore from '../stores/UserStore'
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from './ApiUtils'

//  store the functions related to account and org level instructions
export const ReleasesApi = {
  fetch: (isPagingEnabled, order_id, page = 1, perPage = '', token = '', payload = {}) => {
    const organization_id = userStore.getStateValue('selectedOrg')
    let params = ''
    if (!isEmpty(token)) {
      params = `&order_no=${token}`
    } else {
      const parameter = Object.keys(payload).map(
        (key) => `${key}=${!isEmpty(payload[key]) ? payload[key] : ''}`,
      )
      params = parameter ? `&${parameter.join('&')}` : ''
    }

    if (isPagingEnabled) {
      const url = ApiUrl(
        `v2/releases?${PageParams(
          page,
          perPage,
        )}${params}&operation_code=OREI&order_id=${order_id}&organization_id=${organization_id}`,
      )
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
          releases: 'releases',
          pagination: 'meta.pagination',
          stats: 'stats',
        },
      })
    } else {
      const url = ApiUrl(
        `v2/releases?page=1&per_page=all${params}&operation_code=OREI&order_id=${order_id}&organization_id=${organization_id}`,
      )
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
          releases: 'releases',
          pagination: 'meta.pagination',
          stats: 'stats',
        },
      })
    }
  },
  fetchone: (id) => {
    const url = ApiUrl(`v2/releases/${id}?operation_code=ORED`)
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  update: (id, payload) => {
    payload.operation_code = 'OREU'
    const url = ApiUrl('v2/releases/' + id)
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { quote: 'quote' },
    })
  },
  create: (payload) => {
    payload.operation_code = 'OREC'
    const url = ApiUrl('v2/releases')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { quote: 'quote' },
    })
  },
  delete: (id) => {
    const url = ApiUrl('v2/releases/' + id)
    return Call('delete', url, { operation_code: 'OREDEL' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  bulkDelete: (ids) => {
    const url = ApiUrl('v2/releases/remove_bulk_releases')
    return Call('delete', url, { release_id: ids, operation_code: 'OREDEL' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  changeStatus: (payload) => {
    payload.operation_code= 'ORECS';
    const url = ApiUrl(`v2/releases/update_status`)
    return Call('put', url, payload , ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  uploadReleasePictures: (data) => {
    data.operation_code = 'OREUP'
    const formData = setObjectToFormData(data)
    const url = ApiUrl('v2/releases/upload_release_pictures')
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { pictures: 'pictures' },
    })
  },
  deleteReleasePictures: (data) => {
    data.operation_code = 'OREDP'
    const url = ApiUrl('v2/releases/delete_release_pictures');
    return Call('delete', url, data, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    });
  },
  fetchReleaseLocation: (wh_id, release_date) => {
    const url = ApiUrl(`v2/recoveries/get_recovery_locations?operation_code=ORL&wh_id=${wh_id}&recovery_dead_line=${release_date}`)
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: { locations: 'recoveries' },
    })
  },
  createRoute: (payload) => {
    payload.operation_code = 'ORRC'
    const url = ApiUrl('v2/release_routes')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { route: '' },
    })
  },
  addStopsToRoute: (payload) => {
    payload.operation_code = 'ORRAS'
    const url = ApiUrl('v2/release_routes/add_release_stops')
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { route: '' },
    })
  },
  deleteReleaseRoute: (id) => {
     const url = ApiUrl('v2/release_routes/' + id)
    return Call('delete', url, { operation_code: 'ORRD' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  deleteReleaseStop: (data) => {
    data.operation_code = 'DDO';
    const url = ApiUrl("v2/release_routes/delete_rl_stop");
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { nav_route: "" },
    });
  }
  ,uploadStopPod(data) {
    const formData = setObjectToFormData(data);
    data.operation_code = 'ORRUSP'
    const url = ApiUrl("v2/release_routes/upload_rl_stop_pictures");
    return Call(
      "PUT",
      url,
      formData,
      ParseGeneralResponse,
      {
        responseDataKeys: { orders: "" },
      },
      false
    );
  }
  ,deleteStopAttachment(data) {
    data.operation_code = 'ORRDSP'
    const url = ApiUrl("v2/release_routes/delete_rl_stop_pictures");
    return Call("delete", url, data, ParseGeneralResponse);
  }
}

import I18n from '../common/I18n';
import { Call, ApiUrl, PageParams, ParseGeneralResponse } from './ApiUtils';
import { isEmpty } from 'lodash';


export const fetchLocations = ({ page, perPage, searchText, sortBy ='', sortByType ='' }) => {

    const url = ApiUrl(`v2/predefined_stops?${PageParams(page, perPage)}&sort_by=${sortBy}&sort_order=${I18n.t(`general.sort_${sortByType}`)}&search_text=${searchText}&operation_code=PDSI&skip_wh_check=true`);
    return Call('get', url, null, ParseGeneralResponse, {
        responseDataKeys: {
            predefined_stops: '',
            pagination: 'meta.pagination'
        }
    });
}

export const saveLocation = (location, isNew) => {
    const endpoint = `v2/predefined_stops/${isNew ? '': location.id}?operation_code=${isNew ? 'PDSC' : 'PDSU'}`;
    const url = ApiUrl(endpoint);
    const method = isNew ? 'post' : 'put';
    return Call(method, url, location, ParseGeneralResponse);
}

export const deleteLocation = id => {
    const url = ApiUrl(`v2/predefined_stops/${id}`);
    return Call('delete', url, { operation_code : 'PDSD'}, ParseGeneralResponse);
}

export const fetchLocationsByName = ({ name, requestFrom }) => {
    let url = ApiUrl(`v2/predefined_stops/search?search_text=${name}`);
    if(!isEmpty(requestFrom)){
      url = `${url}&request_from=${requestFrom}`
    }
    return Call('get', url, null, ParseGeneralResponse,{
        responseDataKeys: {
            predefined_stops: ''
        }
    });
}
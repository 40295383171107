import React, { useContext, useState } from "react";
import { ScreenKeys } from "../../containers/constants";
import { UserContext } from "../../context/UserContext";
import AppConfig from "../../config/AppConfig";
import { Spin, Row, Col, Icon } from "antd";
import { Typography } from 'antd';
const { Text } = Typography;
import ConsolidatedMawbsList from "./COnsolidatedMabsList";
import Copyable from "../common/Copyable";
import PropTypes from "prop-types";
import { getFormattedWeight, getWeightUnits } from "../../helpers/common";
import { AccountUnitsContext } from "../../context/AccountUnitsContext";
import { Number } from "core-js/shim";

export const ConsolidatedMAWBs = (props) => {
  const userData = useContext(UserContext);
  const { currentOrg, orgSettings } = useContext(OrgContext);
  const { orgRules } = useContext(UserContext);
  const accountUnitContext = useContext(AccountUnitsContext)
  const { updateRecordsPerPage } = userData;
  const perPageKey = ScreenKeys.CONSOLIDATED_MAWBS;
  const recordsPerPageSaved = _.get(
    userData,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved);
  const details = props.customerDetails;

  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved;
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };

  const renderObtainedStatus = () => {
    const statusCounts = props.allStatus.reduce((acc, status) => {
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    return (
      <Row type="flex" justify="space-between">
        {Object.entries(statusCounts).map(([status, count], index) => (
          <React.Fragment key={status}>
            <h3>
              <Icon
                type={AppConfig.OrderstatusIcons[status]}
                className={AppConfig.OrderStatusClass[status]}
              />{" "}
              {status.replace(/_/g, ' ')} : {count}
            </h3>
            {index < Object.entries(statusCounts).length - 1 && <h3> &nbsp;&nbsp;||&nbsp;&nbsp; </h3>}
          </React.Fragment>
        ))}
      </Row>
    );
  };

  const totalWeight = props.consolidatedData.reduce((acc, data) => {
    return data.weight !== 'N/A' ? acc + data.weight : acc;
  }, 0);

  const totalWeightInKgs = props.consolidatedData.reduce((acc, data) => {
    return data.weight_in_kgs !== 'N/A' ? acc + data.weight_in_kgs : acc;
  }, 0);

  const totalPieces = props.consolidatedData.reduce((acc, data) => {
    return acc + data.pieces;
  }, 0);

    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={props.consolidateLoading}>
            <Row className="page-content">
              <Col xs={24}>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="marginTop10 marginBottom10"
                  gutter={16}
                >
                  <Col xs={12} className="marginBottom10">
                    <div>
                      <Text strong>MAWB: </Text><Text>{ details.mawb }</Text><Copyable text={details.mawb} /><br />
                      <Text strong>Account: </Text>
                      <Text underline onClick={ () => props.navigateToAccount(details.account_code) } className="anchor_cursor textBold marginBottom10">
                        { details.account_code }
                      </Text><br />
                      <Text strong>Total Weight: </Text><Text>{`${getFormattedWeight(totalWeight, totalWeightInKgs, Number(orgRules.org_billing_rules.round_off_decimals))}`}</Text><br />
                      <Text strong>Total Quantity: </Text><Text>{ totalPieces }</Text><br />
                    </div>
                  </Col>
                  <Col xs={12}>
                    {renderObtainedStatus()}
                  </Col>
                </Row>
                <ConsolidatedMawbsList
                  data={!_.isEmpty(props.consolidatedData) ? props.consolidatedData : []}
                  navigateToAccount={props.navigateToAccount}
                  scroll={{ y: 300, x: 'max-content' }}
                  />
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    );
};

ConsolidatedMAWBs.propTypes = {
  customerDetails: PropTypes.object,
  consolidatedData: PropTypes.array,
  allStatus: PropTypes.array,
  consolidateLoading: PropTypes.bool,
  navigateToAccount: PropTypes.func,
};
ConsolidatedMAWBs.defaultProps = {
  customerDetails: {},
  consolidatedData: [],
  allStatus: [],
  consolidateLoading: false,
  navigateToAccount: () => { },
};
export default ConsolidatedMAWBs;
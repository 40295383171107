import React from 'react';
import { Modal } from 'antd';

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
export const validateEmailList = (value) => {
  const validEmails = value.map(email => email.trim()).filter(isValidEmail);
  const invalidEmails = value.map(email => email.trim()).filter(email => !isValidEmail(email));

    if (invalidEmails.length) {
      Modal.error({
        title: 'Invalid Email Addresses Detected',
        content: (
            <ul>
              {invalidEmails.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
        ),
        okText: 'OK',
      });
    }

  return validEmails;
};
import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Tooltip, Form, Typography, Spin, Row } from 'antd';
import GLCodeSelect from '../common/SelectDropdowns/GLCodeSelect';
import I18n from '../../common/I18n';
import { isEmpty } from '../../common/Common';

const { Text } = Typography;

const DriverAdjustmentsTable = ({
  data = [],
  locations = [],
  glCodes = [],
  onSave,
  loading = false,
  form,
  order,
  onDelete
}) => {
  const [tableData, setTableData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [newRowAdded, setNewRowAdded] = useState(false); // Track if a new row has been added
  const [descriptionError, setDescriptionError] = useState("")
  const [invoiceAmountError, setInvoiceAmountError] = useState("")
  const [locationError, setLocationError] = useState("")

  useEffect(() => {
    // Initialize table data on component mount or when data prop changes
    setTableData([...data])
  }, [data]);

  useEffect(() => {
    if (tableData.length === 0 ) { //&& tableData.length === 0) {
      addNewRow();
    }
  }, [tableData]);

  // Function to check if a row is being edited
  const isEditing = (record) => record.key === editingKey;

  // Function to add a new row to the table
  const addNewRow = () => {
    // Prevent adding another new row if one is already being edited
    if (tableData.some(row => row.isNew)) {
      return;
    }
    const newRow = {
      key: `new_${Date.now()}`,
      description: '',
      location_id: locations?.length === 1 ? _.get(locations[0], 'location_id', '') : '',
      glCode: '',
      amount: '',
      isNew: true,
    };
    setTableData([...tableData, newRow]);
    setEditingKey(newRow.key);
    setNewRowAdded(true); // Mark that a new row has been added
    if (form) {
      form.resetFields();
    }
  };

  // Function to start editing a row
  const edit = (record) => {
    if (form) {
      form.setFieldsValue({
        description: '',
        orderType: '',
        glCode: '',
        amount: '',
        ...record,
      });
    }
    setEditingKey(record.key);
  };

  // Function to cancel editing a row
  const cancel = () => {
    if (editingKey.includes('new')) {
      setTableData(tableData.filter((item) => item.key !== editingKey));
      setNewRowAdded(false); // Reset the flag if a new row is canceled
    }
    setEditingKey('');
    // Ensure that a new row is added if the current row is canceled
    if (tableData.length === 0) {
      addNewRow();
    }
  };

  // Function to save a row's data
  // const save = (record, order) => {
   
  //   const key = record.key
  //   form.validateFields((err, row) => {
  //     if (err) {
  //       return;
  //     }
  //     const newData = [...tableData];
  //     const index = newData.findIndex((item) => key === item.key);

  //     if (index > -1) {
  //       const item = newData[index];
  //       newData.splice(index, 1, {
  //         ...item,
  //         ...row,
  //         isNew: false,
  //       });
  //       setTableData(newData);
  //       setEditingKey('');
  //       setNewRowAdded(false); // Reset the flag after saving
  //       if (onSave) {
  //         onSave(record, order);
  //       }
  //     } else {
  //       newData.push(row);
  //       setTableData(newData);
  //       setEditingKey('');
  //       setNewRowAdded(true); // Set the flag to show + icon
  //       if (onSave) {
  //         onSave(record, order);
  //       }
  //     }
  //   });
  // };


  // const save = (record, order) => {
  //   form.validateFields((err, row) => {
  //     if (err) return;
  
  //     const updatedRecord = {
  //       ...record,
  //       gl_code: record.glCode, // Map to expected payload key
  //       ...row,
  //     };
  
  //     const newData = tableData.map(item => 
  //       item.key === updatedRecord.key ? updatedRecord : item
  //     );
  
  //     setTableData(newData);
  //     setEditingKey('');
  
  //     if (onSave) {
  //       onSave(updatedRecord, order);  // Pass the updated record
  //     }
  //   });
  // };

  const save = (record, order) => {
    if (isEmpty(record.description) || isEmpty(record.amount) || isEmpty(record.location_id)){
       if(isEmpty(record.description)){
        setDescriptionError("Required Field")
       }
       if(isEmpty(record.amount)){
        setInvoiceAmountError("Required Field")
       }
       if(isEmpty(record.isEmpty(record.location_id))){
        setLocationError("Required Field")
       }
    }else{
      const updatedRecord = {
        ...record,
        gl_code: record.glCode, 
      };

      const newData = tableData.map((item) =>
        item.key === updatedRecord.key ? updatedRecord : item
      );

      setTableData(newData);  
      setEditingKey("");      

      if (onSave) {
        setInvoiceAmountError("");
        setDescriptionError("")
        onSave(updatedRecord, order);  
      }
      }
  };

  

  // Function to delete a row
  const deleteRow = (record) => {
    if(record.isNew){
      setTableData(tableData.filter((item) => item.key !== key));
      if (tableData.length === 1) {
        addNewRow();
      }
    } else {
      onDelete(record.key)
    }
    setEditingKey('');
    // Add a new row if only one row remains after deletion
    
  };
  const updateTableData = (key, newData) => {
    const newTableData = tableData.map(item => 
      item.key === key ? { ...item, ...newData } : item
    );
    setTableData(newTableData);
  };
  
  const handleInputChange = (value, element, key) => {
    updateTableData(key, { [element]: value });
  };
  
  const handleAmountChange = (event, key) => {
    const { value } = event.target;
    updateTableData(key, { amount: value });
  };
  
  

  // Define the table columns
  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      width: '25%',
      editable: true,
      render: (text, record) => {
        const editable = isEditing(record);
    
        return editable ? (
          <Form.Item
            name="description"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Description is required.' }]}
          >
            <Input
              placeholder="Enter description"
              defaultValue={text}
              onChange={(e) => handleInputChange(e.target.value, 'description', record.key)}
            />
            {!isEmpty(descriptionError) && isEmpty(text) ? (
                <Row>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {invoiceAmountError}
                  </span>
                </Row>
              ) : (
                ""
              )}
          </Form.Item>
        ) : (
          <Text>{text}</Text>
        );
      },
    },
    
    {
      title: 'Order Type',
      dataIndex: 'location_id',
      width: '25%',
      editable: true,
      render: (text, record) => {
        const orderType = record.location_id ? _.get(_.find(locations, { location_id: record.location_id }), 'order_type', '') : ''
        return isEditing(record) ? (
          <Form.Item
            name="location_id"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Order Type is required.' }]}
          >
            <Select placeholder="Select order type" onChange={(e) => handleInputChange(e, 'location_id', record.key)} defaultValue={text}>
              {locations.map((type) => (
                <Select.Option key={type.location_id} value={type.location_id}>
                  {type.order_type}
                </Select.Option>
              ))}
            </Select>
            {!isEmpty(locationError) && isEmpty(text) ? (
                <Row>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {locationError}
                  </span>
                </Row>
              ) : (
                ""
              )}
          </Form.Item>
        ) : (
          <Text>{orderType}</Text>
        );
      },
    },
    {
      title: 'GL Code',
      dataIndex: 'glCode',
      width: '25%',
      editable: true,
      
      render: (text, record) => {
        
        return isEditing(record) ? (
          <Form.Item
          data={glCodes || []}
            name="glCode"
              value={record.gl_code}
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'GL Code is required.' }]}
          >
            <GLCodeSelect
              data={glCodes || []}
              value={record.glCode}
              onChange={(value) => {
                updateTableData(record.key, { glCode: value });  // Ensure correct key
              }}
              style={{ width: 120 }}
              showSearch
              placeholder={I18n.t("invoices.gl_code")}
              dropdownMatchSelectWidth={false}
              size="small"
            />
            {/* <GLCodeSelect
              // type={this.props.type}
                //invoiceType={this.props.invoice_type}
               // defaultValue={record.gl_code || this.props.defaultTransportationChargeGLCode}
                data={glCodes || []}
                value={record.gl_code}
                onChange={(e) => {
                  this.props.handleOnChange(
                    index,
                    "gl_code",
                    e,
                    this.props.currentSection.order_number
                  );
                  this.setState({ invoiceCode: e })
                }}
                selectKey={"gl_code"}
                selectValue={"gl_code"}
                selectedValue={"gl_code"}
                selectedCode={"description"}
                style={{ width: 120 }}
                showSearch={true}
                placeholder={I18n.t("invoices.gl_code")}
                dropdownMatchSelectWidth={false}
                size="small"
              /> */}
          </Form.Item>
        ) : (
          <Text>{text}</Text>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '15%',
      editable: true,
      render: (text, record) => {
        const editable = isEditing(record);
    
        return editable ? (
          <Form.Item
            name="amount"
            style={{ margin: 0 }}
            rules={[
              { required: true, message: 'Amount is required.' },
              {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: 'Enter a valid amount.',
              },
            ]}
          >
            <Input
              type="number"
              prefix="$"
              placeholder="Enter amount"
              defaultValue={text}
              onChange={(e) => handleAmountChange(e, record.key)}
            />
             {!isEmpty(invoiceAmountError) && isEmpty(text) ? (
                <Row>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {invoiceAmountError}
                  </span>
                </Row>
              ) : (
                ""
              )}
          </Form.Item>
        ) : (
          <Text>${text}</Text>
        );
      },
    },
    
    {
      title: 'Actions',
      width: '15%',
      render: (record) => {
        const editable = isEditing(record);
        const isLastRow = record.key === tableData[tableData.length - 1]?.key;

        return (
          <span>
            { editable ? (
              <>
                <Tooltip title="Save">
                  <Button
                    type="link"
                    onClick={ () => save(record, order) }
                    icon="save"
                  />
                </Tooltip>
                { tableData.length > 1 && (
                  <Tooltip title="Cancel">
                    <Button
                      type="link"
                      onClick={ cancel }
                      icon="close"
                      style={ { color: '#f5222d' } } // Red color for cancel
                    />
                  </Tooltip>
                ) }
              </>
            ) : (
              <>
                <Tooltip title="Edit">
                  <Button
                    type="link"
                    onClick={ () => edit(record) }
                    icon="edit"
                    style={ { color: '#1890ff' } } // Blue color for edit
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    type="link"
                    onClick={ () => deleteRow(record) }
                    icon="delete"
                    style={ { color: '#f5222d' } } // Red color for delete
                  />
                </Tooltip>
                { isLastRow && !tableData.some(row => row.isNew) && (
                  <Tooltip title="Add">
                    <Button
                      type="link"
                      onClick={ addNewRow }
                      icon="plus"
                    />
                  </Tooltip>
                ) }
              </>
            ) }
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => col);

  return (
    <Spin spinning={loading}>
      <Form form={form} component={false}>
        <Table
          dataSource={tableData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          bordered
        />
      </Form>
    </Spin>
  );
};

// Wrap the component with Form.create for form handling
const WrappedDriverAdjustmentsTable = Form.create()(DriverAdjustmentsTable);

export default WrappedDriverAdjustmentsTable;
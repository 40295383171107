import { Button, Input } from "antd";
import _ from  "lodash";
import React, { PureComponent } from "react";
import TagsInput from "react-tagsinput";
import { customPasteSplit } from "../../common/Common";
import EmailsInput from "../../containers/Modals/EmailsInput";
import BaseModal from "../BaseModal";
import I18n from "../../common/I18n";

const { TextArea } = Input;
class PODEmailsModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      podEmailsList: [],
      description: props.description || 'Please find the attached PODs.'
    };
  }
  addEmailToPOD = (podEmailsList) => {
    this.setState({ podEmailsList });
  };
  render() {
    const { closeModal, onSend, muteButton, title } = this.props;
    return (
      <BaseModal
        title={title || "POD Emails"}
        onCancel={closeModal}
        width="50%"
        style={{ top: 40 }}
        maskClosable={false}
      >
        <div className="tagsInput">
          {/* <TagsInput
            value={this.state.podEmailsList}
            onChange={this.addEmailToPOD}
            validationRegex={
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            }
            onlyUnique
            addOnPaste
            pasteSplit={customPasteSplit}
            inputProps={{
              placeholder: "Add Email",
            }}
            addOnBlur={ true }
          /> */}
          <EmailsInput emails={this.state.podEmailsList} onChange={this.addEmailToPOD} />
        </div>
        <div className="marginTop10">
          <TextArea
            rows={4}
            placeholder={I18n.t("general.description")}
            className="sop-form-textArea"
            value={this.state.description}
            onChange={(e) => this.setState({description: e.target.value})}
          />
        </div>
        <div
        //   style={{
        //     display: "flex",
        //     alignItems: "center",
        //   }}
		className="alignCenter"
        >
          <Button
            type="primary"
            style={{
              marginTop: "10px",
            }}
            disabled={this.state.podEmailsList.length === 0 || _.isEmpty(this.state.description)}
            loading={muteButton}
            onClick={() => onSend(this.state.podEmailsList, this.state.description)}
          >
            Send
          </Button>
        </div>
      </BaseModal>
    );
  }
}
export default PODEmailsModal;

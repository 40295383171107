import React from "react";
import moment from "moment";
import _ from "lodash";
import { Icon, Row, Col } from "../../common/UIComponents";
import { Link } from "react-router-dom";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import { momentTime } from "../../helpers/date_functions";
import { formatByTimeConfig } from "../../helpers/common";
import { isEmpty } from "../../common/Common";

class Activities extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("general.table_s_no"),
        key: "index",
        render: (text, record, index) => index + 1,
        width: 55,
      },
      {
        key: "description",
        title: I18n.t("general.description"),
        dataIndex: "description",
        render: (data, record) => (
          <div>
            <Row gutter={16}>
              <Col xs={2}>
                {record.activity_code === "EXCEPTION" ||
                record.activity_code === "BILLED" ? (
                  <span>
                    <Icon
                      type={
                        record.activity_code === "EXCEPTION"
                          ? "warning"
                          : "file-text"
                      }
                      className={
                        record.activity_code === "EXCEPTION"
                          ? "textRed"
                          : "textPrimary"
                      }
                    />
                  </span>
                ) : (
                  <div className="paddingTop3">
                    <div
                      style={{
                        borderColor: "#009bff",
                        // backgroundColor: index % 2 === 0 ? '#009bff' : '#FFF',
                      }}
                      className="connectedDot"
                    />
                  </div>
                )}
              </Col>
              <Col xs={22}>
                {data}
                {/* {!isEmpty(record.activity_comments)
                  ? ` (${record.activity_comments})`
                  : ""} */}
              </Col>
            </Row>
          </div>
        ),
      },
      {
        title: I18n.t("invoices.comments"),
        key: "log",
        render: (data, record) => (
          <div>
            {!isEmpty(record.log)
              ? ` ${record.log}`
              : ""}
          </div>
        ),
      },
      {
        key: "created_by",
        title: I18n.t("general.activity_by"),
        dataIndex: "created_by_name",
        className: "textCaptilize",
      },
      {
        key: "activity_on",
        title: I18n.t("general.activity_on"),
        dataIndex: "created_on",
        render: (data) =>
          data
            ? `${formatByTimeConfig(momentTime(
              "",
              data,
              this.props.timeZone
            ), this.props.isMilitaryTime, "MMM Do, YYYY HH:mm", 'MMM Do, YYYY hh:mm A')} ${this.props.timeZoneName ? ` ${this.props.timeZoneName}` : ""
            }`
            : "",
        sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      },
    ];
  }
}

export default Activities;

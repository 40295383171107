import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../common/UIComponents';

const BaseModal = props => (
  <Modal
    title={props.title}
    visible={props.visible}
    destroyOnClose={props.destroyOnClose}
    footer={props.footer ? props.footer :null}
    closable={props.closable}
    maskClosable={props.maskClosable}
    onCancel={props.onCancel}
    width={props.width}
    style={props.style}
    bodyStyle={props.bodyStyle}
    keyboard={props.keyboard === false ? false : true}
    className={props.className || 'base-modal'}
  >
    {props.children}
  </Modal>
);

BaseModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  destroyOnClose: PropTypes.bool,
  width: PropTypes.string,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool,
  style: PropTypes.shape(),
  bodyStyle: PropTypes.shape(),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  visible: PropTypes.bool.isRequired,
};

BaseModal.defaultProps = {
  destroyOnClose: true,
  width: '520px',
  closable: true,
  maskClosable: true,
  style: {},
  bodyStyle: {},
  title: '',
  visible: true,
};

export default BaseModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Statistic, Icon } from '../../common/UIComponents';
import BranchesIcon from '../common/BranchesIcon';

const OrdersStats = ({
  filter, handleOnFilterChange, data,
}) => {
  const size = 2;
  return (
    <Row className='orderStats'>
      <Col span={2} />
      <Col span={size} onClick={() => handleOnFilterChange('filter', '')} className="routeLegends">
        <Statistic
          title="All"
          className={filter === '' && 'active'}
          value={data.total_orders_count}
          prefix={<Icon type="ordered-list" className="textAll" />}
        />
      </Col>

      <Col
        span={size}
        onClick={() => handleOnFilterChange('filter', 'NEW')}
        className="routeLegends"
      >
        <Statistic
          title="New"
          className={filter === 'NEW' && 'active'}
          value={data.new_orders}
          prefix={<Icon type="diff" className="textCompleted" />}
        />
      </Col>
      <Col
        span={size}
        onClick={() => handleOnFilterChange('filter', 'RECEIVED')}
        className="routeLegends"
      >
        <Statistic
          title="Received"
          className={`${filter === 'RECEIVED' && 'active'} cursorPointer`}
          value={data.received_orders}
          prefix={<Icon type="plus-square" className="textReceived" />}
        />
      </Col>
      <Col span={size} onClick={() => handleOnFilterChange('filter', 'ON_HOLD')} className="routeLegends">
        <Statistic
          title="On Hold"
          className={filter === 'ON_HOLD' && 'active'}
          value={data.on_hold}
          prefix={<Icon type="pause-circle" className="textOnhold" />}
        />
      </Col>
      <Col span={size} onClick={() => handleOnFilterChange('filter', 'VERIFIED')} className="routeLegends">
        <Statistic
          title="Verified"
          className={filter === 'VERIFIED' && 'active'}
          value={data.verified_orders}
          prefix={<Icon type="check-circle" className="textVerified" />}
        />
      </Col>
      {/* <Col span={2} onClick={() => handleOnFilterChange('filter', 'PREPLAN')} className="routeLegends">
      <Statistic
        title="Preplan"
        className={filter === 'PREPLAN' && 'active'}
        value={data.preplan_orders || 0}
        prefix={<Icon type="interaction" className="textPending" />}
      />
    </Col>
  */}
      <Col span={size} onClick={() => handleOnFilterChange('filter', 'ASSIGNED')} className="routeLegends">
        <Statistic
          title="Assigned"
          className={filter === 'ASSIGNED' && 'active'}
          value={data.assigned_orders}
          prefix={<BranchesIcon/>
        }
        />
      </Col>
      {/*
   <Col span={2} onClick={() => handleOnFilterChange('filter', 'PREPARE')} className="routeLegends">
      <Statistic
        title="Prepare"
        className={filter === 'PREPARE' && 'active'}
        value={data.prepare_orders || 0}
        prefix={<Icon type="bank" className="textPending" />}
      />
    </Col>
  */}

      <Col span={size} onClick={() => handleOnFilterChange('filter', 'DISPATCHED')} className="routeLegends">
        <Statistic
          title="Dispatched"
          className={filter === 'DISPATCHED' && 'active'}
          value={data.dispatched_orders}
          prefix={<Icon type="schedule" className="textAssigned" />}
        />
      </Col>
      <Col span={size} onClick={() => handleOnFilterChange('filter', 'COMPLETED')} className="routeLegends">
        <Statistic
          title="Completed"
          className={filter === 'COMPLETED' && 'active'}
          value={data.completed_orders}
          prefix={<Icon type="like" className="textCompleted" />}
        />
      </Col>
      <Col
        span={size}
        onClick={() => handleOnFilterChange('filter', 'CLOSED')}
        className="routeLegends"
      >
        <Statistic
          title="Closed"
          className={`${filter === 'CLOSED' && 'active'} cursorPointer`}
          value={data.closed_orders}
          prefix={<Icon type="check-circle" className="textReceived" />}
        />
      </Col>
      <Col span={size} onClick={() => handleOnFilterChange('filter', 'EXCEPTION')} className="routeLegends">
        <Statistic
          title="Exception"
          className={filter === 'EXCEPTION' && 'active'}
          value={data.exception_orders}
          prefix={<Icon type="warning" className="textExceptional" />}
        />
      </Col>

    </Row>


  );
};

OrdersStats.propTypes = {
  filter: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  handleOnFilterChange: PropTypes.func.isRequired,
};

export default OrdersStats;



// import React from 'react';
// import PropTypes from 'prop-types';
// import { Menu, Badge, Icon } from '../../common/UIComponents';
// import BranchesIcon from '../common/BranchesIcon';

// const OrdersStats = ({
//   filter, handleOnFilterChange, data,
// }) => {
//   // Define order statuses with their respective icons and colors
//   const orderStatuses = [
//     { name: '', title: 'All', icon: 'ordered-list', color: '#1890ff', dataKey: 'total_orders_count' },
//     { name: 'NEW', title: 'New', icon: 'diff', color: '#52c41a', dataKey: 'new_orders' },
//     { name: 'RECEIVED', title: 'Received', icon: 'plus-square', color: '#13c2c2', dataKey: 'received_orders' },
//     { name: 'ON_HOLD', title: 'On Hold', icon: 'pause-circle', color: '#faad14', dataKey: 'on_hold' },
//     { name: 'VERIFIED', title: 'Verified', icon: 'check-circle', color: '#722ed1', dataKey: 'verified_orders' },
//     { name: 'ASSIGNED', title: 'Assigned', icon: 'branches', color: '#eb2f96', dataKey: 'assigned_orders' },
//     { name: 'DISPATCHED', title: 'Dispatched', icon: 'schedule', color: '#fa8c16', dataKey: 'dispatched_orders' },
//     { name: 'COMPLETED', title: 'Completed', icon: 'like', color: '#389e0d', dataKey: 'completed_orders' },
//     { name: 'CLOSED', title: 'Closed', icon: 'check-circle', color: '#08979c', dataKey: 'closed_orders' },
//     { name: 'EXCEPTION', title: 'Exception', icon: 'warning', color: '#f5222d', dataKey: 'exception_orders' },
//   ];

//   return (
//     <Menu
//       className="orderStats"
//       mode="horizontal"
//       selectedKeys={ [ filter ] }
//       // style={ { display: 'flex', justifyContent: 'center' } }
//     >
//       { orderStatuses.map((status) => (
//         <Menu.Item
//           key={ status.name }
//           onClick={ () => handleOnFilterChange('filter', status.name) }
//         >
//           { status.icon === 'branches' ? (
//             <BranchesIcon />
//           ) : (
//             <Icon type={ status.icon } />
//           ) }
//           { status.title }
//           <Badge
//             count={ data[ status.dataKey ] || 0 }
//             style={ { backgroundColor: status.color, marginLeft: 5 } }
//           />
//         </Menu.Item>
//       )) }
//     </Menu>
//   );
// };


// OrdersStats.propTypes = {
//   filter: PropTypes.string.isRequired,
//   handleOnFilterChange: PropTypes.func.isRequired,
//   data: PropTypes.shape().isRequired,
// };

// export default OrdersStats;
// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Menu, Badge, Icon, Dropdown } from '../../common/UIComponents';
// import BranchesIcon from '../common/BranchesIcon';

// const OrdersStats = ({ filter, handleOnFilterChange, data }) => {
//   const [ useDropdown, setUseDropdown ] = useState(false);

//   const allStatuses = [
//     { name: '', title: 'All', icon: 'ordered-list', color: '#1890ff', dataKey: 'total_orders_count' },
//     { name: 'NEW', title: 'New', icon: 'diff', color: '#52c41a', dataKey: 'new_orders' },
//     { name: 'RECEIVED', title: 'Received', icon: 'plus-square', color: '#13c2c2', dataKey: 'received_orders' },
//     { name: 'ON_HOLD', title: 'On Hold', icon: 'pause-circle', color: '#faad14', dataKey: 'on_hold' },
//     { name: 'VERIFIED', title: 'Verified', icon: 'check-circle', color: '#722ed1', dataKey: 'verified_orders' },
//     { name: 'ASSIGNED', title: 'Assigned', icon: 'branches', color: '#eb2f96', dataKey: 'assigned_orders' },
//     { name: 'DISPATCHED', title: 'Dispatched', icon: 'schedule', color: '#fa8c16', dataKey: 'dispatched_orders' },
//     { name: 'COMPLETED', title: 'Completed', icon: 'like', color: '#389e0d', dataKey: 'completed_orders' },
//     { name: 'CLOSED', title: 'Closed', icon: 'check-circle', color: '#08979c', dataKey: 'closed_orders' },
//     { name: 'EXCEPTION', title: 'Exception', icon: 'warning', color: '#f5222d', dataKey: 'exception_orders' },
//   ];

//   useEffect(() => {
//     const checkScreenSize = () => {
//       setUseDropdown(window.innerWidth <= 1280); // Approximate width for 13-inch MacBook
//     };

//     checkScreenSize();
//     window.addEventListener('resize', checkScreenSize);
//     return () => window.removeEventListener('resize', checkScreenSize);
//   }, []);

//   const renderMenuItem = (status) => (
//     <Menu.Item
//       key={ status.name }
//       onClick={ () => handleOnFilterChange('filter', status.name) }
//     >
//       { status.icon === 'branches' ? <BranchesIcon /> : <Icon type={ status.icon } /> }
//       <span className="status-title">{ status.title }</span>
//       <Badge
//         count={ data[ status.dataKey ] || 0 }
//         style={ { backgroundColor: status.color, marginLeft: 5 } }
//       />
//     </Menu.Item>
//   );

//   if (useDropdown) {
//     const mainStatuses = allStatuses.slice(0,5);
//     const otherStatuses = allStatuses.slice(6);

//     const otherMenu = (
//       <Menu selectedKeys={ [ filter ] }>
//         { otherStatuses.map(renderMenuItem) }
//       </Menu>
//     );

//     return (
//       <Menu
//         className="orderStats"
//         mode="horizontal"
//         selectedKeys={ [ filter ] }
//         style={ { display: 'flex', justifyContent: 'center' } }
//       >
//         { mainStatuses.map(renderMenuItem) }
//         <Dropdown overlay={ otherMenu } trigger={ [ 'click' , 'hover' ] }>
//           <Menu.Item key="more">
//             <Icon type="ellipsis" />
//             More
//           </Menu.Item>
//         </Dropdown>
//       </Menu>
//     );
//   }

//   return (
//     <Menu
//       className="orderStats"
//       mode="horizontal"
//       selectedKeys={ [ filter ] }
//       style={ { display: 'flex', justifyContent: 'center' } }
//     >
//       { allStatuses.map(renderMenuItem) }
//     </Menu>
//   );
// };

// OrdersStats.propTypes = {
//   filter: PropTypes.string.isRequired,
//   handleOnFilterChange: PropTypes.func.isRequired,
//   data: PropTypes.shape().isRequired,
// };

// export default OrdersStats;

import { Button, Modal } from "antd";
import React, { useState } from "react";
import InvoiceAdjustmentForm from "./InvoiceAdjustmentForm";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";

const ManageInvoiceLine = (props) => {
  const {
    mode,
    data,
    location_id,
    order,
    text = "Adjustments",
    resetList
  } = props;

  const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);
  const [isModifiedLines, setIsModifiedLines] = useState(false);

  
  const closeForm = (closable = false) => {
    setShowAdjustmentModal(false)
    if(isModifiedLines || closable) {
      resetList();
    }
  }

  const enableForm = () => {
    setShowAdjustmentModal(true)
    setIsModifiedLines(false)
  }
  return (
    <>
      {mode !== "update" ? (
        <Button
          onClick={() => {
            enableForm()
          }}
          size="small"
          type="primary"
          icon="plus"
        >
          Add
        </Button>
      ) : (
        <>
          <EditIcon handleClick={() => enableForm()} />
        </>
      )}

      {showAdjustmentModal && (
        <Modal
          visible={showAdjustmentModal}
          onCancel={() => closeForm(false)}
          contentLabel="Invoice Adjustment"
          footer={null}
          maskClosable={false}
          closable={false}
        >
          <InvoiceAdjustmentForm
          type_of_order={order.type_of_order}
          location_type={order}
            dataToEdit={data}
            location_id={location_id}
            order_id={order.id}
            status={order.status}
            action_from={'ORDER'}
            setIsOpenModal={setShowAdjustmentModal}
            closeForm={closeForm}
            setIsModifiedLines={setIsModifiedLines}
          /> 
        </Modal>
      )}
    </>
  );
};

export default ManageInvoiceLine;

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popconfirm, Button } from "../../common/UIComponents";
import OptingAppointment from "./OptingAppointment";
import { checkServiceExistance } from "../../helpers/common";
import PendingOrdersActions from "../billing_screen/PendingOrdersActions";
import PrintLabels from "./PrintLabels";
import CloneOrders from "./CloneOrders";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import BulkLinehaulForm from "./BulkLinehaulForm";
import RejectOrdersActions from "../billing_screen/RejectOrdersAction";
import I18n from "../../common/I18n";
import SwitchDetailsModal from "../preplan_management/SwitchDetailsModal";

const OrdersBulkOperations = ({
  filter,
  routes,
  multipleOrderDeletion,
  handleCancel,
  resetOrders,
  handleMultipleOrdersStatusChange,
  loading,
  handleReceiveToConfirm,
  changeStatus,
  enableLoading,
  handleStatusResult,
  handleEditOrder, warehouseFilter, organizationSettings, handleBarCodePrint,renderColumnSaveButton
  , creditsExceededAccnts,getOrders
}) => {
  const size = 2;
  const [switchDetailsModalVisible, setSwitchDetailsModalVisible] = useState(false);

  const handleSwitchDetails = () => {
    setSwitchDetailsModalVisible(true);
  };

  const closeSwitchDetailsModal = async () => {
    try {
      setSwitchDetailsModalVisible(false);
    } catch (error) {
      console.error('Error switching details:', error);
    }
  };

  return (
    <Row className="bottom_fixed">
      <SwitchDetailsModal
        visible={switchDetailsModalVisible}
        onClose={closeSwitchDetailsModal}
        selectedOrders={routes.selectedOrderRows}
        refreshOrders={getOrders}
        resetOrders={resetOrders}
        clearOrders={handleCancel}
      />
      <Col xs={24} className="alignCenter">
        <Button
          type="default"
          className="clearButtonStyle"
          onClick={handleCancel}
          icon="close"
        >
          Clear
        </Button>
        &nbsp;&nbsp;
        {checkServiceExistance("COD") &&
          ["NEW", "RECEIVED", "VERIFIED","PENDING","APPROVED","REJECTED", "ON_HOLD"].includes(filter) && (
            <Popconfirm
              placement="rightTop"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={multipleOrderDeletion}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" className="buttonStyle" icon="delete">
                Delete
              </Button>
            </Popconfirm>
          )}
        {checkServiceExistance("SBA") &&
          ["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"].includes(filter) && (
            <OptingAppointment
              resetOrders={resetOrders}
              orders={routes.selectedOrderKeys || []}
              filter={filter}
              warehouseFilter={ warehouseFilter }
              organizationSettings={organizationSettings}
              selectedOrders={routes.selectedOrderRows || []}
              showSelectedOrder={true}
              showSchduleOption={true}
            />
          )}
        {checkServiceExistance("COSU") && filter === "NEW" && (
          <Button
            type="primary"
            className="assignDriver"
            onClick={() => handleMultipleOrdersStatusChange("RECEIVED")}
            loading={loading}
            disabled={!(routes.selectedOrderKeys.length > 0)}
            icon="plus-square"
          >
            Move to Received
          </Button>
        )}

        {
        checkServiceExistance("COUPO") && filter === "PENDING" && 
        <PendingOrdersActions
          enableLoading={enableLoading}
          handleStatusResult={handleStatusResult}
          orderIds={routes.selectedOrderKeys}
        />
        }
        {
        checkServiceExistance("COUPO") && filter === "REJECTED" && 
        <RejectOrdersActions
          enableLoading={enableLoading}
          handleStatusResult={handleStatusResult}
          orderIds={routes.selectedOrderKeys}
        />
        }

        {checkServiceExistance("CAMV") && filter === "RECEIVED" && (
          <Button
            type="primary"
            className="assignDriver"
            onClick={() => handleReceiveToConfirm()}
            loading={loading}
            disabled={!(routes.selectedOrderKeys.length > 0)}
            icon="check-circle"
          >
            Move to Verified
          </Button>
        )}
        {checkServiceExistance("COSU") && !['PENDING', 'REJECTED'].includes(filter) && (
          <Button
            className="assignDriver buttonMitesse"
            onClick={() => changeStatus()}
            disabled={!(routes.selectedOrderKeys.length > 0)}
            icon="setting"
          >
            Change Status
          </Button>
        )}
        {/* checkServiceExistance("COPL") && */}
        { 
        !['PENDING', 'REJECTED'].includes(filter) &&
        <PrintLabels
          className={"buttonCoolBlue marginLeft10"}
          buttonType="primary"
          size="default"
          label={I18n.t("order.download_labels")}
          isDisabled={routes.selectedOrderKeys.length === 0}
          handleSuccess={() => {}}
          orderIds={[...routes.selectedOrderKeys]}
          buttonIcon={"download"}
        />
        }

        {checkServiceExistance("SBA") &&
          ["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"].includes(filter) && (
            <CloneOrders
              resetOrders={resetOrders}
              orders={routes.selectedOrderKeys || []}
              filter={filter}
              handleEditOrder={ handleEditOrder }
              warehouseFilter={ warehouseFilter }
              organizationSettings={organizationSettings}
              selectedOrders={routes.selectedOrderRows || []}
              showSelectedOrder={true}
            />
          )}
        {checkServiceExistance("SBA") &&
          (
            <Button type="primary" icon="printer" onClick={() => handleBarCodePrint(true)} style={["ASSIGNED"].includes(filter) || filter === "PENDING" ? { marginLeft: "10px" } : {}} >
              Print Barcodes
            </Button>
          )}
        {checkServiceExistance("SBA") &&
          ["NEW", "RECEIVED","VERIFIED"].includes(filter) && (
            <BulkLinehaulForm
              resetOrders={resetOrders}
              orders={routes.selectedOrderRows || []}
              filter={filter}
              creditsExceededAccnts={ creditsExceededAccnts }
              handleEditOrder={ handleEditOrder }
              warehouseFilter={ warehouseFilter }
              organizationSettings={organizationSettings}
            />
          )}
        {checkServiceExistance("SBA") &&
          ["NEW", "RECEIVED", "VERIFIED"].includes(filter) && (

          <Button
            type="primary"
            icon="swap"
            className="buttonCherryRed"
            onClick={handleSwitchDetails}
            // loading={inProgress}
          >Switch Details
          </Button>
        )}
      </Col>
    </Row>
  );
};

const OrdersBulkOperationsComponent  = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, accountsExceeded } = useContext(OrgContext);
  return (
    <OrdersBulkOperations
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      creditsExceededAccnts={ accountsExceeded }
      {...props}
    />
  );
});
OrdersBulkOperations.propTypes = {
    filter: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    handleReceiveToConfirm: PropTypes.func.isRequired,
    multipleOrderDeletion: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    resetOrders: PropTypes.func.isRequired,
    handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    handleStatusResult: PropTypes.func.isRequired,
    enableLoading: PropTypes.func.isRequired,
    handleEditOrder: PropTypes.func.isRequired

};

OrdersBulkOperations.defaultProps = {
    handleStatusResult: () => {},
    enableLoading: () => {},
    handleEditOrder : () => {}
};

export default OrdersBulkOperationsComponent;
import React, { Component, Fragment, useContext } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import DateTimeSelector from "../../common/DateTimeSelector";
import {
  FormItem,
  Button,
  Row,
  Col,
  Radio,
  Checkbox,
  Input,
  DatePicker,
  Divider,
  SideBySideFormItem,
  Spin,
  Skeleton,
  Upload,
  Icon,
  message,
  Empty,
  Card,
  Badge,
  Popover,
} from "../../common/UIComponents";
import { isEmpty, alertMessage, randomNumber } from "../../common/Common";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import BaseModal from "../BaseModal";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { UserContext } from "../../context/UserContext";
import { fetchOrderStatuses, sendOrderStatuses } from "../../api/OrdersApi";
import Appointments from "../common/Appointments";
import { getDisabledHoursFromStartTime } from "../../helpers/orders";
import { getHMSFromTimeString } from "../../helpers/date_functions";
import { validateslots } from "../orders/helpers";
import DisplayTime from "../common/DisplayTime";

const defaultPreference = {
  // item_preference: moment(moment().startOf("day")),
  appt_date: null,
  slots: [],
  confirmed: false,
  start_time: null,
  end_time: null,
  has_expedite: false,
};

const { Dragger } = Upload;
class SendStatusReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      status_type: "SEND",
      orderStatuses: [],
      fetchedStatuses: [],
      order: props.order || {},
      order_id: props.order_id || "",
      isLoading: false,
      errors: [],
      key: randomNumber(),
    };
  }

  componentDidMount() {
    this.getStatuses();
  }

  getStatuses = () => {
    this.setState({
      isLoading: true,
    });
    fetchOrderStatuses(this.state.order_id).then((result) => {
      if (result.success) {
        const fetchedStatuses = result.order_statuses || {};
        const initialStatuses = this.setInitialOrderStatuses(fetchedStatuses);
        this.setState({
          orderStatuses: initialStatuses,
          fetchedStatuses,
          isLoading: false,
        });
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ orderStatuses: [], isLoading: false });
      }
    });
  };

  setInitialOrderStatuses = (fetchedStatuses) => {
    const { delivery_status_codes = [], pickup_status_codes = [] } =
      fetchedStatuses;
    const pickupStatuses = pickup_status_codes?.length
      ? this.setInitialStatuses(pickup_status_codes)
      : [];
    const deliveryStatuses = delivery_status_codes?.length
      ? this.setInitialStatuses(delivery_status_codes)
      : [];
    let initialStatuses = [];

    if (pickupStatuses?.length) {
      initialStatuses.push({
        type_of_loc: "PICKUP",
        statuses: pickupStatuses,
      });
    }
    if (deliveryStatuses?.length) {
      initialStatuses.push({
        type_of_loc: "DELIVERY",
        statuses: deliveryStatuses,
      });
    }

    return initialStatuses;
  };

  setInitialStatuses = (fetchedStatuses) => {
    const wh_location = _.get(this.props.order, "wh_location", {});
    const initialStatusValues = fetchedStatuses.map((status) => {
      const hasNonEmptyValue = status?.require_fields?.some(
        (field) => !isEmpty(field.value)
      );

      return {
        status_code: status.status_code,
        is_enabled: hasNonEmptyValue,
        require_fields: status?.require_fields?.length
          ? status.require_fields.map((field) => {
              const initialValue = !isEmpty(field.value)
                ? field.type === "date" || field.type === "datetime"
                  ? moment.tz(field.value, wh_location.timeZoneId)
                  : field.type === "appointment"
                  ? {
                      ...field.value,
                      start_time: moment.tz(
                        field.value.start_time,
                        "HH:mm",
                        wh_location.timeZoneId
                      ),
                      end_time: moment.tz(
                        field.value.end_time,
                        "HH:mm",
                        wh_location.timeZoneId
                      ),
                      appt_date: moment.utc(field.value.appt_date),
                    }
                  : field.value
                : field.type === "appointment"
                ? _.cloneDeep(defaultPreference)
                : "";
              return {
                ...field,
                value: initialValue,
                isRequired: hasNonEmptyValue,
                initialValue,
              };
            })
          : [],
      };
    });

    return initialStatusValues;
  };

  enableForm = () => {
    this.setState({
      showForm: true,
    });
  };

  disabelForm = () => {
    this.setState(
      {
        showForm: false,
        status_type: "RESEND",
      },
      () => {
        this.handleStateChange("status_type", "RESEND");
      }
    );
  };

  handleStateChange = (element, value) => {
    this.setState(
      {
        [element]: value,
      },
      () => {
        if (element === "status_type" && value === "RESEND") {
          const resetedInitialStatuses = this.setInitialOrderStatuses(
            this.state.fetchedStatuses
          );
          this.setState({
            orderStatuses: resetedInitialStatuses,
          });
        }
      }
    );
  };

  handleCheckboxChange = (
    type_of_loc,
    status_code,
    checked,
    statusFieldTypes
  ) => {
    this.setState((prevState) => ({
      orderStatuses: prevState.orderStatuses.map((location) =>
        location.type_of_loc === type_of_loc
          ? {
              ...location,
              statuses: location.statuses.map((status) =>
                status.status_code === status_code
                  ? {
                      ...status,
                      is_enabled: checked,

                      require_fields: status.require_fields.map((field) => {
                        return {
                          ...field,
                          value: checked ? field.value : field.initialValue, // Reset value to initial if unchecked
                          isRequired: checked,
                        };
                      }),
                    }
                  : status
              ),
            }
          : location
      ),
      key: statusFieldTypes.includes("appointment")
        ? `${type_of_loc}_${randomNumber()}`
        : prevState.key,
    }));
  };

  handleInputChange = (type_of_loc, status_code, field, value) => {
    this.setState((prevState) => ({
      orderStatuses: prevState.orderStatuses.map((locationStatus) => {
        return {
          ...locationStatus,
          statuses: locationStatus.statuses.map((status) => {
            if (
              locationStatus.type_of_loc === type_of_loc &&
              status.status_code === status_code
            ) {
              return {
                ...status,
                require_fields: status.require_fields.map((reqField) =>
                  reqField.field === field
                    ? { ...reqField, value: value }
                    : reqField
                ),
              };
            }
            return status;
          }),
        };
      }),
    }));
  };
  setAppointmentValue = (preference) => {
    const slots = preference.slots || [];
    return Object.assign(
      {},
      {
        // s_no: i,
        appt_date: moment(preference.appt_date).format("YYYY-MM-DD 00:00:00"),
        slots,
        confirmed: preference.confirmed,
        has_expedite: preference.has_expedite === true,
        start_time:
          slots.includes("CUSTOM") && preference.start_time
            ? moment(preference.start_time).format("HH:mm")
            : "",
        end_time:
          slots.includes("CUSTOM") && preference.end_time
            ? moment(preference.end_time).format("HH:mm")
            : "",
      }
    );
  };

  setDateTimeValue = (val, reqFormat = AppConfig.dateTimeFormat) => {
    return moment(val).format(reqFormat);
  };

  handleSendStatus = () => {
    const { orderStatuses } = this.state;
    const { order, currentOrg, organizationSettings } = this.props;
    const isMilitaryTime = organizationSettings.is_military_time === "true";

    this.setState({ isLoading: true });
    let errors = [];
    const allStatuses = orderStatuses?.length
      ? orderStatuses.map((location) => location.statuses).flat()
      : [];
    const enabledStatuses = allStatuses.filter((status) => status.is_enabled);

    const requiredStatuses = enabledStatuses.filter((status) => {
      return status.require_fields.some((field) => {
        if (field.field === "appointment") {
          const { errors: slotsErrors, appointments: slots } = validateslots(
            [field.value],
            isMilitaryTime
          );
          const hasError = slotsErrors.length > 0 || slots.length === 0;
          return hasError;
        } else {
          // For other fields, perform the default check
          return isEmpty(field.value);
        }
      });
    });
    if (requiredStatuses?.length > 0) {
      errors.push("Please fill all required fields.");
    }

    console.log("errors:::", errors);
    if (errors.length === 0) {
      let payload = {
        organization_id: currentOrg.id,
        order_id: order.id,
      };

      //Forming statuses objct and adding to payload
      orderStatuses.forEach((loc) => {
        const statusPayload = loc.statuses
          .filter((status) => status.is_enabled)
          .map((statusItem) => {
            const obj = { status_code: statusItem.status_code };
            statusItem.require_fields.forEach((field) => {
              if (field.type === "appointment") {
                obj[field.field] = this.setAppointmentValue(field.value);
              } else if (["datetime", "date"].includes(field.type)) {
                obj[field.field] = this.setDateTimeValue(
                  field.value,
                  `YYYY-MM-DD${field.type === "datetime" ? " HH:mm" : ""}`
                );
              } else {
                obj[field.field] = field.value;
              }
            });
            return obj;
          });

        payload[`${loc.type_of_loc.toLowerCase()}_status_codes`] =
          statusPayload;
      });

      sendOrderStatuses(payload).then((result) => {
        if (result.success) {
          this.setState({ isLoading: false });
          alertMessage(result.message,"success",10);
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ isLoading: false });
        }
      });

      this.setState({ errors: [], isLoading: false });
      console.log("finalPayload:::", payload);
    } else {
      this.setState({
        errors,
        isLoading: false,
      });
      alertMessage(errors[0], "error", 10);
    }
  };

  _renderLocationStatusNField = () => {
    const { orderStatuses } = this.state;

    return (
      <Row gutter={[16, 16]}>
        {orderStatuses?.length ? (
          orderStatuses.map((rec) => (
            <Col span={12}>
              <h3>{rec.type_of_loc}</h3>
              {this._renderStatusNField(rec.type_of_loc, rec.statuses)}
            </Col>
          ))
        ) : (
          <Empty
            description={
              <Fragment>
                <h4 className="textRed">
                  No Statuses are Configured for the order associated account.{" "}
                </h4>
              </Fragment>
            }
          />
        )}
      </Row>
    );
  };

  customRequest = (obj) => {
    const { file, onSuccess, onError, originFileObj } = obj;
    this.setState({
      errors: [],
    });
    // Simulate an API request. Replace this with your actual API call.
    setTimeout(() => {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          // this.setState({
          //   base64Data,
          // });
          onSuccess();
          message.success(`${file.name} file uploaded successfully`);
        };

        reader.readAsDataURL(file);
      } catch (error) {
        onError(error);
      }
    }, 1000);
  };

  handleMoreFileChange = (type_of_loc, status_code, field, info) => {
    const { status } = info.file;
    let uploadedFileList = [info.fileList[info.fileList.length - 1]];
    if (status === "removed") {
      // File has been removed
      this.onFileChange(type_of_loc, status_code, field, "");
    } else {
      // Update fileList state with the latest list of files
      this.onFileChange(type_of_loc, status_code, field, uploadedFileList);
    }
  };

  onFileChange = (type_of_loc, status_code, field, uploadedFileList) => {
    this.setState((prevState) => ({
      orderStatuses: prevState.orderStatuses.map((locationStatus) => {
        return {
          ...locationStatus,
          statuses: locationStatus.statuses.map((status) => {
            if (
              locationStatus.type_of_loc === type_of_loc &&
              status.status_code === status_code
            ) {
              const updatedFields = status.require_fields.map(
                (requiredField) => {
                  if (
                    requiredField.field === field &&
                    requiredField.type === "file"
                  ) {
                    return {
                      ...requiredField,
                      value: uploadedFileList, // Assuming fileList is an array of files
                    };
                  }
                  return requiredField;
                }
              );

              return {
                ...status,
                require_fields: updatedFields,
              };
            }
            return status;
          }),
        };
      }),
    }));
  };

  customPreviewFile = (file) => {
    const blob = new Blob([file.originFileObj]);
    const fileURL = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", file.name);
    document.body.appendChild(link);

    // Simulate click on the link to initiate download
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  _renderStatusNField = (type_of_loc, statuses) => {
    const { organizationSettings } = this.props;
    const wh_location = _.get(this.props.order, "wh_location", {});
    const isMilitaryTime = organizationSettings.is_military_time === "true";
    const statusFields =
      statuses?.length &&
      statuses.map((item) => {
        const { status_code, require_fields, is_enabled } = item;

        const statusFieldTypes = [
          ...new Set(require_fields.map((field) => field.type)),
        ]; //getting array of require_fields types

        // Create checkboxes for status_code
        const checkBox = (
          <Checkbox
            key={status_code}
            checked={is_enabled}
            onChange={(e) =>
              this.handleCheckboxChange(
                type_of_loc,
                status_code,
                e.target.checked,
                statusFieldTypes
              )
            }
          >
            {status_code}
          </Checkbox>
        );

        // Create input fields for require_fields
        const textFields = require_fields?.length
          ? require_fields.map((field) => {
              const {
                field: fieldName,
                type,
                value,
                label,
                isRequired,
              } = field;

              // Render different types of input fields based on the type
              let inputField = null;
              let error = null;

              // Validation for required fields
              if (is_enabled && isRequired && isEmpty(value)) {
                error = `${label} is required`;
              }

              switch (type) {
                case "datetime":
                  inputField = (
                    <SideBySideFormItem
                      key={fieldName}
                      label={label}
                      labelCol={{ lg: 5, xl: 5, xxl: 5 }}
                      wrapperCol={{ lg: 19, xl: 19, xxl: 19 }}
                    >
                      <DateTimeSelector
                        FormItem={SideBySideFormItem}
                        dateProps={{
                          label: "Date",
                          format: "Do MMM YYYY",
                          value: value,
                          onChange: (date) => {
                            // When current date is selected
                            this.handleInputChange(
                              type_of_loc,
                              status_code,
                              fieldName,
                              date
                            );
                          },
                          style: { width: "100%", height: "100%" },
                          disabled: !is_enabled,
                          size: "small",
                          allowClear: false,
                        }}
                        timeProps={{
                          label: "Time",
                          format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                          showTime: {
                            format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                            use12Hours: !isMilitaryTime,
                          },
                          isMilitaryTime,
                          onChange: (time) => {
                            // When current date is selected
                            this.handleInputChange(
                              type_of_loc,
                              status_code,
                              fieldName,
                              time
                            );
                          },
                          value: value,
                          style: {
                            width: "100%",
                            height: "100%",
                            marginTop: "5px",
                          },
                          minuteStep: 1,
                          className:
                            "formMaterialTimePicker smallMuiTimePicker",
                          disabled: !is_enabled,
                          size: "small",
                        }}
                        gridStyle={{
                          row: { type: "flex", gutter: 2 },
                          dateCol: { span: 12 },
                          timeCol: { span: 12 },
                        }}
                        hideLabel={true}
                      />
                    </SideBySideFormItem>
                  );
                  break;
                case "date":
                  inputField = (
                    <SideBySideFormItem
                      key={fieldName}
                      label={label}
                      labelCol={{ lg: 5, xl: 5, xxl: 5 }}
                      wrapperCol={{ lg: 19, xl: 19, xxl: 19 }}
                    >
                      <DatePicker
                        format="DD MMM, YYYY"
                        size="small"
                        value={value}
                        onChange={(e) =>
                          this.handleInputChange(
                            type_of_loc,
                            status_code,
                            fieldName,
                            e
                          )
                        }
                        disabled={!is_enabled}
                      />
                    </SideBySideFormItem>
                  );
                  break;
                case "string":
                  inputField = (
                    <SideBySideFormItem
                      key={fieldName}
                      label={label}
                      labelCol={{ lg: 5, xl: 5, xxl: 5 }}
                      wrapperCol={{ lg: 19, xl: 19, xxl: 19 }}
                    >
                      <Input
                        value={value}
                        onChange={(e) =>
                          this.handleInputChange(
                            type_of_loc,
                            status_code,
                            fieldName,
                            e.target.value
                          )
                        }
                        disabled={!is_enabled}
                        size="small"
                      />
                    </SideBySideFormItem>
                  );
                  break;
                case "file":
                  inputField = (
                    <SideBySideFormItem
                      key={fieldName}
                      label={label}
                      labelCol={{ lg: 5, xl: 5, xxl: 5 }}
                      wrapperCol={{ lg: 19, xl: 19, xxl: 19 }}
                    >
                      <div className="uploadFileWithDeleteIcon">
                        <Dragger
                          customRequest={this.customRequest}
                          onChange={(info) =>
                            this.handleMoreFileChange(
                              type_of_loc,
                              status_code,
                              fieldName,
                              info
                            )
                          }
                          // beforeUpload={this.beforeUpload}
                          fileList={value}
                          disabled={!is_enabled}
                          onPreview={this.customPreviewFile}
                        >
                          <p
                            className="ant-upload-text"
                            style={{ fontSize: 15 }}
                          >
                            <Icon type="upload" className="marginRightt10" />
                            &nbsp;&nbsp; Click or drag here to upload files
                          </p>
                        </Dragger>
                      </div>
                    </SideBySideFormItem>
                  );
                  break;
                // case "appointment":
                //   const dispatcherDisabledStartHours =
                //     getDisabledHoursFromStartTime(
                //       organizationSettings.route_delivery_start_time,
                //       organizationSettings.route_delivery_end_time,
                //       false
                //     );
                //   const dispatcherDisabledEndHours =
                //     getDisabledHoursFromStartTime(
                //       organizationSettings.route_delivery_start_time,
                //       organizationSettings.route_delivery_end_time,
                //       false
                //     );
                //   const endTimes = getHMSFromTimeString(
                //     organizationSettings.route_delivery_end_time,
                //     "HH:mm",
                //     null,
                //     null
                //   );
                //   const startTimes = getHMSFromTimeString(
                //     organizationSettings.route_delivery_start_time,
                //     "HH:mm",
                //     null,
                //     null
                //   );

                //   //Fetching if any validation error in appointment
                //   const { errors: slotsErrors, appointments: slots } =
                //     validateslots([value], isMilitaryTime);

                //   const hasError = slotsErrors.length > 0 || slots.length === 0;

                //   // If there are errors, update the error variable
                //   if (is_enabled && isRequired && hasError) {
                //     error = slotsErrors.length
                //       ? `In ${label}: ${slotsErrors.join(", ")}`
                //       : `In ${label}: Please select valid Appointment`;
                //   }

                //   inputField = (
                //     <Appointments
                //       key={this.state.key}
                //       selectedOrderRows={[]}
                //       slots={wh_location.slot_details}
                //       feasible_dates={[]}
                //       feasibleDow={[]}
                //       isMilitaryTime={isMilitaryTime}
                //       hasDeliveryZipcodesConfig={false}
                //       //onSave={ this.setAppointmentSchedule }
                //       onSlotsChange={(selectedSlot, isCustom) => {
                //         let selectedSlots = isCustom
                //           ? ["CUSTOM"]
                //           : [selectedSlot];
                //         const [start_time, end_time] = selectedSlot.split("-");
                //         if (selectedSlots[0] === "08:00-20:00") {
                //           selectedSlots = ["ANY"];
                //         }
                //         const preference = {
                //           ..._.cloneDeep(value),
                //           slots: selectedSlots,
                //           start_time: isCustom ? null : start_time,
                //           end_time: isCustom ? null : end_time,
                //         };
                //         this.handleInputChange(
                //           type_of_loc,
                //           status_code,
                //           fieldName,
                //           preference
                //         );
                //       }}
                //       clearPreference={(index) => {
                //         this.handleInputChange(
                //           type_of_loc,
                //           status_code,
                //           fieldName,
                //           _.cloneDeep(defaultPreference)
                //         );
                //       }}
                //       itemOnChange={(index, element, itemValue) => {
                //         let modifiedPreferences = { ..._.cloneDeep(value) };
                //         if (element === "slots") {
                //           if (
                //             Array.isArray(itemValue) &&
                //             itemValue.length <= 0
                //           ) {
                //             return false;
                //           }
                //           const isCustom =
                //             Array.isArray(itemValue) &&
                //             itemValue.length > 0 &&
                //             itemValue[0] === "CUSTOM";
                //           if (isCustom) {
                //             modifiedPreferences = {
                //               ...modifiedPreferences,
                //               slots: itemValue,
                //               start_time: null,
                //               end_time: null,
                //             };
                //           } else {
                //             const [start_time, end_time] =
                //               itemValue[0].split("-");
                //             modifiedPreferences = {
                //               ...modifiedPreferences,
                //               slots: itemValue,
                //               start_time,
                //               end_time,
                //             };
                //           }
                //         } else {
                //           modifiedPreferences = {
                //             ...modifiedPreferences,
                //             [element]: itemValue,
                //           };
                //         }
                //         this.handleInputChange(
                //           type_of_loc,
                //           status_code,
                //           fieldName,
                //           modifiedPreferences
                //         );
                //       }}
                //       data={{
                //         preferences: [
                //           {
                //             ..._.cloneDeep(value),
                //             //appt_date: this.state.preference?.appt_date ? this.state.preference.appt_date : moment(moment().startOf('day')),
                //           },
                //         ],
                //         endHour: endTimes.hourValue,
                //         endMinute: endTimes.minuteValue,
                //         startHour: startTimes.hourValue,
                //         startMinute: startTimes.minuteValue,
                //         disabledStartHours: dispatcherDisabledStartHours,
                //         disabledEndHours: dispatcherDisabledEndHours,
                //         showExpedite: true,
                //       }}
                //       showSelectedOrder={false}
                //       disableItems={
                //         !is_enabled ? "appt_date,slots,expedite,custom" : null
                //       }
                //       showClear={false}
                //       size={"small"}
                //     />
                //   );
                default:
                  break;
              }

              return (
                <Fragment key={fieldName} className="input-base">
                  {inputField}
                  <Row>
                    <Col xs={8}></Col>
                    <Col xs={16}>
                      {error && (
                        <div style={{ color: "#f5222d", marginTop: -5 }}>
                          {error}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Fragment>
              );
            })
          : null;

        return (
          <div key={status_code} className="marginBottom5">
            <Card
              size="small"
              title={""}
              style={{
                padding: "10px 10px 15px 10px",
                background: "#f5f5f5f5",
              }}
            >
              {checkBox}
              {textFields}
            </Card>
          </div>
        );
      });

    return <Fragment>{statusFields}</Fragment>;
  };

  _renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={this.handleSendStatus}
      disabled={
        this.state.status_type === "SEND" &&
        this.state.orderStatuses.length === 0
      }
    >
      <Icon type="save" />
      Send
    </Button>
  );

  _renderForm = () => {
    const { status_type } = this.state;
    return (
      <Fragment>
        <Radio.Group
          onChange={(e) =>
            this.handleStateChange("status_type", e.target.value)
          }
          value={status_type}
          style={{ marginTop: 3 }}
        >
          <Row>
            {/*

























            */}
            <Col xs={24} className="marginTop10">
              <Radio value="SEND">
                <span style={{ fontWeight: 700 }}>
                  {I18n.t("statusFile.opt2")}
                </span>
              </Radio>
            </Col>
          </Row>
        </Radio.Group>

        {status_type === "SEND" && (
          <div className="marginLeft15 statusCard">
            <Spin spinning={this.state.isLoading} delay={1000}>
              {this._renderLocationStatusNField()}
            </Spin>
          </div>
        )}
        {/* <Row>
          <Col xs={24} className="alignCenter marginTop5">
            <Button
              type="primary"
              icon="check-circle"
              size="small"
              onClick={this.handleSendStatus}
              disabled={
                this.state.status_type === "SEND" &&
                this.state.orderStatuses.length === 0
              }
            >
              {I18n.t("general.send")}
            </Button>
          </Col>
        </Row> */}
        {this._renderFloatingBtn()}
      </Fragment>
    );
  };

  _renerSendStatusWindow = () => {
    const { orderStatuses } = this.state;
    return (
      <Fragment>
        <div>{this._renderForm()}</div>
        {/* <BaseModal
          title={`${I18n.t("general.send")} ${I18n.t("statusFile.lable")}`}
          onCancel={this.disabelForm}
          width={orderStatuses?.length === 1 ? 600 : 1000}
          maskClosable={false}
        >
          <div>{this._renderForm()}</div>
        </BaseModal> */}
      </Fragment>
    );
  };

  renderSourceFileInfo = () => {
    return this.state?.order?.source_files;
  };

  _renderDownloadButton= (files) => {
    const filesCount = files.length;
  
    return (
      <Popover
        title="Download EDI File"
        trigger="click"
        overlayStyle={{ width: "500px" }}
        content={
          filesCount === 0 ? (
            <div>No files to download.</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr key={index}>
                    <td>{file.name}</td>
                    <td>
                        <DisplayTime
                          dateTimeString={file.received_at}
                          displayWithDate={true}
                          isUTC={true}
                          tz_short_name="UTC"
                        />
                    </td>
                    <td>
                      <Button
                        type="link"
                        href={file.url}
                        target="blank"
                        download={`${file.name}.edi`}
                        icon={file.url ? "download" : undefined}
                        >
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        }
      >
        <Button type="primary" size="small" className="floatbtnDownload" icon="download">
          EDI File
            <Badge
            count={filesCount} showZero
              style={{
                backgroundColor: "red",
                color: "white",
                bottom: "12px",
                left: "2px",
              }}
            ></Badge>
          
        </Button>
      </Popover>
    );
  };


  render() {
    return (
      <Fragment>
        {this._renerSendStatusWindow()}
        <div>
          {this._renderDownloadButton(this.renderSourceFileInfo())}
        </div>
        {/* {
          <Button
            type="primary"
            onClick={() => this.enableForm()}
            style={{ marginTop: "2px", marginLeft: 10, marginRight: 10 }}
            className="buttonCoolBlue"
            icon="check-circle"
            size={"small"}
          >
            {`${I18n.t("general.send")} ${I18n.t("statusFile.lable")}`}
          </Button>
        } */}
        {/* {this.state.showForm && this._renerSendStatusWindow()} */}
      </Fragment>
    );
  }
}

export const SendStatusReportComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, currentOrg } =
    useContext(OrgContext);
  const userContext = useContext(UserContext);
  return (
    <SendStatusReport
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      userContext={userContext}
      currentOrg={currentOrg}
      {...props}
    />
  );
});

SendStatusReport.propTypes = {};
SendStatusReport.defaultProps = {};
export default SendStatusReportComponent;
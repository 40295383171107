import React from 'react';
import ReactDOM from 'react-dom';

import Main from '../src/javascripts/index';

const render = (Component) => {
  const rootElement = document.getElementById('main-content');
  
  if (!rootElement) {
    console.error('Error: The element with id "main-content" was not found.');
    return;
  }

  ReactDOM.render(<Component />, rootElement);
};

document.addEventListener('DOMContentLoaded', () => {
  render(Main);
});

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('../src/javascripts/index', () => {
    // Dynamically require the updated module
    const NextMain = require('../src/javascripts/index').default;
    render(NextMain);
  });
}

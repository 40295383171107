/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { Button,Checkbox, Row, Col, Spin,  Radio, Modal } from '../../common/UIComponents'
import {alertMessage, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import { scheduleAppointments } from '../../api/OrdersApi'
import OpinionForm from './OpinionFom'
import BaseModal from '../BaseModal'
import FormButtons from '../common/FormButtons'
import { checkEndTime, getDisabledHoursFromStartTime } from '../../helpers/orders'
import FormErrors from '../common/FormErrors'
import { getHMSFromTimeString } from '../../helpers/date_functions'
import Appointments from '../common/Appointments';
import ErrorComponent from '../common/ErrorComponent'

const defaultValue = {
  s_no: 1,
  item_preference: moment(moment().startOf('day')),
  appt_date: moment(moment().startOf('day')),
  slots: [],
  has_expedite: false,
  start_time: '',
  end_time: '',
}
class OrderBulkAppointment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: this.props.orders || [],
      selectedOrders: this.props.selectedOrders || [],
      inProgress: false,
      showForm: false,
      preference: {
        item_preference: moment(moment().startOf('day')),
        appt_date: moment(moment().startOf('day')),
        slots: [],
        confirmed: false,
        start_time: null,
        end_time: null,
        has_expedite: false,
      },
      organizationSettings: {},
      isMilitaryTime: false,
      errorOrderNos: [],
      errors: [],
      scheduleOption: 'BOTH',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.organizationSettings,
        this.props.organizationSettings,
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == 'true',
      })
    }
    if( !_.isEqual(prevProps.selectedOrders, this.props.selectedOrders)){
      this.setState({ selectedOrders : this.props.selectedOrders});
    }
    if(prevProps.isChildContainerClicked !== this.props.isChildContainerClicked && this.props.isChildContainerClicked ) {
      this.enableForm()
    }
  }

  componentDidMount() {
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == 'true',
    })
  }

  handleScheduleOptionChange = e => {
    this.setState({
      scheduleOption: e.target.value
    });
  };

  enableForm = () => {
    this.setState({
      showForm: true,
    })
  }
  disabelForm = () => {
    this.setState(
      {
        showForm: false,
        preference: { ...defaultValue },
        errors: [],
        orderErrors: [],
        errorOrderNos: [],
        scheduleOption: 'BOTH',
      },
      () => {
        if(this.props.isFromBulkContainer){
          this.props.setIsChildContainerClicked(false)
          this.props.setIsHovered(null);
        }33
      },
    )
  }

  handleErrors = (result) => {
    if (result?.errors?.length > 0) {
      const errorValue = result.errors[0]
      if (_.isString(errorValue)) {
        this.setState({
          errors: result.errors || [],
        })
      } else if (_.isObject(errorValue)) {
        const errors = []
        const errorOrderNos = []
        result.errors.forEach((errObj) => {
          if (_.isObject(errObj) && errObj.order_number) {
            errorOrderNos.push(errObj.order_number)
            errors.push(errObj)
          }
        })
        this.setState({
          errorOrderNos,
          inProgress: false,
          errors,
          showErrors: errors.length > 0,
        })
        // alertMessage('One or more required fields are missing', 'error', 10);
      }
    }
    this.setState({ inProgress: false })
  }

  setAppointmentSchedule = (skipCapacityValidation = true) => {
    this.setState({
      inProgress: true,
    })
    const { preference, orders, scheduleOption } = this.state
    const isDateRequired = ['BOTH', 'DATE'].includes(scheduleOption);
    const isTimeRequired = ['BOTH', 'TIME'].includes(scheduleOption);
    const formErrors = []
    if (isDateRequired && !preference.appt_date) {
      formErrors.push(' appointment date')
    }
    if (isTimeRequired) {
      if (preference.slots.length === 0) {
        formErrors.push(' slots ');
      }
      else if (preference.slots.includes('CUSTOM')) {
        // if not moment
        if (!moment(preference.start_time).isValid() || !moment(preference.end_time).isValid()
          || moment(preference.start_time).isSameOrAfter(preference.end_time)) {
          formErrors.push(' valid start time and end time');
        }
      }
    }
    if (formErrors.length === 0) {
      // const slots = preference.item_option ? preference.item_option : []
      const { slots }= preference
      let appointment= {
        confirmed: true, // preference.confirmed === true,
      }
      if(isDateRequired){
        appointment.appt_date= moment(preference.appt_date).format(
          'YYYY-MM-DD 00:00:00',
        );
      }
      if(isTimeRequired){
        appointment = {
          ...appointment,
          slots,
          start_time: preference.slots.includes('CUSTOM') ? moment(preference.start_time).format('HH:mm') : preference.start_time,
          end_time : preference.slots.includes('CUSTOM') ? moment(preference.end_time).format('HH:mm') : preference.end_time,
          has_expedite: preference.has_expedite === true,
        }
      }
     
      const data = {
        // appointment: {
        //   appt_date: moment(preference.appt_date).format(
        //     'YYYY-MM-DD 00:00:00',
        //   ),
        //   slots,
        //   confirmed: true, // preference.confirmed === true,
        //   start_time: preference.slots.includes('CUSTOM') ? moment(preference.start_time).format('HH:mm') : preference.start_time,
        //   end_time : preference.slots.includes('CUSTOM') ? moment(preference.end_time).format('HH:mm') : preference.end_time,
        //   has_expedite: preference.has_expedite === true,
        // },
        appointment,
        currentState: this.props.filter,
        order_ids: orders || [],
        has_expedite: preference.has_expedite === true,
        scheduled_option: scheduleOption,
        skipCapacityValidation,
      }
      scheduleAppointments(data).then((result) => {
        // result.success = false;
        // result.errors = ["11111111111: false"];
        if (result.success) {
          if(false && result.orders.warnings){
            const warnings = result.orders.warnings || []
            this.setState({
              inProgress: false,
            })
            Modal.error({
              title: I18n.t('messages.proceed_confirm'),
              content: warnings.join(','),
            })
            // confirmationPopup({
            //   title: I18n.t('messages.proceed_confirm'),
            //   content: warnings.join(','),
            //   onConfirm: () => {
            //     this.setAppointmentSchedule(true)
            //   },
            // })
          } else {
            if(result?.orders?.errors?.length ){
              this.handleErrors(result.orders)
            }else {
              this.setState(
                {
                  preference: { ...defaultValue },
                  orderErrors: [],
                  errors: [],
                  errorOrderNos: [],
                  showForm: false,
                },
                () => {
                 
                  this.props.resetOrders()
                  this.props.resetSelection()
                },
              )
            }
          }
          
        } else {
          this.handleErrors(result)
        }
      })
    } else {
      alertMessage(`Please select ${formErrors.join(',')}`, 'error', 5)
      this.setState({
        inProgress: false,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      orders: nextProps.orders || [],
    })
  }

  handleItemOptionOnChange = (index, element, value, checked, type) => {
    const { preference } = this.state
    const optionIndex = preference[element].indexOf(value)
    if (optionIndex >= 0) {
      if (value !== 'CUSTOM') {
        preference.start_time = null
        preference.end_time = null
      }
      if (checked) {
        if (type === 'radio' || ['ANY', 'CUSTOM'].includes(value)) {
          preference[element] = [value]
        } else {
          // Assuming getting A, M , E
          preference[element] = preference[element].filter(
            (ele) => !['ANY', 'CUSTOM'].includes(ele),
          )
          preference[element].push(value)
        }
      } else {
        if (value === 'CUSTOM') {
          preference.start_time = null
          preference.end_time = null
        }
        preference[element].splice(optionIndex, 1)
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (value !== 'CUSTOM') {
        preference.start_time = null
        preference.end_time = null
      }
      if (type === 'radio' || ['ANY', 'CUSTOM'].includes(value)) {
        preference[element] = [value]
      } else {
        // Assuming getting A, M , E
        preference[element] = preference[element].filter(
          (ele) => !['ANY', 'CUSTOM'].includes(ele),
        )
        preference[element].push(value)
      }
    }
    if (
      preference[element].includes('M') &&
      preference[element].includes('A') &&
      preference[element].includes('E')
    ) {
      preference[element] = ['ANY']
    }
    this.setState({ preference })
  }

  getButtonStyle = (option) => {
    const { scheduleOption } = this.state;
    return {
      backgroundColor: scheduleOption === option ? "#1890ff" : "transparent",
      color: scheduleOption === option ? "white" : "inherit",
      marginTop: option === "BOTH" ? "20px" : "0", // Specific style for 'BOTH'
    };
  };

  _renerAppointmentWindow = () => {
    const { organizationSettings, scheduleOption } = this.state;
    const isDateRequired = ["BOTH", "DATE"].includes(scheduleOption);
    const isTimeRequired = ["BOTH", "TIME"].includes(scheduleOption);   
    const dispatcherDisabledStartHours = getDisabledHoursFromStartTime(organizationSettings.route_delivery_start_time, organizationSettings.route_delivery_end_time, false);
    const dispatcherDisabledEndHours = getDisabledHoursFromStartTime(organizationSettings.route_delivery_start_time, organizationSettings.route_delivery_end_time, false);
    const endTimes = getHMSFromTimeString(organizationSettings.route_delivery_end_time,"HH:mm", null, null);
    const startTimes = getHMSFromTimeString(organizationSettings.route_delivery_start_time,"HH:mm", null, null);
    const currentWarehouse = typeof this.props.warehouseFilter.selectedWarehouses === 'string' ? _.find(this.props.warehouseFilter.warehouses, { id: this.props.warehouseFilter.selectedWarehouses }) : _.find(this.props.warehouseFilter.warehouses, { id: this.props.warehouseFilter.selectedWarehouses[ 0 ] });
    return (
      <Fragment>
        <BaseModal
          title={I18n.t('order.schedule_an_appointment')}
          onCancel={this.disabelForm}
          width={ 640 }
          maskClosable={false}
        >
          <Spin spinning={this.state.inProgress}>
            <div>
              {/* <OpinionForm
                record={this.state.preference}
                itemOnChange={(index, element, value) =>
                  this.handleItemOnChange(index, element, value)
                }
                itemOptionOnChange={(index, element, value, checked, type) =>
                  this.handleItemOptionOnChange(
                    index,
                    element,
                    value,
                    checked,
                    type,
                  )
                }
                confirmChange={(index, element, value) =>
                  this.handleConfirmChange(index, element, value)
                }
                clearPreference={(index) => this.clearPreference(index)}
                isDisabled={false}
                feasibleDow={[]}
                feasibleDates={[]}
                deliveryZipcodes={false}
                showClear={false}
                disabledStartHours={dispatcherDisabledStartHours}
                disabledEndHours={dispatcherDisabledEndHours}
                endHour={endTimes.hourValue}
                endMinute= {endTimes.minuteValue}
                startHour={startTimes.hourValue}
                startMinute= {startTimes.minuteValue}
                optingType={'radio'}
                showExpedite
                isMilitaryTime={this.state.isMilitaryTime}
              /> */}
              {this.props.showSchduleOption && <Radio.Group
                onChange={this.handleScheduleOptionChange}
                value={scheduleOption}
                className="marginBottom20"
                style={{ marginTop: -20}}
              >
                <Radio.Button value="BOTH" style={this.getButtonStyle('BOTH')}>
                  Date and Time
                </Radio.Button>
                <Radio.Button value="DATE" style={this.getButtonStyle('DATE')}>
                  Date Only
                </Radio.Button>
                <Radio.Button value="TIME" style={this.getButtonStyle('TIME')}>
                  Time Only
                </Radio.Button>
              </Radio.Group>
              }
              <div style={{ paddingLeft: 10}}>
              <Appointments
                selectedOrderRows={this.state.selectedOrders}
                slots={ currentWarehouse.slot_details }
                feasible_dates={ [] }
                feasibleDow={[]}
                isMilitaryTime={ this.state.isMilitaryTime }
                hasDeliveryZipcodesConfig={ false }
                // showClear={ false }
                onSave={() => this.setAppointmentSchedule(false) }
                onSlotsChange={ (selectedSlot, isCustom) => {
                  let selectedSlots = isCustom ? [ 'CUSTOM' ] : [ selectedSlot ];
                  const [ start_time, end_time ] = selectedSlot.split("-");
                  if (selectedSlots[ 0 ] === '08:00-20:00') {
                    selectedSlots = [ 'ANY' ];
                  }
                  this.setState({
                    preference: {
                      ...this.state.preference,
                      slots: selectedSlots,
                      start_time: isCustom ? null : start_time,
                      end_time: isCustom ? null : end_time,
                    }
                  });
                } }
                clearPreference={ (index) =>
                {
                  this.setState({
                    preference: {
                      s_no: 1,
                      item_preference: moment(moment().startOf('day')),
                      appt_date: moment(moment().startOf('day')),
                      slots: [],
                      has_expedite: false,
                      start_time: '',
                      end_time: '',
                    }
                  });
                }
                }
                itemOnChange={ (index, element, value) => {
                  let modifiedPreferences = {...this.state.preference};
                  if(element === 'slots') {
                    if(Array.isArray(value) && value.length <= 0) {
                      return false;
                    }
                    const isCustom = Array.isArray(value) && value.length > 0 && value[ 0 ] === 'CUSTOM';
                    if (isCustom) {
                      modifiedPreferences = {
                        ...modifiedPreferences,
                        slots: value,
                        start_time: null,
                        end_time: null,
                      }
                    }
                    else { 
                      const [ start_time, end_time ] = value[ 0 ].split("-");
                      modifiedPreferences = {
                        ...modifiedPreferences,
                        slots: value,
                        start_time,
                        end_time,
                      }
                    }
                  }else {
                    modifiedPreferences = { ...modifiedPreferences, [ element ]: value };
                  }
                  this.setState({
                    preference: modifiedPreferences,
                  });
                } }
                data={
                  {
                    preferences: [ {
                      ...this.state.preference,
                      //appt_date: this.state.preference?.appt_date ? this.state.preference.appt_date : moment(moment().startOf('day')),
                    } ],
                    endHour: endTimes.hourValue,
                    endMinute: endTimes.minuteValue,
                    startHour: startTimes.hourValue,
                    startMinute: startTimes.minuteValue,
                    disabledStartHours: dispatcherDisabledStartHours,
                    disabledEndHours: dispatcherDisabledEndHours,
                    showExpedite: isTimeRequired,
                    showDatepicker: isDateRequired,
                    showTimeSlots: isTimeRequired,
                  }
                }
                scheduleOption={this.state.scheduleOption}
              />
              </div>
              {false && (
                <Row>
                  <Col className="marginTop20 schedule preferenceForm">
                    <Checkbox
                      onChange={(e) =>
                        this.handleConfirmChange(0, 'confirmed', e.target.checked)
                      }
                      checked={this.state.preference.confirmed}
                    />{' '}
                    {I18n.t('order.confirm_these_appointments')}
                  </Col>
                </Row>
              )}
              {/* <Row>
                <Col className="marginTop20 schedule">
                  {FormButtons(
                    this.state.inProgress,
                    this.setAppointmentSchedule,
                    this.clearPreference,
                    true,
                    true,
                    'small',
                    I18n.t('general.save'),
                    I18n.t('general.clear'),
                  )}
                </Col>
              </Row> */}
              {!this.state.showErrors && this.state.errors.length > 0 && (
                <Row>
                  <Col xs={24} className="marginTop10">
                    {FormErrors(this.state.errors)}
                    {/* <ErrorComponent errors={this.state.errors} /> */}
                  </Col>
                </Row>
              )}
            </div>
          </Spin>
        </BaseModal>
      </Fragment>
    )
  }

  handleItemOnChange = (index, element, value) => {
    const { hourValue, minuteValue} =  getHMSFromTimeString(this.state.organizationSettings.route_delivery_end_time,"HH:mm", null, null);
    const dispatcherEndHour = hourValue;
    const dispatcherEndMinute = minuteValue; 
    const { preference } = this.state
    if (element === 'start_time' || element === 'end_time') {
      if (value !== null) {
        // For from_date,  to_date
        let hasErrors = false
        const currentPreference = { ...preference }
        if (element === 'start_time') {
          const startHour = moment(value).hour()
          if (startHour > dispatcherEndHour) {
            value = moment(value)
              .set('hour', dispatcherEndHour - 1)
              .set('minute', 0)
          }
          preference.end_time = checkEndTime(currentPreference, value, dispatcherEndHour, dispatcherEndHour)
        }
        if (element === 'end_time') {
          const endHour = moment(value).hour()
          if (endHour > dispatcherEndHour) {
            value = moment(value)
              .set('hour', dispatcherEndHour)
              .set('minute', 0)
          }
          if (!isEmpty(currentPreference.start_time)) {
            const isBefore = value.isBefore(currentPreference.start_time)
            if (isBefore) {
              hasErrors = true
              alertMessage(
                I18n.t('messages.from_time_exceeds_to_time'),
                'error',
                5,
              )
            }
          }
        }
        if (!hasErrors) {
          preference[element] = value
          preference.item_option = ['CUSTOM']
          // if (element === 'start_time' && isEmpty(preference.end_time)) {
          //   preference.end_time = moment(value).add(120, 'minutes');
          // }
          this.setState({ preference })
        }
      } else {
        preference[element] = value
        if (isEmpty(preference.start_time) && isEmpty(preference.end_time)) {
          preference.item_option = []
        }
        this.setState({ preference })
      }
    } else {
      preference[element] = value
      this.setState({ preference })
    }
  }

  handleConfirmChange = (index, element, value) => {
    const { preference } = this.state
    if (value === true) {
      preference.confirmed = true
    } else {
      preference.confirmed = false
    }
    this.setState({ preference })
  }

  clearPreference = () => {
    this.setState({
      preference: { ...defaultValue },
    })
  }

  closeErrors = () => {
    this.setState({
      orderErrors: [],
      showErrors: false,
    })
  }

  renderStatusErrors = () => (
    <BaseModal
      title={I18n.t('errors.errors')}
      placement="right"
      closable
      onCancel={this.closeErrors}
      visible={this.state.showErrors}
      width="50%"
      style={{ top: 35 }}
    >
      {this.state.errors.map((err) => (
        <Row>
          <Col xs={4}>{err.order_number}</Col>
          <Col xs={20}>{_.isArray(err.errors) ? err.errors.join(',') : ''}</Col>
        </Row>
      ))}
    </BaseModal>
  )

  render() {
    return (
      <Fragment>
         {!this.props.isFromBulkContainer ? <Button
          type="primary"
          onClick={() => this.enableForm()}
          style={{ marginTop: '2px', marginLeft: 10 }}
          className="buttonYellow"
          icon="calendar"
        >
          {I18n.t('general.schedule')}
        </Button> : I18n.t('general.schedule')}
        {this.state.showForm && this._renerAppointmentWindow()}
        {this.state.showErrors && this.renderStatusErrors()}
      </Fragment>
    )
  }
}

OrderBulkAppointment.propTypes = {
  resetOrders: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.string).isRequired,
  filter: PropTypes.string.isRequired,
  selectedOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFromBulkContainer: PropTypes.bool,
  isChildContainerClicked: PropTypes.bool,
  setIsChildContainerClicked: PropTypes.func,
  setIsHovered: PropTypes.func,
  resetSelection: PropTypes.func,
}
OrderBulkAppointment.defaultProps = {
  showSelectedOrder: false,
  showSchduleOption: false,
  isFromBulkContainer: false,
  isChildContainerClicked: false,
  setIsChildContainerClicked: () => {},
  setIsHovered: () => {},
  resetSelection : () => {},
};
// OrderFilter.defaultProps = {
// };
export default withRouter(OrderBulkAppointment)

import React, { Fragment } from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import ImageViewer from "../common/ImageViewer";
import moment from "moment";
import AppConfig from "../../config/AppConfig";
import { doFormate } from "../../common/Common";

class ItemsExceptionLogList extends BaseList {
  constructor(props) {
    super(props);
    const timeFormat = props.isMilitaryTime === true ? "MMM DD, YYYY HH:mm" : "MMM DD, YYYY hh:mm A";
    this.columns = [
      {
        title: "#",
        key: "index",
        render: (text, record, index) => index + 1,
      },
      {
        key: "item_name",
        title: I18n.t("general.name"),
        dataIndex: "item_name",
      },
      {
        key: "quantity",
        title: I18n.t("order.pieces"),
        dataIndex: "item_quantity",
        align: "center",
      },
      {
        key: "item_model",
        title: I18n.t("order.model_no"),
        dataIndex: "item_model",
      },
      {
        key: "serial_number",
        title: I18n.t("order.serial_no"),
        dataIndex: "serial_number",
      },
      {
        key: "weight",
        title: I18n.t("order.weight"),
        dataIndex: "item_weight",
      },
      {
        key: "wh_dock",
        title: I18n.t("order.wh_dock"),
        dataIndex: "wh_dock",
        render: (data, record) => (
          <Fragment>{doFormate(data)}</Fragment>
        ),
      },
      {
        key: "dimensions",
        title: (
          <div>
            {I18n.t("order.dimensions")}&nbsp;(<small>L * W * H</small>)
          </div>
        ),
        dataIndex: "item_width",
      },
      {
        key: "dim_factor",
        title: <div>{I18n.t("configurations.dim_factor")}</div>,
        dataIndex: "dim_factor",
        align: "center",
      },
      {
        key: "dim_weight",
        title: (
          <div>
            {I18n.t("configurations.dim_weight")} ({AppConfig.weight_unit})
          </div>
        ),
        dataIndex: "dim_weight",
        align: "center",
      },
      {
        key: "exception_type",
        title: <>{I18n.t("item_exceptions.exceptions")}</>,
        render: (record) => {
          const itemException = _.get(record,"item_picture_data[0].exception_message")
          return(
            itemException ? itemException : "NA"
          )
        },
      },
      {
        key:"exception_comment",
        title:<>{I18n.t("item_exceptions.exception_comment")}</>,
        render:(record) => {
          const itemExceptionComment = _.get(record,"item_picture_data[0].exception_comments")
          return(
            itemExceptionComment ? itemExceptionComment : "NA"
          )
        },
      },
      {
        key: "captured_by",
        title: <>{I18n.t("item_exceptions.captured_by")}</>,
        render:(record) => {
          const capturedBy = _.get(record,"item_picture_data[0].created_by_name")
          return(
            capturedBy ? capturedBy : "NA"
          )
        },
      },
      {
        key: "captured_at",
        title: <>{I18n.t("item_exceptions.captured_at")}</>,
        width: 120,
          render: (record) => {
            const capturedAt = _.get(record,"item_picture_data[0].received_at")
            return(
              capturedAt ? moment(capturedAt).format(timeFormat) : "NA"
            )
          },
      },
      {
        key: "images",
        title: <>{I18n.t("item_exceptions.images")}</>,
        render:(record) => {
          const imageData = _.get(record,"item_picture_data[0]",[])
          const imageUrl =   !_.isEmpty(imageData) && imageData?.item_pictures.map((item) => 
              item.image_url ? item.image_url : _.get(item.item_image, "url")
          );
          return <ImageViewer images={imageUrl} />;
        }
      },
    ];
  }
}
export default ItemsExceptionLogList;

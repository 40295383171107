import { Call, ApiUrl, ParseGeneralResponse, buildQueryString } from "./ApiUtils";
import { isEmpty } from "../common/Common";
import userStore from "../stores/UserStore";
import { Descriptions } from "antd";

export function fetchInvoices(status = "READY_FOR_BILLING", accountCode = "") {
  let params = "";
  if (accountCode) {
    params = `account_code=${accountCode}`;
  }
  if (status) {
    params = `status=${status}`;
  }
  params = params ? `?${params}` : "";
  const url = ApiUrl(`v2/billing${params}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { invoices: "bills", stats: "count" },
  });
}

export function accountInvoiceNumbers(org) {
  const url = ApiUrl(`v2/billing/account_invoice_numbers?organization_id=${org}&operation_code=BAIN`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { invoices: "account_invoice_numbers" },
  });
}

export function fetchInvoicesWithFilter(data, searchKeyType, searchKeyValue) {
  let params = "";
  if(data.warehouse_id){
    data.warehouse_ids = data.warehouse_id
  }
  if (data.customer_order_number !== "") {
    const parameter = Object.keys(data).filter(key => !isEmpty(data)).map((key) => `${key}=${!isEmpty(data[key]) ? data[key] : ''}`);
    params = parameter.join("&");
    if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
      params = `${params}${
        params ? "&" : "?"
      }${searchKeyType}=${searchKeyValue}`;
    }
  } else {
    delete data.customer_order_number;
    const parameter = Object.keys(data).map((key) => `${key}=${!isEmpty(data[key]) ? data[key] : ''}`);
    params = parameter.join("&");
    if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
      params = `${params}${
        params ? "&" : "?"
      }${searchKeyType}=${searchKeyValue}`;
    }
  }
  const opCode = "operation_code=FBLOS";
  params = params ? `?${opCode}&${params}` : `?${opCode}`;
  const url = ApiUrl(`v2/billing/filtered_billing_order_stats${params}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoices: "bills",
      stats: "count",
      pagination: "meta.pagination",
    },
  });
}
export function fetchBillingOrdersByAcc(data, searchKeyType, searchKeyValue) {
  const organization_id = userStore.getStateValue("selectedOrg");
  data.organization_id = organization_id;
  if(data.warehouse_id){
    data.warehouse_ids = data.warehouse_id
  }
  let params = "";
  if (data.customer_order_number !== "") {
    const parameter = Object.keys(data).filter(key => !isEmpty(data)).map((key) => `${key}=${!isEmpty(data[key]) ? data[key] : ''}`);
    params = parameter.join("&");
    if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
      params = `${params}${
        params ? "&" : "?"
      }${searchKeyType}=${searchKeyValue}`;
    }
  } else {
    delete data.customer_order_number;
    const parameter = Object.keys(data).map((key) => `${key}=${!isEmpty(data[key]) ? data[key] : ''}`);
    params = parameter.join("&");
    if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
      params = `${params}${
        params ? "&" : "?"
      }${searchKeyType}=${searchKeyValue}`;
    }
  }
  const opCode = "operation_code=FBLOS";
  params = params ? `?${opCode}&${params}` : `?${opCode}`;
  const url = ApiUrl(`v2/billing/account_wise_billing_list${params}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoices: "billings",
      stats: "billings_count",
      pagination: "meta.pagination",
    },
  });
}

export function saveInvoice(data, isNew = true) {
  const url = isNew ? ApiUrl("v2/billing") : ApiUrl(`v2/billing/${data.id}`);
  data.operation_code = isNew ? "BLC" : "BLC";
  const method = isNew ? "post" : "put";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { invoice: "invoice" } },
    false
  );
}

export function updateInvoice(data) {
  data.operation_code = "BLUIND";
  const url = ApiUrl("v2/billing/update_invoice_details");
  const method = "put";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { invoice: "invoice" } },
    false
  );
}

export function updateInvoiceLines(data) {
  data.operation_code = "CINL";
  const url = ApiUrl("v2/billing/create_invoice_line");
  const method = "post";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { invoice: "invoice" } },
    false
  );
}

export function updateInvoiceCharges(data) {
  const url = ApiUrl("v2/billing/update_invoice_line");
  const method = "PUT";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { invoice: "" } },
    false
  );

}

export const saveHawbChangedDate = (payload) => {
  const url = ApiUrl("v2/billing/update_hawb_no");
  const method = "put";
  return Call(
    method,
    url,
    payload,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function fetchBillingDetails(order, orderGroupType) {
  const url = ApiUrl(
    `v2/billing/invoice_details?order_id=${order}&order_group_type=${orderGroupType}&operation_code=BLIND`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
      remarks: "remarks",
      error_logs: "erro_logs",
      billing_status: "billing_status",
      pickup_driver_pay_details: "pickup_driver_pay_details",
      consolidated_order_numbers : "consolidated_order_numbers",
      is_driver_settlement_approved : "is_driver_settlement_approved"
    },
  });
}

export const getBillingActivities = ({ organization_id = '', customer_order_ids = '' }) => {
  const url = ApiUrl(
    `v2/billing/get_billing_activities?organization_id=${organization_id}&customer_order_ids=${customer_order_ids}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      data: ""
    },
  });
}

export function fetchQuotationDetails(order) {
  const url = ApiUrl(
    `v2/billing/order_quotation_invoice_details?customer_order_id=${order}&operation_code=OQIND`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
    },
  });
}

export function regenerateLosBasedInvoice(data) {
  data.operation_code = "UINLOS";
  const url = ApiUrl("v2/billing/update_invoice_los");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
      remarks: "remarks",
      error_logs: "erro_logs",
      billing_status: "billing_status",
    },
  });
}

export function regenerateInvoiceWithNewLos(data) {
  data.operation_code = "UINLOS";
  const url = ApiUrl("v2/billing/update_invoice_los");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
      remarks: "remarks",
      error_logs: "erro_logs",
      billing_status: "billing_status",
    },
  });
}

export function regenerateAnOrderBilling(data) {
  data.operation_code = "UINLOS";
  const url = ApiUrl("v2/billing/regenerate_an_order_billing");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
    },
  });
}

export function fetchBillingDateManual(payload) {
  const url = ApiUrl("v2/billing/changing_billing_date_manual");
  return Call("PUT", url, payload, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
} 

export function fetchManualBillingDetails(invoice_number) {
  const url = ApiUrl(
    `v2/billing/manual_invoice_details?invoice_number=${invoice_number}&operation_code=MIND`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
      remarks: "remarks",
      error_logs: "erro_logs",
      billing_status: "billing_status",
    },
  });
}

export function fetchGroupBillingDetails(id) {
  const url = ApiUrl(
    `v2/billing/invoice_details?order_id=${id}&operation_code=BLIND`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
      remarks: "remarks",
      error_logs: "erro_logs",
      billing_status: "billing_status",
      consolidated_order_numbers : "consolidated_order_numbers",
      is_driver_settlement_approved : "is_driver_settlement_approved"
    },
  });
}

export function deleteInvoice(id, data = {}) {
  const url = ApiUrl(`v2/billing/${id}`);
  return Call("delete", url, data, ParseGeneralResponse, {}, false);
}

export function deleteInvoiceLine(id, data = {}) {
  const url = ApiUrl(
    `v2/billing/delete_invoice_item?invoice_id=${id}&operation_code=BLDINVI`
  );
  return Call("delete", url, data, ParseGeneralResponse, {}, false);
}

export function changeInvoiceStatus(data) {
  data.operation_code = "BLUS";
  const url = ApiUrl("v2/billing/update_status");
  return Call("put", url, data, ParseGeneralResponse);
}

export function fetchBillingStats() {
  const url = ApiUrl("v2/billing/billing_stats");
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function saveNewInvoice(data) {
  data.operation_code = "BLCIN";
  const url = ApiUrl("v2/billing/create_invoice");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      invoice_number: "invoice_number",
    },
  });
}

export function saveInvoiceDetailsForm(data) {
  data.operation_code = "BLSIND";
  const url = ApiUrl("v2/billing/save_invoice_details");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      invoice_number: "invoice_number",
    },
  });
}

export function fetchOrderInvoicePdf(id, invoiceNo) {
  const url = ApiUrl(
    `v2/billing/get_order_invoice_pdf?customer_order_id=${id}&operation_code=GOIP&invoice_no=${invoiceNo}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      bol_file: "bol_file",
    },
  });
}

export function fetchConsolidatedInvoicePdf(account_id, invoice_no, org_id) {
  const url = ApiUrl(
    `v2/billing/consolidate_invoice_pdf?account_id=${account_id}&invoice_number=${invoice_no}&organization_id=${org_id}&operation_code=GOIP`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      bol_file: "bol_file",
    },
  });
}

export function addOrdersForBillingGroup(data) {
  const url = ApiUrl("v2/billing/add_order_for_billing_group");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      data: "",
    },
  });
}

export function removeOrderFromBillingGroup(data) {
  const url = ApiUrl("v2/billing/remove_order_from_billing_group");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      data: "",
    },
  });
}

export function changeStatusOfInvoices(data) {
  data.operation_code = 'BLUS';
  const url = ApiUrl("v2/billing/multiple_order_billing_approval");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { invoices: "" },
    }
  );
}

export function changeStatusOfInvoicesToHold(data) {
  data.operation_code = 'BLUS';
  const url = ApiUrl("v2/billing/updating_orders_to_hold_status");
  return Call(
    "PUT",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { invoices: "" },
    }
  );
}

export function addToExistingInvoices(data) {
  data.operation_code = 'BLUS';
  const url = ApiUrl("v2/billing/add_orders_to_previous_invoice");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { invoices: "" },
    }
  );
}

export function reapproveBillings(data) {
  data.operation_code = 'BLUS';
  const url = ApiUrl("v2/billing/reapproval_order_billings");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { invoices: "" },
    }
  );
}


export function SendInvoiceEmail(data){
  const url = ApiUrl(`v2/billing/email_consolidate_invoice_pdf?${buildQueryString(data)}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      success: "success",
      message: "message",
    },
  });
}

export function createManualInvoice(data) {
  data.operation_code = "BLCIN";
  const url = ApiUrl("v2/billing/create_manual_invoice");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      data: "",
    },
  });
}

export function applyFMP(data){
  const url = ApiUrl(`v2/billing/apply_fmp_on_group_order`);
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
      new_invoice : "new_invoice",
      remarks: "remarks",
      error_logs: "erro_logs",
      billing_status: "billing_status",
      message: "message"
    },
  });
}

export function applySMP(data) {
  const url = ApiUrl(`v2/billing/apply_smp_on_order`);
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "invoice",
      new_invoice : "new_invoice",
      remarks: "remarks",
      error_logs: "erro_logs",
      billing_status: "billing_status",
      message: "message",
    },
  });
}

export function fetchDriverPayInfo(data) {
  const url = ApiUrl(`v2/order_driver_pay?customer_order_ids=${data}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      order_driver_payments: "order_driver_payments",
    },
  });
}

export function saveDriverPayInfo(data) {
  const url = ApiUrl("v2/order_driver_pay");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    { success: "success" },
    true
  );
}

export function deleteDriverPayInfo(customer_order_ids) {
  const url = ApiUrl(
    `v2/order_driver_pay?customer_order_ids=${customer_order_ids}`
  );
  return Call(
    "delete",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { message: "message" } }
  );
}

export function UpdateDriver(payload) {
  const url = ApiUrl('v2/billing/change_switch_driver_from_billing');
  return Call("put", url, payload, ParseGeneralResponse, {
    responseDataKeys: {
      status: "sucess",
    },
  }); 
};

export function printBillingPdf(ids){
  const url = ApiUrl(`v2/billing/get_billing_pdf?customer_order_ids=${ids}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      bills_file: "billing",
    },
  });
}
export function getContactEmails(accountId) {
  const url = ApiUrl(`v2/billing/get_account_emails?account_id=${accountId}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      contact_emails: "contact_emails"
    },
  });
}


export function printQuoteOrdersPdf(ids){
  const url = ApiUrl(`v2/billing/get_quote_orders_pdf?customer_order_ids=${ids}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      quote_orders_pdf: "quote_orders_pdf",
    },
  });
}

export function updateInvGenDateManual(data) {
  const url = ApiUrl("v2/account_invoices/updating_invoice_generation_date");
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      message: "message",
    },
  });
}

export function getOrderBillingDetails(customerOrderId) {
  const url = ApiUrl(`v2/billing/order_billing_details?customer_order_id=${customerOrderId}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      data : "billing_details",
    },
  });
}

export function getMonthlyCutoffDetails(data,status) {
  const {
    accountCodes = '',
    los = '',
    driver_id = '',
    invoiceAmountFilter = '',
    fromDate = '',
    toDate = '',
    warehouse_id = '',
    approvalDate = '',
  } = data;
  const formattedFromDate = fromDate.format("YYYY-MM-DD");
  const formattedToDate = toDate.format("YYYY-MM-DD");
  let formattedApprovalDate = '';
  if (!_.isEmpty(approvalDate)) {
    formattedApprovalDate = approvalDate.format("YYYY-MM-DD");
  }
  const url = ApiUrl(
    `v2/account_invoices/monthly_cut_off_report?status=${status}&warehouse_id=${warehouse_id}&account_id=${accountCodes}&los_code=${los}&driver_id=${driver_id}&invoice_amount_filter=${invoiceAmountFilter}&from_date=${formattedFromDate}&to_date=${formattedToDate}&warehouse_ids=${warehouse_id}&new_approval_date=${formattedApprovalDate}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      data: "",
    },
  });
}

export function saveTemplateZoneForm(data) {
  data.operation_code = "CINL";
  const url = ApiUrl("v2/billing/create_template_order_invoice_lines");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}

export function saveAddAdjustment(data) {
  data.operation_code = "BLDINVI";
  const url = ApiUrl("v2/estimation_adjustments/estimate_order_adjustments");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}

export function saveEditAdjustment(data) {
  data.operation_code = "BLDINVI";
  const url = ApiUrl("v2/estimation_adjustments/update_estimate_adjustments");
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}

export function deleteAdjustment(data) {
  const url = ApiUrl("/v2/estimation_adjustments/delete_estimation_adjustments");
  return Call("delete", url, data, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}


export function getAdjustments(data) {
  data.operation_code = "CINL";
  const url = ApiUrl(`v2/estimation_adjustments?organization_id=${data.organization_id}&customer_order_id=${data.order_id}`);
  return Call("get", url, data, ParseGeneralResponse, {
    responseDataKeys: { adjustment_lines: 'adjustment_lines'},
  });
}
export function SendInvoiceSummaryEmail(data){
  const url = ApiUrl(`v2/billing/email_summary_order_invoices?${buildQueryString(data)}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      success: "success",
      message: "message",
    },
  });
}

export function getEmailSubjectLine(data) {
  const { organization_id, invoice_number, order_id, setting_name } = data;
  let url = ApiUrl(
    `v2/billing/get_email_subject?organization_id=${organization_id}&setting_name=${setting_name}`
  );
  
  if (setting_name === "invoice_email_subject") {
    url += `&invoice_number=${invoice_number}`;
  }
  if (setting_name === "billing_email_subject") {
    url += `&order_id=${order_id}`;
  }
  if (setting_name === "invoice_email_subject_prefix_value") {
    url += `&invoice_number=${invoice_number}`;
  }
  
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      subject: "subject",
      is_revised: "is_revised",
    },
  });
}

export function deleteBilling(data = {}) {
  const url = ApiUrl(`v2/billing/delete_template_billing`);
  return Call("delete", url, data, ParseGeneralResponse, {}, false);
}
export function saveRateSheet(data) {
  const url = ApiUrl("v2/level_of_services/create_rate_sheet");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { message: "message", data: "rate_sheet" },
  });
}

export function deleteRateSheet(data) {
  const url = ApiUrl(`v2/level_of_services/destroy_rate_sheet`);
  return Call("delete", url, data, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function fetchRateSheet(orgId) {
  const url = ApiUrl(`v2/level_of_services/index_rate_sheet?organization_id=${orgId}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { rate_sheets: "rate_sheets" },
  });
}


export function linkMasterLosToAccount(
  accountId,
  organizationId,
  isAccountLosPresent,
  rateSheetId,
  rate_adjustment_type,
  rate_adjustment_value,
) {
  const url = ApiUrl(`v2/level_of_services/link_master_los_to_account?account_id=${accountId}&organization_id=${organizationId}&is_account_los_present=${isAccountLosPresent}&rate_sheet_id=${rateSheetId}&rate_adjustment_type=${rate_adjustment_type}&rate_adjustment_value=${rate_adjustment_value}`);
  
  return Call("put", url, {}, ParseGeneralResponse, {
    responseDataKeys: { rate_sheet_id: "rate_sheet_id" },
  });
}

export function unlinkMasterLosFromAccount(data) {
  const url = ApiUrl('v2/level_of_services/unlink_master_los_from_account');
  return Call("put", url, data, ParseGeneralResponse);
}

export function updateFuelPercentage(data) {
  const url = ApiUrl("v2/billing/update_fuel_percentage_in_billing");
  const method = "PUT";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { message: "message" } },
    false
  );

}

export function UpdateDriverAdjustmentInfo(data) {
  const url = ApiUrl("v2/driver_pay_adjustments");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    { message: "message" },
    true
  );
}


export function getDriverAdjustmentInfo(orderIds) {
  
  const url = ApiUrl(`v2/driver_pay_adjustments?customer_order_ids=${orderIds}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      driver_pay_adjustments: "driver_pay_adjustments"
    },
  });
}

export function deleteDriverAdjustmentInfo(id, organization_id) {
  const url = ApiUrl(`v2/driver_pay_adjustments/${id}`);
  return Call(
    "DELETE",
    url,
    { organization_id },
    ParseGeneralResponse,
    { message: "message" },
    true
  );
}
export function saveDriverAdjustmentInfo(data) {
  const url = ApiUrl("v2/driver_pay_adjustments");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    { message: "message" },
    true
  );
}
export function saveInvoiceRemark(data) {
  const url = ApiUrl("v2/account_invoices/add_or_update_remarks");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    { message: "message" },
    true
  );
}

export function fetchInvoiceRemarks(payload) {
  const { organization_id, account_invoice_id} = payload
  const url = ApiUrl(
    `v2/account_invoices/get_invoice_remarks?organization_id=${organization_id}&account_invoice_id=${account_invoice_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoiceRemarks: "remarks",
    },
  });
}

export function fetchReBillingList(data) {
  const { account_invoice_id, organization_id } = data;
  const url = ApiUrl(
    `v2/rebillings/billing_orders_list?organization_id=${organization_id}&account_invoice_id=${account_invoice_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      customerBillings: "customer_billings",
    },
  });
}

export function fetchReBillingItems(data) {
  const { customer_order_id, account_invoice_id, organization_id } = data;
  const url = ApiUrl(
    `v2/rebillings/get_customer_order_billing?organization_id=${organization_id}&account_invoice_id=${account_invoice_id}&customer_order_id=${customer_order_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      reBillItems: "invoice_lines",
    },
  });
}


export function createReBill(data) {
  const url = ApiUrl("v2/rebillings/create_rebilling");
  const method = "put";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { paymentDetails: "payment_details" } },
    false
  );
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import I18n from "../../common/I18n";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import { Modal, Tooltip, Icon, Input, Row, Col, Button } from "antd";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import TypeOfOrder from "./TypeOfOrder";
import AppConfig from "../../config/AppConfig";
import Copyable from "../common/Copyable";
import LabelInfo from "../configurations/LabelInfo";
import FormattedErrors from "../common/FormattedErrors";
import ErrorComponent from "../common/ErrorComponent";
import { getFormattedWeight, getWeightUnits } from "../../helpers/common";
import { AccountUnitsContext } from "../../context/AccountUnitsContext";
import { OrgContext } from "../../context/OrgContext";

const SelectedOrdersList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const gridApiRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const { orderSelectedInfo, setOrderSelectedInfo, getOrderKeys } =
    useContext(OrderConfigContext);
  const accountUnitContext = useContext(AccountUnitsContext);
  const { orgSettings,currentOrg } = useContext(OrgContext);
  const {userContext} = props;
  const toRoundDecimals = Number(_.get(userContext, "orgRules.org_billing_rules.round_off_decimals", false));
  useEffect(() => {
    if (props.errorOrders?.length > 0) {
        setVisible(true)
    }
  }, [props.errorOrders]);

  const handleRemove = (id) => {
    const {currentGridRef} =  props;
    const newOrders = orderSelectedInfo.filter((o) => o.id !== id);
    if(newOrders?.length === 0){
      if(currentGridRef){
        currentGridRef.deselectAll();
      }
    } else {
      if(currentGridRef){
      const nodes = currentGridRef.getSelectedNodes();
      const nodesToSelect = nodes.filter(node => node.data.id === id);
      if(nodesToSelect.length > 0){
          currentGridRef.setNodesSelected({
            nodes: nodesToSelect,
            newValue : false,
          });
        }
      }
    }
    setOrderSelectedInfo(newOrders);
    setRowData(newOrders);
  };

  const onGridReady = useCallback(
    (params) => {
      gridApiRef.current = params.api;
      setRowData(orderSelectedInfo);
    },
    [orderSelectedInfo]
  );

  const defaultColDef = useMemo(
    () => ({
      width: 170,
      filter: true,
    }),
    []
  );

  const columnDefs = [
    {
      headerName: "",
      pinned: "left",
      lockPosition: "left",
      field: "remove",
      width: 50,
      rowDrag: false,
      suppressMenu: true,
      cellRenderer: (params) => (
        <Tooltip title="Remove">
          <div onClick={(e) => handleRemove(params.data.id)}>
            <Icon type="minus-circle" className="fontSize20" />
          </div>
        </Tooltip>
      ),
    },
    {
      field: "customer_order_number",
      headerName: "Order #",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params) => (
        <div>
          {params.data.customer_order_number}
          &nbsp;
          <Copyable text={params.data.customer_order_number} />{" "}
        </div>
      ),
    },
    {
      field: "type_of_order",
      headerName: "Order Type",
      width: 110,
      suppressMenu: true,
      cellRenderer: (params) => (
        <TypeOfOrder
          order={params.data}
          orderTypeKey="type_of_order"
          relatedOrderKey="related_order"
          // placement="top"
          showBadge={true}
        />
      ),
    },
    { field: "status", headerName: "Status", width: 90, suppressMenu: true,
    },
    
    {
      headerName: "Weight",
      field: "weight",
      width: 120,
      suppressMenu: true,
      cellRenderer: (params) => getFormattedWeight(params.data.weight, params.data.weight_in_kgs, toRoundDecimals),
      cellStyle: {
      textAlign: 'right',
    },
    },
    { field: "hawb", headerName: "HAWB", suppressMenu: true, },
    { field: "mawb", headerName: "MAWB", suppressMenu: true, },
  ];

  const getRowStyle = useCallback(
    (params) => {
      if ([].includes(params.data.id)) {
        return { backgroundColor: "#eef2fb" };
      }
      return null;
    },
    [orderSelectedInfo]
  );

  const renderModal = () => {
    const containerStyle = { width: "600px", height: "100%" };
    const gridStyle = { width: "600px", height: "40vh" };
    return (
      <Modal
        title={null}
        visible={visible}
        footer={null}
        closable={false}
        maskClosable={false}
        className="route-start-modal orders-list-modal"
        //onClose={() => setVisible(false)}
      >
        <div className="assignOrders">
         <Row>
            <Col xs={20}>
              <span className="title">
                {`Selected (${orderSelectedInfo.length}) Orders`}
              </span>
            </Col>
            <Col xs={4} className="alignRight">
              <Icon
                //type="primary"
                style={{ marginLeft: 0, fontSize: 30 }}
                onClick={() => {
                    setVisible(false)
                    props.setErrorOrders([])
                }}
                type="close-circle"
                className="cursorPointer"
              />
            </Col>
         </Row>
         
          {
            props.errorOrders?.length > 0 && 
            <ErrorComponent
                errors={[`${props.errorOrders.join(', ')}`]} 
            /> 
          }
          <Row type="flex" justify="end" className="marginTop10">
            <Col>
              <Input
                prefix={<Icon type="search" />}
                size="small"
                suffix={
                  <LabelInfo
                    title="This searches in current displayed data"
                    style={{ display: "inline-block" }}
                  />
                }
                placeholder="Quick Filter"
                onChange={(event) =>
                  gridApiRef.current.setQuickFilter(event.target.value)
                }
                style={{
                  width: "auto",
                  display: "inline-block",
                  marginRight: 8,
                }}
              />
            </Col>
          </Row>
          <div style={containerStyle} className="marginTop10">
            <div style={gridStyle} className={"ag-theme-quartz"}>
              <AgGridReact
                rowHeight={35}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                onGridReady={onGridReady}
                suppressMovableColumns={true}
                // icons={{
                //   menu: '<Icon type="user" />',
                // }}
                //   onRowDragEnd={dragStopped}
                //   onRowDragEnter={onRowDragStart}

                getRowStyle={getRowStyle}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  // Render the Popover and the button with a custom class name
  return (
    <>
      <span
        className="popover-button textBold"
        onClick={() => {
          setVisible(true);
        }}
      ><u className="cursorPointer">{`${orderSelectedInfo.length} Orders`}</u></span>
      {visible && renderModal()}
    </>
  );
};

SelectedOrdersList.propTypes = {
  record: PropTypes.shape().isRequired,
  setIsItemVisible: PropTypes.func,
};
SelectedOrdersList.defaultProps = {
  setIsItemVisible: () => {},
};

export default SelectedOrdersList;

import React from 'react';
import { Alert } from '../../common/UIComponents';
import I18n from '../../common/I18n';

const Errors = errors => (
  <div>
    { errors.map(err => (
      <div key={err}>{err}</div>
    ))}
  </div>
);

const FormErrors = (errors, title = I18n.t('errors.errors')) => {
  const formErrors = errors || [];
  if (formErrors.length < 1) return null;
  return (
    <Alert
      style={{ marginBottom: 15, marginTop: 15 }}
      message={title}
      description={Errors(formErrors)}
      type='error'
      showIcon
    />
  );
};

export default FormErrors;

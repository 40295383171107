import React, { memo, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import "core-js/es6";
import "antd/dist/antd.css";
import App, { FleetLayout } from './FleetLayout.jsx';
import ProtectedRoute from './ProtectedRoute';
import AuthForm from './components/Auth/AuthForm';
import { isTokenValid } from './api/ApiUtils.js';
import AppContextProvider from './context/AppContextProvider';
import { AppLoadingScreen } from './components/common/LogisticsLoadingScreen';

const FleetEnableApp = () => {
  const [ isLoading, setIsLoading ] = useState(true);

  const isUserAuthenticated = () => {
    const token = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null;
    if (token) {
      const isTokenValidResult = isTokenValid(token);
      if (isTokenValidResult) {
        return true;
      }
    }
    localStorage.clear();
    return false;
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const url = new URL(window.location.href);
  const searchParams = url.pathname === "/forgot_password" ? new URLSearchParams(url.search) : new URLSearchParams("");
  const reset_password_token = searchParams.get('reset_password_token');

  return (
    <Router>
      <Switch>
        <Route
          path="/login"
          render={() => (
            !isLoading && !isUserAuthenticated() ? (
              <AuthForm />
            ) : (
              reset_password_token ? (
                <AuthForm resetPasswordToken={reset_password_token} />
              ) : (
                <Redirect to="/" />
              )
            )
          )}
        />

        <ProtectedRoute
          isAuthenticated={isUserAuthenticated}
          path="/"
          component={() => (
            !isLoading ? (
              <AppContextProvider>
                <DndProvider backend={ HTML5Backend }>
                  <App />
                </DndProvider>
              </AppContextProvider>
            ) : (
              <AppLoadingScreen />
            )
          )}
        />
      </Switch>
    </Router>
  );
};

export default FleetEnableApp;


import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import 'antd/dist/antd.css';
import { Col, Row, Spin, Alert, TextArea, Collapse, Radio, Input, Checkbox, Select, Button } from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import { fetchSurveyResponses } from '../../api/SurveyApi';
import Gallery from '../common/Gallery';
import { renderAlertMessage } from '../../helpers/common';
import ReactToPrint from 'react-to-print';
import PODEmailsModal from '../Tasks/PODEmailsModal';

const tooltips = ['Very Dissatisfied', 'Dissatisfied', 'Neutral', 'Satisfied', 'Very Satisfied'];
const { Panel } = Collapse;
const singleValueFields = ['text', 'yes_no', 'textbox', 'email_field', 'phone_field', 'single_choice', 'SIGNATURE'];
class SurveyResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailProgress: false,
      emailSendModalVisible: false,
      inProgress: false,
      answers: {},
      questionaries: [],
      currentOrderDetails: {
        customer_first_name: '',
      },
      responses: [],
      surveyDetails: {},
      responsesList: [],

      first_name: '',
      orderno: '',
      image: '',
      order_id: this.props.surveyId ? this.props.surveyId : '',
      location_id: this.props.surveyLocationId ? this.props.surveyLocationId : null ,
      isSubmitted: false,
      trimmedDataURL: null,
      galleryImages: [],
    };
  }

  componentDidMount() {
    this.getDetails(this.state.order_id, this.state.location_id);
  }

  getIsolationAnswers = (question, index, disabled = false) => {
    switch (question.option_type) {
    case 'text': return (
      <Row>
        <Col>
          <Input
            type='text'
            placeholder='Answer'
            style={{ marginRight: 4 }}
            value={this.state.answers[question.question_id] ? this.state.answers[question.question_id] : ''}

          />
        </Col>
      </Row>);
    case 'textbox': return (
      <Row>
        <Col>
          <TextArea
            type='text'
            placeholder='Answer'
            style={{ marginRight: 4 }}
            value={this.state.answers[question.question_id] ? this.state.answers[question.question_id] : ''}
  
          />
        </Col>
      </Row>);
      // case 'email_field': return (
      //   <Row>
      //     <Col>
      //       <Input
      //         type='text'
      //         placeholder='Enter Email'
      //         style={{ marginRight: 4 }}
      //         value={this.state.answers[question.id] || ''}
      //         onChange={e => this.handleChange(question.id, e.target.value)}

      //       />
      //     </Col>
      //   </Row>);
      // case 'phone_field': return (
      //   <Row>
      //     <Col>
      //       <MobileInput
      //         value={this.state.answers[question.id] || ''}
      //         handleInputChange={(value, data) => {
      //           this.handleChange('phone', value);
      //         }}

      //       />
      //     </Col>
      //   </Row>);
      // case 'yes_no': return (
      //   <Row>
      //     <Col>
      //       <Radio.Group
      //         value={this.state.answers[question.id] || ''}
      //         onChange={e => this.handleChange(question.id, e.target.value)}
      //       >
      //         <Radio value >Yes</Radio>
      //         <Radio value={false} >No</Radio>
      //       </Radio.Group>
      //     </Col>
      //   </Row>);
      // case 'address_field': return (
      //   <Row>
      //     <Col>
      //       <AddressForm
      //         autoComplete
      //         address={this.state.answers[question.id] || {}}
      //         onChange={(element, value, object) => this.handleOnAddressChange(element, value, object, question.id)}

      //       />
      //     </Col>
      //   </Row>);
    case 'single_choice': return (
      <Row>
        <Radio.Group
          value={this.state.answers[question.question_id] || ''}

        >
          {
            question.options.map((qoption, qindex) => (
              <Col xs={24} sm={24} md={8} lg={16} key={qindex}>
                <Radio value={qoption.option_id} >{qoption.option_value}</Radio>
              </Col>))
          }
        </Radio.Group>
      </Row>);
    case 'multiple_choice': return (
      <Row>
        <Checkbox.Group
          value={this.state.answers[question.question_id]}

        >
          {
            question.options.map((qoption, qindex) => (
              <Col xs={24} sm={24} md={8} lg={16} key={qindex}>
                <Checkbox
                  value={qoption.option_id}
                /> {qoption.option_value}&nbsp;&nbsp;
              </Col>))
          }
        </Checkbox.Group>
      </Row>);
    case 'dropdown': return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={6}>
          <Select
            showSearch
            value={this.state.answers[question.question_id]}
            style={{ width: '100%' }}
            placeholder="Select"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={e => this.handleChange(question.question_id, e)}

          >
            {
              question.options.map((qoption, qindex) => (
                <Select.Option key={qoption.option_id} value={qoption.option_id}>{qoption.opt_value}</Select.Option>
              ))
            }
          </Select>
        </Col>
      </Row>);
    case 'multi_select_dropdown': return (
      <Row>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Select
            mode="multiple"
            value={this.state.answers[question.question_id]}
            showSearch
            style={{ width: '100%' }}
            placeholder="Select"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={e => this.handleChange(question.question_id, e)}

          >
            {
              question.options.map(qoption => (
                <Select.Option key={qoption.id} value={qoption.id}>{qoption.opt_value}</Select.Option>
              ))
            }
          </Select>
        </Col>
      </Row>);
    case 'SIGNATURE': return (
      <Row>
        <Col xs={24}>
          <img
            src={this.state.answers[question.question_id]}
            alt="Signature"
            width="500px"
            onClick={() => this.openLightbox(question.question_id)}
          />
        </Col>
      </Row>);

    default: break;
    }
  }

  getDetails = (id, loc_id = null) => {
    this.setState({ inProgress: true });
    fetchSurveyResponses(id, loc_id)
      .then((result) => {
        if (result.success) {
          const answers = Object.assign({}, this.state.answers);
          let answer = '';
          if (result.survey_details) {
            const survey_questions = _.get(result, 'survey_details.order_responses', []);
            const questions = [];
            survey_questions.forEach((question) => {
              const options = [];
              if (singleValueFields.includes(question.option_type)) {
                answer = question.responses.length > 0 ? question.responses[0] : '';
              } else {
                answer = question.responses;
              }
              answers[question.question_id] = answer;
              question.options.forEach((option, index) => {
                if (option.length > 0) {
                  options.push(option[0]);
                }
              });

              questions.push({
                question_id: question.question_id,
                content: question.content,
                option_type: question.option_type,
                options,
                answer,

              });
            });
            this.setState({
              questionaries: questions,
              answers,
              inProgress: false,
            }, () => {
            });
          } else {
            alertMessage('Survey not completed', 'error', 10);
            this.setState({ inProgress: false });
          }
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    });
  }

  // eslint-disable-next-line react/sort-comp
  openLightbox = (question_id) => {
    this.setState({
      galleryImages: [{ src: this.state.answers[question_id] }],
      lightboxIsOpen: true,
    }, () => {
    });
  }

  setRef = ref => (this.componentRef = ref);

  openSendEmailModal = () => {
    this.setState({
      emailSendModalVisible: true,
    });
  }

  closeSendEmailModal = () => {
    this.setState({
      emailSendModalVisible: false,
    });
  }

  sendFormToEmail = (emailList, description) => {
    this.setState({ emailProgress: true });

    setTimeout(() => {
      this.setState({ emailProgress: false });
      console.log("🚀 ~ FormResponse ~ description:", description)
      console.log("🚀 ~ FormResponse ~ emailList:", emailList)
      closeSendEmailModal();
    }, 5000);

  }

  render() {
    const { currentOrderDetails } = this.state;
    const item_name = currentOrderDetails.item_name ? currentOrderDetails.item_name : 'Item';
    const customer_name = currentOrderDetails.customer_first_name ? currentOrderDetails.customer_first_name + (currentOrderDetails.customer_last_name ? ` ${currentOrderDetails.customer_last_name}` : '') : I18n.t('general.customer');

    return (
      <div className='content-outer'>
        <div className='content surveyResponses'>
          <Spin spinning={this.state.inProgress} delay={1000}>

            <Fragment>
              {this.state.questionaries.length > 0 ?
                <div>

                  <Row
                    style={{ marginBottom: "1rem" }}
                    type="flex"
                    justify="center"
                    gutter={10}
                  >
                    <Col>
                      <ReactToPrint
                        trigger={() => (
                          <Button type="primary" icon="printer" size={"small"}>
                            Print
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />
                    </Col>
                    {/* <Col>
                      <Button
                        type="primary"
                        icon="mail"
                        size={"small"}
                        onClick={this.openSendEmailModal}
                      >
                        Send To Email
                      </Button>
                    </Col> */}
                  </Row>
                  <Row ref={this.setRef}>
                <Col className="questionaries">
                  {
                    this.state.questionaries.map((question, index) => (
                      <Collapse
                        defaultActiveKey={['1']}
                        style={{ height: 'auto', marginBottom: 20 }}
                        accordion={false}
                        className="marginBottom20"
                        key={`collapse${index}`}
                      >
                        <Panel
                          showArrow={false}
                          header={`${index + 1}. ${question.content}`}
                          key={1}
                        >
                          {
                            this.getIsolationAnswers(question, index)
                          }
                        </Panel>

                      </Collapse>))
                  }

                </Col>
                  </Row>
                </div>
                :
              <Alert message="Survey not completed" type="warning" />
              }
            </Fragment>

          </Spin>

        </div>
        {
          this.state.lightboxIsOpen && this.state.galleryImages.length > 0 && <Gallery
            images={this.state.galleryImages}
            onClose={this.closeLightbox}
          />
        }
        {
          this.state.emailSendModalVisible &&
          <PODEmailsModal
            title={'Survey Email'}
            description={'Please find the attached surveys'}
            closeModal={this.closeSendEmailModal}
            onSend={this.sendFormToEmail}
            muteButton={this.state.emailProgress}
          />
        }
      </div >
    );
  }
}

export default SurveyResponse;

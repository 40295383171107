import React, { Fragment } from "react";
import PropTypes from "prop-types";
import AssignableOrdersList from "./AssignableOrdersList";
import TruckLoadProgress from "./TruckLoadProgress";
import { Button, Row, Col, Tooltip, Icon } from "antd";
import { pickLocsFromRouteOrders } from "../../helpers/orders";
import { calculateTotalWeight } from "../dispatches/helpers";
import { isEmpty, randomNumber, alertMessage, lockIconTooltip } from "../../common/Common";
import _ from "lodash";
import I18n from "../../common/I18n";
import { changeLockOfMultipleRoute } from "../../api/Routes";
import { momentTime } from "../../helpers/date_functions";
import DisplayTime from "../common/DisplayTime";
import { IntransitTruckIcon } from "../../common/CustomIcon";
import { getWeightUnits } from "../../helpers/common";

class DriverRouteInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [...props.ordersInfo],
      totalOrders: [],
      updatedOrders: [],
    };
  }

  componentDidMount() {
    this.processOrders()
  }

  setUpdatedOrders = (updatedOrders) => {
    this.setState({
      updatedOrders,
    })
  }

  componentDidUpdate(prevProps) {

    // this.props.ordersInfo !== prevProps.ordersInfo
    if (!_.isEqual(this.props.ordersInfo, prevProps.ordersInfo)) {
      this.setState({ orders: [...this.props.ordersInfo] }, () => {
        this.processOrders()
      });
    }
    if (!_.isEqual(this.props.route, prevProps.route)) {
      this.processOrders()
    }
  }

  processOrders = () => {
    const  { route } = this.props;
    const { orders } = this.state;
    const locWiserouteOrders = pickLocsFromRouteOrders(route, "id", false);
    const totalOrders = _.uniqBy(
      [].concat(locWiserouteOrders, orders),
      "location_id"
    );
    this.setState({
      totalOrders,
    })
    
  }

  handleRemoveOrder = (order_id, location_id) => {
    // this.setState((prevState) => ({
    //   totalOrders: prevState.totalOrders.filter((o) => o.id !== order_id && o.related_order !== order_id ),
    // }));
    const totalOrders = this.state.updatedOrders.filter(o => o.id !== order_id && o.related_order !== order_id)
    this.setState({
      totalOrders,
    })
  };

  handleLockChange = async () => {
    const { route, currentDriver, setIsUnlocked } = this.props;
    try {
      const result = await changeLockOfMultipleRoute([route.route_id], false);
      if (result.success) {
        const successMsg = result.message
          ? result.message
          : I18n.t("messages.lock_changed");
        setIsUnlocked(true);
        this.props.refreshRouteDetails(currentDriver?.driver_id, route.route_id);
        alertMessage(successMsg);
      } else {
        alertMessage(result.error, "error", 10);
      }
    } catch (error) {
      console.error(
        "An error occurred while changing the lock of multiple routes:",
        error
      );
    }
  };
  

  renderRouteDetails = () => {
    const {
      route,
      driverDetails,
      newRouteTime,
      organizationSettings,
      setUpdateKey,
      driverRouteInfo,
      currentDriver,
      updateKey,
      assignOrders,
      createAndAssignRoute,
      createAndAssignMultiStopRoute,
      currentUser,
      isUnlocked,
      setIsUnlocked,
      selectedWh
    } = this.props;

    const { orders, totalOrders, updatedOrders } = this.state;

    const hasTruckAssigned = !!driverDetails?.vehicle_weight;
    const truckCapacity = driverDetails?.vehicle_weight || "";
    const currentRouteStopsDetail =
      route?.stop_details?.length > 0 ? route.stop_details : [];
    const routeOrders = currentRouteStopsDetail.flatMap(
      (item) => item.stop_orders
    );
    const currentCarriedOrders = routeOrders.filter(
      (route) =>
        ["PENDING", "UNALLOCATED"].includes(route.stop_status) ||
        (["R", "TR"].includes(route.type_of_order) &&
          route.stop_status === "COMPLETED")
    );
    const currentCarriedWeight =
      currentCarriedOrders?.length > 0
        ? calculateTotalWeight(currentCarriedOrders)
        : "";
    const selectedOrdersWeight =
      orders?.length > 0 ? calculateTotalWeight(orders) : "";
    const selectedOrders = orders?.length > 0 ? _.uniq(orders.map((order) => order.id)) : [];
    const requireValidation =
      organizationSettings.capacity_optimization === "true";
    const showLockIcon = currentUser.id !== route?.is_locked_by && route?.is_locked
    const newOrders = _.uniq(totalOrders.filter(o => isEmpty(o.stop_id)))

    return (
      <Fragment>
        <Row type="flex">
          <Col xs={driverRouteInfo?.length > 0 ? 18 : 14} className="alignLeft">
            <Row gutter={16}>
              <Col className="route_no">
                {currentDriver.employee_code} &nbsp;
                {["DISPATCHED"].includes(route.route_status) && (
                  <>
                    <IntransitTruckIcon /> In Transit
                  </>
                )}
              </Col>
            </Row>
            <Row type="flex" gutter={16}>
              <Col className="vehicle_no">
                Vehicle: {currentDriver.vehicle_name}
              </Col>
              {/* {driverRouteInfo?.length > 0 && <Row type="flex" gutter={16} className="marginTop12">
              <Col className="start_time_label"><Icon type="clock-circle" style={{ color: '#33393D' }}/> &nbsp;&nbsp;Route Start Time:</Col>
              <Col className="start_time_value">
                <RouteTimeWindow
                  driverDetails={driverDetails}
                  selectedOrders={orders}
                  showIcon={false}
                  currentDateTime={route.route_start_time}
                  currentRouteDetails={route}
                  refreshDetails={driver_id => {
                    fetchDriverRoutes(driver_id, route.route_id)
                    // updateOrders([])
                  }}
                  orderRefreshCall={orderRefreshCall}
                  requireValidation={requireValidation}
                  timeZoneId={currentWarehouse?.timeZoneId || ''}
                >
                  <DisplayTime
                    dateTimeString={route.route_start_time}
                    displayWithDate={true}
                    isUTC={false}
                    timeZoneId={currentWarehouse?.timeZoneId || ''}
                  />
                  &nbsp;&nbsp;
                  <span>
                    <img
                      src={editIcon}
                      style={{ marginTop: -4, height: 13, width: 13 }}
                    />
                  </span>
                </RouteTimeWindow>
              </Col>
            </Row>} */}
            </Row>
            
            { route.route_start_time &&
               <div className="marginTop10">
                <span className="start_time_value">
                   <Row type="flex">
                   <Col className="start_time_label"><Icon type="clock-circle" style={{ color: '#33393D' }}/> &nbsp;&nbsp;Time:&nbsp;&nbsp;</Col>
                    <Col>
                        <DisplayTime
                            dateTimeString={route.route_start_time}
                            displayWithDate={true}
                            isUTC={true}
                            timeZoneId={selectedWh?.timeZoneId || ''}
                            
                        />
                    </Col>
                    <Col>-</Col>
                    <Col>
                      <DisplayTime
                        dateTimeString={route.route_completion_time}
                        displayWithDate={true}
                        isUTC={true}
                        timeZoneId={selectedWh?.timeZoneId || ''}
                      />
                    </Col>
                  </Row>
                </span>
               </div>
              }
          </Col>
          {orders.length > 0 && (
            <Col
              xs={driverRouteInfo?.length > 0 ? 6 : 10}
              className="alignRight payload_value"
            >
              {showLockIcon && !isUnlocked ? (
                <div class="button-container">
                  <button class="unlock-button">
                    <span class="icon">
                      {lockIconTooltip({ iconClassName: "intransit-truck-icon legs_info", userName:route?.locked_by_name, iconStyle:{color: "#576EC1"}})}
                    </span>{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      // onClick={() => setIsUnlocked(true)}
                      onClick={this.handleLockChange}
                    >
                      Unlock
                    </span>
                  </button>
                </div>
              ) : (
                 newOrders.length > 0 ? <Button
                  onClick={() => {
                    // setUpdateKey(randomNumber());
                    // driverRouteInfo?.length > 0 ? 
                    // assignOrders(updatedOrders, route, requireValidation) :
                    // createAndAssignRoute(newRouteTime, updatedOrders, driverDetails,requireValidation)
                    const isMultiStopOrder = this.state.orders.some(order => order.type_of_order === "MS");
                    if (driverRouteInfo?.length > 0) {
                      if (isMultiStopOrder) {
                        createAndAssignMultiStopRoute(newRouteTime, updatedOrders, driverDetails, requireValidation);
                      } else {
                        assignOrders(updatedOrders, route, requireValidation);
                      }
                    } else {
                      if (isMultiStopOrder) {
                        createAndAssignMultiStopRoute(newRouteTime, updatedOrders, driverDetails, requireValidation);
                      } else {
                        createAndAssignRoute(newRouteTime, updatedOrders, driverDetails, requireValidation);
                      }
                    }
                  }}
                >
                  Assign {newOrders.length} Order(s)
              </Button> : <Fragment />
              )}
            </Col>
          )}
        </Row>
        {hasTruckAssigned && (
          <>
            <Row type="flex" className="marginTop10">
              <Col xs={12} className="alignLeft payload_text">
                Estimated Max Payload Along Route
              </Col>
              <Col xs={12} className="alignRight payload_value">
                <span> {currentCarriedWeight + selectedOrdersWeight} </span> /{" "}
                {truckCapacity} {getWeightUnits({ currentOrg: this.props.currentOrg })}
              </Col>
            </Row>
            <Row>
              <Col>
                <TruckLoadProgress
                  currentCarriedWeight={currentCarriedWeight}
                  selectedOrdersWeight={selectedOrdersWeight}
                  truckCapacity={truckCapacity}
                  style={{ height: 18, marginBottom: 10 }}
                  showInfo={false}
                />
              </Col>
            </Row>
          </>
        )}
        <AssignableOrdersList
          orders={totalOrders}
          selectedOrders={selectedOrders}
          updateKey={updateKey}
          // assignOrdersToRoute={(rowData) =>
          //   driverRouteInfo?.length > 0
          //     ? assignOrders(rowData, route, requireValidation)
          //     : createAndAssignRoute(
            assignOrdersToRoute={(rowData) => {
              if (driverRouteInfo?.length > 0) {
                assignOrders(rowData, route, requireValidation);
              } else {
                if (rowData?.order_label === "MS") {
                  createAndAssignMultiStopRoute(newRouteTime, rowData, driverDetails, requireValidation);
                } else {
                  createAndAssignRoute(
                  newRouteTime,
                  rowData,
                  driverDetails,
                  requireValidation
                )
          }
        }
      }}
          handleRemoveOrder={this.handleRemoveOrder}
          isReadOnly={newOrders.length === 0 || showLockIcon}
          setUpdatedOrders={this.setUpdatedOrders}
          ordersSecHeight={this.props.ordersSecHeight}
        />
      </Fragment>
    );
  };

  render() {
    return <div>{this.renderRouteDetails()}</div>;
  }
}

DriverRouteInfo.propTypes = {
  route: PropTypes.shape({}).isRequired,
  driverDetails: PropTypes.object.isRequired,
  newRouteTime: PropTypes.string,
  organizationSettings: PropTypes.object.isRequired,
  setUpdateKey: PropTypes.func.isRequired,
  ordersInfo: PropTypes.array,
  driverRouteInfo: PropTypes.array,
  currentDriver: PropTypes.object.isRequired,
  updateKey: PropTypes.number.isRequired,
  isUnlocked: false,
  setIsUnlocked: PropTypes.func
};

DriverRouteInfo.defaultProps = {
  ordersInfo: [],
  driverRouteInfo: [],
  newRouteTime: "",
  isUnlocked: false,
  setIsUnlocked: () => {}
};

export default React.memo(DriverRouteInfo);



// import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
// import PropTypes from "prop-types";
// import AssignableOrdersList from "./AssignableOrdersList";
// import TruckLoadProgress from "./TruckLoadProgress";
// import { Button, Row, Col } from "antd";
// import { pickLocsFromRouteOrders } from "../../helpers/orders";
// import { calculateTotalWeight } from "../dispatches/helpers";
// import IntransitTruckIcon from "../../common/IntransitTruckIcon";
// import { randomNumber } from "../../common/Common";
// import _ from "lodash";

// const DriverRouteInfo = ({
//   route,
//   driverDetails,
//   newRouteTime,
//   organizationSettings,
//   setUpdateKey,
//   ordersInfo,
//   driverRouteInfo,
//   currentDriver,
//   updateKey,
// }) => {
//   const [orders, setOrders] = useState([...ordersInfo]);

//   useEffect(() => {
//     if (ordersInfo) {
//       setOrders([...ordersInfo]);
//     }
//   }, [ordersInfo]);

//   const handleRemoveOrder = useCallback(
//     (order_id, location_id) => {
//       setOrders(orders.filter((o) => o.id !== order_id));
//     },
//     [orders]
//   );

//   const renderRouteDetails = useCallback(() => {
//     const hasTruckAssigned = !!driverDetails?.vehicle_weight;

//     const truckCapacity = useMemo(() => driverDetails?.vehicle_weight || "", [driverDetails]);
//     const currentRouteStopsDetail = useMemo(() => (route?.stop_details?.length > 0 ? route.stop_details : []), [route]);
//     const routeOrders = useMemo(() => currentRouteStopsDetail.flatMap((item) => item.stop_orders), [currentRouteStopsDetail]);
//     const currentCarriedOrders = useMemo(
//       () => routeOrders.filter(
//         (route) =>
//           ["PENDING", "UNALLOCATED"].includes(route.stop_status) ||
//           (["R", "TR"].includes(route.type_of_order) &&
//             route.stop_status === "COMPLETED")
//       ),
//       [routeOrders]
//     );
//     const currentCarriedWeight = useMemo(() => (currentCarriedOrders?.length > 0 ? calculateTotalWeight(currentCarriedOrders) : ""), [currentCarriedOrders]);
//     const selectedOrdersWeight = useMemo(() => (orders?.length > 0 ? calculateTotalWeight(orders) : ""), [orders]);
//     const selectedOrders = useMemo(() => (orders?.length > 0 ? _.uniq(orders.map((order) => order.id)) : []), [orders]);
//     const locWiserouteOrders = useMemo(() => pickLocsFromRouteOrders(route, "id", false), [route]);
//     const requireValidation = useMemo(() => organizationSettings.capacity_optimization === "true", [organizationSettings]);
//     const totalOrders = useMemo(() => _.uniqBy([].concat(locWiserouteOrders, orders), "location_id"), [locWiserouteOrders, orders]);

//     return (
//       <Fragment>
//         <Row type="flex">
//           <Col xs={driverRouteInfo?.length > 0 ? 18 : 14} className="alignLeft">
//             <Row type="flex" gutter={16}>
//               <Col className="route_no">
//                 {currentDriver.employee_code} &nbsp;
//                 {["DISPATCHED"].includes(route.route_status) && (
//                   <>
//                     <IntransitTruckIcon /> In Transit
//                   </>
//                 )}
//               </Col>
//             </Row>
//             <Row type="flex" gutter={16}>
//               <Col className="vehicle_no">
//                 Vehicle: {currentDriver.vehicle_name}
//               </Col>
//             </Row>
//           </Col>
//           {orders.length > 0 && (
//             <Col
//               xs={driverRouteInfo?.length > 0 ? 6 : 10}
//               className="alignRight payload_value"
//             >
//               <Button
//                 onClick={() => {
//                   setUpdateKey(randomNumber());
//                 }}
//               >
//                 Assign {orders.length} Order(s)
//               </Button>
//             </Col>
//           )}
//         </Row>
//         {hasTruckAssigned && (
//           <>
//             <Row type="flex" className="marginTop10">
//               <Col xs={12} className="alignLeft payload_text">
//                 Estimated Max Payload Along Route
//               </Col>
//               <Col xs={12} className="alignRight payload_value">
//                 <span> {currentCarriedWeight + selectedOrdersWeight} </span> /{" "}
//                 {truckCapacity} lbs
//               </Col>
//             </Row>
//             <Row>
//               <Col>
//                 <TruckLoadProgress
//                   currentCarriedWeight={currentCarriedWeight}
//                   selectedOrdersWeight={selectedOrdersWeight}
//                   truckCapacity={truckCapacity}
//                   style={{ height: 18, marginBottom: 10 }}
//                   showInfo={false}
//                 />
//               </Col>
//             </Row>
//           </>
//         )}
//         <AssignableOrdersList
//           orders={totalOrders}
//           selectedOrders={selectedOrders}
//           updateKey={updateKey}
//           assignOrdersToRoute={(rowData) =>
//             driverRouteInfo?.length > 0
//               ? assignOrders(rowData, route, requireValidation)
//               : createAndAssignRoute(
//                   newRouteTime,
//                   rowData,
//                   driverDetails,
//                   requireValidation
//                 )
//           }
//           handleRemoveOrder={handleRemoveOrder}
//         />
//       </Fragment>
//     );
//   }, [
//     driverDetails,
//     route,
//     driverRouteInfo,
//     currentDriver,
//     setUpdateKey,
//     newRouteTime,
//     organizationSettings,
//     updateKey,
//     handleRemoveOrder,
//   ]);

//   return <div>{renderRouteDetails()}</div>;
// };

// DriverRouteInfo.propTypes = {
//   route: PropTypes.shape({}).isRequired,
//   driverDetails: PropTypes.object.isRequired,
//   newRouteTime: PropTypes.string,
//   organizationSettings: PropTypes.object.isRequired,
//   setUpdateKey: PropTypes.func.isRequired,
//   ordersInfo: PropTypes.array,
//   driverRouteInfo: PropTypes.array,
//   currentDriver: PropTypes.object.isRequired,
//   updateKey: PropTypes.number.isRequired,
// };

// DriverRouteInfo.defaultProps = {
//   ordersInfo: [],
//   driverRouteInfo: [],
//   newRouteTime: "",
// };

// export default React.memo(DriverRouteInfo);

import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import SideMenu from './components/SideMenu';
import { withRouter } from 'react-router-dom';

const { Sider } = Layout;

function MemoizedSider(props) {
  const [collapsed, setCollapsed] = useState(true);
  const { changeMenuStatus, menuStatus, isClosedPinned } = props;

  return (
    <Sider
      style={{
        marginTop: 50,
        position: "fixed",
        height: "100vh",
        zIndex: 98,
        borderRight: `${!collapsed ? "2px solid rgb(241, 242, 245)" : "none"}`,
      }}
      className="sider"
      collapsed={menuStatus == "pinned" ? false : isClosedPinned == 'pinned' ? true : collapsed}
      onMouseEnter={() => setCollapsed(menuStatus == "pinned" || isClosedPinned == "pinned" ? null : false)}
      onMouseLeave={() => setCollapsed(menuStatus == "pinned" || isClosedPinned == 'pinned' ? null : true)}
    >
      <div style={{ paddingBottom: 45 }}>
        <SideMenu history={props.history} changeMenuStatus={changeMenuStatus} menuStatus={menuStatus} isClosedPinned={isClosedPinned} />
      </div>
    </Sider>
  );
}

export default withRouter(MemoizedSider);

import React, { Fragment } from "react";
import BaseList from "../BaseList";
import { checkNegitive, isEmpty } from "../../common/Common";
import equal from "fast-deep-equal";
import {
  Icon,
  Popconfirm,
  Tooltip,
  Input,
  Select,
  Row,
  Col,
  Button,
} from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import DeleteIcon from "../common/DeleteIcon";
import _ from "lodash";
import I18n from "../../common/I18n";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import GLCodeSelect from "../common/SelectDropdowns/GLCodeSelect";
import { generateReqDecimal } from "../../helpers/array_functions";

const { TextArea } = Input;

class TransportationChargesList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      uniqRows: [],
      inputValues: this.props.initialInputValues || [],
      glCodeError: "",
      descriptionError: "",
      unitsError: "",
      invoiceAmountError: "",
      unitPriceError: "",
      orderError: "",
      decimalPoints: window.localStorage.getItem("round_off_decimals"),
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    const { orderData } = props;
    const stepValue = generateReqDecimal(1, this.state.decimalPoints);
    this.columns = [
      {
        title: "#",
        key: "index",
        render: (text, record, index) => {
          const children = (
            <Fragment>
              <span>{index + 1}</span>
              <div className="textBold textPrimary">
                {record.is_fmp_applied === "true" ? "FMP" : ""}
              </div>
            </Fragment>
          );

          return {
            children,
            props: {},
          };
        },
        width: 40,
        className: "alignCenter",
      },
      {
        key: "invoice_name",
        title: I18n.t("general.description"),
        dataIndex: "invoice_name",
        render: (data, record, index) => {
          const isLastRecord = index === this.state.inputValues.length - 1;
          return (
            <Fragment>
              <Input
                value={this.state.inputValues[index]?.invoice_name || data}
                size="small"
                onChange={(e) =>
                  this.handleInputChange(e.target.value, index, "invoice_name")
                }
              />
              {!isEmpty(this.state.descriptionError) && isEmpty(data) ? (
                <Row>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {this.state.descriptionError}
                  </span>
                </Row>
              ) : (
                ""
              )}
            </Fragment>
          );
        },
        width: 300,
      },
      {
        key: "location_id",
        title: I18n.t("general.order_type"),
        dataIndex: "location_id",
        width: 100,
        render: (data, record, index) => (
          <Fragment>
            <Select
              showSearch
              value={this.state.inputValues[index]?.location_id || data}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) =>
                this.handleInputChange(value, index, "location_id")
              }
              size="small"
              className="width100Per"
            >
              {orderData?.locations?.map((orderType) => (
                <Select.Option
                  key={orderType.location_id}
                  value={orderType.location_id}
                >
                  {orderType.order_type}
                </Select.Option>
              ))}
            </Select>
            {!isEmpty(this.state.orderError) && isEmpty(data) ? (
              <Row>
                <span style={{ color: "red", fontSize: "13px" }}>
                  {this.state.orderError}
                </span>
              </Row>
            ) : (
              ""
            )}
          </Fragment>
        ),
      },
      {
        key: "gl_code",
        title: I18n.t("invoices.gl_code"),
        dataIndex: "gl_code",
        render: (data, record, index) => {
          const isLastRecord = index === this.state.inputValues.length - 1;
          return (
            <Fragment>
              <GLCodeSelect
                type={this.props.type}
                invoiceType={this.props.invoice_type}
                defaultValue={
                  record.gl_code || this.props.defaultTransportationChargeGLCode
                }
                data={this.props.glCodesList || []}
                value={this.state.inputValues[index]?.gl_code || data}
                onChange={(value) =>
                  this.handleInputChange(value, index, "gl_code")
                }
                selectKey={"gl_code"}
                selectValue={"gl_code"}
                selectedValue={"gl_code"}
                selectedCode={"description"}
                style={{ width: 120 }}
                showSearch={true}
                placeholder={I18n.t("invoices.gl_code")}
                dropdownMatchSelectWidth={false}
                size="small"
                isTemplateZoneBilling={this.props.isTemplateZoneBilling}
              />
              {!isEmpty(this.state.glCodeError) &&
              isEmpty(this.props.glCodesList) ? (
                <Row>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {this.state.glCodeError}
                  </span>
                </Row>
              ) : (
                ""
              )}
            </Fragment>
          );
        },
        width: 120,
        align: "center",
      },
      {
        key: "invoice_quantity",
        title: I18n.t("invoices.units"),
        dataIndex: "invoice_quantity",
        render: (data, record, index) => {
          const isLastRecord = index === this.state.inputValues.length - 1;
          return (
            <Fragment>
              <Input
                type="number"
                value={this.state.inputValues[index]?.invoice_quantity || data}
                min="1"
                size="small"
                style={{ width: 100 }}
                onChange={(e) =>
                  this.handleInputChange(
                    e.target.value,
                    index,
                    "invoice_quantity"
                  )
                }
              />
              {!isEmpty(this.state.unitsError) && isEmpty(data) ? (
                <Row>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {this.state.unitsError}
                  </span>
                </Row>
              ) : (
                ""
              )}
            </Fragment>
          );
        },
        align: "center",
      },
      {
        key: "unit_price",
        title: "Unit Price",
        dataIndex: "unit_price",
        render: (data, record, index) => {
          const isLastRecord = index === this.state.inputValues.length - 1;

          return (
            <Fragment>
              <Input
                type="number"
                value={this.state.inputValues[index]?.unit_price || data}
                size="small"
                style={{ width: 100 }}
                min="1"
                addonBefore={I18n.t("general.price_symbol")}
                onChange={(e) =>
                  this.handleInputChange(e.target.value, index, "unit_price")
                }
                onBlur={(e) =>
                  this.handleBlur(e.target.value, index, "unit_price")
                }
                step={stepValue}
              />
              {!isEmpty(this.state.unitPriceError) && isEmpty(data) ? (
                <Row>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {this.state.unitPriceError}
                  </span>
                </Row>
              ) : (
                ""
              )}
            </Fragment>
          );
        },
        align: "center",
      },
      {
        key: "invoice_value",
        title: I18n.t("invoices.amount"),
        dataIndex: "invoice_value",
        render: (data, record, index) => {
          const isLastRecord = index === this.state.inputValues.length - 1;
          return (
            <Fragment>
              <Input
                type="number"
                step={stepValue}
                value={this.state.inputValues[index]?.invoice_value || data}
                placeholder={I18n.t("invoices.amount")}
                size="small"
                style={{ width: 100 }}
                addonBefore={I18n.t("general.price_symbol")}
                onChange={(e) =>
                  this.handleInputChange(e.target.value, index, "invoice_value")
                }
                onBlur={(e) =>
                  this.handleBlur(e.target.value, index, "invoice_value")
                }
              />
              {!isEmpty(this.state.invoiceAmountError) && isEmpty(data) ? (
                <Row>
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {this.state.invoiceAmountError}
                  </span>
                </Row>
              ) : (
                ""
              )}
            </Fragment>
          );
        },
        align: "right",
      },
      {
        key: "actions",
        title: "",
        dataIndex: "isNew",
        render: (data, record, index) => {
          const isLastRecord = index === this.state.inputValues.length - 1;
          return (
            <Row>
              <Col span={8}>
                <Tooltip placement="topLeft" title="Delete">
                  <Popconfirm
                    placement="left"
                    title={I18n.t("messages.delete_confirm")}
                    onConfirm={() =>
                      this.handleDelete(
                        index,
                        this.props.invoice_type,
                        this.props.currentSection.order_number
                      )
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Icon
                      type="delete"
                      style={{ fontSize: 14, color: "red" }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Col>
              {isLastRecord && (
                <Col span={8}>
                  <Icon
                    type="plus-circle"
                    theme="filled"
                    onClick={() => this.handleAddItem(record, index)}
                    style={{ fontSize: 14 }}
                  />
                </Col>
              )}
            </Row>
          );
        },
        width: 100,
        align: "right",
      },
    ];
  };

  handleDelete = (index, itemType, orderNo) => {
    this.props.deleteClick(
      index,
      this.props.invoice_type,
      this.props.currentSection.order_number
    );
  };

  handleAddItem = (record, index) => {
    const { data } = this.props;
    // const prevRecord =
    //   this.state.inputValues[this.state.inputValues?.length - 1];
    // if (
    //   isEmpty(prevRecord.gl_code) ||
    //   isEmpty(prevRecord.invoice_name) ||
    //   isEmpty(prevRecord.invoice_quantity) ||
    //   isEmpty(prevRecord.invoice_value) ||
    //   isEmpty(prevRecord.unit_price) ||
    //   isEmpty(prevRecord.location_id)
    // ) {
    //   this.setState({
    //     glCodeError: isEmpty(prevRecord.gl_code) ? "Cannot be blank" : "",
    //     descriptionError: isEmpty(prevRecord.invoice_name)
    //       ? "Cannot be blank"
    //       : "",
    //     unitsError: isEmpty(prevRecord.invoice_quantity)
    //       ? "Cannot be blank"
    //       : "",
    //     invoiceAmountError: isEmpty(prevRecord.invoice_value)
    //       ? "Cannot be blank"
    //       : "",
    //     unitPriceError: isEmpty(prevRecord.unit_price) ? "Cannot be blank" : "",
    //     orderError: isEmpty(prevRecord.location_id) ? "Cannot be blank" : "",
    //   });
    // } else
    if (
      data.some(
        (record) =>
          isEmpty(record.gl_code) ||
          isEmpty(record.invoice_name) ||
          isEmpty(record.invoice_quantity) ||
          isEmpty(record.invoice_value) ||
          isEmpty(record.unit_price) ||
          isEmpty(record.location_id)
      )
    ) {
      this.setState({
        glCodeError: data.some((record) => isEmpty(record.gl_code))
          ? "Cannot be blank"
          : "",
        descriptionError: data.some((record) => isEmpty(record.invoice_name))
          ? "Cannot be blank"
          : "",
        unitsError: data.some((record) => isEmpty(record.invoice_quantity))
          ? "Cannot be blank"
          : "",
        invoiceAmountError: data.some((record) => isEmpty(record.invoice_value))
          ? "Cannot be blank"
          : "",
        unitPriceError: data.some((record) => isEmpty(record.unit_price))
          ? "Cannot be blank"
          : "",
        orderError: data.some((record) => isEmpty(record.location_id))
          ? "Cannot be blank"
          : "",
      });
    } else {
      this.setState(
        {
          glCodeError: "",
          descriptionError: "",
          unitsError: "",
          invoiceAmountError: "",
          unitPriceError: "",
          orderError: "",
        },
        () => {
          this.props.addItem(
            this.props.invoice_type,
            this.props.currentSection.order_number,
            this.props.orderData.locations
          );
        }
      );
    }
  };

  handleBlur = (value, index, fieldName) => {
    const { decimalPoints } = this.state;
    const zeroValue = Number((0).toFixed(decimalPoints));

    this.setState((prevState) => {
      const updatedInputValues = Array.from(prevState.inputValues || []);
      if (!updatedInputValues[index]) {
        updatedInputValues[index] = {};
      }

      if (value) {
        let invAmount = parseFloat(value);
        updatedInputValues[index][fieldName] = invAmount.toFixed(decimalPoints);
      } else {
        updatedInputValues[index][fieldName] = zeroValue;
      }

      // Call the updateRecords method with the updated input values
      this.props.updateRecords(updatedInputValues);

      return { inputValues: updatedInputValues };
    });
  };

  // handleInputChange = (value, index, fieldName) => {
  //   this.setState((prevState) => {
  //     const updatedInputValues = Array.from(prevState.inputValues || []);

  //     if (!updatedInputValues[index]) {
  //       updatedInputValues[index] = {};
  //     }
  //     updatedInputValues[index][fieldName] = value;

  //     this.props.updateRecords(updatedInputValues);

  //     return { inputValues: updatedInputValues };
  //   });
  // };
  handleInputChange = (value, index, fieldName) => {
    this.setState((prevState) => {
      const updatedInputValues = [...prevState.inputValues];

      if (!updatedInputValues[index]) {
        updatedInputValues[index] = {};
      }
      updatedInputValues[index][fieldName] = value;

      // Update invoice_value based on invoice_quantity and unit_price
      if (fieldName === "invoice_quantity" || fieldName === "unit_price") {
        const quantity = parseFloat(
          updatedInputValues[index]["invoice_quantity"] || 0
        );
        const price = parseFloat(updatedInputValues[index]["unit_price"] || 0);
        const newValue = (quantity * price).toFixed(this.state.decimalPoints);
        updatedInputValues[index]["invoice_value"] = newValue;
      }

      this.props.updateRecords(updatedInputValues);

      return { inputValues: updatedInputValues };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!equal(prevProps.data, this.props.data)) {
      this.columns = [];
      this.setColumns(this.props);
    }
    if (
      !_.isEqual(prevProps.initialInputValues, this.props.initialInputValues)
    ) {
      this.setState({
        inputValues: this.props.initialInputValues,
      });
    }
  }
}

export default TransportationChargesList;

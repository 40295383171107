/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';
import { isEmpty } from '../../common/Common';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.images,
      lightboxIsOpen: true,
      currentImage: !isEmpty(this.props.currentImage) ? this.props.currentImage : 0,
    };
  }

  componentDidMount() {
    this.openLightbox(this.state.currentImage);
  }

  openLightbox = (index, event) => {
    // event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    }, () => {
      this.props.onClose();
    });
  }
  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { images } = props;
    return {
      images,
    };
  }

  render() {
    return (
      <div> {this.state.images && this.state.images.length > 0 && <Lightbox
        images={this.state.images}
        currentImage={this.state.currentImage}
        isOpen={this.state.lightboxIsOpen}
        onClickPrev={this.gotoPrevious}
        onClickNext={this.gotoNext}
        onClose={this.closeLightbox}
      />}
      </div >
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Gallery;


import {
    Call,
    ApiUrl,
    GenerateURL,
    ParseGeneralResponse,
  } from "../../javascripts/api/ApiUtils";


export function updateActiveWarehouse(activeWarehouseIds) {
    const url = ApiUrl("v1/users/update_active_warehouse");
    const method = "PUT";
    const data = {
      active_warehouse_ids: activeWarehouseIds,
    };
  
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { user: "user" },
    });
  }
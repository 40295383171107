import React from 'react'
import equal from "fast-deep-equal";
import { withRouter } from 'react-router-dom'
import { postErrorToSlack } from '../api/ApiUtils'
import { NewVersionAvailableScreen, FleetCoolCrash } from './InfoScreens';
import _ from 'lodash';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      error: null, 
      errorInfo: null,
      channel: null
    }
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.catchPromiseErrors);
    window.onerror = this.catchUnhandledError;

    const newChannel = new BroadcastChannel('version_update');
    this.setState({ channel: newChannel });

    newChannel.onmessage = (event) => {
      if (event.data.type === 'RELOAD') {
        console.log('Reload message received. Reloading...');
        window.location.reload();
      }
    };
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.catchPromiseErrors);
    if (this.state.channel) {
      this.state.channel.close();
    }
  }

  catchUnhandledError = (message, url, line, column, errorMsg = "") => {
    const errorMessage = errorMsg ? errorMsg.toString() : ''
    const prevErrorMessage =  window.localStorage.getItem('errObj')
    if (!(errorMessage === prevErrorMessage) && !errorMessage.includes('overlayMouseTarget')) {
      postErrorToSlack({ url: window.location.href, error: errorMsg });
      localStorage.setItem('errObj', errorMessage);
    }
  };

  catchPromiseErrors = (event) => {
    const errorMessage = event?.reason ? event.reason.toString() : "";
    const prevErrorMessage =  window.localStorage.getItem('errObj')
    if (!(errorMessage === prevErrorMessage)) {
      postErrorToSlack({ url: window.location.href, error: event.reason });
      localStorage.setItem('errObj', errorMessage);
    }
  };

  componentDidCatch(error, errorInfo) {
     this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // Get error message or error stack trace
    const errorMessage = error.message || error.toString();
    const errorStack = errorInfo.componentStack;

    // Get previous error message or error stack trace from local storage
    const prevErrorMessage = localStorage.getItem('errObj');
    // Check if current error is the same as previous error
    if (!(errorMessage === prevErrorMessage)) {
       // Post error message to Slack channel
        postErrorToSlack({
          url: window.location.href,
          error, 
          errorInfo,
        })
        // Store current error message and error stack trace in local storage
        localStorage.setItem('errObj', errorMessage);
    }
  }

  goToDashBoard = () => {
    this.props.history.push('/dashboard')
    this.setState({ error: '', errorInfo: '' })
  }

  broadcastReload = () => {
    if (this.state.channel) {
      this.state.channel.postMessage({ type: 'RELOAD' });
    }
    window.location.reload();
  };

  render() {
    const isValidChunkError = _.get(this.state.error, 'message', '').toLowerCase().includes("chunk");
    if(isValidChunkError) {
      return <NewVersionAvailableScreen reloadOnClick={this.broadcastReload} />;
    }
    if (this.state.errorInfo) {
      return (
        <FleetCoolCrash history={this.props.history} />
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)

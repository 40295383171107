
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

const EyeIcon = (props) => {
  const { handleClick,color="#096dd9", styleObj ={} } = props;
  return (
    <>
      <Icon
        type="eye"
        theme="twoTone"
        twoToneColor={color}
        style={{ ...styleObj }}
        onClick={handleClick}
      />
    </>
  );
};

EyeIcon.propTypes = {
  handleClick: PropTypes.func,
  styleObj: PropTypes.shape().isRequired
};

EyeIcon.defaultProps = {
  handleClick: () => {},
  styleObj: {},
};
export default EyeIcon;

import React from "react";
import BaseList from "../BaseList";
import { momentTimeWithShortName } from "../../helpers/date_functions";
import I18n from "../../common/I18n";

class StatusLogList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        className: 'textCaptilize'
      },
      {
        key: "error",
        title: I18n.t("general.error"),
        dataIndex: "error",
      },
      {
        key: "updated_at",
        title: I18n.t("notifications.logs.sent_on"),
        dataIndex: "tracked_at",
        render: (data) =>
          data ? (
            <div>
              {momentTimeWithShortName(
                "lll",
                data,
                this.props.timeZone,
                this.props.timeZoneName
              )}
            </div>
          ) : (
            "NA"
          ),
      },
    ];
  }
}
export default StatusLogList;

import React, { Fragment, useState } from "react";
import {
  Col,
  Row,
  Icon,
  Card,
  Text,
} from "../../common/UIComponents";
import { checkNegitive } from "../../common/Common";
import { checkServiceExistance } from "../../helpers/common";
import { isEmpty } from "lodash";
import { withRouter } from "react-router";
import { checkForSMP } from "../../helpers/billing";
import OrderBillingDetails from "../billing/OrderBillingDetails";
import ShowInvoice from "../billing_screen/ShowInvoice";
import I18n from "../../common/I18n";

const navigateToInvoice = (history, orderNumber) => {
  history.push(`/invoices?order_number=${orderNumber}`);
};

const EstimationAmount = ({ order, swithToBilling, history,renderSideBySide,refreshList, isShipper = false }) => {
  const { quotation_amount , quote_amount , quote_order_number : quotation_number , invoice_number, customer_order_number } = order || {};
  const hasInvoiceNumber = !!invoice_number;


  return (
    <Fragment>
      {renderSideBySide ? (
        <div className="alignLeft">
          <Row type="flex" gutter={8}>
            <Col span={24}>
              <span className="primaryText textBold">
               Invoice & Quote Details
               {/* {I18n.t("general.quotation_details")}  */}
              </span>
            </Col>
            <Col span={24}>
              {!isShipper && <Col span={12}>
                <Text className="textBold">{I18n.t("order.quote")}</Text>:{" "}
                <Text
                  className="details_value_grey"
                  underline
                  onClick={() => swithToBilling("billing")}
                  style={{ cursor: "pointer" }}
                >
                  {checkNegitive(quotation_amount)}
                </Text>
              </Col>
              }
              {(!isShipper  || order.invoice_status === 'APPROVED') && <Col span={12}>
                <Text className="textBold">
                  {I18n.t("invoices.invoice_no")}
                </Text>
                :{" "}
                <Text
                  className="details_value_grey"
                  // onClick={() =>
                  //   navigateToInvoice(history, customer_order_number)
                  // }
                  style={{ cursor: "pointer" }}
                >
                  {hasInvoiceNumber ? (
                    <Fragment>
                      <span className="anchor_cursor">
                        {/* {invoice_number} */}
                        <ShowInvoice
                              account={ order.account_id || order.primary_account_id }
                              invoice_no={ order.invoice_number }
                              customer_order_id={ order.customer_order_id }
                              style={{ marginTop: -6 }}
                              account_invoice_id = {order.invoice_id}
                              refreshList={refreshList}
                              showInvoiceActions={false}
                              approvalDate={order.actual_end_datetime}
                              renderType="text"

                            />
                        </span>
                      {checkForSMP(order)}
                    </Fragment>
                  ) : (
                    I18n.t("general.na")
                  )}
                </Text>
              </Col>}
            </Col>
            <Col span={24}>
              <Col span={12}>
                <Text className="textBold">{"Quote Number"}</Text>:{" "}
                <Text className="details_value_grey">
                  {quotation_number || I18n.t("general.na")}
                </Text>
              </Col>
              <Col span={12}>
                <Text className="textBold">
                  {I18n.t("order.quotation_amount")}
                </Text>
                :{" "}
                <Text className="details_value_grey">
                  {checkNegitive(quote_amount) || I18n.t("general.na")}
                </Text>
              </Col>
            </Col>
            <Col span={24}>
            { (!isShipper  || order.invoice_status === 'APPROVED') && order.status === "COMPLETED" && <>
                  <Row type="flex" style={{ marginTop: "2px" }} gutter={12}>
                  <Col>
                    <Icon type="dollar" />
                  </Col>
                  <Col className="textBold">
                    <span className="textBold ">
                      {I18n.t("invoices.total_amount")}
                    </span>
                  </Col>
                  <Col>
                  <OrderBillingDetails
                    record={{...order, customer_order_id : order.id}}
                    keytoShow = {"total_amount"}
                    showActions = {false}
                  />
                      </Col>
                  </Row>
                  </>
            }
            </Col>
          </Row>
        </div>
      ) : (
        <Row justify="space-around" align="middle">
          {checkServiceExistance("OEA") && (
            <Card size="small" style={{}}>
              <Row>
                {!isShipper && <Row type="flex">
                  <Col span={2} style={{ marginRight: "5px" }}>
                    <Icon type="dollar" />
                  </Col>

                  <Col>
                    <span className="textBold ">{I18n.t("order.quote")}</span>
                  </Col>
                  <Col>
                    <span
                      className="anchor_cursor  textBold  leftBlock details_value_grey"
                      onClick={() => swithToBilling("billing")}
                      style={{ marginLeft: "10px" }}
                    >
                      {checkNegitive(quotation_amount)}
                    </span>
                  </Col>
                </Row>
                }
                {(!isShipper  || order.invoice_status === 'APPROVED') &&  
                <Row type="flex" style={{ marginTop: "2px" }}>
                  <Col span={2} style={{ marginRight: "5px" }}>
                    <Icon type="file-text" />
                  </Col>
                  <Col>
                    <span className="textBold ">
                      {I18n.t("invoices.invoice_no")}
                    </span>
                  </Col>
                  <Col>
                    <span
                      className={`${
                        hasInvoiceNumber
                          ? "textBold  leftBlock details_value_grey"
                          : "pointerEventsNone textBold leftBlock details_value_grey"
                      }`}
                      // onClick={() =>
                      //   navigateToInvoice(history, customer_order_number)
                      // }
                      style={{ marginLeft: "10px" }}
                    >
                      {hasInvoiceNumber ? (
                        <Fragment>
                          <span className="anchor_cursor">
                            {/* {invoice_number} */}
                            <ShowInvoice
                              account={ order.primary_account_id || order.account_id }
                              invoice_no={ order.invoice_number }
                              customer_order_id={ order.customer_order_id }
                              style={{ marginTop: -6 }}
                              account_invoice_id = {order.invoice_id}
                              refreshList={refreshList}
                              showInvoiceActions = {false}
                              approvalDate={order.actual_end_datetime}
                              renderType="text"
                            />
                          </span>
                          {checkForSMP(order)}
                        </Fragment>
                      ) : (
                        I18n.t("general.na")
                      )}
                    </span>
                  </Col>
                </Row>
                }
                <Row type="flex" style={{ marginTop: "2px" }}>
                  <Col span={2} style={{ marginRight: "5px" }}>
                    <Icon type="number" />
                  </Col>
                  <Col>
                    {/* <span className="textBold ">{ I18n.t("order.quotation_no") }</span> */}
                    <span className="textBold ">{"Quote Number"}</span>
                  </Col>
                  <Col>
                    <span
                      className=" textBold  leftBlock details_value_grey"
                      style={{ marginLeft: "10px" }}
                    >
                      {quotation_number || I18n.t("general.na")}
                    </span>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "2px" }}>
                  <Col span={2} style={{ marginRight: "5px" }}>
                    <Icon type="dollar" />
                  </Col>
                  <Col>
                    <span className="textBold ">
                      {I18n.t("order.quotation_amount")}
                    </span>
                  </Col>
                  <Col>
                    <span
                      className="textBold  leftBlock details_value_grey"
                      style={{ marginLeft: "10px" }}
                    >
                      {checkNegitive(quote_amount) || I18n.t("general.na")}
                    </span>
                  </Col>
                </Row>
                {(!isShipper  || order.invoice_status === 'APPROVED') && order.status === "COMPLETED" && <>
                  <Row type="flex" style={{ marginTop: "2px" }} gutter={12}>
                  <Col>
                    <Icon type="dollar" />
                  </Col>
                  <Col className="textBold">
                    <span className="textBold ">
                      {I18n.t("invoices.total_amount")}
                    </span>
                  </Col>
                  <Col>
                      <OrderBillingDetails
                        record={{...order, customer_order_id : order.id}}
                        keytoShow = {"total_amount"}
                        showActions = {false}
                      />
                      </Col>
                  </Row>
                  </>
                  }
              </Row>
            </Card>
          )}
        </Row>
      )}
    </Fragment>
  );
};

export default withRouter(EstimationAmount);
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import WarehouseSelector from './WarehouseSelector';
import { WarehouseContext } from '../../context/WarehouseContext';

function WarehouseFilter (props) {
  const { onChange , isDisabled , showIcon, showAllOption} = props;
  const { isFetchingWarehouses, isWarehouseMultiSelect, warehouses, selectedWarehouse, selectedWarehouses, onWarehouseChange } = useContext(WarehouseContext);

  const getWarehouse = () => {
    return selectedWarehouses.length ? selectedWarehouses : "";
     

    // if (isWarehouseMultiSelect) {
    //   return selectedWarehouses.length ? selectedWarehouses : [ { key: "", label: "" } ];
    // } else {
    //   return selectedWarehouse ? selectedWarehouse : "";
    // }
  };

  useEffect(() => {
    onChange(getWarehouse());
  }, [selectedWarehouse, selectedWarehouses]);

  const handleWarehouseChange = (values) => {
    if (false) {
    // if (isWarehouseMultiSelect) {
      const filteredValues = values.length
        ? values[ 0 ].key === ""
          ? values.slice(1)
          : values[ values.length - 1 ].key === ""
            ? []
            : values
        : [];
      onWarehouseChange(filteredValues);
      // onChange(filteredValues);
    }
    else {
      onWarehouseChange(values);
      // onChange(values);
    }
  };

  return (
    <WarehouseSelector 
      defaultSelectionValue={ props.defaultSelectionValue }
      handleWarehouseChange={ handleWarehouseChange }
      isDisabled={ isDisabled }
      isFetchingWarehouses={ isFetchingWarehouses }
      isWarehouseMultiSelect={ false }
      key={ selectedWarehouse }
      warehouses={ warehouses }
      style={ props.style }
      getWarehouse={ getWarehouse }
      showIcon={ showIcon }
      showAllOption={showAllOption}
    />
  );
}


export default WarehouseFilter;


WarehouseFilter.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.object,
  isDisabled : PropTypes.bool, 
  defaultSelectionValue : PropTypes.string,
  showAllOption: PropTypes.bool,
};
WarehouseFilter.defaultProps = {
  onChange: () => { },
  style: {},
  isDisabled : false,
  defaultSelectionValue : "-- All --",
  showAllOption: true,
};
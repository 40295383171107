import React  from "react";
import PropTypes from "prop-types";
import { Popover, Icon, Button } from "../../common/UIComponents";
import { isEmpty } from "../../common/Common";

const renderData = (
  record,
  displayKey,
  prependText,
  label,
  showLabel,
  className = "",
  displayType = "string"
) => {
  const isException = ['EXCEPTION', 'ON_HOLD'].includes(record[displayKey])
  const displayValue = record[displayKey];
  return displayType === "string" ? (
    <span>
      {prependText}
      {!isEmpty(label) && showLabel && <span>{label}: </span>}
      {isException ? (
        <span className="textRed">{displayValue}</span>
      ) : (
        <span className={className ? className : "textBold"}>
          {displayValue}
        </span>
      )}
    </span>
  ) : (
    <Button
      className={isException ? "buttonRed" : "buttonGreen"}
      size="small"
      icon={isException ? "warning" : "check-circle"}
    >
      {prependText}
      {!isEmpty(label) && showLabel && <span>{label}: </span>}
      {displayValue}
    </Button>
  );
};
const OrderStatus = ({
  record,
  displayKey,
  prependText = "",
  label = "",
  showLabel = false,
  popoverMsg,
  className = "",
  displayType = "string",
}) => {
  if (!record) return null;
  if (!displayKey) return null;
  if (!record[displayKey]) return null;

  if (isEmpty(popoverMsg)) {
    return renderData(
      record,
      displayKey,
      prependText,
      label,
      showLabel,
      className,
      displayType
    );
  }

  return (
    <Popover
      //title={I18n.t("general.reason")}
      content={`${popoverMsg}`}
      placement="topLeft"
      overlayStyle={{ width: 350 }}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <span>
        {renderData(
          record,
          displayKey,
          prependText,
          label,
          showLabel,
          ['EXCEPTION', 'ON_HOLD'].includes(record[displayKey]) ? "textRed" : className,
          displayType
        )}
      </span>
      {['EXCEPTION', 'ON_HOLD'].includes(record[displayKey]) && (
        <span className="marginLeft5">
          <Icon
            type="info-circle"
            theme="twoTone"
            twoToneColor="red"
            className="fontSize15"
          />
        </span>
      )}
    </Popover>
  );
};

OrderStatus.propTypes = {
  record: PropTypes.shape().isRequired,
  displayKey: PropTypes.string.isRequired,
};

export default OrderStatus;

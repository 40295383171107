import React, { Fragment } from "react";
import {isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { Popover, Icon } from "../../common/UIComponents";
import infoIcon from "../../../assets/images/info_icon.svg";

const PreplanOrderNotes = (props) => {
  const { record, placement = "left", showPopupContainer = true } = props;
  const notes = record.notes;
  return (
    <Fragment>
      {!isEmpty(notes) && (
        <Popover
          content={<div style={{ maxWidth: 300 }}>{notes}</div>}
          placement={placement}
          title={I18n.t("order.notes")}
          getPopupContainer={showPopupContainer ? (triggerNode) => triggerNode.parentNode : null}
        >
          <Icon
            component={() => (
              <img style={{ height: "13px", marginTop: -4 }} src={infoIcon} />
            )}
          />
        </Popover>
      )}
    </Fragment>
  );
};
export default PreplanOrderNotes;

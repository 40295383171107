import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Tooltip,
  Row,
  Icon,
  Form,
  Modal,
  DatePicker,
  Spin,
  Button,
  Col,
  FormItem,
  Select,
} from "../../common/UIComponents";
import { withRouter } from "react-router";
import { updateInvGenDateManual } from "../../api/Billing";
import { alertMessage } from "../../common/Common";
import moment from "moment";
import { momentTime } from "../../helpers/date_functions";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";
import { fetchUsers } from "../../api/UsersApi";
import { changeDriverOnCompletedOrder } from "../../api/OrdersApi";

export const EditDriverDetails = (props) => {
  const {
    defaultDriver = '',
    updateDriverData
 } = props;
  const [openDriversModal, setOpenDriversModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(defaultDriver);
  const [inProgress, setInProgress] = useState(false);

  const changeDriver = () => {
    const locationId = props?.currentData?.segment_location_id || ''
    const orderId = props?.currentData?.customer_order_id || ''
    const driverObj = _.find(props.drivers, { id: selectedDriver})
    const itemName = props?.currentData?.item_name || ''
    setInProgress(true);
    const data = {
      driver_id: selectedDriver,
      order_id: orderId,
      location_id: locationId,
      old_driver_id: defaultDriver,
      item_name: itemName,
    };
    changeDriverOnCompletedOrder(data)
      .then((result) => {
        if (result.success) {
          alertMessage(result.data.message, "success", 10);
          setOpenDriversModal(false);
          updateDriverData({
            driver_id: driverObj?.id || '',
            driver_code: driverObj?.employee_code|| '',
          });
          
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .catch((error) => {
        alertMessage(error.message, "error", 10);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const handleEdit = () => {
    setOpenDriversModal(true);
  };

  const handelCancel = () => {
    setOpenDriversModal(false);
  };

  const setInitialData =() => {
    setSelectedDriver(defaultDriver);
    setOpenDriversModal(true);

  }

  const handleDriverChnage = (value) => {
    setSelectedDriver(value);
  };

  const renderDriverEditModal = () => {
    const drivers = props.drivers || [];
    return (
      <Modal
        title={"Change Driver"}
        onCancel={handelCancel}
        footer={null}
        bodyStyle={{ padding: 10 }}
        visible={openDriversModal}
      >
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Spin spinning={inProgress}>
                <Form>
                  <span className="textBold">{I18n.t("general.Driver")}:</span>
                  <Select
                    showSearch
                    value={selectedDriver ? selectedDriver : ""}
                    onChange={handleDriverChnage}
                    filterOption={(input, option) => {
                      const children = option.props.children;
                      if (children && children.length > 0) {
                        if (Array.isArray(children)) {
                          return (
                            children[0]
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        } else {
                          return (
                            children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                      }
                    }}
                    style={{ width: "80%", height: "20%", marginLeft: 10 }}
                  >
                    <Select.Option key={"driver"} value={""}>
                      --select--
                    </Select.Option>
                    {drivers.map((driver) => (
                      <Select.Option key={driver.id} value={driver.id}>
                        {driver.employee_code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form>
              </Spin>
            </Col>
          </Row>
          <Row className="marginTop10">
            <Col xs={24} className="alignCenter">
              <Button
                className="marginRight5"
                onClick={handelCancel}
                icon="close"
                size="small"
              >
                {I18n.t("general.cancel")}
              </Button>
              <Button
                type="primary"
                onClick={changeDriver}
                icon="save"
                loading={inProgress}
                size="small"
              >
                {I18n.t("general.save")}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };
  return (
    <Fragment>
      <Tooltip title={I18n.t("general.edit")}>
        <Icon
          type="edit"
          style={{ color: "blue" }}
          onClick={() => setInitialData()}
        />
      </Tooltip>

      {openDriversModal && renderDriverEditModal()}
    </Fragment>
  );
};

EditDriverDetails.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(EditDriverDetails);

import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
  optionalPageParams,
} from "./ApiUtils";
import userStore from "../stores/UserStore";
import AppConfig from "../config/AppConfig";
import { isEmpty } from "../common/Common";

const getAccountId = (_) => localStorage.getItem("accountId");
// const orgID = userStore.getStateValue("selectedOrg");

// export function fetchContactGroup(
//   page = 1,
//   perPage = AppConfig.perPage,
//   render_type = ""
// ) {
//   const org_id = userStore.getStateValue("selectedOrg");
//   let url = ApiUrl(`v2/account_contact_groups?organization_id=${org_id}`);
//   if (render_type === "list") {
//     url += `&render_type=list&${optionalPageParams(page, perPage, false)}`;
//   }

//   // const url = ApiUrl(
//   //   `v2/account_contact_groups?render_type=${render_type}&organization_id=${org_id}&${optionalPageParams(
//   //     page,
//   //     perPage,
//   //     false
//   //   )}`
//   // );
//   return Call(
//     "get",
//     url,
//     {},
//     ParseGeneralResponse,
//     {
//       responseDataKeys: {
//         account_contact_groups: "account_contact_groups",
//         pagination: "meta.pagination",
//       },
//     },
//     false
//   );
// }
export function fetchContactGroupDetails(id) {
  const url = ApiUrl(`v2/contacts_groups/${id}?organization_id=${userStore.getStateValue("selectedOrg")}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { contacts_group: "contacts_group" },
  });
}

export function fetchContactGroup(
  page = 1,
  perPage = AppConfig.perPage,
  render_type = "",
  isOrgLevel,
  selectedAccountId=""
) {
  const accountId = !isEmpty(selectedAccountId) ? selectedAccountId : "";
  // const accountId = !isEmpty(selectedAccountId) ? selectedAccountId : getAccountId();
  let url = ApiUrl(
    `v2/contacts_groups?organization_id=${userStore.getStateValue("selectedOrg")}&operation_code=CONGI`
  );
  if (accountId) {
    url += `&render_type=${render_type}&level=ACCOUNT&level_id=${accountId}&${PageParams(
      page,
      perPage
    )}`;
  } else {
    url += `&render_type=${render_type}&level=ORGANIZATION&level_id=${userStore.getStateValue("selectedOrg")}&${PageParams(
      page,
      perPage
    )}`;
  }
  // if (render_type === "list") {
  //   url += `&render_type=list&${optionalPageParams(page, perPage, false)}`;
  // }

  // const url = ApiUrl(
  //   `v2/account_contact_groups?render_type=${render_type}&organization_id=${org_id}&${optionalPageParams(
  //     page,
  //     perPage,
  //     false
  //   )}`
  // );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        contacts_groups: "contacts_groups",
        pagination: "meta.pagination",
      },
    },
    false
  );
}

export function deleteContactGroup(id) {
  const url = ApiUrl(`v2/contacts_groups/${id}?organization_id=${userStore.getStateValue("selectedOrg")}`);
  return Call("delete", url, { operation_code: "CGD" }, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

// export function saveContactGroup(isNew, data) {
//   data.organization_id = userStore.getStateValue("selectedOrg");
//   const formData = setObjectToFormData(data);
//   const url = isNew
//     ? ApiUrl("v2/account_contact_groups")
//     : ApiUrl(`v2/account_contact_groups/${data.id}`);
//   const method = isNew ? "post" : "put";
//   return Call(
//     method,
//     url,
//     formData,
//     ParseGeneralResponse,
//     { responseDataKeys: { account_contact_groups: "" } },
//     false
//   );
// }

export function saveContactGroup(isNew, data, isOrgLevel, accountId) {
  data.operation_code = isNew ? "CGCR" : "CGU";
  //data.organization_id = userStore.getStateValue("selectedOrg");

  const url = isNew
    ? ApiUrl("v2/contacts_groups")
    : ApiUrl(`v2/contacts_groups/${data.id}`);
  const method = isNew ? "POST" : "PUT";

  if (!isOrgLevel) {
    data.level = "ACCOUNT";
    data.level_id = accountId; //getAccountId();
    data.organization_id = userStore.getStateValue("selectedOrg");
  } else {
    data.level = "ORGANIZATION";
    data.level_id = userStore.getStateValue("selectedOrg");
    data.organization_id = userStore.getStateValue("selectedOrg");
  }
  // const formData = setObjectToFormData(data);
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { contacts_groups: "contacts_group" } },
    false
  );
}

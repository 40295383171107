import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tooltip,
  Row,
  Icon,
  Form,
  Modal,
  Col,
} from "../../common/UIComponents";
import { withRouter } from "react-router";
import { Input } from "antd";
import { checkNegitive, findNumberTotal, isEmpty } from "../../common/Common";
import BaseModal from "../BaseModal";

const EditConsolidatedPrice = ({ record, orderId, order_number, save }) => {
  const decimalPoints = window.localStorage.getItem("round_off_decimals");
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [data, setData] = useState({});
  const [total, setTotal] = useState({
    actual_price: 0.0,
    consolidation_price: 0.0,
  });

  useEffect(() => {
    let actualPrice = 0.0,
      consolidationPrice = 0.0;
    if (_.isArray(data.accessorial_charges)) {
      actualPrice = parseFloat(
        findNumberTotal(
          data.accessorial_charges,
          "actual_price",
          null,
          decimalPoints
        )
      );
      consolidationPrice = parseFloat(
        findNumberTotal(
          data.accessorial_charges,
          "consolidation_price",
          null,
          decimalPoints
        )
      );
    }
    if (_.isObject(data.transportation_charges)) {
      Object.keys(data.transportation_charges).forEach((key) => {
        if (data.transportation_charges[key].actual_price) {
          const actualPriceValue = parseFloat(
            data.transportation_charges[key].actual_price
          );
          actualPrice += isNaN(actualPriceValue) ? 0.0 : actualPriceValue;
        }
        if (data.transportation_charges[key].consolidation_price) {
          const consolidationPriceValue = parseFloat(
            data.transportation_charges[key].consolidation_price
          );
          consolidationPrice += isNaN(consolidationPriceValue)
            ? 0.0
            : consolidationPriceValue;
        }
      });
    }
    if (isNaN(actualPrice)) {
      actualPrice = 0.0;
    }

    if (isNaN(consolidationPrice)) {
      consolidationPrice = 0.0;
    }
    setTotal({
      actual_price: actualPrice,
      consolidation_price: consolidationPrice,
    });
  }, [data]);

  useEffect(() => {}, [record]);

  const updateRecord = () => {
    setData(record?.consolidation_prices || {});
  };

  const handleOkDate = async () => {
    save(data, total.consolidation_price);
    handleCancel();
  };

  const handleCancel = () => {
    setData({});
    setShowInfoWindow(false);
  };

  const inputStyle = {
    padding: "5px",
    border: "1px solid #ccc",
    borderRadius: "3px",
    height: 25,
    width: 90,
  };

  const renderModalWindow = () => {
    const transportation_charges = data.transportation_charges || {};
    const accessorial_charges = data.accessorial_charges || [];
    return (
      <Modal
        style={{
          top: -80,
          width: 500,
        }}
        title={`Edit Consolidated Price (#${order_number})`}
        centered
        visible={showInfoWindow}
        onOk={handleOkDate}
        onCancel={handleCancel}
        bodyStyle={{ padding: 10 }}
      >
        <Form onFinish={handleOkDate}>
          <Row gutter={16}>
            {/* Accessoriable Section */}
            <Col span={24}>
              <Row gutter={16}>
                <Col xs={8}></Col>
                <Col xs={8} className="textBold">
                  Actual Price
                </Col>
                <Col xs={8} className="textBold">
                  Consolidation Price
                </Col>
              </Row>
              {Object.keys(transportation_charges).length > 0 && (
                <Fragment>
                  <Row gutter={16}>
                    <Col xs={10} className="textBold marginTop10">
                      Transportation Charges :
                    </Col>
                  </Row>
                  {Object.keys(transportation_charges).map((key) => (
                    <Row gutter={16} className="marginTop5">
                      <Col xs={8} className="alignRight textBold textUpperCase">
                        {key}
                      </Col>
                      <Col xs={8}>
                        {checkNegitive(
                          transportation_charges[key].actual_price
                        )}
                      </Col>
                      <Col xs={8}>
                      { transportation_charges[key].is_consolidated ? <Input
                          style={inputStyle}
                          value={
                            transportation_charges[key].consolidation_price
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "transportation_charges",
                              key,
                              "consolidation_price",
                              e.target.value
                            )
                          }
                        /> :
                              checkNegitive(transportation_charges[key].consolidation_price)
                        }
                      </Col>
                    </Row>
                  ))}
                </Fragment>
              )}
              {accessorial_charges.length > 0 && (
                <Fragment>
                  <Row gutter={16}>
                    <Col xs={8} className="textBold marginTop10">
                      Accessorial Charges :
                    </Col>
                  </Row>
                  {accessorial_charges.map((rec, index) => (
                    <Row gutter={16} className="marginTop5">
                      <Col xs={8} className="alignRight textBold">
                        {rec.accessorial_name || "NA"}
                      </Col>
                      <Col xs={8}>{checkNegitive(rec.actual_price)}</Col>
                      <Col xs={8}>
                        {
                          rec.consolidated_accesssorial && rec.is_consolidated ? <Input
                          style={inputStyle}
                          value={rec.consolidation_price}
                          onChange={(e) =>
                            handleInputChange(
                              "accessorial_charges",
                              index,
                              "consolidation_price",
                              e.target.value
                            )
                          }
                        /> :
                          checkNegitive(rec.consolidation_price)
                       }
                      </Col>
                    </Row>
                  ))}
                </Fragment>
              )}
              <Row gutter={16} className="marginTop10">
                <Col xs={8} className="alignRight textBold">
                  Total
                </Col>
                <Col xs={8}>{checkNegitive(total.actual_price)}</Col>
                <Col xs={8}>{checkNegitive(total.consolidation_price)}</Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };

  const handleInputChange = (category, index, field, value) => {
    const updatedData = { ...data };

    if (category === "accessorial_charges") {
      updatedData.accessorial_charges[index][field] = value;
    } else {
      if (isEmpty(updatedData[category])) {
        updatedData[category] = { [index]: {} };
      }
      if (isEmpty(updatedData[category][index])) {
        updatedData[category][index] = { [field]: checkNegitive(0, false) };
      }
      updatedData[category][index][field] = value;
    }
    setData(updatedData);
  };

  return (
    <Fragment>
      <Tooltip title="Edit">
        <Icon
          type="edit"
          style={{ color: "blue" }}
          onClick={() => {
            updateRecord();
            setShowInfoWindow(true);
          }}
        />
      </Tooltip>

      {showInfoWindow && renderModalWindow()}
    </Fragment>
  );
};

EditConsolidatedPrice.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(EditConsolidatedPrice);

import React from "react";
import { TableImageColumn } from "./TableImageColumn";

export const VehicleImageData = ({
  vhType = [],
  width='30',
  borderRadius='5',
  record,
  showImage=false,
  showVehicleTitle=false,
}) => {
  const vehicleTypeRecord = vhType?.length > 0 ? vhType.find((match) => match.code === record) : null;
  const imageUrl = vehicleTypeRecord
    ? vehicleTypeRecord.vehicle_image.url
    : null;
  return (
    <div style={{ display: "flex" }}>
      { showImage && imageUrl ? (
        <div>
          <TableImageColumn
            record={imageUrl}
            width={width}
            borderRadius={borderRadius}
          />
        </div>
      ) : null}
      &nbsp;&nbsp;
      <div>{showVehicleTitle ? record : ""}</div>
    </div>
  );
};

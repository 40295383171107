import React from "react";
import PropTypes from "prop-types";
class Signature extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			imageLoadError: false,
		};
	}

	render() {
		const { title, image, content ,text } = this.props;
		const { imageLoadError } = this.state;
		return (
			<div className="signature" style={{
				textAlign: "center",
			}}>
				{image && !imageLoadError && (
					<img
						src={image}
						alt="signature"
						style={{
							width: "100%",
							height: "100%",
							objectFit: "contain",
						}}
						onError={() => {
							this.setState({
								imageLoadError: true,
							});
						}}
					/>
				)}
				{text && (
					<div className="signature-text">
						<h2 style={{
							margin: 0,
						}}>{text}</h2>
					</div>
				)}
				{content && <h2>{content}</h2>}
				<h4 className="signTitle"> {title} </h4>
			</div>
		);
	}
}

export default Signature;

Signature.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	content: PropTypes.string,
	text : PropTypes.string,
};

Signature.defaultProps = {
	title: "",
	image: "",
	content: "",
	text : "",
};
import React from "react";
import { Switch } from "./UIComponents";

const SwitchComponent = (props) => {
    const { handleChange, isChecked, checkedChildren = 'Yes', unCheckedChildren="No", checkedValue, unCheckedValue, isDisabled = false} = props;
    return (
        <Switch
            {...props}
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            onChange={(checked) => {
                if (checked) {
                    handleChange(checkedValue)
                } else {
                    handleChange(unCheckedValue)
                }
            }}
            checked={isChecked}
            className={!isChecked ? "switchComponent" : null}
            disabled={isDisabled}
        ></Switch>
    );
};
export default SwitchComponent;
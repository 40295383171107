import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfoWindow from 'react-google-maps/lib/components/InfoWindow';
import Marker from 'react-google-maps/lib/components/Marker';
import moment from 'moment';
import { Descriptions, Badge } from 'antd';
import _ from "lodash";
import { contrast } from '../common/Colors';
import AppConfig from '../config/AppConfig';
import { formateImageName } from '../common/Common';
function DriverMarkers (props) {
  const { routes, driversInfo, isMilitaryTime } = props;
  const [ hoveredMarker, setHoveredMarker ] = useState(null);
  const buildDescriptions = (driverId, driversInfo , tr_datetime) => {
    // Find the driver object in the driversInfo array with the id matching the driverId passed in
    const driver = driversInfo.find(({ id }) => id === driverId);
    // Return a Descriptions component with the driver details
    const displayFormat = isMilitaryTime
      ? AppConfig.milataryDateTimeFormat
      : AppConfig.dateTimeFormat;
    return (
      <Descriptions title="Driver Info" size='small' column={ 1 }>
        <Descriptions.Item label="Employee Code">{ driver ? driver.employee_code : driverId }</Descriptions.Item>
        <Descriptions.Item label="Mobile Number">{ driver ? driver.mobile_number : '' }</Descriptions.Item>
        <Descriptions.Item label="Last Updated"> { tr_datetime ? moment(tr_datetime).format(displayFormat) : '' }</Descriptions.Item>
      </Descriptions>
    );
  };

  useEffect(() => {
    if (hoveredMarker) {
      props.onMarkerHover(hoveredMarker);
    }
  }, [ hoveredMarker ]);

  const getDriverImageUrl = (route, driversInfo) => {
    // find the driver by driverId
    const { primary_driver_id, bgColor } = route;
    const foreGroundColor = contrast(bgColor);
    const driver = driversInfo.find(({ id }) => id === primary_driver_id);
    if (_.isEmpty(driver)) return null;
    if (driver.image && driver.image.url) return driver.image.url;
    if (!driver.first_name) return null;
    const driver_name = formateImageName(driver.first_name, driver.last_name)
    return `https://ui-avatars.com/api/?name=${driver_name}&background=${bgColor}&color=${foreGroundColor}&size=32`;
  };

  return (
    <>
      { routes.map((route, index) => {
        if (route.driver_last_location) {
          const { latitude, longitude, tr_datetime  } = route.driver_last_location;
          return (
            // Create a marker for each route
            <Marker
              key={ index }
              position={ { lat: latitude, lng: longitude } }
              // Use the driver's image as the marker icon
              icon={ {
                url: getDriverImageUrl(route, driversInfo),
                size: { width: 30, height: 30 },
                scaledSize: { width: 30, height: 30 },
              } }
              // When the mouse hovers over the marker, display the driver's information in an InfoWindow
              onMouseOver={ () => setHoveredMarker(route.id) }
              onMouseOut={ () => setHoveredMarker(null) }
              // When the user clicks on the marker, display the route summary
              onClick={ () => props.showRouteSummary(route) }
            >
              { hoveredMarker === route.id && (
                <InfoWindow onCloseClick={ () => { setHoveredMarker(null); } } options={ { maxWidth: 300 } }>
                  { buildDescriptions(route.primary_driver_id, driversInfo , tr_datetime) }
                </InfoWindow>
              ) }
            </Marker>
          );
        }
      }
      ) }
    </>
  );
}

export default DriverMarkers;


DriverMarkers.propTypes = {
  routes: PropTypes.array.isRequired,
  driversInfo: PropTypes.array.isRequired,
  onMarkerHover: PropTypes.func.isRequired,
};

DriverMarkers.defaultProps = {
  routes: [],
  driversInfo: [],
  onMarkerHover: () => { },
};
/* eslint-disable react/sort-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import React, { Component, Fragment, useContext } from "react";
import { Spin,Typography } from "antd";
import equal from "fast-deep-equal";
import PropTypes from "prop-types";
import moment from "moment";
import _, { result } from "lodash";
import {
  Col,
  Row,
  Tooltip,
  Card,
  Icon,
  Modal,
  Form,
  Input,
} from "../../common/UIComponents";
import {
  uploadPod,
  deleteOrderPic,
  fetchOrderDetails,
  fetchUpdatedSignerDetails,
  downloadOrderPictures,
} from "../../api/OrdersApi";
import {
  alertMessage,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import photoIcon from "../../../assets/images/upload_photo.png";
import placeholder from "../../../assets/images/not_available.png";
import { fetchAccountConfigs } from "../../api/Account";
import Legs from "../Tasks/Legs";
import PrintGallery from "./PrintGallery";
import BaseModal from "../BaseModal";
import UploadGallery from "./UploadGallery";
import { checkServiceExistance, formatByTimeConfig, printPDF, renderAlertMessage, updatePicSettingsWithBol,updatePicSettingsWithTender } from "../../helpers/common";
import ImageViewer from "./ImageViewer";
import { ReleasesApi } from "../../api/ReleasesApi";
import AppConfig from "../../config/AppConfig";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import EditIcon from "./EditIcon";
import userStore from "../../stores/UserStore";
import DateTimeSelector from "../../common/DateTimeSelector";

const { Text } = Typography;

const captured_info = {
  sign_by: '',
  signer_title: '',
  captured_at: moment(),
  actual_start_datetime: moment(),
  actual_end_datetime: moment()
}
class OrderPictures extends Component {
  // static contextType = OrganizationSettingsContext;
  constructor(props) {
    super(props);
    this.state = {
      showSignEditModal: false,
      currentSignerDetails: {},
      pictures: [],
      errors: [],
      order_id: this.props.order_id || "",
      currentOrder: {},
      currentSign: {},
      config: {},
      currentLocation: this.props.currentLocation ? this.props.currentLocation : null,
      isMilitaryTime: false,
      order: "",
      organizationSettings: {},
      account_id: this.props.account_id,
      inProgress: false,
      customer_order_number: this.props.customer_order_number,
    };
  }

  componentDidMount () {
    this.getOrderDetails();
    this.setState({ organizationSettings: this.props.organizationSettings }, () => {
    });
  }

  componentDidUpdate(prevProps,prevState ) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings });
    }
    // change in attachments
    if (prevProps.attachments !== this.props.attachments) {
      this.setState({ currentOrder : { ...this.state.currentOrder , pictures : this.props.attachments } });
    }
    // change in location
    if ( !equal(
        prevProps.currentLocation,
        this.props.currentLocation,
    )) {
      this.setState({ currentLocation : { ...this.props.currentLocation } });
    }
  }



  handleOnChange = (element, value) => {
    this.setState({
      currentSignerDetails: Object.assign({}, this.state.currentSignerDetails, {[element]: value}  ),
    });
  };

  handleDetailsTimeChange = (element, value) => {
    const currentSignerDetails = {...this.state.currentSignerDetails}
    currentSignerDetails[element] = value
    if (element === 'capture_time') {
      // set capture_date to today's date if empty
      if (isEmpty(currentSignerDetails[ 'capture_date' ])) {
        currentSignerDetails[ 'capture_date' ] = moment()
      }
    } else if (element === 'capture_date') {
      // set capture_time to now if empty
      if (isEmpty(currentSignerDetails[ 'capture_time' ])) {
        currentSignerDetails[ 'capture_time' ] = moment()
      }
    }
    this.setState({
      currentSignerDetails,
    });
  }

  getReferId = (refer = 'order') => {
    if (refer === 'stop') {
      return this.props.selectedStop;
    }
    else if(refer === 'release'){
      return this.props.release_id;
    }
    return this.state.order_id;
  }

  handleOkSigner = async () => {
    const { currentLocation } = this.state;
    this.setState({ inProgress: true });
    const capture_date = this.state.currentSignerDetails?.capture_date ? moment(this.state.currentSignerDetails.capture_date ).format("YYYY-MM-DD "): null
    const capture_time = this.state.currentSignerDetails?.capture_time ? moment(this.state.currentSignerDetails.capture_time ).format("HH:mm"): null
    const payload = {
      refer: this.props?.refer ? this.props.refer : "order",
      organization_id: userStore.getStateValue("selectedOrg"),
      refer_id: this.getReferId(this.props.refer),
      account_id: this.state.account_id,
      signer_name: this.state.currentSignerDetails.sign_by,
      signer_title: this.state.currentSignerDetails.title_by_relation,
      captured_at : capture_date && capture_time ? capture_date.concat(capture_time) : null,
      location_id: currentLocation?.id ? currentLocation.id : '',

    };
    fetchUpdatedSignerDetails(payload).then((result) => {
      if (result.success) {
        this.setState({inProgress: false}),
        alertMessage("Signer Details Updated Successfully");
        this.setState({showSignEditModal: false,})
        this.getOrderDetails()
      }
      if (result.errors) {
        this.setState({inProgress: false}),
        renderAlertMessage(result.errors)
      }
    });
  };

  getAccountConfigs = (cb = null) => {
    const { config, currentOrder } = this.state;
    this.setState({ inProgress: true })
    let account_id = this.props.account_id || currentOrder.account_id;
    let organization_id = this.props.org_id || currentOrder.organization_id;
    fetchAccountConfigs(
        account_id, organization_id,
    ).then((result) => {
      if (result.success) {
        const accountConfigurations = result.account_configurations || [];
        accountConfigurations.forEach((setting) => {
          config[setting.setting_name] = setting.setting_value;
        });
        if (
            !isEmpty(config.pod_pic_settings) &&
            _.isString(config.pod_pic_settings) &&
            config.pod_pic_settings === "[]"
        ) {
          config.pod_pic_settings = [];
        }
        config.pod_pic_settings = updatePicSettingsWithBol(config.pod_pic_settings);
        {this.props.has_tender === true && (
           config.pod_pic_settings = updatePicSettingsWithTender(config.pod_pic_settings)
        )}
        if (isEmpty(config.communication_preference)) {
          config.communication_preference = "SMSES";
        }
        this.setState({
          config,
          inProgress: false
        }, () => {
          if(cb) {
            cb()
          }
        });
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  getOrderDetails = () => {
    this.setState({
      inProgress: true,
    });
    fetchOrderDetails(this.state.order_id, "DISPATCHER").then((result) => {
      if (result.success) {
        const currentOrders = [...result.orders];
        let currentOrder = _.find(currentOrders, { id: this.state.order_id });
        if (isEmpty(currentOrder)) {
          currentOrder = { pictures: [] };
        }
        if(this.props.refer == "release"){
          currentOrder = { ...currentOrder, ...this.state.currentOrder , pictures : this.props.attachments };
        }
        this.setState(
            {
              currentOrder,
              inProgress: false,
              nsr : result.orders[0].nsr,
            },
            () => {
              this.getAccountConfigs();
            }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          inProgress: false,
        });
      }
    });
  };

  imageAction = () => {
    this.getAccountConfigs(() => {
    this.setState({
      imagesVisible: true,
    });
  })
  };

  getPictures = (currentOrder, currentLocation) => {
    const isLocationBased = currentLocation && currentLocation.is_location_based ? currentLocation.is_location_based : false;
    const orderLocations = isLocationBased && currentOrder.locations ? currentOrder.locations : [];
    const loc = isLocationBased && currentLocation ? _.find(orderLocations, { id: currentLocation.id }) : null;
    const pictures = isLocationBased && !isEmpty(loc) ? (loc?.pictures ? loc.pictures : []) : (currentOrder.pictures || []);
    return pictures;
  }
  editSignerinfo = (signatureObject) => {
    this.setState({
      currentSignerDetails: {...signatureObject,
        capture_date:signatureObject.captured_at ? moment(signatureObject.captured_at) : null,
        capture_time :signatureObject.captured_at ? moment(signatureObject.captured_at) : null,
      },
      showSignEditModal: true,
    });
  };

  handlePrint = (url) => {
    const { currentOrder } = this.state;
    const refer_id = currentOrder.order_id;
    const location_id = currentOrder.cs_location.id;
    const itemObject = _.find(currentOrder.pictures, obj => obj.picture.url === url);
    const imageId = _.get(itemObject, '_id', '');
    alertMessage("Printing in progress", "info");
    downloadOrderPictures(refer_id, location_id, imageId)
      .then((result) => {
        if (result.success) {
          if (result.data.length === 0) {
            alertMessage("No attachment found", "error");
            return;
          }
          printPDF(result.data.combine_attachments_pdf);
        } else {
          if (Array.isArray(result.errors) && result.errors.length > 0) {
            renderAlertMessage(result.errors);
          } else {
            alertMessage("Failed to print", "error");
          }
        }
      })
  }

  renderOrderPics = () => {
    const { currentLocation } = this.state;
    const currentOrder = this.state.currentOrder ? this.state.currentOrder : {};
    const showActions = this.props.showActions !== false;
    const isLocked = this.props.isLocked || false;
    let signatureImgs = [];
    const printPics = [];
    const pictures = [];
    // const orderPictures = isLocationBased && currentOrder.locations &&
    //   this.state.currentLocation?.pictures &&
    //   this.state.currentLocation.pictures.length
    //     ? this.state.currentLocation.pictures
    //     : currentOrder.pictures
    //     ? currentOrder.pictures
    //     : [];
    const orderPictures = this.getPictures(currentOrder, currentLocation);
    let signatureObject = {};
    const totalPictures = [];
    if (orderPictures.length > 0) {
      orderPictures.forEach((pic) => {
        if (pic.picture && pic.picture.url) {
          const formattedImage = pic.picture.url;
          if (pic.image_type === "normal") {
            pictures.push(formattedImage);
            totalPictures.push(formattedImage);
          } else if (pic.image_type === "signature") {
            signatureImgs.push(formattedImage);
            totalPictures.push(formattedImage);
            signatureObject = pic;
          } else if (pic.image_type === "print") {
            printPics.push(formattedImage);
          }
        }
        else if(pic.image_type === "signature"){
          signatureObject = pic;   // For update signer details
        }
      });
    }
    const signature = signatureImgs.length > 0 ? signatureImgs[0] : "";
    let picturesLength = pictures.length;
    signatureImgs = signatureImgs.filter(i => !isEmpty(i));
    if (signatureImgs.length > 0) picturesLength = picturesLength + 1;
    const opCode = this.props.refer === 'release' ? "OREUP" : (this.props.refer === 'stop' ? "ORRUSP" : "NRASOP");

    if (this.props.isNewLocation) {
      return (
        <Card
          size="small"
          style={ this.props.styleObj ? { ...this.props.styleObj } : {} }
        >
          <Row type="flex" gutter={ 16 } align="middle">
            <Col >
              <span className="primaryText textBold fontSize18">
                { I18n.t("general.POD") }
              </span>
            </Col>
          </Row>
          <Row gutter={ 16 } className="marginTop15">
            <Col xs={ 9 }>
              <Col>
                <img src={ placeholder } alt="pod" style={ { height: 115, width: "100%" } } />
              </Col>
            </Col>
            <Col xs={ 15 }>
              <h3 className="details_value_grey fontSize20">
                Location not saved yet to upload images
              </h3>
            </Col>
          </Row>
        </Card>
      );
    }

    return (
      <Spin spinning={this.state.inProgress} >
      <Card
        size="small"
        // style={ { height: "192px" } }
        style={this.props.styleObj ? { ...this.props.styleObj} : {}}
      >
        <Row type="flex" gutter={ 16 } align="middle">
          <Col >
            <span className="primaryText textBold fontSize18">
              { I18n.t("general.POD") }
            </span>
          </Col>
          <Col >
            <div >
              { showActions && checkServiceExistance(opCode) && (
                <Tooltip
                  title={ I18n.t("order.upload_images") }
                  getPopupContainer={ (triggerNode) => triggerNode.parentNode }
                >
                  <img
                    src={ photoIcon }
                    onClick={ this.imageAction }
                    style={ { width: 20, height: 20,verticalAlign: 'middle' } }
                    alt="upload"
                  />
                </Tooltip>
              ) }
              { printPics && printPics.length > 0 && (
                <Tooltip title="Print Images">
                  <PrintGallery pictures={ printPics } />
                </Tooltip>
              ) }
            </div>
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col>
            <span className="textBold"
            >Signature Required : </span><span className='textBold'>{!this.state.nsr ? 'Yes' : 'No'}</span>
          </Col>
        </Row>
        <Row gutter={ 16 } className="marginTop15">
          <Col xs={ 9 }>
            { picturesLength > 0 ? (
              <div onClick={ this.imageAction }>
                <div>
                  <h3 className="fontSize20 textBold textGrey alignCenter" style={ { marginBottom: "0px" } }>
                    { picturesLength }
                  </h3>
                </div>
                <div>
                  <h4 className="fontSize16 textBold alignCenter details_value_grey">
                    { I18n.t("general.attachment") }
                  </h4>
                </div>
              </div>
            ) : (
              <Col>
                  <img src={ placeholder } alt="pod" style={ { height: 115, width: "100%" } } />
                </Col>
            ) }
          </Col>
          <Col xs={ 15 }>
            { !isEmpty(signatureObject) && _.isObject(signatureObject) && signatureObject.sign_by ? (
              <Fragment>
                <Row className="textBold" style={ { marginTop: "7px" } }>
                  <Col>
                    { I18n.t("order.sign_by") } <span>{ signatureObject.sign_by }</span>{ " " }
                    { !isEmpty(signatureObject.title_by_relation) && signatureObject.title_by_relation !== "N/A" && (
                      <span className="textCaptilize">, ({ signatureObject.title_by_relation })</span>
                    ) }
                    { showActions && checkServiceExistance(opCode) && this.props.refer !== "release" && isLocked === false && (
                      <Tooltip
                        overlayStyle={ { minWidth: 75, zIndex: 1 } }
                        title={ "Edit Signer Details" }
                        getPopupContainer={ (triggerNode) => triggerNode.parentNode }
                      >
                        <EditIcon handleClick={ () => this.editSignerinfo(signatureObject) } />
                      </Tooltip>
                    ) }
                  </Col>
                </Row>

                <Row className="textBold" style={ { marginTop: 4 } }>
                  <Col>
                    { signatureObject.captured_at ? (
                      <span>
                        { " on " }
                        { formatByTimeConfig(
                          moment.utc(signatureObject.captured_at),
                          this.state.organizationSettings[ "is_military_time" ],
                          "Do MMM YYYY, HH:mm",
                          "Do MMM YYYY, hh:mm A"
                        ) }
                        { currentOrder.order_tz_short_name ? ` ${currentOrder.order_tz_short_name}` : "" }
                      </span>
                    ) : null }
                  </Col>
                </Row>
              </Fragment>
            ) : (
                <div style={ { height: [ ...totalPictures ].length > 0 ? 60 : 120 } } className="centerToBlock">
                  <h3 className="details_value_grey fontSize20">
                    { I18n.t("general.signature") } not available{ " " }
                    { showActions && checkServiceExistance(opCode) && isLocked === false && <EditIcon handleClick={ () => this.editSignerinfo(signatureObject) } /> }
                  </h3>
                </div>
            ) }
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <ImageViewer
            images={ [ ...totalPictures ] }
              key={this.state.uploadKey}
            handlePrint={this.handlePrint}
            closeGallery={ this.onCloseUploadGallery }
            takeMultipleSigns={ this.props.takeMultipleSigns }
          />
        </Row>
      </Card>
      </Spin>
    );
  };

  // podSignerDetail = () => {
  //   const currentOrder = this.state.currentOrder ? this.state.currentOrder : {};
  //   const signatureImgs = [];
  //   const printPics = [];
  //   const pictures = [];
  //   const orderPictures = currentOrder.pictures || [];
  //   let signatureObject = {};
  //   if (orderPictures.length > 0) {
  //     orderPictures.forEach((pic) => {
  //       if (pic.picture && pic.picture.url) {
  //         const formattedImage = pic.picture.url;
  //         if (pic.image_type === "normal") {
  //           pictures.push(formattedImage);
  //         } else if (pic.image_type === "signature") {
  //           signatureImgs.push(formattedImage);
  //           signatureObject = pic;
  //         } else if (pic.image_type === "print") {
  //           printPics.push(formattedImage);
  //         }
  //       }
  //     });
  //   }
  //   const signature = signatureImgs.length > 0 ? signatureImgs[0] : "";
  //   return(
  //     <Fragment>
  //     {!isEmpty(signatureObject) &&
  //         _.isObject(signatureObject) &&
  //         signatureObject.sign_by && (
  //           <Fragment>
  //             <Row className="textBold" style={{ marginTop: "9px" }}>
  //               <Col xs={7}>{I18n.t("order.sign_by")}&nbsp;:&nbsp;</Col>
  //               <Col xs={17}>
  //                 {signatureObject.sign_by}
  //                 {!isEmpty(signatureObject.title_by_relation) && (
  //                   <span className="textCaptilize">
  //                     ( {signatureObject.title_by_relation} )
  //                   </span>
  //                 )}
  //               </Col>
  //             </Row>
  //             <Row className="textBold">
  //               <Col xs={7}>{I18n.t("order.sign_on")}&nbsp;:&nbsp;</Col>
  //               <Col xs={17}>
  //                 {signatureObject.captured_at
  //                   ? `${moment
  //                       .utc(signatureObject.captured_at)
  //                       .format("Do MMM YYYY, hh:mm A")} ${
  //                       currentOrder.order_tz_short_name
  //                         ? ` ${currentOrder.order_tz_short_name}`
  //                         : ""
  //                     }`
  //                   : ""}
  //               </Col>
  //             </Row>

  //           </Fragment>
  //         )}</Fragment>
  //   )
  // };

  handleReleaseAttachmentsSuccess = (attachments) => {
    this.setState({ currentOrder: { ...this.state.currentOrder, pictures : attachments} });
    this.props.handleSuccessFullUpload(attachments);
  }

  renderUploadGallery = () => {
    const orderPics = this.getPictures(this.state.currentOrder, this.state.currentLocation);
    return (
        <BaseModal
            title={I18n.t("order.upload_images")}
            onCancel={this.onCloseUploadGallery}
            visible={this.state.imagesVisible}
            width="70%"
            style={{ top: 30 }}
            maskClosable={false}
        >
          <UploadGallery
              config={this.state.config}
              refreshOrder={this.props.refer!== "release" ? this.getOrderDetails : () => {}}
              order={ this.props.refer === 'release' ? {
                ...this.state.currentOrder,
                pictures: this.props.attachments
              } : this.state.currentLocation ? {
                ...this.state.currentOrder,
                pictures: [...orderPics],
              } : this.state.currentOrder }
              key={this.state.uploadKey}
              closeGallery={this.onCloseUploadGallery}
                currentOrder={this.state.currentOrder}
                orderLocations={this.props.orderLocations}
              takeMultipleSigns={this.props.takeMultipleSigns}
              uploadApi={this.props.refer === 'release' ? ReleasesApi.uploadReleasePictures : uploadPod}
              deleteAttachments={this.props.refer === 'release' ? ReleasesApi.deleteReleasePictures : deleteOrderPic}
              refer={this.props.refer}
              release_id={this.props.release_id}
              handleReleaseAttachmentsSuccess={this.handleReleaseAttachmentsSuccess}
              currentLocation={this.state.currentLocation}
              showPrintedAttachments={true}
          />
        </BaseModal>
    );
  };

  onCloseUploadGallery = () => {
    this.setState(
        {
          imagesVisible: false,
        },
        () => {
          if(this.props.refer !== 'release') {
            this.getOrderDetails();
          } else {
            this.props.getAttachments();
          }
        }
    );
  };

  renderSignerEditModal = () => {
    const { currentSignerDetails = {}, customer_order_number, showSignEditModal } = this.state
    const isMilitaryTime = this.state.organizationSettings['is_military_time'] == 'true'
    return (
        <Modal
            title={`Edit Signer Details ${customer_order_number ? ` of #${customer_order_number}`: ''}`}
            centered
            visible={showSignEditModal}
            onOk={this.handleOkSigner}
            onCancel={() =>
                this.setState({
                  showSignEditModal: false,
                })
            }
            bodyStyle={{ padding: 10}}
        >
          <Form onFinish={this.handleOkSigner}>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label=" Signed By" name="signed_by">
                  <Input
                      type="text"
                      value={currentSignerDetails.sign_by}
                      maxLength={40}
                      onChange={(e) =>
                          this.handleOnChange("sign_by", e.target.value)
                      }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label=" Signed Title" name="signed_title">
                  <Input
                      type="text"
                      value={currentSignerDetails.title_by_relation}
                      onChange={(e) =>
                          this.handleOnChange("title_by_relation", e.target.value)
                      }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <DateTimeSelector
                  dateProps={ {
                    format: 'Do MMM YYYY',
                    label : I18n.t('general.capture_date' ),
                    value : currentSignerDetails.capture_date ? moment(currentSignerDetails.capture_date) : null,
                    onChange: (date) => {
                      this.handleDetailsTimeChange(
                          'capture_date',
                          date,
                      )
                    },
                    style: { width: '100%' },
                    disabledDate: (current) => {
                      return (
                          current && current.valueOf() > Date.now() // disabled the future dates
                      )
                    }
                  } }
                  timeProps={ {
                    format: isMilitaryTime ? 'HH:mm' : 'hh:mm A',
                    label: I18n.t('general.capture_time' ),
                    showTime: { format: isMilitaryTime ? 'HH:mm' : 'hh:mm A', use12Hours: !isMilitaryTime },
                    showSecond: false,
                    onChange: (time) => {
                      this.handleDetailsTimeChange(
                          'capture_time',
                          time,
                      )
                    },
                    style: { width: '100%', height: '100%', marginTop: '5px' },
                    value : currentSignerDetails.capture_time ? moment(currentSignerDetails.capture_time, isMilitaryTime ? 'HH:mm' : 'hh:mm A') : null,
                    placeholder: 'Select Time',
                    minuteStep: 1,
                    className: "formMaterialTimePicker",
                    isMilitaryTime
                  } }
                  gridStyle={ { row: { type: "flex", gutter: 4 }, dateCol: { span: 12 }, timeCol: { span: 12 } } }
              />
            </Row>
            </Spin>
          </Form>
        </Modal>
    )

  };

  render() {
    return (
        <Row>
          {this.renderOrderPics()}
          {this.state.imagesVisible && this.renderUploadGallery()}
          {this.state.showSignEditModal && this.renderSignerEditModal()}
        </Row>
    );
  }
}

OrderPictures.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.string).isRequired,
  refreshOrder: PropTypes.func.isRequired,
  otherImgs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  config: PropTypes.string.isRequired,
  order: PropTypes.shape().isRequired,
  closeGallery: PropTypes.func.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

OrderPictures.defaultProps = {
  pictures: [],
  otherImgs: [],
  attachments: [],
};

export const OrderPicturesComponent = withRouter((props) => {
      const warehouseFilter = useContext(WarehouseContext);
      const { orgSettings : organizationSettings  }= useContext(OrgContext);

      return (
          <OrderPictures
              warehouseFilter={warehouseFilter}
              organizationSettings={organizationSettings}
              {...props}
          />
      );
    }
);
export default OrderPicturesComponent;

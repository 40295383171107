import React from 'react';
import { Pagination as AntPagination } from '../../common/UIComponents';


function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span>Previous</span>;
  } else if (type === 'next') {
    return <span>Next</span>;
  }
  return originalElement;
}

const Pagination = (options = {}) => (
  <AntPagination
    current={options.current}
    total={options.total}
    pageSize={options.pageSize}
    itemRender={itemRender}
  />
);

export default Pagination;

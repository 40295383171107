import React, { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Spin,
  Button,
  Checkbox,
  Select,
  Modal,
  FormItem,
  Popconfirm,
  Input,
} from "../../common/UIComponents";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import userStore from "../../stores/UserStore";
import {
  changeStatusOfInvoices,
  addToExistingInvoices,
  accountInvoiceNumbers,
  reapproveBillings,
} from "../../api/Billing";
import _ from "lodash";
import SwitchComponent from "../../common/switchComponent";
import { DriverSelect } from "../common/SelectDropdowns/BaseSelect";
import FormErrors from "../common/FormErrors";
import { UserContext } from "../../context/UserContext";

const ApproveInvoices = ({
  selectedInfo,
  handleCancel,
  isDisabled,
  showActions,
  handleSuccess,
  buttonType,
  className,
  label,
  action,
  is_pickup_by_driver,
  pickup_order_driver_id,
  pickup_order_amount,
  invoice_no,
  screenFrom,
  drivers = [],
  buttonSize = "default",
  // loading,
  // invoices,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [showInvoiceNoWindow, setShowInvoiceNoWindow] = useState(false)
  const [isAddToInvoice, setIsAddToInvoice] = useState(false)
  const [invoiceNo, setInvoiceNo] = useState('')
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [accountsLoading, setAccountsLoading] = useState(false)
  const userContext = useContext(UserContext);
  // useEffect(() => {
  //   getInvoices()
  // }, [])

  const takeInvoiceForm = (value) => {
    if (action === 'ADD_TO_EXISTING') {
      setShowInfoWindow(value)
      setIsAddToInvoice(value)
    } else if (action === 'APPROVED') {
      handleNavigation();
    } else {
      handleSave()
    }
  }

  const handleValidate = () => {
    if (screenFrom === "form") {
      // if (
      //   is_pickup_by_driver === "true" &&
      //   (isEmpty(pickup_order_driver_id) || isEmpty(pickup_order_amount))
      // ) {
      //   if (isEmpty(pickup_order_driver_id)) {
      //     alertMessage("Please select Pickup Driver", "error", 5);
      //   }
      //   else if (isEmpty(pickup_order_amount)) {
      //     alertMessage("Please fill Order Amount", "error", 5);
      //   }
      // }
      // else {
      //   takeInvoiceForm(true);
      // }
      takeInvoiceForm(true);
    } else {
      takeInvoiceForm(true);
    }
  };

  const handleNavigation = () => {
    const { orgRules } = userContext;
    const value = _.get(orgRules.org_billing_rules, 'ask_to_provide_invoice_no_upon_billing_approval');
    const showInvoiceNoModal = value === 'true' ? true : false;
    if (showInvoiceNoModal) {
      setShowInvoiceNoWindow(true);
    } else {
      handleSave();
    } 
    // if(screenFrom === 'form') {
    //   setShowInvoiceNoWindow(true)
    // }else {
    //   handleSave()
    // }
  }

  const resetValues = (closeModal = false) => {
    setInvoiceNo('')
    setIsAddToInvoice(false)
    setShowInfoWindow(false)
    setShowInvoiceNoWindow(false)
    if (closeModal) {
      handleCancel()
    }
  }

  const handleSave = async () => {
    const selectedIds = selectedInfo?.selectedKeys
      ? _.compact(selectedInfo.selectedKeys)
      : []
      try {
        const payload = {
          customer_order_ids: selectedIds,
          organization_id: userStore.getStateValue("selectedOrg"),
        };
        if (!isEmpty(invoiceNo)) {
          payload.invoice_number = invoiceNo;
        }
        if (is_pickup_by_driver === "true") {
          payload.is_pickup_by_driver = "true";
          payload.pickup_order_driver_id = pickup_order_driver_id;
          payload.pickup_order_amount = pickup_order_amount;
        }
        setLoading(true);
        let response = {};
        if (isAddToInvoice) {
          (payload.account_invoice_id = invoiceNo),
            (response = await addToExistingInvoices(payload));
        } else if (action === "APPROVED") {
          response = await changeStatusOfInvoices(payload);
        } else if (action === "REAPPROVED") {
          response = await reapproveBillings(payload);
        }
        if (response.success) {
          const result = response?.invoices ? response.invoices : {};
          const successMessage = result?.message
            ? result.message
            : I18n.t("messages.saved");
          //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
          alertMessage(successMessage);
          handleSuccess();
          resetValues();
        } else {
          throw new Error(response.errors[0]);
        }
      } catch (error) {
        alertMessage(error.message, "error", 10);
      } finally {
        setLoading(false);
      }
  };

  const getInvoices = async () => {
    try {
      const orgId = userStore.getStateValue('selectedOrg')
      setAccountsLoading(true)
      const response = await accountInvoiceNumbers(orgId)
      if (response.success) {
        const invoicesList =
          response.invoices && _.isArray(response.invoices)
            ? response.invoices
            : []
        setInvoices(invoicesList)
      } else {
        throw new Error(response.errors[0])
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setAccountsLoading(false)
    }
  }

  const renderModalWindow = () => {
    const content = (
      <div>
        {/* <Row>
          <Col xs={24}>
            <Checkbox
              checked={isAddToInvoice}
              onChange={(e) => setIsAddToInvoice(e.target.checked)}
            />{' '}
              Do you want to add these orders to existing invoice ?
          </Col>
        </Row> */}
        {/* {isAddToInvoice && ( */}
        <Row>
          <Col xs={24}>
            <Select
              value={invoiceNo}
              showSearch
              style={{
                width: '100%',
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => setInvoiceNo(e)}
            >
              {invoices.map((invoice) => (
                <Select.Option
                  key={invoice.account_invoice_id}
                  value={invoice.account_invoice_id}
                >
                  {invoice.invoice_number}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {/* )} */}
      </div>
    )
    // confirmationPopup({
    //   title: 'Are you sure',
    //   content,
    //   onConfirm: handleSave,
    // })
    return (
      <Modal
        // title={I18n.t('messages.action_confirm', {
        //   action: 'appove',
        //   field: 'the invoices',
        // })}
        title={`Select ${I18n.t('invoices.invoice_no')}`}
        visible={showInfoWindow}
        onOk={handleSave}
        onCancel={() => resetValues(true)}
        confirmLoading={loading}
        width={400}
      >
        <Spin spinning={loading} delay={1000}>
          {content}
        </Spin>
      </Modal>
    )
  }
  const renderInvoiceNoWindow = () => {
    const content = (
      <div>
        <Row>
          <Col xs={24}>
            <FormItem label={`${I18n.t("account.billing.invoice_no")}(if any)`}>
              <Input
                value={invoiceNo}
                onChange={(e) => {
                  const inputValue = e.target.value;
  const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, ''); // Remove anything that's not a letter or digit
  setInvoiceNo(sanitizedValue);
                }}
                onKeyDown={(e)=>{
                  if (e.key === ' ' || e.keyCode === 32) {
                    // Prevent the space key from being entered
                    e.preventDefault();
                  }
                }}
                className="textUpperCase"
              />
            </FormItem>
          </Col>
          <Col xs={24} className="alignRight marginTop20">
            <Button
              type={"default"}
              disabled={loading}
              onClick={resetValues}
              style={{ marginRight: 10 }}
              icon='close'
            >
              {I18n.t("general.cancel")}
            </Button>
            <Button
              type={"primary"}
              // disabled={
              //   (is_pickup_by_driver !== "true" && isEmpty(invoiceNo)) ||
              //   loading
              // }
              onClick={handleSave}
              size={buttonSize ? buttonSize : "default"}
              icon="file-done"
            >
              {I18n.t("invoices.approve")}
            </Button>
          </Col>
        </Row>
      </div>
    );
    return (
      <Modal
        title={I18n.t('invoices.do_you_want_to_provide_invoice_no')}
        visible={showInvoiceNoWindow}
        okButtonProps={{
          style: { display: 'none'}
        }}
        onCancel={resetValues}
        // confirmLoading={loading}
        closable
        width={400}
        footer={null}
        maskClosable={false}
      >
        <Spin spinning={loading} delay={1000}>
          {content}
        </Spin>
      </Modal>
    )
  }

  return (
    <Fragment>
      {!(action === 'ADD_TO_EXISTING' && invoices.length === 0) && (
          <Button
            type={buttonType ? buttonType : 'primary'}
            className={className ? className : ''}
            loading={loading}
            disabled={isDisabled}
            icon={"file-done"}
            size={buttonSize ? buttonSize : "default"}
            onClick={() => handleValidate()}
          >
            {label}
          </Button>
      )}
      {showInfoWindow && renderModalWindow()}
      {showInvoiceNoWindow && renderInvoiceNoWindow()}
    </Fragment>
  )
}

ApproveInvoices.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  resetOrders: PropTypes.func.isRequired,
  handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
  statusChange: PropTypes.func.isRequired,
  buttonSize: PropTypes.string,
};
ApproveInvoices.defaultProps = {
  buttonSize: "default",
};

export default ApproveInvoices

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import AddressInfo from '../common/AddressInfo';
import { Col, Row, Icon, Tooltip } from '../../common/UIComponents';
import { doFormate,isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import warehouse from '../../../assets/images/warehouse.png';
import { formatUSPhoneNumber } from '../../helpers/common';


const ShipperDetails = ({ data, showImage }) => {
  const shName = doFormate(data.shipr_first_name);
  const shPhone = doFormate(data.shipr_phone_num);
  const shEmail = doFormate(data.shipr_email);
  const shContact = doFormate(data.shipr_contact);
  const l_address = {
    address_line1: data.shipr_address_line1 ? data.shipr_address_line1 : '',
    address_line2: data.shipr_address_line2 ? data.shipr_address_line2 : '',
    city: data.shipr_city ? data.shipr_city : '',
    state: data.shipr_state ? data.shipr_state : '',
    country: data.shipr_country ? data.shipr_country : '',
    zipcode: data.shipr_postal_code ? data.shipr_postal_code : '',
  };
  return (
    <Row style={{ minHeight: 100 }}>
      { showImage !== false &&
      <Col span={8}>
        <div className="">
          <img src={`${warehouse}`} style={{ width: 70 }} alt="WH" />
        </div>
      </Col>
      }
      <Col span={showImage ? 16 : 24}>
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="bank" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}>
            <div className="info-text textBold"> <Tooltip title={I18n.t('preplan.warehouse_name')}>{shName} {!isEmpty(data.shipr_last_name) ? data.shipr_last_name : ''}</Tooltip></div>
          </Col>
        </Row>
        {shContact !== 'NA' &&
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="user" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}><div className="info-text">{shContact}</div></Col>
        </Row>
        }
        {shEmail !== 'NA' &&
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="mail" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}> <div className="info-text">{shEmail}</div></Col>
        </Row>
        }
        {shPhone !== 'NA' &&
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="phone" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}><div className="info-text">{formatUSPhoneNumber(shPhone)}</div></Col>
        </Row>
        }
        {l_address && Object.keys(l_address).length > 0 && !isEmpty(l_address.address_line1) && 
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="environment" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}>
            <div className="info-text alignLeft">
              <AddressInfo address={l_address} />
            </div>
          </Col>
        </Row>
        }
      </Col>
    </Row>
  );
};
export default ShipperDetails;

ShipperDetails.propTypes = {
  data: PropTypes.shape().isRequired,
  showImage: PropTypes.bool,
};
ShipperDetails.defaultProps = {
  showImage: true,
};


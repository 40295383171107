import { Table } from "antd";
import React from "react";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import DisplayTime from "../common/DisplayTime";


const  WMSList = (props) =>  {
const columns = [
  {
    title: 'Comments',
    key : "comments",
    dataIndex: 'comment',
    render: (data) => <span>{data}</span>,
  },
  {
    title: 'Captured At',
    key : 'createdAt',
    dataIndex: 'createdAt',
    render: (data) => (
      data ? <span>
        <DisplayTime
          dateTimeString={data}
          timeFormat={AppConfig.dateTimeFormat}
          displayWithDate={true}
          timeZoneId={props.timeZoneId}
          isUTC={false}
        />
        {` ${props.order_tz_short_name}`}
      </span> : 'NA'
    )
  },
  {
    title: 'Captured By',
    key : "createdBy",
    dataIndex: 'createdBy',
    render: (data) => <span>{data ? data : '-'}</span>,
  },
];

  return (
    <div>
      <h4 className="primaryText textBold fontSize18 paddingTop10">Warehouse Comments</h4>
      <Table columns={columns} size="large"  dataSource={props.data || []} pagination={{ position: "none" }}/>
    </div>
  );
}
  export default WMSList;
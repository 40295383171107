import React, { useState, Fragment } from "react";
import misc_icon from "../../../assets/images/download-pdf.svg";
import { Dropdown, Button, Icon, Row, Col, Modal, Tooltip } from "antd";
import Copyable from "../common/Copyable";
import CopyableContent from "../common/CopyableContent";
import StickyNote from "./StickyNote";
import { Draggable } from "react-draggable";

const KeyValueComponent = ({ data }) => {
  const renderKeyValue = (object) => {
    if (_.isObject(object)) {
      return "";
    }
    return Object.keys(object).map((key) => (
      <Row key={key} type={"flex"} className="marginTop5" gutter={16}>
        <Col>{key} :</Col>
        <Col>
          <CopyableContent text={JSON.stringify(object[key], null, 4)} />
        </Col>
      </Row>
    ));
  };

  return <div className="key-value-container">{renderKeyValue(data)}</div>;
};

const MiscData = ({ pdf_response = {}, position = null }) => {
  const { file_url, response } = pdf_response;
  const [filterWindow, setFilterWindow] = useState(false);
  const handleClose = () => {};
  return (
    <Fragment>
      {false && (
        <Dropdown
          onVisibleChange={(flag) => setFilterWindow(flag)}
          visible={filterWindow}
          trigger={["click"]}
          overlay={
            <div
              style={{
                backgroundColor: "white",
                padding: 15,
                marginTop: 10,
                minWidth: 550,
                padding: 15,
                marginLeft: 40,
              }}
              className="boxShadow"
            >
              <h2>PDF Information</h2>
              <KeyValueComponent data={response} />
            </div>
          }
        >
          <Button shape="circle" size="small">
            <Icon
              component={() => (
                <img
                  style={{ height: "20px", marginTop: -5 }}
                  src={misc_icon}
                />
              )}
              style={{ color: "red" }}
            />
          </Button>
        </Dropdown>
      )}
      {!filterWindow && (
        <Tooltip  title="Pdf Info">
        <Button
          shape="circle"
          size="small"
          onClick={() => setFilterWindow(true)}
         
        >
          <Icon
            component={() => (
              <img style={{ height: "20px", marginTop: -5 }} src={misc_icon} />
            )}
            style={{ color: "red" }}
          />
        </Button>
        </Tooltip>
      )}

      {filterWindow && (
        <StickyNote
          title="Note Title"
          onClose={() => setFilterWindow(false)}
          className="sticky-note"
          info={pdf_response}
          position={position}
        >
          {/* <pre className="settingSubTabScroll">
            {JSON.stringify(additionalInformation, null, 4)}
          </pre> */}

          {JSON.stringify(pdf_response, null, 4)}
        </StickyNote>
      )}
    </Fragment>
  );
};

export default MiscData;

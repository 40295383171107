import React from 'react'
import I18n from '../../common/I18n';
import BaseModal from '../BaseModal';
import ItemsExceptionsList from './ItemsExceptionsList';

const ItemsExceptionModal = ({ visible, onCancel, data,isMilitaryTime }) => {
    const itemName = _.get(data,"item_name", "");
    return (
    <BaseModal
    title={`${I18n.t("item_exceptions.title")}${itemName ? ` - ${itemName}` : ''}`}
      visible={visible}
      onCancel={onCancel}
      width={800}
    >
      <ItemsExceptionsList
        data={ _.get(data,"item_picture_data", [])}
        isMilitaryTime={isMilitaryTime}
      />
    </BaseModal>
  )
};
  
  export default ItemsExceptionModal;

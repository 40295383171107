import { Button, Row, Col, Modal } from "antd";
import React, { useContext, useEffect, useState, Fragment } from "react";
import InvoiceAdjustmentForm from "./InvoiceAdjustmentForm";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import { renderAlertMessage } from "../../helpers/common";
import { deleteAdjustment, getAdjustments } from "../../api/Billing";
import { OrgContext } from "../../context/OrgContext";
import ManageInvoiceLine from "./ManageInvoiceLine";
import LinesList from "./LinesList";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";

const InvoicesLines = (props) => {
  const { order } = props;
  const { currentOrg } = useContext(OrgContext);
  const [isLoading, setIsLoading] = useState(false);
  const [adjustments, setAdjustments] = useState([]);


  const handleDelete = (id) => {
    setIsLoading(true);
     deleteAdjustment({
      invoice_line_id: id
     }).then((result) => {
        if (result.success) {
          alertMessage(
            result.message ? result.message : I18n.t("messages.deleted"),
            "success",
            10
          );
          getInvoiceLines();
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        setIsLoading(true);
      });
  };

  const getInvoiceLines = async () => {
    try {
      // Call saveAddAdjustment if dataToEdit is empty
      const result = await getAdjustments({
        organization_id: currentOrg.id,
        order_id: order?.id,
      });
      if (result.success) {
        const adjustments_line = result.adjustment_lines || []
        setAdjustments(adjustments_line)
      } else {
        renderAlertMessage(result.errors || []);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    getInvoiceLines();
  }, []);

  const renderLocationBlock = (loc, size = 12) => {
    const adjustments_lines = adjustments.filter(adj => adj.location_id === loc.id)
    return (
      <Col md={size}>
        <Row>
          <Col md={12} className="textBold">{loc.type_of_loc ? loc.type_of_loc : ''}</Col>
          <Col md={12} className="alignRight">
            <ManageInvoiceLine
              order_id={order.id}
              location_id={loc.id}
              action_from="order"
              order={order}
              resetList={getInvoiceLines}
            />
          </Col>
        </Row>

        <LinesList
          data={adjustments_lines}
          pagination={{ position: "none" }}
          handleDelete={handleDelete}
          order={order}
          location_id={loc.id}
          action_from="order"
          scroll={{ x: `100%`, y: 200 }}
          resetList={getInvoiceLines}

        />
      </Col>
    );
  };

  return (
    <Row gutter={24}>
      {order?.locations?.length > 0
        ? order.locations.map((loc) => renderLocationBlock(loc, 12))
        : renderLocationBlock(order.cs_location, 24)}
    </Row>
  );
};

export default InvoicesLines;

import { Icon, List, Table } from 'antd';
import _ from 'lodash';
import Text from 'antd/lib/typography/Text';
import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import greenMarker from "../../assets/images/cyan_filled.png";
import redMarker from "../../assets/images/red_filled.png";
import selectedMarker from "../../assets/images/purple_filled.png";
import blueMarker from "../../assets/images/blue_filled.png";
import { generateLetterMarker } from '../helpers/map';
import { retrieveMapIdentity } from '../helpers/orders';
import { OrgContext } from '../context/OrgContext';
import { pinLikeMarker } from '../components/dispatches/helpers';

function MultiMarkers (props) {
  const { orderInfo , rowKeys , latLngArray , onMarkerHover ,hoveredMarker   } = props;
  const [infoWindowData , setInfoWindowData] = useState({});
  const [isLoading , setIsLoading] = useState(true);
  const { isAirCargo  }= useContext(OrgContext);


  const onMarkerSelect = (rowKey) => {
    onMarkerHover(orderInfo[rowKey].customer_order_id);
    props.handleMarkerSelection(rowKey);
  }

  const renderOrdernumbers = (address = {}) => {
    const isAirCargoOrg = isAirCargo()
    const [ lon, lat ] = address.coordinates;
    const sameLocationOrders = orderInfo.filter((order) => {
      if(order?.customer_address?.coordinates?.length > 1){
        const orderLat = order.customer_address.coordinates[ 1 ];
        const orderLong = order.customer_address.coordinates[ 0 ];
        return lat === orderLat && lon === orderLong;
      }
      return false;
    }
    );
    return sameLocationOrders.map((order) => retrieveMapIdentity( isAirCargoOrg,order));
  };

  const renderCompanyDetails = (item, index) => {
    let dataToDisplay = "N/A";
    if (orderInfo[index].customer_order_number === item || orderInfo[index].hawb === item) {
      if (!_.isEmpty(orderInfo[index].company_name)) {
        dataToDisplay = orderInfo[index].company_name
      }
    }
    return (
      <span style={{ fontWeight: 600 }}>
        {dataToDisplay}
      </span>
    )
  }

  const preCalculateInfoWindowData = () => {
    setIsLoading(true);
    const orderNumbers = {};
    orderInfo.forEach((order) => {
      // object validation
      if(_.isEmpty(order.customer_address) || _.isEmpty(order.customer_address.coordinates)){
        return;
      }
      const [ lon, lat ] = order.customer_address.coordinates;
      orderNumbers[ `${lat}${lon}` ] = renderOrdernumbers(order.customer_address);
    }
    );
    setInfoWindowData(orderNumbers);
    setIsLoading(false);
  }

  useEffect(() => {
    preCalculateInfoWindowData();
  }, []);

  useEffect(() => {
    preCalculateInfoWindowData();
  } , [orderInfo]);


	const clusterIcon = (address ,clusterLength ) => {
    let letterMarker = "";
    const validOrders = orderInfo.filter((order) => {
      return !_.isEmpty(order.customer_address) && !_.isEmpty(order.customer_address.coordinates);
    });
    // check cluster status by checking rowKeys array and get orders cooridnates which are selected in rowKeys array and check if they are same as the marker coordinates
    let clusterSelectionStatus = "Not Selected";
    if (rowKeys.length > 0) {
      const selectedOrders = rowKeys.reduce((acc, rowKey) => {
        const [lon , lat] = _.isEmpty(validOrders[rowKey]) ? [0,0] : validOrders[rowKey].customer_address.coordinates;
        acc.push({
          lat,
          lon
        });
        return acc;
      }
      , []).filter((coords) => {
        const { lat, lon } = address;
        return coords.lat === lat && coords.lon === lon;
      }
      );

      // and check if length of selectedOrders is equal to clusterLength
      if (selectedOrders.length) {
        if (selectedOrders.length === clusterLength) {
          clusterSelectionStatus = "Selected";
        }
        else {
          clusterSelectionStatus = "Partially Selected";
        }
      }
    }
    if(clusterSelectionStatus === "Selected"){
      // letterMarker = generateLetterMarker( clusterLength, "9b59b6", "FFFFFF" );
      letterMarker = pinLikeMarker(clusterLength, "9b59b6", "FFFFFF", 24);
    }else if(clusterSelectionStatus === "Partially Selected"){
      // letterMarker = generateLetterMarker( clusterLength, "F08080", "000000" );
			letterMarker = pinLikeMarker(clusterLength, "F08080", "000000", 24);
    }else{
      // letterMarker = generateLetterMarker( clusterLength, "ffbc00", "000000" );
      letterMarker = pinLikeMarker(clusterLength, "ffbc00", "000000", 24);
    }

    return letterMarker;
	};

  if (isLoading || (latLngArray.length !== orderInfo.length)) {
    return null; 
  }
  return (
    latLngArray.map((address, index) => {
      const clusterInfo = {
        length: infoWindowData[`${address.lat}${address.lon}`] ? infoWindowData[`${address.lat}${address.lon}`].length : 0,
        address: address,
        isCluster: infoWindowData[`${address.lat}${address.lon}`] ? infoWindowData[`${address.lat}${address.lon}`].length > 1 : false
      }
      return (
        <Marker position={ {
          lat: address.lat,
          lng: address.lon
        } }
          icon={
            clusterInfo.isCluster ? {
              url: clusterIcon(address ,clusterInfo.length),
            } : 
            {
              url: rowKeys.includes(index) ? selectedMarker : orderInfo[ index ].type_of_loc === "DELIVERY" ? greenMarker : redMarker,
              // url: rowKeys.includes(index) ? selectedMarker : greenMarker,
              size: { width: 20, height: 20 },
              scaledSize: { width: 20, height: 20 },
            }
          }
          onMouseOver={ () => { onMarkerHover(orderInfo[ index ].customer_order_id); } }
          onClick={() => onMarkerSelect(index)}
        >
          { hoveredMarker === orderInfo[ index ].customer_order_id && (
            <InfoWindow onCloseClick={ () => { onMarkerHover(null); } }>
              <div style={{ padding: '5px', maxWidth: '380px' }}>
                {!_.isEmpty(orderInfo[index].customer_address.address_line1) && <div style={{ marginBottom: "12px" }}>
                  <Text strong style={{ display: "block", fontSize: "14px" }}>
                    <Icon style={{ marginRight:'10px' }} type="home" theme="twoTone" />
                    {orderInfo[index].customer_address.address_line1}
                  </Text>
                </div>}
                {/* <div style={ { marginBottom: '8px' } }>
                  <Text type="secondary" style={ { fontSize: '12px' } }>Company</Text>
                  <Text strong style={ { display: 'block', fontSize: '14px' } }>{ orderInfo[ index ].company_name || 'N/A' }</Text>
                </div>
                <Text type="secondary" style={ { fontSize: '12px', marginBottom: '4px', display: 'block' } }>Order Numbers</Text>
                <List
                  size="small"
                  dataSource={ infoWindowData[ `${address.lat}${address.lon}` ] }
                  renderItem={ item => (
                    <List.Item style={ { padding: '2px 0', borderBottom: 'none' } }>
                      <Text strong style={{ fontSize: '13px' }}>{item} {orderInfo[index].customer_order_number === item ? orderInfo[index].company_name : ''}</Text>
                    </List.Item>
                  ) }
                /> */}

                <Table
                  pagination={false}
                  dataSource={infoWindowData[`${address.lat}${address.lon}`]}
                  columns={[
                    {
                      title: "Order No/HAWB",
                      render: (text) => <span style={{ fontWeight: 600 }}>{text}</span>,
                      width: 120,
                    },
                    {
                      title: "Company",
                      render: (item) => (renderCompanyDetails(item, index)),
                      width: 250,
                    },
                  ]}
                />
              </div>
            </InfoWindow>
          ) }
        </Marker>
      );
    })
  );
}

export default MultiMarkers;
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Col, FormItem, Input, Select, Checkbox, Row, SideBySideFormItem } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";

const OrderActivityForm = (props) => {
  const {
    formData = {},
    handleOnChange,
    gridSize = {
      // xs: 24,
      // sm: 24,
      // md: 12,
      // lg: 8,
      // xl: 8,
    },
  } = props;

  const isPickup = formData.type_of_loc === "PICKUP";

  const renderCheckbox = (label, field, checked) => (
    <Col { ...gridSize }>
      <div style={{ marginTop: -10 }}>
        <label htmlFor={ field } style={ { cursor: 'pointer' } }>
          <Checkbox
            id={ field }
            onChange={ (e) => {
              handleOnChange(field, e.target.checked)
              if(!e.target.checked) handleOnChange(field === "is_recovery" ? "recovery_status" : "release_status", undefined )
            } }
            checked={ checked }
          />
          { label }
        </label>
      </div>
    </Col>
  );

  const renderSelect = (label, field, options, value, disabled) => {
    return (
    <Col { ...gridSize }>
      <SideBySideFormItem label={label} require={!disabled} 
        labelCol={{ lg: 13, xl: 13, xxl: 13 }}
        wrapperCol={{ lg: 11, xl: 11, xxl: 11 }}
      > 
        <Select
          style={ { minWidth: 100, width: "100%" } }
          className="routes-input"
          onChange={ (e) => handleOnChange(field, e) }
          defaultValue={undefined}
          value={ value ? value : "-- Select --" }
          size="small"
          filterOption={ (input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={ disabled }
          showSearch
        >
          { options.filter(statusRec => !['assigned', 'completed'].includes(statusRec.value)
          ).map((statusRec) => (
            <Select.Option value={ statusRec.value } key={ statusRec.value }>
              { statusRec.text }
            </Select.Option>
          )) }
        </Select>
      </SideBySideFormItem>
    </Col>

    )
  };

  return (
    <Row type="flex" gutter={8} align="middle">
      { isPickup
        ? renderCheckbox(" Is Recovery", "is_recovery", formData.is_recovery)
         :  renderCheckbox(" Is Release", "is_release", formData.is_release)}

      { isPickup
        ? renderSelect(
          I18n.t("recoveries.recovery_status"),
          "recovery_status",
          AppConfig.recoveryStatuses,
          formData.recovery_status,
          !formData.is_recovery
        )
        :
        renderSelect(
          I18n.t("releases.release_status"),
          "release_status",
          AppConfig.releaseStatuses,
          formData.release_status,
          !formData.is_release
        )
        }
    </Row>
  );
};

export default OrderActivityForm;

OrderActivityForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

OrderActivityForm.defaultProps = {
  predefined_locations: "true",
};

/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Icon, Button } from '../../common/UIComponents';
import { checkNegitive,  isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import Profile from '../common/Profile';
import InvoiceContact from './InvoiceContact';
import AppConfig from '../../config/AppConfig';
import imagePlaceholder from '../../../assets/images/not_available.png';

const Header = (props) => {
  const {
    invoice, isReadyOnly,
  } = props;
  return invoice &&
  <Row>
    { false &&
    <Fragment>
      <Col sm={24} xs={24} md={12} lg={12}>
        <h4>{I18n.t('invoices.total')}: {checkNegitive(invoice.total_amount)}</h4>
      </Col>
      <Col sm={24} xs={24} md={12} lg={12} className="alignRight">
        { !isReadyOnly &&
        <Button
          type="link"
          size='small'
          onClick={() => this.addItem()}
        >
          <Icon type="plus-circle" />&nbsp;{I18n.t('invoices.add_charge')}
        </Button>
        }
      </Col>

    </Fragment>
    }

    { false &&
    <Fragment>
      <Col xs={8}>
        <h3 className="main_heading">{invoice.warehouse_details.name}</h3>
        <InvoiceContact data={invoice.warehouse_details} showIcon />
      </Col>

    </Fragment>

    }
    <Col xs={8} className="smallContent">
      <h3 className="main_heading">{I18n.t('account.details')}</h3>
      <span className="textBold">
        <Fragment>{invoice.account_details.name}</Fragment>
      </span>
      <InvoiceContact
        data={invoice.account_details}
        showIcon
        showAddr={false}
      />
    </Col>
    <Col xs={8} className="smallContent">
      <h3 className="main_heading">{I18n.t('general.driver_details')}</h3>

      { !isEmpty(invoice.driver_details) && <Profile
        address={{}}
        profile={(({
          name, contact, customer_phone_two, email,
        }) => ({
          image: imagePlaceholder,
          full_name: `${name}`,
          mobile_number: `${contact} ${!isEmpty(customer_phone_two) ? `,${customer_phone_two}` : ''}`,
          email,
        }))(invoice.driver_details)}
        displayType='line'
        showImage={false}
        withLables={false}

      />
      }
    </Col>
    <Col xs={8}>
      <table>
        <tbody>
          {/* <tr>
            <td>{I18n.t('order.weight')}</td>
            <td>:</td>
            <td>{invoice.order_info.order_weight ? `${invoice.order_info.order_weight} ${AppConfig.weight_unit}` : 'NA'}</td>
              </tr>
          */}
          <tr>
            <td>{I18n.t('zones.zonename')}</td>
            <td>:</td>
            <td>{invoice.order_info.zone_name || 'NA'}</td>
          </tr>
          <tr>
            <td>{I18n.t('zones.type')}</td>
            <td>:</td>
            <td>{invoice.order_info.zone_type || 'NA'}</td>
          </tr>
          { invoice.order_info.zone_type === 'location' &&
          <tr>
            <td>{I18n.t('general.zipcode')}</td><td>:</td><td>{invoice.order_info.zipcode || 'NA'} </td>
          </tr>
          }
          { invoice.order_info.zone_type === 'distance' &&
          <tr>
            <td>{I18n.t('general.distance')}</td>
            <td>:</td>
            <td>
              {
                !isEmpty(invoice.order_info.distance) ?
                  `${parseFloat(invoice.order_info.distance).toFixed(2)} ${AppConfig.distance_unit}`
                  : 'NA'
              }
            </td>
          </tr>
          }
          <tr>
            <td>{I18n.t('los.label')}</td>
            <td>:</td>
            <td>{invoice.order_info.level_of_service || 'NA'}</td>
          </tr>
        </tbody>
      </table>
    </Col>

  </Row>;
};

export default Header;

Header.propTypes = {
  invoice: PropTypes.shape().isRequired,
  isReadyOnly: PropTypes.bool.isRequired,
};

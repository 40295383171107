import React from "react";

const DisplaySettingsContext = React.createContext({
    userConfiguration : {},
    orgConfiguration : {},
    setUserConfiguration : () => {},
    setOrgConfiguration : () => {},
    isFetchDisplaySettings : false
});

export {DisplaySettingsContext};
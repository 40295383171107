import React from "react";
import { LhCreateForm } from './LhCreateForm';

export const LineHaulDetails = (props) => {
  const {
    warehouses,
    accounts,
    refreshAccounts,
    orderId,
    accountInfo
  } = props;

  return (
    <div>
      <LhCreateForm
        warehouseFilter={props.warehouseFilter}
        warehouses={warehouses}
        accounts={accounts}
        refreshAccounts={refreshAccounts}
        orderId={orderId}
        accountInfo={accountInfo}
      />
    </div>
  );
};

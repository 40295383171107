import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthenticationService } from '../api/Auth';
import _, { set } from 'lodash';
import { handleCurrentRole, handleSelectedOrg } from './helpers';
// import LogisticsLoadingScreen from '../components/common/LogisticsLoadingScreen';
import userStore from '../stores/UserStore';
import { UserServices } from '../api/UsersApi';
import AppConfig from '../config/AppConfig';
import { WarehouseContext } from './WarehouseContext';
import { AppLoadingScreen } from '../components/common/LogisticsLoadingScreen';

// Create a context for user-related data
const UserContext = createContext({});

// Define a provider component for the UserContext
const UserContextProvider = ({ children }) => {
  // Define state variables using the useState hook
  const [ currentUser, setCurrentUser ] = useState({});
  const [ currentOrg, setCurrentOrg ] = useState('');
  const [ currentRole, setCurrentRole ] = useState('');
  const [ isLoading, setIsLoading ] = useState(true);
  const [ permitViews, setPermitViews ] = useState([]);
  const [rbacs, setRbacs] = useState([]);
  const [orgRules, setOrgRules] = useState({});

  const { selectedWarehouses } = useContext(WarehouseContext);

  // Initialize state for the active warehouse
  const [ activeWarehouseid, setActiveWarehouseid ] = useState([]);

  // Retrieve token details from local storage
  const tokenDetails = JSON.parse(localStorage.getItem("auth"));

  async function fetchCurrentUser () {
    try {
      setIsLoading(true);
      // Call the AuthenticationService to get the current user data
      let { success, data: UserData } = await AuthenticationService.currentUser();
      let perPageSaved = {};
      let columnWidths = {};

      if (UserData.screen_config) {
        Object.entries(UserData.screen_config).forEach(([ key, value ]) => {
          perPageSaved[ key ] = value.per_page_saved || AppConfig.perPage;
          columnWidths[ key ] = value.column_widths || {};
        });
      }

      UserData.per_page_saved = perPageSaved;
      UserData.column_widths = columnWidths;

      if (success) {
        // Store user data and related information in local storage and state
        UserData.permittable_screens = [ "dashboard", "orders" ];
        localStorage.setItem("CURRENT_USER", JSON.stringify(UserData));
        localStorage.setItem("selectedOrg", currentOrg);
        userStore.init(UserData);
        setCurrentUser(UserData);
        setActiveWarehouseid(UserData.active_warehouse_ids);
        const { id: userId, currentOrg, roles: userRoles } = UserData;
        const role = handleCurrentRole(userRoles);
        setCurrentRole(role);
        if(role !== 'admin'){
          const rbacCodes = _.get(UserData, 'rbac', []).map(rbac => rbac.operation_code);
          setRbacs(rbacCodes);
          userStore.setStateValue("rbac", UserData?.rbac ? UserData.rbac : []);
        }else{
          userStore.setStateValue("rbac", []);
        }

        // Set the current organization if the user is not a super admin
        if (role !== 'super_admin') {
          const selectedORG = handleSelectedOrg(currentOrg);
          setCurrentOrg(selectedORG);
        }
      } else {
        // Handle error when fetching user data
      }
    } catch (error) {
      // Handle any other errors that might occur
    } finally {
      // Set isLoading to false once data is fetched or an error occurs
      setIsLoading(false);
    }
  }

  // Use the useEffect hook to fetch current user data when the component mounts
  useEffect(() => {
    fetchCurrentUser();
  }, []); 

  const updateScreenConfiguration = async (page_name, value, config_type, updateToUserData = true) => {
    const userDataCopy = { ...currentUser };
    if(updateToUserData){
      userDataCopy[ config_type ][ page_name ] = value;
    } else {
      if(!userDataCopy['screen_config']?.[ page_name ]){
        userDataCopy['screen_config'] = {
          [page_name]: {}
        }
      }
      userDataCopy['screen_config'][ page_name ][config_type] = value;
    }
    const payload = {
      parent_key: page_name,
      config_type,
      config_value: value
    };
    UserServices.saveScreenConfiguration(payload).then((response) => {
      if (response.success) {
        localStorage.setItem("CURRENT_USER", JSON.stringify(userDataCopy));
        userStore.init(userDataCopy);
        setCurrentUser(userDataCopy);
      } else {
        // handle error
      }
    });
  };

  const updateRecordsPerPage = async (page_name, value) => {
    await updateScreenConfiguration(page_name, value, "per_page_saved");
  };

  const updateColWidths = async (page_name, value) => {
    await updateScreenConfiguration(page_name, value, "column_widths");
  };
  const updateScreenConfig = async (page_name, value, config_type) => {
    await updateScreenConfiguration(page_name, value, config_type, false);
  };

  const checkScreenAccessbility = (
    screenCode
   ) => {
    if(userStore.admin() || userStore.superAdmin()){
      return true;
    }
    if(!currentUser?.permitViews?.length || (
      currentUser.permitViews.length > 0 && _.findIndex(currentUser.permitViews, { code : screenCode}) >= 0)) {
      return true
    } 
    return false;;
  }; 

  // Define context values to be provided to child components
  const contextValues = {
    currentUser,
    setCurrentUser,
    currentOrg,
    setCurrentOrg,
    tokenDetails,
    currentRole,
    handleSelectedOrg,
    handleCurrentRole,
    updateRecordsPerPage,
    updateColWidths,
    setPermitViews,
    checkScreenAccessbility,
    rbacs,
    setOrgRules,
    orgRules,
    activeWarehouseid,
    setActiveWarehouseid,
    updateScreenConfig
  };

  // Render a loading screen while data is being fetched, or the context provider with child components
  return (
    isLoading ? <AppLoadingScreen /> : <UserContext.Provider value={ contextValues }>{ children }</UserContext.Provider>
  );
};

// Export the UserContext and UserContextProvider for use in other parts of the application
export { UserContext, UserContextProvider };

import React, { Fragment, useEffect, useState, useContext } from 'react'
import _, { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import {
  Button,
  Col,
  Row,
  Form,
  DatePicker,
  Popconfirm,
} from 'antd'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import {
  alertMessage,
  doValidate,
  validateAddressForm,
} from '../../common/Common'
import FormErrors from '../common/FormErrors'
import moment from 'moment'
import LocationForm from '../common/LocationForm'
import { loadGoogleMaps } from '../../utils/Utils'
import { RecoveriesApis } from '../../api/RecoveriesApi'
import { FormItem, TextArea } from '../../common/UIComponents'
import DispatcherNotes from '../orders/DispatcherNotes'
import { dispatcherNotesApi } from '../../api/OrdersApi'
import DateTimeSelector from '../../common/DateTimeSelector';
import { checkServiceExistance } from '../../helpers/common'
import { OrgContext } from '../../context/OrgContext';
import { momentTime } from '../../helpers/date_functions'

const requiredFields = [
  {
    form_field: 'first_name',
    ui_name: I18n.t('general.first_name'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'last_name',
    ui_name: I18n.t('general.last_name'),
    isRequired: false,
    inputType: 'text',
  },
  {
    form_field: 'email',
    ui_name: I18n.t('general.email'),
    isRequired: false,
    inputType: 'email',
  },
  {
    form_field: 'phone_number_one',
    ui_name: `${I18n.t('general.contact_no')} 1`,
    isRequired: true,
    inputType: 'mobile',
  },
  {
    form_field: 'phone_number_two',
    ui_name: `${I18n.t('general.contact_no')} 2`,
    isRequired: false,
    inputType: 'mobile',
  },
]

function RecoveryDetailsForm(props) {
  const {
    typeOfOrder = 'M',
    order_id,
    clickedSave,
    recoveryParams,
    currentRecovery,
    deleteClick,
    isNew,
    getOrderRecoveries,
  } = props

  const [errors, setErrors] = useState([])
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ formData, setFormData ] = useState({})
  const [driverNotes, setDriverNotes] = useState([])
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const isMilitaryTime = organizationSettings.is_military_time === 'true'

  const getOriverNotes = () => {
     dispatcherNotesApi.fetch(currentRecovery.id).then((response)=>{
        if(response.success){
          setDriverNotes(response.dispatch_notes)
        }
      })
  }

  useEffect(() => {
    if (window.google) {
      setIsMapLoaded(true)
    } else {
      loadGoogleMaps(() => {
        setIsMapLoaded(true)
      })
    }
    const modifiedRecovery = {
      ...currentRecovery,
      recovery_eta_date: !isEmpty(currentRecovery.recovery_eta) ? momentTime("YYYY-MM-DD", currentRecovery.recovery_eta, currentRecovery.pickup_timeZoneId): null,
      recovery_eta_time: !isEmpty(currentRecovery.recovery_eta) ? momentTime(isMilitaryTime ? "HH:mm" : "hh:mm A", currentRecovery.recovery_eta, currentRecovery.pickup_timeZoneId) : null,
    };
    setFormData({ ...modifiedRecovery })
    if(currentRecovery.id){
      getOriverNotes();
    }
  }, [])

  const validateForm = () => {
    let validationErrors = []
    Object.keys(recoveryParams).forEach((key, index) => {
      const details = !isEmpty(formData[key]) ? formData[key] : {}
      const addressErrors = validateAddressForm(details.l_address)
      const commonErrors = doValidate(
        requiredFields,
        details,
        `In ${recoveryParams[key]} details, `,
      )
      validationErrors = [].concat(
        validationErrors,
        commonErrors,
        !isEmpty(addressErrors)
          ? [` ${addressErrors} in ${recoveryParams[key]} Address`]
          : [],
      )
    })
    const cleanedErrors = _.compact(validationErrors)
    if (
      !isEmpty(formData[ 'recovery_eta_date' ]) && !isEmpty(formData[ 'recovery_eta_time' ]) &&
      !isEmpty(formData['recovery_dead_line'])
    ) {
      
      const etaDate = moment(formData[ 'recovery_eta_date' ]).format('YYYY-MM-DD');
      const deadlineDate =  moment(formData['recovery_dead_line']).format('YYYY-MM-DD');
      if (
        moment(deadlineDate).isBefore(
          moment(etaDate),
          'day',
        )
      ) {
        cleanedErrors.push(
          `${I18n.t('recoveries.deadline')} should not be before ${I18n.t(
            'recoveries.recovery_eta',
          )}`,
        )
      }
    }
    if (cleanedErrors.length > 0) {
      setErrors([...cleanedErrors])
    } else {
      setErrors([])
      handleSave()
    }
  }

  const handleSave = async () => {
    try {
      setIsLoading(true);

      // Clone the form data
      const payload = _.cloneDeep(formData);

      // Set the organization ID
      payload.organization_id = userStore.getStateValue('selectedOrg');

      // Set the customer order ID
      payload.customer_order_id = order_id;

      const timeFormat = isMilitaryTime ? 'HH:mm' : 'hh:mm A';
      // Set the recovery ETA
      if (!isEmpty(payload.recovery_eta_date) && !isEmpty(payload.recovery_eta_time)) {
        payload.recovery_eta = moment(payload.recovery_eta_date).format('YYYY-MM-DD') + ' ' + moment(payload.recovery_eta_time, timeFormat).format('HH:mm');
      } else {
        payload.recovery_eta = '';
      }

      // Set the recovery dead line
      if (!isEmpty(payload.recovery_dead_line)) {
        payload.recovery_dead_line = moment(payload.recovery_dead_line).format('YYYY-MM-DD');
      } else {
        payload.recovery_dead_line = '';
      }

      // delete the recovery ETA date and time
      delete payload.recovery_eta_date;
      delete payload.recovery_eta_time;

      // Create or update the recovery
      let response = {};
      if (currentRecovery._id) {
        payload.id = currentRecovery._id
        response = await RecoveriesApis.update(currentRecovery._id, payload)
      } else {
        response = await RecoveriesApis.create(payload)
      }

      // Check the response
      if (response.success) {
        setIsLoading(false);
        setErrors([]);

        // Display a success message
        const successMessage = currentRecovery._id
          ? I18n.t('messages.updated')
          : I18n.t('messages.saved');
        alertMessage(successMessage, 'success', '5');

        // Get the order recoveries
        getOrderRecoveries();
      } else {
        setIsLoading(false);
        setErrors([ ...response.errors ]);
      }
    } catch (error) {
      setIsLoading(false);
      setErrors([ error.message ]);
    } finally {
      // Clear the loading state
      // setIsLoading(false);
    }
  };


  const handleOnChange = (orderType, element, value) => {
    const updatedFormData = _.cloneDeep(formData)
    if (isEmpty(orderType)) {
      updatedFormData[element] = value
      if (element === "recovery_eta_time") {
        // set recovery_eta_date to today's date if empty
        if (isEmpty(updatedFormData.recovery_eta_date)) {
          updatedFormData.recovery_eta_date = moment();
        }
      } else if (element === "recovery_eta_date") {
        // set recovery_eta_time to current time if empty
        if (isEmpty(updatedFormData.recovery_eta_time)) {
          updatedFormData.recovery_eta_time = moment();
        }
      }

    } else {
      if (isEmpty(updatedFormData[orderType])) {
        updatedFormData[orderType] = {}
      }
      updatedFormData[orderType][element] = value
    }
    setFormData(updatedFormData)
  }

  const handleLocationChange = (orderType, value) => {
    const updatedFormData = _.cloneDeep(formData)
    if (isEmpty(updatedFormData[orderType])) {
      updatedFormData[orderType] = {}
    }
    updatedFormData[orderType] = { ...value }
    setFormData(updatedFormData)
  }

  const renderDispatcherNotes = () => {
    const updatedFormData = _.cloneDeep(formData)
    const { organization_id } = updatedFormData
    return (
      <DispatcherNotes
        organization_id={organization_id}
        currentSourceRecord={updatedFormData}
        customer_order_notes={driverNotes}
        key={'customer_order_notes'}
        refer_type="recovery"
        apiEndpoint={dispatcherNotesApi}
        noteTitle="Driver Notes"
      />
    )
  }
  return (
    <Fragment>
      <Form>
        <Row
          className="quote-order-consignee-details marginTop10"
          type="flex"
          gutter={32}
        >
          {Object.keys(recoveryParams).map((key, index) => {
            return (
              <Col xs={12} className="alignLeft">
                <h3>{recoveryParams[key]}</h3>
                <LocationForm
                  {...props}
                  formData={formData[key] ? formData[key] : {}}
                  key={index}
                  errors={errors[key]}
                  handleOnChange={(element, value) =>
                    handleOnChange(key, element, value)
                  }
                  handleLocationChange={(value) =>
                    handleLocationChange(key, value)
                  }
                  includePredefinedStops={true}
                  editableFields={[
                    'email',
                    'phone_number_one',
                    'phone_number_two',
                    'address',
                    'l_address',
                  ]}
                  map={isMapLoaded}
                  twoRows
                  isDisabled={ key !== 'pickup_location' && formData[key]?.l_type ? formData[key].l_type === 'WH' : false}
                />
              </Col>
            )
          })}
        </Row>
        <Row gutter={16} className="marginTop10">
          <Col span={6} className="alignLeft">
            <DateTimeSelector
              dateProps={ {
                format: 'Do MMM YYYY',
                label: I18n.t('configurations.date_label', { label: I18n.t('configurations.recovery_eta') }),
                value: formData.recovery_eta_date ? moment(formData.recovery_eta_date) : null,
                onChange: (date) => {
                  handleOnChange(null, 'recovery_eta_date', date);
                },
              } }
              timeProps={ {
                format: isMilitaryTime ? 'HH:mm' : 'hh:mm A',
                showTime: { format: isMilitaryTime ? 'HH:mm' : 'hh:mm A', use12Hours: !isMilitaryTime },
                showSecond: false,
                onChange: (time) => {
                  handleOnChange(null, 'recovery_eta_time', time);
                },
                label: I18n.t('configurations.time_label', { label: I18n.t('configurations.recovery_eta') }),
                value: formData.recovery_eta_time ? moment(formData.recovery_eta_time, isMilitaryTime ? 'HH:mm' : 'hh:mm A') : null,
                placeholder: 'Select Time',
                style: { width: '100%', height: '100%', marginTop: '5px' },
                className: "formMaterialTimePicker",
                isMilitaryTime
              } }
              gridStyle={ { row: { type: "flex", gutter: 8 }, dateCol: { span: 12 }, timeCol: { span: 12 } } } 
              />
          </Col>
          <Col span={6} className="alignLeft">
            <FormItem label={I18n.t('recoveries.deadline')}>
              <DatePicker
                format="DD MMM, YYYY"
                onChange={(e) => handleOnChange(null, 'recovery_dead_line', e)}
                value={
                  formData.recovery_dead_line
                    ? moment(formData.recovery_dead_line)
                    : null
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row className="marginTop10">
          <Col xs={24} className="alignCenter">
            {
              checkServiceExistance('ORDEL') && <Popconfirm
                placement="topRight"
                title={I18n.t('messages.delete_confirm')}
                onConfirm={() => deleteClick(currentRecovery['_id'])}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger" className="marginRight5" icon="delete">
                  {I18n.t('general.delete')}
                </Button>
              </Popconfirm>
            }
            <Button type="primary" onClick={() => validateForm()} icon="save" disabled={!isNew && !checkServiceExistance('ORU')}>
              {isNew ? I18n.t('general.save') : I18n.t('general.update')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>{FormErrors(errors)}</Col>
        </Row>

        {currentRecovery?.id && (<Row className='marginTop20'>
          <Col xs={24}>
            {renderDispatcherNotes()}
          </Col>
        </Row>)
        }
      </Form>
      <hr style={{ margin: 10 }} />
    </Fragment>
  )
}

const RecoveryForm = Form.create({ name: 'recovery_form' })(RecoveryDetailsForm)
export { RecoveryForm }

RecoveryForm.propTypes = {
  currentRecovery: PropTypes.shape(),
}
RecoveryForm.defaultProps = {
  currentRecovery: null,
}

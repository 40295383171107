import React, { Fragment, useState, } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  Popconfirm,
} from '../../common/UIComponents'
import { alertMessage, customPasteSplit, isEmpty } from '../../common/Common'
import userStore from '../../stores/UserStore'
import {
  addToExistingInvoices,
} from '../../api/Billing'
import { _ } from 'core-js'
import BaseModal from '../BaseModal'
import FormErrors from '../common/FormErrors';
import TagsInput from 'react-tagsinput';
import I18n from '../../common/I18n'

const AddOrdersToInvoice = ({
  handleCancel,
  handleSuccess,
  buttonType,
  className,
  label,
  isDisabled,
  invoiceId,
  size,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [ordersNos, setOrderNos] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const showAddingForm = (value) => {
      setShowInfoWindow(value)
  }

  const resetValues = (closeModal = false) => {
    setOrderNos('')
    setErrors([])
    setShowInfoWindow(false)
    if (closeModal) {
      handleCancel()
    }
  }

  const handleSave = async () => {
    const selectedNos = !isEmpty(ordersNos) ? ordersNos.split(',') : [];
    try {
      const payload = {
        customer_order_numbers: selectedNos,
        organization_id: userStore.getStateValue('selectedOrg'),
        account_invoice_id: invoiceId,
      }
      setLoading(true)
      let response = await addToExistingInvoices(payload)
      if (response.success) {
        const result = response?.invoices ? response.invoices : {}
        const successMessage = result?.message
          ? result.message
          : I18n.t('messages.saved')
        //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
        if(result.errors && result.errors.length > 0) {
          setErrors(result.errors)
          setOrderNos('')
        } else{
          handleSuccess()
          resetValues()
        }
      } else {
        setErrors([...response.errors])
        // throw new Error(response.errors[0])
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setLoading(false)
    }
  }

  const renderModalWindow = () => {
    return (
      <BaseModal
        title={I18n.t('general.customer_orders')}
        className="customer-orders-modal"
        onCancel={() => {
          resetValues()
        }}
        width="45%"
      >
        <Row type="flex" gutter={4} align="middle">
          <Col span={22}>
            <div className="tagsInput">
              <TagsInput
                value={ ordersNos.length ? ordersNos.split(',') : [] }
                onChange={ (orderNums) => setOrderNos(orderNums.join(',')) }
                validationRegex={ /^[a-zA-Z0-9_-]+$/ }
                onlyUnique
                addOnPaste
                pasteSplit={ customPasteSplit }
                inputProps={ {
                  placeholder: "Add Orders",
                } }
                addOnBlur={ true }
              />
        </div>
          </Col>
          <Col span={2}>
            <Popconfirm
              placement="topRight"
              title={I18n.t('messages.confirm')}
              onConfirm={handleSave}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={loading}>
                Add
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        {FormErrors(errors)}
      </BaseModal>
    )
  }

  return (
    <Fragment>
      <Button
        onClick={() => setShowInfoWindow(true)}
        type={buttonType ? buttonType : 'primary'}
        icon="plus"
        className={className ? className : ''}
        loading={loading}
        disabled={isDisabled}
        size={size ? size : 'default'}
      >
        {label}
      </Button>
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  )
}

AddOrdersToInvoice.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  resetOrders: PropTypes.func.isRequired,
  handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
  statusChange: PropTypes.func.isRequired,
}

export default AddOrdersToInvoice

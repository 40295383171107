import { Drawer, Dropdown, Empty, Progress, Tabs, Tooltip, Menu, TimePicker, Skeleton } from "antd";
import _ from "lodash";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Search, Row, Col, Icon, Button,Spin, Popover, Link } from "../../common/UIComponents";
import editIcon from "../../../images/assign_route/edit.svg";
import AssignableOrdersList from "./AssignableOrdersList";
import RouteTimeWindow from "./RouteTimeWindow";
import DisplayTime from "../common/DisplayTime";
import { alertMessage, isEmpty, lockIconTooltip, randomNumber } from "../../common/Common";
import { deleteRoutes, getDriversRoute, newDispatchRoute, updateQuickDispatchRouteInMiddle, updateRouteInMiddle } from "../../api/Routes";
import { getOperationalTimings, getRouteStartTime, renderAlertMessage } from "../../helpers/common";
import { findDependencyOrders } from "../../helpers/preplan";
import { calculatePercentage, calculateTotalWeight } from "../dispatches/helpers";
import TruckLoadProgress from "./TruckLoadProgress";
import { showRouteValidationError } from "../../helpers/capacityValidation";
import { WarehouseContext } from "../../context/WarehouseContext";
import { momentTime } from "../../helpers/date_functions";
import moment from "moment";
import { pickLocsFromRouteOrders } from "../../helpers/orders";
import RouteLabel from "../../config/RouteLabel";
import DriverRouteInfo from "./DriverRouteInfo";
import I18n from "../../common/I18n";
import { IntransitTruckIcon, LockIcon } from "../../common/CustomIcon";
import { OrgContext } from "../../context/OrgContext";
import { newDispatchMultiStopRoute } from "../../api/OrdersApi";
//import { Snackbar } from '@mui/base/Snackbar';
const { TabPane } = Tabs;
const DriverRoutesDetails = ({
  unassinedOrders,
  currentDriver = {},
  driversInfo,
  getDriverInfo,
  orders,
  filteredDrivers = [],
  searchText,
  warehouseData,
  currentOrg,
  orderRefreshCall,
  organizationSettings = {},
  currentDate,
  renderNotification,
  updateOrders = () => {},
  getDriverDetails = () => {},
  navigateToRoute,
  userContext,
  driversSecHeight,
  renderErrors = () => {},
  ordersSecHeight = ""
}) => {
  const [driverDetails, setDriverDetails] = useState(currentDriver);
  const [activeKey, setActiveKey] = useState("");
  const [isAssigning, setIsAssigning] = useState(false);
  const [isRouteLoading, setIsRouteLoading] = useState(false)
  const [driverRouteInfo, setDriversRouteInfo] = useState([])
  const [updateKey, setUpdateKey] = useState(null);
  const { getWHDetails } = useContext(WarehouseContext);
  const { orgSettings } = useContext(OrgContext);
  const currentWarehouse = getWHDetails();
  const [routeFetchingTime, setRouteFetchingTime] = useState(currentDriver);
  const [visibleDropdowns, setVisibleDropdowns] = useState({});
  const [selectedRouteTimes, setSelectedRouteTimes] = useState({});
  const [editingRouteId, setEditingRouteId] = useState(null);
  const [ routeKey, setRouteKey ] = useState(`${randomNumber()}_${currentDriver?.driver_id || ''}`.toString());
  const [isUnlocked, setIsUnlocked] = useState(false);
  const isMilitaryTime = organizationSettings.is_military_time === "true";
  const dateFormatString = isMilitaryTime ? "HH:mm" : "hh:mm A";
  const driverRefs = useRef([]);
  const selectedWh = getWHDetails()
  const { start_time = "", end_time = "" } = getOperationalTimings(
    organizationSettings,
    selectedWh
  );

  useEffect(() => {
    // setActiveKey(_.get(currentDriver, "route_details[0].route_id", ""));
    // const firstRouteDetails = _.get(driverRouteInfo, "[0].route_details", []);
    // setActiveKey(_.get(firstRouteDetails, "[0].route_id", ""));
    setDriverDetails(currentDriver)
    fetchDriverRoutes(currentDriver.driver_id)
    setSelectedRouteTimes({})
    setVisibleDropdowns({})
  }, [currentDriver]);

  useEffect(() => {
   if(currentDriver?.driver_id){
     fetchDriverRoutes(currentDriver.driver_id)
   }
  }, [currentDate]);

  useEffect(() => {
  const activeIndex = filteredDrivers.findIndex((driver) => driver.driver_id === driverDetails.driver_id);
  if (activeIndex && activeIndex !== -1 && driverRefs?.current[activeIndex]) {
    setTimeout(() => {
      driverRefs.current[activeIndex]?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 500); // Delay for 100ms
  }
}, [driverDetails, filteredDrivers]);

 const fetchDriverRoutes = async (driverId = "", route_id = "") => {
  const organization_id = currentOrg.id;
   try {
     const payload = {
       org_id: organization_id,
       start_datetime: currentDate.format("YYYY-MM-DD"),
       warehouse_id: selectedWh?.id || "",
       driver_id: !isEmpty(driverId) ? driverId : currentDriver.driver_id,
     };

     setIsRouteLoading(true);
     const response = await getDriversRoute(payload);
     if (response.success) {
       const routesData = response.driver_routes || [];
       setDriversRouteInfo(routesData);
       const newRouteKey = `${randomNumber()}_${currentDriver.driver_id}`.toString()
       setRouteKey(newRouteKey);
       const currentActiveId = route_id && _.find(routesData, {route_id }) >=0  ? 
       route_id : routesData?.length > 0 ? routesData[0].route_id: newRouteKey;
       setActiveKey(currentActiveId);
       setRouteFetchingTime(moment().format("HH_mm_ss_ms"))
       if (isUnlocked) {
        getDriverDetails();
       }
     } else {
       throw new Error(response.errors[0]);
     }
   } catch (error) {
     alertMessage(error.message, "error", 10);
   } finally {
     setIsRouteLoading(false);
   }
 };

  const getLocId = (locObj = {}) => {
    return locObj.l_type === "CS" && locObj.status === "PENDING" ? locObj.id : "";
  };

  const getOrdersLocId = (locObj = {}, key = "id") => {
    return locObj.l_type === "CS" && locObj.status !== "COMPLETED" ? locObj?.[key] : ''
  };
 
  const assignOrders = (rowData, route, requireValidation) => {
    console.log("moment", moment.tz(route.route_start_time, selectedWh?.timeZoneId).format("YYYY-MM-DD HH:mm"))
    const startDeliveryTime = selectedRouteTimes[route.route_id]
      ? moment(
          `${moment(currentDate).format("YYYY-MM-DD")} ${moment(
            selectedRouteTimes[route.route_id],
            "HH:mm"
          ).format("HH:mm")}`,
          "YYYY-MM-DD HH:mm"
        ).format()
      : moment(moment.tz(route.route_start_time, selectedWh?.timeZoneId).format("YYYY-MM-DD HH:mm"),"YYYY-MM-DD HH:mm").format()


    const currentRouteStopsDetail = route?.stop_details?.length > 0 ? route.stop_details : []  
     // Fetching Order ids ....
    const routeOrders = currentRouteStopsDetail?.length > 0 ? currentRouteStopsDetail.flatMap((item) => item.stop_orders) : []
    const routeOrderIds = routeOrders?.length > 0 ? routeOrders.map((order) => order.id) : [];
    const selectedOrderIds = rowData.map((order) => order.id)
    const totalOrderIds = selectedOrderIds ; //[...routeOrderIds,...selectedOrderIds]
    //.....

    const getStopLocationIds = (route) => {
      return route?.stop_details?.map((stop) => stop.stop_location_ids).flat();
    };

    // Fetching location ids...
    const routeStopLocationIds = !_.isEmpty(route) ? getStopLocationIds(route) : [];
    const selectedOrdersLocationIds = _.compact(_.flatten(
      rowData.map((order) => {
        return order.location_id;
        // const loc =  order.stop_id ? 
        // [getOrdersLocId(order.origin, "id"), getOrdersLocId(order.destination, "id")]
        // :
        // [getOrdersLocId(order.origin, "location_id"), getOrdersLocId(order.destination, "location_id")]
        // ;
        // return loc;
      }))
    );
    const loc_ids = _.uniq([...selectedOrdersLocationIds]) //[...routeStopLocationIds, ...selectedOrdersLocationIds];
    //.....
    const deleted_order_ids = _.uniq(routeOrders.filter(order => !totalOrderIds.includes(order.id)).map(order => order.id))
    const deleted_location_ids = routeStopLocationIds.filter(loc => !loc_ids.includes(loc))
    const payload = {
      nav_route_id: route.route_id,
      order_ids_to_add: totalOrderIds,
      warehouse_id: selectedWh?.id || "",
      location_ids: loc_ids || [],
      driver_id: driverDetails?.driver_id || "",
      skip_capacity_validation: !requireValidation,
      route_start_date: startDeliveryTime,
      deleted_order_ids,
      deleted_location_ids
    };

    setIsAssigning(true)
    updateQuickDispatchRouteInMiddle(payload)
      .then((result) => {
        if (result.success) {
          if(result.response.capacity_overloaded){
            showRouteValidationError(
              result.response.capacity_info,
              driverDetails.employee_code,
              () => {
                assignOrders(rowData, route, false)
              },
              setIsRouteLoading,
            )
          } else{
            const navRoute = result.nav_route || {};
            renderNotification(<Fragment>{`${_.uniq(totalOrderIds).length} Order(s) have been assigned to `}<Link onClick={() => navigateToRoute(navRoute.id, navRoute.r_status, currentDate.format("YYYY-MM-DD"))}>{driverDetails.employee_code}</Link></Fragment> , 'success')
            updateOrders(orders.filter(order => !totalOrderIds.includes(order.id)))
            getDriverDetails()
            fetchDriverRoutes(driverDetails?.driver_id || "", route.route_id);
          }
         
        } else {
          renderErrors(result.errors)
          //renderNotification(result.errors ? result.errors.join(',') : 'Unable to process the request', 'error');
        }
      })
      .finally(() => {
        setIsAssigning(false);
      });
  };

  const createAndAssignRoute = async (routeTime, sequencedOrders, record, requireValidation) => {
    const organization_id = currentOrg.id;
    const selectedWh = warehouseData.getWHDetails();
    // let { start_time = "", end_time = "" } = getOperationalTimings(
    //   organizationSettings,
    //   selectedWh
    // );
    const routeStartTime = routeTime ? moment.tz(routeTime, selectedWh.timeZoneId ).format("HH:mm") : start_time;
    const startDeliveryTime = moment(
      `${currentDate.format("YYYY-MM-DD")} ${routeStartTime}`,
      "YYYY-MM-DD HH:mm"
    ).format();
    const endDeliveryTime = (moment(routeStartTime, "HH:mm")).isAfter((moment(end_time, "HH:mm"))) ? moment(
      `${currentDate.format("YYYY-MM-DD")} 23:59`,
       "YYYY-MM-DD HH:mm"
    ).format() :
     moment(
      `${currentDate.format("YYYY-MM-DD")} ${end_time}`,
      "YYYY-MM-DD HH:mm"
    ).format();

    const loc_ids = _.uniq(sequencedOrders.map((order) => order.location_id));
    const customerOrderIds = _.uniq(sequencedOrders.map((order) => order.id));
    const payload = {
      organization_id,
      warehouse_id: selectedWh?.id || '',
      driver_id: record.driver_id,
      delivery_end_date: endDeliveryTime,
      delivery_start_date: startDeliveryTime,
      customer_order_ids: customerOrderIds,
      action_type: RouteLabel.actionLabels.ROUTE_CREATED,
      action_from: "ORDER",
      location_ids: loc_ids || [],
      skip_capacity_validation: !requireValidation,
    };

    setIsAssigning(true);

    try {
      const result = await newDispatchRoute(payload);
      if (result.success) {
        if(result.response.capacity_overloaded){
          showRouteValidationError(
            result.response.capacity_info,
            record.employee_code,
            () => {
              createAndAssignRoute(routeTime, sequencedOrders, record, false)
            },
            setIsAssigning,
          )
        } else{
          const navRoute = result.nav_route || {};
          renderNotification(<Fragment>{`${orders.length} Order(s) have been assigned to `}<Link onClick={() => navigateToRoute(navRoute.id, navRoute.r_status, currentDate.format("YYYY-MM-DD"))}>{record.employee_code}</Link></Fragment> , 'success')
          // renderNotification(`${orders.length} Order(s) have been assigned to ${record.employee_code}` , 'success')
          // alertMessage(
          //   `Orders have been assigned to ${record.employee_code}`,
          //   "success",
          //   10
          // );
          // setShowDrawer(false);
          updateOrders(orders.filter(order => !customerOrderIds.includes(order.id)))
          fetchDriverRoutes(driverDetails?.driver_id || "");
          getDriverDetails()
          //orderRefreshCall();
          setIsAssigning(false)
          setVisibleDropdowns({})
        }
       
      } else {
        setIsAssigning(false)
        renderErrors(result.errors)
        //renderNotification(result.errors ? result.errors.join(',') : 'Unable to process the request', 'error');
        //renderAlertMessage(result.errors);
      }
    } catch (error) {
      console.error("Error assigning orders:", error);
      setIsAssigning(false)
    } finally {
      // setIsLoading(false);
    }
  };

  const createAndAssignMultiStopRoute = async (routeTime, sequencedOrders, record, requireValidation) => {
    const organization_id = currentOrg.id;
    const selectedWh = warehouseData.getWHDetails();
    const routeStartTime = routeTime ? moment.tz(routeTime, selectedWh.timeZoneId ).format("HH:mm") : start_time;
    const startDeliveryTime = moment(
      `${currentDate.format("YYYY-MM-DD")} ${routeStartTime}`,
      "YYYY-MM-DD HH:mm"
    ).format();
    const endDeliveryTime = (moment(routeStartTime, "HH:mm")).isAfter((moment(end_time, "HH:mm"))) ? moment(
      `${currentDate.format("YYYY-MM-DD")} 23:59`,
       "YYYY-MM-DD HH:mm"
    ).format() :
     moment(
      `${currentDate.format("YYYY-MM-DD")} ${end_time}`,
      "YYYY-MM-DD HH:mm"
    ).format();

    const loc_ids = _.uniq(sequencedOrders.map((order) => order.location_id));
    const customerOrderIds = _.uniq(sequencedOrders.map((order) => order.id));
    const payload = {
      organization_id,
      warehouse_id: selectedWh?.id || '',
      driver_id: record.driver_id,
      end_time: endDeliveryTime,
      start_time: startDeliveryTime,
      order_id: customerOrderIds,
    };

    setIsAssigning(true);

    try {
      const result = await newDispatchMultiStopRoute(payload);
      if (result.success) {
        if(result.response.capacity_overloaded){
          showRouteValidationError(
            result.response.capacity_info,
            record.employee_code,
            () => {
              createAndAssignMultiStopRoute(routeTime, sequencedOrders, record, false)
            },
            setIsAssigning,
          )
        } else{
          const navRoute = result.nav_route || {};
          renderNotification(<Fragment>{`${orders.length} Order(s) have been assigned to `}<Link onClick={() => navigateToRoute(navRoute.id, navRoute.r_status, currentDate.format("YYYY-MM-DD"))}>{record.employee_code}</Link></Fragment> , 'success')
          updateOrders(orders.filter(order => !customerOrderIds.includes(order.id)))
          fetchDriverRoutes(driverDetails?.driver_id || "");
          getDriverDetails()
          setIsAssigning(false)
          setVisibleDropdowns({})
        }

      } else {
        setIsAssigning(false)
        renderErrors(result.errors)
      }
    } catch (error) {
      console.error("Error assigning orders:", error);
      setIsAssigning(false)
    } finally {
      // setIsLoading(false);
    }
  };

  const renderAddButton = (newTime) => {
    return (
      <RouteTimeWindow
        driverDetails={driverDetails}
        selectedOrders={orders}
        refreshDetails={(driver_id, route_id) => {
          getDriverDetails()
          fetchDriverRoutes(driver_id, route_id)
          updateOrders([])
        }}
        orderRefreshCall={orderRefreshCall}
        renderNotification={renderNotification}
        timeToConsider={newTime}
        timeZoneId={selectedWh?.timeZoneId || ''}
        navigateToRoute={navigateToRoute}
        renderErrors={renderErrors}
        // currentDateTime={currentDate}
      />
    );
  };

  const handleRouteDeleteClick = (routeId) => {
    setIsAssigning(true);
    deleteRoutes(routeId).then((result) => {
      if (result.success) {
        renderNotification(I18n.t("routes.route_deleted_successfully"), 'success');
        fetchDriverRoutes(driverDetails.driver_id);
        setIsAssigning(false);
        getDriverDetails()
      } else {
        renderNotification(result.errors ? result.errors.join(',') : 'Unable to process the request', 'error');
        setIsAssigning(false);
      }
    });
  };

  const handleMenuClick = (route, e) => {
    const { key } = e
    switch (key) {
      case 'edit':
        console.log(`Edit Start Time for route: ${route.route_id}`);
        // Your edit logic here
         handleDropdownVisibleChange(route.route_id, false);
         setEditingRouteId(route.route_id);
        break;
      case 'delete':
        console.log(`Delete Route: ${route.route_id}`, route);
        handleRouteDeleteClick(route.route_id);
        break;
      default:
        break;
    }
  };

  const handleMenu = (route, e) => {
    if(!_.isEmpty(route)){
      handleMenuClick(route, e);
    }else{
      const newRoute = { route_id: routeKey };
      handleMenuClick(newRoute, e);
    }
  }

  const generateMenu = (route = {}) => (
    <Menu onClick={(e) => handleMenu(route, e)}>
      {orders.length > 0 && <Menu.Item key="edit">
        <Icon type="clock-circle" style={{ fontSize: 17 }} />
        Edit Start Time
      </Menu.Item>}

      {!_.isEmpty(route) && <Menu.Item key="delete" >
        <Icon type="delete" style={{ fontSize: 17 }} />
        Delete Route
      </Menu.Item>}
    </Menu>
  );

  const handleTimeSelect = (timeString, route) => {
    const time24hr = moment(timeString, "hh:mm A").format("HH:mm");
    setSelectedRouteTimes({ ...selectedRouteTimes, [route.route_id]: time24hr });
    setEditingRouteId(null);
    // Your logic to update the route start time
  };

  const handleTime = (timeString, route) =>{
    if(!_.isEmpty(route)){
      handleTimeSelect(timeString, route);
    }else{
      const newRoute = { route_id: routeKey };
      handleTimeSelect(timeString, newRoute);
    }
  }

  const convertMinutesToTime = (minutes, format) => {
    // Create a moment object starting from '00:00' and add the minutes
    const time = moment().startOf("day").add(minutes, "minutes");
    // Determine the format string based on isMilitaryTime
    const formatString = format ?  format : isMilitaryTime ? "HH:mm" : "hh:mm A";
    // Format the time
    return time.format(formatString);
  }

  const generateTimeMenu = (route = {}) => {
    // const selectedWh =
    //   warehouseData?.selectedWarehouses?.length &&
    //   Array.isArray(warehouseData.selectedWarehouses)
    //     ? warehouseData.selectedWarehouses[0]
    //     : warehouseData.selectedWarehouses;
    // let { start_time = "", end_time = "" } = getOperationalTimings(
    //   organizationSettings,
    //   selectedWh
    // );
    // const timeFormat = isMilitaryTime ? "HH:mm" : "hh:mm A";
    const currentDateTimePlus30Minutes =  addMinutesToDate();
    const routeId = route?.route_id ?? routeKey; //if its a new route instead of route_id setting active key as new route_id
    const selectedRouteDateTime = convertToRouteStartDateTime(selectedRouteTimes[routeId]);
    const currentRouteTime = !_.isEmpty(route)
      ? (selectedRouteDateTime || route.route_start_time)
      : (selectedRouteDateTime || currentDateTimePlus30Minutes);
    const current_route_time = momentTime("HH:mm", currentRouteTime, currentWarehouse?.timeZoneId || '')
    const current_route_time_minutes = (moment(current_route_time, "HH:mm").hours() * 60 +  moment(current_route_time, "HH:mm").minutes())
    const isSameDay = moment().format('YYYY-MM-DD') === moment(currentDate).format('YYYY-MM-DD');
    const timeOptions = [];
    const maxTime = end_time ? ( moment(end_time, "HH:mm").hours() * 60 +  moment(end_time, "HH:mm").minutes()) : 1439;
    let step = 0;
    if(isSameDay){
      const now = moment.tz(selectedWh.timeZoneId);
      const startTimeMinutes = (moment(start_time, "HH:mm").hours() * 60 +  moment(start_time, "HH:mm").minutes())
      const currentTimeMinutes = now.hours() * 60 + now.minutes();
      const minutes = currentTimeMinutes >= startTimeMinutes ? now.hours() * 60 + now.minutes() : startTimeMinutes;
      const reminder = minutes % 60
      step = currentTimeMinutes >= startTimeMinutes ? reminder > 30 ? (minutes + (60-reminder)) : (minutes + (30 - reminder)) : startTimeMinutes ;
    } else {
      step = start_time ? ( moment(start_time, "HH:mm").hours() * 60 +  moment(start_time, "HH:mm").minutes()) : 0;
    }
    for( ; step <= maxTime ; step = step + 30){
      timeOptions.push(step);
    }
    timeOptions.push(current_route_time_minutes);
    const sortedItems = _.sortBy(_.uniq(timeOptions));
    return (
    <Menu onClick={(e) => handleTime(e.key, route)}>
      <Menu.ItemGroup title={currentDate.format("MMM DD")}>
        {sortedItems.map((time) => {
          const isActive = current_route_time === convertMinutesToTime(time, "HH:mm");
          const timeString = convertMinutesToTime(time);
          return <Menu.Item key={timeString} className={ isActive? "activeItem" : ''}>{timeString} {isActive && <Icon type="check" style={{fontSize: 16, marginLeft: 10}}/>}</Menu.Item>;}
      )}
      </Menu.ItemGroup>
    </Menu>
  )};

  const handleDropdownVisibleChange = (routeId, visible) => {
    setVisibleDropdowns((prevState) => ({
      ...prevState,
      [routeId]: visible,
    }));
  };

  // Function to add 30 minutes to the provided date
  const addMinutesToDate = (routeTime = null) => {
    /*  const newDate = date ? new Date(date) : new Date();
    "2024-07-07T02:18:44.244+00:00"
    
    // getRouteStartTime(start_time, end_time, selectedWh.timeZoneId, currentDate, null);
    newDate.setMinutes(newDate.getMinutes() + 30);
    // Get ISO string and split it to format properly
    const isoString = newDate.toISOString();
    const datePart = isoString.split(".")[0]; // "2024-06-18T14:15:53"
    const milliseconds = String(newDate.getUTCMilliseconds()).padStart(3, "0");
    const timezoneOffset = "+00:00"; // Assuming UTC time zone

    return `${datePart}.${milliseconds}${timezoneOffset}`;
    */
    const dateString = moment(currentDate).format("YYYY-MM-DD")
    const timeString = getRouteStartTime(start_time, end_time, selectedWh.timeZoneId, currentDate, routeTime);
    const timeinUtc = moment.tz(`${dateString} ${timeString}:00`, 'America/New_York').utc().format('YYYY-MM-DDTHH:mm:ss.SSS+00:00');
    return timeinUtc;
  };

  const convertToRouteStartDateTime = (time) => {
    if (!time) return ""; 

    const selectedDate = moment(currentDate).format("YYYY-MM-DD");
    // Combine the provided date and time
    const dateTimeString = `${selectedDate} ${time}`;

    // Convert to the desired format
    return moment.tz(dateTimeString, currentWarehouse.timeZoneId).format('YYYY-MM-DDTHH:mm:ss.SSSZ')

  };

  const renderDriverBox = () => {
    const requireValidation = organizationSettings.capacity_optimization === "true";
    const { route_details = {} } = driverDetails;
    const { getWHDetails } = warehouseData;
    const { currentUser = {} } = userContext;
    const currentDateTimePlus30Minutes =  addMinutesToDate();
    const newRouteSelectedDateTime = convertToRouteStartDateTime(
      selectedRouteTimes[activeKey]
    );
    const nextRouteStartTime =  currentDriver?.all_routes_completion_date ? addMinutesToDate(currentDriver.all_routes_completion_date): currentDateTimePlus30Minutes;
    return (
      <div>
      <Spin spinning={isAssigning} tip="Loading...">
        <Row gutter={16}>
          <Col
            xs={6}
            style={{
              overflowY: "auto",
              height: driversSecHeight,
            }}
          >
            {filteredDrivers.map((rec, index) => {
              const inTransitRoute = _.find(rec.route_details, {
                route_status: "DISPATCHED",
              });
              const { route_details = {} } = rec;
              const showLockIcon = currentUser.id !== route_details?.is_locked_by && route_details?.is_locked;
              const isDateExceeded = rec.all_routes_completion_date ? moment
              .tz(rec.all_routes_completion_date, selectedWh.timeZoneId)
              .isAfter(moment.tz(`${currentDate.format("YYYY-MM-DD")} 23:59`, 'YYYY-MM-DD HH:mm', selectedWh.timeZoneId)) : false;
              return (
                <div
                  className={`driverBox ${
                    rec.driver_id === driverDetails.driver_id ? "activeDriver" : ""
                  }`}
                  onClick={() => {
                    setUpdateKey(null)
                    setDriverDetails(rec);
                    getDriverInfo(rec);

                  }}
                  ref={(el) => (driverRefs.current[index] = el)} // added ref assignment
                  key={rec.driver_id}
                >
                  <Row type="flex" gutter={8}>
                    <Col className="driver_title">{rec.employee_code}</Col>
                    {!_.isEmpty(route_details) && (
                    <>
                      <Col style={{ marginTop: 4 }}>
                        <IntransitTruckIcon className="intransit-truck-icon legs_info" />
                      </Col>
                      <Col style={{marginLeft: -7}}>
                        {showLockIcon && lockIconTooltip({ iconClassName: "intransit-truck-icon legs_info", userName: route_details?.locked_by_name })}
                      </Col>
                    </>
                  )}
                  </Row>
                  <div className="legs_info">
                    {rec?.all_routes_completion_date ? (
                      <Row>
                        <Col className={`${isDateExceeded ? 'errorText' : ''}`}>
                          {moment
                            .tz(
                              rec.all_routes_completion_date,
                              currentWarehouse.timeZoneId
                            )
                            .format(isDateExceeded ? `MMM DD, ${dateFormatString}` : dateFormatString)}
                        </Col>
                        <Col>
                          {rec.all_routes_pickup_orders}P/
                          {rec.all_routes_delivery_orders}D, {rec.routes_count}{" "}
                          Routes
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>No Active Routes</Col>
                        <Col>{" "}</Col>
                      </Row>
                      
                    )}
                  </div>
                </div>
              );
            })}
          </Col>
          <Col xs={18}
           style={{
              overflowY: "hidden",
              height: driversSecHeight,
            }}
          >
            {filteredDrivers?.length > 0 ? 
            <div key={routeFetchingTime}>
            <Spin spinning={isRouteLoading} tip="Loading...">
            <Tabs
              activeKey={activeKey}
              onChange={(e) => {
                if (e !== "plus-button") {
                  setActiveKey(e);
                }
              }}
              type="card"
              size="small"
            >
              {driverRouteInfo?.length > 0 ? 
               driverRouteInfo.map((route, index) => {
                const selectedRouteDateTime = convertToRouteStartDateTime(
                  selectedRouteTimes[route.route_id]
                );
                const routeTime = moment.tz(route.route_start_time, currentWarehouse.timeZoneId).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                const timeFormat = isMilitaryTime ? "HH:mm" : "hh:mm A";
                const showLockIcon = currentUser.id !== route?.is_locked_by && route?.is_locked
                return (
                <TabPane
                  tab={
                    <div>
                      <span className={`title ${driverRouteInfo?.length > 0 ? '' : 'ant-form-item-required'}`}>
                        <DisplayTime
                          dateTimeString={selectedRouteDateTime || route.route_start_time}
                          displayWithDate={false}
                          isUTC={true}
                          timeZoneId={currentWarehouse?.timeZoneId || ''}
                        />
                        {/* {momentTime(timeFormat, selectedRouteDateTime || route.route_start_time, currentWarehouse?.timeZoneId || '')} */}
                        &nbsp; Route &nbsp;&nbsp;&nbsp;
                        {!showLockIcon && !["DISPATCHED"].includes(route?.route_status) && <Dropdown
                          overlay={generateMenu(route)}
                          trigger={['click']}
                          onVisibleChange={visible => handleDropdownVisibleChange(route.route_id, visible)}
                          visible={!!visibleDropdowns[route.route_id]}
                          overlayClassName="custom-dropdown"
                          placement="bottomRight"
                          key={route.route_id}
                        >
                          <Icon type="more" onClick={e => e.stopPropagation()} style={{fontSize: 22, marginRight: 0}}/>
                        </Dropdown>}
                        {showLockIcon && <LockIcon />}
                      </span>
                      {editingRouteId === route.route_id && (
                        <Dropdown
                          overlay={generateTimeMenu(route, (selectedRouteDateTime || route.route_start_time))}
                          trigger={['click']}
                          visible={true}
                          onVisibleChange={(visible) => !visible && setEditingRouteId(null)}
                          key={route.route_id}
                          placement="bottomRight"
                          overlayClassName="edit-route-dropdown "
                        >
                          <span/>
                        </Dropdown>
                      )}
                    </div>
                  }
                  key={route.route_id}
                >
                  {/* {renderRouteDetails(route)} */}
                  <DriverRouteInfo
                  currentOrg={currentOrg}
                  orgSettings={orgSettings}
                  route={route}
                  driverDetails={driverDetails} 
                  organizationSettings={organizationSettings}
                  driverRouteInfo={driverRouteInfo}
                  setUpdateKey={setUpdateKey}
                  ordersInfo={orders}
                  unassinedOrders={unassinedOrders}
                  currentDriver={currentDriver}
                  updateKey={updateKey}
                  assignOrders={assignOrders}
                  createAndAssignRoute={createAndAssignRoute}
                  createAndAssignMultiStopRoute={createAndAssignMultiStopRoute}
                  currentUser={currentUser}
                  isUnlocked={isUnlocked}
                  setIsUnlocked={setIsUnlocked}
                  refreshRouteDetails={(driverId,route_id) => fetchDriverRoutes(driverId, route_id)}
                  selectedWh={selectedWh}
                  ordersSecHeight={ordersSecHeight}
                  />
              
                </TabPane>
                );
              }) :
              (
                <TabPane
                  tab={
                    <div>
                      <span className="title ant-form-item-required">
                        <DisplayTime
                          dateTimeString={newRouteSelectedDateTime || currentDateTimePlus30Minutes}
                          displayWithDate={false}
                          isUTC={true}
                          timeZoneId={currentWarehouse?.timeZoneId || ''}
                        />
                        &nbsp; Route &nbsp;&nbsp;&nbsp;
                       {orders?.length > 0 && <Dropdown
                          overlay={generateMenu({})}
                          trigger={['click']}
                          onVisibleChange={visible => handleDropdownVisibleChange(routeKey, visible)}
                          visible={!!visibleDropdowns[routeKey]}
                          overlayClassName="custom-dropdown"
                          placement="bottomRight"
                          key={routeKey}
                        >
                          {<Icon type="more" onClick={e => e.stopPropagation()} style={{fontSize: 22, marginRight: 0}}/>}
                        </Dropdown>}
                      </span>
                       {editingRouteId === routeKey && (<Dropdown
                          overlay={generateTimeMenu({}, (newRouteSelectedDateTime || currentDateTimePlus30Minutes))}
                          trigger={['click']}
                          visible={true}
                          onVisibleChange={(visible) => !visible && setEditingRouteId(null)}
                          key={routeKey}
                          placement="bottomRight"
                          overlayClassName="edit-route-dropdown"
                        >
                          <span/>
                        </Dropdown>)}
                    </div>
                  }
                  key={routeKey}
                >
                  {/* {renderRouteDetails({}, currentDateTimePlus30Minutes)} */}
                 <DriverRouteInfo route={{}} newRouteTime={newRouteSelectedDateTime || currentDateTimePlus30Minutes} driverDetails={driverDetails} 
                  organizationSettings={organizationSettings}
                  driverRouteInfo={driverRouteInfo}
                  setUpdateKey={setUpdateKey}
                  ordersInfo={orders}
                  unassinedOrders={unassinedOrders}
                  currentDriver={currentDriver}
                  assignOrders={assignOrders}
                  createAndAssignRoute={createAndAssignRoute}
                  createAndAssignMultiStopRoute={createAndAssignMultiStopRoute}
                  currentUser={currentUser}
                  refreshRouteDetails={(driverId,route_id) => fetchDriverRoutes(driverId, route_id)}
                  ordersSecHeight={ordersSecHeight}
                  />
                </TabPane>
                )
              }
              {/* <TabPane tab={renderAddButton()} key={"plus-button"}>
                  {renderRouteDetails({})}
              </TabPane> */}
              { orders.length > 0 && driverRouteInfo?.length > 0 && <TabPane tab={renderAddButton(nextRouteStartTime)} key={"plus-button"} />}
              {/* {orders.length>0 && driverRouteInfo?.length > 0 && <TabPane tab={renderAddButton()} key={"plus-button"} />} */}
              {/* {driverRouteInfo?.length === 0 && (
                  <Empty
                    description={
                      <h3>
                       No Route is Assigned 
                      </h3>
                    }
                    style={{marginTop: "25%"}}
                  >
                    {orders.length > 0 && <RouteTimeWindow
                      driverDetails={driverDetails}
                      selectedOrders={orders}
                      showIcon={false}
                      refreshDetails={(driver_id, route_id) => {
                        fetchDriverRoutes(driver_id, route_id)
                        updateOrders([])
                      }}
                      orderRefreshCall={orderRefreshCall}
                      requireValidation={requireValidation}
                      timeZoneId={currentWarehouse?.timeZoneId || ''}
                    >
                      <Button
                        type="primary"
                        icon = "plus-circle"
                        style={{borderRadius: 20}}
                      >
                        New Route
                      </Button>
                    </RouteTimeWindow>}
                  </Empty>
                )} */}
            </Tabs>
            </Spin>
            </div> : 
            (<Empty
                description={<h3>Driver Not Found!!</h3>}
                style={{ display: "inline-block", marginTop: "35%", marginLeft: "15%" }}
              />
            )}
          </Col>
        </Row>
      </Spin>
      </div>
    );
  };
  return (
    <div>
      <Row>
        <Col>{renderDriverBox()}</Col>
      </Row>
    </div>
  );
};

export default DriverRoutesDetails;
import { Row, Col, Select, Button, Alert } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FormItem } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import { OrgContext } from "../../context/OrgContext";
import { mawbConnections } from "../../api/OrdersApi";
import { alertMessage } from "../../common/Common";

export const OrderLinkModal = (props) => {
  const [orderNos, setOrderNos] = useState([]);
  const [hawbNos, setHawbNos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uncheckedOrderNos, setUncheckedOrderNos] = useState(
    props.extractedOrderNos
  );
  const [warningMessages, setWarningMessages] = useState([]);
  const orgData = useContext(OrgContext);

  const handleOrderNosChange = (ele, value) => {
    if (!props.isDelink) {
      if (ele === "orderNos") {
        setOrderNos(value);
      } else {
        setHawbNos(value);
      }
    } else {
      if (ele === "orderNos") {
        setUncheckedOrderNos(value);
      }
    }
  };

  useEffect(() => {
    if (!props.openModal) {
      setOrderNos([]);
      setHawbNos([]);
    }
  }, [props.openModal]);

  const handleSave = () => {
    const screen = props.activeScreen;
    const orderData = screen === "delink" ? uncheckedOrderNos : orderNos;
    const hawbData = screen !== "delink" ?   hawbNos : [];
    setLoading(true);
    const payload = {
      parent_order_id: props.order.id,
      order_numbers: orderData,
      account_id: props.order.account_id,
      hawb_numbers: hawbData,
      operation: props.activeScreen,
      org_id: _.get(orgData, "currentOrg.id", ""),
    };
    mawbConnections(payload)
      .then((res) => {
        if (res.success) {
          let messages = [];
          const warnings = res.data.warnings;
  
          if (Array.isArray(warnings)) {
            messages = warnings;
          } else if (
            Object.values(warnings).some(
              (array) => Array.isArray(array) && array.length > 0
            )
          ) {
            if (
              warnings.failed_hawb_numbers &&
              warnings.failed_hawb_numbers.length > 0
            ) {
              messages.push(
                `Failed HAWB Numbers: ${warnings.failed_hawb_numbers.join(", ")}`
              );
            }
  
            if (
              warnings.failed_order_numbers &&
              warnings.failed_order_numbers.length > 0
            ) {
              messages.push(
                `Failed Order Numbers: ${warnings.failed_order_numbers.join(
                  ", "
                )}`
              );
            }
          }
  
          setWarningMessages(messages);
  
          if (messages.length === 0) {
            alertMessage(res.data.message, "success");
            props.onCancel();
            props.fetchData();
            props.getOrderDetails();
          }
        } else {
          alertMessage(res.errors, "error");
          setLoading(false);
          props.setIsDelink(false);
        }
      })
      .catch((err) => {
        const errorMessage = Array.isArray(err) ? err.join(', ') : 'An error occurred';
        alertMessage(errorMessage, "error");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
        props.setIsDelink(false);
      });
  };

  return (
    <div>
      <Row
        className="tagsInput emailForm"
        type="flex"
        align="middle"
        gutter={8}
      >
        {
          !props.isDelink && (
            <Alert
            message= {`Linkable orders and HAWBs must originate from the same account (${props.order.account_code}).`}         
            type="info"
            style={{ marginBottom: "16px" }}
            />
          )
        }
        <Col span={24}>
          <FormItem label={I18n.t("general.orders")}>
            <Select
              value={!props.isDelink ? orderNos : uncheckedOrderNos}
              onChange={(value) => handleOrderNosChange("orderNos", value)}
              mode="tags"
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Enter Orders"
              tokenSeparators={[","]}
              maxTagCount={9}
              maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
            ></Select>
          </FormItem>
        </Col>
        {!props.isDelink && (
          <Col span={24}>
            <FormItem label={I18n.t("general.hawb")}>
              <Select
                value={hawbNos}
                onChange={(value) => handleOrderNosChange("hawbNos", value)}
                mode="tags"
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Enter HAWB's"
                tokenSeparators={[","]}
                maxTagCount={9}
                maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
              ></Select>
            </FormItem>
          </Col>
        )}
      </Row>
      {warningMessages.length > 0 && (
        <Alert
          message={warningMessages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
          type="warning"
          showIcon
          style={{ marginBottom: "16px" }}
        />
      )}
      <Row type="flex" align="middle" gutter={8}>
        <Col xs={24} className="alignCenter">
          <Button
            className="marginRight5"
            onClick={() => {
              props.onCancel(),
                setOrderNos([]),
                setHawbNos([]),
                props.setIsDelink(false);
            }}
            icon="close"
          >
            {I18n.t("general.cancel")}
          </Button>
          <Button
            type="primary"
            onClick={() => handleSave()}
            icon="save"
            disabled={
              !props.isDelink
                ? _.isEmpty(orderNos) && _.isEmpty(hawbNos)
                : _.isEmpty(props.extractedOrderNos) &&
                  _.isEmpty(props.extractedHawbNos)
            }
            loading={loading}
          >
            {I18n.t("general.save")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

import objectToFormData from "object-to-formdata";
import axios from "axios/index";
import _ from "lodash";
import AppConfig from "../config/AppConfig";
import userStore from "../stores/UserStore";
import { alertMessage, isEmpty } from "../common/Common";
import { AuthenticationService } from "./Auth";
import { clearSession } from "../helpers/common";


export const isTokenValid = (token = {}) => {
  const now = Math.floor(Date.now() / 1000);
  const expiry = token.created_at + token.expires_in;
  if(now < expiry){
    return true;
  }
  return false;
};

export async function getRefreshToken (token_details) {
  if (!token_details || !token_details.refresh_token) {
    throw new Error('Invalid token details');
  }
  try {
    const response = await fetch(GenerateURL('oauth/token'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        client_id: AppConfig.clientId,
        client_secret: AppConfig.clientSecret,
        grant_type: 'refresh_token',
        refresh_token: token_details.refresh_token,
      }),
    });
    if (!response.ok) {
      throw new Error('Token refresh failed');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Token refresh error: ${error.message}`);
  }
}

axios.interceptors.request.use(async config => {
  const token_details = JSON.parse(localStorage.getItem('auth') || '{}');
  // if not token details, return config
  if (!Object.keys(token_details).length) { return config; } // if user is not logged in, return config


  if (isTokenValid(token_details)) {
    const token = token_details.access_token;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    try {
      const data = await getRefreshToken(token_details);
      const token = data.access_token;
      config.headers.Authorization = `Bearer ${token}`;
      localStorage.setItem('auth', JSON.stringify(data));
      return config;
    } catch (error) {
      alertMessage("Your session has expired. Please login again.", "error");
      localStorage.clear();
      return Promise.reject({
        response: {
          data: {
            errors: [ error.message ],
          },
        },
      });
    }
  }
}, error => {
  return Promise.reject(error);
});

// similar one for response
axios.interceptors.response.use(response => {
  return response;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      if(!originalRequest._retry) // if already tried, return error
      {
        clearSession();
        return Promise.reject(error);
      }

      originalRequest._retry = true;

      const token_details = JSON.parse(localStorage.getItem('auth') || '{}');
      if (!Object.keys(token_details).length) { return Promise.reject(error); } // if user is not logged in, return config

      try {
        let data = {};
        if(isTokenValid(token_details)){
          data = token_details;
        }else{
          data = await getRefreshToken(token_details);
        }
        const token = data.access_token;
        originalRequest.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem('auth', JSON.stringify(data));
        return axios(originalRequest);
      } catch (error) {
        alertMessage("Your session has expired. Please login again.", "error");
        localStorage.clear();
        return Promise.reject({
          response: {
            data: {
              errors: [ error.message ],
            },
          },

        })
      }
    }else{
      return Promise.reject(error);
    }
  }
)





export function PageParams(page, perPage) {
  return `page=${page || 1}&per_page=${perPage || AppConfig.perPage}`;
}

export function optionalPageParams(page, perPage, isReqPagination = true) {
  return `page=${page || 1}&per_page=${
    !isEmpty(perPage) ? perPage : isReqPagination ? AppConfig.perPage : ''
  }`;
}

export function ApiUrl(path) {
  return `${AppConfig.baseUrl}/api/${path}`;
}

export function javaWmsUrl (path) {
  return `${AppConfig.wmsUrl}${path}`;
}

export function javaWmsUrlPreCall (path) {
  return `${AppConfig.wmsUrl}${path}`;
}

export function GenerateURL(path) {
  return `${AppConfig.baseUrl}/${path}`;
}

export function HttpErrorMessage(error) {
  const response = {};
  if(!_.isString(error)){
    if (_.get(error, "response.data.errors", null)) {
      response.errors = error.response.data.errors;
    }
    const dataKeys = _.get(error, "response.data", {});
    _.forEach(dataKeys, (value, key) => {
      if (key !== "errors") {
        response[key] = !isEmpty(value) ? value : "";
      }
    });
    if (Object.keys(response).length > 0) {
      return response;
    }
    return [error.message];
  } else {
    return [error]
  }
}

export function HttpErrorResponseKeys(error) {
  if (_.get(error, "response.data", {})) {
    const data = error.response.data || {};
    const response = {};
    Object.keys(data).forEach((key) => {
      response[key] = data[key];
    });
    return response;
  }
  return {};
}

export function ParseGeneralResponse(success, response, options) {
  let result = { success };

  if (!_.isEmpty(options.return)) {
    result.return = options.return;
  }
  if (success) {
    const dataKeys = options.responseDataKeys || {};
    _.forEach(dataKeys, (value, key) => {
      const valueKey = _.isEmpty(value) ? "data" : `data.${value}`;
      result[key] = _.get(response, valueKey, null);
    });
  } else {
    result = HttpErrorMessage(response);
  }
  return result;
}

export async function Call (method, url, data, parseCallback, options = {}, isPassOrgId = true) {
  const currentUser = localStorage.getItem("CURRENT_USER") ? JSON.parse(localStorage.getItem("CURRENT_USER")) : null;
  if (currentUser) {
    const { roles, accounts } = currentUser;
    const account_ids = accounts && _.isArray(accounts) ? accounts.map(acc => acc.id).join(',') : ''
    const currentOrg = localStorage.getItem("selectedOrg")
    const isSuperAdmin = roles.includes("super_admin");
    const isShipper = roles.includes("shipper");
    const currentRoleParam = method === "get" ? `current_role=${roles[ 0 ]}` : '';
    const selectedWarehouses = localStorage.getItem("selectedWarehouses") || '';
    // const warehouseIdsParam = selectedWarehouses.length < 1
    //   ? JSON.parse(localStorage.getItem("warehouses"))?.map(w => w.id).join(',')
    //   : selectedWarehouses;
    const warehouseIdsParam = selectedWarehouses.length < 1
        ? (JSON.parse(localStorage.getItem("warehouses")) ?? []).map(w => w.id).join(',')
        : selectedWarehouses;

    if (!isSuperAdmin && isPassOrgId) {
      if (method === "get") {
       
        url += `${url.includes("?") ? '&' : '?'}${currentRoleParam}`;
        url += !url.includes("org_id") ? `&org_id=${currentOrg}` : '';
        url += !url.includes("warehouse_ids") ? `&warehouse_ids=${warehouseIdsParam}` : '';
        url += isShipper && !url.includes("account_id") && !isEmpty(account_ids) ? `&account_id=${account_ids}` : '';
      } else if (!data.current_role) {
        data.current_role = roles[ 0 ];
      }
      if (data && !data.org_id) {
        data.org_id = currentOrg;
      }
    } else {
      if (method === "get") {
        url += `${url.indexOf("?") < 0 ? "?" : "&"}`;
        url += `current_role=${roles[0]}`;
        url += isShipper && !url.includes("account_id") && !isEmpty(account_ids) ? `&account_id=${account_ids}` : '';
      } else if (!data.current_role) {
        data.current_role = roles[0];
      }
    }
  }
  try {
    const response = await axios({ method, url, data });
    if (isEmpty(response.data)) {
      postErrorToSlack({ url, method, payLoad: data, error: "Did not get the response" });
    }
    return parseCallback(true, response, options);
  } catch (error) {
    if (_.isObject(error) && _.isObject(error.response)) {
      if (error.response.status === 401) {
        // localStorage.clear();
        // window.location.href = "/login";
        // const token_details = JSON.parse(localStorage.getItem('auth') || '{}');
        // getRefreshToken(token_details).then((data) => {
        //     localStorage.setItem('auth', JSON.stringify(data));
        //     window.location.reload();
        // }
        // ).catch((err) => {
        //   localStorage.clear();
        //   window.location.href = "/login";
        // }
        // );
      }
      if (![ 404, 422 ].includes(error.response.status) || !_.isObject(error.response.data)) {
        postErrorToSlack({ url, method, payLoad: data, error: error.response.data });
      }
    }
    return parseCallback(false, error, options);
  }
}

export function setObjectToFormData(data) {
  const currentUser = localStorage.getItem("CURRENT_USER") ? JSON.parse(localStorage.getItem("CURRENT_USER")) : null;
  const dataToPost = Object.assign({}, data);
  const current_role = userStore.getCurrentRole();
  if (!userStore.superAdmin() && !dataToPost.org_id) {
    dataToPost.org_id = userStore.getStateValue("selectedOrg");
    data.current_role = current_role;
  }
  // if(!userStore.superAdmin() && !dataToPost.selected_whs) {
  //   dataToPost.selected_whs = localStorage.getItem("selectedWarehouses");
  // }
  if( current_role === "shipper" && isEmpty(data.account_id) && !isEmpty(currentUser.accounts)){
    const account_ids = currentUser.accounts && _.isArray(currentUser.accounts) ? currentUser.accounts.map(acc => acc.id).join(',') : ''
    data.account_id = account_ids;
  }
  const formData = objectToFormData(dataToPost);
  return formData;
}

export function buildQueryString (obj, encodeReq=true){
  return Object.keys(obj)
    .map((key) => {
      return encodeReq
        ? `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        : `${key}=${obj[key]}`;
    }).join("&");
}

export function postErrorToSlack ({url, payLoad, method, error = '', errorInfo = {}}){
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = "";
    if(url){
      raw = "URL: "+ url + "\n";
    }
    if(method && method.toLowerCase() !== 'get') {
      raw += "payLoad: "+ JSON.stringify(payLoad) + "\n";
    }
    if(!isEmpty(error)) {
      if(_.isArray(error)){
        raw += '\nError' +  error.join(', ');
      }else if(_.isObject(error)){ 
        raw += '\nError Stack' +  (error.stack ? error.stack : error.message ? error.message : '');
      }else {
        raw += '\nError' +  error.toString();
      }
    }





    if(errorInfo?.componentStack){
      raw += '\nError Stack' +  errorInfo.componentStack;
    }


    
    // exclude the error message if error string has
  const toExclude = [ 'googletag', 'appendChild', 'Invariant failed', 'Too Large', 'URL: /api/'];

    if(toExclude.some((ex) => raw.includes(ex))){
      return;
    }
    
    const reqUrl = ApiUrl("v1/users/ui_exception?operation_code=EPS");
    var requestOptions = {
      url: reqUrl,
      method: 'POST',
      headers: myHeaders,
      data: {message: raw},
      
      redirect: 'follow'
    };
     axios({...requestOptions})
      .then((response) => {
      })
      .catch((error) => {
      });
    // fetch(reqUrl, requestOptions)
    //   .then(response => response.text())
}


import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEmpty } from 'lodash'
import AppConfig from '../../config/AppConfig'
import { momentTime } from '../../helpers/date_functions'
import { OrgContext } from '../../context/OrgContext';

const DisplayTime = ({
  dateTimeString,
  timeFormat,
  displayWithDate,
  timeZoneId,
  isUTC,
  tz_short_name = '',
  style = {}
}) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const isMilitaryTime = organizationSettings.is_military_time === 'true'
  if (isEmpty(dateTimeString) || !moment(dateTimeString).isValid()) {
    return <Fragment />
  }

  const formatToShow = (dateTimeString) => {
    if(dateTimeString && tz_short_name) {
      return <span style={{ ...style }}>{`${dateTimeString} ${tz_short_name}`}</span>
    }
    return <span style={{ ...style }}>{dateTimeString}</span>;
  }
  let displayFormat = timeFormat
  if (isEmpty(displayFormat)) {
    if (displayWithDate) {
      displayFormat = isMilitaryTime
        ? AppConfig.milataryDateTimeFormat
        : AppConfig.dateTimeFormat
    } else {
      displayFormat = isMilitaryTime
        ? AppConfig.milatary_time_format
        : AppConfig.time_format
    }
  }
  if(isUTC){
    if (timeZoneId) {
        return formatToShow(momentTime(displayFormat, dateTimeString, timeZoneId))
    } else {
        return formatToShow(moment.utc(dateTimeString).format(displayFormat))
    }
  } else {
    if (timeZoneId) {
        return formatToShow(momentTime(displayFormat, dateTimeString, timeZoneId))
    } else {
        return formatToShow(moment(dateTimeString).format(displayFormat))
    }
  }
  return <Fragment />
  
}

DisplayTime.propTypes = {
  dateTimeString: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
  displayWithDate: PropTypes.bool.isRequired,
  timeZoneId: PropTypes.string,
  isUTC: PropTypes.bool,
}

DisplayTime.defaultProps = {
  dateTimeString: '',
  timeFormat: '',
  displayWithDate: true,
  timeZoneId: null,
  isUTC: false,
}

export default DisplayTime

import React, { Fragment, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Col,
  Spin,
  Row,
  Form,
  Input,
  Popconfirm,
  Radio,
  Typography,
  Select
} from 'antd'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import {
  alertMessage,
  doValidate,
  isEmpty,
  validateAddressForm,
} from '../../common/Common'
import FormErrors from '../common/FormErrors'
import moment from 'moment'
import LocationForm from '../common/LocationForm'
import { loadGoogleMaps } from '../../utils/Utils'
import { FormItem } from '../../common/UIComponents'
import MobileInput from '../common/MobileInput'
import DispatcherNotes from '../orders/DispatcherNotes'
import { dispatcherNotesApi } from '../../api/OrdersApi'
import { ReleasesApi } from '../../api/ReleasesApi'
import OrderPictures from '../common/OrderPictures';
import { PicturesApi } from '../../api/PicturesApi';
import DateTimeSelector from '../../common/DateTimeSelector';
import { checkIfRequiredFieldsAreFilled, checkServiceExistance } from '../../helpers/common';
import { OrgContext } from '../../context/OrgContext';
import AppConfig from '../../config/AppConfig';
import { fetchLocations } from '../../api/LocationsApi';

const requiredFields = [
  {
    form_field: 'company_name',
    ui_name: I18n.t('general.company_name'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'pickup_person',
    ui_name: I18n.t('general.pickup_person'),
    isRequired: false,
    inputType: 'text',
  },
  {
    form_field: 'email',
    ui_name: I18n.t('general.email'),
    isRequired: false,
    inputType: 'email',
  },
  {
    form_field: 'contact_no',
    ui_name: `${I18n.t('general.contact_no')}`,
    isRequired: false,
    inputType: 'mobile',
  },
  {
    form_field: 'warehouse_code',
    ui_name: "Pickup Location",
    isRequired: true,
    inputType: 'text',
  },
]

const requiredFieldForOtherRelease = [
  {
    form_field: 'release_date',
    ui_name: I18n.t('releases.release_eta_date'),
    isRequired: true,
    inputType: 'date',
  },
  {
    form_field: 'release_time',
    ui_name: I18n.t('releases.release_eta'),
    isRequired: true,
    inputType: 'time',
  },
]


function ReleaseDetailsForm(props) {
  const {
    currentRelease,
    deleteClick,
    isNew,
    getReleases,
    order_id,
    defaultRelease,account_id,org_id
  } = props

  const [errors, setErrors] = useState([])
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({ ...currentRelease })
  const [driverNotes, setDriverNotes] = useState([])
  const [attachments, setAttachments] = useState([]);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const isMilitaryTime = organizationSettings.is_military_time === 'true'
  const [ warehouses, setWarehouses ] = useState([]);
  
  const getWarehouses = () => {
    setIsLoading(true);
    fetchLocations(AppConfig.warehouseCode, 1, null, true)
      .then((result) => {
        if (result.success) {
          setWarehouses(result.locations.locations);
          if (!isNew) {
            const wh = result.locations.locations.find((wh) => wh.id === formData.pickup_location_id);
            if (wh) {
              setFormData({ ...formData, warehouse_code: wh.location_code, ...currentRelease, ...getAttachments(), ...divideScheduledReleaseTime(currentRelease) });
            }
          }
        }
      }).finally(() => {
        setIsLoading(false);
      });
  };
  
  const getOriverNotes = () => {
    dispatcherNotesApi.fetch(currentRelease.id).then((response) => {
      if (response.success) {
        setDriverNotes(response.dispatch_notes)
      }
    })
  }

  const handleOnChange = (element, value) => {
    const updatedFormData = { ...formData , [element]: value }
    if(element === "release_date"){
      // if release_time is empty, set it to current time
      if(!updatedFormData.release_time){
        updatedFormData.release_time = moment()
      }
    }else if(element === "release_time"){
      // if release_date is empty, set it to current date
      if(!updatedFormData.release_date){
        updatedFormData.release_date = moment()
      }
    }
    setFormData(updatedFormData)
    // setFormData({
    //   ...formData,
    //   [element]: value,
    // })
  }

  //  useEffect(() => {
  //   setFormData({...formData, status: currentRelease.status})
  //  }, [currentRelease]); 

  const clearClick = () => {
    if (currentRelease.id) {
      setFormData({
        ...defaultRelease,
        id: currentRelease.id,
        isNew: false,
      })
    } else {
      setFormData({
         ...defaultRelease,
         isNew: true,
      })
    }
  }

  const handleAddressOnChange = (element, value) => {
    setFormData({
      ...formData,
      delivery_location: { ...formData.delivery_location, [element]: value },
    })
  }

  const handleLocationChange = (value) => {
    setFormData({ ...formData, delivery_location: { ...value } })
  }

  const getAttachments = () => {
    // const fetchedAttachments = [];
    setIsLoading(true);
    // try{
    //   const attachments = PicturesApi.fetch("release", currentRelease.id);
    //   fetchedAttachments = attachments.pictures;
    // }
    // catch(error){
    //   fetchedAttachments = [];
    // }
    // finally{
    //   setIsLoading(false);
    //   return fetchedAttachments;
    // }
    PicturesApi.fetch("release", currentRelease.id)
      .then((result) => {
        setAttachments(result.pictures);
      })
      .catch((error) => {
        setAttachments([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleSuccessFullUpload = (attachments) => {
    setAttachments(attachments);
    setFormData({ ...formData, attachments: attachments })
  }

  const divideScheduledReleaseTime = (currentRelease) => {
      return {
        release_date: currentRelease.scheduled_release_time || null,
        release_time: currentRelease.scheduled_release_time || null,
      }
  }

  useEffect(() => {
    getWarehouses()
    if (window.google) {
      setIsMapLoaded(true)
    } else {
      loadGoogleMaps(() => {
        setIsMapLoaded(true)
      })
    }
    if (currentRelease.id) {
      getOriverNotes()
    }
  }, [])

  const renderDispatcherNotes = () => {
    const updatedFormData = _.cloneDeep(formData)
    const { organization_id } = updatedFormData
    return (
      <DispatcherNotes
        organization_id={organization_id}
        currentSourceRecord={updatedFormData}
        customer_order_notes={driverNotes}
        key={'customer_order_notes'}
        refer_type="release"
        apiEndpoint={dispatcherNotesApi}
        noteTitle="Driver Notes"
      />
    )
  }

  const handleSubmit = (e) => {
    e && e.preventDefault()
    props.form.validateFields(async (err, values) => {
      if (!err) {
      } else {
      }
    })
  }

  const pickupPersonDetail = () => {
    const isCrossDock = formData.release_type === 'cross_dock'
    return (
      // <Spin spinning={isLoading} delay={1000}>
      <Row gutter={16}>
        <h3
          style={{ fontWeight: 'initial !important' }}
          className="marginLeft10"
        >
          {`${I18n.t('releases.release_to')} ${I18n.t('general.details')}`}
        </h3>
        <Col sm={24} xs={24} md={12} lg={4}>
          <FormItem label={I18n.t('general.company_name')} require>
            <Input
              value={formData.company_name}
              onChange={(e) => handleOnChange('company_name', e.target.value)}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={12} lg={4}>
          <FormItem label={I18n.t('releases.pickup_person')}>
            <Input
              value={formData.pickup_person}
              onChange={(e) => handleOnChange('pickup_person', e.target.value)}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={12} lg={4}>
          <FormItem label={I18n.t('general.email')}>
            <Input
              value={formData.email}
              onChange={(e) => handleOnChange('email', e.target.value)}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={12} lg={4}>
          <FormItem label={I18n.t('general.contact_no')}>
              <MobileInput
              handleInputChange={(value, data) => {
                handleOnChange('contact_no', value, data)
              }}
              className="width100Per"
              value={formData.contact_no ? formData.contact_no : ''}
            />
          </FormItem>
        </Col>
        <Col sm={24} xs={24} md={12} lg={8}>
          <DateTimeSelector
            dateProps={ {
                label: I18n.t('releases.release_eta_date'), 
              format: 'Do MMM YYYY',
              value: formData.release_date,
              onChange: (date) => {
                handleOnChange('release_date', date)
              },
              style: { width: '100%', height: '100%' },
                disabledDate: (current) => current && current <= moment().startOf('day'),
                require: isCrossDock ? false : true
            } }
            timeProps={ {
              label: I18n.t('releases.release_eta'), 
              format : isMilitaryTime ? 'HH:mm' : 'hh:mm A',
              showTime: { format: isMilitaryTime ? 'HH:mm' : 'hh:mm A', use12Hours: !isMilitaryTime },
              isMilitaryTime,
              onChange: (time) => {
                handleOnChange('release_time', time)
              },
              value: formData.release_time,
              style: { width: '100%' , height : '100%', marginTop: '5px'},
              minuteStep: 1,
              className: "formMaterialTimePicker",
              require: isCrossDock ? false : true
            } }
            gridStyle={{ row : { type : "flex" , gutter : 16 } , dateCol : { span : 12 } , timeCol : { span : 12 } }} 
          />
        </Col>
      </Row>
      // </Spin>
    )
  }


  const checkAddressFields = (data) => {
    const addressKeys = [ 'country', 'state', 'city', 'address_line1', 'zipcode' ];
    const addressRequiredFields = [
      'phone_number_one',
      'first_name',
      {
        key: 'l_address',
        fields: addressKeys
      },
    ];
    const addressFieldFilled = checkIfRequiredFieldsAreFilled(
      data,
      addressRequiredFields,
    );
    return addressFieldFilled;
  }

  const validateForm = (completed = false) => {
    let validationErrors = []
    const isCrossDock = formData.release_type === 'cross_dock';
    const allRequiredFields = isCrossDock ? requiredFields : requiredFields.concat(requiredFieldForOtherRelease);
    const errors = doValidate(allRequiredFields, formData)
    const details = !isEmpty(formData) ? formData : {}
    const { delivery_location } = details
    if(!isCrossDock){
      const addressFieldFilled = checkAddressFields(delivery_location);
      validationErrors = [].concat(
        errors,
        addressFieldFilled ? [] : ["Please fill all required fields in delivery details"]
      )
    }else{
      validationErrors = errors;
    }
    const cleanedErrors = _.compact(validationErrors)
    if (cleanedErrors.length > 0) {
      setErrors([...cleanedErrors])
    } else {
      setErrors([])
      handleSave(completed)
    }
  }

  const handleSave = async (completed = false) => {
    try {
      setIsLoading(true)
      let  payload = _.cloneDeep(formData)
      payload.organization_id = userStore.getStateValue('selectedOrg')
      payload.customer_order_id = order_id
      let currentWh = warehouses.find((wh) => wh.location_code === payload.warehouse_code)
      payload.pickup_location_id = currentWh ? currentWh.id : null
      delete payload.warehouse_code

      // schedule release date and time combined
      if(!isEmpty(payload.release_date) && !isEmpty(payload.release_time)){
        payload.scheduled_release_time = moment(payload.release_date).format('YYYY-MM-DD') + ' ' + moment(payload.release_time).format('HH:mm') 
      }else payload.scheduled_release_time = null

      if (completed) {
        payload.completed = true
        payload.status = "completed"
      }
      let response = {}
      if (payload.id) {
        // payload.id = payload._id
        response = await ReleasesApi.update(payload.id, payload)
      } else {
        response = await ReleasesApi.create(payload)
      }
      if (response.success) {
        setErrors([])
        const successMessage = payload._id
          ? I18n.t('messages.updated')
          : I18n.t('messages.saved')
        alertMessage(successMessage, 'success', '5')
        getReleases()
      } else {
        setErrors([...response.errors])
      }
    } catch (error) {
      setErrors([error.message])
    } finally {
      setIsLoading(false)
    }
  }

  //  const { getFieldDecorator } = props.form
  const isCrossDock = formData.release_type === 'cross_dock'
  return (
      <Spin spinning={isLoading} delay={1000}>
      <Form>
        <Row className="marginTop10" gutter={ 8 } type='flex' >
          <Col span={8}>
            <FormItem label={ I18n.t('releases.release_type.label') } require>
              <Radio.Group
                onChange={ (e) => handleOnChange('release_type', e.target.value) }
                value={ formData.release_type }
              >
                <Radio value="cross_dock">{ I18n.t('releases.wh_dock') }</Radio>
                <Radio value="off_wh">{ I18n.t('releases.off_wh') }</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col span={ 10 }>
            <FormItem label={"Pickup Location"} require>
              <Select
                value={ formData.warehouse_code || '' }
                showSearch
                style={ { width: '100%' } }
                filterOption={ (input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={ (e) =>
                  handleOnChange('warehouse_code', e)
                }
              >
                { warehouses.map((warehouse) => (
                  <Select.Option
                    key={ warehouse.location_code }
                    value={ warehouse.location_code }
                  >
                    { warehouse.name }
                  </Select.Option>
                )) }
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row className="marginTop10" gutter={16}>
          <Col xs={24}>{pickupPersonDetail()}</Col>
        </Row>
        <Row>
          <h3 style={{ fontWeight: 'initial !important' }}>
            {I18n.t('releases.delivery_details')}
          </h3>
          <LocationForm
            {...props}
            formData={
              formData?.delivery_location ? formData.delivery_location : {}
            }
            //errors={errors[key]}
            handleOnChange={(element, value) =>
              handleAddressOnChange(element, value)
            }
            handleLocationChange={(value) => handleLocationChange(value)}
            includePredefinedStops={true}
            editableFields={[
              'email',
              'phone_number_one',
              'phone_number_two',
              'address',
              'l_address',
            ]}
            map={isMapLoaded}
            showAddress
            // requiredFields={[]}
            requiredFields={ isCrossDock ? [] : [ "first_name",
              "contact_number_one",
              "address_line1",
              "city",
              "state",
              "country",
              "zipcode",
            ] }
          />
        </Row>

        { checkServiceExistance('OREU') && <Row className="marginTop10">
          <Col xs={24} className="alignCenter">
            {
              <Popconfirm
                placement="topRight"
                title={I18n.t('messages.clear_confirm')}
                onConfirm={() => clearClick(currentRelease['_id'])}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger" className="marginRight5" icon="delete">
                  {I18n.t('general.clear')}
                </Button>
              </Popconfirm>
            }
            <Button type="primary" onClick={() => validateForm()} icon="save">
              {isNew ? I18n.t('general.save') : I18n.t('general.update')}
            </Button>
            {
              formData.release_type !== "off_wh" && !isEmpty(currentRelease.id) && currentRelease.status !== 'completed' && <Popconfirm
                placement="topRight"
                title={I18n.t('releases.release_confirm')}
                onConfirm={() => {
                  validateForm(true)
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="marginLeft10 buttonMitesse"
                  icon="arrow-right"
                >
                  {I18n.t('releases.ready')}
                </Button>
              </Popconfirm>
            }
          </Col>
        </Row>
        }
        <Row>
          <Col xs={24}>{FormErrors(errors)}</Col>
        </Row>

        {!isEmpty(currentRelease.id) && (
          <Row className="marginTop20">
            <Col xs={24}>{renderDispatcherNotes()}</Col>
          </Row>
        )}
        {!isEmpty(currentRelease.id) && (
          <Row className="marginTop20">
            <Col xs={12}>{
              attachments 
                ?
                <OrderPictures
                  order_id={ order_id }
                  takeMultipleSigns={ false }
                  showType="imageClick"
                  refer="release"
                  release_id={ currentRelease.id }
                  attachments={ attachments }
                  account_id={ account_id }
                  org_id={ org_id }
                  getAttachments={ getAttachments }
                  handleSuccessFullUpload={ handleSuccessFullUpload }
                /> : null
            }</Col>
          </Row>
        )}
      </Form>
    </Spin>
  )
}

const ReleaseForm = Form.create({ name: 'recovery_form' })(ReleaseDetailsForm)
export { ReleaseForm }

ReleaseForm.propTypes = {
  currentRelease: PropTypes.shape(),
}

ReleaseForm.defaultProps = {
  currentRelease: null,
}

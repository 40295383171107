import React, { useState } from "react";
import { Button, Col, Row, Tooltip } from "antd";
import BaseModal from "../components/BaseModal";
import I18n from "./I18n";

const ManualBolViewer = ({ verifyManualBol, manualBol, title, height="15px", width="15px" }) => {
  const [openModal, setOpenModal] = useState(false);
  const Boltitle = _.get(title, "customer_order_number", "NA");
  const origin = _.get(verifyManualBol, "origin", "");
  const destination = _.get(verifyManualBol, "destination", "");

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCLoseModal = () => {
    setOpenModal(false);
  };

  if (typeof verifyManualBol === "string") {
    return (
      <Tooltip title="Manual Bol" placement="left">
        <img
          src={manualBol}
          onClick={() => {
            window.open(verifyManualBol, "_blank");
          }}
          style={{ height: height, width: width, cursor: "pointer" }}
        />
      </Tooltip>
    );
  } else if (verifyManualBol && origin && destination) {
    const content = (
      <Row type="flex" gutter={8} justify="space-around">
        <Col>
          <Button
            onClick={() => {
              window.open(origin, "_blank");
            }}
            type="primary"
            icon="vertical-align-bottom"
            size="small"
          >
            Pickup
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              window.open(destination, "_blank");
            }}
            type="primary"
            icon="vertical-align-bottom"
            size="small"
          >
            Delivery
          </Button>
        </Col>
      </Row>
    );

    return (
      <>
        <Tooltip title="Manual Bol" placement="left">
          <img
            src={manualBol}
            onClick={handleOpenModal}
            style={{ height: height, width: width, cursor: "pointer" }}
          />
        </Tooltip>
        <BaseModal
          title={`${I18n.t("order.bol")} - #${Boltitle}`}
          visible={openModal}
          onCancel={handleCLoseModal}
          footer={null}
          width={"300px"}
        >
          {content}
        </BaseModal>
      </>
    );
  } else if (verifyManualBol && origin) {
    return (
      <Tooltip title="Manual Bol" placement="left">
        <img
          src={manualBol}
          onClick={() => {
            window.open(origin, "_blank");
          }}
          style={{ height: height, width: width, cursor: "pointer" }}
          />
      </Tooltip>
    );
  } else if (verifyManualBol && destination) {
    return (
      <Tooltip title="Manual Bol" placement="left">
        <img
          src={manualBol}
          onClick={() => {
            window.open(destination, "_blank");
          }}
          style={{ height: height, width: width, cursor: "pointer" }}
          />
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default ManualBolViewer;

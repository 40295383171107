/* eslint-disable import/prefer-default-export */
import moment from 'moment'
import moment_tz from 'moment-timezone'
import _ from 'lodash'
import { isEmpty } from '../common/Common'
import AppConfig from '../config/AppConfig'

export const getDisabledHours = (element, disabledHours, record) => {
  const hours = [...disabledHours]
  if (element === 'end_time' && !isEmpty(record.start_time)) {
    for (let i = 0; i < moment(record.start_time).hour(); i += 1) {
      hours.push(i)
    }
  }
  return hours
}

export const getDisabledMinutes = (
  element,
  selectedHour,
  record,
  endHour,
  endMinute = 59,
  startHour,
  startMinute,
) => {
  let minutes = []
  if (element === 'end_time') {
    if (!isEmpty(record.start_time)) {
      if (selectedHour === moment(record.start_time).hour()) {
        for (let i = 0; i < moment(record.start_time).minute(); i += 1) {
          minutes.push(i)
        }
      }
      if (selectedHour === endHour) {
        if (!isEmpty(endMinute)) {
          for (let i = endMinute+1; i < 59 ; i += 1) {
            minutes.push(i)
          }
        } else {
          minutes = [].concat(minutes, [
            5,
            10,
            15,
            20,
            25,
            30,
            35,
            40,
            45,
            50,
            55,
          ])
        }
      }
    }
  } else { 
    if (selectedHour === endHour) {
      if (!isEmpty(endMinute)) {
        for (let i = endMinute+1; i < 59 ; i += 1) {
          minutes.push(i)
        }
      } else {
        minutes = [].concat(minutes, [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55])
      }
    } else {
       if (selectedHour === startHour) {
        for (let i = startMinute-1 ; i >= 0 ; i -= 1) {
          minutes.push(i)
        }
       }
    }
  }
  return minutes
}

export const formatDateTime = (dateValue, reqFormate) => {
  if (!isEmpty(dateValue) && moment(dateValue).isValid) {
    const formattedValue = moment(dateValue).format(reqFormate)
    return formattedValue
  }
  return null
}

export const stringToMoment = (dateString, type = 'date') => {
  if (!isEmpty(dateString) && moment(dateString).isValid) {
    if (type === 'time') {
      return moment(dateString, 'HH:mm')
    }
    return moment(dateString)
  }
  return null
}

export const momentTime = (
  formateTo = '',
  dafaultValue = '',
  timeZoneName = '',
) => {
  let momentObj = null
  if (!isEmpty(dafaultValue)) {
    momentObj = moment_tz(dafaultValue)
  } else {
    momentObj = moment_tz()
  }
  if (!isEmpty(timeZoneName) && momentObj.tz(timeZoneName).isValid()) {
    momentObj = momentObj.tz(timeZoneName)
  }
  if (!isEmpty(formateTo)) {
    momentObj = momentObj.format(formateTo)
  }

  // let timeValue = '';
  // if (!isEmpty(timeZoneName)) {
  //   if (dafaultValue) {
  //     timeValue = moment_tz(dafaultValue).tz(timeZoneName).format(formateTo);
  //   } else {
  //     timeValue = moment_tz().tz(timeZoneName).format(formateTo);
  //   }
  // } else if (dafaultValue) {
  //   timeValue = moment(dafaultValue).format(formateTo);
  // } else if (dafaultValue) {
  //   timeValue = moment().format(formateTo);
  // }
  // if (timeValue) {
  //   return timeValue;
  // }
  return momentObj
}

export const momentTimeWithShortName = (
  formateTo = '',
  dafaultValue = '',
  timeZoneName = '',
  tzShortName = '',
) => {
  const formattedTime = momentTime(formateTo, dafaultValue, timeZoneName)
  const momentObj = `${formattedTime} ${tzShortName ? ` ${tzShortName}` : ''}`
  return momentObj
}

export const getAllDisabledHours = (
  dateValue,
  disabledHours,
  limitStartTime = null,
  limitEndTime = null,
  timeZoneName = '',
) => {
  let hours = [...disabledHours]
  if (!(!isEmpty(dateValue) && moment_tz(dateValue).isValid())) {
    dateValue = moment_tz()
  }
  if (dateValue) {
    const dateValueDate = momentTime('YYYY-MM-DD', dateValue, timeZoneName)
    const todayDate = momentTime('YYYY-MM-DD', '', timeZoneName)
    const currentHour = momentTime('', '', timeZoneName).hour()
    const isSameorBefore = moment_tz(dateValueDate).isSameOrBefore(todayDate)
    let limitMaxHour = 0
    const islimitStartTimeValid =
      !isEmpty(limitStartTime) && moment(limitStartTime).isValid()
    if (isSameorBefore || islimitStartTimeValid) {
      if (islimitStartTimeValid) {
        limitMaxHour =
          momentTime('', limitStartTime, timeZoneName).hour() > currentHour
            ? momentTime('', limitStartTime, timeZoneName).hour()
            : currentHour
      } else {
        limitMaxHour = currentHour
      }
    }
    for (let i = 0; i < limitMaxHour; i += 1) {
      hours.push(i)
    }
    if (!isEmpty(limitEndTime) && moment(limitEndTime).isValid()) {
      const limitStartHour = momentTime('', limitEndTime, timeZoneName).hour()
      for (let i = limitStartHour; i < 23; i += 1) {
        hours.push(i)
      }
    }
  }
  hours = _.uniq(hours)
  return hours
}

export const getAllDisabledMinutes = (
  dateValue,
  disabledMinutes,
  selectedHour,
  limitStartTime = null,
  limitEndTime = null,
  timeZoneName = '',
) => {
  let minutes = [...disabledMinutes]
  if (!(!isEmpty(dateValue) && moment(dateValue).isValid())) {
    dateValue = moment()
  }
  if (dateValue) {
    const dateValueDate = moment(
      `${moment(dateValue).format('YYYY-MM-DD')} ${selectedHour}:00`,
      'YYYY-MM-DD HH:mm',
    ).format()
    const todayDate = momentTime('YYYY-MM-DD HH:mm', '', timeZoneName)
    const isSameorBefore = moment_tz(dateValueDate).isSameOrBefore(todayDate)
    const currentMinutes = momentTime('', '', timeZoneName).minutes()
    let limitMaxMinute = 0
    const islimitStartTimeValid =
      !isEmpty(limitStartTime) && moment_tz(limitStartTime).isValid()
    if (isSameorBefore || islimitStartTimeValid) {
      if (islimitStartTimeValid) {
        limitMaxMinute =
          momentTime('', limitStartTime, timeZoneName).minutes() >
          currentMinutes
            ? momentTime('', limitStartTime, timeZoneName).minutes()
            : currentMinutes
      } else {
        limitMaxMinute = currentMinutes
      }
    }
    for (let i = 0; i < limitMaxMinute; i += 1) {
      minutes.push(i)
    }
    if (!isEmpty(limitEndTime) && moment_tz(limitEndTime).isValid()) {
      const limitStartMinute = momentTime(
        '',
        limitEndTime,
        timeZoneName,
      ).minutes()
      for (let i = limitStartMinute; i < 59; i += 1) {
        minutes.push(i)
      }
    }
  }
  minutes = _.uniq(minutes)
  return minutes
}

export const isPastTime = (timeValue, timeZoneName = '') => {
  if (!isEmpty(timeValue) && moment_tz(timeValue).isValid()) {
    const givenDateTime = timeValue // momentTime('YYYY-MM-DD HH:mm', timeValue, timeZoneName);
    const presentValue = momentTime('YYYY-MM-DD HH:mm', '', timeZoneName)
    const isBefore = moment_tz(givenDateTime).isBefore(presentValue)
    if (isBefore) {
      return true
    }
  }
  return false
}

export const isFutureTime = (timeValue, timeZoneName = '') => {
  if (!isEmpty(timeValue) && moment(timeValue).isValid()) {
    const givenDateTime = momentTime(
      'YYYY-MM-DD HH:mm',
      timeValue,
      timeZoneName,
    )
    const presentValue = momentTime('YYYY-MM-DD HH:mm', '', timeZoneName)
    const isAfter = moment(givenDateTime).isAfter(presentValue)
    if (isAfter) {
      return true
    }
  }
  return false
}

export const getCurrentTime = (timeZoneName) => {
  return !isEmpty(timeZoneName) ? moment().tz(timeZoneName) : moment()
}

export const getHMSFromTimeString = (
  dateString,
  strFormat,
  timeZoneName = null,
  isUTC,
) => {
  if (timeZoneName) {
    if (isUTC) {
      const timeString = !isEmpty(dateString)
        ? moment.utc(dateString, strFormat).tz(timeZoneName)
        : moment.utc().tz(timeZoneName)
      return {
        hourValue: timeString.hour(),
        minuteValue: timeString.minutes(),
        secondValue: timeString.seconds(),
      }
    } else {
      const timeString = !isEmpty(dateString)
        ? moment(dateString, strFormat).tz(timeZoneName)
        : moment().tz(timeZoneName)
      return {
        hourValue: timeString.hour(),
        minuteValue: timeString.minutes(),
        secondValue: timeString.seconds(),
      }
    }
  } else {
    if (isUTC) {
      const timeString = !isEmpty(dateString)
        ? moment.utc(dateString, strFormat)
        : moment.utc()
      return {
        hourValue: timeString.hour(),
        minuteValue: timeString.minutes(),
        secondValue: timeString.seconds(),
      }
    } else {
      const timeString = !isEmpty(dateString)
        ? moment(dateString, strFormat)
        : moment()
      return {
        hourValue: timeString.hour(),
        minuteValue: timeString.minutes(),
        secondValue: timeString.seconds(),
      }
    }
  }
}

export const isBetweenTimes = (startTime , endTime, timeString, timeFormate = "HH:mm", isIncludeBoundary = false) => {
  if(!isEmpty(startTime) && !isEmpty(endTime) && !isEmpty(timeString)){
    const result = moment(timeString, timeFormate).isBetween(moment(startTime, timeFormate), moment(endTime, timeFormate), undefined, isIncludeBoundary ? '[]' : '()');
    return result;
  }
  return false;
}

// to check is date or time we are getting is either moment object or not (in case of dependent date and time)
export const isMomentObject = (checkMomentFor, dependentDateOrTime, reqFormat) => {
  const dateTime =
    !isEmpty(checkMomentFor) && !isEmpty(dependentDateOrTime)
      ? moment.isMoment(checkMomentFor)
        ? checkMomentFor.format(reqFormat)
        : checkMomentFor
      : null;
  return dateTime;
};

export const getWeekString = (weekPeriodString) => {
  if(!isEmpty(weekPeriodString)){
    return `${moment(weekPeriodString)
      .startOf('week').format(AppConfig.dateFormat)} ~ ${moment(weekPeriodString).endOf('week')
      .format(AppConfig.dateFormat)}`;
  }
  return "";
}

export const getWeekValues = (weekPeriodString, reqFormat = AppConfig.dateFormat) => {
  if(!isEmpty(weekPeriodString)){
    return { 
      from_date: moment(weekPeriodString).startOf('week').format(reqFormat),
      end_date: moment(weekPeriodString).startOf('week').format(reqFormat)
    };
  }
  return "";
}

import { useState, useEffect } from 'react'
import AppConfig from '../../../config/AppConfig'
import { isEmpty } from '../../../common/Common'

export const useForm = (schema) => {
  const [errors, setErrors] = useState({})

  const [values, setValues] = useState({})
  const PHONE_REGEX = AppConfig.regexMobile
  useEffect(() => {
    const _values = Object.keys(schema).reduce((acc, key) => {
      return {
        ...acc,
        [key]:
          schema[key].initialValue !== undefined
            ? schema[key].initialValue
            : '',
      }
    }, {})
    setValues(_values)
  }, [])

  const register = (key) => (value) => {
    setValues((prev) => ({ ...prev, [key]: value }))
  }

  const validate = () => {
    // holds actual errors shown at ui
    const __errors = {}

    const _errors = Object.keys(schema).reduce((acc, key) => {
      const value = values[key]
      const object = schema[key]

      if (object.required) {
        if (
          !value ||
          (value.trim && !value.trim()) ||
          (typeof object.onValidate == 'function' &&
            object.onValidate(value) == false)
        ) {
          // not valid block
          if (object.defaultError) {
            __errors[key] = object.defaultError
          }

          if (object.excludeErrorWhen && object.excludeErrorWhen(value)) {
            // dont include the error
          } else {
            __errors[key] = object.error
          }

          // do not touch this
          acc[key] = object.error
        }
      } else if (
        object.required === false &&
        key === 'phone_2' &&
        value !== null
      ) {
        if (
          !value ||
          (value.trim && !value.trim()) ||
          (typeof object.onValidate == 'function' &&
            object.onValidate(value) == false)
        ) {
          // not valid block
          if (object.defaultError) {
            __errors[key] = object.defaultError
          }

          if (
            (object.excludeErrorWhen &&
              object.excludeErrorWhen(value) &&
              value === "") ||
            AppConfig.phonePrefix.some((val) => val === value)
          ) {
            return acc
          } else {
            __errors[key] = object.error
          }

          // do not touch this
          acc[key] = object.error
        }
      } else if (key === 'email') {
        if (!isEmpty(values[key]) && !AppConfig.regexEmail.test(values[key])) {
          __errors[key] = 'Email is invalid'
          // do not touch this
          acc[key] = object.error
        } else if (
          isEmpty(values[key]) &&
          ['EMAIL', 'BOTH'].includes(values['alert_preference'])
        ) {
          __errors[key] = 'Email is required'
          // do not touch this
          acc[key] = object.error
        }
      } else if (key === 'phone') {
        if (
          !isEmpty(values[key]) 
        ) {
          let testValue = values[key];
          if (!AppConfig.phonePrefix.some((val) => val === testValue)) {
            if (testValue === "") {
              __errors[key] = "Primary Mobile Number is required";
              acc[key] = object.error;
            } else if (testValue.match(/\d/g).length <= 11) {
              if (
                testValue.match(/\d/g).slice(0, 1)[0] !== "1" ||
                testValue.match(/\d/g).length === 10
              ) {
                __errors[key] = "Primary Mobile Number is invalid";
                // do not touch this
                acc[key] = object.error;
              }
              const mobileNo = testValue;
              testValue = mobileNo.replace(/[^0-9]/g, "");
            } else {
              const mobileNo = testValue;
              testValue = mobileNo.replace(/[^0-9]/g, "");
            }
            if (!PHONE_REGEX.test(testValue)) {
              __errors[key] = "Primary Mobile Number is invalid";
              // do not touch this
              acc[key] = object.error;
            }
          }
          else if(['TEXT', 'BOTH'].includes(values['alert_preference'])){
             __errors[key] = "Primary Mobile Number is required";
             acc[key] = object.error;
          }
        } else if (
          isEmpty(values[key]) &&
          ['TEXT', 'BOTH'].includes(values['alert_preference'])
        ) {
          __errors[key] = 'Primary Mobile Number is required'
          // do not touch this
          acc[key] = object.error
        }
      }
      return acc
    }, {})
    setErrors(__errors)
    return Object.keys(_errors).length == 0
  }

  return [values, errors, register, validate, setErrors]
}

/* eslint-disable react/sort-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import React, {  Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Col, Row, Icon } from '../../common/UIComponents'
import { isEmpty } from '../../common/Common'
import AddressInfo from './AddressInfo'
import { formatUSPhoneNumber } from '../../helpers/common'

const ConsigneeDetails = ({ record, showAddress }) => {

  let username;
  
  if (record.customer_first_name && record.customer_first_name.trim() !== '') {
    username = record.customer_first_name;

    // If 'customer_last_name' is available, append it to the username
    if (record.customer_last_name && record.customer_last_name.trim() !== '') {
      username += ` ${record.customer_last_name}`;
    }
  } else if (record.first_name && record.first_name.trim() !== '') {
    username = record.first_name;

    // If 'last_name' is available, append it to the username

    if (record.last_name && record.last_name.trim() !== '') {
      username += ` ${record.last_name}`;
    }
  } else {
    username = 'N/A'; // Default value if no name is available
  }


  return (
    <Fragment>
      
    <Row>
    <Col lg={2} md={2} sm={6} xs={6}>
          <Icon type="user" />
        </Col>   

        <Col lg={22} md={22} sm={18} xs={18}>
          {username}
        </Col>
    </Row>

      <Row>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Icon type="bank" />
        </Col>

        <Col lg={22} md={22} sm={18} xs={18}>
          {isEmpty(record.company_name) ? 'N/A' : record.company_name}
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Icon type="phone" />
        </Col>

        <Col lg={22} md={22} sm={18} xs={18}>
          {isEmpty(record.customer_phone_one)
            ? 'N/A'
            : formatUSPhoneNumber(record.customer_phone_one)}
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Icon type="phone" />
        </Col>

        <Col lg={22} md={22} sm={18} xs={18}>
          {isEmpty(record.customer_phone_two)
            ? 'N/A'
            : formatUSPhoneNumber(record.customer_phone_two)}
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Icon type="mail" />
        </Col>

        <Col lg={22} md={22} sm={18} xs={18}>
          {isEmpty(record.customer_email) ? 'N/A' : record.customer_email}
        </Col>
      </Row>
      { showAddress && <Row>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Icon type="environment" />
        </Col>

        <Col lg={22} md={22} sm={18} xs={18}>
          {isEmpty(record.l_address) ? 'N/A' : <AddressInfo address={record.l_address}/>}
        </Col>
      </Row>
      }
    </Fragment>
  )
}

ConsigneeDetails.propTypes = {
  record: PropTypes.shape(),
  showAddress: PropTypes.bool,
}

ConsigneeDetails.defaultProps = {
  record: {},
  showAddress: false,
}

export default ConsigneeDetails

import _ from 'lodash';
import { isEmpty } from '../common/Common';
import AppConfig from '../config/AppConfig';

export const findCommonElementsExisting = (arr1, arr2) => {
  return (arr1.some(item => arr2.includes(item)));
};

export const getValueFromArrayOfObjects = (data = [], key, value, reqKey = '') => {
  const matchedRecord = _.find(data, { [key]: value });
  if(isEmpty(reqKey)){
   return matchedRecord;
  }
  if (!isEmpty(matchedRecord)) {
    return !isEmpty(matchedRecord[reqKey]) ? matchedRecord[reqKey] : '';
  }
  return '';
};

export const getRequiedValueFromArray = (data = [], key, value, reqKey) => {
  const matchedRecords= data.filter(record => record[key] === value);
  if (!isEmpty(matchedRecords) && matchedRecords.length > 0) {
    return !isEmpty(reqKey) ? matchedRecords.map(record => record[reqKey]) : [];
  }
  return [];
};

export const sortByDate = (data = [], sortField = "", type = 'ascend') => {
  if (data && data.length > 0) {
    if (!isEmpty(sortField)) {
      const sortedData = data.sort((a, b) => new Date(a[sortField]) - new Date(b[sortField]));
      if (type !== 'ascend') {
        return sortedData.reverse();
      }
      return sortedData;
    }
    return data;
  }
  return [];
}

export const getUniqvaluesFromArray = (data) => {
  if(!isEmpty(data)){
    const converted = _.uniq(_.compact(_.flattenDeep(data)));
    return converted;
  }
  return [];
}

export const setValueToArrayOfObjects = (data = [], key, value, reqKey, newValue = '') => {
  const matchedRecordIndex = _.findIndex(data, { [key]: value });
  if (matchedRecordIndex >= 0) {
    data[matchedRecordIndex][reqKey] = newValue;
    return [...data];
  }
  return [...data];
};

export const getOrdinal = (n) => {
    let ord = "th";
    if (n % 10 == 1 && n % 100 != 11) {
        ord = "st";
    } else if (n % 10 == 2 && n % 100 != 12) {
        ord = "nd";
    } else if (n % 10 == 3 && n % 100 != 13) {
        ord = "rd";
    }
    return `${n}${ord}`;
}

const generateRegexForDecimals = (numDecimals) => {
  if (numDecimals === 0) {
    return /^\d+$/;
  }
  return new RegExp(`^\\d+(\\.\\d{0,${numDecimals}})?$`);
};

export const testValueWithDecimals = (value, numDecimals) => {
  const regex = generateRegexForDecimals(numDecimals);
  return regex.test(value);
};

export const generateReqDecimal = (integer, decimals = 2) => {
  if(decimals < 0){
    decimals = AppConfig.default_decimal_points
  }
  if (integer < 0) {
    return "Both parameters must be non-negative.";
  } else {
    const result = integer / Math.pow(10, decimals);
    return parseFloat(result.toFixed(decimals));
  }
}

export const searchRecords = (records = [], searchInput = '', uniqKey = 'id', keysToCompare = []) => {
  const searchTerms = searchInput.toLowerCase().split(',').map(term => term.trim());
  return _.filter(records, order => {
    for (const term of searchTerms) {
      if (keysToCompare.length > 0) {
        if (keysToCompare.some(key => compareValue(order[key], term))) {
          continue;
        }
      } else {
        for (const key in order) {
          if (compareValue(order[key], term)) {
            return true;
          }
        }
      }
    }
    return false;
  });
}

export const  compareValue = (value, term) => {
  if (_.isString(value)) {
    return value.toLowerCase().includes(term);
  } else if (_.isArray(value)) {
    if (value.every(item => _.isObject(item))) {
      return _.some(value, obj => {
        return _.some(Object.values(obj), objValue => {
          if (_.isString(objValue)) {
            return objValue.toLowerCase().includes(term);
          }
          return false;
        });
      });
    }
    return _.some(value, item => item.toLowerCase().includes(term));
  }
  return false;
}


export const getDifferenceOfArrays = (first = [], second = [], key = 'id') => {
  return first.filter(a => !second.some(b => b[key] === a[key]));
};

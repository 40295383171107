/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import moment from "moment-timezone";
import { isEmpty } from "../common/Common";

export const formatStopName = (contactDetails, location, stopNumber) => {
  const name =
    contactDetails && contactDetails.contact_name
      ? contactDetails.contact_name
      : `Stop ${stopNumber}`;
  let cityState =
    location && location.l_address
      ? `${location.l_address.city}, ${location.l_address.state}`
      : "";
  if (cityState) {
    cityState = cityState.replace(/(^,)|(,$)/g, "");
    cityState = ` (${cityState})`;
  }
  return name + cityState;
};

export const formatStopAddress = (address) => {
  if (address) {
    const displayAddress = (({
      address_line1,
      address_line2,
      city,
      state,
      country,
      zipcode,
    }) => ({
      address_line1,
      address_line2,
      city,
      state,
      country,
      zipcode,
    }))(address);
    const filteredValues = Object.values(displayAddress).filter(
      (addr) => !isEmpty(addr)
    );
    const formattedAddress =
      filteredValues.length > 0 ? filteredValues.join(", ") : "";
    return !isEmpty(formattedAddress) ? formattedAddress.replace(/(^,)|(,$)/g, "") : "";
  }
  return "";
};

export const getStopName = (contactDetails, location, stopNumber) => {
  const name =
    contactDetails && contactDetails.contact_name
      ? contactDetails.contact_name
      : `Stop ${stopNumber}`;
  let cityState =
    location && location.l_address
      ? `${location.l_address.city}, ${location.l_address.state}`
      : "";
  if (cityState) {
    cityState = cityState.replace(/(^,)|(,$)/g, "");
    cityState = ` (${cityState})`;
  }
  return name + cityState;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const rearrangeStops = (
  stops,
  reOrder = false,
  sourceIndex,
  destinationIndex
) => {
  if (stops.length > 2) {
    const ignoredStopTypes = ["WH", "DV"];
    const newStops = [];
    const firstStop = stops[0];
    const lastStop = stops[stops.length - 1];
    const driverStops = stops.filter(
      (stop) =>
        stop.location &&
        stop.location_type === "DV" &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    ); // considering driver stops which are not yet started
    const completedStops = stops.filter(
      (stop) =>
        stop.location &&
        stop.location_type !== "WH" &&
        (stop.status === "COMPLETED" || stop.status === "ARRIVED")
    );
    let stopsData = stops.filter(
      (stop) =>
        stop.location &&
        !ignoredStopTypes.includes(stop.location_type) &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    );
    newStops.push(firstStop);
    let i = 1;
    completedStops.forEach((stop, index) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    if (reOrder) {
      stopsData = reorder(stopsData, sourceIndex, destinationIndex);
    }
    stopsData.forEach((stop) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    driverStops.forEach((stop) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    newStops.push(lastStop);
    return newStops;
  }
  return stops || [];
};

export const reverseStops = (
  stops,
  sortKey,
  sortType = "asc",
  startNumber = 0
) => {
  if (stops.length > 2) {
    const ignoredStopTypes = ["WH", "DV"];
    const newStops = [];
    const firstStop = stops[0];
    const lastStop = stops[stops.length - 1];
    const driverStops = stops.filter(
      (stop) =>
        stop.location &&
        stop.location_type === "DV" &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    ); // considering driver stops which are not yet started
    const completedStops = stops.filter(
      (stop) =>
        stop.location &&
        stop.location_type !== "WH" &&
        (stop.status === "COMPLETED" || stop.status === "ARRIVED")
    );
    const csStopsData = stops.filter(
      (stop) =>
        stop.location &&
        !ignoredStopTypes.includes(stop.location_type) &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    );
    newStops.push(firstStop);
    let i = startNumber;
    completedStops.forEach((stop, index) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    const sortedData = _.orderBy(csStopsData, [sortKey], [sortType]);
    sortedData.forEach((stop) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    driverStops.forEach((stop) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    newStops.push(lastStop);
    return newStops;
  }
  return stops || [];
};

export const reverseStopsWithMoves = (
  stops,
  sortKey,
  sortType = "asc",
  startNumber = 0
) => {
  if (stops.length > 2) {
    const ignoredStopTypes = ["WH", "DV"];
    const newStops = [];
    const firstStop = stops[0];
    const lastStop = stops[stops.length - 1];
    const driverStops = stops.filter(
      (stop) =>
        stop.location &&
        stop.location_type === "DV" &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    ); // considering driver stops which are not yet started
    const completedStops = stops.filter(
      (stop) =>
        stop.location &&
        stop.location_type !== "WH" &&
        (stop.status === "COMPLETED" || stop.status === "ARRIVED")
    );
    const csStopsData = stops.filter(
      (stop) =>
        stop.location &&
        !ignoredStopTypes.includes(stop.location_type) &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    );
    newStops.push(firstStop);
    let i = startNumber;
    completedStops.forEach((stop, index) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    const dependancyIndexes = [];
    const nonDependancyIndexes = [];
    csStopsData.forEach((stop, index) => {
      const dependancyOrders = stop.orders.filter(order => !isEmpty(order.related_order));
      if(dependancyOrders.length > 0){
        dependancyIndexes.push(index);
      } else {
        nonDependancyIndexes.push(index);
      }
    })
    const sortedData = [];
    csStopsData.forEach((stop, index) => {
      if(dependancyIndexes.includes(index)){
        sortedData.push({...csStopsData[index]});
      } else {
        sortedData.push({...csStopsData[nonDependancyIndexes.pop()]});
      }
    });
    sortedData.forEach((stop) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    driverStops.forEach((stop) => {
      i += 1;
      newStops.push(Object.assign({}, stop, { stop_number: i }));
    });
    newStops.push(lastStop);


    return newStops;
  }
  return stops || [];
};

export const stopTypeFromObject = (stop = {}) => {
  if (
    !isEmpty(stop) &&
    _.isObject(stop) &&
    stop?.location
  ) {
    return !isEmpty(stop.location_type) ? stop.location_type : 
    (
      stop.location?.l_type ? stop.location.l_type : ''
    );
  }
  return "";
};

export const findDelayTime = (stop = {}, route, statusKey, timeZoneName) => {
  if (!isEmpty(stop) && _.isObject(stop)) {
    const stopType = stopTypeFromObject(stop);
    if (stopType === "WH" && stop.stop_number === 1) {
      if (
        ["PENDING", "UNALLOCATED"].includes(stop.status) &&
        route[statusKey] !== "COMPLETED" &&
        ["DISPATCHED", "PREPARE"].includes(route[statusKey]) &&
        !isEmpty(stop.estimated_departure_date_time_with_tz)
      ) {
        if (timeZoneName) {
          const dt = moment().tz(timeZoneName).format("YYYY-MM-DD HH:mm");
          return moment(dt).diff(
            moment(stop.estimated_departure_date_time_with_tz),
            "seconds"
          );
        }
        return moment().diff(
          moment(stop.estimated_departure_date_time_with_tz),
          "seconds"
        );
      }
      if (
        !["PENDING", "UNALLOCATED"].includes(stop.status) &&
        !isEmpty(stop.actual_start_date_time_with_tz) &&
        !isEmpty(stop.estimated_departure_date_time_with_tz)
      ) {
        return moment(moment(stop.actual_start_date_time_with_tz)).diff(
          stop.estimated_departure_date_time_with_tz,
          "seconds"
        );
      }
    } else {
      if (
        ["PENDING", "UNALLOCATED"].includes(stop.status) &&
        route[statusKey] !== "COMPLETED" &&
        ["DISPATCHED", "PREPARE"].includes(route[statusKey]) &&
        !isEmpty(stop.scheduled_end_date_time_with_tz)
      ) {
        if (timeZoneName) {
          const dt = moment().tz(timeZoneName).format("YYYY-MM-DD HH:mm");
          return moment(dt).diff(
            moment(stop.scheduled_end_date_time_with_tz),
            "seconds"
          );
        }
        return moment().diff(
          moment(stop.scheduled_end_date_time_with_tz),
          "seconds"
        );
      }
      if (
        !["PENDING", "UNALLOCATED"].includes(stop.status) &&
        !isEmpty(stop.actual_start_date_time_with_tz) &&
        !isEmpty(stop.scheduled_end_date_time_with_tz)
      ) {
        return moment(moment(stop.actual_start_date_time_with_tz)).diff(
          stop.scheduled_end_date_time_with_tz,
          "seconds"
        );
      }
    }
  }
  return 0;
};

export const findResonObject = (source = [], id, type) => {
  if (!isEmpty(source)) {
    const obj = _.find(source, { id, type });
    if (!isEmpty(obj)) {
      return obj;
    }
  }
  return "";
};

export const getAddressLatLng = (address, format = "latLngObj") => {
  if (
    !isEmpty(address) &&
    address.coordinates &&
    address.coordinates.length >= 2
  ) {
    const coordinates = [...address.coordinates];
    if (format === "latLngObj") {
      return { lat: coordinates[1], lng: coordinates[0] };
    } else {
      return new window.google.maps.LatLng(coordinates[1], coordinates[0]);
    }
  }
  return null;
};

export const stopObjectIds = ( stop = {}) => {
  return (_.compact([].concat(stop.customer_order_ids, stop.recovery_ids, stop.release_ids, stop.linehaul_ids)))
}

export const getStopObjectKeyName = ( stop = {}) => {
  switch (stop.location_type) {
    case "RC": return 'recovery_ids';
    case "LH": return 'linehaul_ids';
    case "RL": return 'release_ids';
    default: return 'customer_order_ids';
  }
}

 
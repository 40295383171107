import React, { useState, Fragment, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card } from '../../common/UIComponents'
import { Button } from 'antd'
import DispatcherNotesTable from './DispatcherNotesTable'
import I18n from '../../common/I18n'
import { OrgContext } from '../../context/OrgContext';
import PrivateNotesTable from './PrivateNotesTable'
import { checkServiceExistance } from '../../helpers/common'

function PrivateNotes(props) {
  const {
    organization_id,
    customer_order_notes,
    apiEndpoint,
    currentSourceRecord,
    noteTitle = '',
    refer_type,
    show_actions,
    show_title,
    scroll = {}
  } = props
  const [NewNoteInsertion, setNewNoteInsertion] = useState(false)
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const [isMilitaryTime, setIsMilitaryTime] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const handleCellEditing = (isEditing) => {
    setIsEditing(isEditing);
  };

  useEffect(() => {
    if (
      organizationSettings &&
      organizationSettings.is_military_time === 'true'
    ) {
      setIsMilitaryTime(true)
    } else {
      setIsMilitaryTime(false)
    }
  }, [organizationSettings])

  const renderNotes = () => (
    <PrivateNotesTable
      apiEndpoint={apiEndpoint}
      isMilitaryTime={isMilitaryTime}
      data={customer_order_notes}
      organization_id={organization_id}
      refer_id={currentSourceRecord.id}
      newNotesInsertion={NewNoteInsertion}
      toggleNewNotesInsertion={(val) => {
        setNewNoteInsertion(val)
      }}
      timeZone={currentSourceRecord.order_timeZoneId}
      timeZoneName={currentSourceRecord.order_tz_short_name}
      refer_type={refer_type}
      show_actions={show_actions}
      scroll={{ ...scroll }}
      onCellEditing={handleCellEditing}
    />
  )
  return (
    <Fragment>
      {show_title ? (
        <Card
          title={noteTitle || I18n.t('general.notes')}
          size="small"
          extra={
            <div className="alignRight">
              {show_actions && checkServiceExistance("SCA") ? (
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => {
                    setNewNoteInsertion(true)
                  }}
                  size="small"
                  disabled={isEditing}
                >
                  {I18n.t('invoices.add_remark')}
                </Button>
              ) : (
                <Fragment />
              )}
            </div>
          }
        >
          {renderNotes()}
        </Card>
      ) : (
        renderNotes()
      )}
    </Fragment>
  )
}

export default PrivateNotes

PrivateNotes.propTypes = {
  organization_id: PropTypes.number.isRequired,
  customer_order_notes: PropTypes.array.isRequired,
  currentSourceRecord: PropTypes.object.isRequired,
  apiEndpoint: PropTypes.object.isRequired,
  refer_type: PropTypes.string.isRequired,
  show_actions: PropTypes.bool.isRequired,
  show_title: PropTypes.bool.isRequired,
}
PrivateNotes.defaultProps = {
  organization_id: 0,
  customer_order_notes: [],
  currentSourceRecord: {},
  apiEndpoint: {},
  refer_type: 'order',
  show_actions: true,
  show_title: true,
}

import React, { useState, useCallback, useEffect, memo, Fragment } from 'react';
import { Row, Col, DatePicker, Typography, Button, Popconfirm, Select, Collapse } from 'antd';
import moment from 'moment';
import OutlinedButton from './OutlinedButton';
import AppointmentPicker from '../orders/AppointmentPicker';
import AppConfig from '../../config/AppConfig';
import I18n from '../../common/I18n';
import _ from 'lodash';
const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const SlotButton = memo(({ slot, selected, onClick, isMilitaryTime , isDisabled }) => {
  const handleClick = useCallback(() => {
    onClick(slot);
  }, [ onClick, slot ]);

  const toMilitaryFormart = (slot) => {
    const currentTimeFormat = isMilitaryTime ? 'HH:mm' : 'hh:mm A';
    const { start_time, end_time } = slot;
    const start = moment(start_time, 'HH:mm');
    const end = moment(end_time, 'HH:mm');
    return {
      start_time: start.format(currentTimeFormat),
      end_time: end.format(currentTimeFormat),
    };
  };

  const { start_time, end_time } = toMilitaryFormart(slot);

  return (
    <OutlinedButton
      isDisabled={ isDisabled }
      text={ `${start_time} - ${end_time}` }
      selected={ selected }
      onClick={ handleClick }
      style={ {
        borderRadius: 0, padding: "5px"
      } }
    />
  );
});


const SlotSelection = (props) => {
  const { slots, multiSelect, onChange, isMilitaryTime , disableSlots , hideAnySlot } = props
  // State variable to keep track of selected slots
  const [ selectedSlots, setSelectedSlots ] = useState([]);
  const [ selectedSlot, setSelectedSlot ] = useState('');

  const toMilitaryFormat = (slot) => {
    const currentTimeFormat = isMilitaryTime ? 'HH:mm' : 'hh:mm A';
    const { start_time, end_time } = slot;
    const start = moment(start_time, 'HH:mm');
    const end = moment(end_time, 'HH:mm');
    return {
      start_time: start.format(currentTimeFormat),
      end_time: end.format(currentTimeFormat),
    };
  };

  const handleSlotChange = (value) => {
    setSelectedSlot(value);
    if (onChange) {
      const selectedSlot = slots.find(slot => {
        const { start_time, end_time } = toMilitaryFormat(slot);
        return `${start_time}-${end_time}` === value;
      });
      onChange(selectedSlot);
    }
  };

  useEffect(() => {
    // find slots having selected as true and keep in format "12:00 PM-04:00 PM"
    const selectedSlot = slots.find(slot => slot.selected);
    if (selectedSlot) {
      const { start_time, end_time } = toMilitaryFormat(selectedSlot);
      setSelectedSlot(`${start_time}-${end_time}`);
    }else{
      setSelectedSlot('');
    }
  }, [ slots ]);

  // Callback function to handle slot selection
  const handleSlotClick = (slot) => {
    let updatedSelectedSlots;
    // Update selected slots based on multi-select or single-select mode
    if (multiSelect) {
      if (selectedSlots.includes(slot)) {
        updatedSelectedSlots = selectedSlots.filter((s) => s !== slot);
      } else {
        updatedSelectedSlots = [ ...selectedSlots, slot ];
      }
    } else {
      updatedSelectedSlots = _.map(selectedSlots, (s) => {
        return {
          ...s,
          selected: s.start_time === slot.start_time && s.end_time === slot.end_time,
        };
      });
    }
    // Update state and notify parent component of selection change
    setSelectedSlots(updatedSelectedSlots);
    if (onChange) {
      onChange(updatedSelectedSlots);
    }
  }

  const renderSlot = (slots) => {
    const filteredSlots = props.hideAnySlot
      ? slots.filter(slot => {
        return !(slot.start_time === '08:00' && slot.end_time === '20:00');
      })
      : slots;

    if (filteredSlots.length === 0) {
      return "No slots Available";
    }

    return (
      <>
        { filteredSlots.map((slot, i) => (
          <Col key={ i }>
            <SlotButton
              isDisabled={ disableSlots }
              isMilitaryTime={ isMilitaryTime }
              slot={ slot }
              selected={ selectedSlots.find((selectedSlot) => (
                selectedSlot.start_time === slot.start_time &&
                selectedSlot.end_time === slot.end_time
              ))?.selected }
              onClick={ handleSlotClick }
            />
          </Col>
        )) }
      </>
    );
  };

  const renderSlotOptions = () => {
    const filteredSlots = hideAnySlot
      ? slots.filter(slot => !(slot.start_time === '08:00' && slot.end_time === '20:00'))
      : slots;

    if (filteredSlots.length === 0) {
      return <Option key={ "no-slots" } disabled>No slots available</Option>;
    }

    return filteredSlots.map((slot, i) => {
      const { start_time, end_time } = toMilitaryFormat(slot);
      return (
        <Option key={ i } value={ `${start_time}-${end_time}` }>
          { `${start_time} - ${end_time}` }
        </Option>
      );
    });
  };

  return (
    <Row type="flex" align='middle'>
      {/* <Col {...props.adjustStyle["label"]}> */ }
      {/* <Col>
        <Text strong>Slot</Text>
      </Col> */}
      {/* <Col {...props.adjustStyle["wrapper"]}>
        <Row type="flex" gutter={ [ 4, 4 ] }>
          { renderSlot(slots) }
        </Row>
      </Col> */}
      <Col>
        <Select
          size='small'
          placeholder="Select a slot"
          value={ selectedSlot ? selectedSlot : undefined }
          onChange={ handleSlotChange }
          disabled={ disableSlots }
          style={ { width: 200 } }
        >
          { renderSlotOptions() }
        </Select>
      </Col>
    </Row>
  );
};
function Appointments (props) {
  const { slots = [], data, feasible_dates, hasDeliveryZipcodesConfig, itemOnChange, isNewAppointment, showClear = true, disableItems, isMobile = false, feasibleDow = "", 
    selectedTemplateId = "", onSave, source = "", hideAnySlot = isMobile, handleDateChange, size="default", selectedOrderRows=[], showSelectedOrder,  scheduleOption = 'BOTH' } = props;
  const disableItemsArray = disableItems ? disableItems.split(',') : [];
  const { preferences, showExpedite = true , showDatepicker = true, showTimeSlots = true, startHour,
    startMinute,
    endHour,
    endMinute,
    disabledStartHours,
    disabledEndHours,
  } = data;
  // State variables
  const [ customEnabled, setCustomEnabled ] = useState(false);
  const [ slotsWithSelection, setSlotsWithSelection ] = useState([]);
  const [ isExpedite, setIsExpedite ] = useState(false);
  const [ currentPreference, setCurrentPreference ] = useState(preferences[ 0 ] || {});
  const anySlot = [
    {
      "start_time": "08:00",
      "end_time": "20:00",
      "order_capacity": "1",
      "selected": false
    },
  ];

  const hasAnySlot = (slots = []) => slots.find((slot) => {
    return (
      slot.start_time === "08:00" &&
      slot.end_time === "20:00"
    );
  }
  );

  // useEffect(() => {
  //   if (!customEnabled) {
  //     props.itemOnChange(0, "start_time", null);
  //     props.itemOnChange(0, "end_time", null);
  //     setCurrentPreference({ ...currentPreference, start_time: null, end_time: null });
  //   }
  // }, [ customEnabled ]);

  useEffect(() => {
    if(preferences.length === 0){
      const resetSlots = slots.map((slot) => ({ ...slot, selected: false }));
      const updatedSlotsWithSelection = hasAnySlot(resetSlots) ? resetSlots : anySlot.concat(resetSlots);
      setSlotsWithSelection(updatedSlotsWithSelection);
      setCurrentPreference({});
      setCustomEnabled(false);
    }
  }, [preferences]);

  useEffect(() => {
    const updatedSlots = slots ? slots.map((slot) => ({ ...slot, selected: false })) : [];
    const updatedSlotsWithSelection = hasAnySlot(updatedSlots) ? updatedSlots : anySlot.concat(updatedSlots);
    const prevAppts = data.preferences[ 0 ];

    // Check if prevAppts has slots and if they are present in the current slots array
    const isPrevSlotsIncluded = prevAppts && prevAppts.start_time && prevAppts.end_time && slots.some(slot => {
      const startTime = moment.isMoment(prevAppts.start_time) ? moment(prevAppts.start_time).format('HH:mm') : prevAppts.start_time;
      const endTime = moment.isMoment(prevAppts.end_time) ? moment(prevAppts.end_time).format('HH:mm') : prevAppts.end_time;

      return (
        startTime === slot.start_time &&
        endTime === slot.end_time
      );
    });

    if (prevAppts && Object.keys(prevAppts).length > 0 && !isPrevSlotsIncluded) {
      setCustomEnabled(true);
      setCurrentPreference({
        ...prevAppts,
        slots: [ 'CUSTOM' ],
        start_time: moment.isMoment(prevAppts.start_time) ? prevAppts.start_time : moment(prevAppts.start_time, 'HH:mm'),
        end_time: moment.isMoment(prevAppts.end_time) ? prevAppts.end_time : moment(prevAppts.end_time, 'HH:mm'),
      });
      props.itemOnChange(0, "slots", prevAppts.slots ? prevAppts.slots : [ "CUSTOM" ]);
      props.itemOnChange(0, "start_time", prevAppts.start_time);
      props.itemOnChange(0, "end_time", prevAppts.end_time);
    } else {
      // const previousIsCustom = prevAppts.slots.includes("CUSTOM");
      if (!prevAppts) return;
      const previousIsCustom = Object.keys(prevAppts).length > 0 && prevAppts?.slots ? prevAppts.slots.includes("CUSTOM") : false;
      setCustomEnabled(previousIsCustom);
      setCurrentPreference({
        ...prevAppts,
        start_time: moment.isMoment(prevAppts.start_time) ? prevAppts.start_time : moment(prevAppts.start_time, 'HH:mm'),
        end_time: moment.isMoment(prevAppts.end_time) ? prevAppts.end_time : moment(prevAppts.end_time, 'HH:mm'),
      });
      props.itemOnChange(0, "slots", prevAppts.slots);
      props.itemOnChange(0, "start_time", prevAppts.start_time);
      props.itemOnChange(0, "end_time", prevAppts.end_time);
    }
    setSlotsWithSelection(updatedSlotsWithSelection);
  }, [ props.warehouse_code ]);


  useEffect(() => {
    if (preferences.length && isNewAppointment && !selectedTemplateId.length) return;
    if (preferences.length) {
      const { has_expedite } = preferences[ 0 ];
      const isCustom = Array.isArray(preferences[ 0 ].slots) && preferences[ 0 ].slots.includes('CUSTOM');
      const defaultSlots = slots ? slots.map((slot) => ({ ...slot, selected: false })) : [];
      const defaultSlotsWithSelection = hasAnySlot(defaultSlots) ? defaultSlots : anySlot.concat(defaultSlots);
      const selectedSlots = defaultSlotsWithSelection.map((slot) => {

        let isSelected = false;
        if (preferences[ 0 ].slots.includes('ANY')) {
          isSelected = slot.start_time === "08:00" && slot.end_time === "20:00";
        } else {
          isSelected = !isCustom && preferences[ 0 ].slots.some((s) => {
            const [ start, end ] = s.split('-');
            return slot.start_time === start && slot.end_time === end;
          });
        }
        return { ...slot, selected: isSelected };
        });

        setIsExpedite(has_expedite || false);
        setCustomEnabled(isCustom);
        // const updatedSlots = updateSelectedSlots(slotsWithSelection, selectedSlots);
        setSlotsWithSelection(selectedSlots);
        setCurrentPreference(preferences[ 0 ] || {});
      }
  }, [ selectedTemplateId ]);

  const handleSlotSelectionChange = (selectedSlots) => {
    setCustomEnabled(false);
    const selectedSlot = selectedSlots.start_time + '-' + selectedSlots.end_time;
    props.onSlotsChange(selectedSlot, false);
  };
  // Update slot selection when user clicks on a slot
  // const handleSlotSelectionChange = (selectedSlots) => {
  //   const updatedSlots = _.map(slotsWithSelection, (slot) => {
  //     // only if it matches the start and end time make it selected if its already not selected
  //     const selectedSlot = selectedSlots.find((s) => {
  //       return (
  //         s.start_time === slot.start_time &&
  //         s.end_time === slot.end_time
  //       );
  //     });
  //     return { ...slot, selected: selectedSlot?.selected };
  //   });
  //   setSlotsWithSelection(updatedSlots);
  //   setCustomEnabled(false);
  //   const onlyselectedSlots = updatedSlots.filter((slot) => slot.selected);
  //   props.onSlotsChange(onlyselectedSlots, false);
  // };

  // Update slot selection when user switches to custom selection
  const handleCustomSelectionChange = useCallback(() => {
    const updatedSlots = slotsWithSelection.map((slot) => ({ ...slot, selected: false }));
    setSlotsWithSelection(updatedSlots);
    setCustomEnabled(true);
    setCurrentPreference({
      ...currentPreference,
      slots: [ 'CUSTOM' ],
      start_time : null,
      end_time : null,
    });
    props.itemOnChange(0, "slots", [ "CUSTOM" ]);
    // props.switchToCustomSelection();
  }, [ slotsWithSelection ]);



  // Clear all selections
  const handleClearSelection = () => {
    props.clearPreference(0);
    setSlotsWithSelection(slotsWithSelection.map((slot) => ({ ...slot, selected: false })));
    setCustomEnabled(false);
    setIsExpedite(false);
    setCurrentPreference({});
  };

  const handleCustomTimeChange = (index, element, value) => {
    // handleCustomSelectionChange();
    setCurrentPreference({ ...currentPreference, [ element ]: value });
    props.itemOnChange(index, element, value);
  }

  function isDateDisabled (current, hasDeliveryZipcodesConfig, isMobile, feasibleDow) {
    // if /orders
    if (source === "ORDER_FORM") {
      return (
        current &&
        current <=
        moment(
          moment().add(AppConfig.orderFieldDuration - 24, "hours"),
          "YYYY-MM-DD"
        )
      );
    }
    else if (hasDeliveryZipcodesConfig) {
      if (isMobile) {
        return (
          current &&
          (current <= moment() ||
            current > moment(moment().add(31, "days"), "YYYY-MM-DD") ||
            !feasibleDow.includes(moment(current).format("dddd")))
        );
      } else {
        return (
          current &&
          (current <=
            moment(
              moment().add(AppConfig.orderFieldDuration - 24, "hours"),
              "YYYY-MM-DD"
            ) ||
            current > moment(moment().add(31, "days"), "YYYY-MM-DD") ||
            !feasibleDow.includes(moment(current).format("dddd")))
        );
      }
    } else {
      if (isMobile) {
        return current && current <= moment();
      } else {
        return current && current <=
          moment(
            moment().add(
              AppConfig.orderFieldDuration - 24,
              "hours"
            ),
            "YYYY-MM-DD"
          );
      }
    }
  }

  const customPickerProps = {
    data: currentPreference,
    endHour,
    endMinute,
    startHour,
    startMinute,
    disabledStartHours,
    disabledEndHours,
    itemChange: handleCustomTimeChange,
    dataIndex: 0,
    isMilitaryTime: props.isMilitaryTime,
    isDisabled : disableItemsArray.includes("custom")
  };

  const styleProps = {
    label: {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 6,
      xl: 4,
      xxl: 2
    },
    wrapper: {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 18,
      xl: 20,
      xxl: 22
    }
  };


  return (
    <Row type='flex' align='middle' gutter={ [ 16, 16 ] }>
     
        { showDatepicker && (
           <Col>
          <Row type="flex" align='middle' gutter={ 8 }>
            <Col >
              <Text strong>Date:</Text>
            </Col>
            <Col>
              <DatePicker
                disabled={disableItemsArray.includes("appt_date")}
                size="small"
                style={ { width: "150", marginLeft: "1%" } }
                placeholder="Select Date"
                format="Do MMM YYYY"
                onChange={ (e) =>
                {
                  if(handleDateChange && e){
                    handleDateChange(0, "appt_date", e)
                  }else{
                    itemOnChange(0, "appt_date", e);
                    setCurrentPreference({ ...currentPreference, appt_date: e });
                  }
                }
                }
                value={
                  _.has(preferences[ 0 ], "appt_date") &&
                  moment(preferences[ 0 ].appt_date).isValid() ? preferences[ 0 ].appt_date : null
                }
                dateRender={ (current) => {
                  if(isMobile){
                  return (
                    <div
                      className="ant-calendar-date fontSize12"
                    >
                      { current.date() }
                    </div>
                  );
                  }
                  const style = {};
                  const pickerDate = current.format("YYYY-MM-DD");
                  if (
                    hasDeliveryZipcodesConfig &&
                    feasible_dates.includes(pickerDate)
                  ) {
                    style.border = "2px solid green";
                    style.borderRadius = "50%";
                    style.fontSize = "11px";
                  }
                  return (
                    <div
                      className="ant-calendar-date fontSize12"
                      style={ style }
                    >
                      { current.date() }
                    </div>
                  );
                } }
                defaultValue={ null }
                onOk={ () => { } }
                disabledDate={ (current) => isDateDisabled(current, hasDeliveryZipcodesConfig, isMobile, feasibleDow) }

              />
            </Col>
          </Row>
          </Col>
          ) }
     
      {showTimeSlots &&
      <Col>
        <Row type="flex" align='middle' gutter={ 8 }>
          <Col>
            <Text strong>Slot:</Text>
          </Col>
          <Col>
            <SlotSelection
              disableSlots={ disableItemsArray.includes("slots") }
              slots={ slotsWithSelection }
              multiSelect={ false }
              onChange={ handleSlotSelectionChange }
              isMilitaryTime={ props.isMilitaryTime }
              // adjustStyle={ styleProps }
              hideAnySlot={ hideAnySlot }
            />
          </Col>
          { showExpedite && (
            <Col>
              <OutlinedButton
                isDisabled={ disableItemsArray.includes("expedite") }
                text="Expedite"
                selected={ isExpedite }
                onClick={ () => {
                  const prevExpedite = isExpedite;
                  setIsExpedite(!prevExpedite);
                  itemOnChange(0, "has_expedite", !prevExpedite);
                } }
                icon={ "check" }
                size={size}
              />
            </Col>
          ) }

        </Row>
      </Col>}

      { !isMobile && (
        customEnabled && showTimeSlots ? (
      <Col span={ 24 }>
          <Row type="flex" align='middle' gutter={ 8 }>
                  <Col>
              <Row type='flex' gutter={ 4 }>
                      <Col>
                  <Text strong>Start Time:</Text>
                      </Col>
                      <Col className={props?.size === 'small' ? 'order_details_form' : ''}>
                      <AppointmentPicker
                        { ...customPickerProps }
                        property="start_time"
                        size={size}
                      />
                      </Col>
                    </Row>
                  </Col>
                <Col>
              <Row type='flex' gutter={ 4 }>
                    <Col>
                      <label>End Time:</label>
                    </Col>
                    <Col className={props?.size === 'small' ? 'order_details_form' : ''}>
                      <AppointmentPicker
                        { ...customPickerProps }
                        property="end_time"
                        size={size}
                      />
                    </Col>
                  </Row>
                </Col>
              <Col >
            </Col>
            </Row>
          </Col>
        ) : showTimeSlots  ? (
          <Col span={ 24 }>
            <OutlinedButton
              isDisabled={ disableItemsArray.includes("custom") }
              text="Choose Custom Slot"
              onClick={ handleCustomSelectionChange }
              icon={ "plus" }
              size={ size }
            />
          </Col>
          ) : <Fragment />
      ) }

      {showSelectedOrder && (<Col span={ 24 } className='selecetdOrderPanel'>
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            className="marginTop10"
          >
            <Panel
              header="Selected Orders"
              key="1"
              style={{
                marginTop: "10px",
              }}
            >
              <p style={{ maxHeight: 70, overflowY: "auto" }}>
                {selectedOrderRows
                  .map((order) => order.customer_order_number)
                  .join(", ")}
              </p>
            </Panel>
          </Collapse>
      </Col>)}

      <Col span={ 24 }>
        <Row type='flex' gutter={ 4 } align='middle'>
          { showClear && !isMobile && (
            <Col>
              
                <Button 
                 onClick={ () => handleClearSelection() } type="danger" icon="close">
                  Clear
                </Button>
             
            </Col>
          ) }
          { onSave && (
            <Col>
              <Button type="primary" onClick={ () => onSave() } icon="save">
                Save
              </Button>
            </Col>
          ) }
        </Row>
      </Col>
    </Row>
  );
}

export default Appointments;

import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function fetchWHZones(id = '') {
  const url = ApiUrl(`v2/delivery_zones?id=${id}&operation_code=DZI`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { zones: 'delivery_zones' },
  });
}

export function fetchAllDeliveryZones(shipWhCheck = false) {
  let url = ApiUrl('v2/delivery_zones/get_org_delivery_zones?operation_code=DZGODZ');
  if(shipWhCheck){
    url= `${url}&skip_wh_filter=${shipWhCheck}`
  }
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { zones: 'delivery_zones' },
  });
}

export function saveWHZones(data, isNew = true) {
  data.operation_code= isNew ? 'DZC' : 'DZU';
  const url = isNew ? ApiUrl('v2/delivery_zones') : ApiUrl(`v2/delivery_zones/${data.id}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, data, ParseGeneralResponse, {});
}

export function deleteWHZone(data = {}) {
  const url = ApiUrl(`v2/delivery_zones/${data.id}`);
  return Call('delete', url, data, ParseGeneralResponse, {operation_code: 'DZW'});
}

import React, { useState, Fragment } from 'react';
import { Tooltip, Icon,message } from 'antd';
import AppConfig from '../../config/AppConfig';

const Copyable = ({ text, tooltipPosition = 'right', tooltipText = 'Copy', color = '#1890ff', showTooltip = true}) => {
  const [ copied, setCopied ] = useState(false);

  const copyText = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (shouldAllowCopy(text)) {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      setCopied(true);
      message.success('Copied Successfully.!!');
      setTimeout(() => setCopied(false), 1000);
    }
  };

  const shouldAllowCopy = (text) => {
    return text && text !== "NA" && text !== null && text !== "";
  };

  if (!shouldAllowCopy(text)) {
    return null;
  }

  return (
    <Fragment>
      <Icon type={ copied ? "check" : "copy" } onClick={(e) => copyText(e) } style={ { cursor: 'pointer', color: color} } />
    </Fragment>
  );
};

export default Copyable;

import AppConfig from "../config/AppConfig";
import {
  Call,
  ApiUrl,
  ParseGeneralResponse,
  GenerateURL,
} from "./ApiUtils";

export const AuthenticationService = {
  login: (username, password) => {
    const url = ApiUrl(`authentication/user_access_token`);
    const data = {
      client_id: AppConfig.clientId,
      client_secret: AppConfig.clientSecret,
      username,
      password,
    };
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  currentUser: () => {
    const url = ApiUrl(`v1/users/me`);
    return Call("get", url, null, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  }
  ,refreshToken: (refresh_token) => {
    const endpoint = GenerateURL('oauth/token');
    const data = {
      client_id: AppConfig.clientId,
      client_secret: AppConfig.clientSecret,
      grant_type: "refresh_token",
      refresh_token
    };
    return Call("post", endpoint, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  }
  ,forgotPassword: (email) => {
    const endpoint = GenerateURL('api/authentication/forgot_password');
    const data = {
      email
    };
    return Call("post", endpoint, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  changePassword : (data) => {
    const {token, password, password_confirmation} = data;
    const endpoint = GenerateURL('api/authentication/reset_password');

    const body = {
      reset_password_token : token,
      password,
      password_confirmation
    }
    return Call("patch", endpoint, body, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  validateOtp : (mobile_number, otp) =>{
    const url = ApiUrl(`authentication/verify_otp_forgot_password`);
    const data = {
      client_id: AppConfig.clientId,
      client_secret: AppConfig.clientSecret,
      mobile_number,
      otp,
    };
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "",resetToken: "reset_password_token" }
    });
  },
  sendOtp : (mobile_number)=>{
    const url = ApiUrl(`authentication/send_verification_code_web`);
    const data = {
      client_id: AppConfig.clientId,
      client_secret: AppConfig.clientSecret,
      mobile_number
    };
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { sms_msg: "otp" },
    });
  }
};
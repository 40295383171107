import objectToFormData from "object-to-formdata";
import {
  Call,
  ApiUrl,
  GenerateURL,
  ParseGeneralResponse,
} from "./ApiUtils";

export function saveAppointments(data) {
  data.operation_code = "SCA";
  const formData = objectToFormData(data);
  const url = GenerateURL("tracking/save_customer_appointments");
  const method = "put";
  return Call(method, url, formData, ParseGeneralResponse, {
    responseDataKeys: { orders: "tracking" },
  });
}

export function saveCustomerData(id, data) {
  data.operation_code = 'TRCSCD';
  const formData = objectToFormData(data);
  const url = GenerateURL(
    `tracking/save_consignee_details?customer_order_id=${id}`
  );
  const method = "put";
  return Call(method, url, formData, ParseGeneralResponse, {
    responseDataKeys: { settings: "" },
  });
}

export function fetchSettings() {
  const url = ApiUrl("v2/app_settings");
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { settings: "app_settings" },
  });
}

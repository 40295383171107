import React, { useContext, useEffect, useState } from "react";
import DateTimeSelector from "../../common/DateTimeSelector";
import { Button, Col, Form, Popconfirm, Row, Select, Spin } from "antd";
import I18n from "../../common/I18n";
import { OrgContext } from "../../context/OrgContext";
import { fetchAccountShortLos } from "../../api/Los";
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";
import userStore from "../../stores/UserStore";
import moment from "moment";
import { LineHaulApi } from "../../api/LineHaulApi";
import { alertMessage, isEmpty } from "../../common/Common";
import { UserContext } from "../../context/UserContext";
import AppConfig from "../../config/AppConfig";
import { momentTime } from "../../helpers/date_functions";
import { renderAlertMessage } from "../../helpers/common";

const LhCreation = (props) => {
  const { orderId, accounts, warehouses, refreshAccounts,accountInfo } = props;
  const currentUser = useContext(UserContext);
  const { currentOrg } = currentUser;
  const defaultFormdata = {
    warehouse_code: "",
    wh_location_id: "",
    account_code: "",
    account_id: "",
    level_of_service: "",
    pickup_location: "",
    drop_location: "",
    drop_date: null,
    drop_time: null,
  };
  const [formData, setFormData] = useState({
    ...defaultFormdata,
  });
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const isMilitaryTime = organizationSettings.is_military_time === "true";
  const [levelOfServices, setLevelOfServices] = useState([]);
  const [existingLinehaulId, setExistingLinehaulId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getLos = (accountCode) => {
    const { account_code } = formData;
    const accountIndex = _.findIndex(accounts, {
      code: account_code,
    });
    if (accountIndex >= 0) {
      const account = accounts[accountIndex];
      if (!isEmpty(account.primary_account_id)) {
        accountCode = getValueFromArrayOfObjects(
          accounts,
          "id",
          account.primary_account_id,
          "code"
        );
      }
    }
    if (!isEmpty(accountCode)) {
      setIsLoading(true);
      fetchAccountShortLos(accountCode).then((result) => {
        if (result.success) {
          const levelOfServices = result.account.account_los || [];
          setLevelOfServices(levelOfServices);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  useEffect(() => {
    if (!_.isEmpty(formData.account_code)) {
      let account = {};
      const accountIndex = _.findIndex(accounts, {
        code: formData.account_code,
      });
      if (accountIndex >= 0) {
        account = accounts[accountIndex];
      }
      if (Object.keys(account).length > 0) {
        getLos(formData.account_code);
      }
    } else {
      setLevelOfServices([]);
    }
  }, [formData.account_code]);

  // Call account API when warehouse code changes
  useEffect(() => {
    if (!_.isEmpty(formData.warehouse_code)) {
      // Find the ID of the selected warehouse
      const whRecord = warehouses.find(
        (wh) => wh.id === formData.warehouse_code
      );
      const whId = whRecord?.id ? whRecord.id : "";

      // Call the API to refresh the accounts
      refreshAccounts(whId);

      // Clear account info from the quote form
      setFormData({
        ...formData,
        account_code: "",
        account_id: "",
        wh_location_id: whId,
        level_of_service: "",
      });
    }
  }, [formData.warehouse_code]);

  // Update account ID when account code changes
  useEffect(() => {
    const newFormData = { ...formData };
    if (!_.isEmpty(formData.account_code)) {
      const accountIndex = _.findIndex(accounts, {
        code: formData.account_code,
      });
      if (accountIndex >= 0) {
        const account = accounts[accountIndex];
        newFormData.account_id = account.id;
      }
    } else {
      newFormData.account_id = "";
    }

    setFormData(newFormData);
  }, [formData.account_code, accounts]);

  useEffect(() => {
    const setFormValues = (linehaul) => {
      const { warehouse_id, account_code, pickup_location_id, drop_location_id, appointment_date, timeZoneId = "America/New_York" } = linehaul;
      const appointmentDate = momentTime('', appointment_date, timeZoneId)//moment(appointment_date);
      const appointmentTime = momentTime('', appointment_date, timeZoneId)//moment.utc(appointment_date);
      const defaultFormdata = {
        warehouse_code: warehouse_id,
        account_code: account_code,
        pickup_location: pickup_location_id,
        drop_location: drop_location_id,
        drop_date: appointmentDate,
        drop_time: appointmentTime,
      };
      setFormData(defaultFormdata);
      props.form.setFieldsValue(defaultFormdata);
    };

    const fetchLinehaul = () => {
      if (orderId) {
        const payload = { organization_id: currentOrg.id };
        LineHaulApi.fetchOne(orderId, 1, null, null, payload)
          .then((response) => {
            if (response.linehaulstops.length === 0) {
              setExistingLinehaulId(null);
              setFormData({});
              props.form.setFieldsValue({ warehouse_code: currentWh?.id, pickup_location: currentWh?.id, account_code: accountInfo || '' });
            } else {
              const linehaul = response.linehaulstops[ response.linehaulstops.length - 1 ];
              setExistingLinehaulId(linehaul.id);
              setFormValues(linehaul);
            }
          })
          .catch((error) => {
            console.error("failed to retrieve linehaul", error);
            // handle error response
          });
      }
    };

    fetchLinehaul();
  }, [ orderId ]);


  const handleOnChange = (element, value) => {
    const updatedFormData = { ...formData, [element]: value };

    const { setFieldsValue } = props.form;
    if (element === "warehouse_code") {
      setFieldsValue({ account_code: "", level_of_service: "" });
    } else if (element === "account_code") {
      setFieldsValue({ level_of_service: "" });
    } else if (element === "drop_date") {
      if (!moment.isMoment(updatedFormData.drop_time))
        updatedFormData.drop_time = moment();
    } else if (element === "drop_time") {
      if (!moment.isMoment(updatedFormData.drop_date))
        updatedFormData.drop_date = moment();
    }

    setFormData(updatedFormData);
  };

  const { getFieldDecorator, validateFields, resetFields , getFieldValue } = props.form;
  const currentWh =
    typeof props.warehouseFilter.selectedWarehouses === "string"
      ? _.find(props.warehouseFilter.warehouses, {
          id: props.warehouseFilter.selectedWarehouses,
        })
      : _.find(props.warehouseFilter.warehouses, {
          id: props.warehouseFilter.selectedWarehouses[0],
        });

  const clearClick = () => {
    resetFields();
    setFormData({});
  };

  const validateForm = () => {
    validateFields((err, values) => {
      if (!err) {
        // if pickup and drop location are the same
        if (values.pickup_location === values.drop_location) {
          alertMessage(
            "Pickup and delivery location cannot be the same",
            "error",
            10
          );
          return;
        }
        const currentAcc = accounts.find((i) => i.code === values.account_code);
        // const currentlos = levelOfServices.find(
        //   (i) => i.code === values.level_of_service
        // );

        const payload = {
          organization_id: userStore.getStateValue("selectedOrg"),
          customer_order_id: orderId,
          warehouse_id: values.warehouse_code,
          account_code: values.account_code,
          account_id: currentAcc.id,
          account_name: currentAcc.name,
          // los_id: currentlos.id,
          // los_name: currentlos.name,
          // los_code: currentlos.code,
          pickup_location_id: values.pickup_location,
          drop_location_id: values.drop_location,
          appointment_date: formData.drop_date &&
            moment(formData.drop_date).format("YYYY-MM-DD") +
            " " +
            moment(formData.drop_time).format("HH:mm"),
        };

        // Check if updating an existing linehaul
        if (existingLinehaulId) {
          setIsLoading(true);
          LineHaulApi.update(existingLinehaulId, payload)
            .then((response) => {
              alertMessage("linehaul updated  successfully");
              // handle success response
            })
            .catch((error) => {
              console.error("failed to update linehaul", error);
              // handle error response
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          // creating a new linehaul
          setIsLoading(true);
          LineHaulApi.create(payload)
            .then((response) => {
              alertMessage("linehaul created successfully");
              setExistingLinehaulId(response.linehaul.id);
              // Retrieve the newly created LineHaul
              LineHaulApi.get(response.linehaul.id)
                .then((linehaul) => {
                  // handle success response
                })
                .catch((error) => {
                  console.error("failed to retrieve linehaul", error);
                  // handle error response
                });
            })
            .catch((error) => {
              console.error("failed to create linehaul", error);
              // handle error response
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    });
  };

  return (
    <div className="padding10">
      <Row gutter={16} className="quote-order-consignee-details">
        <h3
          style={{ fontWeight: "initial !important" }}
          className="marginLeft20"
        >
          {"Location 1"}
        </h3>
      </Row>
      <div className="ant-collapse ant-collapse-icon-position-right marginBottom10 marginLeft10 padding10 customCollapse">
        <Spin spinning={isLoading}>
          <Form className="linehaul-creation-form-wrapper">
            <Row gutter={16} type="flex" align="middle">
              <Col sm={24} xs={24} md={12} lg={6}>
                <Form.Item label={I18n.t("location.location")}>
                  {getFieldDecorator("warehouse_code", {
                    initialValue: currentWh ? currentWh.id : undefined,
                    rules: [
                      {
                        required: true,
                        message: I18n.t("errors.invalid_field", {
                          field: I18n.t("general.value"),
                        }),
                      },
                    ],
                  })(
                    <Select
                      onChange={(value) => {
                        handleOnChange("warehouse_code", value);
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {warehouses.map((warehouse) => (
                        <Select.Option key={warehouse.id} value={warehouse.id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12} lg={6}>
                <Form.Item label={I18n.t("general.account")}>
                  {getFieldDecorator("account_code", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: I18n.t("account.account_required", {
                          field: I18n.t("general.value"),
                        }),
                      },
                    ],
                  })(
                    <Select
                      onChange={(value) => {
                        handleOnChange("account_code", value);
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option key="account_code" value="">
                        -- Select --
                      </Select.Option>
                      {accounts.map((account) => (
                        <Select.Option key={account.code} value={account.code}>
                          {`${account.name} (${account.code})`}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {/* <Col sm={24} xs={24} md={12} lg={6}>
                <Form.Item label={I18n.t("los.label")}>
                  {getFieldDecorator("level_of_service", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: I18n.t("errors.los_required", {
                          field: I18n.t("general.value"),
                        }),
                      },
                    ],
                  })(
                    <Select
                      onChange={(value) => {
                        handleOnChange("level_of_service", value);
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option key="level_of_service" value="">
                        -- Select --
                      </Select.Option>
                      {levelOfServices.map((los) => (
                        <Select.Option key={los.code} value={los.code}>
                          {los.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col> */}
              <Col sm={24} xs={24} md={12} lg={6}>
                <Form.Item label={ `${I18n.t("general.origin")}`}>
                  {getFieldDecorator("pickup_location", {
                    initialValue: "",

                    rules: [
                      {
                        required: true,
                        message: I18n.t("errors.pickup_location", {
                          field: I18n.t("general.value"),
                        }),
                      },
                    ],
                  })(
                    <Select
                      value={formData.pickup_location || ""}
                      showSearch
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        handleOnChange("pickup_location", value)
                      }
                    >
                      <Select.Option key="pickup_location" value="">
                        -- Select --
                      </Select.Option>
                      {warehouses.map((warehouse) => (
                        <Select.Option key={warehouse.id} value={warehouse.id} disabled={warehouse.id === getFieldValue('drop_location')}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12} lg={6}>
                <Form.Item label={ `${I18n.t("general.destination")}`}>
                  {getFieldDecorator("drop_location", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: I18n.t("errors.drop_location", {
                          field: I18n.t("general.value"),
                        }),
                      },
                    ],
                  })(
                    <Select
                      value={formData.drop_location || ""}
                      showSearch
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        handleOnChange("drop_location", value)
                      }
                    >
                      <Select.Option key="drop_location" value="">
                        -- Select --
                      </Select.Option>
                      {warehouses.map((warehouse) => (
                        <Select.Option key={warehouse.id} value={warehouse.id} disabled={warehouse.id === getFieldValue('pickup_location')}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12} lg={6}>
                <DateTimeSelector
                  dateProps={{
                    label: "Scheduled Date",
                    format: "Do MMM YYYY",
                    value: formData.drop_date,
                    onChange: (date) => {
                      // When current date is selected
                      handleOnChange("drop_date", date);
                    },
                    style: { width: "100%", height: "100%" },
                    disabledDate: (current) =>
                      current && current <= moment().startOf("day"),
                  }}
                  timeProps={{
                    label: "Scheduled Time",
                    format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                    showTime: {
                      format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                      use12Hours: !isMilitaryTime,
                    },
                    isMilitaryTime,
                    onChange: (time) => {
                      handleOnChange("drop_time", time);
                    },
                    value: formData.drop_time,
                    style: { width: "100%", height: "100%", marginTop: "5px" },
                    minuteStep: 1,
                    className: "formMaterialTimePicker",
                  }}
                  gridStyle={{
                    row: { type: "flex", gutter: 2 },
                    dateCol: { span: 12 },
                    timeCol: { span: 12 },
                  }}
                />
              </Col>
            </Row>
            <Row className="marginTop10">
              <Col xs={24} className="alignCenter">
                {
                  <Popconfirm
                    placement="topRight"
                    title={I18n.t("messages.clear_confirm")}
                    onConfirm={() => clearClick()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="danger"
                      className="marginRight5"
                      icon="delete"
                    >
                      {I18n.t("general.clear")}
                    </Button>
                  </Popconfirm>
                }
                <Button
                  type="primary"
                  onClick={() => validateForm()}
                  icon="save"
                >
                  {existingLinehaulId
                    ? I18n.t("general.update")
                    : I18n.t("general.save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  );
};
const LhCreateForm = Form.create({ name: "linehaul_form" })(LhCreation);
export { LhCreateForm };

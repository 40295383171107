/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import AddressInfo from '../common/AddressInfo';
import { Col, Row, Icon } from '../../common/UIComponents';
import { doFormate } from '../../common/Common';
import warehouse from '../../../assets/images/warehouse.png';
import { formatUSPhoneNumber } from '../../helpers/common';

const WarehouseDetails = ({ data, showImage , history }) => {
  const whName = doFormate(data.name);
  const whPhone = doFormate(data.phone);
  const whEmail = doFormate(data.email);
  const whLocationCode = doFormate(data.location_code);
  return (
    <Row style={{ minHeight: 100 }}>
      { showImage !== false &&
      <Col span={8}>
        <div className="paddingTop10 alignCenter">
          <img src={`${warehouse}`} style={{ width: 70 }} alt="WH" />
        </div>
      </Col>
      }
      <Col span={showImage ? 16 : 24}>
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="bank" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}>
            <div className="info-text textBold">{whName}</div>
          </Col>
        </Row>
        {whLocationCode !== 'NA' &&
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="tag" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}><div className="info-text" >{whLocationCode}</div></Col>
        </Row>
        }
        {whEmail !== 'NA' &&
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="mail" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}> <div className="info-text">{whEmail}</div></Col>
        </Row>
        }
        {whPhone !== 'NA' &&
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="phone" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}><div className="info-text">{formatUSPhoneNumber(whPhone)}</div></Col>
        </Row>
        }
        {data.l_address && Object.keys(data.l_address).length > 0 &&
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}><Icon type="environment" /></Col>
          <Col sm={22} xs={22} md={22} lg={22}>
            <div className="info-text alignLeft">
              <AddressInfo address={data.l_address} />
            </div>
          </Col>
        </Row>
        }
      </Col>
    </Row>
  );
};
export default WarehouseDetails;

WarehouseDetails.propTypes = {
  data: PropTypes.shape().isRequired,
  showImage: PropTypes.bool,
};
WarehouseDetails.defaultProps = {
  showImage: true,
};


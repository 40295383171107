
module.exports = {
  getParamId(props) {
    return props.match.params.id;
  },
  getParam(props, param) {
    return props.match.params[param];
  },
  loadGoogleMaps(callback) {
    const existingScript = document.getElementById('googleMaps');
   
    if (!existingScript) {
      const script = document.createElement('script');
      //  local api key: AIzaSyDurZQBXjtSzKeieXwtFeGe-jhZu-HEGQU
      const hostname = window.location.hostname;
      const isProdEnv = ['app.fleetenable.com', 'app1.fleetenable.com'].includes(hostname) ? true : false;
      script.src =
      `https://maps.googleapis.com/maps/api/js?key=${isProdEnv ? "AIzaSyCsZapWPw5VPmBWRAIz1M3SOl3HBL4W4t4" : "AIzaSyDrG4BOLf0QFidqUioPyurHLnFG1bSV69o"}&libraries=places`;
      script.id = 'googleMaps';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  },
  sortItem(a, b, field) {
    const field1 = a[field].toUpperCase(); // ignore upper and lowercase
    const field2 = b[field].toUpperCase(); // ignore upper and lowercase
    if (field1 < field2) {
      return -1;
    }
    if (field1 > field2) {
      return 1;
    }
    return 0;
  },
};

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BaseListView from '../BaseListView';

const Remarks = ({ data }) => {
  if (data) {
    return (
      <div>

        <BaseListView
          size="small"
          bordered
          list={data.map(remarks => ({ title: remarks.remark, description: moment(remarks.remark.created_at).format('MMM Do YYYY hh:ss A') }))}
          showAvatar={false}
          showDescription={false}
        />
      </div>
    );
  }
  return null;
};

export default Remarks;

Remarks.propTypes = {
  data: PropTypes.shape().isRequired,
  displayType: PropTypes.string,
  showIcon: PropTypes.bool,
};

Remarks.defaultProps = {
  displayType: 'line',
  showIcon: false,
};


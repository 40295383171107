import React from "react";
import { Icon, Tooltip } from "../../common/UIComponents";
import labelInfo from "../../../assets/images/labelInfo.svg";

const LabelInfo = ({ title, style, getPopupContainer = (triggerNode) => triggerNode.parentNode }) => {
  return (
    <Tooltip
      placement="rightTop"
      title={title}
      overlayStyle={{
        width: "200px",
      }}
      trigger="click"
      autoAdjustOverflow={true}
      getPopupContainer={getPopupContainer} // Default: parentNode, overrideable with custom prop
    >
      {/* <Icon
        type="info-circle"
        style={{
          fontSize: "13px",
          color: "#08c",
          margin: 13,
          marginLeft: 4,
          ...style,
        }}
        theme="filled"
      /> */}

      <img src={labelInfo} alt="labelInfo" className="labelInfoImage" />
    </Tooltip>
  );
};

export default LabelInfo;

import React from "react";
import BaseList from "../BaseList";
import { momentTimeWithShortName } from "../../helpers/date_functions";
import _ from "lodash";
import I18n from "../../common/I18n";

class NotificationList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }
    setColumns = (props) => {
    const { isMilitaryTime } = props;
    const displayFormat = isMilitaryTime ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY hh:mm A';
    this.columns = [
      {
        title: I18n.t("general.table_s_no"),
        key: "index",
        render: (text, record, index) => index + 1,
      },
      {
        title: I18n.t("notifications.logs.mobile"),
        key: "notification_sent_to",
        dataIndex: "send_to",
      },
      {
        title: I18n.t("notifications.logs.notify_type"),
        key: "notification_type",
        dataIndex: "notification_type",
        render: (text, record, index) => <div className="textUpperCase">{text}</div>
      },
      {
        title: I18n.t("notifications.logs.sent_to"),
        key: "notification_for",
        dataIndex: "notification_for",
      },
      {
        title: I18n.t("notifications.logs.event"),
        key: "notification_event",
        dataIndex: "notification_event",
        render: (text, record, index) => <div className="textCaptilize">{text}</div>
      },
      {
        title: I18n.t("general.status"),
        key: "notification_delivery_status",
        dataIndex: "notification_delivery_status",
        className: 'textCaptilize',
      },
      {
        title: I18n.t("notifications.logs.sent_on"),
        key: "created_at",
        dataIndex: "created_at",
        width: 175,
        render: (data) =>
          data ? (
            <div>
              {momentTimeWithShortName(
                displayFormat,
                data,
                this.props.timeZone,
                this.props.timeZoneName
              )}
            </div>
          ) : (
            "NA"
          ),
      },
    ];
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }
}
export default NotificationList;

import React, { Fragment, useEffect, useState } from "react";
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import {
  Button,
  Icon
} from "../../../common/UIComponents";
import {
  alertMessage,
} from '../../../common/Common';
import BaseModal from "../../BaseModal";

import _, { isEmpty } from "lodash";
import { fetchContactGroup } from "../../../api/ContactGroup";
import userStore from "../../../stores/UserStore";
import Form from "../../configurations/Contacts/Form";
import { fetchContacts } from "../../../api/Contacts";
import AppConfig from "../../../config/AppConfig";
import I18n from "../../../common/I18n";
function AddContactByButton({
  isOrgLevel,
  size,
  accountId,
  saveOn = () => {},
}) {
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [loading, setLoading] = useState(false);


  const handleAdd = () => {
    console.log("level_id::::",accountId);
    // setSelectedContact({});
    setShowContactForm(true);
    // getContactGroups();
  };

  const onEditCancel = () => {
    setSelectedContact(null);
    setShowContactForm(false);
  };

  const onEditCompleted = (message, contact = null) => {
    alertMessage(message);
    setShowContactForm(false);
    saveOn(contact);
  };

  const renderNewContactForm = () => {
    const isNew = selectedContact ? Object.keys(selectedContact).length === 0 : true;
    console.log("selectedContact::::::::::",selectedContact);
    return (
      <BaseModal
        title={
          isNew
            ? I18n.t("configurations.contact.add")
            : I18n.t("configurations.contact.edit_contact")
        }
        onCancel={onEditCancel}
        width="60%"
        style={{ top: 20 }}
      >
        <Form
          onCancel={onEditCancel}
          onSave={onEditCompleted}
        //   initialValues={selectedContact}
        //   isOrgLevel={isOrgLevel}
          accountId={accountId}
        />
      </BaseModal>
    );
  };

  return (
    <Fragment>
    {<Button type="primary" size={size ? size : 'small'} onClick={handleAdd} disabled={!accountId} icon="plus">Add New</Button>}
      {showContactForm && renderNewContactForm()}
    </Fragment>
  );
}

export default AddContactByButton;
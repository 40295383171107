import React, { Fragment } from 'react';
import { Icon } from '../../common/UIComponents';

const Errors = (errors, showIcon = true) => (
  <div>
    { errors.map(err => (
      <div key={err} className="textRed">
        {showIcon === true ? <Fragment><Icon type="warning" />&nbsp;&nbsp;</Fragment> : <Fragment />} {err}
      </div>
    ))}
  </div>
);

const FormattedErrors = (errors, showIcon = true) => {
  const formErrors = errors || [];
  if (formErrors.length < 1) return null;
  return (
    Errors(formErrors, showIcon)
  );
};

export default FormattedErrors;

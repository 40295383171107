import React, { Fragment, useState, useEffect, useMemo } from "react";
import moment from "moment";
import _ from "lodash"; // Make sure you have lodash installed
import { LightOnIcon } from "../../common/CustomIcon";

const QuickDispatchapptError = React.memo((props) => {
  const [errorOrders, setErrorOrders] = useState([]);
  const { orders, selectedDate, showApptErrors, setShowApptErrors } = props;

  // Memoize the calculateErrorOrders function
  const calculateErrorOrders = useMemo(() => {
    const givenDate = moment(selectedDate).format("YYYY-MM-DD");
    const result = _.filter(orders, (order) => {
      return _.some(order.appointments, (appointment) => {
        return !appointment.start_datetime.startsWith(givenDate);
      });
    }).map(order => order.customer_order_number);
    return result;
  }, [orders, selectedDate]);

  useEffect(() => {
    const result = calculateErrorOrders;
    setErrorOrders(result);
    setShowApptErrors(result.length > 0);
  }, [calculateErrorOrders, setShowApptErrors]);

  return showApptErrors && errorOrders?.length ? (
    <div className="error-container">
      <p>Appointment(s) mismatch</p>
      <p className="description" style={{ maxHeight: 60, overflowY: "auto" }}>
        The order's {
          errorOrders.join(', ')
        } appointments are not matching with selected date.
      </p>
      <span
        className="dismiss-tip"
        onClick={() => {
          setShowApptErrors(false);
        }}
      >
        Dismiss
      </span>
      <div className="icon">
        {" "}
        <LightOnIcon color="red" />{" "}
      </div>
    </div>
  ) : (
    <Fragment />
  );
});

export default QuickDispatchapptError;

import { Divider, Spin } from "antd";
import React, { Fragment } from "react";
import { borderCollapseInherit } from "../helpers/invoice_styles";
import Signature from "./Signature";
import { fetchBillOfRating } from "../api/OrdersApi";
import I18n from "../common/I18n";
import EndUserTitle from "../components/common/EndUserTitle";
import { fetchBolLabels } from "./BillOfRatingModal.helpers";
import _ from 'lodash';

class BillOfRatingModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			orderId: this.props.orderId,
			billOfRating: {},
			isLoading: true,
		};
	}

	componentDidMount() {
		this.fetchbillOfRatingData(this.state.orderId);
	}
	async fetchbillOfRatingData(orderId) {
		try {
			const data = await fetchBillOfRating(orderId);
			const { success, bill_of_rate } = data;
			if (success) {
				this.setState({
					billOfRating: bill_of_rate,
					isLoading: false,
				});
			} else {
				this.setState({
					isLoading: false,
				});
				throw new Error(data.message || "Something went wrong");
			}
		} catch (error) {
		}
	}
	renderAddressRow = (obj) => {
		return (
			<td>
				{obj.name ? obj.name : ""}
				{obj.name ? <br /> : ""}
				{obj.company_name ? <Fragment><span>{obj.company_name}<br /> </span></Fragment> : ""}
				{obj.address?.address_line1 ? obj.address.address_line1 : ""}
				{obj.address?.address_line1 ? <br /> : ""}
				{obj.address?.address_line2 ? obj.address.address_line2 : ""}
				{obj.address?.address_line2 ? <br /> : ""}
				{obj.address?.city && obj.address?.state
					? obj.address.city + obj.address.state
					: ""}
			</td>
		);
	};
	renderQuestions = (questions) => {
		return questions.map(({ question, question_type, answers }, index) => {
			return (
				<tr key={ index }>
					<td>
						{ index + 1 }. { question }
					</td>
					<td>
						{ question_type === "signature" ? (
							<Signature
								image={ `data:image/png;base64,${answers}` }
							/>
						) :
							Array.isArray(answers)
								? answers.map((answer, index) => {
									return <div key={ index }>{ answer }</div>;
								})
								: answers }
					</td>
				</tr>
			);
		});
	};
	renderDescriptionRows = (descriptions) => {
		if (descriptions.length === 0) {
			return (
				<tr>
					<td colSpan="6" style={ { textAlign: 'center', verticalAlign: 'middle' } }>No item Data available</td>
				</tr>
			);
		}

		return descriptions.map((description, index) => {
			const name = _.get(description, 'name', '');
			const serialNumber = _.get(description, 'serial_number', '');
			const itemModel = _.get(description, 'item_model', '');
			const customerOrderNumber = _.get(description, 'customer_order_number', '');
			const whDock = _.get(description, 'wh_dock', '');
			const itemPieces = _.get(description, 'item_pieces');
			const itemWeight = Number(_.get(description, 'item_weight', 0)).toFixed(2);
			const weightDisplay = `${itemWeight} ${itemPieces ? `(${itemPieces}PCS)` : ""}`;

			return (
				<tr key={index}>
					<td>{ name }</td>
					<td>{ serialNumber }</td>
					<td>{ itemModel }</td>
					<td>{ customerOrderNumber }</td>
					<td>{ whDock }</td>
					<td>{ weightDisplay }</td>
				</tr>
			);
		});
	};

	formatHoursData = (hour) => {
		return hour;
	};

	renderInstructions = (instructions, isLykesOrg) => {
		const other = _.get(instructions, 'other', "N/A");
		const hours = instructions?.hours ? this.formatHoursData(instructions.hours) : "N/A";
		return (
			<table className="instructionsTable" style={ { width: "100%", maxWidth: "70vw" } }>
				<thead>
					<tr>
						<th colSpan="2" style={ { textAlign: "center", verticalAlign: "middle" } }>
							{ I18n.t(`BillOfRating.instructions`) }
						</th>
					</tr>
				</thead>
				<tbody>
					{ isLykesOrg ? (
						<>
							{ this.renderRow("general.notes", other) }
							{ this.renderRow("BillOfRating.hours", hours) }
						</>
					) : (
						<>
							{ this.renderRow("BillOfRating.other", other) }
							{ this.renderRow("BillOfRating.hours", hours) }
						</>
					) }
				</tbody>
			</table>
		);
	};

	renderRow = (headingKey, content) => (
		<tr>
			<td className="heading" style={ { fontWeight: "bold" } }>
				{ I18n.t(headingKey) }
			</td>
			<td>
				{ content }
			</td>
		</tr>
	);


	renderSignature = (sign_by, title_by_relation, signature, signature_date) => {
		return (
			<div
				style={ {
					display: "flex",
					justifyContent: "space-between",
				} }
			>
				<div
					style={ {
						display: "flex",
						flexDirection: "column",
						justifyContent: "end",
						maxWidth: "60%",
					} }
				>
					<div
						style={ {
							display: "flex",
							justifyContent: "start",
							gap: "5px",
							minWidth: "22rem",
						} }
					>
						<Signature
							title={ I18n.t("BillOfRating.shipper") }
						/>
						<Signature
							title={ I18n.t("BillOfRating.date") }
						/>
					</div>
				</div>
				<div
					style={ {
						display: "grid",
						gridTemplateColumns: "repeat(2, 1fr)",
						gap: "5px",
						minWidth: "22rem",
					} }
				>
					<Signature
						title={ I18n.t("BillOfRating.print_name") }
						text={ sign_by }
					/>
					<Signature title={ I18n.t("BillOfRating.title") }
						text={ title_by_relation }
					/>
					<Signature
						title={ I18n.t("BillOfRating.signature") }
						image={ signature }
					/>
					<Signature
						title={ I18n.t("BillOfRating.date") }
						content={ signature_date }
					/>
				</div>
			</div>);
	};

	renderLykesSignature = (labelsForBillOfRating) => {
		const { PU_PERSON_NAME, RECEIVER_NAME, DRIVER_NAME } = labelsForBillOfRating;
		return (
			<div style={ { display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" } }>
				<div style={ { display: "flex", justifyContent: "space-between", flex: "1" } }>
					<Signature title={ PU_PERSON_NAME } />
					<Signature title={ RECEIVER_NAME } />
					<Signature title={ DRIVER_NAME } />
				</div>
				<div style={ { display: "flex", justifyContent: "space-between", flex: "1" } }>
					<Signature title={ labelsForBillOfRating.PU_PERSON_SIGN } />
					<Signature title={ labelsForBillOfRating.PU_TIME } />
					<Signature title={ labelsForBillOfRating.RECEIVER_SIGN } />
					<Signature title={ labelsForBillOfRating.RECEIVED_TIME } />
					<Signature title={ labelsForBillOfRating.DRIVER_SIGN } />
					<Signature title={ labelsForBillOfRating.DRIVER_DROP_TIME } />
				</div>
			</div>
		);
	}
	render() {
		if (this.state.isLoading) {
			return <Spin tip="Loading..." spinning={true}></Spin>;
		}

		const { billOfRating } = this.state;
		const { order } = this.props;
		const { hawb, organization_id, type_of_order } = order;
		const labelsForBillOfRating = fetchBolLabels(organization_id);
		const isLykesOrg = organization_id === "602a8070822dd6ce12a49435";

		const {
			vehicle_no,
			date,
			branch,
			from,
			bill_to,
			ship_to,
			customer_phone,
			customer_reference,
			zone,
			service_date,
			description,
			instructions,
			barcode,
			customer_order_number,
			sign_by,
			title_by_relation,
			signature,
			signature_date,
			form,
			organization_logo
		} = billOfRating;

		return (
			<div id="billOfRatingModalContainer">
				<div className="billOfRatingModalBody">
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(3, 1fr)",
						}}
					>
						
						<div>
							{ organization_logo && <img src={organization_logo} alt="Logo" style={{ height: 40, marginTop: 3 }} />}
							<h3 >{from.address?.city && from.address?.state? `${from.address.city} , ${from.address.state}`: ""}</h3>
						</div>
						<div
							className="barcodeInfo"
							style={{
								textAlign: "center",
							}}
						>
							<img
								style={{
									height: "45px",
									width: "110px",
								}}
								src={barcode}
							/>
							<h4>{customer_order_number}</h4>
						</div>
					</div>
					{ isLykesOrg && (
						<div className="hawbOrder" style={ { textAlign: "end" } }>
							<h4><b>#{ labelsForBillOfRating.HAWB }: { hawb }</b></h4>
							<h4><b>#{ labelsForBillOfRating.ORDER_LABEL }: { customer_order_number }</b></h4>
						</div>
					) }
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<div style={ {
							borderRight: "1px dashed #000",
							borderLeft: "1px dashed #000",
							paddingRight: "80px"
						} }>
							<h4>{I18n.t("BillOfRating.vehicle_no")}</h4>
							<h5>{vehicle_no}</h5>
						</div>
						<div
							className="toInfo"
							style={{
								display: "flex",
							}}
						>
							<div style={ {
								borderRight: "1px dashed #000",
								borderLeft: "1px dashed #000",
								paddingRight: "80px"
							} }>
								<h4>{I18n.t("BillOfRating.date")}</h4>
								<h5>{date}</h5>
							</div>
							<div style={ {
								borderRight: "1px dashed #000",
								borderLeft: "1px dashed #000",
								paddingRight: "80px"
							} }>
								<h4>{I18n.t("BillOfRating.branch")}</h4>
								<h5>{branch}</h5>
							</div>
						</div>
					</div>
					<table className="addressTable" width="100%" style={{}}>
						<thead>
							<tr>
								<td className="borHeading"> 
									{I18n.t("BillOfRating.from")}
								</td>
								<td className="borHeading">
									{I18n.t("BillOfRating.bill_to")}
								</td>
								<td className="borHeading">
									{I18n.t("BillOfRating.ship_to")}
								</td>
							</tr>
						</thead>
						<tbody>
							{this.renderAddressRow(from)}
							{this.renderAddressRow(bill_to)}
							{this.renderAddressRow(ship_to)}
						</tbody>
					</table>
					<table width="100%" style={{}}>
						<thead>
							<tr>
								<td className="borHeading">
									{ isLykesOrg ? labelsForBillOfRating.CUSTOMER_PHONE :
										<EndUserTitle stringToAppend={ I18n.t("general.phone") } /> }
								</td>
								<td className="borHeading">
									{I18n.t("BillOfRating.zone")}
								</td>
								<td className="borHeading">
									{I18n.t("BillOfRating.service_date") +
										"/" +
										I18n.t("BillOfRating.spread_date")}
								</td>
								<td className="borHeading">
									{ isLykesOrg ? labelsForBillOfRating.CUSTOMER_REFERENCE : <EndUserTitle stringToAppend={ I18n.t("order.reference") } /> }
								</td>
							</tr>
						</thead>
						<tbody>
							<td>{customer_phone}</td>
							<td>{zone}</td>
							<td>{service_date}</td>
							<td>{customer_reference}</td>
						</tbody>
					</table>
					<div className="descriptionTable">
						<table width="100%" style={{}}>
							<thead>
								<tr>
									<td></td>
									<td></td>
									<td
										style={{
											fontWeight: "bold",
										}}
									>
										{I18n.t("BillOfRating.description")}
									</td>
									<td></td>
									<td></td>
									<td
										style={{
											fontWeight: "bold",
										}}
									>
										{I18n.t("BillOfRating.weight")}
									</td>
								</tr>
							</thead>
							<tbody>
								{this.renderDescriptionRows(description)}
							</tbody>
						</table>
					</div>
					<Divider
						dashed
						style={{
							margin: "0",
							display: form.length ? "auto" : "none",
						}}
					/>
					<div className="modalQuestionare">
						<table width="50%" style={{ ...borderCollapseInherit }}>
							<thead>
								<tr></tr>
							</thead>
							<tbody>
								{form.length > 0 && this.renderQuestions(form)}
							</tbody>
						</table>
					</div>
					<Divider
						dashed
						style={{
							margin: "0",
							display: form.length ? "auto" : "none",
						}}
					/>
					{ this.renderInstructions(instructions, isLykesOrg) }
				</div>
				<div className="modalFooter">
					<div>
						<hr />
						<h5>
							This equipment has been received, put in use, is in
							good working order and is satisfactory and
							acceptable.
						</h5>
						<small style={ { fontSize: "7px" } }>
							PICK UP TENDERED: THE ABOVE EQUIPMENT WAS
							TENDERED IN GOOD CONDITION EXCEPT AS NOTED ON
							THE ATTACHED INVENTORY.
						</small>
					</div>
					{ (isLykesOrg && (type_of_order === 'R' || type_of_order === 'T') ) ? this.renderLykesSignature(labelsForBillOfRating) : this.renderSignature(sign_by, title_by_relation, signature, signature_date) }
					<small style={ { fontSize: "7px" } }>
						SHIPPER AGREES WITH THE TERMS OF THIS BILL OF LADING AND
						TENDERS ARTICLES LISTED ABOVE TO CARRIER IN CONDITION
						SHOWN. SHIPPER EXPRESSLY RELEASES THE SHIPMENT TO A
						VALUE OF $ .60 PER POUND PER ARTICLE
					</small>
					<small style={{ fontSize: "7px" }}>
						TOTAL VALUE OF SHIPMENT IS $ {"___ "} AND REQUEST EXCESS
						COVERAGE FOR FULL VALUE, ONLY IF CONTROL NO IS ISSUED
					</small>
				</div>
			</div>
		);
	}
}
export default BillOfRatingModal;

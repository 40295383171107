import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";
export const glCodeApi = {
  fetch: (organizationId) => {
    let url = ApiUrl(`v2/gl_codes?organization_id=${organizationId}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "organization_glcodes" },
    });
  },
  save: (data) => {
    let url = ApiUrl(`v2/gl_codes`)
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  update: (id, data) => {
    let url = ApiUrl(`v2/gl_codes/${id}`);
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  delete: (id, organizationId) => {
    let url = ApiUrl(`v2/gl_codes/${id}`)
    return Call("delete", url,{organization_id: organizationId}, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
};
import React, { Fragment, useMemo } from "react";
import Draggable from "react-draggable";
import { Button, Card, Link, Icon, TextArea } from "../../common/UIComponents";
import CopyableContent from "../common/CopyableContent";
import { isEmpty } from "lodash";

const StickyNote = ({ info = {}, onClose , position}) => {
  const cardStyle = {
    position: "fixed",
    height: "430px",
    width: "400px",
    zIndex: 999,
  };

  const copyJSONToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const memoizedJSON = useMemo(() => JSON.stringify(info.response, null, 2), [info.response]);
  const defaultPosition = position ? position :{ x: 0, y: 0 }

  return (
    <Draggable handle=".ant-card-head" 
    //  defaultPosition={
    //   position && !isEmpty(position.x) && !isEmpty(position.y) ? position : { x: 0, y: 0 }
    // } 
    defaultPosition={defaultPosition} 
    position={null}>
      <Card
        className="sticky-note-card"
        size="small"
        style={cardStyle}
        title={
          <Fragment>
            <Icon type="menu" />
            &nbsp;Pdf Info
          </Fragment>
        }
        extra={<Button size="small" onClick={onClose}>X</Button>}
        bodyStyle={{
          height: "380px",
          width: "400px",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <div className="sticky-note-content">
          {info?.file_url && (
            <a href={info.file_url} target="_blank" style={{ color: '#1890ff'}}>
              {info?.response?.filename ? info.response.filename : 'File'}
            </a>
          )}

          {info?.response && (
            <div>
              <TextArea value={memoizedJSON} rows={15} />
            </div>
          )}
          {/* {info?.response ? Object.entries(info.response).map(([key, value]) => (
              <div key={key}>
                <span>{key}:</span>  <pre><CopyableContent text={JSON.stringify(value, null, 2)} /></pre>
              </div>
            )) : <Fragment />} */}
        </div>
      </Card>
    </Draggable>
  );
};

export default StickyNote;

import moment from "moment";

function removeSuffixFromKeys (obj, suffix) {
  return _.mapKeys(obj, (value, key) => key.replace(suffix, ''));
}
const pickUpParams = (details) => _.pickBy(details, (value, key) => key.endsWith('_pickup_params'));
const dropParams = (details) => _.pickBy(details, (value, key) => key.endsWith('_drop_params'));

const pickUpDetails = (details) => removeSuffixFromKeys(pickUpParams(details), '_pickup_params');
const dropDetails = (details) => removeSuffixFromKeys(dropParams(details), '_drop_params');

const remainingDetails = (details) => _.omit(details, [
  ...Object.keys(pickUpParams(details)),
  ...Object.keys(dropParams(details))
]);

export const buildTemplatePayload = (details) => ({
  ...remainingDetails(details),
  pickup_details: pickUpDetails(details),
  delivery_details: [ dropDetails(details) ]
});



export const processTemplateSequence = (data) => {
  let seq = {};
  let locations = [];
  let keysToSkip = [ 'reference_1', 'reference_2' ];

  Object.keys(data).forEach((key) => {
    if (keysToSkip.includes(key)) {
      seq[key] = data[key];
      return; 
    }
    const seqNumber = parseInt(key.split("_").pop());

    if (_.isNumber(seqNumber) && !isNaN(seqNumber)) {
      const isPickupParams = key.includes("_pickup_params");
      const isDropParams = key.includes("_drop_params");
      const isSameOrderType = (isPickupParams && data.order_type === 'R') || (isDropParams && data.order_type === 'D');
      const moveOrder = data.order_type === 'M';
      const isLHOrder = data.order_type === 'LH';
      const isTransferParams = key.includes("_transfer_params");

      if (isSameOrderType || moveOrder || isTransferParams) {
        const newKey = key.replace(`_seq_${seqNumber}`, "")
          .replace("_pickup_params", "")
          .replace("_drop_params", "")
          .replace("_transfer_params", "");

        seq[ seqNumber ] = Object.assign({}, seq[ seqNumber ]);
        seq[ seqNumber ][ newKey ] = data[ key ];

        if (isPickupParams) {
          seq[ seqNumber ][ 'l_type' ] = 'R';
        } else if (isDropParams || isSameOrderType || moveOrder) {
          seq[ seqNumber ][ 'l_type' ] = 'D';
        } else if (isLHOrder) {
          seq[ seqNumber ][ 'l_type' ] = 'LH';
        } else {
          seq[ seqNumber ][ 'l_type' ] = 'T';
        }

        seq[ seqNumber ].seq_number = seqNumber;
      }
    } else {
      seq[ key ] = data[ key ];
    }
  });

  // add all the objects to the locations array
  Object.keys(seq).forEach((key) => {
    const keyNum = parseInt(key);
    if (_.isNumber(keyNum) && !isNaN(keyNum)) {
      locations.push(seq[ key ]);
      delete seq[ key ];
    }
  });

  seq.locations = locations;
  return seq;
};


export const getAppointMentTime = (appointment, isMilitaryTime) => {
  if (!appointment || !appointment[ 0 ] || !appointment[ 0 ].item_option[ 0 ]) return null;
  const { item_option } = appointment[ 0 ];
  const format = isMilitaryTime ? "HH:mm" : "hh:mm A";
  if (item_option[ 0 ] === "ANY") return { start_time: moment("8:00 AM", "hh:mm A").format(format), end_time: moment("8:00 PM", "hh:mm A").format(format) };

  const { start_time, end_time } = appointment[ 0 ];
  const start_time_moment = moment(start_time);
  const end_time_moment = moment(end_time);
  if (start_time_moment.isValid() && end_time_moment.isValid()) { // 12 hour format 
    return { start_time: start_time_moment.format(format), end_time: end_time_moment.format(format) };
  }
  // 24 hour format
  return { start_time: moment(start_time, "HH:mm").format(format), end_time: moment(end_time, "HH:mm").format(format) };
};


export const processOptionData = (data , isMilitaryTime) => {
  const { locations, order_type } = data;
  let first_name = "", last_name = "", pickup_address = "", delivery_address = "", pickup_time = "", delivery_time = "";

  if (["LH", "T", "M"].includes(order_type)) {
    const pickup_details = _.get(locations, [ 0 ]);
    const delivery_details = _.get(locations, [ locations.length - 1 ]);

    if (_.isObject(pickup_details) && _.isObject(pickup_details.address)) {
      pickup_address = _.get(pickup_details, [ 'address', 'city' ]);
      pickup_time = getAppointMentTime(_.get(pickup_details, [ 'appointment' ]),  isMilitaryTime);
    }

    if (_.isObject(delivery_details) && _.isObject(delivery_details.address)) {
      first_name = _.get(delivery_details, [ 'first_name' ]);
      last_name = _.get(delivery_details, [ 'last_name' ]);
      delivery_address = _.get(delivery_details, [ 'address', 'city' ]);
      delivery_time = getAppointMentTime(_.get(delivery_details, [ 'appointment' ]),  isMilitaryTime);
    }
  } else if (order_type == 'D') {
    const delivery_details = locations[ 0 ];

    if (_.isObject(delivery_details) && _.isObject(delivery_details.address)) {
      first_name = delivery_details.first_name;
      last_name = delivery_details.last_name;
      delivery_address = delivery_details.address.city;
      delivery_time = getAppointMentTime(delivery_details.appointment , isMilitaryTime);
    }

    pickup_address = _.isObject(data.selected_warehouse) ? data.selected_warehouse.location_code : "";
  } else if (order_type == 'R') {
    const pickup_details = locations[ 0 ];

    if (_.isObject(pickup_details) && _.isObject(pickup_details.address)) {
      first_name = pickup_details.first_name;
      last_name = pickup_details.last_name;
      pickup_address = pickup_details.address.city;
      pickup_time = getAppointMentTime(pickup_details.appointment , isMilitaryTime);
    }

    delivery_address = _.isObject(data.selected_warehouse) ? data.selected_warehouse.location_code : "";
  }

  return { first_name, last_name, pickup_address, delivery_address, pickup_time, delivery_time };
};

export const adjustTemplateAppointment = (value = [], isMilitaryTime) => {
  if (!value) return value;
  const valueMap = value ? value.map((item) => {
    const returnItem = { ...item };
    if (item.start_time && item.end_time) {
      returnItem.start_time = isMilitaryTime
        ? moment(item.start_time, 'HH:mm') 
        : moment(item.start_time, 'hh:mm A');
      returnItem.end_time = isMilitaryTime
        ? moment(item.end_time, 'HH:mm') 
        : moment(item.end_time, 'hh:mm A');
    }
    if (returnItem.item_option){
      returnItem.slots = returnItem.item_option
    }
    return returnItem;
  }) : [];
  return valueMap;
};

export const handleAppointmentErrors = (field, error, appointmentValidationInfo) => {
  if (error === null) {
    return {
      ...appointmentValidationInfo,
      [ field ]: {
        isValid: true,
        error: ''
      }
    };
  } else {
    let errorContent = '';
    if (error.includes('shouldDisableTime')) {
      errorContent = 'Your appointment time is outside of the dispatcher\'s hours. Please select a valid time.';
    } else if (error === 'invalidDate') {
      errorContent = 'Please select a valid time.';
    }
    if (errorContent !== '') {
      return {
        ...appointmentValidationInfo,
        [ field ]: {
          isValid: false,
          error: errorContent
        }
      };
    }
  }
};

import React, { Fragment, useEffect, useState } from "react";
import { Button, Row, Col } from "../../common/UIComponents";
import { printBillingPdf } from "../../api/Billing";
import { alertMessage, base64ToPdf, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import moment from "moment";

const BillingPdfDownload = ({
  className,
  buttonType,
  size,
  label,
  isDisabled,
  handleSuccess,
  orderIds,
}) => {
  const [loading, setLoading] = useState(false);

  const handlePrintBills = async () => {
    const selectedIds = !isEmpty(orderIds) ? orderIds : [];
    try {
      setLoading(true);
      let result = await printBillingPdf(selectedIds);
      if (result.success) {
        base64ToPdf(
          result.bills_file,
          `OrderBillings-${moment().format("YYYY-MM-DDTHH:mm:ss")}.pdf`
        );
      } else {
        if (result.errors.length > 0) {
          alertMessage(result.errors.join(", "), "error", 10);
        }
      }
    } catch (error) {
      alertMessage(error.message, "error", 10);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        onClick={() => handlePrintBills()}
        type={buttonType ? buttonType : "primary"}
        icon="printer"
        className={className ? className : ""}
        loading={loading}
        disabled={isDisabled}
        size={size ? size : "default"}
      >
        {label}
      </Button>
    </Fragment>
  );
};
export default BillingPdfDownload;

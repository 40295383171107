import React, { useEffect, useState } from "react";
import { Alert, Descriptions, Drawer, Spin, Tooltip, Row, Col } from "antd";
import { fetchOrderDetails } from "../../api/OrdersApi";
import Profile from "../../components/common/Profile";
import { isEmpty } from "lodash";
import Copyable from "../../components/common/Copyable";
import EditIcon from "../../components/common/EditIcon";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { formatStopTime} from "../../common/Common";
import { momentTime } from "../../helpers/date_functions";

const LineHaulDetails = ({ order_id, history, isMilitaryTime }) => {
  const [lhData, setLhData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lhNumber, setLhNumber] = useState("");
  const [lhId, setLhId] = useState("");

  useEffect(() => {
    setIsLoading(true);
    fetchOrderDetails(order_id, "ALL")
      .then((result) => {
        if (result.success) {
          setLhData(result.orders[0].locations);
          setLhNumber(result.orders[0].customer_order_number);
          setLhId(result.orders[0].id);
          setIsLoading(false);
        } else {
          setLhData([]);
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function formatAddress(address) {
    return _.compact([
      address.address_line1,
      address.address_line2,
      address.city,
      address.state,
      address.country,
      address.zipcode,
    ]).join(", ");
  }

  if (isLoading && _.isEmpty(lhData)) {
    return (
      <div>
        <Spin spinning={isLoading}></Spin>
      </div>
    );
  } else if (!isLoading && _.isEmpty(lhData)) {
    return (
      <div>
        <Alert message="Linehaul Details Not Found" type="info" showIcon />
      </div>
    );
  }

  const pickup_location =
    lhData?.[0]?.type_of_order === "PICKUP" && lhData[0]?.l_address;
  const pickup_location_address = pickup_location
    ? formatAddress(pickup_location)
    : "";
  const drop_location =
    lhData?.[1]?.type_of_order === "DELIVERY" && lhData[1]?.l_address;
  const drop_location_address = drop_location
    ? formatAddress(drop_location)
    : "";
  const { appointments: pickupAppointments = [] } = lhData?.[0];
  const { appointments: deliveryAppointments = [] } = lhData?.[1];
  const pickupSchlDate = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.appt_date
    : "";
  const pickupSchlStartTime = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.start_time
    : "";
  const pickupSchlEndTime = !_.isEmpty(pickupAppointments)
    ? pickupAppointments?.[0]?.end_time
    : "";
  const deliverySchlDate = !_.isEmpty(deliveryAppointments)
    ? deliveryAppointments?.[0]?.appt_date
    : "";
  const deliverySchlStartTime = !_.isEmpty(deliveryAppointments)
    ? deliveryAppointments?.[0]?.start_time
    : "";
  const deliverySchlEndTime = !_.isEmpty(deliveryAppointments)
    ? deliveryAppointments?.[0]?.end_time
    : "";
  const driverInfo = lhData?.[0]?.driver_details?.[0];
  const pactualStartTime = lhData?.[0]?.actual_start_datetime;
  const pactualEndTime = lhData?.[0]?.actual_end_datetime;
  const dactualStartTime = lhData?.[1]?.actual_start_datetime;
  const dactualEndTime = lhData?.[1]?.actual_end_datetime;
  const pickupTz = lhData?.[0]?.tz_short_form;
  const deliveryTz = lhData?.[1]?.tz_short_form;
  const pickupStatus = lhData?.[0]?.status;
  const deliveryStatus = lhData?.[1]?.status;
  const pickupData = lhData?.[0];
  const deliveryData = lhData?.[1];

  const handleClick = () => {
    history.push({ pathname: `/orders/${lhId}/edit` });
  };

  const formatTimeStamp = (dataObject, startTime, endTime, formatString) => {
    let startString = "";
    let endString = "";
    if (isEmpty(dataObject[startTime]) && isEmpty(dataObject[endTime])) {
      return "NA";
    }
    const scheduleDate = dataObject.scheduled_end_datetime
      ? moment(dataObject.scheduled_end_datetime).format("YYYY-MM-DD")
      : "";
    const skipDateCheck =
      isEmpty(scheduleDate) ||
      startTime === "scheduled_start_datetime" ||
      endTime === "scheduled_end_datetime";
    if (!isEmpty(dataObject[startTime])) {
      const startDate = moment(dataObject[startTime]).format("YYYY-MM-DD");
      const finalFormate =
        !skipDateCheck && startDate !== scheduleDate
          ? `${AppConfig.dateFormat} ${formatString}`
          : formatString;
      startString =
        startTime !== "scheduled_start_datetime"
          ? momentTime(
              finalFormate,
              dataObject[startTime],
              dataObject.timeZoneId
            )
          : moment(dataObject[startTime]).format(finalFormate);
    }
    if (!isEmpty(dataObject[endTime])) {
      const endDate = moment(dataObject[startTime]).format("YYYY-MM-DD");
      const finalFormate =
        !skipDateCheck && endDate !== scheduleDate
          ? `${AppConfig.dateFormat} ${formatString}`
          : formatString;
      endString =
        endTime !== "scheduled_end_datetime"
          ? momentTime(finalFormate, dataObject[endTime], dataObject.timeZoneId)
          : moment(dataObject[endTime]).format(finalFormate);
    }
    if (!isEmpty(dataObject[startTime]) && isEmpty(dataObject[endTime])) {
      endString = startString;
      startString = "Arr. at";
    }
    if (!isEmpty(dataObject[endTime]) && isEmpty(dataObject[startTime])) {
      startString = "Dept. at";
    }
    return `${startString} - ${endString} ${
      dataObject.tz_short_form ? dataObject.tz_short_form : ""
    }`;
  };
  const timeFormat = isMilitaryTime ? "HH:mm" : "h:mm A";

  return (
    <>
      <Spin spinning={isLoading}>
        <Descriptions
          title={
            <span>
              Linehaul Details ({lhNumber}){" "}
              <Copyable text={lhNumber} tooltipPosition={"top"} />
              {"  "}
              <EditIcon handleClick={handleClick} />
            </span>
          }
          bordered
        >
          <Descriptions.Item label="Origin">
            {pickup_location_address}
          </Descriptions.Item>
          <Descriptions.Item label="Destination" span={2}>
            {drop_location_address}
          </Descriptions.Item>
          <Descriptions.Item label="Customer Details">
            <Profile
              profile={(({ first_name, last_name, email, phone }) => ({
                full_name: `${first_name !== null ? first_name : ""} ${
                  last_name !== null ? last_name : ""
                }`,
                email,
                mobile_number: phone ? phone : "",
              }))(lhData[0])}
              withLables={false}
              orderInfo
              showImage={false}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Customer Details" span={3}>
            <Profile
              profile={(({ first_name, last_name, email, phone }) => ({
                full_name: `${first_name !== null ? first_name : ""} ${
                  last_name !== null ? last_name : ""
                }`,
                email,
                mobile_number: phone ? phone : "",
              }))(lhData[1])}
              withLables={false}
              showImage={false}
              verifymedium={true}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Pickup Scheduled Date">
            {!isEmpty(pickupSchlDate)
              ? moment(pickupSchlDate).format(" DD MMM, YYYY")
              : "NA"}
          </Descriptions.Item>
          <Descriptions.Item label="Delivery Scheduled Date" span={3}>
            {!isEmpty(deliverySchlDate)
              ? moment(deliverySchlDate).format("DD MMM, YYYY")
              : "NA"}
          </Descriptions.Item>
          <Descriptions.Item label="Pickup Timings Info">
            <Row gutter={8}>
              <Col>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"SC :"} </b>
                    </span>
                    {!isEmpty(pickupSchlStartTime) ||
                    !isEmpty(pickupSchlEndTime) ? (
                      <React.Fragment>
                        {formatStopTime(
                          pickupSchlStartTime,
                          pickupSchlEndTime,
                          pickupTz
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"AT :"} </b>
                    </span>
                    {pickupData &&
                    (!isEmpty(pactualStartTime) || !isEmpty(pactualEndTime)) ? (
                      <React.Fragment>
                        {formatTimeStamp(
                          pickupData,
                          "actual_start_datetime",
                          "actual_end_datetime",
                          timeFormat
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="Delivery Timings Info" span={3}>
            <Row gutter={8}>
              <Col>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"SC :"} </b>
                    </span>
                    {!isEmpty(deliverySchlStartTime) ||
                    !isEmpty(deliverySchlEndTime) ? (
                      <React.Fragment>
                        {formatStopTime(
                          deliverySchlStartTime,
                          deliverySchlEndTime,
                          deliveryTz
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24}>
                    <span>
                      <b>{"AT :"} </b>
                    </span>
                    {deliveryData &&
                    (!isEmpty(dactualStartTime) || !isEmpty(dactualEndTime)) ? (
                      <React.Fragment>
                        {formatTimeStamp(
                          deliveryData,
                          "actual_start_datetime",
                          "actual_end_datetime",
                          timeFormat
                        )}
                      </React.Fragment>
                    ) : (
                      "NA"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="Pickup Status">
            {pickupStatus !== null ? <b>{pickupStatus}</b> : "NA"}
          </Descriptions.Item>
          <Descriptions.Item label="Delivery Status" span={3}>
            {deliveryStatus !== null ? <b>{deliveryStatus}</b> : "NA"}
          </Descriptions.Item>
          {!_.isEmpty(driverInfo) ? (
            <Descriptions.Item label="Driver Details">
              <Profile
                profile={(({
                  first_name,
                  last_name,
                  mobile_number,
                  employee_code,
                }) => ({
                  full_name: `${first_name !== null ? first_name : ""} ${
                    last_name !== null ? last_name : ""
                  } (${employee_code !== null ? employee_code : ""})`,
                  mobile_number: mobile_number ? mobile_number : "",
                }))(driverInfo)}
                withLables={false}
                showImage={false}
                verifymedium={true}
                hideMail={true}
              />
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </Spin>
    </>
  );
};

const LhDetails = withRouter(LineHaulDetails);
export default LhDetails;

import React  from "react";
import { isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { Popover, Icon } from "../../common/UIComponents";
import accessorialIcon from "../../../assets/images/orderAccessorial.svg";

const PreplanOrderAccessorial = (props) => {
  const { record, placement = "left", showPopupContainer = true } = props;
  const order_accessorials = record.order_accessorials;
  const accessorialList =
    !isEmpty(order_accessorials) && order_accessorials.length > 0
      ? order_accessorials
          .filter((acc) => !isEmpty(acc.accessorial_code))
          .map((acc) => acc.accessorial_code).sort()
      : null;

  return (
    <div>
      {!isEmpty(accessorialList) && accessorialList.length > 0 && (
        <Popover
          content={
            <div style={{ maxWidth: 300 }}>{accessorialList.join(", ")}</div>
          }
          placement={placement}
          title={I18n.t("general.accessorial")}
          getPopupContainer={showPopupContainer ? (triggerNode) => triggerNode : null}
        >
          <Icon
            component={() => (
              <img
                style={{ height: 15, marginTop: -4 }}
                src={accessorialIcon}
              />
            )}
          />
        </Popover>
      )}
    </div>
  );
};
export default PreplanOrderAccessorial;

import { Avatar, Dropdown, Icon, Menu, Popover, Row, Col, Button, Select, Badge } from 'antd';
import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { Header, Search } from '../../common/UIComponents';
import { checkAccessExistance, checkServiceExistance, clearSession, removeSpecialCharacters } from '../../helpers/common';
import WarehouseFilter from '../common/WarehouseFilter';
// import logo from "../../../assets/images/logo-thumbnail.png";
import AppConfig from '../../config/AppConfig';
import OrderFilter from '../orders/OrderFilter';
import EditIcon from '../common/EditIcon';
import SettingIcon from '../common/SettingIcon';
import { TableImageColumn } from '../../common/TableImageColumn';
import GlobalSearch from './GlobalSearch';
import { CompanyLogo } from '../common/LogisticsLoadingScreen';
import userStore from '../../stores/UserStore';
import { refreshOrders } from '../../api/OrdersApi';
import { alertMessage } from '../../common/Common';

const menu = (isSuperAdmin, isShipper, isDriver) =>
{
  return (
  <Menu>
    { checkServiceExistance("UME") && (
      <Menu.Item key="008">
        <NavLink to="/edit_profile">
          <SettingIcon/>
          <span className="nav-text">Edit Profile</span>
        </NavLink>
      </Menu.Item>
    ) }
    { checkServiceExistance("UCP") && (
      <Menu.Item key="009">
        <NavLink to="/changePassword">
          <EditIcon/>
          <span className="nav-text">Change Password</span>
        </NavLink>
      </Menu.Item>
    ) }
    {!isSuperAdmin && !isShipper && !isDriver && (
      <Menu.Item key="010">
      <NavLink to="/displaySettings">
        <Icon type="picture" style={{color:"#ad2102"}}/>
        <span className="nav-text">Display Settings</span>
      </NavLink>
    </Menu.Item>
    )}
    
    <Menu.Item>
      <a
        // href="/users/sign_out"
        onClick={clearSession}
      >
        <Icon type="logout" className="settings-icon" style={{color:"#eb2f96"}}  />
        <span className="nav-text">Sign Out</span>
      </a>
    </Menu.Item>
  </Menu>
  )
}


function getBadgeCount () {
  const hostname = window.location.hostname;
  if (hostname.includes('localhost')) return { count: 'dev', bgColor: '#add8e6', color: '#000' };
  if (hostname === 'stage.fleetenable.com') return { count: 'stage', bgColor: '#ffa500', color: '#000' };
  if (hostname === 'fe-beta.fleetenable.com') return { count: 'beta', bgColor: '#008000', color: '#fff' };
  return { count: null, bgColor: null, color: null };
}

function MainHeader (props) {
  const { orderNumberSearch, filterPlaceHolder, handleFilterValueChange, handleFilterPlaceHolderChange, handleSearch,handleSaveNSearch, handleVisibleChange, filterWindow, landingPage, currentUser, handleOnChange, isShipper, isDriver,WarehouseContext,currentOrg} = props;
  const orgLogo =currentUser?.currentOrg?.logo?.url;
  const badge = getBadgeCount();
  const [isLoading, setIsLoading] = useState(false);


  return (
    <Header style={ { position: "fixed", width: "100%", backgroundColor: "white" } }>
      <Row type="flex" align='middle' justify='space-between'>
        <Col span={ 4 }>
          <Row type="flex" justify="start" align='middle'>
            <Col>
              <NavLink to={ landingPage }>
                <Badge count={ badge.count } style={ { backgroundColor: badge.bgColor, color: badge.color, fontWeight: 'bold', fontSize: '12px' } } offset={ [ 10, 0 ] }>

                  {/* <img src={ logo } alt="Logo" style={ {
                    width: "100%",
                    height: "25px"
                  } } /> */}
                  <CompanyLogo style={ { width: '100%', height: '25px' } } />
                </Badge>
              </NavLink>
            </Col>
          </Row>
        </Col>
        <Col span={ 20 }>
          <Row type="flex" justify="end" gutter={ 2 }>
            {
            !props.isSuperAdmin && !isDriver && checkAccessExistance('ORDER') && <Col span={ 14 }>
              { checkServiceExistance([ "COFILTER", "COI" ], "ALL") && (
                  <GlobalSearch
                    filterPlaceHolder={ filterPlaceHolder }
                    handleFilterValueChange={ handleFilterValueChange }
                    handleFilterPlaceHolderChange={ handleFilterPlaceHolderChange }
                    handleSearch={ handleSearch }
                    handleSaveNSearch={ handleSaveNSearch }
                    handleVisibleChange={ handleVisibleChange }
                    filterWindow={ filterWindow }
                    landingPage={ landingPage }
                    currentUser={ currentUser }
                    handleOnChange={ handleOnChange }
                    isShipper={ isShipper }
                    orderNumberSearch={ orderNumberSearch }
                    warehouseContext={WarehouseContext}
                  />
              )}
            </Col>
            }
            { !props.isSuperAdmin && !isDriver && (
            <Col style={ {
              minWidth: 200,
            } }
            >
              <WarehouseFilter style={ { width: "100%" } } showIcon={true} showAllOption={false} />
            </Col>
            ) }

          {!props.isSuperAdmin && orgLogo && <Col>
              <TableImageColumn
                record={orgLogo}
                alt="org logo"
                width="100"
                height="30"
              />
            </Col>}

            <Col >
              <Row type="flex" justify='space-between' gutter={ 4 } className="Avatar">
                <Col>
                  <Popover content={ <div>
                    For any queries contact <br />
                    <a href={ `mailto:${AppConfig.support_email}` } target="_blank" className="textBold textPrimary">
                      <Icon type="mail" />&nbsp;
                      <u>
                        { AppConfig.support_email }
                      </u>
                    </a>
                  </div> } placement="bottomRight">
                    <Avatar style={ { backgroundColor: '#607ad6', verticalAlign: 'middle' } } size="small">
                      ?
                    </Avatar>
                  </Popover>
                </Col>
                <Col>
                  <Dropdown overlay={() => menu(props.isSuperAdmin, props.isShipper, isDriver) } placement="bottomRight">
                    { currentUser &&
                      currentUser.image &&
                      currentUser.image.url &&
                      currentUser.image.url !== '' ? (
                      <Avatar src={ `${currentUser.image.url}` } />
                    ) : (
                      <Avatar icon="user" />
                    ) }
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>

  );
}

export default MainHeader;
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from './ApiUtils'
import AppConfig from '../config/AppConfig'
import { isEmpty } from '../common/Common'
import I18n from '../common/I18n'

export const RulesApi = {
  fetch: (organization_id) => {
    const url = ApiUrl(
      `v2/rules?organization_id=${organization_id}&operation_code=OCNFR`,
    )
    return Call('get', url, {}, ParseGeneralResponse, {
      responseDataKeys: { organization_rules: 'rules' },
    })
  },
  save: (data) => {
    const url = ApiUrl('v2/rules')
    return Call('post', url, data, ParseGeneralResponse, {
        responseDataKeys: {
        organization_rule: 'organization_rule',
        message: 'message',
        },
    })
  },
  update: (data) => {
    const url = ApiUrl('v2/rules/update_rules')
    return Call('put', url, data, ParseGeneralResponse, {
        responseDataKeys: {
        organization_rule: 'organization_rule',
        message: 'message',
        },
    })
  }
}

export const SubjectLines = {
  save: (data) => {
    const url = ApiUrl("v2/organization/update_org_config");
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "org_config_setting" },
    });
  },
};

import { Badge } from "antd";
import React from "react";

export const BetaBadge = ({ count, className, text }) => {
  return (
 <Badge
      count={count}
      style={{
        backgroundColor: "red",
        color: "white",
        left: "40px",
        bottom: "30px",
        right: "0px",
        border: "none",
        fontSize: "13px",
        width: "40px",
        height: "20px",
        borderRadius: "5px",
      }}
    >
      <span className={className}>{text}</span>
    </Badge>
  );
};
import * as React from "react";
import ReactToPrint from "react-to-print";
import { Icon } from "antd";
import BaseModal from "../components/BaseModal";
import I18n from "../common/I18n";

export class PrintModal extends React.PureComponent {
	componentRef = null;

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			text: "old boring text",
		};
	}

	// handleAfterPrint = () => {
	// };

	// handleBeforePrint = () => {
	// };

	handleOnBeforeGetContent = () => {
		this.setState({ text: "Loading new text...", isLoading: true });

		return new Promise((resolve) => {
			setTimeout(() => {
				this.setState(
					{ text: "New, Updated Text!", isLoading: false },
					resolve
				);
			}, 2000);
		});
	};

	setComponentRef = (ref) => {
		this.componentRef = ref;
	};

	reactToPrintContent = () => {
		return this.componentRef;
	};

	reactToPrintTrigger = () => {
		// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
		// to the root node of the returned component as it will be overwritten.

		// Bad: the `onClick` here will be overwritten by `react-to-print`
		// return <button onClick={() => alert('This will not work')}>Print this out!</button>;

		// Good
		// return <button>Print using a Class Component</button>;

		return (
			<div className="alignCenter marginBottom10" style={{ cursor: "pointer" }}>
				<Icon type="printer" /> Print
			</div>
		);
	};

	render() {
		const {
			width,
			setRef,
			style,
			componentRef,
			closeModal,
			title,
			children,
			documentName,
		} = this.props;
		return (
			<BaseModal
				title={title}
				onCancel={() => closeModal()}
				width={width || "70%"}
				style={style || { top: 50 }}
			>
				<ReactToPrint
					content={this.reactToPrintContent}
					documentTitle={documentName || I18n.t("PrintModal.print")}
					// onAfterPrint={this.handleAfterPrint}
					// onBeforeGetContent={this.handleOnBeforeGetContent}
					// onBeforePrint={this.handleBeforePrint}
					removeAfterPrint
					trigger={this.reactToPrintTrigger}
				/>
				{/* if you wanna show loading while processing component to print. use below code */}
				{/* {this.state.isLoading && (
					<p className="indicator">onBeforeGetContent: Loading...</p>
				)} */}
				<div ref={this.setComponentRef}>{children}</div>
			</BaseModal>
		);
	}
}

PrintModal.defaultProps = {
	width: "70%",
	style: { top: 50 },
	closeModal: () => {},
	title: "",
};

import { Call, ApiUrl, PageParams, ParseGeneralResponse, setObjectToFormData } from './ApiUtils';
import AppConfig from '../config/AppConfig';
import I18n from '../common/I18n';

export function fetchTrucks (filter = {}, page = 1, perPage = AppConfig.perPage) {
  let url = ApiUrl(`v2/fleet?${PageParams(page, perPage)}&operation_code=FLTI`);
  url = filter.status ? `${url}&status=${filter.status}` : `${url}&status=all`;
  url = filter.vin ? `${url}&vin=${filter.vin}` : url;
  url = filter.vehicle_type ? `${url}&vehicle_type=${filter.vehicle_type}` : url;
  url = filter.fleet_id ? `${url}&fleet_id=${filter.fleet_id}` : url;
  url = filter.pre_plan_id ? `${url}&pre_plan_id=${filter.pre_plan_id}` : url;
  url = filter.sortBy ? `${url}&sort_by=${filter.sortBy}` : url;
  url = filter.sortByType ? `${url}&sort_order=${I18n.t(`general.sort_${filter.sortByType}`)}` : url;
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { trucks: 'fleet', pagination: 'meta.pagination' },
  });
}

export function saveTruck (isNew, data) {
  const formData = setObjectToFormData(data);
  const url = isNew ? ApiUrl('v2/fleet') : ApiUrl(`v2/fleet/${data.id}`);
  const method = isNew ? 'post' : 'put';
  formData.append('operation_code', isNew ? 'FLTC' : 'FLTU');
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { truck: '' } });
}

export function updateStatus (id, status) {
  const formData = setObjectToFormData({ fleet_id: id, status });
  const url = ApiUrl('v2/fleet/update_fleet_status');
  const method = 'put';
  formData.append('operation_code', 'FLTUFS');
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { truck: '' } }, false);
}

export function fetchTrucksByType (type) {
  const url = ApiUrl(`v2/fleet?truck_type=${type}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { trucks: '', pagination: 'meta.pagination' },
  });
}

export function deleteTruck (id) {
  const url = ApiUrl(`v2/fleet/${id}?operation_code=FLTD`);
  return Call('delete', url, {}, ParseGeneralResponse);
}

import React, { Fragment } from "react";
import BaseList from "../BaseList";
import { doFormate, isEmpty } from "../../common/Common";
import {
  Row,
  Col,
  Checkbox,
  Icon,
  Radio,
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import { formatFullName } from "../../helpers/common";
import { getSlotInfo } from "../configurations/helper";
import I18n from "../../common/I18n";
import DisplayTime from "../common/DisplayTime";

const RadioGroup = Radio.Group;

class OrderAccessorialList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "accessorial",
        title: I18n.t("general.accessorial"),
        dataIndex: "accessorial_name",
        render: (data, record) => {
          const orderAccObject = this.props.orderAccessorials.find(         
            (acc) => acc.account_accessorial_id === record.id
          );
          const isLos =
            !isEmpty(orderAccObject) &&
            orderAccObject.accessorial_type === "los";
          return !this.props.isReadOnly ? (
            <Checkbox
              checked={!isEmpty(orderAccObject)}
              onChange={(e) =>
                this.props.handleAddOrderCompAccessorial(
                  record.id,
                  record.accessorial_code,
                  e.target.checked,
                  record.standard_code === null
                )
              }
              disabled={isLos}
            >
              {record.accessorial_name}
            </Checkbox>
          ) : (
            <Fragment>
              {record.accessorial_type === "los" ||
              (!isEmpty(orderAccObject) &&
                orderAccObject.is_completed === true) ? (
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  className="textGreen"
                />
              ) : (
                <Icon type="check-circle" className="textGrey" />
              )}
              &nbsp;&nbsp;
              {record.accessorial_name} 
              {record?.accessorial_config_params?.warehouse_operation === "true" && (
               <span style={{ color: "#0f85e8" ,fontSize:"10px" }}>&nbsp;&ndash; Warehouse Operation </span>
              )}
            </Fragment>
          );
        },
        width: 200,
        className: "fontSize12",
      },
      {
        key: "code",
        dataIndex: "accessorial_code",
        title: I18n.t("general.code"),
        width: 100,
        className: "fontSize12",
      },
      {
        key: "created_by",
        dataIndex: "created_by",
        title: "Created By",
        width: 100,
        className: "fontSize12",
        render : (data,record) => {
          const orderAccObject = this.props.orderAccessorials.find(
            (acc) => acc.account_accessorial_id === record.id
          );
          return (
            <span className="textCaptilize">{orderAccObject?.created_by || ''}</span>
          )
        }
      },
      {
        key: "created_at",
        dataIndex: "created_at",
        title: "Created At",
        width: 150,
        className: "fontSize12",
        render : (data,record) => {
          const orderAccObject = this.props.orderAccessorials.find(
            (acc) => acc.account_accessorial_id === record.id
          );
          return (
            <span>{orderAccObject?.created_by ? 
              <DisplayTime
              dateTimeString={orderAccObject.created_at}
              displayWithDate={true}
              isUTC={true}
              tz_short_name="UTC"
            />: ''}</span>
          )
        }
      },
      {
        key: "components",
        dataIndex: "componentDefs",
        width:300,
        render: (data, record) => {
          const isMilitaryTime = this.props.isMilitaryTime;
          const orderAccObject = this.props.orderAccessorials.find(
            (acc) => acc.account_accessorial_id === record.id
          );
          const isReadOnly = this.props.isReadOnly;
          let orderComponentParams = [];
          if (
            !isEmpty(orderAccObject) &&
            !isEmpty(orderAccObject.component_params)
          ) {
            orderComponentParams = orderAccObject.component_params;
          }
          const driverPayParams = orderAccObject.driver_pay_params ? orderAccObject.driver_pay_params : {};
          const componentDefinitions =
            !isEmpty(record.componentDefs) &&
            _.isArray(record.componentDefs) &&
            record.componentDefs.length > 0
              ? record.componentDefs.filter((component) =>
                  this.props.displayComponents.includes(component.code)
                )
              : [];
          if (componentDefinitions.length > 0) {
            return <Fragment>
              {
              componentDefinitions.map((componentDef) => {
              let paramObject = {};
              if (
                componentDef.component_code === "COLLECTION" &&
                componentDef.component_params.length > 0
              ) {
                paramObject = _.find(componentDef.component_params, {
                  accessorial_key: "collection_name",
                });
              }

              const orderComponent = orderComponentParams.find(
                (component) => component.component_code === componentDef.code
              );

              const orderParamValues =
                orderComponent && orderComponent.component_values
                  ? orderComponent.component_values
                  : [];
              let componentParams = componentDef.component_params.filter(
                (param) => param.visible_to.includes("DISPATCHER")
              );
              return (
                <Fragment>
                  {
                    this.displayDriverParams(record, orderAccObject, this.props.technicians)
                  }
                  {
                    this.displayAttempts(record, orderAccObject, this.props.drivers)
                  }
                  {componentParams.map((paramDefinition) => {
                    const currentParamObj = orderParamValues.find(
                      (cp) => cp.accessorial_key === paramDefinition.code
                    );
                    return (isReadOnly && (paramDefinition.code === "SLOT_CONFIG" || !isEmpty(currentParamObj))) ||
                      !isReadOnly ? (
                      <Col xs={this.props.haveMultipleLocations ? 24 : 8} className="fontSize12" key={paramDefinition.code}>
                        <Row>
                          <Col xs={10}>
                            {paramDefinition.code === "things_count" &&
                            !isEmpty(paramObject) &&
                            paramObject.accessorial_value
                              ? paramObject.accessorial_value
                              : paramDefinition.name}
                          </Col>
                          <Col xs={1}>:</Col>
                          <Col xs={13}>
                          {
                            paramDefinition.code === "SLOT_CONFIG" ?
                            <Fragment>
                              { driverPayParams && driverPayParams.time_slots ? <Fragment>{getSlotInfo(driverPayParams.time_slots, isMilitaryTime)}</Fragment> : <Fragment />}
                            </Fragment>
                            :
                            <Fragment>
                              {
                                !this.props.isReadOnly
                              ? this.props.renderField(
                                  paramDefinition,
                                  {
                                    ...componentDef,
                                    component_code: componentDef.code,
                                  },
                                  record,
                                  orderAccObject,
                                  currentParamObj
                                )
                              : !isEmpty(currentParamObj) &&
                                !isEmpty(currentParamObj.accessorial_value)
                              ? currentParamObj.accessorial_value
                              : "-"
                              }
                            </Fragment>
                          }
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      <Fragment />
                    );
                  })}
                 
                  
                </Fragment>
              );
            })
            }
            {this.displayAuthInfo(record, orderAccObject)}
            </Fragment>
          } else {
            return <Fragment>
            {this.displayDriverParams(record, orderAccObject, this.props.technicians)}
            {
              this.displayAttempts(record, orderAccObject, this.props.drivers)
            }
            {this.displayAuthInfo(record, orderAccObject)}
            </Fragment>;
          }
        },
      },
    ];
  }

  displayDriverParams = (record, orderAccObject, technicians) => {
    const haveDriverPrams =  record.accessorial_config_params && record.accessorial_config_params.eligible_for && record.accessorial_config_params.eligible_for == 'wh_handling' ? true : false;
    const haveTechnician = !isEmpty(orderAccObject) && orderAccObject.driver_pay_params?.driver_id ? true : false;
    const selectedDriver = haveDriverPrams && haveTechnician ? _.find(technicians, { id: orderAccObject.driver_pay_params.driver_id}) : null;
    return (selectedDriver ? <Col xs={this.props.haveMultipleLocations ? 12 : 8} className="fontSize12"><Row>
      <Col xs={10}>{AppConfig.roles.technician}</Col>
      <Col xs={1}>:</Col>
      <Col xs={13}>
        {selectedDriver ? `${selectedDriver.employee_code ? selectedDriver.employee_code : formatFullName(selectedDriver)}` : ''}
      </Col>
    </Row></Col>: '')
  }
  displayAttempts = (record, orderAccObject, drivers) => {
    const prevAttempts = !isEmpty(orderAccObject) && orderAccObject.driver_pay_params?.attempted_driver_ids ? orderAccObject.driver_pay_params.attempted_driver_ids : [];
    const havePrevAttempts = prevAttempts.length > 0
    let dvNames = [];
    if(havePrevAttempts){
      dvNames = prevAttempts.map((dv) => {
          const dvRec = _.find(drivers, { id: dv});
          return dvRec ? `${dvRec.employee_code ? dvRec.employee_code : formatFullName(dvRec)}` : ''
        });
    }
    return (havePrevAttempts ? <Col xs={this.props.haveMultipleLocations ? 24 : 8} className="fontSize12"><Row>
      <Col xs={7}>
        {/* {AppConfig.roles.driver} */}
        Attempts by
      </Col>
      <Col xs={1}>:</Col>
      <Col xs={16}>
        { (_.compact(dvNames)).join(', ')}
      </Col>
    </Row></Col>: '')
  }
  displayAuthInfo = (record, orderAccObject, drivers) => {
    return (orderAccObject.accessorial_type !== "los" && this.props.config?.require_accessorial_authorization === 'true' ? <Col xs={24} className="fontSize12"><Row>
      <Col xs={this.props.haveMultipleLocations ? 24 : 8}>
        {I18n.t('accessorials.authorize.by')}:&nbsp;{doFormate(orderAccObject['authorized_by'])}
      </Col>
       <Col xs={this.props.haveMultipleLocations ? 24 : 8}>
        {I18n.t('accessorials.authorize.code')}:&nbsp;{doFormate(orderAccObject['authorization_code'])}
      </Col>
       <Col xs={this.props.haveMultipleLocations ? 24 : 8}>
        {I18n.t('accessorials.authorize.comments')}:&nbsp;{doFormate(orderAccObject['comments'])}
      </Col>
    </Row></Col>: '')
  }
}

export default OrderAccessorialList;

import React from 'react';
import { Select } from 'antd';
import PropTypes from "prop-types";

/** 
  this is a base select component 
**/

function BaseSelect (props) {
  const { onChange, value = '', selectKey, selectValue, selectedValue,selectedCode, defaultOption, handleOptionFilter, showSelectOption = true, defaultOptionValue="" , ...rest } = props;
  const defaultFilter = (input, option) => option.props?.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return (
    <Select { ...rest } onChange={onChange} value={ value }
      filterOption={ handleOptionFilter || defaultFilter }
    >
      {
      showSelectOption && 
      <Select.Option key="warehouse" value={defaultOptionValue}>
        { defaultOption || '-- Select --' }
      </Select.Option>
      }

      { props.data.map((item) => {
        return (
        <Select.Option key={item[selectKey]} value={item[selectValue]}>
          {`${item[selectedValue]} ${item[selectedCode] ?  `(${item[selectedCode]})`:''}` }
        </Select.Option>
      )
      }) }
    </Select>
  );
}

export default BaseSelect;
BaseSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectKey: PropTypes.string.isRequired,
  selectVal: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  defaultOption: PropTypes.string,
};

BaseSelect.defaultProps = {
  onChange: () => { },
  value: '',
  data: [],
  selectKey: '',
  selectVal: '',
  selectedValue: '',
  defaultOption: '',
};


function WarehouseSelect (props) {
  return (
    <BaseSelect
      { ...props }
      selectKey={ props.selectKey || "location_code" }
      selectValue={ props.selectValue || "id" }
      selectedValue={ props.selectedValue || "name" }
    />
  );
}


// DriverSelect
function DriverSelect (props) {
  return (
    <BaseSelect
      { ...props }
      selectKey={ props.selectKey || "employee_code" }
      selectValue={ props.selectValue || "id" }
      selectedValue={ props.selectedValue || "employee_code" }
    />
  );
}

function AccountSelect (props) {
  return (
    <BaseSelect
      { ...props }
      selectedCode={ props.selectedCode || "" }
    />
  );
}

export { WarehouseSelect, DriverSelect,AccountSelect };
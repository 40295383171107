import { Col, DatePicker, Row } from "antd";
import React, { Fragment } from "react";
import { FormItem } from "./UIComponents";
import TimePicker from "../common/TimePicker";
import moment from "moment";
import PropTypes from "prop-types";
import MaterialTimePicker from "../components/orders/MaterialTimePicker";
import I18n from "./I18n";

function DateTimeSelector(props) {
  const { hideLabel = false, hideDate = false, dateProps = {}, timeProps = {}, gridStyle, ...rest } = props;
  const FormItemEle = props?.FormItem ? props?.FormItem : FormItem;
  const {
    format: dateFormat = "YYYY-MM-DD",
    onChange: onDateChange,
    value: dateValue,
    style: dateStyle,
    placeholder: DatePlaceHolder,
    disabledDate,
    label: dateLabel = "Date",
    require: dateRequire = false,
    dateKey: dateKey,
    ...restDateProps
  } = dateProps;

  const {
    format: timeFormat = "HH:mm",
    onChange: onTimeChange,
    value: timeValue,
    style: timeStyle,
    showTime,
    placeHolder: TimePlaceHolder,
    label: timeLabel = "Time",
    className: timepickerClassName,
    isMilitaryTime,
    require: timeRequire = false,
    timeKey: timeKey,
    disabled: disabled = false,
    ...restTimeProps
  } = timeProps;

  const containersStyles = props.gridStyle || {
    row: {
      type: "flex",
    },
    timeCol: {
      span: props.hideDate ? 24 : 12,
    },
    dateCol: {
      span: props.hideTime ? 24 : 12,
    },
  };

  return (
    <Row
      {...containersStyles.row}
      gutter={
        containersStyles.row && containersStyles.row.gutter
          ? containersStyles.row.gutter
          : 8
      }
    >
    {props.hideLabel ? (
      <Fragment>
        {props.hideDate !== true ? (
            <Col {...containersStyles.dateCol}>
              {/* <FormItemEle label={dateLabel} require={dateProps.dateRequire}> */}
                <DatePicker
                  format={dateFormat}
                  onChange={onDateChange}
                  value={dateValue}
                  style={dateStyle}
                  disabledDate={disabledDate}
                  placeholder={DatePlaceHolder || "Select Date"}
                  {...restDateProps}
                />
              {/* </FormItemEle> */}
            </Col>
          ) : null}
          {props.hideTime ? null : (
            <Col {...containersStyles.timeCol}>
              {/* <FormItemEle label={timeLabel} require={timeProps.timeRequire}> */}
                <MaterialTimePicker
                  className={timepickerClassName || ""}
                  style={timeStyle}
                  onChange={(e) => onTimeChange(e)}
                  value={timeValue ? moment(timeValue, timeFormat) : null}
                  computeDisabledHours={() => []}
                  isMilitaryTime={isMilitaryTime}
                  computeDisabledMinutes={(pickedTime) => {
                    return [];
                  }}
                  size={"small"}
                  disabled={disabled}
                />
              {/* </FormItemEle> */}
            </Col>
          )}
      </Fragment>
      )
      : props.Form ? (
        <Fragment>
          <Col {...containersStyles.dateCol}>
            <props.Form.Item label={dateLabel}>
              {props.getFieldDecorator(dateKey, {
                initialValue: "",
                rules: [
                  {
                    required: dateRequire,
                    message: I18n.t("errors.field_required", {
                      field: "Value",
                    }),
                  },
                ],
              })(
                <DatePicker
                  format={dateFormat}
                  onChange={onDateChange}
                  // value={dateValue}
                  style={dateStyle}
                  disabledDate={disabledDate}
                  placeholder={DatePlaceHolder || "Select Date"}
                  {...restDateProps}
                />
              )}
            </props.Form.Item>
          </Col>
          {props.hideTime ? null : (
            <Col {...containersStyles.timeCol}>
              <props.Form.Item label={timeLabel}>
                {props.getFieldDecorator(timeKey, {
                  initialValue: "",
                  rules: [
                    {
                      required: timeRequire,
                      message: I18n.t("errors.field_required", {
                        field: "Value",
                      }),
                    },
                  ],
                })(
                  <MaterialTimePicker
                    className={timepickerClassName || ""}
                    style={timeStyle}
                    onChange={(e) => onTimeChange(e)}
                    format={timeFormat}
                    //value={timeValue ? moment(timeValue, timeFormat) : null}
                    computeDisabledHours={() => []}
                    isMilitaryTime={isMilitaryTime}
                    computeDisabledMinutes={(pickedTime) => {
                      return [];
                    }}
                    size="small"
                    disabled={disabled}

                  />
                )}
              </props.Form.Item>
            </Col>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {
            props.hideDate !== true ? <Col {...containersStyles.dateCol}>
            <FormItemEle label={dateLabel} require={dateProps.dateRequire}>
              <DatePicker
                format={dateFormat}
                onChange={onDateChange}
                value={dateValue}
                style={dateStyle}
                disabledDate={disabledDate}
                placeholder={DatePlaceHolder || "Select Date"}
                {...restDateProps}
              />
            </FormItemEle>
          </Col>
          : null }

          {props.hideTime ? null : (
            <Col {...containersStyles.timeCol}>
              <FormItemEle label={timeLabel} require={timeProps.timeRequire}>
                <MaterialTimePicker
                  className={timepickerClassName || ""}
                  style={timeStyle}
                  onChange={(e) => onTimeChange(e)}
                  value={timeValue ? moment(timeValue, timeFormat) : null}
                  computeDisabledHours={() => []}
                  isMilitaryTime={isMilitaryTime}
                  computeDisabledMinutes={(pickedTime) => {
                    return [];
                  }}
                  size={"small"}
                  disabled={disabled}
                />
              </FormItemEle>
            </Col>
          )}
        </Fragment>
      )}
    </Row>
  );
}

export default DateTimeSelector;

DateTimeSelector.propTypes = {
  dateProps: PropTypes.shape({
    format: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.object,
    style: PropTypes.object,
    placeholder: PropTypes.string,
    disabledDate: PropTypes.func,
    label: PropTypes.string,
  }),
  timeProps: PropTypes.shape({
    format: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.object,
    style: PropTypes.object,
    showTime: PropTypes.bool,
    placeHolder: PropTypes.string,
    label: PropTypes.string,
  }),
  gridStyle: PropTypes.shape({
    row: PropTypes.object,
    timeCol: PropTypes.object,
    dateCol: PropTypes.object,
  }),
};

import React, { Fragment, useState, } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Popconfirm,
} from '../../common/UIComponents'
import { alertMessage, } from '../../common/Common'
import { _ } from 'core-js'
import { moveOrdersToNewFromPending } from '../../api/OrdersApi'
import I18n from '../../common/I18n'

const RejectOrdersActions = ({
  size,
  orderIds,
  isDisabled = false,
  enableLoading,
  handleStatusResult,
}) => {

  const handleStatusChangeCalls = (func, status) => {
    try {
      func().then((result) => {
        handleStatusResult(result, orderIds)
      })
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      enableLoading(false)
    }
  }

  const handleStatusChange = (status, reasonMessage = '') => {
    enableLoading(true)
      const data = { 
        order_ids: orderIds, 
        status: 'PENDING',
        rejection_reason : "Pending"
    }
    handleStatusChangeCalls(() => moveOrdersToNewFromPending(data), status)
  }

  return (
    <Fragment>
    
        <Button
         onClick={() => handleStatusChange('REJECTED')}
          icon='rollback'
          disabled={isDisabled}
          size={size ? size : 'default'}
          className="marginLeft10 buttonMitesse"
        >
          {I18n.t("general.move_to_pending")}
        </Button>

      &nbsp;
    </Fragment>
  )
}

RejectOrdersActions.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  orderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RejectOrdersActions

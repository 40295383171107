/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover } from 'antd';
import { isEmpty,ellipseText } from '../../common/Common';
import I18n from '../../common/I18n';

function formatAddress(values) {
  const filtered = values.filter(x => (x !== '' && x !== null));
  return filtered.map((line, index) => <div key={index} className={index === 0 ? 'address_first_line' : 'address_rem_lines'}>{line}{index === filtered.length - 1 ? '.' : ','}</div>);
}

const AddressInfo = ({ address, displayType = 'line', showIcon = false,showPopUp = false, showNAInfo = false }) => {
  if (address) {
    const displayAddress = (({
      address_line1, address_line2, city, state, country, zipcode,
    }) => ({
      address_line1, address_line2, city, state, country, zipcode,
    }))(address);
    const filteredValues = Object.values(displayAddress).filter(addr => !isEmpty(addr));
    return (
      <div>
        {filteredValues.length > 0 ? (
          <Fragment>
            {showIcon && (
              <Fragment>
                <Icon type="environment" />
                &nbsp;
              </Fragment>
            )}

            {displayType === "mixFormat" && (
              <Fragment>
                {displayAddress.address_line1 && (
                  <div>{displayAddress.address_line1},</div>
                )}
                {displayAddress.address_line2 && (
                  <div>{displayAddress.address_line2},</div>
                )}
                {displayAddress.city && <div>{displayAddress.city},</div>}
                {Object.keys(displayAddress)
                  .filter(
                    (key) =>
                      !["address_line1", "address_line2", "city"].includes(
                        key
                      ) && !isEmpty(displayAddress[key])
                  )
                  .map((key) => displayAddress[key])
                  .join(", ")}
              </Fragment>
            )}

            {/* {displayType === "line" ? (
              filteredValues.join(", ")
            ) : (
              <div>{formatAddress(Object.values(displayAddress))}</div>
            )} */}

            {displayType === "line" &&
              (showPopUp ? (
                <Popover
                  size="small"
                  content={filteredValues.join(", ")}
                  title={I18n.t("general.address")}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  trigger="hover"
                  placement="top"
                >
                  {ellipseText(filteredValues.join(", "), 15,"","",true)}
                </Popover>
              ) : (
                filteredValues.join(", ")
              ))}
            {displayType === "" && (
              <div>{formatAddress(Object.values(displayAddress))}</div>
            )}
          </Fragment>
        ) : (
          !showNAInfo ? "NA" : <Fragment><Icon type="environment" /> &nbsp; Not Available</Fragment>
        )}
      </div>
    );
  }
  return null;
};

export default AddressInfo;

AddressInfo.propTypes = {
  address: PropTypes.shape().isRequired,
  displayType: PropTypes.string,
  showIcon: PropTypes.bool,
};

AddressInfo.defaultProps = {
  displayType: 'line',
  showIcon: false,
};


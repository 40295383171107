import { Call,ApiUrl,ParseGeneralResponse } from "../../api/ApiUtils";

export const SwitchDetailsApi = {
  switchOrderWarehouse: (organizationId, warehouseId, orderIds,  accountId, losCode) => {
    const url = ApiUrl("v2/customer_orders/update_orders_warehouse");
    const data = {
      organization_id: organizationId,
      warehouse_id: warehouseId,
      order_ids: orderIds,
      account_id: accountId, 
      los_code: losCode 
    };

    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: {
        warehouse_account_orders: 'warehouse_account_orders',
        no_warehouse_account_orders: 'no_warehouse_account_orders',
      },
    });
  },
};
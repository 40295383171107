import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  Modal,
  Popconfirm,
  Tooltip,
} from '../../common/UIComponents'
import { alertMessage, } from '../../common/Common'
import { _ } from 'core-js'
import { postInvoiceToQb, unlockInvoice } from '../../api/BillingScreenAPI'
import lockButton  from '../../../assets/images/lock_blue.png'
import lock from '../../../assets/images/lock.png';
import I18n from '../../common/I18n'

const UnlockInvoice = ({
  buttonType,
  className,
  label,
  isDisabled,
  invoiceIds,
  size,
  handleSuccess,
  displayType,
  requireAction =  true,
  styleObj = {},
  selectedInfo,
  handleUnmount,
  indexList,
  handleOnModalClose,
  isFileGenerated = false,
  isPartiallyPaid,
}) => {
  const [loading, setLoading] = useState(false)
  const resetValues = (closeModal = false) => {
    setLoading(false)
  }

  const showErrors = (errors) => {
    if (errors.length > 0) {
      const firstError = errors[0];
      let title = "";
      let content = "";
      if(_.isObject(firstError)){
        const errorInvoices = errors.filter((err) => err.errors.length > 0)
        title = 'Following invoices could not be processed'
        content = (
          <div>
            {errorInvoices.map((err) => (
              <Row key={err.frequency_invoice_number}>
                <Col xs={24}>
                  <span className="textRed">{err.frequency_invoice_number}</span>:
                  &nbsp;
                  <span>{err.errors.join(',')}</span>
                </Col>
              </Row>
            ))}
          </div>
        );
      }else{
        title= "Errors";
        content= (<div>
            <Row key={'error'}>
              <Col xs={24}>
                <span>{errors.join(',')}</span>
              </Col>
            </Row>
        </div>);
      }
      return Modal.error({
        title,
        content,
        width: '40%',
      });
    }
  }
  const handleSave = async () => {
    let invoiceNos;
    if (typeof selectedInfo === 'string') {
      invoiceNos = [selectedInfo];
    } else if (selectedInfo.selectedRows) {
      invoiceNos = selectedInfo.selectedRows.map((invoice) => invoice.frequency_invoice_number);
    }
    try {
      const payload = {
        account_invoice_ids: invoiceIds ? invoiceIds.split(',') : []
      }
      setLoading(true)
      let response = await unlockInvoice(payload)

      if (response.success) {
        const result = response?.invoices ? response.invoices : {}
        const successMessage = result?.message
          ? result.message
          : I18n.t('messages.saved')
        //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
        alertMessage(successMessage)
        if ( !indexList && typeof handleSuccess === 'function') {
          handleSuccess("",invoiceNos,false)
        }else if(typeof handleUnmount === 'function'){
          handleUnmount(invoiceNos,false)
        }
        if(result?.errors && result.errors.length > 0){
          showErrors(result.errors)
        }
        resetValues();
      } else {
        const errors = response.errors || []
        showErrors(errors)
        resetValues();
        
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setLoading(false)
      if(typeof selectedInfo === 'string'){
        handleOnModalClose()
      }
    }
  }
  const renderIcon = () => (
    displayType === "button" ? (
      <Button
      type={buttonType ? buttonType : 'primary'}
      icon="unlock" 
      className={className ? className : ''}
      loading={loading}
      disabled={isDisabled}
      size={size ? size : 'default'}
    >
      {label}
    </Button>
    ) : (
   (isFileGenerated || !isPartiallyPaid ) && (
    <img
      src={isFileGenerated ? lock : lockButton}
      className="expandIcon cursorPointer"
      alt={I18n.t('invoices.locked')}
      style={{ height:17, width: 17, ...styleObj }}
    />
  )
    )
  );

  return (
    <Fragment>
      {
        requireAction ? <Popconfirm
        placement="topRight"
        title={I18n.t('messages.action_confirm', { action:'unlock', field: ""})}
        onConfirm={requireAction ? handleSave : undefined}
        okText="Yes"
        cancelText="No"
        disabled={!requireAction || isFileGenerated}
      >
          <Tooltip title={isFileGenerated ? "Exported" : ""}>
            {renderIcon()}
          </Tooltip>
        </Popconfirm>
      :
          <Tooltip title={isFileGenerated ? "Exported" : ""}>
            {renderIcon()}
          </Tooltip>}
    </Fragment>
  )
}

UnlockInvoice.propTypes = {
  invoiceIds: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.string.isRequired,
  invoiceIds: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func,
  displayType: PropTypes.string.isRequired,
  requireAction: PropTypes.bool.isRequired,
}
UnlockInvoice.defaultProps = {
  handleSuccess: () => {},
  displayType: "button",
  requireAction: true,
  indexList : false
}

export default UnlockInvoice;
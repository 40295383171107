import React, {  Fragment } from "react";
import {
  Popover,
  Button,
  Table,
} from "../../common/UIComponents";
import { doFormate } from "../../common/Common";
import AppConfig from "../../config/AppConfig";
import { formatByTimeConfig } from "../../helpers/common";
import { momentTime } from "../../helpers/date_functions";
import I18n from "../../common/I18n";

const AttemptedDetails = (props) => {
  const { record, isMilitaryTime, timeZone, timeZoneName } = props;
  const columns = [
    {
      key: "driver_code",
      title: I18n.t("general.Driver"),
      dataIndex: "driver_code",
      width: "18%",
      render: (data) => doFormate(data),
    },
    {
      key: "description",
      title: I18n.t("general.attempt"),
      dataIndex: "description",
      //width: "50%",
      render: (data) => <span>{data ? data : "NA"}</span>,
    },
    {
      key: "effort_on",
      title: I18n.t("general.activity_on"),
      dataIndex: "effort_on",
      width: "40%",
      render: (data) => {
        return (
          <span>
            {data
              ? `${formatByTimeConfig(
                  momentTime("", data, timeZone),
                  isMilitaryTime,
                  AppConfig.milataryDateTimeFormat,
                  AppConfig.dateTimeFormat
                )} ${timeZoneName ? ` ${timeZoneName}` : ""}`
              : "NA"}
          </span>
        );
      },
    },
  ];
  return (
    <Fragment>
      <Popover
        title={`${I18n.t("invoice_lines.attempts")} to the order #${record.customer_order_number}`}
        content={
          <Fragment>
            <Table
              dataSource={record.driver_attempts}
              columns={columns}
              pagination={{
                position: "none",
                pageSize: record.driver_attempts
                  ? record.driver_attempts.length
                  : 20,
              }}
              size="small"
            />
          </Fragment>
        }
        overlayStyle={{
          maxWidth: 500,
        }}
        placement="topLeft"
      >
        <Button
          className="buttonPacificGreen"
          size="small"
          icon="plus"
          style={{ height: 21, marginTop: 3, fontSize: 12, paddingRight: 10 }}
        >
          <span style={{ fontSize: 13 }}>{record.driver_attempts.length}</span>
        </Button>
      </Popover>
    </Fragment>
  );
};

export default AttemptedDetails;

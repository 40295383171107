import React, { useEffect, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Icon
} from "antd";
import { isEmpty } from "lodash";
import { OrgContext } from "../../context/OrgContext";
import { saveAddAdjustment, saveEditAdjustment } from "../../api/Billing";
import GLCodeSelect from "../common/SelectDropdowns/GLCodeSelect";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";
import { alertMessage } from "../../common/Common";

const { Option } = Select;
const { Title } = Typography;

const InvoiceAdjustmentForm = ({
  dataToEdit,
  location_type,
  type_of_order,
  location_id,
  order_id,
  setIsOpenModal,
  status,
  action_from,
  form, // form instance is provided by Form.create()
  closeForm,
  setIsModifiedLines
}) => {
  const { glCodesList = [] } = useContext(OrgContext);
  // Set initial form values if dataToEdit is not empty
  useEffect(() => {
    if (!isEmpty(dataToEdit)) {
      form.setFieldsValue({
        invoice_name: dataToEdit.invoice_name,
        invoice_value: dataToEdit.invoice_value,
        invoice_quantity: dataToEdit.invoice_quantity,
        gl_code: dataToEdit.gl_code,
      });
    }
  }, [dataToEdit]);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        let locationType = null;
  
        // Find the matching location and determine the type_of_loc
        if (location_type && typeof location_type === 'object' && location_type.locations) {
          const matchedLocation = location_type.locations.find(loc => loc._id === location_id);
          if (matchedLocation) {
            locationType = matchedLocation.type_of_loc;
          }
        }
  
        const requestBody = { new_invoice_lines: [{
          ...values,
          invoice_type: "Adjustments",
          location_id,
          order_id: order_id,
          location_type: type_of_order === "T" ? locationType : null
        }],
         customer_order_id: order_id,
         status, 
         action_from: action_from || 'ORDER',
      };
        try {
          if (isEmpty(dataToEdit)) {
            // Call saveAddAdjustment if dataToEdit is empty
            const result = await saveAddAdjustment(requestBody);
            if(result.success){
              alertMessage(result.message ? result.message : I18n.t('messages.saved'))
              form.resetFields();
              setIsModifiedLines(true)
            } else{
              renderAlertMessage(result.errors || [])
            }
          } else {
            requestBody.invoice_line_id = dataToEdit.id;
            const result = await saveEditAdjustment(requestBody);
            if(result.success){
              alertMessage(result.message ? result.message : I18n.t('messages.saved'))
              form.resetFields();
              setIsModifiedLines(true)
              closeForm(true)
            } else{
              renderAlertMessage(result.errors || [])
            }
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }
    });
  };

  // Function to handle form cancellation
  const handleCancel = () => {
    form.resetFields();
    setIsOpenModal(false);
  };

  return (
    <>
      <Row style={{ marginBottom: 16}}>
        <Col xs={12}>
        <Title
          level={4}
          style={{
            
            fontSize: 18,
          }}
          className="alignLeft"
        >
          {!isEmpty(dataToEdit) ? "Update Adjustments" : "Add Adjustments"}
        </Title>
        </Col>
        <Col xs={12} className="alignRight">
          <Icon type="close-circle" style={{ fontSize : 24, fontWeight:'bold' }} className="cursorPointer" onClick={closeForm}/>
        </Col>
      </Row>
     
      <Form
        layout="vertical"
        onSubmit={handleSubmit}
        initialValues={{ invoice_quantity: 1 }}
      >
        {/* Description Input */}
        <Form.Item label="Description" style={{ marginBottom: 16 }}>
          {form.getFieldDecorator("invoice_name", {
            rules: [
              { required: true, message: "Please enter the description!" },
            ],
          })(<Input placeholder="Enter description" />)}
        </Form.Item>

        <Row gutter={16}>
          {/* Amount Input */}
          <Col span={12}>
            <Form.Item label="Amount" style={{ marginBottom: 16 }}>
              {form.getFieldDecorator("invoice_value", {
                rules: [
                  { required: true, message: "Please enter the amount!" },
                ],
              })(
                <InputNumber
                  // min={0}
                  placeholder="Enter amount"
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
          {/* Quantity Input */}
          <Col span={12}>
            <Form.Item label="Quantity" style={{ marginBottom: 16 }}>
              {form.getFieldDecorator("invoice_quantity", {
                rules: [
                  { required: true, message: "Please enter the quantity!" },
                ],
                initialValue: 1, // Set initial quantity to 1
              })(
                <InputNumber
                  min={1}
                  placeholder="Enter quantity"
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
        <Col span={12}>
        {/* GL Code Select */}
        <Form.Item label="GL Code" style={{ marginBottom: 16 }}>
          {form.getFieldDecorator("gl_code", {
            rules: [{ required: true, message: "Please select a GL-Code!" }],
          })(
            <GLCodeSelect
                data={glCodesList || []}
                value={form.getFieldValue("gl_code")}
                // onChange={(value) =>
                //   this.handleInputChange(value, index, "gl_code")
                // }
                selectKey={"gl_code"}
                selectValue={"gl_code"}
                selectedValue={"gl_code"}
                selectedCode={"description"}
                styleObj={{  width: '100%' }}
                showSearch={true}
                placeholder={I18n.t("invoices.gl_code")}
                dropdownMatchSelectWidth={false}
                size="default"
              />
          )}
             
        </Form.Item>
        </Col>
        </Row>

        {/* Submit and Cancel Buttons */}
        <Form.Item style={{ marginTop: 16 }}>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Button onClick={handleCancel} type="danger">Cancel</Button>
            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
              {!isEmpty(dataToEdit) ? "Update" : "Submit"}
            </Button>
           
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

// Wrap the component with Form.create() to provide the form instance as a prop
export default Form.create()(InvoiceAdjustmentForm);

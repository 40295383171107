/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Result } from '../common/UIComponents';

class Renewal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='content-outer'>
                <div className='content'>
                    <Result
                        status="404"
                        title="Please upgrade to Pro version"
                        subTitle="Sorry, the page you visited does not exist in current version."
                        extra={<Button type="primary">Upgrade</Button>}
                    />
                </div>
            </div>
        );
    }
}
export default Renewal;

import React from 'react';
import PropTypes from "prop-types";
import TagsInput from 'react-tagsinput';
import { customPasteSplit } from '../../common/Common';

function EmailsInput (props) {
  const {emails , onChange} = props
  return (
    <TagsInput
      value={ emails}
      onChange={ onChange}
      validationRegex={
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
      onlyUnique
      addOnPaste
      pasteSplit={ customPasteSplit }
      inputProps={ {
        placeholder: "Add Email",
      } }
      addOnBlur={ true }
    />
  );
}

export default EmailsInput;

EmailsInput.propTypes = {
  emails: PropTypes.array,
  onChange: PropTypes.func,
}

EmailsInput.defaultProps = {
  emails: [],
  onChange: () => {},
}

import React, { useState } from 'react';
import { Button } from "antd";
// import "./OutlinedButton.css";
const OutlinedButton = (props) => {
  const { text, onClick, selected , style , icon , isDisabled = false, size="default" } = props;
  const handleClick = () => {
    onClick();
  };

  return (
    <Button
      className={ `outlined-button ${selected ? "filled" : ""}` }
      onClick={ handleClick }
      style={ style || {} }
      icon={ icon }
      disabled={ isDisabled }
      size={size}
    >
      { text }
    </Button>
  );
};

export default OutlinedButton;

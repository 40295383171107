import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { doFormate, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'


const HawbMawbNo = ({ record }) => {
  const hasMawb = !isEmpty(record.mawb) 
  const hasHawb = !isEmpty(record.hawb)
  return (
    <Fragment>
      {(hasMawb || hasHawb) && (
        <Fragment>
          ({' '}
          {hasMawb && (
            <Fragment>
              {I18n.t('general.mawb')} : {doFormate(record.mawb)}
            </Fragment>
          )}
          {hasHawb && (
            <Fragment>
              {' '}
              {hasMawb && <span>&nbsp;,&nbsp;</span>}
              {I18n.t('general.hawb')} : {doFormate(record.hawb)}
            </Fragment>
          )}{' '}
          )
        </Fragment>
      )}
    </Fragment>
  )
}

HawbMawbNo.propTypes = {
  record: PropTypes.shape.isRequired,
}

export default HawbMawbNo

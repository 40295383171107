import React from "react";
import { Progress } from "antd";
import { calculatePercentage } from "../dispatches/helpers";

const TruckLoadProgress = ({
  currentCarriedWeight,
  selectedOrdersWeight,
  truckCapacity,
  style,
}) => {

  const colors = ["#EEF2F5", "#AABDCD", "#607AD6",  "#FF2D78" ];;

  const combinedWeight = currentCarriedWeight + selectedOrdersWeight + truckCapacity;

  const overLoadedWeight = currentCarriedWeight + selectedOrdersWeight - truckCapacity
  const totalCapacityTobeConsider = overLoadedWeight > 0 ? combinedWeight : truckCapacity


  const routeOrdersPercentage = currentCarriedWeight ? calculatePercentage(
    currentCarriedWeight,
    totalCapacityTobeConsider
  ) : 0;

  const selectedOrdersPercentage = selectedOrdersWeight ? calculatePercentage(
    selectedOrdersWeight,
    totalCapacityTobeConsider
  ) : 0;
  

  // Calculate the width of each segment
  const routeOrdersWidth =
    routeOrdersPercentage > 100 ? 100 : routeOrdersPercentage;
  const selectedOrdersWidth =
    selectedOrdersPercentage > 100
      ? 100 - routeOrdersWidth
      : selectedOrdersPercentage;
  const progressColor = overLoadedWeight > 0 ? colors[3] : colors[0]
  const steps = currentCarriedWeight && selectedOrdersWeight ? 3 : (selectedOrdersWeight || currentCarriedWeight) ? 2 : 1;
  const strokeColor = currentCarriedWeight && selectedOrdersWeight ? {
    "0%": colors[1],
    [`${routeOrdersPercentage}%`]: colors[1],
    [`${routeOrdersPercentage+0.01}%`]: colors[2],
    [`${selectedOrdersPercentage+routeOrdersPercentage}%`]: colors[2],
    [`${selectedOrdersPercentage+routeOrdersPercentage+0.1}%`]: progressColor,
    "100%": progressColor,

  }: selectedOrdersWeight ?
    {
      "0%": colors[2],
      [`${selectedOrdersPercentage}%`]: colors[2],
      [`${selectedOrdersPercentage+0.1}%`]: progressColor,
      "100%": progressColor
    }
    :
    currentCarriedWeight ?
    {
      "0%": colors[1],
      [`${routeOrdersPercentage}%`]: colors[1],
      [`${routeOrdersPercentage+0.01}%`]: progressColor,
      "100%": progressColor
    }
    :
    {
      "0%": colors[0],
      "100%": colors[0]
    };
  return (
    <div>
      <Progress
        //percent={displayCombinedPercentage}
        showInfo={false}
        // strokeColor={{
        //   "0%": "#AABDCD",
        //   [`${routeOrdersWidth}%`]: "#607AD6",
        //   [`${routeOrdersWidth + selectedOrdersWidth}%`]:
        //   overLoadedWeight > 0 ? "#FF2D78" : "#607AD6",
        // }}
        // trailColor={overLoadedWeight > 0 ? "#FF2D78" : undefined}
        // style={style}
        strokeColor={{...strokeColor}}
        steps={steps}
        percent="100%"
      />
      {/* {overLoadedWeight > 0 && (
        <div style={{ color: "#FF2D78", marginBottom: 10 }}>
          Exceeded by:{" "}
          {`${
            overLoadedWeight
          } lbs`}
        </div>
      )} */}
    </div>
  );
};

export default TruckLoadProgress;


/*

import React from "react";
import { Progress } from "antd";
import { calculatePercentage } from "../dispatches/helpers";

const TruckLoadProgress = ({
  currentCarriedWeight,
  selectedOrdersWeight,
  truckCapacity,
  style,
}) => {
  const routeOrdersPercentage = calculatePercentage(
    currentCarriedWeight,
    truckCapacity
  );
  const selectedOrdersPercentage = calculatePercentage(
    selectedOrdersWeight,
    truckCapacity
  );
  const combinedWeightPercentage = calculatePercentage(
    currentCarriedWeight + selectedOrdersWeight,
    truckCapacity
  );

  // Calculate the exceeded percentage
  const exceededPercentage =
    combinedWeightPercentage > 100 ? combinedWeightPercentage - 100 : 0;

  // Calculate the displayed percentage within the 100% limit
  const displayCombinedPercentage =
    combinedWeightPercentage > 100 ? 100 : combinedWeightPercentage;

  // Calculate the width of each segment
  const routeOrdersWidth =
    routeOrdersPercentage > 100 ? 100 : routeOrdersPercentage;
  const selectedOrdersWidth =
    selectedOrdersPercentage > 100
      ? 100 - routeOrdersWidth
      : selectedOrdersPercentage;
  const exceededWidth = exceededPercentage > 0 ? exceededPercentage : 0;

  return (
    <div>
      <Progress
        percent={displayCombinedPercentage}
        success={{
          percent: routeOrdersWidth,
          strokeColor: "#AABDCD",
        }}
        showInfo={false}
        strokeColor={{
          "0%": "#AABDCD",
          [`${routeOrdersWidth}%`]: "#607AD6",
          [`${routeOrdersWidth + selectedOrdersWidth}%`]:
            exceededWidth > 0 ? "#FF2D78" : "#607AD6",
        }}
        trailColor={exceededWidth > 0 ? "#FF2D78" : undefined}
        style={style}
      />
      {exceededPercentage > 0 && (
        <div style={{ color: "#FF2D78", marginBottom: 10 }}>
          Exceeded by:{" "}
          {`${
            currentCarriedWeight + selectedOrdersWeight - truckCapacity
          } lbs (${exceededPercentage.toFixed(2)}%)`}
        </div>
      )}
    </div>
  );
};

export default TruckLoadProgress;

*/